import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getExchangeRateAPI } from "../../../../services/commonServices";

const StockHeader = ({ stockData, stockDate, tradeCode, tradeP }) => {
  const [increased, setIncreased] = useState(true);
  const [profitLoss, setProfitLoss] = useState(true);
  const [profitPresent, setprofitPresent] = useState(true);
    const [cryptoName, setCryptoName] = useState("");
    const [codee, setCodee] = useState()

  const [localAskPrice, setLocalAskPrice] = useState("");
  const [localBidPrice, setLocalBidPrice] = useState("");

  const callLocal = async () => {
      const codee = JSON.parse(localStorage?.getItem("tradeCode"));
    const localAskPrice = JSON.parse(localStorage?.getItem("askPrice"));
    const localBidPrice = JSON.parse(localStorage?.getItem("bidPrice"));
    setLocalAskPrice(localAskPrice || 0);
      setLocalBidPrice(localBidPrice || 0);
      setCodee(codee || "")
  };
  const n = tradeCode;
  useEffect(() => {
    // Call local prices initially
    callLocal();
    setCryptoName(n);

    // Set up an interval to check for updates
    const interval = setInterval(callLocal, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup on unmount
  },[]);

  // const n = tradeCode.split('_')

  useEffect(() => {
    let op = stockData[stockData?.length - 1]?.open || 0;
    let cl = stockData[0]?.close || 0;

    setProfitLoss(cl - op);
    if (cl - op > 0) {
      setIncreased(true);
    } else {
      setIncreased(false);
    }
    // let n=stockData[0]?.symbol_id.split('_')

    if (n !== undefined) {
      // setCryptoName(n[n?.length-2])
      setCryptoName(n);
    }
  }, [stockData]);
  useMemo(
    () =>
      setprofitPresent(
        (profitLoss * 100) / stockData[stockData?.length - 1]?.open || 0
      ),
    [profitLoss]
  );

  return (
    <>
      <div class="row">
        <div class="cp-tail-bx">
          {/* <div class="col-sm-7">
                        <div class="company-lg">
                            <img src="assets/image/tata.webp" class="img-fluid" />
                        </div>
                    </div> */}

          {/* 
                    <div class="col-sm-4  ">
                        <div class="bell-bx">
                            <ul>
                                <li><a href=""><i class="bi bi-bell"></i></a></li>
                                <li><a href=""><i class="bi bi-bookmark"></i></a></li>
                            </ul>
                        </div>
                    </div> */}

          <div class="col-sm-12 mt-4 d-md-flex white_text">
            {/* <button class="btn ms-auto d-block optino-btn-td"> <i class="bi bi-link" style={{"font-size":"14px;"}}></i> Option Chain</button> */}
            <h1>{codee || cryptoName || tradeCode}</h1>
            <h5 className="overview-header-text">
              Price-{(tradeP?.close || localAskPrice || 0).toFixed(5) || 0}{" "}
              &nbsp;&nbsp;&nbsp;{" "}
              <Link
                to={`/chart?tradeCode=${n}`}
                target="_blank"
                className="candle-chart-link"
              >
                View professional chart
              </Link>
            </h5>
            <button
              type="button"
              id="closeButton"
              className="d-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockHeader;
