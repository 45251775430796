import React, { useEffect, useMemo, useState } from 'react'
import { createPortfoloio, editPortfoloio } from '../../../services/PortfolioService'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { getCountryListAPI } from '../../../services/commonServices';
import { toast } from 'react-toastify';
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';
import process from "process";
// import CountryCurrencyList form 
const ApplicationKey = process.env.REACT_APP_ApplicationKey;
// console.log(ApplicationKey ,"ApplicationKey from craete")
const CreatePortfolio = ({ runAgainDashBoard, setRunAgainDashBoard, userType, setRunPortFolioAPI, providerList, portFolioData, showEdit, setShowEdit, setWatchListRun }) => {


    const [portInputData, setPortInputData] = useState({
        portfolioName: '',
        country: '',
        providerId: '',
        currency: '',
        currencySymbol: '',
        userId: userType?.userId,
        reRender: 1,
        ApplicationKey: ApplicationKey

    })
    
    const [countryList, setCountryList] = useState()
    const { portfolioName, currency, country, email, reRender } = portInputData
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (showEdit == true) {

            setPortInputData({
                portfolioName: portFolioData?.portfolioName || '',
                country: portFolioData?.country || '',
                providerId: portFolioData?.providerId || '',
                currencySymbol: portFolioData?.currencySymbol || '',
                userId: userType?.userId,
                currency: portFolioData.currency,
                ApplicationKey: ApplicationKey
            })
        }
    }, [portFolioData != '' && portFolioData && showEdit])

    useEffect(() => {
        try {
            getCountryListAPI().then((res) => {
                if (res?.status === 200) {
                    // console.log(res.data, "countrydata")
                    // console.log(portInputData ,"portInputData add")
                    setCountryList(res.data)
                }
            })
        } catch (error) {

        }
    }, [])

    const handleAddEditPortfolio = async (value) => {
        const portCancle = document.getElementById('Cancle-portFolio');
        value.userId = userType?.userId;
      
        try {
          setIsLoading(true);
      
          if (showEdit === true) {
            let id = portFolioData.portfolioId;
      
            if (userType?.userId !== undefined && userType?.userId !== null && userType?.userId.length > 0) {
              const res = await editPortfoloio(value, id);
      
              if (res?.status === 200) {
                setRunAgainDashBoard(runAgainDashBoard + 1);
                setWatchListRun(true);
                portCancle.click();
              }
            } else {
              toast.error('invalid user!');
            }
          } else {
            if (userType?.userId !== undefined && userType?.userId !== null && userType?.userId.length > 0) {
              const res = await createPortfoloio(value);
      
              if (res?.status === 200) {
                setWatchListRun(true);
                setRunPortFolioAPI(true);
                portCancle.click();
                setRunAgainDashBoard(runAgainDashBoard + 1);
              }
            } else {
              toast.error('invalid user!');
            }
          }
        } catch (error) {
          // Handle errors if needed
        } finally {
          setIsLoading(false);
        }
      };
      
    const handleRemoveInputData = () => {
        // 
        setPortInputData({
            portfolioName: '',
            country: '',
            providerId: '',
            currencySymbol: '',
            userId: userType?.userId,
            reRender: reRender + 1 ,
            ApplicationKey : ApplicationKey
        })
        // setRunAgainDashBoard(runAgainDashBoard+1)
        if (showEdit == true) {
            setShowEdit(false)
        }

    }
    let validationSchema = Yup.object().shape({
        portfolioName: Yup.string().required('Portfolio name is required'),
        country: Yup.string().required('Country is required'),
        // providerId: Yup.string().required('Provider is required'),
    });

    const handleChangePortFolioInput = (e) => {
        if (e.target.name == 'country') {
            let selectedCountry = countryList.filter((val) => val.key == e.target.value)
            let symboleData
            if (selectedCountry.length > 0) {
                symboleData = CountryCurrencyList.filter((val) => val.code == selectedCountry[0].value)
            }

            setPortInputData({ ...portInputData, [e.target.name]: e.target.value, currencySymbol: symboleData[0]?.symbol_native })
        } else {
            setPortInputData({ ...portInputData, [e.target.name]: e.target.value })
        }
    }
    const handleBlurCreatePort = () => {

    }
    return (
        <>
            <div className="modal fade show backdrop-1" data-bs-backdrop="static" data-keyboard="false" id="portfolio" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ "display": 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content  provider-min-md">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4 white_text" id="exampleModalLgLabel">{showEdit == true ? 'Update' : 'Create'} Portfolio</h5>
                            <button type="button" className=" ms-auto button-padding" data-bs-dismiss="modal" aria-label="Close" onClick={handleRemoveInputData}>×</button>
                        </div>
                        <div className="modal-body blackout-bx">
                            <Formik
                                initialValues={portInputData}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                onSubmit={(value) => {
                                    handleAddEditPortfolio(value)
                                    // alert(JSON.stringify(value))
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form className='row p-3'>
                                        {/* <div class="col-sm-12"> */}
                                        <div class="col-12">
                                            <label>Portfolio Name</label>
                                            <Field type="text" class="form-control" value={portfolioName} onBlur={handleBlurCreatePort} name='portfolioName' onChange={handleChangePortFolioInput} id="autoSizingInput" placeholder="" />
                                            {errors.portfolioName && touched.portfolioName ? (
                                                <div className='color-msg-err'>{errors.portfolioName}</div>
                                            ) : null}
                                        </div>
                                        <div class="col-12">
                                            <label className='countrylabel'>Country</label>
                                            <Field name='country' disabled={showEdit == true ? true : false} as='select' onBlur={handleBlurCreatePort} className="form-select" onChange={handleChangePortFolioInput}>
                                                <option value="0" style={{ 'visibility': "hidden" }}>Select Country</option>
                                                {
                                                    countryList?.map((val, ind) => {
                                                        return (
                                                            <option key={ind} value={val.key}>{val.key}</option>
                                                        )
                                                    })
                                                }
                                            </Field>
                                            {errors.country && touched.country ? (
                                                <div className='color-msg-err'>{errors.country}</div>
                                            ) : null}
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                {/* <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit' >{showEdit === true ? 'Edit Porfolio' : <>Add Porfolio <span> <i class="bi bi-plus-lg"></i> </span></>}   <span>  </span></button> */}
                                                <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit' disabled={isLoading}> {showEdit == true ? 'Update' : 'Save'}</button>
                                            </div>
                                            <div className='col-md-6'>
                                                <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-portFolio' type='button' onClick={handleRemoveInputData} data-bs-dismiss="modal" aria-label="Close" >Cancel</button>
                                            </div>

                                        </div>

                                    </Form>
                                )}

                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreatePortfolio