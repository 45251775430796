import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
    'Authorization':API_KEY
}
const ApplicationKey = process.env.REACT_APP_ApplicationKey;

export const createStrategyAPI = async (data) => {
    
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddTradeSystem?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getTradeSystemBYID = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeSystems?UserId=${data?.id}&pageNumber=${data?.pageNumber}&pageSize=${data?.pageSize}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        
    }
}

export const getStrategy = async (id) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeSystemsList?UserId=${id}&ApplicationKey=${ApplicationKey} `,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Server error.')
        }
        // 
    } catch (error) {
        
    }
}

export const editStrategy = async (data) => {
    try {
        const response = await axios({
            method: 'put',
            url: `${API_URL}/Trades/updateTradeSystem?id=${data.id}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const deleteStrategy = async (data) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteTradeSystem?id=${data.tradingSystemId}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}