import React, { useEffect, useState } from 'react'
import { addStocks, GetProviderstocks, updateProviderStock } from '../../../services/ProviderServices'
import moment from 'moment/moment'
import Pagination from '../../commonComponent/Pagination'
import { BiSearchAlt } from 'react-icons/bi';

const ProviderModel = ({ providerDataList }) => {
    const [inputStockDetail, setInputStockDetail] = useState({ ProviderId: providerDataList[0]?.providerId, file: '' })
    const { file, ProviderId } = inputStockDetail
    const [providerStockList, setProviderStockList] = useState([])
    const [runList, setRunList] = useState(true)
    const [pId, setpId] = useState(providerDataList[0]?.providerId)
    const [pagination, setPagination] = useState({ currentPage: 1, pageNumber: 1, pageSize: 15, totalPages: 0, search: '' })
    const { pageNumber, pageSize, currentPage, totalPages, search } = pagination
    const [stockDetails, setStockDetails] = useState({
        country: "", createdBy: '', createdDate: "", deletedDate: "",
        isActive: '', isDeleted: '', isShort: '', lastPrice: '', margin: '', modifiedBy: '', modifiedDat: "", provide: null,
        providerI: 1, shareSector: "", stockCode: " ", stockId: '', stockName: ""
    })
    const { stockCode, margin, stockName, country } = stockDetails
    const [stockEdit, setStockEdit] = useState(false)
    const [nextLoading, setNextLoading] = useState(false)
    const [excelLoading, setExcelLoading] = useState({ status: false, msg: 'Uploading' })



    const handlePageChange = (newPage) => {
        setNextLoading(true)
        setRunList(true)
        setPagination({ ...pagination, currentPage: currentPage + newPage, pageNumber: pageNumber + newPage })
        // Perform data fetching or other actions when page changes
    };
    useEffect(() => { setInputStockDetail({ ...inputStockDetail, ProviderId: providerDataList[0]?.providerId }) }, [providerDataList])
    const handleSelectProvider = (e) => {
        if (e.target.name === 'file') {
            setInputStockDetail({ ...inputStockDetail, [e.target.name]: e.target.files[0] })
        } else {
            document.getElementById('SelectProvider').value = ""
            setInputStockDetail({ ...inputStockDetail, [e.target.name]: e.target.value,file:'' })
            setpId(e.target.value)
            setPagination({ ...pagination, currentPage: 1 })
        }
    }
    useEffect(() => {
        setpId(providerDataList[0]?.providerId)
    }, [providerDataList[0]?.providerId])
    const handleImportStocks = () => {
        setExcelLoading({ ...excelLoading, status: true })
        let formData = new FormData();
        formData.append('ProviderId', inputStockDetail.ProviderId);
        formData.append('file', inputStockDetail.file);
        try {
            addStocks(formData).then((res) => {
                
                if (res?.status === 200) {
                    setInputStockDetail({ ProviderId: ProviderId, file: '' })
                    setRunList(true)
                    document.getElementById('SelectProvider').value = ""
                    getProviderStockListAPI()
                    setExcelLoading({ msg: 'Uploaded', status: true })
                    setTimeout(() => {
                        setExcelLoading({ msg: 'Uploading', status: false })
                    }, 5000)
                } else{

                    setExcelLoading({ msg: 'Failed', status: true })
                    setTimeout(() => {
                        setExcelLoading({ msg: 'Uploading', status: false })
                    }, 5000)
                }

            })
        } catch (error) {
            console.timeLog(error)
            setExcelLoading({ msg: 'Failed', status: true })
            setTimeout(() => {
                setExcelLoading({ msg: 'Uploading', status: false })
            }, 5000)
        }
    }

    const getProviderStockListAPI = (checkEnt) => {
        let data
        if(checkEnt=='enter'){
            
             data = {
                PageNumber: 1,
                PageSize: pageSize,
                search: search,
                id: pId
            }
        }else{
            data = {
                PageNumber: currentPage,
                PageSize: pageSize,
                search: search,
                id: pId
            }
        }
        if (data.id !== undefined && data.id > 0) {
            try {
                GetProviderstocks(data).then((res) => {
                    if (res?.status === 200) {
                        setRunList(false)
                        setProviderStockList(res.data.data)
                        if(checkEnt=='enter'){
                            setPagination({ ...pagination, totalPages: res.data.totalPages, currentPage:1,pageNumber:1 })
                        }else{

                            setPagination({ ...pagination, totalPages: res.data.totalPages })
                        }

                        setNextLoading(false)
                    }
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        getProviderStockListAPI()
    }, [pId !== undefined && runList === true, runList, pId])

    const handleSearchInput = (e) => {
        setPagination({ ...pagination, [e.target.name]: e.target.value })
    }
    const onPressSearchStock = (key) => {
        setPagination({ ...pagination, currentPage: 1, pageNumber: 1 })
        if (search.length == 0) {
            getProviderStockListAPI()
        } if (key == 'Enter' && currentPage == 1) {
            setTimeout(() => {
            }, 400)
        }
        getProviderStockListAPI()
    }
    const onButtonClick = () => {
        // using Java Script method to get PDF file

        fetch('assets/pdf/comoditiCode.xlsx').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'comoditiCode.xlsx';
                alink.click();
                // window.URL.revokeObjectURL(fileURL);
            })      
        })
    }
    const handleUpdateStock = (value) => {
        setStockDetails(value)
        setStockEdit(true)
    }
    const handleSaveUpdateStock = () => {
        //  updateProviderStock
        if (stockCode.length > 0 && margin > 0 && stockName.length > 0 && country.length > 0) {
            try {
                updateProviderStock(stockDetails).then((res) => {
                    if (res?.status === 200) {
                        setStockEdit(false)
                        setRunList(true)
                    }
                })
            } catch (error) {

            }
        }

    }
    const handleChangeStockInput = (e) => {
        setStockDetails({ ...stockDetails, [e.target.name]: e.target.value })
    }
    const handleCloseProviderModal = () => {
        setPagination({ ...pagination, search: '', currentPage: 1, pageNumber: 1 })
        setRunList(true)
        setExcelLoading({ msg: 'Uploading', status: false })
        document.getElementById('SelectProvider').value = ""
        setInputStockDetail({...inputStockDetail,file:''})
    }
    return (
        <>
            <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="providerList" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ 'display': 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg  modal-dialog-centered">
                    <div className="modal-content  provider-min-md">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4 white_text" id="exampleModalLgLabel">Providers Stock List</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" onClick={handleCloseProviderModal} aria-label="Close">×</button>
                        </div>

                        <div className="modal-body blackout-bx ">
                            {/* <!-------modeal-----> */}

                            <div className='row'>
                                <section class="mt-4">

                                    <div class="container">


                                        <div class="data-import">
                                            <div class="row">



                                                <div class="col-sm-6 mb-2">
                                                <label>Select Provider</label>
                                                    <select class="form-select" name='ProviderId' value={ProviderId} onChange={handleSelectProvider} aria-label="Default select example">
                                                        {
                                                            providerDataList.map((val, ind) => {
                                                                return (

                                                                    <option key={ind} value={val.providerId}>{val.providerName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>





                                                <div class="col-sm-6">
                                                    <button class="btn  ms-auto d-block mb-3" onClick={onButtonClick}>Download Excel Format</button>
                                                </div>

                                                <div class="about-data-right tabs-sec">
                                                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                        <li class="nav-item" role="presentation">
                                                            {/* <button class="nav-link button-link-overview height-36px active" id="pills-about-tab" data-bs-toggle="pill" data-bs-target="#pills-about" type="button" role="tab" aria-controls="pills-about" aria-selected="true">Upload Stock</button> */}
                                                        </li>
                                                        {/* <li class="nav-item" role="presentation">
                                                            <button class="nav-link button-link-overview height-36px " id="pills-know-tab" data-bs-toggle="pill" data-bs-target="#pills-know" type="button" role="tab" aria-controls="pills-know" aria-selected="false">Select Stock</button>
                                                        </li> */}
                                                    </ul>

                                                </div>
                                                <div class="about-data-min">
                                                    <div class="tab-content" id="pills-tabContent">
                                                        <div class="tab-pane fade " id="pills-know" role="tabpanel" aria-labelledby="pills-know-tab" >
                                                            <div className='row'>
                                                                {/* <div class="col-sm-12 ">
                                                                    <button class="nav-link search-input border-0 mb-3 p-0 ms-auto"><input name='search' value={search} className='search-input-main' onKeyUp={(e) => e.key == 'Enter' && getProviderStockListAPI()} onChange={handleSearchInput} placeholder='search trade...' />
                                                                        <span className='span1'><BiSearchAlt onClick={getProviderStockListAPI} /></span>
                                                                    </button>
                                                                    <div class="Fundamentals-bx">
                                                                       
                                                                        <div class="table-responsive">
                                                                            <div class="table-input1 text-center provider-datafd position-relative">
                                                                                <table class="table text-center table-input-data1 mb-4">

                                                                                    <tr class="border-bottom-0">
                                                                                        <th scope="row" class="ad-tab-b col-2 border-end">stockCode</th>
                                                                                        <th scope="row" class="ad-tab-b col-2 border-end">Margin</th>
                                                                                        <th scope="row" class="ad-tab-b col-4 border-end">stockName</th>
                                                                                        <th scope="row" class="ad-tab-b col-2 border-end">Country</th>
                                                                                        <th scope="row" class="ad-tab-b col-2 border-end">Edit</th>
                                                                                    </tr>

                                                                                    <tbody>
                                                                                        {
                                                                                            providerStockList.map((val, ind) => {
                                                                                                return (
                                                                                                    stockEdit && stockDetails?.stockId == val.stockId ?
                                                                                                        <tr key={ind}>
                                                                                                            <td class="text-center"><input className={stockEdit == true && stockCode == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='stockCode' value={stockCode} onChange={handleChangeStockInput} /></td>
                                                                                                            <td class="text-center"><input className={stockEdit == true && margin == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='margin' value={margin} onChange={handleChangeStockInput} /></td>
                                                                                                            <td class="text-center"><input className={stockEdit == true && stockName == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='stockName' value={stockName} onChange={handleChangeStockInput} /></td>
                                                                                                            <td class="text-center"><input className={stockEdit == true && country == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='country' value={country} onChange={handleChangeStockInput} /></td>
                                                                                                            <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" title='Edit' onClick={() => handleSaveUpdateStock(val)}>Save</button></td>


                                                                                                        </tr> :
                                                                                                        <tr key={ind}>
                                                                                                            <td class="text-center">{val.stockCode}</td>
                                                                                                            <td class="text-center">{val.margin} %</td>
                                                                                                            <td class="text-center">{val.stockName}</td>
                                                                                                            <td class="text-center">{val.country}</td>
                                                                                                            <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" title='Edit' onClick={() => handleUpdateStock(val)}> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td>
                                                                    
                                                                                                        </tr>
                                                                                                )
                                                                                            })
                                                                                        }


                                                                                    </tbody>

                                                                                </table>
                                                                                <Pagination
                                                                                    currentPage={currentPage}
                                                                                    totalPages={totalPages}
                                                                                    onPageChange={handlePageChange}
                                                                                    setPagination={setPagination}
                                                                                    pageNumber={pageNumber}
                                                                                    pageSize={pageSize}
                                                                                    pagination={pagination}
                                                                                    nextLoading={nextLoading}
                                                                                />
                                                                                {
                                                                                    nextLoading &&
                                                                                    <div className='loading-Dot-stock'>
                                                                                        <div class="dot1"> </div>
                                                                                        <div class="dot2"></div>
                                                                                        <div class="dot3"></div>
                                                                                    </div>
                                                                                }


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>

                                                        <div class="tab-pane fade show active mt-3" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                                                            <div className='row'>
                                                                <div class="col-sm-12">
                                                                    <div class="import-bx ">

                                                                        <i class="bi bi-box-arrow-up drop"></i> <br /> <br />

                                                                        <h4 className='white_text'>Data Import</h4>
                                                                       
                                                                        <input type="file" name="file" id='SelectProvider' placeholder="choose file "  onChange={handleSelectProvider} />
                                                                        
                                                                        {
                                                                          
                                                                            excelLoading.status == true ?
                                                                                excelLoading.msg == 'Uploading' ? <h5 className='excel_msg_pending d-inline loading-main-dot'>{excelLoading.msg}
                                                                                    <div className='loading-Dot-stock-excel'>
                                                                                        <div class="dot11">.</div>
                                                                                        <div class="dot21">.</div>
                                                                                        <div class="dot31">.</div>
                                                                                    </div></h5> : excelLoading.msg == 'Failed' ? <h5 className='excel_msg_pending d-inline loading-main-dot'>{excelLoading.msg}</h5> :
                                                                                    <h5 className='excel_msg d-inline loading-main-dot'>{excelLoading.msg}</h5>
                                                                                : ''
                                                                        }
                                                                       
                                                                        <button class="btn  mx-auto d-block mt-4" disabled={excelLoading.status == true || inputStockDetail.file == ''} onClick={handleImportStocks}>Import</button>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-12 mt-3 ">
                                                                    <button class="nav-link search-input border-0 mb-3 p-0 ms-auto"><input name='search' value={search} className='search-input-main' onKeyUp={(e) => (e.key == 'Enter' || e.target.value.length == 0) && getProviderStockListAPI('enter')} onChange={handleSearchInput} placeholder='search Stock...' />
                                                                        <span className='span1'><BiSearchAlt onClick={()=>getProviderStockListAPI('enter')} /></span>
                                                                    </button>
                                                                    <div class="Fundamentals-bx">
                                                                        {/* <!-- <h5 class="mb-3">Tata Power Co Ltd Key Indicators</h5> --> */}

                                                                        <div class="table-responsive">
                                                                            <div class="table-input1 text-center provider-datafd position-relative">
                                                                                <table class="table white_text text-center table-input-data1 mb-4">

                                                                                    <tr class="border-bottom-0">
                                                                                        {/* <th scope="row" class="ad-tab-b border-end">Provider</th> */}
                                                                                        <th scope="row" class="ad-tab-b col-2 border-end">stockCode</th>
                                                                                        <th scope="row" class="ad-tab-b col-2 border-end">Margin</th>
                                                                                        <th scope="row" class="ad-tab-b col-4 border-end">stockName</th>
                                                                                        {/* <th scope="row" class="ad-tab-b border-end">Short</th> */}
                                                                                        <th scope="row" class="ad-tab-b col-2 border-end">Country</th>
                                                                                        {/* <th scope="row" class="ad-tab-b col-2 border-end">CreateDates</th> */}
                                                                                        <th scope="row" class="ad-tab-b col-2 border-end">Edit</th>
                                                                                        {/* <th scope="row" class="ad-tab-b border-end">ModifiedDate</th> */}
                                                                                    </tr>

                                                                                    <tbody>
                                                                                        {
                                                                                            providerStockList.map((val, ind) => {
                                                                                                return (
                                                                                                    stockEdit && stockDetails?.stockId == val.stockId ?
                                                                                                        <tr key={ind}>
                                                                                                            <td class="text-center"><input className={stockEdit == true && stockCode == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='stockCode' value={stockCode} onChange={handleChangeStockInput} /></td>
                                                                                                            <td class="text-center"><input className={stockEdit == true && margin == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='margin' value={margin} onChange={handleChangeStockInput} /></td>
                                                                                                            <td class="text-center"><input className={stockEdit == true && stockName == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='stockName' value={stockName} onChange={handleChangeStockInput} /></td>
                                                                                                            <td class="text-center"><input className={stockEdit == true && country == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='country' value={country} onChange={handleChangeStockInput} /></td>
                                                                                                            <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" title='Edit' onClick={() => handleSaveUpdateStock(val)}>Save</button></td>


                                                                                                        </tr> :
                                                                                                        <tr key={ind}>
                                                                                                            <td class="text-center">{val.forexSymbol}</td>
                                                                                                            <td class="text-center">{val.margin} %</td>
                                                                                                            <td class="text-center">{val.currencyGroup}</td>
                                                                                                            {/* <td class="text-center">{val.isShort}</td> */}
                                                                                                            <td class="text-center">{val.country}</td>
                                                                                                            <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" title='Edit' onClick={() => handleUpdateStock(val)}> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td>
                                                                                                            {/* <td class="text-center">{moment(val.createdDate).format('MM/DD/YYYY')}</td> */}

                                                                                                        </tr>
                                                                                                )
                                                                                            })
                                                                                        }


                                                                                    </tbody>

                                                                                </table>
                                                                                <Pagination
                                                                                    currentPage={currentPage}
                                                                                    totalPages={totalPages}
                                                                                    onPageChange={handlePageChange}
                                                                                    setPagination={setPagination}
                                                                                    pageNumber={pageNumber}
                                                                                    pageSize={pageSize}
                                                                                    pagination={pagination}
                                                                                    nextLoading={nextLoading}
                                                                                />
                                                                                {
                                                                                    nextLoading &&
                                                                                    <div className='loading-Dot-stock'>
                                                                                        <div class="dot1"> </div>
                                                                                        <div class="dot2"></div>
                                                                                        <div class="dot3"></div>
                                                                                    </div>
                                                                                }


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>



                                        </div>
                                    </div>

                                </section>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ProviderModel