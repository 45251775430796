import React, { useEffect, useState } from 'react'
import { DeleteFeedBack, GetProviderCummission, addProvider, getFeedBack, deleteProviders, getProviders, updateProviders, deleteHelpVideoAPI, GetHelpVideos } from '../../services/ProviderServices'
import AddUpdateModel from './Model/AddUpdateModel'
import ProviderModel from './Model/ProviderModel'
import AddCummission from './Model/AddCummission'
import { deleteStrategy, getStrategy, getTradeSystemBYID } from '../../services/StrategyService'
import AddEditStrategy from './Model/AddEditStrategy'
import DeleteConfirmation from '../commonComponent/DeleteConfirmation'
import { editTrade, gettradeList } from '../../services/TradeService'
import moment from 'moment/moment'
import CreateTrade from './Model/CreateTrade'
import Pagination from '../commonComponent/Pagination'
import TradeModel from '../Trade/Model/TradeModel'
import EditTrade from './Model/EditTrade'
import { deleteTrade } from '../../services/TradeService'
import CummissionDetails from '../commonComponent/commonModels/CummissionDetails'
import { BiSearchAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ViewImage from '../commonComponent/commonModels/ViewImage'
import AddEditVideo from './Model/AddEditVideo'

const AdminTable = ({ userType }) => {
    const [addUpdateSelect, setAddUpdateSelect] = useState('')
    const [strategyList, setStrategyList] = useState([])
    const [getProviderRun, serGetProviderRun] = useState(true)
    const [providerDataList, setPrividerDataList] = useState([])
    const [inputCummission, setInputCummission] = useState({ providerId: '', aboveTransValue: '', upToTransValue: '', commission: '', comments: '', type: 'Fixed', createdBy: '', modifiedBy: '' })
    const [inputData, setInputData] = useState({ providerName: '', parentCompany: '', yearAsCFDProvider: '', platform: '' })
    const [showSearchInput, setShowSearchInput] = useState(false)
    const [deleteConfirmData, setDeleteConfirmData] = useState('')
    const [editTrue, setEditTrue] = useState(false)
    const [runAgainStrategyAPI, setRunAgainStrategyAPI] = useState(true)
    const [strategyInput, setSerategyInput] = useState({ UserId: '1', Name: '', Notes: '', EntryStrategy: '', ExitStrategy: '', ScaleInOutStrategy: '', reRender: 0 })
    const [pagination, setPagination] = useState({ currentPage: 1, pageNumber: 1, pageSize: 10, totalPages: 0, search: '' })
    const { pageNumber, pageSize, currentPage, totalPages, search } = pagination
    const [paginationStr, setPaginationStr] = useState({ currentStrPage: 1, pageStrNumber: 1, pageStrSize: 10, totalStrPages: 0, Strsearch: '' })
    const { pageStrNumber, pageStrSize, currentStrPage, totalStrPages, Strsearch } = paginationStr
    const [tradeList, setTradeList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [typeShow, setTypeShow] = useState('TRADES')
    const [tradeData, setTradeData] = useState('')
    const [tradeCode, setTradeCode] = useState('')
    const [deleteType, setDeleteType] = useState('')
    const [tradeListRun, setTradeListRun] = useState(true)
    const [deleteMsg, setDeleteMsg] = useState('')
    const [providerCummission, setProviderCummission] = useState([])
    const [feedbackpagination, setfeedbackPagination] = useState({ feedbackcurrentPage: 1, feedbackpageNumber: 1, feedbackpageSize: 15, feedbacktotalPages: 0, earch: '' })
    const { feedbackpageNumber, feedbackpageSize, feedbackcurrentPage, feedbacktotalPages, feedbacksearch } = feedbackpagination
    const [getfeedback, setgetfeedback] = useState([])
    const [getfeedbackRun, serGetfeedbackRun] = useState(true)
    const [actionEdit, setActionEdit] = useState(false)
    const [editTradeLineData, setEditTradeLineData] = useState({})
    const [editerror, setEditError] = useState({ entryErr: false, tragerErr: false, stoplossErr: false })
    const { entryErr, tragerErr, stoplossErr } = editerror
    const [chartImg, setChartImg] = useState('')
    const [deleteConfirmCommi, setDeleteConfirmCommi] = useState(false)
    const [helpVideopagination, setHelpVideoPagination] = useState({ helpVideocurrentPage: 1, helpVideopageNumber: 1, helpVideopageSize: 15, helpVideototalPages: 0, helpVideosearch: '' })
    const { helpVideopageNumber, helpVideopageSize, helpVideocurrentPage, helpVideototalPages, helpVideosearch } = helpVideopagination
    const [helpVideoList, setHelpVideoList] = useState([])
    const [addUpdateVideoSelect, setAddUpdateVideoSelect] = useState('add')
    const [inputVideo, setInputVideo] = useState({ userId: '', moduleNeme: '', helpNeme: '', description: '', videoFile: '', reRenderV: 1 })
    const [tooltipContent, setTooltipContent] = useState('');

    const handleMouseOver = (stockName) => {
        setTooltipContent(stockName);
    };

    const handleMouseOut = () => {
        setTooltipContent('');
    };


    const handlePageChangeStr = (newPage) => {
        setPaginationStr({ ...paginationStr, currentStrPage: currentStrPage + newPage })
    }
    const feedbackhandlePageChange = (newPage) => {
        setfeedbackPagination({ ...pagination, feedbackcurrentPage: feedbackcurrentPage + newPage })
    };

    useEffect(() => {
        let data = {
            PageNumber: 1,
            PageSize: 1000
        }
        try {
            getProviders(data).then((res) => {
                setPrividerDataList(res.data.data)
            })
        } catch (error) {

        }
        serGetProviderRun(false)
    }, [getProviderRun === true])

    const handleDeleteProvider = () => {
        try {
            deleteProviders(deleteConfirmData.providerId).then((res) => {

                if (res?.status === 200) {
                    serGetProviderRun(true)
                    setDeleteConfirmData('')
                }
            })
        } catch (error) {

        }
    }

    const deleteTradeData = () => {

        try {
            deleteTrade(deleteConfirmData).then((res) => {

                if (res?.status === 200) {
                    setTradeListRun(true)
                    setDeleteConfirmData('')
                }
            })
        } catch (error) {

        }
    }

    const deleteTradeSystem = () => {
        try {
            deleteStrategy(deleteConfirmData).then((res) => {

                if (res?.status === 200) {
                    setRunAgainStrategyAPI(true)
                    setDeleteConfirmData('')
                }
            })
        } catch (error) {

        }
    }
    const deleteConfirm = () => {
        if (deleteConfirmData !== '' && deleteConfirmData !== undefined) {
            if (deleteType == 'trade') {
                deleteTradeData()
            } else if (deleteType == 'provider') {

                handleDeleteProvider()
            }
            else if (deleteType == 'strategy') {

                deleteTradeSystem()
            } else if (deleteType == 'feedback') {
                deletefeedbackData()
            } else if (deleteType == 'commission') {
                setDeleteConfirmCommi(true)
            } else if (deleteType == 'helpVideo') {
                deleteHelpVideo()
            }

        }
    }
    const HandleEdit = (value) => {

        setInputData({ providerId: value.providerId, providerName: value.providerName, parentCompany: value.parentCompany, yearAsCFDProvider: value.yearAsCFDProvider, platform: value.platform })
        setAddUpdateSelect('Update')
    }
    const handleAddProvider = () => {
        setInputData({ providerName: '', parentCompany: '', yearAsCFDProvider: '', platform: '' })
        setAddUpdateSelect('Add')
        setEditTrue(false)
    }
    const HandleAddCummission = (value) => {
        setInputCummission({ ...inputCummission, providerId: value.providerId })
    }
    const HandleViewCummission = (providerId) => {

        try {
            GetProviderCummission(providerId).then((res) => {
                if (res?.status === 200) {
                    setProviderCummission(res.data)
                }
            })
        } catch (error) {

        }
    }

    // Strategy start 
    useEffect(() => {
        let data = {
            id: userType?.userId,
            pageNumber: 1,
            pageSize: 1000
        }
        try {
            getStrategy(userType?.userId).then((res) => {
                if (res?.status === 200) {
                    // console.log(res.data ,"startegy data")
                    setStrategyList(res.data)
                }
            })
        } catch (error) {

        }

    }, [])
    // useEffect(() => {
    //     let data = {
    //         id: userType.role == 'user' ? userType?.userId : '0',
    //         pageNumber: currentStrPage,
    //         pageSize: pageStrSize
    //     }
    //     if (runAgainStrategyAPI == true) {
    //         try {
    //             getTradeSystemBYID(data).then((res) => {
    //                 if (res?.status === 200) {
    //                     setPaginationStr({ ...paginationStr, totalStrPages: res.data.totalPages })
    //                     setStrategyList(res.data.data)
    //                     setRunAgainStrategyAPI(false)
    //                 }
    //             })
    //         } catch (error) {

    //         }
    //     }

    // }, [runAgainStrategyAPI, currentStrPage])

    const handleEditStrategy = (value) => {
        setEditTrue(true)
        setSerategyInput({ UserId: userType?.userId, id: value.tradingSystemId, Name: value.name, Notes: value.notes, EntryStrategy: value.entryStrategy, ExitStrategy: value.exitStrategy, ScaleInOutStrategy: value.scaleInOutStrategy })
    }
    // strategy End

    //  Trade List start
    const handleGetTradeList = () => {
        let data = {
            id: userType?.userId,
            pageNumber: currentPage,
            pageSize: pageSize,
            search: search,
            type: typeShow
        }
        // console.log(data, "data")
        try {
            gettradeList(data).then((res) => {

                if (res?.status === 200) {
                    // console.log(res.data.data, "trdadelist from admin")
                    setTradeListRun(false)
                    setTradeList(res.data.data)
                    setPagination({ ...pagination, totalPages: res.data.totalPages })

                    // setPagination({...pagination,totalPages:res.data.totalPages})
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        if (search.length == 0 || search == '') {

            handleGetTradeList()
        }

    }, [tradeListRun == true, currentPage, pageNumber, pageSize, search, typeShow])
    const handleSearchInput = (e) => {

        // console.log(e.target.value, "search ")
        // console.log(pagination, "pagination before")
        setPagination({ ...pagination, [e.target.name]: e.target.value })
        // console.log(pagination ,"pagination after")
    }

    useEffect(() => {
        let clist = []
        for (let i = 0; i < tradeList.length; i++) {
            // 
            if (clist.length == 0) {
                // console.log(tradeList[i].country, "tradeList[i].country")
                clist.push(tradeList[i].country)
            } else {
                let nm = false
                for (let j = 0; j < clist.length; j++) {
                    if (tradeList[i].country.toUpperCase() == clist[j].toUpperCase()) {
                        nm = false
                        break;
                    } else {
                        nm = true
                    }
                }
                if (nm == true) {
                    clist.push(tradeList[i].country.toUpperCase())
                }
            }
        }

        let setCList = new Set(clist);
        setCountryList(clist)
    }, [tradeList])

    const handleDeleteTrade = (val) => {
        setDeleteConfirmData(val.tradeId)
        setDeleteType('trade')
        setDeleteMsg('delete this Trade')
    }
    const handleDeleteP = (val) => {
        setDeleteConfirmData(val)
        setDeleteType('provider')
        setDeleteMsg('delete this provider')
    }
    //  Trade List End

    // pagination
    const handlePageChange = (newPage) => {
        setPagination({ ...pagination, currentPage: currentPage + newPage })
        // Perform data fetching or other actions when page changes
    };

    const handleDeleteTredingSystem = (val) => {
        setDeleteMsg('delete this Trading System')
        setDeleteConfirmData(val)
        setDeleteType('strategy')
    }
    const feedbackhandler = () => {
        let data = {
            id: userType?.userId,
            pageNumber: 1,
            pageSize: 15,
            search: search,

        }
        try {
            getFeedBack(data).then((res) => {

                if (res?.status === 200) {

                    setgetfeedback(res.data.data)
                    setfeedbackPagination({ ...feedbackpagination, feedbacktotalPages: res.data.totalPages })
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {

        feedbackhandler()
        serGetfeedbackRun(false)
    }, [getfeedbackRun === true])

    const deletefeedbackData = () => {
        try {
            DeleteFeedBack(deleteConfirmData.feedbackId).then((res) => {

                if (res?.status === 200) {
                    serGetfeedbackRun(true)
                    setDeleteConfirmData('')
                }
            })
        } catch (error) {

        }
    }

    const handleDeletefeedback = (val) => {
        setDeleteConfirmData(val)
        setDeleteType('feedback')
        setDeleteMsg('delete this feedback')
    }
    const handleAction = (val) => {
        // console.log(val, "val")
        setEditTradeLineData(val)
        setActionEdit(true)
    }


    const twoDecimal = (value) => {

        var roundedNumber = value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 5) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 6);
            }
        }
        return roundedNumber
    }
    const handleChangeStockInput = (e) => {

        if (e.target.name == 'stopLoss') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                let n = e.target.value.split('.')
                var roundedNumber = twoDecimal(e.target.value);
                if (editTradeLineData?.short == 'buy') {
                    if (editTradeLineData?.entryPrice > Number(e.target.value)) {
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                if (e.target.value.length == 1) {
                                    setEditError({ ...editerror, stoplossErr: true })
                                } else {
                                    setEditError({ ...editerror, stoplossErr: false })
                                }
                                setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, closed: roundedNumber })
                            }

                        } else if (n.length == 1 && e.target.value.length <= 10) {
                            setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, closed: roundedNumber })
                            if (e.target.value.length == 0) {
                                setEditError({ ...editerror, stoplossErr: true })
                            } else {
                                setEditError({ ...editerror, stoplossErr: false })
                            }
                        }

                    } else {
                        toast.error('Set lower stop loss price than entry while buy trading')
                    }
                }
                else {
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            if (e.target.value.length == 1) {
                                setEditError({ ...editerror, stoplossErr: true })
                            } else {
                                setEditError({ ...editerror, stoplossErr: false })
                            }
                            setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, closed: roundedNumber })
                        }

                    } else if (n.length == 1 && e.target.value.length <= 10) {
                        setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber, closed: roundedNumber })
                        if (e.target.value.length == 0) {
                            setEditError({ ...editerror, stoplossErr: true })
                        } else {
                            setEditError({ ...editerror, stoplossErr: false })
                        }
                    }
                }
            }
        } else if (e.target.name == 'target') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                let n = e.target.value.split('.')
                var roundedNumber = twoDecimal(e.target.value);

                if (editTradeLineData?.short == 'sell') {
                    if (editTradeLineData?.entryPrice > Number(e.target.value)) {
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                if (e.target.value.length == 0) {
                                    setEditError({ ...editerror, tragerErr: true })
                                } else {
                                    setEditError({ ...editerror, tragerErr: false })
                                }
                                setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                            }
                        } else if (n.length == 1 && e.target.value.length <= 10) {
                            if (e.target.value.length == 0) {
                                setEditError({ ...editerror, tragerErr: true })
                            } else {
                                setEditError({ ...editerror, tragerErr: false })
                            }
                            setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                        }
                    } else {
                        toast.error('Set lower target price than entry while sell trading')
                    }
                } else {
                    setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                    if (e.target.value.length > 0) {
                        setEditError({ ...editerror, tragerErr: false })
                    } else {
                        setEditError({ ...editerror, tragerErr: true })
                    }
                }
            }
        } else if (e.target.name == 'tradingSystemId' || e.target.name == 'action') {
            // console.log(e.target.value ,"dd")
            const selectedTradingSystemId = parseInt(e.target.value, 10);
            const strategyname = strategyList.find(
                (strategy) => strategy.tradingSystemId === selectedTradingSystemId
            );
            // console.log(strategyname.name, "strategy name");
            // console.log(editTradeLineData ,"editTradeLineData")
            // setEditTradeLineData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
            setEditTradeLineData({ ...editTradeLineData, [e.target.name]: e.target.value })
            setTimeout(() => {
                // console.log("selected tradingSystemId: , name", editTradeLineData?.tradingSystemId  ,editTradeLineData?.strategy );
            }, 0)
        } else if (e.target.name == 'entryPrice') {
            let n = e.target.value.split('.')

            var roundedNumber = twoDecimal(e.target.value);
            if (n.length == 2) {
                if (n[0].length <= 10) {
                    setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                    if (e.target.value.length == 1) {
                        setEditError({ ...editerror, entryErr: true })
                    } else {
                        setEditError({ ...editerror, entryErr: false })
                    }
                }
            } else if (n.length == 1 && e.target.value.length <= 10) {
                setEditTradeLineData({ ...editTradeLineData, [e.target.name]: roundedNumber })
                if (e.target.value.length == 0) {
                    setEditError({ ...editerror, entryErr: true })
                } else {
                    setEditError({ ...editerror, entryErr: false })
                }
            }
        }
    }
    const [isLoading, setIsLoading] = useState(false);
    // const handleUpdateAction = () => {

    //     let apiData = {}
    //     if (editTradeLineData?.entryPrice && editTradeLineData?.stopLoss && editTradeLineData?.target) {
    //         setIsLoading(true);
    //         // console.log(editTradeLineData ,"editTradeLineData")
    //         try {
    //             // setEditTradeLineData(prevState => ({ ...prevState })); 
    //             editTrade(editTradeLineData).then((res) => {

    //                 if (res?.status === 200) {

    //                     setActionEdit(false)
    //                     setEditTradeLineData({})
    //                     handleGetTradeList()
    //                 }
    //             })
    //         } catch (error) {

    //         }
    //         finally {
    //             setIsLoading(false);
    //           }
    //     } else {
    //         setEditError({ ...editerror, entryErr: !editTradeLineData?.entryPrice ? true : false, stoplossErr: !editTradeLineData?.stopLoss ? true : false, tragerErr: !editTradeLineData?.target ? true : false })
    //     }
    // }
    const handleUpdateAction = async () => {
        let apiData = {};
        if (editTradeLineData?.entryPrice && editTradeLineData?.stopLoss && editTradeLineData?.target) {
            setIsLoading(true);

            try {
                const res = await editTrade(editTradeLineData);

                if (res?.status === 200) {
                    setActionEdit(false);
                    setEditTradeLineData({});
                    handleGetTradeList();
                }
            } catch (error) {
                // Handle errors if needed
            } finally {
                setIsLoading(false);
            }
        } else {
            setEditError({
                ...editerror,
                entryErr: !editTradeLineData?.entryPrice,
                stoplossErr: !editTradeLineData?.stopLoss,
                tragerErr: !editTradeLineData?.target,
            });
        }
    };
    const handleCheckValidation = (e) => {

        if (Number(e.target.value) >= 0) {
            if (editTradeLineData.entryPrice !== '') {
                if (e.target.name == 'target') {
                    if (editTradeLineData.short === 'buy') {
                        if (Number(editTradeLineData.entryPrice) > Number(e.target.value)) {
                            setEditError({ ...editerror, tragerErr: true })

                            toast.error('Set higher target price than entry while buy trading')
                        }
                    }
                } else {
                    if (editTradeLineData.short === 'sell') {
                        if (Number(editTradeLineData.entryPrice) >= Number(e.target.value)) {
                            setEditError({ ...editerror, stoplossErr: true })
                            toast.error('Set higher stop loss price than entry while sell trading')

                        }
                    }
                }
            }
        }
    }
    const hnadleChangeDirection = (val) => {
        setEditTradeLineData({ ...editTradeLineData, short: val, stopLoss: '', target: '' })
        setEditError({ entryErr: false, tragerErr: false, stoplossErr: false })
    }

    const helpVideohandlePageChange = (newPage) => {

        setHelpVideoPagination({ ...helpVideopagination, helpVideocurrentPage: helpVideocurrentPage + newPage })
        // Perform data fetching or other actions when page changes
    };

    const getHelpVideo = () => {
        let pageData = {
            pageNumber: helpVideocurrentPage,
            pageSize: helpVideopageSize
        }

        try {
            GetHelpVideos(pageData).then((res) => {
                if (res?.status === 200) {
                    setHelpVideoList(res.data.data)

                    setHelpVideoPagination({ ...helpVideopagination, helpVideototalPages: res.data.totalPages })
                    // console.log(res.data.data)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getHelpVideo()
    }, [helpVideocurrentPage])
    const handleEditHelpVideo = (value) => {
        // console.log(value , "value")
        setAddUpdateVideoSelect('edit')
        // setInputVideo({...value,helpNeme:`https://api.trading.tradinglounge.com/${value.helpNeme}`,reRenderV:1 })
        setInputVideo({ ...value, helpNeme: `${value.helpNeme}`, reRenderV: 1 })
        // const [inputVideo, setInputVideo] = useState({UserId:'', ModuleNeme: '', HelpNeme: '', Description: '', videoFile: '',reRenderV:1 })
    }
    const deleteHelpVideo = () => {
        try {
            deleteHelpVideoAPI(deleteConfirmData.tradingHelpId).then((res) => {
                if (res?.status === 200) {
                    getHelpVideo()
                }
            })
        } catch (error) {

        }
    }
    const handleDeleteHelpVideo = (val) => {

        setDeleteConfirmData(val)
        setDeleteType('helpVideo')
        setDeleteMsg('delete this Video')

    }
    return (
        <>
            <div class="row">
                <section>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="postion-bx-min mt-4">
                                    {/* {
										countryList?.map((value, index) => {
											return (
												<span class={countryShow.toUpperCase() === value.toUpperCase() ? "frm-hd-t me-1 pe-auto" : "frm-hd-t me-1 country-Active pe-auto"} onClick={() => { setCountryShow(value.toUpperCase()) }}>{value}</span>
											)
										})
									} */}
                                    <div class="postion-frm mt-2 table-input1" style={{ "border-top": "1px solid #cbd0dd" }}>
                                        {/* <span class="padding-ds">Pending Positions</span>
                                        <span class="padding-ds">Open Positions</span>
                                        <span class="padding-ds">Watchlist</span> */}
                                        <div class="col-sm-12 tabs-sec">
                                            <ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                                                <li><button class="nav-link active color-thumb" id="pills-profile-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                                    aria-selected="false" onClick={() => setShowSearchInput(false)}><span >Providers</span></button></li>
                                                <li ><button class="nav-link  color-thumb" id="pills-profile-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-strategy" type="button" role="tab" aria-controls="pills-home"
                                                    aria-selected="false" onClick={() => setShowSearchInput(false)}><span >Trading System</span></button></li>

                                                <li><button class="nav-link color-thumb" id="pills-Trade-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-Trade" type="button" role="tab" aria-controls="pills-Trade"
                                                    aria-selected="false" onClick={() => setShowSearchInput(true)}><span >Trades</span></button></li>
                                                <li><button class="nav-link color-thumb" id="pills-Trade-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-feedback" type="button" role="tab" aria-controls="pills-feedback"
                                                    aria-selected="false" onClick={() => setShowSearchInput(false)}><span >Feedbacks</span></button></li>
                                                <li><button class="nav-link color-thumb" id="pills-help-video-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-help-video" type="button" role="tab" aria-controls="pills-feedback"
                                                    aria-selected="false" onClick={() => setShowSearchInput(false)}><span >Help Videos</span></button></li>
                                                {
                                                    showSearchInput &&

                                                    <li><button class="nav-link search-input"><input name='search' value={search} className='search-input-main' onKeyUp={(e) => e.key == 'Enter' && handleGetTradeList()} onChange={handleSearchInput} placeholder='search by code...' />
                                                        <span ><BiSearchAlt onClick={handleGetTradeList} /></span>
                                                    </button></li>
                                                }
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-profile" role="tabpanel"
                                                    aria-labelledby="pills-profile-tab">
                                                    <div className='d-md-flex d-sm-block'>
                                                        <button class="btn  ms-auto d-block " data-bs-toggle="modal" data-bs-target="#providerList">Providers Stock List</button>
                                                        <button class="btn  ms-md-1 ms-sm-auto d-block " onClick={handleAddProvider} data-bs-toggle="modal" data-bs-target="#addProvider">Add Provider <span> <i class="bi bi-plus-lg"></i> </span></button>
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="adm-pr1 color-grey"><b>Provider</b></th>
                                                                    <th scope="col" class="adm-pr2 color-grey"><b>Parent Company</b></th>
                                                                    <th scope="col" class="adm-pr3 color-grey"><b>Years As CFD Provider</b></th>
                                                                    <th scope="col" class="adm-pr4 color-grey"><b>Platforms</b></th>
                                                                    <th scope="col" class="adm-pr4 color-grey"><b>Commission Details</b></th>
                                                                    <th scope="col" class="adm-pr4 color-grey"><b>Commission</b></th>
                                                                    <th class="border-end-0 color-grey">Edit</th>
                                                                    <th className='color-grey'>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    providerDataList.map((val, ind) => {
                                                                        return (
                                                                            <tr key={ind}>

                                                                                <th scope="row" class="border-end-0">{val.providerName}</th>
                                                                                <td>{val.parentCompany}</td>
                                                                                <td>{val.yearAsCFDProvider}</td>
                                                                                <td>{val.platform}</td>
                                                                                <td><button class="btn padding-btn-dtd edit-btn-adm" title="Add" data-bs-toggle="modal" data-bs-target="#cummissionDetails" onClick={() => HandleViewCummission(val?.providerId)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />View</button></td>
                                                                                <td><button class="btn padding-btn-dtd edit-btn-adm" title="Add" data-bs-toggle="modal" data-bs-target="#addCummission" onClick={() => HandleAddCummission(val)}> <img src="assets/image/edit.png" class="img-fluid" /> Add</button></td>
                                                                                <td><button class="btn padding-btn-dtd edit-btn-adm" title="Edit" data-bs-toggle="modal" data-bs-target="#addProvider" onClick={() => HandleEdit(val)}> <img src="assets/image/edit.png" class="img-fluid" /> Edit</button></td>
                                                                                <td><button class="btn padding-btn-dtd delete-admin" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeleteP(val)}> <i class="bi bi-trash3-fill"></i> </button></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-strategy" role="tabpanel"
                                                    aria-labelledby="pills-profile-tab">
                                                    <div className='d-flex'>
                                                        <button class="btn  ms-auto d-block " onClick={handleAddProvider} data-bs-toggle="modal" data-bs-target="#addEditStrategy">Add Strategy <span> <i class="bi bi-plus-lg"></i> </span></button>
                                                    </div>
                                                    <div class="table-responsive text-center">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr class="border-bottom-0">
                                                                    <th scope="row" class="ad-tab-b color-grey ">Name</th>
                                                                    <th scope="row" class="ad-tab-b color-grey ">Entry Strategy </th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Exit Strategy</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Scale In/Out Strategy</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Comments</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Edit</th>
                                                                    <th className='color-grey'>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    strategyList?.map((val, ind) => {
                                                                        return (
                                                                            <tr key={ind}>
                                                                                <th> &nbsp;{val.name} </th>
                                                                                <td> &nbsp;{val.entryStrategy} </td>
                                                                                <td> &nbsp;{val.exitStrategy} </td>
                                                                                <td> &nbsp;{val.scaleInOutStrategy} </td>
                                                                                <td> &nbsp;{val.notes} </td>
                                                                                <td onClick={() => handleEditStrategy(val)}>
                                                                                    <button class="btn padding-btn-dtd edit-btn-adm" data-bs-toggle="modal" data-bs-target="#addEditStrategy" title="Edit">
                                                                                        <img src="assets/image/edit1.png" class="img-fluid" />  </button>
                                                                                </td>
                                                                                <td><button class="btn padding-btn-dtd delete-admin" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeleteTredingSystem(val)}> <i class="bi bi-trash3-fill"></i> </button></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>

                                                        <Pagination
                                                            currentPage={currentStrPage}
                                                            totalPages={totalStrPages}
                                                            onPageChange={handlePageChangeStr}
                                                            setPagination={setPaginationStr}
                                                            pageNumber={pageStrNumber}
                                                            pageSize={pageStrSize}
                                                            pagination={paginationStr}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade " id="pills-Trade" role="tabpanel"
                                                    aria-labelledby="pills-Trade-tab">
                                                    <div className='d-flex admin-country'>

                                                        <div className='country-width'>

                                                            {/* <span class={typeShow === 'TRADES' ? "frm-hd-t me-1 pe-auto admin-country-w-h" : "frm-hd-t me-1 country-Active pe-auto admin-country-w-h"} onClick={() => { setTypeShow('TRADES') }}>TRADES</span> */}
                                                            {/* <span class={typeShow === 'TRENDS' ? "frm-hd-t me-1 pe-auto admin-country-w-h" : "frm-hd-t me-1 country-Active pe-auto admin-country-w-h"} onClick={() => { setTypeShow('TRENDS') }}>TRENDS</span>
                                                            <span class={typeShow === 'HODL' ? "frm-hd-t me-1 pe-auto admin-country-w-h" : "frm-hd-t me-1 country-Active pe-auto admin-country-w-h"} onClick={() => { setTypeShow('HODL') }}>HODL</span> */}

                                                        </div>

                                                        <button class="btn  ms-auto d-block" onClick={handleAddProvider} data-bs-toggle="modal" data-bs-target="#createTrade">Create Trade <span> <i class="bi bi-plus-lg"></i> </span></button>
                                                    </div>
                                                    <div class="table-responsive text-center">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='color-grey'>Date</th>
                                                                    <th scope="col" className='color-grey'>Code</th>
                                                                    {/* <th scope="col" className='color-grey'>Strategy</th> */}
                                                                    <th scope="col" className='color-grey'>Direction</th>
                                                                    <th scope="col" className='color-grey'>Entry</th>
                                                                    <th scope="col" className='color-grey'>Stop</th>
                                                                    <th scope="col" className='color-grey'>Target </th>
                                                                    <th scope="col" className='color-grey'>strategy</th>
                                                                    <th scope="col" className='color-grey'>Actions</th>
                                                                    {/* <th scope="col" className='color-grey'>Closed</th> */}
                                                                    {/* <th scope="col" className='color-grey'>Trade This</th> */}
                                                                    <th scope="col" className='color-grey'>Edit</th>
                                                                    <th scope="col" className='color-grey'>Image</th>
                                                                    <th scope="col" className='color-grey'>Delete</th>
                                                                    <th scope="col" className='color-grey'>View</th>
                                                                    {/* <th scope="col" className='color-grey'>Watchlist</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    tradeList.map((val, ind) => {
                                                                        // console.log(val, "val trade")
                                                                        let stockArr = val.stockCode
                                                                        return (

                                                                            <tr key={ind}>
                                                                                <th scope="row">{moment(val.tradeDate).format('DD MMM YYYY')}</th>
                                                                                <td
                                                                                    className="tooltip g"
                                                                                    data-title={tooltipContent}
                                                                                    onMouseOver={() => handleMouseOver(val.stockName)}
                                                                                    onMouseOut={handleMouseOut}
                                                                                >
                                                                                    {stockArr}
                                                                                </td>
                                                                                
                                                                                {
                                                                                    (actionEdit && editTradeLineData.tradeId == val.tradeId) ?
                                                                                        <>
                                                                                            <td class="text-center">
                                                                                                <nav className="menu">
                                                                                                    <Link to="#" className={editTradeLineData.short == 'buy' ? "menu-link menu-active py-0px" : "menu-link py-0px"} onClick={() => hnadleChangeDirection('buy')} data-menu="0">Buy</Link>
                                                                                                    <Link to="#" className={editTradeLineData.short == 'sell' ? "menu-link menu-active py-0px" : "menu-link py-0px"} onClick={() => hnadleChangeDirection('sell')} data-menu="3">Sell</Link>
                                                                                                </nav>
                                                                                            </td>
                                                                                            <td class="text-center"><input className={actionEdit == true && entryErr == true ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='entryPrice' value={editTradeLineData?.entryPrice} onChange={handleChangeStockInput} /></td>
                                                                                            <td class="text-center"><input className={actionEdit == true && stoplossErr == true ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} onBlur={handleCheckValidation} name='stopLoss' value={editTradeLineData?.stopLoss} onChange={handleChangeStockInput} /></td>
                                                                                            <td class="text-center"><input className={actionEdit == true && tragerErr == true ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} onBlur={handleCheckValidation} name='target' value={editTradeLineData?.target} onChange={handleChangeStockInput} /></td>
                                                                                            <td class="text-center">
                                                                                                <select className={actionEdit == true && editTradeLineData?.tradingSystemId == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='tradingSystemId' value={editTradeLineData?.tradingSystemId} onChange={handleChangeStockInput} >
                                                                                                    {
                                                                                                        strategyList.map((value, ind) => {
                                                                                                            // console.log(strategyList, "list")

                                                                                                            return (
                                                                                                                <option key={ind} value={value.tradingSystemId}>{value.name}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                                {/* <input className={actionEdit == true && editTradeLineData?.tradingSystemId == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='target' value={editTradeLineData.tradingSystemId} onChange={handleChangeStockInput} /> */}
                                                                                            </td>
                                                                                            <td class="text-center"><input className={"know-commper-bx-min w-100"} name='action' value={editTradeLineData?.action} onChange={handleChangeStockInput} /></td>
                                                                                            <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" onClick={() => handleUpdateAction(val)} disabled={isLoading}> Save </button> </td>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <td>{val.short}</td>
                                                                                            <td>$ {val.entryPrice}</td>
                                                                                            <td>$ {val.stopLoss}</td>
                                                                                            <td>$ {val.target}</td>
                                                                                            {/* <td>{val?.tradingSystems?.name}</td> */}
                                                                                            <td>{val?.strategy}</td>
                                                                                            <td>{val?.action}</td>
                                                                                            <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" onClick={() => handleAction(val)}> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td>
                                                                                        </>
                                                                                }
                                                                                <td><button class="btn padding-btn-dtd open-btn w-100" data-bs-toggle="modal" title='View Image' data-bs-target="#viewImage" onClick={() => setChartImg(val.chartFile)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button> </td>
                                                                                <td><button class="btn padding-btn-dtd delete-btn w-100 delete-admin" title='Delete' data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeleteTrade(val)}>  <i class="bi bi-trash3-fill"></i>  </button> </td>
                                                                                <td><button class="btn padding-btn-dtd open-btn w-100" data-bs-toggle="modal" title='View Chart' data-bs-target="#StockView" onClick={() => setTradeCode(val.stockCode)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button> </td>
                                                                            </tr>
                                                                        )

                                                                    })
                                                                }


                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            currentPage={currentPage}
                                                            totalPages={totalPages}
                                                            onPageChange={handlePageChange}
                                                            setPagination={setPagination}
                                                            pageNumber={pageNumber}
                                                            pageSize={pageSize}
                                                            pagination={pagination}
                                                        />
                                                    </div>
                                                </div>

                                                <div class="tab-pane fade " id="pills-feedback" role="tabpanel"
                                                    aria-labelledby="pills-feedback-tab">
                                                    {/* <div className='d-flex admin-country'>
                                                        {
                                                            countryList.length > 0 &&
                                                            <div className='country-width'>
                                                                {
                                                                    countryList?.map((value, index) => {
                                                                        return (
                                                                            <span key={index} class={countryShow?.toUpperCase() === value?.toUpperCase() ? "frm-hd-t me-1 pe-auto admin-country-w-h" : "frm-hd-t me-1 country-Active pe-auto admin-country-w-h"} onClick={() =>handleChangeCountry(value)}>{value}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }

                                                    </div> */}
                                                    <div class="table-responsive text-center">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='color-grey'>UserId</th>
                                                                    <th scope="col" className='color-grey'>Email</th>
                                                                    <th scope="col" className='color-grey'>Subject</th>
                                                                    <th scope="col" className='color-grey'>Message</th>
                                                                    <th scope="col" className='color-grey'>Delete</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    getfeedback.map((val, ind) => {
                                                                        return (
                                                                            <tr key={ind}>
                                                                                <th scope="row">{val.userId}</th>
                                                                                <td>{val.email}</td>
                                                                                <td>{val.subject}</td>

                                                                                <td>{val.description}</td>

                                                                                <td><button class="btn padding-btn-dtd delete-btn w-100 delete-admin" title='Delete' data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeletefeedback(val)}>  <i class="bi bi-trash3-fill"></i>  </button> </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            currentPage={feedbackcurrentPage}
                                                            totalPages={feedbacktotalPages}
                                                            onPageChange={feedbackhandlePageChange}
                                                            setPagination={setfeedbackPagination}
                                                            pageNumber={feedbackpageNumber}
                                                            pageSize={feedbackpageSize}
                                                            pagination={feedbackpagination}
                                                        />
                                                    </div>
                                                </div>

                                                <div class="tab-pane fade " id="pills-help-video" role="tabpanel"
                                                    aria-labelledby="pills-help-video-tab">
                                                    <div className='d-flex admin-country'>
                                                        <button class="btn  ms-auto d-block" onClick={() => setAddUpdateVideoSelect('add')} data-bs-toggle="modal" data-bs-target="#addHelpVideo">Upload Video <span> <i class="bi bi-plus-lg"></i> </span></button>
                                                    </div>
                                                    <div class="table-responsive text-center">
                                                        <table class="table table-bordered mt-3 text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='color-grey'>Name</th>
                                                                    <th scope="col" className='color-grey'>Description</th>
                                                                    {/* <th scope="col" className='color-grey'>video</th> */}
                                                                    <th scope="col" className='color-grey'>Edit</th>
                                                                    <th scope="col" className='color-grey'>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    helpVideoList.map((val, ind) => {
                                                                        {/* console.log(val , "val") */ }
                                                                        return (


                                                                            <tr key={ind}>
                                                                                <td>{val.moduleNeme}</td>
                                                                                <td>{val.description}</td>
                                                                                {/* <td>{val.helpNeme}</td> */}
                                                                                <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" onClick={() => handleEditHelpVideo(val)} data-bs-toggle="modal" data-bs-target="#addHelpVideo" > <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td>
                                                                                <td><button class="btn padding-btn-dtd delete-btn w-100 delete-admin" onClick={() => handleDeleteHelpVideo(val)} title='Delete' data-bs-toggle="modal" data-bs-target="#deleteModel">  <i class="bi bi-trash3-fill"></i>  </button> </td>
                                                                            </tr>
                                                                        )

                                                                    })
                                                                }


                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            currentPage={helpVideocurrentPage}
                                                            totalPages={helpVideototalPages}
                                                            onPageChange={helpVideohandlePageChange}
                                                            setPagination={setHelpVideoPagination}
                                                            pageNumber={helpVideopageNumber}
                                                            pageSize={helpVideopageSize}
                                                            pagination={helpVideopagination}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ViewImage chartImg={chartImg} />
            <CummissionDetails setDeleteConfirmCommi={setDeleteConfirmCommi} deleteConfirmCommi={deleteConfirmCommi} deleteType={deleteType} cummissionData={providerCummission} setDeleteMsg={setDeleteMsg} setDeleteType={setDeleteType} setDeleteConfirmData={setDeleteConfirmData} HandleViewCummission={HandleViewCummission} />
            <TradeModel tradeCode={tradeCode} />
            <DeleteConfirmation type={deleteMsg} deleteConfirm={deleteConfirm} />
            <ProviderModel providerDataList={providerDataList} />
            <AddCummission inputCummission={inputCummission} setInputCummission={setInputCummission} />
            <AddUpdateModel inputData={inputData} setInputData={setInputData} addUpdateSelect={addUpdateSelect} serGetProviderRun={serGetProviderRun} />
            <AddEditStrategy userType={userType} setRunAgainStrategyAPI={setRunAgainStrategyAPI} setSerategyInput={setSerategyInput} strategyInput={strategyInput} editTrue={editTrue} />
            <CreateTrade setTradeListRun={setTradeListRun} strategyList={strategyList} providerList={providerDataList} />
            <EditTrade setTradeListRun={setTradeListRun} strategyList={strategyList} providerList={providerDataList} tradeData={tradeData} setTradeData={setTradeData} />
            <AddEditVideo getHelpVideo={getHelpVideo} inputVideo={inputVideo} setInputVideo={setInputVideo} addUpdateVideoSelect={addUpdateVideoSelect} />

        </>

    )
}

export default AdminTable