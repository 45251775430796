import React, { useEffect, useState } from 'react'
import { getStockBalanceSheet } from '../../../../services/TradeService'
import approximateNumber from 'approximate-number'
import NumberConverter from '../../../commonComponent/NumberConverter'

const StockBalanceSheet = ({ typeData, tradeCode }) => {
	const [balanceData, setBalanceData] = useState([])
	const [balanceDataStatus, setBalanceDataStatus] = useState(false)
	const [mapedData, setMapedData] = useState([])
	useEffect(() => {
		if (typeData === 'BalanceSheet') {

			try {
				getStockBalanceSheet(tradeCode).then((res) => {

					setBalanceData(res.data)
					setBalanceDataStatus(true)
				})
			} catch (error) {

			}
		}
	}, [typeData])
	useEffect(() => {
		if (balanceData.length > 0) {
			let a = []
			for (let i = 0; i < 5; i++) {
				let n = []
				const result = balanceData.filter((arrayData) => {
					if (arrayData.fiscalYear === balanceData[0].fiscalYear - i) {
						return arrayData
					}
				})
				for (let j = 0; j < result.length; j++) {
					// if (result[j].fiscalYear === 2023) {
					// 	

					// 		capital: true,
					// 		decimal: '.',
					// 		// round: true
					// 	}))
					// }

					n = {
						"fiscalYear": result[j].fiscalYear,
						"totalAssets": n?.totalAssets || 0 + result[j].totalAssets,
						"totalLiabilities": n?.totalLiabilities || 0 + result[j].totalLiabilities,
						"totalCurrentLiabilities": n?.totalCurrentLiabilities || 0 + result[j].totalCurrentLiabilities,
						"currentAssets": n?.currentAssets || 0 + result[j].currentAssets,
						"fiscalQuarter": n?.fiscalQuarter || 0 + result[j].fiscalQuarter,
						"netBorrowings": n?.netBorrowings || 0 + result[j].netBorrowings,
						"netIncome": n?.netIncome || 0 + result[j].netIncome
					}
				}

				a.push(n)
				// 
			}
			setMapedData(a)
		}
	}, [balanceData])
	return (
		<>
			<div class="tab-pane fade" id="pills-balancesheet" role="tabpanel"
				aria-labelledby="pills-balancesheet-tab">

				<div class="row">

					<div className='d-flex'>
						<h4 class="mt-4 mb-4">Balance Sheet</h4>
						<h6 class="mt-4 mb-4 ml-auto">(USD)</h6>
					</div>
					<div class="col-sm-12">
						{
							balanceDataStatus == false ? <div class="container-fluid">
								<div class="container">
									<div class="row">
										<div class="col-md-12">
											<div class="preloader1">
												{/* <div class="loader loader-inner-1"> */}
												<div class="loader loader-inner-2">
													<div class="loader loader-inner-3">
													</div>
												</div>
												{/* </div> */}
											</div>
										</div>
									</div>
								</div>
							</div> :

								balanceData.length >0 ?
									<div class="Fundamentals-bx">
										<div class="table-responsive">
											<div class="table-input1">
												<table class="table ">

													<tbody>
														<tr>
															<th scope="row" class="border-end col-lg-4">
																Financial Year </th>
															{
																mapedData?.map((val, ind) => {
																	return (
																		<td key={ind} class="text-end ">FY {val.fiscalYear} </td>
																	)
																})
															}
														</tr>
														<tr>
															<th scope="row" class="border-end col-lg-4">Total Assets </th>
															{

																mapedData?.map((val, ind) => {
																	return (
																		<td key={ind} class="text-end ">$ {NumberConverter(val.totalAssets)} </td>
																	)
																})

															}
														</tr>
														<tr>
															<th scope="row" class="border-end col-lg-4">Current Assets </th>
															{

																mapedData?.map((val, ind) => {
																	return (
																		<td key={ind} class="text-end ">$ {NumberConverter(val.currentAssets)} </td>
																	)
																})

															}
														</tr>
														<tr>
															<th scope="row" class="border-end col-lg-4">Total Liabilities</th>
															{

																mapedData?.map((val, ind) => {
																	return (
																		<td key={ind} class="text-end ">$ {NumberConverter(val.totalLiabilities)} </td>
																	)
																})

															}
														</tr>




														<tr>
															<th scope="row" class="border-end col-lg-4">Current Liabilities </th>
															{

																mapedData?.map((val, ind) => {
																	return (
																		<td key={ind} class="text-end ">$ {NumberConverter(val.totalCurrentLiabilities)} </td>
																	)
																})

															}
														</tr>
													</tbody>

												</table>
											</div>
										</div>
									</div> : <div className='w-100 text-center'>
										<h4 className='no-data'>Data Not Available</h4>
									</div>


						}

					</div>
				</div>

			</div>
		</>
	)
}

export default StockBalanceSheet