import React from 'react'
import SideBar from '../components/commonComponent/SideBar'
import Footer from '../components/commonComponent/Footer'
import Header from '../components/commonComponent/Header'

const TradeCheck = () => {
  return (
    <>
            <section>

                <div className="container-fluid">

                    <div className="row">
                        <SideBar/>
                        {/* <!--------ul-----clouse----> */}
                        <div className="col-lg-10 mt-5">
                            <Header/>
                            {/* <!--------search-bar----> */}
                            
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer/> */}
           
        </>
  )
}

export default TradeCheck