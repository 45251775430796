import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Loader from '../components/commonComponent/Loader';

const CandleChartView = () => {
    const [chartLoader, setChartLoader] = useState(true)
    const chartRef = useRef()
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const stockCode = params.get('tradeCode');
    // let stockCode = location.state?.tradeCode
    let tvScriptLoadingPromise;

    useEffect(
        () => {
            chartRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => chartRef.current && chartRef.current());
            const pineScriptCode = `
            //@version=5
            indicator("Simple Moving Average", overlay=true, shorttitle="SMA")
            length = input(14, minval=1, title="Length")
            src = input(close, title="Source")
            sma = sma(src, length)
            plot(sma, title="SMA", color=color.blue)
          `;


            function createWidget() {
                if (document.getElementById('tradingview_ac040') && 'TradingView' in window) {
                    setChartLoader(false)
                    new window.TradingView.widget({
                        autosize: true,
                        symbol: stockCode+'USD',
                        timezone: "Etc/UTC",
                        theme: "light",
                        style: "1",
                        locale: "en",
                        toolbar_bg: "#f1f3f6",
                        // enable_publishing: true,
                        // withdateranges: true,
                        range: "YTD",
                        hide_side_toolbar: false,
                        allow_symbol_change: true,
                        // hide_legend: true,
                        details: true,
                        // studies: [
                        //     "ElliottWave@tv-basicstudies",
                        // ],
                        show_popup_button: true,
                        container_id: "tradingview_ac040",
                        // overrides: {
                        //     'paneProperties.background': '#131722',
                        //     'paneProperties.vertGridProperties.color': '#363c4e',
                        //     'paneProperties.horzGridProperties.color': '#363c4e',
                        //     'symbolWatermarkProperties.transparency': 90,
                        //     'scalesProperties.textColor': '#AAA',
                        //     'mainSeriesProperties.candleStyle.wickUpColor': '#336854',
                        //     'mainSeriesProperties.candleStyle.wickDownColor': '#7f323f',
                        //   },
                    });
                    // return () => {
                    //     widget.remove();
                    // };
                }
            }
            // document.querySelector('.tv-header__link').remove();
        }, [stockCode]);
    const handleClose = () => {
        window.close()
    }
    setTimeout(() => {

    }, 3000)
    let height = window.screen.height;
    return (
        <>

            <div className='container-fluid m-0 p-0 position-relative'>
                {/* <Link to={'/'} > */}
                <button type="button" onClick={handleClose} className="btn-close btn-close-provider btn-close-candle" data-bs-dismiss="modal" aria-label="Close">×</button>
                {/* </Link> */}
                {/* <div className="row">
                    <div class="row">
                        <div class="cp-tail-bx cp-tail-bx-asd">
                            <div class="col-sm-12 mt-4 d-flex  md-2">
                                <h1>{stockData[0]?.symbol}</h1>
                                <h5 className=''>{stockData[0]?.close || 0} <span className={increased ? 'green' : 'red'}>{Number(profitLoss ? profitLoss : 0).toFixed(2)} ( {Number(profitPresent ? profitPresent : 0).toFixed(2)}% )</span><span class="id ms-1">{stockData[0]?.priceDate}</span> </h5>
                            </div>
                        </div>
                    </div>
                    <div class="bot-brd "></div>

                </div> */}


                {chartLoader &&
                    <div className='chartLoader'>
                        <Loader />
                    </div>
                }
                <div className='tradingview-widget-container'>
                    <div id='tradingview_ac040' style={{ 'height': `${height}px` }} className='tradingview_5327b-css w-100' />
                    <p ref={chartRef}></p>
                </div>

            </div>
            {/* <div className="tradingview-widget-container" ref={contariner}>
                <div className="tradingview-widget-container__widget"></div>
                <div className="tradingview-widget-copyright"></div>
            </div> */}

        </>
    )
}

export default CandleChartView