export const PriceData =[
  {
    "time": "18-09-2023",
    "open": 145.77,
    "high": 146.48,
    "low": 145.06,
    "close": 145.09,
    "volume": 2508062
  },
  {
    "time": "15-09-2023",
    "open": 147.11,
    "high": 147.85,
    "low": 145.53,
    "close": 145.99,
    "volume": 6234033
  },
  {
    "time": "14-09-2023",
    "open": 147.38,
    "high": 147.73,
    "low": 146.48,
    "close": 147.35,
    "volume": 2723200
  },
  {
    "time": "13-09-2023",
    "open": 145.95,
    "high": 146.98,
    "low": 145.92,
    "close": 146.55,
    "volume": 2627999
  },
  {
    "time": "12-09-2023",
    "open": 147.92,
    "high": 148,
    "low": 145.8,
    "close": 146.3,
    "volume": 4457695
  },
  {
    "time": "11-09-2023",
    "open": 148.57,
    "high": 148.78,
    "low": 147.58,
    "close": 148.38,
    "volume": 3273720
  },
  {
    "time": "08-09-2023",
    "open": 147.35,
    "high": 148.59,
    "low": 147.26,
    "close": 147.68,
    "volume": 3722927
  },
  {
    "time": "07-09-2023",
    "open": 148.13,
    "high": 148.78,
    "low": 147.4,
    "close": 147.52,
    "volume": 3333040
  },
  {
    "time": "06-09-2023",
    "open": 147.66,
    "high": 148.33,
    "low": 147.12,
    "close": 148.06,
    "volume": 2932203
  },
  {
    "time": "05-09-2023",
    "open": 147.91,
    "high": 149,
    "low": 147.5719,
    "close": 148.13,
    "volume": 3731281
  },
  {
    "time": "01-09-2023",
    "open": 147.26,
    "high": 148.1,
    "low": 146.92,
    "close": 147.94,
    "volume": 2727796
  },
  {
    "time": "31-08-2023",
    "open": 146.94,
    "high": 147.7275,
    "low": 146.54,
    "close": 146.83,
    "volume": 3885949
  },
  {
    "time": "30-08-2023",
    "open": 146.42,
    "high": 146.92,
    "low": 145.7452,
    "close": 146.86,
    "volume": 2245402
  },
  {
    "time": "29-08-2023",
    "open": 146.3,
    "high": 146.73,
    "low": 145.62,
    "close": 146.45,
    "volume": 2778113
  },
  {
    "time": "28-08-2023",
    "open": 145.41,
    "high": 146.74,
    "low": 145.21,
    "close": 146.02,
    "volume": 3561347
  },
  {
    "time": "25-08-2023",
    "open": 144.18,
    "high": 145.47,
    "low": 143.5,
    "close": 145.35,
    "volume": 3660147
  },
  {
    "time": "24-08-2023",
    "open": 143.505,
    "high": 144.47,
    "low": 143.22,
    "close": 143.55,
    "volume": 2900244
  },
  {
    "time": "23-08-2023",
    "open": 141.72,
    "high": 143.475,
    "low": 141.58,
    "close": 143.41,
    "volume": 2559083
  },
  {
    "time": "22-08-2023",
    "open": 142.66,
    "high": 143.225,
    "low": 141.3,
    "close": 141.49,
    "volume": 3557734
  },
  {
    "time": "21-08-2023",
    "open": 141.42,
    "high": 142.39,
    "low": 141.11,
    "close": 142.28,
    "volume": 2937781
  },
  {
    "time": "18-08-2023",
    "open": 140,
    "high": 141.83,
    "low": 139.76,
    "close": 141.41,
    "volume": 3915480
  },
  {
    "time": "17-08-2023",
    "open": 141.01,
    "high": 142.66,
    "low": 140.6,
    "close": 140.66,
    "volume": 3742058
  },
  {
    "time": "16-08-2023",
    "open": 141.7,
    "high": 142.09,
    "low": 140.56,
    "close": 140.64,
    "volume": 3285347
  },
  {
    "time": "15-08-2023",
    "open": 141.5,
    "high": 142.31,
    "low": 141.2,
    "close": 141.87,
    "volume": 3656559
  },
  {
    "time": "14-08-2023",
    "open": 143.05,
    "high": 143.365,
    "low": 141.802,
    "close": 141.91,
    "volume": 4226563
  },
  {
    "time": "11-08-2023",
    "open": 143.12,
    "high": 143.45,
    "low": 142.205,
    "close": 143.12,
    "volume": 2526433
  },
  {
    "time": "10-08-2023",
    "open": 143.04,
    "high": 144.58,
    "low": 142.69,
    "close": 143.25,
    "volume": 4735763
  },
  {
    "time": "09-08-2023",
    "open": 144.94,
    "high": 144.94,
    "low": 142.3,
    "close": 142.49,
    "volume": 4073038
  },
  {
    "time": "08-08-2023",
    "open": 145.7,
    "high": 146.15,
    "low": 144.11,
    "close": 145.91,
    "volume": 4654582
  },
  {
    "time": "07-08-2023",
    "open": 145,
    "high": 146.5,
    "low": 144.93,
    "close": 146.18,
    "volume": 3438654
  },
  {
    "time": "04-08-2023",
    "open": 145.09,
    "high": 146.09,
    "low": 143.99,
    "close": 144.24,
    "volume": 4223204
  },
  {
    "time": "03-08-2023",
    "open": 143.78,
    "high": 145.22,
    "low": 143.3116,
    "close": 144.45,
    "volume": 3952640
  },
  {
    "time": "02-08-2023",
    "open": 142.78,
    "high": 144.3,
    "low": 142.31,
    "close": 144.17,
    "volume": 4959381
  },
  {
    "time": "01-08-2023",
    "open": 144.25,
    "high": 144.48,
    "low": 142.17,
    "close": 143.33,
    "volume": 4798703
  },
  {
    "time": "31-07-2023",
    "open": 143.81,
    "high": 144.605,
    "low": 143.53,
    "close": 144.18,
    "volume": 6138902
  },
  {
    "time": "28-07-2023",
    "open": 143.44,
    "high": 143.95,
    "low": 142.85,
    "close": 143.45,
    "volume": 6686627
  },
  {
    "time": "27-07-2023",
    "open": 142.3,
    "high": 143.38,
    "low": 141.9,
    "close": 142.97,
    "volume": 6331563
  },
  {
    "time": "26-07-2023",
    "open": 140.44,
    "high": 141.25,
    "low": 139.88,
    "close": 141.07,
    "volume": 4046441
  },
  {
    "time": "25-07-2023",
    "open": 139.42,
    "high": 140.43,
    "low": 139.0403,
    "close": 140.33,
    "volume": 3770813
  },
  {
    "time": "24-07-2023",
    "open": 139.35,
    "high": 140.12,
    "low": 138.7788,
    "close": 139.54,
    "volume": 3475442
  },
  {
    "time": "21-07-2023",
    "open": 138.21,
    "high": 139.7799,
    "low": 137.76,
    "close": 138.94,
    "volume": 5858741
  },
  {
    "time": "20-07-2023",
    "open": 137.19,
    "high": 140.32,
    "low": 136.56,
    "close": 138.38,
    "volume": 10896330
  },
  {
    "time": "19-07-2023",
    "open": 135.53,
    "high": 136.45,
    "low": 135.19,
    "close": 135.48,
    "volume": 5519992
  },
  {
    "time": "18-07-2023",
    "open": 134.71,
    "high": 135.95,
    "low": 134.29,
    "close": 135.36,
    "volume": 3852058
  },
  {
    "time": "17-07-2023",
    "open": 133.26,
    "high": 134.61,
    "low": 133.1,
    "close": 134.24,
    "volume": 3168419
  },
  {
    "time": "14-07-2023",
    "open": 133.91,
    "high": 133.92,
    "low": 132.94,
    "close": 133.4,
    "volume": 2861496
  },
  {
    "time": "13-07-2023",
    "open": 133.51,
    "high": 135.07,
    "low": 133.36,
    "close": 133.92,
    "volume": 3221422
  },
  {
    "time": "12-07-2023",
    "open": 135.07,
    "high": 135.33,
    "low": 132.575,
    "close": 132.84,
    "volume": 3732189
  },
  {
    "time": "11-07-2023",
    "open": 133.66,
    "high": 134.56,
    "low": 133.23,
    "close": 134.44,
    "volume": 2925238
  },
  {
    "time": "10-07-2023",
    "open": 131.76,
    "high": 133.05,
    "low": 131.695,
    "close": 132.9,
    "volume": 2369425
  },
  {
    "time": "07-07-2023",
    "open": 131.78,
    "high": 133.855,
    "low": 131.75,
    "close": 132.08,
    "volume": 2982738
  },
  {
    "time": "06-07-2023",
    "open": 133.235,
    "high": 133.9,
    "low": 131.55,
    "close": 132.16,
    "volume": 3508083
  },
  {
    "time": "05-07-2023",
    "open": 133.32,
    "high": 134.31,
    "low": 132.59,
    "close": 134.24,
    "volume": 2955870
  },
  {
    "time": "03-07-2023",
    "open": 133.42,
    "high": 134.35,
    "low": 132.87,
    "close": 133.67,
    "volume": 1477149
  },
  {
    "time": "30-06-2023",
    "open": 134.69,
    "high": 135.03,
    "low": 133.425,
    "close": 133.81,
    "volume": 4236677
  },
  {
    "time": "29-06-2023",
    "open": 131.75,
    "high": 134.35,
    "low": 131.69,
    "close": 134.06,
    "volume": 3639836
  },
  {
    "time": "28-06-2023",
    "open": 132.06,
    "high": 132.17,
    "low": 130.91,
    "close": 131.76,
    "volume": 2753779
  },
  {
    "time": "27-06-2023",
    "open": 131.3,
    "high": 132.95,
    "low": 130.83,
    "close": 132.34,
    "volume": 3219909
  },
  {
    "time": "26-06-2023",
    "open": 129.39,
    "high": 131.41,
    "low": 129.31,
    "close": 131.34,
    "volume": 4845649
  },
  {
    "time": "23-06-2023",
    "open": 130.4,
    "high": 130.62,
    "low": 129.18,
    "close": 129.43,
    "volume": 11324705
  },
  {
    "time": "22-06-2023",
    "open": 131.68,
    "high": 132.96,
    "low": 130.68,
    "close": 131.17,
    "volume": 6013021
  },
  {
    "time": "21-06-2023",
    "open": 135.11,
    "high": 135.39,
    "low": 133.29,
    "close": 133.69,
    "volume": 5501272
  },
  {
    "time": "20-06-2023",
    "open": 136.36,
    "high": 137.23,
    "low": 135.89,
    "close": 135.96,
    "volume": 4272511
  },
  {
    "time": "16-06-2023",
    "open": 139.23,
    "high": 139.469,
    "low": 137.47,
    "close": 137.48,
    "volume": 7473676
  },
  {
    "time": "15-06-2023",
    "open": 137.27,
    "high": 138.8,
    "low": 137.175,
    "close": 138.4,
    "volume": 3812582
  },
  {
    "time": "14-06-2023",
    "open": 137.8,
    "high": 138.93,
    "low": 136.94,
    "close": 137.2,
    "volume": 4514888
  },
  {
    "time": "13-06-2023",
    "open": 136.51,
    "high": 138.17,
    "low": 136,
    "close": 137.6,
    "volume": 3927331
  },
  {
    "time": "12-06-2023",
    "open": 136,
    "high": 136.62,
    "low": 135.8216,
    "close": 136.42,
    "volume": 4500120
  },
  {
    "time": "09-06-2023",
    "open": 134.36,
    "high": 136.1,
    "low": 134.17,
    "close": 135.3,
    "volume": 3981748
  },
  {
    "time": "08-06-2023",
    "open": 134.69,
    "high": 135.98,
    "low": 134.01,
    "close": 134.41,
    "volume": 4128939
  },
  {
    "time": "07-06-2023",
    "open": 132.5,
    "high": 134.44,
    "low": 132.19,
    "close": 134.38,
    "volume": 5772024
  },
  {
    "time": "06-06-2023",
    "open": 132.43,
    "high": 132.94,
    "low": 131.88,
    "close": 132.69,
    "volume": 3297951
  },
  {
    "time": "05-06-2023",
    "open": 133.12,
    "high": 133.58,
    "low": 132.27,
    "close": 132.64,
    "volume": 3993516
  },
  {
    "time": "02-06-2023",
    "open": 130.38,
    "high": 133.12,
    "low": 130.15,
    "close": 132.42,
    "volume": 5375796
  },
  {
    "time": "01-06-2023",
    "open": 128.44,
    "high": 130.145,
    "low": 127.78,
    "close": 129.82,
    "volume": 4136086
  },
  {
    "time": "31-05-2023",
    "open": 128.51,
    "high": 129.44,
    "low": 127.46,
    "close": 128.59,
    "volume": 11086313
  },
  {
    "time": "30-05-2023",
    "open": 129.56,
    "high": 130.0699,
    "low": 128.26,
    "close": 129.48,
    "volume": 3741050
  },
  {
    "time": "26-05-2023",
    "open": 127.06,
    "high": 129.66,
    "low": 126.81,
    "close": 128.89,
    "volume": 5612570
  },
  {
    "time": "25-05-2023",
    "open": 125.61,
    "high": 127.23,
    "low": 125.01,
    "close": 126.76,
    "volume": 4102854
  },
  {
    "time": "24-05-2023",
    "open": 127.82,
    "high": 127.9,
    "low": 125.47,
    "close": 125.68,
    "volume": 3915505
  },
  {
    "time": "23-05-2023",
    "open": 127.24,
    "high": 129.09,
    "low": 127.13,
    "close": 128.18,
    "volume": 4592280
  },
  {
    "time": "22-05-2023",
    "open": 127.5,
    "high": 128.19,
    "low": 127.15,
    "close": 127.5,
    "volume": 2806770
  },
  {
    "time": "19-05-2023",
    "open": 126.79,
    "high": 128.29,
    "low": 126.55,
    "close": 127.26,
    "volume": 4306657
  },
  {
    "time": "18-05-2023",
    "open": 125.3,
    "high": 126.51,
    "low": 125.1894,
    "close": 126.15,
    "volume": 3797883
  },
  {
    "time": "17-05-2023",
    "open": 123.94,
    "high": 125.85,
    "low": 123.47,
    "close": 125.71,
    "volume": 4515134
  },
  {
    "time": "16-05-2023",
    "open": 123.35,
    "high": 123.86,
    "low": 122.45,
    "close": 123.46,
    "volume": 2749125
  },
  {
    "time": "15-05-2023",
    "open": 123,
    "high": 123.6881,
    "low": 122.34,
    "close": 123.36,
    "volume": 2915725
  },
  {
    "time": "12-05-2023",
    "open": 121.41,
    "high": 122.86,
    "low": 121.11,
    "close": 122.84,
    "volume": 4564825
  },
  {
    "time": "11-05-2023",
    "open": 122.02,
    "high": 122.24,
    "low": 120.55,
    "close": 120.9,
    "volume": 3446452
  },
  {
    "time": "10-05-2023",
    "open": 121.99,
    "high": 122.49,
    "low": 121.1,
    "close": 122.02,
    "volume": 4189222
  },
  {
    "time": "09-05-2023",
    "open": 121.9,
    "high": 121.97,
    "low": 120.66,
    "close": 121.17,
    "volume": 4540047
  },
  {
    "time": "08-05-2023",
    "open": 123.76,
    "high": 123.92,
    "low": 122.55,
    "close": 123.4,
    "volume": 3663818
  },
  {
    "time": "05-05-2023",
    "open": 123.11,
    "high": 124.1,
    "low": 122.805,
    "close": 123.65,
    "volume": 4971936
  },
  {
    "time": "04-05-2023",
    "open": 123.03,
    "high": 123.52,
    "low": 121.7563,
    "close": 122.57,
    "volume": 4468237
  },
  {
    "time": "03-05-2023",
    "open": 125.46,
    "high": 125.57,
    "low": 123.26,
    "close": 123.45,
    "volume": 4554212
  },
  {
    "time": "02-05-2023",
    "open": 126.3,
    "high": 126.45,
    "low": 123.27,
    "close": 125.16,
    "volume": 4445283
  },
  {
    "time": "01-05-2023",
    "open": 126.35,
    "high": 126.75,
    "low": 126.06,
    "close": 126.09,
    "volume": 2724992
  },
  {
    "time": "28-04-2023",
    "open": 126.58,
    "high": 127.25,
    "low": 125.64,
    "close": 126.41,
    "volume": 5061247
  },
  {
    "time": "27-04-2023",
    "open": 126.37,
    "high": 127.02,
    "low": 125.455,
    "close": 126.97,
    "volume": 3204889
  },
  {
    "time": "26-04-2023",
    "open": 125.81,
    "high": 126.545,
    "low": 125.12,
    "close": 125.85,
    "volume": 4058800
  },
  {
    "time": "25-04-2023",
    "open": 124.9,
    "high": 126.19,
    "low": 124.76,
    "close": 125.89,
    "volume": 4275396
  },
  {
    "time": "24-04-2023",
    "open": 125.55,
    "high": 126.05,
    "low": 124.56,
    "close": 125.4,
    "volume": 4043892
  },
  {
    "time": "21-04-2023",
    "open": 126,
    "high": 126.7,
    "low": 125.27,
    "close": 125.73,
    "volume": 6725426
  },
  {
    "time": "20-04-2023",
    "open": 130.15,
    "high": 130.98,
    "low": 125.84,
    "close": 126.36,
    "volume": 9749618
  },
  {
    "time": "19-04-2023",
    "open": 126.5,
    "high": 126.98,
    "low": 125.3,
    "close": 126.32,
    "volume": 7014368
  },
  {
    "time": "18-04-2023",
    "open": 128.14,
    "high": 128.68,
    "low": 127.35,
    "close": 127.78,
    "volume": 3193787
  },
  {
    "time": "17-04-2023",
    "open": 128.3,
    "high": 128.72,
    "low": 126.8,
    "close": 127.82,
    "volume": 3657929
  },
  {
    "time": "14-04-2023",
    "open": 128.46,
    "high": 129.84,
    "low": 127.31,
    "close": 128.14,
    "volume": 4180614
  },
  {
    "time": "13-04-2023",
    "open": 128.01,
    "high": 128.39,
    "low": 126,
    "close": 127.9,
    "volume": 5621512
  },
  {
    "time": "12-04-2023",
    "open": 130.4,
    "high": 130.8857,
    "low": 128.17,
    "close": 128.54,
    "volume": 3957542
  },
  {
    "time": "11-04-2023",
    "open": 130.58,
    "high": 131.105,
    "low": 130.18,
    "close": 130.42,
    "volume": 3132430
  },
  {
    "time": "10-04-2023",
    "open": 129.83,
    "high": 131.08,
    "low": 129.24,
    "close": 131.03,
    "volume": 2614402
  },
  {
    "time": "06-04-2023",
    "open": 132.16,
    "high": 132.6,
    "low": 130.315,
    "close": 130.5,
    "volume": 3050581
  },
  {
    "time": "05-04-2023",
    "open": 131.37,
    "high": 132.61,
    "low": 131.37,
    "close": 132.14,
    "volume": 2898759
  },
  {
    "time": "04-04-2023",
    "open": 131.99,
    "high": 132.1499,
    "low": 130.89,
    "close": 131.6,
    "volume": 3382783
  },
  {
    "time": "03-04-2023",
    "open": 130.97,
    "high": 132.61,
    "low": 130.77,
    "close": 132.06,
    "volume": 3840139
  },
  {
    "time": "31-03-2023",
    "open": 129.47,
    "high": 131.23,
    "low": 129.42,
    "close": 131.09,
    "volume": 4524686
  },
  {
    "time": "30-03-2023",
    "open": 130.16,
    "high": 131.48,
    "low": 129.1,
    "close": 129.22,
    "volume": 3561762
  },
  {
    "time": "29-03-2023",
    "open": 130.12,
    "high": 130.35,
    "low": 129.18,
    "close": 129.71,
    "volume": 3279846
  },
  {
    "time": "28-03-2023",
    "open": 129.18,
    "high": 129.66,
    "low": 128.8,
    "close": 129.34,
    "volume": 2889115
  },
  {
    "time": "27-03-2023",
    "open": 126.47,
    "high": 130.255,
    "low": 126.47,
    "close": 129.31,
    "volume": 6524113
  },
  {
    "time": "24-03-2023",
    "open": 123.36,
    "high": 125.4,
    "low": 122.88,
    "close": 125.29,
    "volume": 3812644
  },
  {
    "time": "23-03-2023",
    "open": 123.81,
    "high": 124.93,
    "low": 122.6,
    "close": 123.37,
    "volume": 4651936
  },
  {
    "time": "22-03-2023",
    "open": 127,
    "high": 127.215,
    "low": 124.01,
    "close": 124.05,
    "volume": 3549024
  },
  {
    "time": "21-03-2023",
    "open": 126.9,
    "high": 127.15,
    "low": 125.66,
    "close": 126.57,
    "volume": 3856345
  },
  {
    "time": "20-03-2023",
    "open": 124.31,
    "high": 126.16,
    "low": 124.19,
    "close": 125.94,
    "volume": 4588304
  },
  {
    "time": "17-03-2023",
    "open": 124.08,
    "high": 124.52,
    "low": 122.93,
    "close": 123.69,
    "volume": 37400167
  },
  {
    "time": "16-03-2023",
    "open": 122.96,
    "high": 124.82,
    "low": 121.92,
    "close": 124.7,
    "volume": 6440023
  },
  {
    "time": "15-03-2023",
    "open": 122.99,
    "high": 123.35,
    "low": 121.71,
    "close": 123.28,
    "volume": 5989339
  },
  {
    "time": "14-03-2023",
    "open": 126.49,
    "high": 126.64,
    "low": 123.2,
    "close": 124.65,
    "volume": 8114792
  },
  {
    "time": "13-03-2023",
    "open": 125.15,
    "high": 128.19,
    "low": 124.85,
    "close": 125.58,
    "volume": 8188369
  },
  {
    "time": "10-03-2023",
    "open": 126.12,
    "high": 127.29,
    "low": 125.13,
    "close": 125.45,
    "volume": 5990867
  },
  {
    "time": "09-03-2023",
    "open": 128.3,
    "high": 128.53,
    "low": 125.98,
    "close": 126.16,
    "volume": 5478317
  },
  {
    "time": "08-03-2023",
    "open": 128.48,
    "high": 128.74,
    "low": 127.545,
    "close": 128.05,
    "volume": 2778798
  },
  {
    "time": "07-03-2023",
    "open": 130.28,
    "high": 130.42,
    "low": 128.19,
    "close": 128.25,
    "volume": 3530439
  },
  {
    "time": "06-03-2023",
    "open": 129.64,
    "high": 130.86,
    "low": 129.59,
    "close": 130.19,
    "volume": 2982980
  },
  {
    "time": "03-03-2023",
    "open": 129.35,
    "high": 129.905,
    "low": 128.77,
    "close": 129.64,
    "volume": 2860286
  },
  {
    "time": "02-03-2023",
    "open": 128.39,
    "high": 129.22,
    "low": 127.71,
    "close": 128.93,
    "volume": 3340254
  },
  {
    "time": "01-03-2023",
    "open": 128.9,
    "high": 129.4726,
    "low": 127.74,
    "close": 128.19,
    "volume": 3760678
  },
  {
    "time": "28-02-2023",
    "open": 130.55,
    "high": 130.61,
    "low": 129.14,
    "close": 129.3,
    "volume": 5143133
  },
  {
    "time": "27-02-2023",
    "open": 131.42,
    "high": 131.87,
    "low": 130.13,
    "close": 130.49,
    "volume": 2761326
  },
  {
    "time": "24-02-2023",
    "open": 129.62,
    "high": 130.67,
    "low": 129.22,
    "close": 130.57,
    "volume": 3015907
  },
  {
    "time": "23-02-2023",
    "open": 131.5,
    "high": 131.7,
    "low": 128.86,
    "close": 130.79,
    "volume": 3725648
  },
  {
    "time": "22-02-2023",
    "open": 131.9,
    "high": 131.99,
    "low": 130.29,
    "close": 130.97,
    "volume": 3200185
  },
  {
    "time": "21-02-2023",
    "open": 134,
    "high": 134.385,
    "low": 131.66,
    "close": 131.71,
    "volume": 4257210
  },
  {
    "time": "17-02-2023",
    "open": 134.5,
    "high": 135.58,
    "low": 133.89,
    "close": 135.02,
    "volume": 3466184
  },
  {
    "time": "16-02-2023",
    "open": 135.57,
    "high": 135.9672,
    "low": 134.59,
    "close": 135,
    "volume": 2965495
  },
  {
    "time": "15-02-2023",
    "open": 135.2,
    "high": 136.445,
    "low": 135.07,
    "close": 136.4,
    "volume": 2507004
  },
  {
    "time": "14-02-2023",
    "open": 137.05,
    "high": 137.24,
    "low": 135.05,
    "close": 136.01,
    "volume": 3202172
  },
  {
    "time": "13-02-2023",
    "open": 136,
    "high": 137.39,
    "low": 135.85,
    "close": 137.35,
    "volume": 4403015
  },
  {
    "time": "10-02-2023",
    "open": 133.78,
    "high": 135.77,
    "low": 133.5,
    "close": 135.6,
    "volume": 5049571
  },
  {
    "time": "09-02-2023",
    "open": 134.99,
    "high": 135.73,
    "low": 133.34,
    "close": 133.75,
    "volume": 3918817
  },
  {
    "time": "08-02-2023",
    "open": 135.71,
    "high": 136.74,
    "low": 135.16,
    "close": 135.98,
    "volume": 4593748
  },
  {
    "time": "07-02-2023",
    "open": 135.67,
    "high": 136.4,
    "low": 134.45,
    "close": 135.84,
    "volume": 3737553
  },
  {
    "time": "06-02-2023",
    "open": 135.83,
    "high": 136.32,
    "low": 134.95,
    "close": 136.18,
    "volume": 4841300
  },
  {
    "time": "03-02-2023",
    "open": 136.35,
    "high": 136.95,
    "low": 135.53,
    "close": 136.94,
    "volume": 3755720
  },
  {
    "time": "02-02-2023",
    "open": 135.96,
    "high": 136.72,
    "low": 134.85,
    "close": 136.39,
    "volume": 6107793
  },
  {
    "time": "01-02-2023",
    "open": 134.49,
    "high": 135.79,
    "low": 132.8,
    "close": 135.09,
    "volume": 5428898
  },
  {
    "time": "31-01-2023",
    "open": 135.5,
    "high": 135.65,
    "low": 133.76,
    "close": 134.73,
    "volume": 7206448
  },
  {
    "time": "30-01-2023",
    "open": 134.32,
    "high": 136.11,
    "low": 133.98,
    "close": 135.3,
    "volume": 5375712
  },
  {
    "time": "27-01-2023",
    "open": 134.44,
    "high": 135.488,
    "low": 133.7701,
    "close": 134.39,
    "volume": 8143146
  },
  {
    "time": "26-01-2023",
    "open": 137.53,
    "high": 138.27,
    "low": 132.98,
    "close": 134.45,
    "volume": 17548483
  },
  {
    "time": "25-01-2023",
    "open": 140.47,
    "high": 141.03,
    "low": 139.36,
    "close": 140.76,
    "volume": 7347453
  },
  {
    "time": "24-01-2023",
    "open": 141.25,
    "high": 142.75,
    "low": 140,
    "close": 141.49,
    "volume": 4407622
  },
  {
    "time": "23-01-2023",
    "open": 141.4,
    "high": 142.985,
    "low": 141.06,
    "close": 141.86,
    "volume": 5898436
  },
  {
    "time": "20-01-2023",
    "open": 141.67,
    "high": 141.86,
    "low": 140.51,
    "close": 141.2,
    "volume": 7153341
  },
  {
    "time": "19-01-2023",
    "open": 140,
    "high": 142.23,
    "low": 139.75,
    "close": 140.62,
    "volume": 4833924
  },
  {
    "time": "18-01-2023",
    "open": 144.4,
    "high": 144.678,
    "low": 140.225,
    "close": 140.41,
    "volume": 6445642
  },
  {
    "time": "17-01-2023",
    "open": 146.42,
    "high": 147.18,
    "low": 145.01,
    "close": 145.19,
    "volume": 2986461
  },
  {
    "time": "13-01-2023",
    "open": 144.06,
    "high": 146.1,
    "low": 144.01,
    "close": 145.89,
    "volume": 2455786
  },
  {
    "time": "12-01-2023",
    "open": 144.88,
    "high": 146.66,
    "low": 144.52,
    "close": 145.55,
    "volume": 2716118
  },
  {
    "time": "11-01-2023",
    "open": 145,
    "high": 145.53,
    "low": 143.45,
    "close": 145.26,
    "volume": 3268738
  },
  {
    "time": "10-01-2023",
    "open": 143.61,
    "high": 144.85,
    "low": 142.9,
    "close": 144.8,
    "volume": 2152172
  },
  {
    "time": "09-01-2023",
    "open": 144.08,
    "high": 145.47,
    "low": 143.4,
    "close": 143.55,
    "volume": 3987782
  },
  {
    "time": "06-01-2023",
    "open": 142.38,
    "high": 144.25,
    "low": 141.58,
    "close": 143.7,
    "volume": 3574042
  },
  {
    "time": "05-01-2023",
    "open": 142.44,
    "high": 142.498,
    "low": 140.01,
    "close": 141.11,
    "volume": 2866648
  },
  {
    "time": "04-01-2023",
    "open": 142.07,
    "high": 143.615,
    "low": 141.3675,
    "close": 142.6,
    "volume": 3869236
  },
  {
    "time": "03-01-2023",
    "open": 141.1,
    "high": 141.9,
    "low": 140.48,
    "close": 141.55,
    "volume": 3338829
  },
  {
    "time": "30-12-2022",
    "open": 140.54,
    "high": 140.9,
    "low": 139.45,
    "close": 140.89,
    "volume": 2858110
  },
  {
    "time": "29-12-2022",
    "open": 140.58,
    "high": 142.26,
    "low": 140.45,
    "close": 141.06,
    "volume": 2337207
  },
  {
    "time": "28-12-2022",
    "open": 142.4,
    "high": 142.81,
    "low": 139.95,
    "close": 140.02,
    "volume": 2539577
  },
  {
    "time": "27-12-2022",
    "open": 141.73,
    "high": 142.72,
    "low": 141.23,
    "close": 142.42,
    "volume": 2742525
  },
  {
    "time": "23-12-2022",
    "open": 140.59,
    "high": 141.8565,
    "low": 139.6,
    "close": 141.65,
    "volume": 2092715
  },
  {
    "time": "22-12-2022",
    "open": 140.95,
    "high": 141.44,
    "low": 138.62,
    "close": 140.88,
    "volume": 3337851
  },
  {
    "time": "21-12-2022",
    "open": 141.84,
    "high": 143.09,
    "low": 140.975,
    "close": 142.14,
    "volume": 3793700
  },
  {
    "time": "20-12-2022",
    "open": 138.84,
    "high": 141.425,
    "low": 138.34,
    "close": 141.28,
    "volume": 5156450
  },
  {
    "time": "19-12-2022",
    "open": 140.16,
    "high": 140.44,
    "low": 137.195,
    "close": 138.87,
    "volume": 5282860
  },
  {
    "time": "16-12-2022",
    "open": 141.25,
    "high": 141.915,
    "low": 138.97,
    "close": 140.16,
    "volume": 9834427
  },
  {
    "time": "15-12-2022",
    "open": 148.52,
    "high": 148.98,
    "low": 141.58,
    "close": 142.36,
    "volume": 6687744
  },
  {
    "time": "14-12-2022",
    "open": 150.47,
    "high": 151.91,
    "low": 148.45,
    "close": 149.86,
    "volume": 4205878
  },
  {
    "time": "13-12-2022",
    "open": 150.37,
    "high": 153.21,
    "low": 149.95,
    "close": 150.57,
    "volume": 8811553
  },
  {
    "time": "12-12-2022",
    "open": 147.82,
    "high": 149.21,
    "low": 146.9426,
    "close": 149.21,
    "volume": 4032780
  },
  {
    "time": "09-12-2022",
    "open": 147.4,
    "high": 148.34,
    "low": 146.97,
    "close": 147.05,
    "volume": 3047581
  },
  {
    "time": "08-12-2022",
    "open": 147.9,
    "high": 149.153,
    "low": 147.365,
    "close": 147.78,
    "volume": 2665687
  },
  {
    "time": "07-12-2022",
    "open": 147.33,
    "high": 148.105,
    "low": 146.29,
    "close": 147.27,
    "volume": 3971285
  },
  {
    "time": "06-12-2022",
    "open": 147.3,
    "high": 147.8,
    "low": 146.7,
    "close": 147.5,
    "volume": 2847610
  },
  {
    "time": "05-12-2022",
    "open": 147.94,
    "high": 148.93,
    "low": 146.8,
    "close": 147.41,
    "volume": 2784767
  },
  {
    "time": "02-12-2022",
    "open": 148.13,
    "high": 149.16,
    "low": 147.73,
    "close": 148.67,
    "volume": 2899995
  },
  {
    "time": "01-12-2022",
    "open": 149.98,
    "high": 150.01,
    "low": 147.34,
    "close": 149.16,
    "volume": 4495924
  },
  {
    "time": "30-11-2022",
    "open": 146.19,
    "high": 149.64,
    "low": 145.67,
    "close": 148.9,
    "volume": 6377582
  },
  {
    "time": "29-11-2022",
    "open": 145.91,
    "high": 147.1668,
    "low": 145.7,
    "close": 146.49,
    "volume": 2754744
  },
  {
    "time": "28-11-2022",
    "open": 147.98,
    "high": 148.24,
    "low": 145.935,
    "close": 146.18,
    "volume": 3538696
  },
  {
    "time": "25-11-2022",
    "open": 148.27,
    "high": 149.49,
    "low": 148.101,
    "close": 148.37,
    "volume": 2075156
  },
  {
    "time": "23-11-2022",
    "open": 149.1,
    "high": 150.46,
    "low": 148.3,
    "close": 148.75,
    "volume": 3658459
  },
  {
    "time": "22-11-2022",
    "open": 147.6,
    "high": 149.35,
    "low": 147.02,
    "close": 149.1,
    "volume": 7062060
  },
  {
    "time": "21-11-2022",
    "open": 147.55,
    "high": 147.928,
    "low": 146.45,
    "close": 146.68,
    "volume": 3476208
  },
  {
    "time": "18-11-2022",
    "open": 146.56,
    "high": 148.31,
    "low": 145.94,
    "close": 147.64,
    "volume": 4661735
  },
  {
    "time": "17-11-2022",
    "open": 143.41,
    "high": 146.18,
    "low": 143.25,
    "close": 146.09,
    "volume": 3955162
  },
  {
    "time": "16-11-2022",
    "open": 144.13,
    "high": 144.95,
    "low": 144.0081,
    "close": 144.52,
    "volume": 3445302
  },
  {
    "time": "15-11-2022",
    "open": 144.08,
    "high": 146.1609,
    "low": 142,
    "close": 144.34,
    "volume": 4727107
  },
  {
    "time": "14-11-2022",
    "open": 142.63,
    "high": 146.08,
    "low": 142.18,
    "close": 144.2,
    "volume": 5245624
  },
  {
    "time": "11-11-2022",
    "open": 141.5,
    "high": 144.13,
    "low": 140.96,
    "close": 143.17,
    "volume": 5871264
  },
  {
    "time": "10-11-2022",
    "open": 140.26,
    "high": 141.37,
    "low": 138.29,
    "close": 141.23,
    "volume": 5389025
  },
  {
    "time": "09-11-2022",
    "open": 137.95,
    "high": 138.9,
    "low": 136.94,
    "close": 137.39,
    "volume": 4720007
  },
  {
    "time": "08-11-2022",
    "open": 139,
    "high": 140.93,
    "low": 138.72,
    "close": 140.04,
    "volume": 5042756
  },
  {
    "time": "07-11-2022",
    "open": 136.64,
    "high": 138.7,
    "low": 136.51,
    "close": 138.34,
    "volume": 4043062
  },
  {
    "time": "04-11-2022",
    "open": 135.65,
    "high": 137.73,
    "low": 134.94,
    "close": 136.96,
    "volume": 4177972
  },
  {
    "time": "03-11-2022",
    "open": 136.42,
    "high": 136.48,
    "low": 133.97,
    "close": 134.47,
    "volume": 4442443
  },
  {
    "time": "02-11-2022",
    "open": 137.75,
    "high": 140.17,
    "low": 136.8,
    "close": 136.83,
    "volume": 5365264
  },
  {
    "time": "01-11-2022",
    "open": 138.25,
    "high": 138.65,
    "low": 136.7,
    "close": 138.2,
    "volume": 3590607
  },
  {
    "time": "31-10-2022",
    "open": 138.06,
    "high": 138.7699,
    "low": 136.595,
    "close": 138.29,
    "volume": 4915270
  },
  {
    "time": "28-10-2022",
    "open": 135.56,
    "high": 138.8615,
    "low": 135.22,
    "close": 138.51,
    "volume": 5965457
  },
  {
    "time": "27-10-2022",
    "open": 135.55,
    "high": 136.4,
    "low": 134.445,
    "close": 134.77,
    "volume": 3993168
  },
  {
    "time": "26-10-2022",
    "open": 133.72,
    "high": 135.863,
    "low": 132.81,
    "close": 135.01,
    "volume": 5140023
  },
  {
    "time": "25-10-2022",
    "open": 132,
    "high": 133.3,
    "low": 131.3,
    "close": 132.93,
    "volume": 5957623
  },
  {
    "time": "24-10-2022",
    "open": 130.9,
    "high": 133.11,
    "low": 129.85,
    "close": 132.69,
    "volume": 5610914
  },
  {
    "time": "21-10-2022",
    "open": 128.39,
    "high": 130.845,
    "low": 127.59,
    "close": 129.9,
    "volume": 7201256
  },
  {
    "time": "20-10-2022",
    "open": 126.25,
    "high": 128.96,
    "low": 125.15,
    "close": 128.3,
    "volume": 13623115
  },
  {
    "time": "19-10-2022",
    "open": 122.36,
    "high": 123.94,
    "low": 121.9875,
    "close": 122.51,
    "volume": 5906576
  },
  {
    "time": "18-10-2022",
    "open": 123,
    "high": 123.94,
    "low": 121.82,
    "close": 122.94,
    "volume": 5120336
  },
  {
    "time": "17-10-2022",
    "open": 121.8,
    "high": 122.875,
    "low": 121.43,
    "close": 121.52,
    "volume": 5458585
  },
  {
    "time": "14-10-2022",
    "open": 121.8,
    "high": 122.54,
    "low": 119.84,
    "close": 120.04,
    "volume": 3763840
  },
  {
    "time": "13-10-2022",
    "open": 116.1,
    "high": 122.15,
    "low": 115.545,
    "close": 121.79,
    "volume": 5837645
  },
  {
    "time": "12-10-2022",
    "open": 118,
    "high": 118.81,
    "low": 117.2,
    "close": 117.57,
    "volume": 3338754
  },
  {
    "time": "11-10-2022",
    "open": 117.46,
    "high": 119.23,
    "low": 116.94,
    "close": 117.8,
    "volume": 4043265
  },
  {
    "time": "10-10-2022",
    "open": 119.79,
    "high": 119.96,
    "low": 117.04,
    "close": 117.75,
    "volume": 5990008
  },
  {
    "time": "07-10-2022",
    "open": 121.5,
    "high": 121.8016,
    "low": 118.07,
    "close": 118.82,
    "volume": 4499672
  },
  {
    "time": "06-10-2022",
    "open": 124.88,
    "high": 125.3,
    "low": 121.77,
    "close": 122.23,
    "volume": 5074624
  },
  {
    "time": "05-10-2022",
    "open": 124.71,
    "high": 126.46,
    "low": 124.23,
    "close": 125.74,
    "volume": 3212872
  },
  {
    "time": "04-10-2022",
    "open": 122.8,
    "high": 125.65,
    "low": 122.52,
    "close": 125.5,
    "volume": 4566055
  },
  {
    "time": "03-10-2022",
    "open": 120.16,
    "high": 122.21,
    "low": 119.6,
    "close": 121.51,
    "volume": 4261729
  },
  {
    "time": "30-09-2022",
    "open": 121.66,
    "high": 122.43,
    "low": 118.61,
    "close": 118.81,
    "volume": 5346108
  },
  {
    "time": "29-09-2022",
    "open": 121.85,
    "high": 122.62,
    "low": 120.57,
    "close": 121.63,
    "volume": 3817723
  },
  {
    "time": "28-09-2022",
    "open": 121.65,
    "high": 123.23,
    "low": 119.81,
    "close": 122.76,
    "volume": 4996378
  },
  {
    "time": "27-09-2022",
    "open": 122.6,
    "high": 123.95,
    "low": 121.08,
    "close": 121.74,
    "volume": 4117215
  },
  {
    "time": "26-09-2022",
    "open": 122.3,
    "high": 124.26,
    "low": 121.75,
    "close": 122.01,
    "volume": 3987817
  },
  {
    "time": "23-09-2022",
    "open": 124.53,
    "high": 125,
    "low": 121.74,
    "close": 122.71,
    "volume": 5195693
  },
  {
    "time": "22-09-2022",
    "open": 124.76,
    "high": 126.51,
    "low": 124.41,
    "close": 125.31,
    "volume": 4038335
  },
  {
    "time": "21-09-2022",
    "open": 126.89,
    "high": 127.84,
    "low": 124.92,
    "close": 124.93,
    "volume": 3389545
  },
  {
    "time": "20-09-2022",
    "open": 126.9,
    "high": 126.99,
    "low": 125.52,
    "close": 126.3,
    "volume": 2837485
  },
  {
    "time": "19-09-2022",
    "open": 126.49,
    "high": 128.06,
    "low": 126.28,
    "close": 127.73,
    "volume": 3981606
  },
  {
    "time": "16-09-2022",
    "open": 124.36,
    "high": 127.53,
    "low": 123.83,
    "close": 127.27,
    "volume": 9839696
  },
  {
    "time": "15-09-2022",
    "open": 127.39,
    "high": 127.465,
    "low": 124.9,
    "close": 125.49,
    "volume": 5141671
  },
  {
    "time": "14-09-2022",
    "open": 127.5,
    "high": 129,
    "low": 126.845,
    "close": 127.69,
    "volume": 3819067
  },
  {
    "time": "13-09-2022",
    "open": 129.14,
    "high": 129.909,
    "low": 126.7709,
    "close": 127.25,
    "volume": 4565585
  },
  {
    "time": "12-09-2022",
    "open": 130.33,
    "high": 130.99,
    "low": 129.89,
    "close": 130.66,
    "volume": 3741168
  },
  {
    "time": "09-09-2022",
    "open": 128.9,
    "high": 129.49,
    "low": 128.06,
    "close": 129.19,
    "volume": 3293365
  },
  {
    "time": "08-09-2022",
    "open": 127.2,
    "high": 128.54,
    "low": 126.59,
    "close": 128.47,
    "volume": 2964613
  },
  {
    "time": "07-09-2022",
    "open": 126.69,
    "high": 127.8667,
    "low": 126.28,
    "close": 127.71,
    "volume": 2401513
  },
  {
    "time": "06-09-2022",
    "open": 127.8,
    "high": 128.06,
    "low": 126.3,
    "close": 126.72,
    "volume": 3345343
  },
  {
    "time": "02-09-2022",
    "open": 130.3,
    "high": 130.56,
    "low": 127.24,
    "close": 127.79,
    "volume": 3040813
  },
  {
    "time": "01-09-2022",
    "open": 128.4,
    "high": 129.8173,
    "low": 127.74,
    "close": 129.66,
    "volume": 3396219
  },
  {
    "time": "31-08-2022",
    "open": 129.92,
    "high": 130,
    "low": 128.4,
    "close": 128.45,
    "volume": 3490380
  },
  {
    "time": "30-08-2022",
    "open": 130.56,
    "high": 130.77,
    "low": 129.29,
    "close": 129.58,
    "volume": 2407888
  },
  {
    "time": "29-08-2022",
    "open": 129.99,
    "high": 131.42,
    "low": 129.57,
    "close": 130.31,
    "volume": 2751308
  },
  {
    "time": "26-08-2022",
    "open": 134.1,
    "high": 134.18,
    "low": 130.34,
    "close": 130.38,
    "volume": 4185254
  },
  {
    "time": "25-08-2022",
    "open": 133.65,
    "high": 134.425,
    "low": 133.07,
    "close": 133.98,
    "volume": 2706005
  },
  {
    "time": "24-08-2022",
    "open": 134.89,
    "high": 135.11,
    "low": 133.11,
    "close": 133.23,
    "volume": 2855421
  },
  {
    "time": "23-08-2022",
    "open": 135.37,
    "high": 136.1,
    "low": 134.72,
    "close": 134.74,
    "volume": 4141798
  },
  {
    "time": "22-08-2022",
    "open": 137.65,
    "high": 137.85,
    "low": 135.47,
    "close": 135.55,
    "volume": 3093629
  },
  {
    "time": "19-08-2022",
    "open": 138.75,
    "high": 139.34,
    "low": 137.66,
    "close": 138.37,
    "volume": 3155842
  },
  {
    "time": "18-08-2022",
    "open": 137.79,
    "high": 139.12,
    "low": 137.75,
    "close": 139.07,
    "volume": 3177726
  },
  {
    "time": "17-08-2022",
    "open": 136.46,
    "high": 138.415,
    "low": 136.3,
    "close": 137.79,
    "volume": 3667568
  },
  {
    "time": "16-08-2022",
    "open": 134.6,
    "high": 137.37,
    "low": 134.4517,
    "close": 136.56,
    "volume": 4313600
  },
  {
    "time": "15-08-2022",
    "open": 132.96,
    "high": 135.19,
    "low": 132.24,
    "close": 134.93,
    "volume": 2791241
  },
  {
    "time": "12-08-2022",
    "open": 132.62,
    "high": 134.09,
    "low": 131.98,
    "close": 134.01,
    "volume": 2767054
  },
  {
    "time": "11-08-2022",
    "open": 132.36,
    "high": 133.225,
    "low": 132,
    "close": 132.54,
    "volume": 3501925
  },
  {
    "time": "10-08-2022",
    "open": 130.75,
    "high": 131.78,
    "low": 130.34,
    "close": 131.5,
    "volume": 3663995
  },
  {
    "time": "09-08-2022",
    "open": 129.92,
    "high": 130.79,
    "low": 129.12,
    "close": 129.47,
    "volume": 3495991
  },
  {
    "time": "08-08-2022",
    "open": 133.1,
    "high": 133.35,
    "low": 132.02,
    "close": 132.61,
    "volume": 3825145
  },
  {
    "time": "05-08-2022",
    "open": 131.25,
    "high": 132.67,
    "low": 131.07,
    "close": 132.48,
    "volume": 2586519
  },
  {
    "time": "04-08-2022",
    "open": 132.15,
    "high": 132.2866,
    "low": 131.02,
    "close": 131.64,
    "volume": 3179789
  },
  {
    "time": "03-08-2022",
    "open": 131.82,
    "high": 132.862,
    "low": 131.32,
    "close": 132.34,
    "volume": 3088532
  },
  {
    "time": "02-08-2022",
    "open": 132.2,
    "high": 132.78,
    "low": 130.51,
    "close": 131.81,
    "volume": 4403633
  },
  {
    "time": "01-08-2022",
    "open": 130.75,
    "high": 132.7,
    "low": 130.7,
    "close": 132.04,
    "volume": 4142099
  },
  {
    "time": "29-07-2022",
    "open": 129.52,
    "high": 131,
    "low": 129.31,
    "close": 130.79,
    "volume": 5786815
  },
  {
    "time": "28-07-2022",
    "open": 128.75,
    "high": 129.81,
    "low": 128.606,
    "close": 129.22,
    "volume": 3913680
  },
  {
    "time": "27-07-2022",
    "open": 127.97,
    "high": 129.43,
    "low": 127.58,
    "close": 129.12,
    "volume": 4175625
  },
  {
    "time": "26-07-2022",
    "open": 128.26,
    "high": 129.3,
    "low": 127.63,
    "close": 128.08,
    "volume": 3645313
  },
  {
    "time": "25-07-2022",
    "open": 128.44,
    "high": 129.1257,
    "low": 127.9,
    "close": 128.54,
    "volume": 4702352
  },
  {
    "time": "22-07-2022",
    "open": 127.03,
    "high": 128.32,
    "low": 125.71,
    "close": 128.25,
    "volume": 6467205
  },
  {
    "time": "21-07-2022",
    "open": 128.75,
    "high": 128.81,
    "low": 125.13,
    "close": 127.15,
    "volume": 11975361
  },
  {
    "time": "20-07-2022",
    "open": 130.7,
    "high": 130.72,
    "low": 128.06,
    "close": 129.18,
    "volume": 9882016
  },
  {
    "time": "19-07-2022",
    "open": 131.58,
    "high": 132.56,
    "low": 127.72,
    "close": 130.88,
    "volume": 29690535
  },
  {
    "time": "18-07-2022",
    "open": 140.15,
    "high": 140.31,
    "low": 137.7844,
    "close": 138.13,
    "volume": 8231694
  },
  {
    "time": "15-07-2022",
    "open": 140.68,
    "high": 140.68,
    "low": 138.61,
    "close": 139.92,
    "volume": 4519320
  },
  {
    "time": "14-07-2022",
    "open": 135.6,
    "high": 139.43,
    "low": 135.02,
    "close": 139.06,
    "volume": 5298351
  },
  {
    "time": "13-07-2022",
    "open": 137.18,
    "high": 138.9,
    "low": 136.8,
    "close": 137.18,
    "volume": 4123213
  },
  {
    "time": "12-07-2022",
    "open": 140.84,
    "high": 141.55,
    "low": 138.565,
    "close": 139.18,
    "volume": 3235571
  },
  {
    "time": "11-07-2022",
    "open": 140.62,
    "high": 141.87,
    "low": 140.13,
    "close": 141,
    "volume": 3912773
  },
  {
    "time": "08-07-2022",
    "open": 140.76,
    "high": 141.3203,
    "low": 139.82,
    "close": 140.47,
    "volume": 2820928
  },
  {
    "time": "07-07-2022",
    "open": 138.91,
    "high": 141.325,
    "low": 138.83,
    "close": 140.83,
    "volume": 3897077
  },
  {
    "time": "06-07-2022",
    "open": 138,
    "high": 139.14,
    "low": 137.13,
    "close": 138.08,
    "volume": 3237059
  },
  {
    "time": "05-07-2022",
    "open": 139.97,
    "high": 139.97,
    "low": 135.27,
    "close": 137.62,
    "volume": 6274067
  },
  {
    "time": "01-07-2022",
    "open": 141,
    "high": 141.67,
    "low": 139.26,
    "close": 141.12,
    "volume": 4012106
  },
  {
    "time": "30-06-2022",
    "open": 139.58,
    "high": 142.46,
    "low": 139.28,
    "close": 141.19,
    "volume": 4878020
  },
  {
    "time": "29-06-2022",
    "open": 142.74,
    "high": 143.5213,
    "low": 139.5,
    "close": 140.71,
    "volume": 4161491
  },
  {
    "time": "28-06-2022",
    "open": 142.92,
    "high": 144.155,
    "low": 141.32,
    "close": 141.86,
    "volume": 4065202
  },
  {
    "time": "27-06-2022",
    "open": 142.26,
    "high": 143.83,
    "low": 141.95,
    "close": 142.8,
    "volume": 3935968
  },
  {
    "time": "24-06-2022",
    "open": 139.2,
    "high": 142.37,
    "low": 139.13,
    "close": 142.06,
    "volume": 11493012
  },
  {
    "time": "23-06-2022",
    "open": 137.14,
    "high": 138.62,
    "low": 136.5,
    "close": 138.44,
    "volume": 4547925
  },
  {
    "time": "22-06-2022",
    "open": 136.1,
    "high": 138.18,
    "low": 136.03,
    "close": 137.08,
    "volume": 3791635
  },
  {
    "time": "21-06-2022",
    "open": 135.9,
    "high": 138.5,
    "low": 135.9,
    "close": 137.85,
    "volume": 4089272
  },
  {
    "time": "17-06-2022",
    "open": 135.99,
    "high": 136.98,
    "low": 132.85,
    "close": 135.02,
    "volume": 12968509
  },
  {
    "time": "16-06-2022",
    "open": 135.3,
    "high": 136.24,
    "low": 134.39,
    "close": 135.67,
    "volume": 5386396
  },
  {
    "time": "15-06-2022",
    "open": 136.01,
    "high": 138.45,
    "low": 135.58,
    "close": 137.06,
    "volume": 4606887
  },
  {
    "time": "14-06-2022",
    "open": 135,
    "high": 136.9501,
    "low": 134.86,
    "close": 135.72,
    "volume": 4607785
  },
  {
    "time": "13-06-2022",
    "open": 133.97,
    "high": 137.57,
    "low": 133.76,
    "close": 135.11,
    "volume": 6486044
  },
  {
    "time": "10-06-2022",
    "open": 137.8,
    "high": 137.8,
    "low": 135.25,
    "close": 136.19,
    "volume": 4661716
  },
  {
    "time": "09-06-2022",
    "open": 140.15,
    "high": 141.04,
    "low": 137.93,
    "close": 137.96,
    "volume": 3592551
  },
  {
    "time": "08-06-2022",
    "open": 142.07,
    "high": 142.8,
    "low": 140.15,
    "close": 140.83,
    "volume": 3005666
  },
  {
    "time": "07-06-2022",
    "open": 142.6,
    "high": 142.98,
    "low": 140.96,
    "close": 142.78,
    "volume": 2887051
  },
  {
    "time": "06-06-2022",
    "open": 142.98,
    "high": 144.73,
    "low": 142,
    "close": 142.88,
    "volume": 4767100
  },
  {
    "time": "03-06-2022",
    "open": 140.26,
    "high": 142.5794,
    "low": 139.74,
    "close": 141.18,
    "volume": 4352213
  },
  {
    "time": "02-06-2022",
    "open": 139.45,
    "high": 140.29,
    "low": 136.85,
    "close": 140.15,
    "volume": 3734812
  },
  {
    "time": "01-06-2022",
    "open": 139.67,
    "high": 140.4699,
    "low": 138.52,
    "close": 139.43,
    "volume": 3796488
  },
  {
    "time": "31-05-2022",
    "open": 138.2,
    "high": 139.83,
    "low": 136.81,
    "close": 138.84,
    "volume": 7978053
  },
  {
    "time": "27-05-2022",
    "open": 137.39,
    "high": 139.7394,
    "low": 137.24,
    "close": 139.27,
    "volume": 4608215
  },
  {
    "time": "26-05-2022",
    "open": 135,
    "high": 137.55,
    "low": 134.52,
    "close": 136.89,
    "volume": 3929338
  },
  {
    "time": "25-05-2022",
    "open": 132.86,
    "high": 134.82,
    "low": 132.86,
    "close": 134.39,
    "volume": 3299303
  },
  {
    "time": "24-05-2022",
    "open": 130.57,
    "high": 134.37,
    "low": 130.42,
    "close": 133.8,
    "volume": 4189723
  },
  {
    "time": "23-05-2022",
    "open": 129.5,
    "high": 131.95,
    "low": 129.42,
    "close": 131.17,
    "volume": 3591511
  },
  {
    "time": "20-05-2022",
    "open": 130.18,
    "high": 130.37,
    "low": 125.8,
    "close": 128.48,
    "volume": 6914458
  },
  {
    "time": "19-05-2022",
    "open": 132,
    "high": 132.32,
    "low": 128.71,
    "close": 129.66,
    "volume": 5819664
  },
  {
    "time": "18-05-2022",
    "open": 137.1,
    "high": 137.615,
    "low": 132.17,
    "close": 132.94,
    "volume": 6003015
  },
  {
    "time": "17-05-2022",
    "open": 137.19,
    "high": 138.37,
    "low": 135.64,
    "close": 138.37,
    "volume": 4372909
  },
  {
    "time": "16-05-2022",
    "open": 133.1,
    "high": 136.51,
    "low": 132.41,
    "close": 135.03,
    "volume": 4250395
  },
  {
    "time": "13-05-2022",
    "open": 133,
    "high": 133.8,
    "low": 131.05,
    "close": 133.6,
    "volume": 4195218
  },
  {
    "time": "12-05-2022",
    "open": 131.27,
    "high": 133.62,
    "low": 130.41,
    "close": 132.9,
    "volume": 5384809
  },
  {
    "time": "11-05-2022",
    "open": 129.86,
    "high": 132.96,
    "low": 129.86,
    "close": 130.75,
    "volume": 5301131
  },
  {
    "time": "10-05-2022",
    "open": 135,
    "high": 135.41,
    "low": 128.43,
    "close": 129.13,
    "volume": 8642398
  },
  {
    "time": "09-05-2022",
    "open": 134.41,
    "high": 136.345,
    "low": 133.315,
    "close": 134.44,
    "volume": 7647933
  },
  {
    "time": "06-05-2022",
    "open": 135.47,
    "high": 137.99,
    "low": 135.47,
    "close": 137.67,
    "volume": 7306396
  },
  {
    "time": "05-05-2022",
    "open": 136.46,
    "high": 137.26,
    "low": 134.76,
    "close": 135.92,
    "volume": 5957434
  },
  {
    "time": "04-05-2022",
    "open": 132.87,
    "high": 137.87,
    "low": 132.14,
    "close": 137.4,
    "volume": 5913705
  },
  {
    "time": "03-05-2022",
    "open": 133,
    "high": 133.6,
    "low": 131.99,
    "close": 132.52,
    "volume": 3688574
  },
  {
    "time": "02-05-2022",
    "open": 133,
    "high": 133.77,
    "low": 130.89,
    "close": 133.04,
    "volume": 4213477
  },
  {
    "time": "29-04-2022",
    "open": 135.13,
    "high": 135.5545,
    "low": 132,
    "close": 132.21,
    "volume": 5078660
  },
  {
    "time": "28-04-2022",
    "open": 136.85,
    "high": 136.99,
    "low": 134.81,
    "close": 135.74,
    "volume": 4477068
  },
  {
    "time": "27-04-2022",
    "open": 135.78,
    "high": 137.12,
    "low": 134.63,
    "close": 135.06,
    "volume": 4369052
  },
  {
    "time": "26-04-2022",
    "open": 139,
    "high": 139.87,
    "low": 136.17,
    "close": 136.19,
    "volume": 4887395
  },
  {
    "time": "25-04-2022",
    "open": 137.59,
    "high": 139.65,
    "low": 135.19,
    "close": 139.1,
    "volume": 5748289
  },
  {
    "time": "22-04-2022",
    "open": 139.7,
    "high": 140.4399,
    "low": 137.35,
    "close": 138.25,
    "volume": 6508599
  },
  {
    "time": "21-04-2022",
    "open": 138.23,
    "high": 141.88,
    "low": 137.35,
    "close": 139.85,
    "volume": 9922349
  },
  {
    "time": "20-04-2022",
    "open": 135,
    "high": 139.56,
    "low": 133.38,
    "close": 138.32,
    "volume": 17770306
  },
  {
    "time": "19-04-2022",
    "open": 126.08,
    "high": 129.4,
    "low": 126,
    "close": 129.15,
    "volume": 7971361
  },
  {
    "time": "18-04-2022",
    "open": 126.6,
    "high": 127.3899,
    "low": 125.53,
    "close": 126.17,
    "volume": 4884150
  },
  {
    "time": "14-04-2022",
    "open": 128.93,
    "high": 130.58,
    "low": 126.38,
    "close": 126.56,
    "volume": 6384180
  },
  {
    "time": "13-04-2022",
    "open": 125.64,
    "high": 126.67,
    "low": 124.91,
    "close": 126.14,
    "volume": 3064918
  },
  {
    "time": "12-04-2022",
    "open": 126.42,
    "high": 127.34,
    "low": 125.5835,
    "close": 125.98,
    "volume": 2690998
  },
  {
    "time": "11-04-2022",
    "open": 127.95,
    "high": 128.175,
    "low": 126.18,
    "close": 126.37,
    "volume": 3202545
  },
  {
    "time": "08-04-2022",
    "open": 128.01,
    "high": 128.78,
    "low": 127.27,
    "close": 127.73,
    "volume": 3143309
  },
  {
    "time": "07-04-2022",
    "open": 128.87,
    "high": 129.2499,
    "low": 126.73,
    "close": 128.55,
    "volume": 3538317
  },
  {
    "time": "06-04-2022",
    "open": 127.78,
    "high": 129.515,
    "low": 127.25,
    "close": 129.39,
    "volume": 3958057
  },
  {
    "time": "05-04-2022",
    "open": 129.85,
    "high": 131.23,
    "low": 128.66,
    "close": 128.89,
    "volume": 3102238
  },
  {
    "time": "04-04-2022",
    "open": 130.26,
    "high": 130.45,
    "low": 128.93,
    "close": 130.27,
    "volume": 2811100
  },
  {
    "time": "01-04-2022",
    "open": 129.66,
    "high": 130.27,
    "low": 128.06,
    "close": 130.15,
    "volume": 4012373
  },
  {
    "time": "31-03-2022",
    "open": 130.72,
    "high": 131.88,
    "low": 130,
    "close": 130.02,
    "volume": 4274029
  },
  {
    "time": "30-03-2022",
    "open": 132.01,
    "high": 133.08,
    "low": 131.39,
    "close": 132.13,
    "volume": 2622860
  },
  {
    "time": "29-03-2022",
    "open": 132.04,
    "high": 132.84,
    "low": 130.43,
    "close": 131.94,
    "volume": 5791032
  },
  {
    "time": "28-03-2022",
    "open": 130.82,
    "high": 131.5,
    "low": 129.6,
    "close": 131.47,
    "volume": 2483492
  },
  {
    "time": "25-03-2022",
    "open": 129.5,
    "high": 131.4,
    "low": 129.31,
    "close": 131.35,
    "volume": 3516923
  },
  {
    "time": "24-03-2022",
    "open": 128.33,
    "high": 129.37,
    "low": 127.8,
    "close": 129.25,
    "volume": 2971075
  },
  {
    "time": "23-03-2022",
    "open": 129.08,
    "high": 129.32,
    "low": 128.25,
    "close": 128.3,
    "volume": 2924535
  },
  {
    "time": "22-03-2022",
    "open": 128.5,
    "high": 129.3,
    "low": 127.85,
    "close": 129.06,
    "volume": 2649026
  },
  {
    "time": "21-03-2022",
    "open": 129,
    "high": 129.74,
    "low": 127.4,
    "close": 128.1,
    "volume": 3379393
  },
  {
    "time": "18-03-2022",
    "open": 127.38,
    "high": 128.93,
    "low": 126.37,
    "close": 128.76,
    "volume": 7400216
  },
  {
    "time": "17-03-2022",
    "open": 127.1,
    "high": 128.29,
    "low": 126.53,
    "close": 127.96,
    "volume": 3671903
  },
  {
    "time": "16-03-2022",
    "open": 126.5,
    "high": 127.2842,
    "low": 124.71,
    "close": 127.04,
    "volume": 4292845
  },
  {
    "time": "15-03-2022",
    "open": 124,
    "high": 125.9398,
    "low": 123.22,
    "close": 125.64,
    "volume": 4021228
  },
  {
    "time": "14-03-2022",
    "open": 124.45,
    "high": 125.16,
    "low": 122.685,
    "close": 123.94,
    "volume": 3289440
  },
  {
    "time": "11-03-2022",
    "open": 125,
    "high": 126.77,
    "low": 123.91,
    "close": 123.96,
    "volume": 4119630
  },
  {
    "time": "10-03-2022",
    "open": 125.75,
    "high": 126.03,
    "low": 123.125,
    "close": 124.35,
    "volume": 5326835
  },
  {
    "time": "09-03-2022",
    "open": 127.41,
    "high": 128.1,
    "low": 125.91,
    "close": 126.22,
    "volume": 4403499
  },
  {
    "time": "08-03-2022",
    "open": 126.62,
    "high": 128.345,
    "low": 124.82,
    "close": 125.68,
    "volume": 4887565
  },
  {
    "time": "07-03-2022",
    "open": 126.47,
    "high": 127.905,
    "low": 125.62,
    "close": 126.07,
    "volume": 5047358
  },
  {
    "time": "04-03-2022",
    "open": 124.4,
    "high": 127.35,
    "low": 124.2103,
    "close": 126.62,
    "volume": 4402911
  },
  {
    "time": "03-03-2022",
    "open": 124.96,
    "high": 126.665,
    "low": 124.2351,
    "close": 125.93,
    "volume": 5206649
  },
  {
    "time": "02-03-2022",
    "open": 122.59,
    "high": 124.64,
    "low": 121.8,
    "close": 123.86,
    "volume": 4891435
  },
  {
    "time": "01-03-2022",
    "open": 122.67,
    "high": 123.39,
    "low": 120.7,
    "close": 121.35,
    "volume": 4873331
  },
  {
    "time": "28-02-2022",
    "open": 122.21,
    "high": 123.3851,
    "low": 121.0388,
    "close": 122.51,
    "volume": 6757332
  },
  {
    "time": "25-02-2022",
    "open": 122.05,
    "high": 124.2625,
    "low": 121.45,
    "close": 124.18,
    "volume": 4460904
  },
  {
    "time": "24-02-2022",
    "open": 120,
    "high": 122.1,
    "low": 118.81,
    "close": 121.97,
    "volume": 6563202
  },
  {
    "time": "23-02-2022",
    "open": 124.38,
    "high": 124.7,
    "low": 121.87,
    "close": 122.07,
    "volume": 4086367
  },
  {
    "time": "22-02-2022",
    "open": 124.2,
    "high": 125,
    "low": 122.68,
    "close": 123.92,
    "volume": 5306688
  },
  {
    "time": "18-02-2022",
    "open": 124.94,
    "high": 125.44,
    "low": 123.61,
    "close": 124.35,
    "volume": 4609212
  },
  {
    "time": "17-02-2022",
    "open": 128.05,
    "high": 128.5,
    "low": 124.85,
    "close": 124.97,
    "volume": 6796997
  },
  {
    "time": "16-02-2022",
    "open": 129.45,
    "high": 130.44,
    "low": 128.26,
    "close": 129.18,
    "volume": 4875612
  },
  {
    "time": "15-02-2022",
    "open": 130.64,
    "high": 131.68,
    "low": 129.61,
    "close": 129.94,
    "volume": 4395714
  },
  {
    "time": "14-02-2022",
    "open": 132.59,
    "high": 132.65,
    "low": 129.07,
    "close": 130.15,
    "volume": 5345289
  },
  {
    "time": "11-02-2022",
    "open": 133.9,
    "high": 134.7099,
    "low": 132.38,
    "close": 132.69,
    "volume": 4176155
  },
  {
    "time": "10-02-2022",
    "open": 135.47,
    "high": 136.56,
    "low": 133.17,
    "close": 133.52,
    "volume": 5978640
  },
  {
    "time": "09-02-2022",
    "open": 137.84,
    "high": 138.35,
    "low": 136.83,
    "close": 137.79,
    "volume": 5393478
  },
  {
    "time": "08-02-2022",
    "open": 137.23,
    "high": 137.52,
    "low": 135.78,
    "close": 137.02,
    "volume": 4181825
  },
  {
    "time": "07-02-2022",
    "open": 137.45,
    "high": 137.8223,
    "low": 136.27,
    "close": 137.24,
    "volume": 3759046
  },
  {
    "time": "04-02-2022",
    "open": 137.86,
    "high": 138.82,
    "low": 136.215,
    "close": 137.15,
    "volume": 4142045
  },
  {
    "time": "03-02-2022",
    "open": 137,
    "high": 138.76,
    "low": 135.831,
    "close": 137.78,
    "volume": 6100777
  },
  {
    "time": "02-02-2022",
    "open": 135.7,
    "high": 137.555,
    "low": 135.26,
    "close": 137.25,
    "volume": 5357237
  },
  {
    "time": "01-02-2022",
    "open": 133.76,
    "high": 135.96,
    "low": 132.5,
    "close": 135.53,
    "volume": 6206448
  },
  {
    "time": "31-01-2022",
    "open": 134.09,
    "high": 134.09,
    "low": 132.3,
    "close": 133.57,
    "volume": 5859043
  },
  {
    "time": "28-01-2022",
    "open": 133.19,
    "high": 134.53,
    "low": 131.79,
    "close": 134.5,
    "volume": 5471497
  },
  {
    "time": "27-01-2022",
    "open": 133.66,
    "high": 134.75,
    "low": 132.08,
    "close": 132.52,
    "volume": 5499566
  },
  {
    "time": "26-01-2022",
    "open": 136.47,
    "high": 137.07,
    "low": 133.13,
    "close": 134.26,
    "volume": 8335992
  },
  {
    "time": "25-01-2022",
    "open": 129.14,
    "high": 137.3361,
    "low": 128.3,
    "close": 136.1,
    "volume": 19715698
  },
  {
    "time": "24-01-2022",
    "open": 127.99,
    "high": 129.15,
    "low": 124.193,
    "close": 128.82,
    "volume": 13777648
  },
  {
    "time": "21-01-2022",
    "open": 131.65,
    "high": 131.87,
    "low": 129.27,
    "close": 129.35,
    "volume": 5906950
  },
  {
    "time": "20-01-2022",
    "open": 131.26,
    "high": 132.88,
    "low": 130.5705,
    "close": 130.82,
    "volume": 5278244
  },
  {
    "time": "19-01-2022",
    "open": 132.9,
    "high": 133.9,
    "low": 131.5,
    "close": 131.58,
    "volume": 4099035
  },
  {
    "time": "18-01-2022",
    "open": 132.95,
    "high": 133.89,
    "low": 131.78,
    "close": 132.94,
    "volume": 5236258
  },
  {
    "time": "14-01-2022",
    "open": 134.55,
    "high": 135.14,
    "low": 133.3,
    "close": 134.21,
    "volume": 5310305
  },
  {
    "time": "13-01-2022",
    "open": 133.9,
    "high": 136.05,
    "low": 133.56,
    "close": 134.76,
    "volume": 4868349
  },
  {
    "time": "12-01-2022",
    "open": 133.25,
    "high": 134.47,
    "low": 131.37,
    "close": 133.59,
    "volume": 5352014
  },
  {
    "time": "11-01-2022",
    "open": 130.52,
    "high": 133.25,
    "low": 127.97,
    "close": 132.87,
    "volume": 11081210
  },
  {
    "time": "10-01-2022",
    "open": 134.47,
    "high": 136.2,
    "low": 133.38,
    "close": 135.03,
    "volume": 5432807
  },
  {
    "time": "07-01-2022",
    "open": 134.9,
    "high": 135.6618,
    "low": 133.5111,
    "close": 134.83,
    "volume": 5238099
  },
  {
    "time": "06-01-2022",
    "open": 138.2,
    "high": 138.4099,
    "low": 132.51,
    "close": 135.34,
    "volume": 9908081
  },
  {
    "time": "05-01-2022",
    "open": 138.31,
    "high": 142.2,
    "low": 137.88,
    "close": 138.22,
    "volume": 8956910
  },
  {
    "time": "04-01-2022",
    "open": 136.1,
    "high": 139.95,
    "low": 135.9,
    "close": 138.02,
    "volume": 7300011
  },
  {
    "time": "03-01-2022",
    "open": 134.07,
    "high": 136.295,
    "low": 133.63,
    "close": 136.04,
    "volume": 4610665
  },
  {
    "time": "31-12-2021",
    "open": 134,
    "high": 134.99,
    "low": 133.61,
    "close": 133.66,
    "volume": 3315313
  },
  {
    "time": "30-12-2021",
    "open": 133.75,
    "high": 134.37,
    "low": 133.33,
    "close": 133.91,
    "volume": 3158111
  },
  {
    "time": "29-12-2021",
    "open": 132.4,
    "high": 134.21,
    "low": 132.3,
    "close": 133.35,
    "volume": 4239935
  },
  {
    "time": "28-12-2021",
    "open": 131.6,
    "high": 133.22,
    "low": 131.59,
    "close": 132.63,
    "volume": 3447079
  },
  {
    "time": "27-12-2021",
    "open": 130.63,
    "high": 131.65,
    "low": 129.95,
    "close": 131.62,
    "volume": 4294499
  },
  {
    "time": "23-12-2021",
    "open": 130,
    "high": 130.96,
    "low": 129.52,
    "close": 130.63,
    "volume": 3649044
  },
  {
    "time": "22-12-2021",
    "open": 129.06,
    "high": 129.79,
    "low": 127.6,
    "close": 129.75,
    "volume": 3923198
  },
  {
    "time": "21-12-2021",
    "open": 127.66,
    "high": 129.3356,
    "low": 127.66,
    "close": 128.97,
    "volume": 4855972
  },
  {
    "time": "20-12-2021",
    "open": 125.72,
    "high": 127.2,
    "low": 124.7,
    "close": 127.06,
    "volume": 4941411
  },
  {
    "time": "17-12-2021",
    "open": 125.87,
    "high": 128.64,
    "low": 125.2093,
    "close": 127.4,
    "volume": 10382693
  },
  {
    "time": "16-12-2021",
    "open": 123.51,
    "high": 126.6399,
    "low": 123.475,
    "close": 125.93,
    "volume": 7280537
  },
  {
    "time": "15-12-2021",
    "open": 123.8,
    "high": 124.815,
    "low": 122.18,
    "close": 123.11,
    "volume": 4990028
  },
  {
    "time": "14-12-2021",
    "open": 122.35,
    "high": 125.03,
    "low": 122.3,
    "close": 123.76,
    "volume": 5716124
  },
  {
    "time": "13-12-2021",
    "open": 123.76,
    "high": 124.3554,
    "low": 120.79,
    "close": 122.58,
    "volume": 6847468
  },
  {
    "time": "10-12-2021",
    "open": 124.3,
    "high": 125.33,
    "low": 123.36,
    "close": 124.09,
    "volume": 4966921
  },
  {
    "time": "09-12-2021",
    "open": 122.15,
    "high": 123.95,
    "low": 121.79,
    "close": 123.57,
    "volume": 4601092
  },
  {
    "time": "08-12-2021",
    "open": 122,
    "high": 123.38,
    "low": 121.52,
    "close": 123.02,
    "volume": 5483948
  },
  {
    "time": "07-12-2021",
    "open": 120.475,
    "high": 122.08,
    "low": 120.07,
    "close": 121.58,
    "volume": 5193991
  },
  {
    "time": "06-12-2021",
    "open": 119.4,
    "high": 121.15,
    "low": 119.4,
    "close": 119.91,
    "volume": 4785560
  },
  {
    "time": "03-12-2021",
    "open": 117.36,
    "high": 119.36,
    "low": 117.36,
    "close": 118.84,
    "volume": 6630139
  },
  {
    "time": "02-12-2021",
    "open": 117.37,
    "high": 117.98,
    "low": 116.56,
    "close": 116.9,
    "volume": 5267149
  },
  {
    "time": "01-12-2021",
    "open": 118.25,
    "high": 118.93,
    "low": 116.85,
    "close": 116.92,
    "volume": 5959867
  },
  {
    "time": "30-11-2021",
    "open": 117.5,
    "high": 119.2399,
    "low": 116.45,
    "close": 117.1,
    "volume": 9252701
  },
  {
    "time": "29-11-2021",
    "open": 118.62,
    "high": 119.61,
    "low": 117.53,
    "close": 118.5,
    "volume": 8949795
  },
  {
    "time": "26-11-2021",
    "open": 115,
    "high": 116.335,
    "low": 114.56,
    "close": 115.81,
    "volume": 3322012
  },
  {
    "time": "24-11-2021",
    "open": 116.16,
    "high": 117.27,
    "low": 116.08,
    "close": 116.73,
    "volume": 3220802
  },
  {
    "time": "23-11-2021",
    "open": 116.79,
    "high": 117.94,
    "low": 116.04,
    "close": 116.79,
    "volume": 4914995
  },
  {
    "time": "22-11-2021",
    "open": 116,
    "high": 118.81,
    "low": 115.19,
    "close": 116.47,
    "volume": 6417218
  },
  {
    "time": "19-11-2021",
    "open": 116.49,
    "high": 116.56,
    "low": 115.27,
    "close": 116.05,
    "volume": 5384548
  },
  {
    "time": "18-11-2021",
    "open": 118.36,
    "high": 118.36,
    "low": 116.31,
    "close": 116.66,
    "volume": 5047879
  },
  {
    "time": "17-11-2021",
    "open": 118.38,
    "high": 119.33,
    "low": 117.78,
    "close": 118.06,
    "volume": 4043289
  },
  {
    "time": "16-11-2021",
    "open": 118.92,
    "high": 119.9,
    "low": 118.42,
    "close": 118.46,
    "volume": 4750760
  },
  {
    "time": "15-11-2021",
    "open": 119.54,
    "high": 120.16,
    "low": 118.31,
    "close": 118.87,
    "volume": 5046321
  },
  {
    "time": "12-11-2021",
    "open": 120,
    "high": 120.64,
    "low": 118.78,
    "close": 118.96,
    "volume": 5376966
  },
  {
    "time": "11-11-2021",
    "open": 120.9,
    "high": 121.7894,
    "low": 120.08,
    "close": 120.27,
    "volume": 4643257
  },
  {
    "time": "10-11-2021",
    "open": 121,
    "high": 122.43,
    "low": 119.932,
    "close": 120.22,
    "volume": 6268961
  },
  {
    "time": "09-11-2021",
    "open": 122.56,
    "high": 122.9,
    "low": 120.26,
    "close": 120.85,
    "volume": 7195453
  },
  {
    "time": "08-11-2021",
    "open": 123.985,
    "high": 124.78,
    "low": 123.53,
    "close": 124.54,
    "volume": 5625275
  },
  {
    "time": "05-11-2021",
    "open": 121.43,
    "high": 123.77,
    "low": 121.43,
    "close": 123.61,
    "volume": 6790478
  },
  {
    "time": "04-11-2021",
    "open": 123.05,
    "high": 123.34,
    "low": 119.9,
    "close": 120.85,
    "volume": 7208736
  },
  {
    "time": "03-11-2021",
    "open": 126.23,
    "high": 127.29,
    "low": 125.68,
    "close": 127.13,
    "volume": 5421406
  },
  {
    "time": "02-11-2021",
    "open": 126.3,
    "high": 127.17,
    "low": 124.91,
    "close": 126.18,
    "volume": 4496393
  },
  {
    "time": "01-11-2021",
    "open": 125.05,
    "high": 126.31,
    "low": 123.8375,
    "close": 126.28,
    "volume": 5874767
  },
  {
    "time": "29-10-2021",
    "open": 125.43,
    "high": 126.32,
    "low": 124.91,
    "close": 125.1,
    "volume": 5916789
  },
  {
    "time": "28-10-2021",
    "open": 125.17,
    "high": 126.31,
    "low": 124.62,
    "close": 125.84,
    "volume": 6503003
  },
  {
    "time": "27-10-2021",
    "open": 127.44,
    "high": 127.88,
    "low": 125.01,
    "close": 125.17,
    "volume": 6973432
  },
  {
    "time": "26-10-2021",
    "open": 127.52,
    "high": 128.3,
    "low": 126.755,
    "close": 127.13,
    "volume": 8520872
  },
  {
    "time": "25-10-2021",
    "open": 127.53,
    "high": 128.65,
    "low": 126.94,
    "close": 127.64,
    "volume": 6374038
  },
  {
    "time": "22-10-2021",
    "open": 128.05,
    "high": 130.25,
    "low": 126.611,
    "close": 127.88,
    "volume": 11582195
  },
  {
    "time": "21-10-2021",
    "open": 133.51,
    "high": 133.72,
    "low": 128.1,
    "close": 128.33,
    "volume": 31466529
  },
  {
    "time": "20-10-2021",
    "open": 141.68,
    "high": 142.2,
    "low": 140.7,
    "close": 141.9,
    "volume": 6189255
  },
  {
    "time": "19-10-2021",
    "open": 141.08,
    "high": 142.94,
    "low": 140.5201,
    "close": 141.98,
    "volume": 4339548
  },
  {
    "time": "18-10-2021",
    "open": 144,
    "high": 144.94,
    "low": 141.759,
    "close": 142.32,
    "volume": 6154055
  },
  {
    "time": "15-10-2021",
    "open": 143.39,
    "high": 144.85,
    "low": 142.79,
    "close": 144.61,
    "volume": 3222778
  },
  {
    "time": "14-10-2021",
    "open": 141.04,
    "high": 143.92,
    "low": 141.01,
    "close": 143.39,
    "volume": 4217305
  },
  {
    "time": "13-10-2021",
    "open": 140.52,
    "high": 141.41,
    "low": 139.66,
    "close": 140.76,
    "volume": 2880747
  },
  {
    "time": "12-10-2021",
    "open": 142.21,
    "high": 142.3,
    "low": 140.3,
    "close": 140.47,
    "volume": 3148559
  },
  {
    "time": "11-10-2021",
    "open": 143.5,
    "high": 144.08,
    "low": 142.4,
    "close": 142.43,
    "volume": 2793298
  },
  {
    "time": "08-10-2021",
    "open": 141.81,
    "high": 143.65,
    "low": 141.05,
    "close": 143.22,
    "volume": 3731279
  },
  {
    "time": "07-10-2021",
    "open": 142.73,
    "high": 143.395,
    "low": 141.53,
    "close": 141.81,
    "volume": 3823803
  },
  {
    "time": "06-10-2021",
    "open": 142.48,
    "high": 143.37,
    "low": 140.89,
    "close": 142.36,
    "volume": 5328433
  },
  {
    "time": "05-10-2021",
    "open": 144.75,
    "high": 145,
    "low": 142.64,
    "close": 143.15,
    "volume": 6976648
  },
  {
    "time": "04-10-2021",
    "open": 142.74,
    "high": 146,
    "low": 142.3501,
    "close": 144.11,
    "volume": 7351128
  },
  {
    "time": "01-10-2021",
    "open": 141,
    "high": 143.97,
    "low": 140.37,
    "close": 143.32,
    "volume": 6604064
  },
  {
    "time": "30-09-2021",
    "open": 140,
    "high": 140.57,
    "low": 138.5,
    "close": 138.93,
    "volume": 5824431
  },
  {
    "time": "29-09-2021",
    "open": 137.73,
    "high": 139.93,
    "low": 136.44,
    "close": 139.18,
    "volume": 3774236
  },
  {
    "time": "28-09-2021",
    "open": 139.17,
    "high": 139.688,
    "low": 137.21,
    "close": 137.47,
    "volume": 4314595
  },
  {
    "time": "27-09-2021",
    "open": 137.96,
    "high": 139.065,
    "low": 137.48,
    "close": 138.56,
    "volume": 3306865
  },
  {
    "time": "24-09-2021",
    "open": 137.03,
    "high": 138.48,
    "low": 136.75,
    "close": 137.49,
    "volume": 2964397
  },
  {
    "time": "23-09-2021",
    "open": 135.25,
    "high": 137.42,
    "low": 135.03,
    "close": 136.73,
    "volume": 3013238
  },
  {
    "time": "22-09-2021",
    "open": 133.72,
    "high": 135.37,
    "low": 133.47,
    "close": 134.63,
    "volume": 3602416
  },
  {
    "time": "21-09-2021",
    "open": 135.11,
    "high": 135.65,
    "low": 132.94,
    "close": 132.97,
    "volume": 4074528
  },
  {
    "time": "20-09-2021",
    "open": 133.9,
    "high": 135.18,
    "low": 132.78,
    "close": 134.31,
    "volume": 4770651
  },
  {
    "time": "17-09-2021",
    "open": 135.75,
    "high": 135.9199,
    "low": 135.05,
    "close": 135.23,
    "volume": 5633480
  },
  {
    "time": "16-09-2021",
    "open": 137.28,
    "high": 137.95,
    "low": 135.71,
    "close": 136.43,
    "volume": 2643975
  },
  {
    "time": "15-09-2021",
    "open": 136.22,
    "high": 137.8,
    "low": 135.67,
    "close": 137.2,
    "volume": 3254122
  },
  {
    "time": "14-09-2021",
    "open": 138.4,
    "high": 138.57,
    "low": 135.34,
    "close": 136.22,
    "volume": 4454291
  },
  {
    "time": "13-09-2021",
    "open": 138.4,
    "high": 138.99,
    "low": 137.51,
    "close": 138.15,
    "volume": 4144345
  },
  {
    "time": "10-09-2021",
    "open": 138.82,
    "high": 139.3699,
    "low": 137,
    "close": 137.02,
    "volume": 3975115
  },
  {
    "time": "09-09-2021",
    "open": 137.85,
    "high": 138.96,
    "low": 137.555,
    "close": 137.74,
    "volume": 3508363
  },
  {
    "time": "08-09-2021",
    "open": 138.14,
    "high": 139.09,
    "low": 137.6,
    "close": 138.67,
    "volume": 2985409
  },
  {
    "time": "07-09-2021",
    "open": 139.65,
    "high": 139.79,
    "low": 137.7614,
    "close": 138.06,
    "volume": 3285363
  },
  {
    "time": "03-09-2021",
    "open": 139.68,
    "high": 140.47,
    "low": 139.3,
    "close": 139.58,
    "volume": 1924215
  },
  {
    "time": "02-09-2021",
    "open": 139.72,
    "high": 140.05,
    "low": 139.03,
    "close": 140.01,
    "volume": 2715659
  },
  {
    "time": "01-09-2021",
    "open": 139.98,
    "high": 140.0699,
    "low": 139.19,
    "close": 139.3,
    "volume": 2474544
  },
  {
    "time": "31-08-2021",
    "open": 139.54,
    "high": 140.94,
    "low": 138.95,
    "close": 140.34,
    "volume": 4235101
  },
  {
    "time": "30-08-2021",
    "open": 139.5,
    "high": 139.88,
    "low": 138.815,
    "close": 138.97,
    "volume": 1995526
  },
  {
    "time": "27-08-2021",
    "open": 138.71,
    "high": 139.585,
    "low": 138.4,
    "close": 139.41,
    "volume": 2459643
  },
  {
    "time": "26-08-2021",
    "open": 139.97,
    "high": 140.8,
    "low": 138.71,
    "close": 138.78,
    "volume": 2498915
  },
  {
    "time": "25-08-2021",
    "open": 139.92,
    "high": 140.8,
    "low": 139.46,
    "close": 139.86,
    "volume": 2012817
  },
  {
    "time": "24-08-2021",
    "open": 139.78,
    "high": 140.23,
    "low": 139.32,
    "close": 139.84,
    "volume": 2365638
  },
  {
    "time": "23-08-2021",
    "open": 139.62,
    "high": 140.15,
    "low": 138.8,
    "close": 139.62,
    "volume": 3039587
  },
  {
    "time": "20-08-2021",
    "open": 137.74,
    "high": 139.38,
    "low": 137.27,
    "close": 139.11,
    "volume": 2657763
  },
  {
    "time": "19-08-2021",
    "open": 138.69,
    "high": 139.45,
    "low": 137.21,
    "close": 138.02,
    "volume": 4160129
  },
  {
    "time": "18-08-2021",
    "open": 141.67,
    "high": 141.915,
    "low": 139.39,
    "close": 139.47,
    "volume": 3510694
  },
  {
    "time": "17-08-2021",
    "open": 143,
    "high": 143.16,
    "low": 141.09,
    "close": 142.42,
    "volume": 3074078
  },
  {
    "time": "16-08-2021",
    "open": 143.23,
    "high": 143.74,
    "low": 142.23,
    "close": 143.59,
    "volume": 2786343
  },
  {
    "time": "13-08-2021",
    "open": 142.64,
    "high": 143.58,
    "low": 142.44,
    "close": 143.18,
    "volume": 1908951
  },
  {
    "time": "12-08-2021",
    "open": 142.26,
    "high": 143.15,
    "low": 142.0766,
    "close": 143.07,
    "volume": 2089418
  },
  {
    "time": "11-08-2021",
    "open": 141.78,
    "high": 142.7685,
    "low": 141.5,
    "close": 142.13,
    "volume": 4259952
  },
  {
    "time": "10-08-2021",
    "open": 141.21,
    "high": 141.811,
    "low": 140.34,
    "close": 141.38,
    "volume": 5299869
  },
  {
    "time": "09-08-2021",
    "open": 142.2,
    "high": 142.495,
    "low": 140.97,
    "close": 141.25,
    "volume": 4904065
  },
  {
    "time": "06-08-2021",
    "open": 143,
    "high": 144.39,
    "low": 142.89,
    "close": 144.09,
    "volume": 3826835
  },
  {
    "time": "05-08-2021",
    "open": 143.03,
    "high": 143.41,
    "low": 142.22,
    "close": 142.77,
    "volume": 2757389
  },
  {
    "time": "04-08-2021",
    "open": 143.8,
    "high": 144.18,
    "low": 142.47,
    "close": 142.76,
    "volume": 2830079
  },
  {
    "time": "03-08-2021",
    "open": 141.9,
    "high": 144.7,
    "low": 141.65,
    "close": 144.07,
    "volume": 4084724
  },
  {
    "time": "02-08-2021",
    "open": 141.45,
    "high": 143.06,
    "low": 141.03,
    "close": 141.42,
    "volume": 2929540
  },
  {
    "time": "30-07-2021",
    "open": 141.52,
    "high": 141.85,
    "low": 140.79,
    "close": 140.96,
    "volume": 3535555
  },
  {
    "time": "29-07-2021",
    "open": 142.33,
    "high": 142.96,
    "low": 141.6,
    "close": 141.93,
    "volume": 2657669
  },
  {
    "time": "28-07-2021",
    "open": 143.01,
    "high": 143.1,
    "low": 141.64,
    "close": 141.77,
    "volume": 2544099
  },
  {
    "time": "27-07-2021",
    "open": 142.53,
    "high": 143.64,
    "low": 141.6,
    "close": 142.75,
    "volume": 3137027
  },
  {
    "time": "26-07-2021",
    "open": 141.39,
    "high": 143,
    "low": 141.13,
    "close": 142.77,
    "volume": 4246266
  },
  {
    "time": "23-07-2021",
    "open": 140.96,
    "high": 141.7,
    "low": 140.33,
    "close": 141.34,
    "volume": 4474157
  },
  {
    "time": "22-07-2021",
    "open": 141.66,
    "high": 141.81,
    "low": 140.41,
    "close": 140.71,
    "volume": 3314153
  },
  {
    "time": "21-07-2021",
    "open": 139.97,
    "high": 141.39,
    "low": 139.65,
    "close": 141.3,
    "volume": 4803977
  },
  {
    "time": "20-07-2021",
    "open": 143,
    "high": 144.92,
    "low": 138.7,
    "close": 139.97,
    "volume": 13611675
  },
  {
    "time": "19-07-2021",
    "open": 136.45,
    "high": 138.49,
    "low": 136.2089,
    "close": 137.92,
    "volume": 8582302
  },
  {
    "time": "16-07-2021",
    "open": 141,
    "high": 141,
    "low": 138.59,
    "close": 138.9,
    "volume": 4109308
  },
  {
    "time": "15-07-2021",
    "open": 139.32,
    "high": 140.46,
    "low": 138.8005,
    "close": 140.45,
    "volume": 3639698
  },
  {
    "time": "14-07-2021",
    "open": 140.72,
    "high": 140.75,
    "low": 138.9273,
    "close": 139.82,
    "volume": 4403752
  },
  {
    "time": "13-07-2021",
    "open": 140.92,
    "high": 140.92,
    "low": 139.63,
    "close": 140.28,
    "volume": 3164294
  },
  {
    "time": "12-07-2021",
    "open": 141.43,
    "high": 141.9599,
    "low": 140.115,
    "close": 140.92,
    "volume": 3342627
  },
  {
    "time": "09-07-2021",
    "open": 141.45,
    "high": 141.98,
    "low": 140.841,
    "close": 141.52,
    "volume": 3904059
  },
  {
    "time": "08-07-2021",
    "open": 137.78,
    "high": 141.31,
    "low": 137.66,
    "close": 140.74,
    "volume": 5487425
  },
  {
    "time": "07-07-2021",
    "open": 138.76,
    "high": 140.33,
    "low": 138.76,
    "close": 139.82,
    "volume": 4059667
  },
  {
    "time": "06-07-2021",
    "open": 139.99,
    "high": 140.42,
    "low": 137.1,
    "close": 138.78,
    "volume": 8093747
  },
  {
    "time": "02-07-2021",
    "open": 146.91,
    "high": 146.95,
    "low": 139.46,
    "close": 140.02,
    "volume": 16828161
  },
  {
    "time": "01-07-2021",
    "open": 146.96,
    "high": 147.5,
    "low": 146.57,
    "close": 146.84,
    "volume": 2686289
  },
  {
    "time": "30-06-2021",
    "open": 145.13,
    "high": 146.93,
    "low": 144.71,
    "close": 146.59,
    "volume": 3245091
  },
  {
    "time": "29-06-2021",
    "open": 145.26,
    "high": 146.74,
    "low": 145.1,
    "close": 145.55,
    "volume": 2428626
  },
  {
    "time": "28-06-2021",
    "open": 147.01,
    "high": 147.3,
    "low": 144.9121,
    "close": 145.29,
    "volume": 3888869
  },
  {
    "time": "25-06-2021",
    "open": 145.38,
    "high": 146.93,
    "low": 144.945,
    "close": 146.84,
    "volume": 3594218
  },
  {
    "time": "24-06-2021",
    "open": 145.8,
    "high": 146.19,
    "low": 144.69,
    "close": 145.44,
    "volume": 3609679
  },
  {
    "time": "23-06-2021",
    "open": 146.43,
    "high": 146.5,
    "low": 144.57,
    "close": 144.61,
    "volume": 3202644
  },
  {
    "time": "22-06-2021",
    "open": 146.52,
    "high": 146.81,
    "low": 145.35,
    "close": 146.36,
    "volume": 2437121
  },
  {
    "time": "21-06-2021",
    "open": 144.11,
    "high": 147.07,
    "low": 144.06,
    "close": 146.65,
    "volume": 4285711
  },
  {
    "time": "18-06-2021",
    "open": 144.48,
    "high": 144.68,
    "low": 143.04,
    "close": 143.12,
    "volume": 9156505
  },
  {
    "time": "17-06-2021",
    "open": 147.55,
    "high": 148.06,
    "low": 145.28,
    "close": 145.6,
    "volume": 4367387
  },
  {
    "time": "16-06-2021",
    "open": 149.76,
    "high": 149.76,
    "low": 147.22,
    "close": 147.83,
    "volume": 3795420
  },
  {
    "time": "15-06-2021",
    "open": 149.85,
    "high": 149.91,
    "low": 148.6,
    "close": 149.36,
    "volume": 2513281
  },
  {
    "time": "14-06-2021",
    "open": 150.71,
    "high": 151.03,
    "low": 148.655,
    "close": 150.03,
    "volume": 3344845
  },
  {
    "time": "11-06-2021",
    "open": 150.43,
    "high": 151.845,
    "low": 150.37,
    "close": 151.28,
    "volume": 3438255
  },
  {
    "time": "10-06-2021",
    "open": 151.47,
    "high": 152.84,
    "low": 149.76,
    "close": 150.54,
    "volume": 4758488
  },
  {
    "time": "09-06-2021",
    "open": 149.03,
    "high": 151.07,
    "low": 148.82,
    "close": 150.67,
    "volume": 5303252
  },
  {
    "time": "08-06-2021",
    "open": 148.12,
    "high": 150.2,
    "low": 148.12,
    "close": 149.07,
    "volume": 5080099
  },
  {
    "time": "07-06-2021",
    "open": 147.55,
    "high": 148.74,
    "low": 147.17,
    "close": 148.02,
    "volume": 3462712
  },
  {
    "time": "04-06-2021",
    "open": 146,
    "high": 147.55,
    "low": 145.76,
    "close": 147.42,
    "volume": 3117905
  },
  {
    "time": "03-06-2021",
    "open": 144.91,
    "high": 145.88,
    "low": 144.04,
    "close": 145.55,
    "volume": 4130741
  },
  {
    "time": "02-06-2021",
    "open": 144.62,
    "high": 145.75,
    "low": 144.11,
    "close": 145.72,
    "volume": 2786916
  },
  {
    "time": "01-06-2021",
    "open": 145,
    "high": 145.83,
    "low": 143.75,
    "close": 144.19,
    "volume": 2417455
  },
  {
    "time": "28-05-2021",
    "open": 144.21,
    "high": 144.33,
    "low": 143.485,
    "close": 143.74,
    "volume": 2534811
  },
  {
    "time": "27-05-2021",
    "open": 143.82,
    "high": 144.77,
    "low": 143.63,
    "close": 143.82,
    "volume": 5630345
  },
  {
    "time": "26-05-2021",
    "open": 143.5,
    "high": 143.9894,
    "low": 143.04,
    "close": 143.38,
    "volume": 3083789
  },
  {
    "time": "25-05-2021",
    "open": 144.92,
    "high": 145,
    "low": 143.2,
    "close": 143.79,
    "volume": 3937323
  },
  {
    "time": "24-05-2021",
    "open": 145.06,
    "high": 145.39,
    "low": 144.18,
    "close": 144.72,
    "volume": 3297570
  },
  {
    "time": "21-05-2021",
    "open": 144.28,
    "high": 145.699,
    "low": 144.28,
    "close": 144.74,
    "volume": 4033482
  },
  {
    "time": "20-05-2021",
    "open": 143.12,
    "high": 144.91,
    "low": 142.16,
    "close": 143.88,
    "volume": 4112690
  },
  {
    "time": "19-05-2021",
    "open": 142.32,
    "high": 143.2,
    "low": 140.92,
    "close": 143.19,
    "volume": 4300732
  },
  {
    "time": "18-05-2021",
    "open": 144.78,
    "high": 145.3,
    "low": 143.23,
    "close": 143.91,
    "volume": 3824050
  },
  {
    "time": "17-05-2021",
    "open": 144.44,
    "high": 145.8,
    "low": 144.33,
    "close": 145.11,
    "volume": 4275172
  },
  {
    "time": "14-05-2021",
    "open": 145.11,
    "high": 145.69,
    "low": 143.96,
    "close": 144.68,
    "volume": 2747385
  },
  {
    "time": "13-05-2021",
    "open": 141.45,
    "high": 144.9,
    "low": 141.28,
    "close": 144.17,
    "volume": 4598920
  },
  {
    "time": "12-05-2021",
    "open": 143.84,
    "high": 144.15,
    "low": 141.14,
    "close": 141.3,
    "volume": 5959579
  },
  {
    "time": "11-05-2021",
    "open": 144.99,
    "high": 145.19,
    "low": 142.9,
    "close": 144.22,
    "volume": 7126404
  },
  {
    "time": "10-05-2021",
    "open": 145.8,
    "high": 148.38,
    "low": 145.8,
    "close": 146.17,
    "volume": 6983377
  },
  {
    "time": "07-05-2021",
    "open": 145.92,
    "high": 146.14,
    "low": 144.57,
    "close": 145.46,
    "volume": 7003467
  },
  {
    "time": "06-05-2021",
    "open": 145.26,
    "high": 148.515,
    "low": 145.18,
    "close": 148.42,
    "volume": 7503487
  },
  {
    "time": "05-05-2021",
    "open": 145.94,
    "high": 145.94,
    "low": 144.97,
    "close": 145.22,
    "volume": 4999900
  },
  {
    "time": "04-05-2021",
    "open": 144.41,
    "high": 146.59,
    "low": 144.33,
    "close": 145.75,
    "volume": 6350479
  },
  {
    "time": "03-05-2021",
    "open": 143.81,
    "high": 145.75,
    "low": 143.55,
    "close": 144.75,
    "volume": 5733463
  },
  {
    "time": "30-04-2021",
    "open": 143.7,
    "high": 143.83,
    "low": 140.55,
    "close": 141.88,
    "volume": 8872181
  },
  {
    "time": "29-04-2021",
    "open": 144.13,
    "high": 148.74,
    "low": 142.98,
    "close": 144.24,
    "volume": 4353880
  },
  {
    "time": "28-04-2021",
    "open": 142.92,
    "high": 143.4,
    "low": 142.1,
    "close": 143,
    "volume": 3768129
  },
  {
    "time": "27-04-2021",
    "open": 141.69,
    "high": 142.56,
    "low": 140.75,
    "close": 142.01,
    "volume": 3884037
  },
  {
    "time": "26-04-2021",
    "open": 142.42,
    "high": 143.63,
    "low": 141.48,
    "close": 141.57,
    "volume": 4710763
  },
  {
    "time": "23-04-2021",
    "open": 141.31,
    "high": 143.61,
    "low": 140.95,
    "close": 142.43,
    "volume": 4556700
  },
  {
    "time": "22-04-2021",
    "open": 143.7,
    "high": 144.74,
    "low": 141,
    "close": 141.28,
    "volume": 7101368
  },
  {
    "time": "21-04-2021",
    "open": 138.06,
    "high": 143.73,
    "low": 137.71,
    "close": 143.55,
    "volume": 11909005
  },
  {
    "time": "20-04-2021",
    "open": 137.07,
    "high": 139.77,
    "low": 136.7,
    "close": 138.16,
    "volume": 15480579
  },
  {
    "time": "19-04-2021",
    "open": 133.6,
    "high": 133.815,
    "low": 132.58,
    "close": 133.12,
    "volume": 8198582
  },
  {
    "time": "16-04-2021",
    "open": 133,
    "high": 134.1,
    "low": 132.95,
    "close": 133.59,
    "volume": 5291756
  },
  {
    "time": "15-04-2021",
    "open": 133.28,
    "high": 133.87,
    "low": 132.22,
    "close": 132.58,
    "volume": 3883955
  },
  {
    "time": "14-04-2021",
    "open": 131.305,
    "high": 132.78,
    "low": 130.52,
    "close": 132.63,
    "volume": 5868049
  },
  {
    "time": "13-04-2021",
    "open": 133,
    "high": 133.62,
    "low": 130.38,
    "close": 131.18,
    "volume": 8033530
  },
  {
    "time": "12-04-2021",
    "open": 135.02,
    "high": 135.37,
    "low": 133.85,
    "close": 134.59,
    "volume": 3753959
  },
  {
    "time": "09-04-2021",
    "open": 134.87,
    "high": 135.74,
    "low": 134.71,
    "close": 135.73,
    "volume": 3023916
  },
  {
    "time": "08-04-2021",
    "open": 134.57,
    "high": 135.6299,
    "low": 134.16,
    "close": 135.12,
    "volume": 4087228
  },
  {
    "time": "07-04-2021",
    "open": 133.84,
    "high": 134.94,
    "low": 133.78,
    "close": 134.93,
    "volume": 2976136
  },
  {
    "time": "06-04-2021",
    "open": 135.58,
    "high": 135.64,
    "low": 134.09,
    "close": 134.22,
    "volume": 3620964
  },
  {
    "time": "05-04-2021",
    "open": 133.64,
    "high": 136.69,
    "low": 133.4,
    "close": 135.93,
    "volume": 5471616
  },
  {
    "time": "01-04-2021",
    "open": 133.76,
    "high": 133.93,
    "low": 132.27,
    "close": 133.23,
    "volume": 4074161
  },
  {
    "time": "31-03-2021",
    "open": 134.54,
    "high": 134.71,
    "low": 132.71,
    "close": 133.26,
    "volume": 4945315
  },
  {
    "time": "30-03-2021",
    "open": 135.86,
    "high": 136.27,
    "low": 134.02,
    "close": 134.72,
    "volume": 4790366
  },
  {
    "time": "29-03-2021",
    "open": 135.98,
    "high": 137.07,
    "low": 135.51,
    "close": 135.86,
    "volume": 4622664
  },
  {
    "time": "26-03-2021",
    "open": 133.29,
    "high": 136.48,
    "low": 133.12,
    "close": 136.38,
    "volume": 5567592
  },
  {
    "time": "25-03-2021",
    "open": 130.33,
    "high": 133.24,
    "low": 129.77,
    "close": 133.07,
    "volume": 5553966
  },
  {
    "time": "24-03-2021",
    "open": 130.95,
    "high": 132.11,
    "low": 130.57,
    "close": 130.62,
    "volume": 4005027
  },
  {
    "time": "23-03-2021",
    "open": 130.44,
    "high": 131.56,
    "low": 129.8,
    "close": 130.46,
    "volume": 4356359
  },
  {
    "time": "22-03-2021",
    "open": 128.5,
    "high": 130.72,
    "low": 127.89,
    "close": 130.55,
    "volume": 4164914
  },
  {
    "time": "19-03-2021",
    "open": 130.02,
    "high": 130.44,
    "low": 128.53,
    "close": 128.9,
    "volume": 9844983
  },
  {
    "time": "18-03-2021",
    "open": 128.94,
    "high": 130.995,
    "low": 127.79,
    "close": 130.06,
    "volume": 5835669
  },
  {
    "time": "17-03-2021",
    "open": 128.46,
    "high": 129.49,
    "low": 127.49,
    "close": 129.03,
    "volume": 4291351
  },
  {
    "time": "16-03-2021",
    "open": 128.28,
    "high": 128.52,
    "low": 127.34,
    "close": 128.24,
    "volume": 4653178
  },
  {
    "time": "15-03-2021",
    "open": 127.77,
    "high": 128.75,
    "low": 127.5401,
    "close": 128.58,
    "volume": 3421281
  },
  {
    "time": "12-03-2021",
    "open": 127.19,
    "high": 127.68,
    "low": 126.61,
    "close": 127.61,
    "volume": 4010100
  },
  {
    "time": "11-03-2021",
    "open": 128.09,
    "high": 128.64,
    "low": 126.78,
    "close": 127.14,
    "volume": 5146937
  },
  {
    "time": "10-03-2021",
    "open": 125.05,
    "high": 128.24,
    "low": 124.61,
    "close": 127.87,
    "volume": 7247807
  },
  {
    "time": "09-03-2021",
    "open": 125.4,
    "high": 126.43,
    "low": 124.16,
    "close": 124.18,
    "volume": 5609029
  },
  {
    "time": "08-03-2021",
    "open": 122.99,
    "high": 126.85,
    "low": 122.88,
    "close": 124.81,
    "volume": 7239191
  },
  {
    "time": "05-03-2021",
    "open": 120.64,
    "high": 123.75,
    "low": 120.25,
    "close": 122.83,
    "volume": 6949265
  },
  {
    "time": "04-03-2021",
    "open": 122,
    "high": 123.22,
    "low": 118.755,
    "close": 120.11,
    "volume": 8068492
  },
  {
    "time": "03-03-2021",
    "open": 120.5,
    "high": 122.634,
    "low": 119.98,
    "close": 122.36,
    "volume": 7404269
  },
  {
    "time": "02-03-2021",
    "open": 120.74,
    "high": 121.9,
    "low": 120.26,
    "close": 120.33,
    "volume": 4524299
  },
  {
    "time": "01-03-2021",
    "open": 120.35,
    "high": 122.32,
    "low": 119.8649,
    "close": 120.74,
    "volume": 5715767
  },
  {
    "time": "26-02-2021",
    "open": 122.25,
    "high": 122.25,
    "low": 118.88,
    "close": 118.93,
    "volume": 8868848
  },
  {
    "time": "25-02-2021",
    "open": 123.37,
    "high": 124.35,
    "low": 122.335,
    "close": 122.47,
    "volume": 5672671
  },
  {
    "time": "24-02-2021",
    "open": 120.8,
    "high": 123.49,
    "low": 120.52,
    "close": 123.21,
    "volume": 5819983
  },
  {
    "time": "23-02-2021",
    "open": 120.92,
    "high": 121.35,
    "low": 119.8,
    "close": 120.71,
    "volume": 4531464
  },
  {
    "time": "22-02-2021",
    "open": 118.5,
    "high": 121.125,
    "low": 118.44,
    "close": 120.86,
    "volume": 5838841
  },
  {
    "time": "19-02-2021",
    "open": 120.75,
    "high": 120.76,
    "low": 118.38,
    "close": 118.99,
    "volume": 6578741
  },
  {
    "time": "18-02-2021",
    "open": 120.5,
    "high": 120.94,
    "low": 119.7,
    "close": 120.73,
    "volume": 5399145
  },
  {
    "time": "17-02-2021",
    "open": 119.27,
    "high": 120.56,
    "low": 119.02,
    "close": 119.97,
    "volume": 3949876
  },
  {
    "time": "16-02-2021",
    "open": 120.15,
    "high": 120.6,
    "low": 119.36,
    "close": 120.07,
    "volume": 6639790
  },
  {
    "time": "12-02-2021",
    "open": 121,
    "high": 121.36,
    "low": 120.09,
    "close": 120.8,
    "volume": 3871195
  },
  {
    "time": "11-02-2021",
    "open": 122,
    "high": 122.205,
    "low": 120.63,
    "close": 120.91,
    "volume": 5381556
  },
  {
    "time": "10-02-2021",
    "open": 123.03,
    "high": 123.41,
    "low": 121.2138,
    "close": 122.24,
    "volume": 4831858
  },
  {
    "time": "09-02-2021",
    "open": 121.9,
    "high": 122.5953,
    "low": 121.05,
    "close": 122.1,
    "volume": 4661655
  },
  {
    "time": "08-02-2021",
    "open": 122.62,
    "high": 123.9767,
    "low": 122.4,
    "close": 123.61,
    "volume": 5887999
  },
  {
    "time": "05-02-2021",
    "open": 121,
    "high": 121.81,
    "low": 120.52,
    "close": 121.79,
    "volume": 4565727
  },
  {
    "time": "04-02-2021",
    "open": 119.91,
    "high": 121.1,
    "low": 118.87,
    "close": 121.02,
    "volume": 4562124
  },
  {
    "time": "03-02-2021",
    "open": 119.04,
    "high": 119.8,
    "low": 118.12,
    "close": 119.12,
    "volume": 6715366
  },
  {
    "time": "02-02-2021",
    "open": 119.36,
    "high": 121,
    "low": 119.28,
    "close": 119.44,
    "volume": 6311881
  },
  {
    "time": "01-02-2021",
    "open": 119.9,
    "high": 120.95,
    "low": 118.73,
    "close": 120.54,
    "volume": 6250508
  },
  {
    "time": "29-01-2021",
    "open": 120.22,
    "high": 121.3,
    "low": 118.9,
    "close": 119.11,
    "volume": 11825496
  },
  {
    "time": "28-01-2021",
    "open": 122.8,
    "high": 123.2,
    "low": 120.07,
    "close": 120.08,
    "volume": 8084051
  },
  {
    "time": "27-01-2021",
    "open": 122.08,
    "high": 124.79,
    "low": 121.6214,
    "close": 122.47,
    "volume": 12146541
  },
  {
    "time": "26-01-2021",
    "open": 119.18,
    "high": 122.59,
    "low": 118.42,
    "close": 122.49,
    "volume": 10694708
  },
  {
    "time": "25-01-2021",
    "open": 118.7624,
    "high": 119.538,
    "low": 117.45,
    "close": 118.58,
    "volume": 13686391
  },
  {
    "time": "22-01-2021",
    "open": 120.7,
    "high": 120.7,
    "low": 117.36,
    "close": 118.61,
    "volume": 38063533
  },
  {
    "time": "21-01-2021",
    "open": 130.12,
    "high": 132.24,
    "low": 130.05,
    "close": 131.65,
    "volume": 12819233
  },
  {
    "time": "20-01-2021",
    "open": 129.7,
    "high": 131.06,
    "low": 128.56,
    "close": 130.08,
    "volume": 5598705
  },
  {
    "time": "19-01-2021",
    "open": 129.28,
    "high": 129.59,
    "low": 128.0885,
    "close": 129.02,
    "volume": 5397956
  },
  {
    "time": "15-01-2021",
    "open": 128.28,
    "high": 129.24,
    "low": 127.67,
    "close": 128.39,
    "volume": 4905506
  },
  {
    "time": "14-01-2021",
    "open": 128.02,
    "high": 130.16,
    "low": 127.55,
    "close": 128.97,
    "volume": 7503180
  },
  {
    "time": "13-01-2021",
    "open": 129.15,
    "high": 129.75,
    "low": 126.455,
    "close": 126.92,
    "volume": 7677739
  },
  {
    "time": "12-01-2021",
    "open": 129.09,
    "high": 129.85,
    "low": 127.94,
    "close": 129.21,
    "volume": 3729068
  },
  {
    "time": "11-01-2021",
    "open": 127.95,
    "high": 129.675,
    "low": 127.66,
    "close": 128.58,
    "volume": 5602466
  },
  {
    "time": "08-01-2021",
    "open": 128.57,
    "high": 129.32,
    "low": 126.98,
    "close": 128.53,
    "volume": 4676487
  },
  {
    "time": "07-01-2021",
    "open": 130.04,
    "high": 130.46,
    "low": 128.26,
    "close": 128.99,
    "volume": 4507382
  },
  {
    "time": "06-01-2021",
    "open": 126.9,
    "high": 131.88,
    "low": 126.72,
    "close": 129.29,
    "volume": 7956740
  },
  {
    "time": "05-01-2021",
    "open": 125.01,
    "high": 126.68,
    "low": 124.61,
    "close": 126.14,
    "volume": 6114619
  },
  {
    "time": "04-01-2021",
    "open": 125.85,
    "high": 125.9174,
    "low": 123.04,
    "close": 123.94,
    "volume": 5179161
  },
  {
    "time": "31-12-2020",
    "open": 124.22,
    "high": 126.03,
    "low": 123.99,
    "close": 125.88,
    "volume": 3574696
  },
  {
    "time": "30-12-2020",
    "open": 123.8,
    "high": 124.85,
    "low": 123.63,
    "close": 124.34,
    "volume": 3380494
  },
  {
    "time": "29-12-2020",
    "open": 125.35,
    "high": 125.48,
    "low": 123.24,
    "close": 123.8,
    "volume": 3487007
  },
  {
    "time": "28-12-2020",
    "open": 125.1,
    "high": 126.6,
    "low": 124.46,
    "close": 124.82,
    "volume": 3583222
  },
  {
    "time": "24-12-2020",
    "open": 125,
    "high": 125.1,
    "low": 124.21,
    "close": 124.69,
    "volume": 1761122
  },
  {
    "time": "23-12-2020",
    "open": 123.88,
    "high": 125.21,
    "low": 123.74,
    "close": 123.9,
    "volume": 2693889
  },
  {
    "time": "22-12-2020",
    "open": 123.31,
    "high": 124.22,
    "low": 122.41,
    "close": 123.61,
    "volume": 4337757
  },
  {
    "time": "21-12-2020",
    "open": 123.97,
    "high": 124.18,
    "low": 121.72,
    "close": 123.39,
    "volume": 6115671
  },
  {
    "time": "18-12-2020",
    "open": 125.59,
    "high": 126.4,
    "low": 124.97,
    "close": 125.85,
    "volume": 7552845
  },
  {
    "time": "17-12-2020",
    "open": 126.08,
    "high": 126.09,
    "low": 124.91,
    "close": 125.55,
    "volume": 3787962
  },
  {
    "time": "16-12-2020",
    "open": 125.93,
    "high": 126.5728,
    "low": 125.286,
    "close": 125.55,
    "volume": 4530096
  },
  {
    "time": "15-12-2020",
    "open": 124.39,
    "high": 125.93,
    "low": 123.44,
    "close": 125.93,
    "volume": 4359601
  },
  {
    "time": "14-12-2020",
    "open": 125.32,
    "high": 126.2435,
    "low": 123.47,
    "close": 123.53,
    "volume": 5050023
  },
  {
    "time": "11-12-2020",
    "open": 124.08,
    "high": 125.51,
    "low": 123.61,
    "close": 124.27,
    "volume": 4481416
  },
  {
    "time": "10-12-2020",
    "open": 126.35,
    "high": 126.93,
    "low": 124.94,
    "close": 124.96,
    "volume": 4803172
  },
  {
    "time": "09-12-2020",
    "open": 125.8,
    "high": 127.69,
    "low": 125.7,
    "close": 126.79,
    "volume": 6513517
  },
  {
    "time": "08-12-2020",
    "open": 125.32,
    "high": 126.33,
    "low": 124.64,
    "close": 125.71,
    "volume": 5395024
  },
  {
    "time": "07-12-2020",
    "open": 126.49,
    "high": 126.97,
    "low": 124.57,
    "close": 124.7,
    "volume": 8318500
  },
  {
    "time": "04-12-2020",
    "open": 123.97,
    "high": 127.38,
    "low": 123.64,
    "close": 127.2,
    "volume": 5522760
  },
  {
    "time": "03-12-2020",
    "open": 124.16,
    "high": 124.86,
    "low": 123.29,
    "close": 123.61,
    "volume": 4548161
  },
  {
    "time": "02-12-2020",
    "open": 122.85,
    "high": 124.64,
    "low": 122.41,
    "close": 124.62,
    "volume": 3690737
  },
  {
    "time": "01-12-2020",
    "open": 123.9,
    "high": 125.83,
    "low": 123.08,
    "close": 123.16,
    "volume": 5099334
  },
  {
    "time": "30-11-2020",
    "open": 124.1,
    "high": 125,
    "low": 123.09,
    "close": 123.52,
    "volume": 5987991
  },
  {
    "time": "27-11-2020",
    "open": 124.2,
    "high": 125.313,
    "low": 123.91,
    "close": 124.35,
    "volume": 2091186
  },
  {
    "time": "25-11-2020",
    "open": 122.93,
    "high": 124.33,
    "low": 122.11,
    "close": 124.2,
    "volume": 4135894
  },
  {
    "time": "24-11-2020",
    "open": 120.86,
    "high": 124.73,
    "low": 120.805,
    "close": 124.42,
    "volume": 7535949
  },
  {
    "time": "23-11-2020",
    "open": 117.43,
    "high": 120.515,
    "low": 117.27,
    "close": 120.09,
    "volume": 5655119
  },
  {
    "time": "20-11-2020",
    "open": 117.6,
    "high": 118.04,
    "low": 116.69,
    "close": 116.94,
    "volume": 5024593
  },
  {
    "time": "19-11-2020",
    "open": 116.54,
    "high": 117.45,
    "low": 115.89,
    "close": 117.18,
    "volume": 3439648
  },
  {
    "time": "18-11-2020",
    "open": 117.72,
    "high": 118.88,
    "low": 116.75,
    "close": 116.77,
    "volume": 4606828
  },
  {
    "time": "17-11-2020",
    "open": 117.6,
    "high": 118.54,
    "low": 117.07,
    "close": 117.7,
    "volume": 4134455
  },
  {
    "time": "16-11-2020",
    "open": 118.3,
    "high": 118.55,
    "low": 117.12,
    "close": 118.36,
    "volume": 5293385
  },
  {
    "time": "13-11-2020",
    "open": 115.19,
    "high": 117.37,
    "low": 115.01,
    "close": 116.85,
    "volume": 4683512
  },
  {
    "time": "12-11-2020",
    "open": 115.63,
    "high": 116.37,
    "low": 113.48,
    "close": 114.5,
    "volume": 6500799
  },
  {
    "time": "11-11-2020",
    "open": 118.12,
    "high": 118.35,
    "low": 116.22,
    "close": 117.2,
    "volume": 4289601
  },
  {
    "time": "10-11-2020",
    "open": 116.69,
    "high": 118.17,
    "low": 116.25,
    "close": 117.91,
    "volume": 5622756
  },
  {
    "time": "09-11-2020",
    "open": 117.88,
    "high": 119.74,
    "low": 115.27,
    "close": 115.53,
    "volume": 8992152
  },
  {
    "time": "06-11-2020",
    "open": 115.08,
    "high": 115.1,
    "low": 113.39,
    "close": 114.04,
    "volume": 5249171
  },
  {
    "time": "05-11-2020",
    "open": 113.3,
    "high": 115.29,
    "low": 113.01,
    "close": 114.77,
    "volume": 4902206
  },
  {
    "time": "04-11-2020",
    "open": 112.33,
    "high": 113.91,
    "low": 111.16,
    "close": 111.9,
    "volume": 5800071
  },
  {
    "time": "03-11-2020",
    "open": 114,
    "high": 115.65,
    "low": 113.63,
    "close": 114.16,
    "volume": 4204287
  },
  {
    "time": "02-11-2020",
    "open": 112.65,
    "high": 113.8265,
    "low": 112.25,
    "close": 112.91,
    "volume": 5311497
  },
  {
    "time": "30-10-2020",
    "open": 107.9,
    "high": 111.8,
    "low": 107.75,
    "close": 111.66,
    "volume": 7923882
  },
  {
    "time": "29-10-2020",
    "open": 107.25,
    "high": 109.64,
    "low": 106.55,
    "close": 108.91,
    "volume": 6760241
  },
  {
    "time": "28-10-2020",
    "open": 108.66,
    "high": 109.73,
    "low": 105.92,
    "close": 106.65,
    "volume": 9427321
  },
  {
    "time": "27-10-2020",
    "open": 112.15,
    "high": 112.22,
    "low": 110.03,
    "close": 110.56,
    "volume": 5936106
  },
  {
    "time": "26-10-2020",
    "open": 114.45,
    "high": 114.9,
    "low": 111.84,
    "close": 112.22,
    "volume": 7203366
  },
  {
    "time": "23-10-2020",
    "open": 116.5,
    "high": 116.62,
    "low": 115.53,
    "close": 116,
    "volume": 3893362
  },
  {
    "time": "22-10-2020",
    "open": 115,
    "high": 116.06,
    "low": 112.98,
    "close": 115.76,
    "volume": 7858158
  },
  {
    "time": "21-10-2020",
    "open": 116.66,
    "high": 117.6899,
    "low": 114.79,
    "close": 115.06,
    "volume": 9755308
  },
  {
    "time": "20-10-2020",
    "open": 119.8,
    "high": 120.15,
    "low": 116.84,
    "close": 117.37,
    "volume": 21501073
  },
  {
    "time": "19-10-2020",
    "open": 126.8,
    "high": 127.35,
    "low": 125.08,
    "close": 125.52,
    "volume": 7478735
  },
  {
    "time": "16-10-2020",
    "open": 125.17,
    "high": 126.43,
    "low": 124.655,
    "close": 125.93,
    "volume": 4714320
  },
  {
    "time": "15-10-2020",
    "open": 124.08,
    "high": 125.215,
    "low": 123.85,
    "close": 124.89,
    "volume": 3389301
  },
  {
    "time": "14-10-2020",
    "open": 125.13,
    "high": 126.94,
    "low": 125.13,
    "close": 125.94,
    "volume": 3730139
  },
  {
    "time": "13-10-2020",
    "open": 126.57,
    "high": 127.15,
    "low": 124.46,
    "close": 125.1,
    "volume": 5406088
  },
  {
    "time": "12-10-2020",
    "open": 128.07,
    "high": 128.25,
    "low": 126.44,
    "close": 127.21,
    "volume": 4635115
  },
  {
    "time": "09-10-2020",
    "open": 132,
    "high": 132,
    "low": 127.6,
    "close": 127.79,
    "volume": 8353704
  },
  {
    "time": "08-10-2020",
    "open": 130.86,
    "high": 135.5,
    "low": 129.77,
    "close": 131.49,
    "volume": 25288926
  },
  {
    "time": "07-10-2020",
    "open": 122.67,
    "high": 124.39,
    "low": 122.32,
    "close": 124.07,
    "volume": 2815663
  },
  {
    "time": "06-10-2020",
    "open": 122.58,
    "high": 124.83,
    "low": 121.59,
    "close": 121.97,
    "volume": 3872265
  },
  {
    "time": "05-10-2020",
    "open": 121.84,
    "high": 122.75,
    "low": 121.05,
    "close": 122.01,
    "volume": 3050949
  },
  {
    "time": "02-10-2020",
    "open": 119.04,
    "high": 121.75,
    "low": 118.82,
    "close": 120.57,
    "volume": 2925228
  },
  {
    "time": "01-10-2020",
    "open": 122.36,
    "high": 123.3,
    "low": 120.355,
    "close": 121.09,
    "volume": 3211661
  },
  {
    "time": "30-09-2020",
    "open": 121.38,
    "high": 122.91,
    "low": 120.8,
    "close": 121.67,
    "volume": 3261235
  },
  {
    "time": "29-09-2020",
    "open": 121.41,
    "high": 122.1858,
    "low": 120.21,
    "close": 120.94,
    "volume": 2130564
  },
  {
    "time": "28-09-2020",
    "open": 120.57,
    "high": 122.33,
    "low": 120.41,
    "close": 121.73,
    "volume": 3509576
  },
  {
    "time": "25-09-2020",
    "open": 117.6,
    "high": 119.41,
    "low": 116.94,
    "close": 118.95,
    "volume": 2953686
  },
  {
    "time": "24-09-2020",
    "open": 118.1,
    "high": 119.515,
    "low": 116.48,
    "close": 118.09,
    "volume": 3546160
  },
  {
    "time": "23-09-2020",
    "open": 120.8,
    "high": 121.59,
    "low": 118.43,
    "close": 118.83,
    "volume": 3939398
  },
  {
    "time": "22-09-2020",
    "open": 120.33,
    "high": 121.45,
    "low": 119.53,
    "close": 120.51,
    "volume": 2957809
  },
  {
    "time": "21-09-2020",
    "open": 120.48,
    "high": 120.7,
    "low": 118.58,
    "close": 120.25,
    "volume": 5311441
  },
  {
    "time": "18-09-2020",
    "open": 124.26,
    "high": 124.92,
    "low": 122.65,
    "close": 122.76,
    "volume": 5391570
  },
  {
    "time": "17-09-2020",
    "open": 122.62,
    "high": 125.55,
    "low": 121.97,
    "close": 124.92,
    "volume": 3171262
  },
  {
    "time": "16-09-2020",
    "open": 122.71,
    "high": 125.82,
    "low": 122.71,
    "close": 124.22,
    "volume": 3789458
  },
  {
    "time": "15-09-2020",
    "open": 122.82,
    "high": 123.4,
    "low": 122.24,
    "close": 122.44,
    "volume": 2915221
  },
  {
    "time": "14-09-2020",
    "open": 122.36,
    "high": 123.38,
    "low": 121.76,
    "close": 122.09,
    "volume": 3641887
  },
  {
    "time": "11-09-2020",
    "open": 121.39,
    "high": 122.7,
    "low": 120.73,
    "close": 121.46,
    "volume": 3548091
  },
  {
    "time": "10-09-2020",
    "open": 122.53,
    "high": 123.5764,
    "low": 120.24,
    "close": 120.56,
    "volume": 3978805
  },
  {
    "time": "09-09-2020",
    "open": 122.13,
    "high": 123.7,
    "low": 121.29,
    "close": 122.26,
    "volume": 3770678
  },
  {
    "time": "08-09-2020",
    "open": 122.16,
    "high": 122.87,
    "low": 120.71,
    "close": 121.21,
    "volume": 5210292
  },
  {
    "time": "04-09-2020",
    "open": 124.35,
    "high": 125.35,
    "low": 121.24,
    "close": 122.3,
    "volume": 6018238
  },
  {
    "time": "03-09-2020",
    "open": 128.19,
    "high": 129.95,
    "low": 123.65,
    "close": 124.45,
    "volume": 5716750
  },
  {
    "time": "02-09-2020",
    "open": 123.72,
    "high": 128.7,
    "low": 123.575,
    "close": 128.18,
    "volume": 6592430
  },
  {
    "time": "01-09-2020",
    "open": 122.85,
    "high": 123.95,
    "low": 122.15,
    "close": 123.4,
    "volume": 3155623
  },
  {
    "time": "31-08-2020",
    "open": 125.25,
    "high": 125.25,
    "low": 123.03,
    "close": 123.31,
    "volume": 4827879
  },
  {
    "time": "28-08-2020",
    "open": 124.96,
    "high": 125.3,
    "low": 124.2187,
    "close": 125.07,
    "volume": 3099940
  },
  {
    "time": "27-08-2020",
    "open": 124.6,
    "high": 125.1,
    "low": 123.98,
    "close": 124.65,
    "volume": 3422654
  },
  {
    "time": "26-08-2020",
    "open": 124.95,
    "high": 125.12,
    "low": 123.95,
    "close": 124.17,
    "volume": 3388687
  },
  {
    "time": "25-08-2020",
    "open": 126,
    "high": 126.82,
    "low": 124.49,
    "close": 124.64,
    "volume": 2977697
  },
  {
    "time": "24-08-2020",
    "open": 123.79,
    "high": 126.06,
    "low": 123.36,
    "close": 125.68,
    "volume": 4070816
  },
  {
    "time": "21-08-2020",
    "open": 123.01,
    "high": 123.4842,
    "low": 122.31,
    "close": 123.16,
    "volume": 3385051
  },
  {
    "time": "20-08-2020",
    "open": 123.2,
    "high": 124.04,
    "low": 122.81,
    "close": 123.15,
    "volume": 2561164
  },
  {
    "time": "19-08-2020",
    "open": 124.83,
    "high": 125.5,
    "low": 123.5,
    "close": 123.84,
    "volume": 3743949
  },
  {
    "time": "18-08-2020",
    "open": 125,
    "high": 125.5,
    "low": 124.24,
    "close": 124.92,
    "volume": 2882355
  },
  {
    "time": "17-08-2020",
    "open": 125.25,
    "high": 125.59,
    "low": 124.41,
    "close": 124.44,
    "volume": 3336459
  },
  {
    "time": "14-08-2020",
    "open": 124.2,
    "high": 125.56,
    "low": 123.91,
    "close": 125.27,
    "volume": 2963753
  },
  {
    "time": "13-08-2020",
    "open": 125.96,
    "high": 126.39,
    "low": 124.77,
    "close": 125.03,
    "volume": 3171258
  },
  {
    "time": "12-08-2020",
    "open": 127.61,
    "high": 127.79,
    "low": 125.876,
    "close": 126.7,
    "volume": 3530177
  },
  {
    "time": "11-08-2020",
    "open": 128.76,
    "high": 130.47,
    "low": 126.61,
    "close": 126.75,
    "volume": 5001176
  },
  {
    "time": "10-08-2020",
    "open": 125.42,
    "high": 127.24,
    "low": 125.1849,
    "close": 127.11,
    "volume": 3968295
  },
  {
    "time": "07-08-2020",
    "open": 123.5,
    "high": 125.07,
    "low": 123.21,
    "close": 124.96,
    "volume": 3651226
  },
  {
    "time": "06-08-2020",
    "open": 125,
    "high": 126.26,
    "low": 125,
    "close": 126.12,
    "volume": 3417131
  },
  {
    "time": "05-08-2020",
    "open": 126.73,
    "high": 126.75,
    "low": 124.61,
    "close": 125.45,
    "volume": 3675400
  },
  {
    "time": "04-08-2020",
    "open": 123.82,
    "high": 125.86,
    "low": 123.82,
    "close": 125.84,
    "volume": 3431366
  },
  {
    "time": "03-08-2020",
    "open": 123.5,
    "high": 124.41,
    "low": 122.15,
    "close": 124.31,
    "volume": 3526574
  },
  {
    "time": "31-07-2020",
    "open": 122.71,
    "high": 122.99,
    "low": 121.09,
    "close": 122.94,
    "volume": 5277652
  },
  {
    "time": "30-07-2020",
    "open": 123.71,
    "high": 123.9046,
    "low": 121.64,
    "close": 122.9,
    "volume": 3988062
  },
  {
    "time": "29-07-2020",
    "open": 124.56,
    "high": 125.99,
    "low": 124.07,
    "close": 125.32,
    "volume": 2833141
  },
  {
    "time": "28-07-2020",
    "open": 125.82,
    "high": 126.34,
    "low": 124.15,
    "close": 124.47,
    "volume": 4178176
  },
  {
    "time": "27-07-2020",
    "open": 124.86,
    "high": 126.32,
    "low": 124.71,
    "close": 126.21,
    "volume": 3733547
  },
  {
    "time": "24-07-2020",
    "open": 126.48,
    "high": 127.6459,
    "low": 125.5,
    "close": 125.79,
    "volume": 3531076
  },
  {
    "time": "23-07-2020",
    "open": 129.1,
    "high": 129.37,
    "low": 127.15,
    "close": 127.33,
    "volume": 4220136
  },
  {
    "time": "22-07-2020",
    "open": 125.9,
    "high": 129.47,
    "low": 125.8,
    "close": 128.67,
    "volume": 8195366
  },
  {
    "time": "21-07-2020",
    "open": 131.16,
    "high": 132.17,
    "low": 125.8,
    "close": 126.06,
    "volume": 15356679
  },
  {
    "time": "20-07-2020",
    "open": 126.07,
    "high": 127.07,
    "low": 125.14,
    "close": 126.37,
    "volume": 9737157
  },
  {
    "time": "17-07-2020",
    "open": 124.39,
    "high": 125.63,
    "low": 123.2,
    "close": 125.11,
    "volume": 3983972
  },
  {
    "time": "16-07-2020",
    "open": 122.68,
    "high": 124.48,
    "low": 122.16,
    "close": 124.01,
    "volume": 4328856
  },
  {
    "time": "15-07-2020",
    "open": 122.4,
    "high": 123.96,
    "low": 122.15,
    "close": 123,
    "volume": 4470095
  },
  {
    "time": "14-07-2020",
    "open": 118.62,
    "high": 120.89,
    "low": 117.92,
    "close": 120.6,
    "volume": 4531393
  },
  {
    "time": "13-07-2020",
    "open": 119.78,
    "high": 120.99,
    "low": 118.66,
    "close": 119.2,
    "volume": 4644595
  },
  {
    "time": "10-07-2020",
    "open": 115.5,
    "high": 118.57,
    "low": 115.29,
    "close": 118.35,
    "volume": 4285088
  },
  {
    "time": "09-07-2020",
    "open": 118,
    "high": 118,
    "low": 115.2,
    "close": 115.71,
    "volume": 4760875
  },
  {
    "time": "08-07-2020",
    "open": 118.06,
    "high": 118.64,
    "low": 116.48,
    "close": 117.71,
    "volume": 5191792
  },
  {
    "time": "07-07-2020",
    "open": 119,
    "high": 119.51,
    "low": 117.33,
    "close": 117.58,
    "volume": 4278088
  },
  {
    "time": "06-07-2020",
    "open": 121.25,
    "high": 121.85,
    "low": 119.46,
    "close": 120.19,
    "volume": 4044188
  },
  {
    "time": "02-07-2020",
    "open": 119.69,
    "high": 121.42,
    "low": 119.26,
    "close": 119.7,
    "volume": 3745663
  },
  {
    "time": "01-07-2020",
    "open": 120.27,
    "high": 121.23,
    "low": 118.37,
    "close": 118.54,
    "volume": 4657168
  },
  {
    "time": "30-06-2020",
    "open": 119.19,
    "high": 121.2,
    "low": 119,
    "close": 120.77,
    "volume": 3916381
  },
  {
    "time": "29-06-2020",
    "open": 117.98,
    "high": 119.78,
    "low": 117.37,
    "close": 119.75,
    "volume": 4159488
  },
  {
    "time": "26-06-2020",
    "open": 118.26,
    "high": 118.99,
    "low": 116.26,
    "close": 117.19,
    "volume": 10749166
  },
  {
    "time": "25-06-2020",
    "open": 116.76,
    "high": 119.21,
    "low": 116.1,
    "close": 119.03,
    "volume": 6128720
  },
  {
    "time": "24-06-2020",
    "open": 118.32,
    "high": 118.32,
    "low": 115.88,
    "close": 116.42,
    "volume": 6747421
  },
  {
    "time": "23-06-2020",
    "open": 122.01,
    "high": 122.9,
    "low": 119.23,
    "close": 119.41,
    "volume": 6628278
  },
  {
    "time": "22-06-2020",
    "open": 122.12,
    "high": 122.5,
    "low": 120.53,
    "close": 121.07,
    "volume": 4762601
  },
  {
    "time": "19-06-2020",
    "open": 126.17,
    "high": 126.82,
    "low": 122.25,
    "close": 122.47,
    "volume": 8098994
  },
  {
    "time": "18-06-2020",
    "open": 123,
    "high": 124.4,
    "low": 122.33,
    "close": 124.16,
    "volume": 2860286
  },
  {
    "time": "17-06-2020",
    "open": 125.83,
    "high": 126.25,
    "low": 123.4,
    "close": 124.15,
    "volume": 3153862
  },
  {
    "time": "16-06-2020",
    "open": 125,
    "high": 127.5,
    "low": 123.16,
    "close": 125.15,
    "volume": 5605791
  },
  {
    "time": "15-06-2020",
    "open": 119.19,
    "high": 122.37,
    "low": 118.29,
    "close": 121.65,
    "volume": 5148756
  },
  {
    "time": "12-06-2020",
    "open": 121.25,
    "high": 123.12,
    "low": 119.28,
    "close": 121.91,
    "volume": 6218003
  },
  {
    "time": "11-06-2020",
    "open": 126.03,
    "high": 126.1,
    "low": 117.84,
    "close": 118.01,
    "volume": 11753674
  },
  {
    "time": "10-06-2020",
    "open": 132.15,
    "high": 132.23,
    "low": 129.8,
    "close": 129.87,
    "volume": 4725055
  },
  {
    "time": "09-06-2020",
    "open": 133.52,
    "high": 133.6,
    "low": 131.02,
    "close": 131.87,
    "volume": 5391520
  },
  {
    "time": "08-06-2020",
    "open": 132.27,
    "high": 135.88,
    "low": 132.27,
    "close": 135.75,
    "volume": 5368387
  },
  {
    "time": "05-06-2020",
    "open": 133.89,
    "high": 134.23,
    "low": 131.75,
    "close": 132.06,
    "volume": 5892718
  },
  {
    "time": "04-06-2020",
    "open": 127.78,
    "high": 128.95,
    "low": 127.02,
    "close": 128.89,
    "volume": 3864131
  },
  {
    "time": "03-06-2020",
    "open": 127.15,
    "high": 129.6,
    "low": 127,
    "close": 129.05,
    "volume": 3913804
  },
  {
    "time": "02-06-2020",
    "open": 125.67,
    "high": 126,
    "low": 124.66,
    "close": 126,
    "volume": 2976908
  },
  {
    "time": "01-06-2020",
    "open": 124.64,
    "high": 125.75,
    "low": 124.03,
    "close": 124.89,
    "volume": 2867306
  },
  {
    "time": "29-05-2020",
    "open": 123.67,
    "high": 125.13,
    "low": 122.24,
    "close": 124.9,
    "volume": 7929905
  },
  {
    "time": "28-05-2020",
    "open": 126.87,
    "high": 126.97,
    "low": 124.22,
    "close": 124.53,
    "volume": 3915224
  },
  {
    "time": "27-05-2020",
    "open": 123.94,
    "high": 125.83,
    "low": 123.47,
    "close": 125.54,
    "volume": 5725163
  },
  {
    "time": "26-05-2020",
    "open": 121.3,
    "high": 122.62,
    "low": 121.01,
    "close": 121.76,
    "volume": 5467436
  },
  {
    "time": "22-05-2020",
    "open": 119.37,
    "high": 119.47,
    "low": 117.59,
    "close": 118.39,
    "volume": 4179906
  },
  {
    "time": "21-05-2020",
    "open": 120.99,
    "high": 121.72,
    "low": 118.97,
    "close": 119.12,
    "volume": 4018329
  },
  {
    "time": "20-05-2020",
    "open": 120.93,
    "high": 122.47,
    "low": 120.6,
    "close": 121.38,
    "volume": 3840563
  },
  {
    "time": "19-05-2020",
    "open": 121.19,
    "high": 122.17,
    "low": 120.14,
    "close": 120.29,
    "volume": 3239964
  },
  {
    "time": "18-05-2020",
    "open": 119.88,
    "high": 122.36,
    "low": 119.75,
    "close": 121.56,
    "volume": 4205425
  },
  {
    "time": "15-05-2020",
    "open": 115.93,
    "high": 117.39,
    "low": 115.25,
    "close": 116.98,
    "volume": 4785773
  },
  {
    "time": "14-05-2020",
    "open": 114.57,
    "high": 117.09,
    "low": 111.81,
    "close": 116.95,
    "volume": 5255607
  },
  {
    "time": "13-05-2020",
    "open": 119.95,
    "high": 119.99,
    "low": 114.85,
    "close": 115.73,
    "volume": 5874676
  },
  {
    "time": "12-05-2020",
    "open": 123.01,
    "high": 124.32,
    "low": 120.26,
    "close": 120.26,
    "volume": 4779635
  },
  {
    "time": "11-05-2020",
    "open": 121.81,
    "high": 123.45,
    "low": 120.67,
    "close": 122.59,
    "volume": 3533382
  },
  {
    "time": "08-05-2020",
    "open": 122.67,
    "high": 123.23,
    "low": 121.06,
    "close": 122.99,
    "volume": 5002450
  },
  {
    "time": "07-05-2020",
    "open": 122.98,
    "high": 123.26,
    "low": 120.85,
    "close": 121.23,
    "volume": 4412047
  },
  {
    "time": "06-05-2020",
    "open": 123.24,
    "high": 124.05,
    "low": 122.41,
    "close": 123.17,
    "volume": 3861081
  },
  {
    "time": "05-05-2020",
    "open": 123.39,
    "high": 124.32,
    "low": 122.47,
    "close": 122.58,
    "volume": 3897761
  },
  {
    "time": "04-05-2020",
    "open": 120.82,
    "high": 121.97,
    "low": 119.39,
    "close": 121.68,
    "volume": 4016551
  },
  {
    "time": "01-05-2020",
    "open": 123.19,
    "high": 123.47,
    "low": 121.39,
    "close": 121.87,
    "volume": 4923913
  },
  {
    "time": "30-04-2020",
    "open": 126.52,
    "high": 127.27,
    "low": 125.22,
    "close": 125.56,
    "volume": 6630032
  },
  {
    "time": "29-04-2020",
    "open": 128.88,
    "high": 129.31,
    "low": 127.53,
    "close": 128.69,
    "volume": 5252734
  },
  {
    "time": "28-04-2020",
    "open": 128.03,
    "high": 128.2,
    "low": 125.38,
    "close": 126.27,
    "volume": 4775867
  },
  {
    "time": "27-04-2020",
    "open": 125.56,
    "high": 126.99,
    "low": 125.47,
    "close": 125.92,
    "volume": 4920443
  },
  {
    "time": "24-04-2020",
    "open": 122.41,
    "high": 125,
    "low": 120.76,
    "close": 124.72,
    "volume": 4985575
  },
  {
    "time": "23-04-2020",
    "open": 119.57,
    "high": 123.03,
    "low": 119.12,
    "close": 121.35,
    "volume": 6873899
  },
  {
    "time": "22-04-2020",
    "open": 119.87,
    "high": 120.33,
    "low": 117.55,
    "close": 119.31,
    "volume": 7085901
  },
  {
    "time": "21-04-2020",
    "open": 114,
    "high": 117.15,
    "low": 112.06,
    "close": 116.76,
    "volume": 14341304
  },
  {
    "time": "20-04-2020",
    "open": 119.15,
    "high": 122.86,
    "low": 118.14,
    "close": 120.41,
    "volume": 7965530
  },
  {
    "time": "17-04-2020",
    "open": 119.3,
    "high": 120.39,
    "low": 117.92,
    "close": 120.12,
    "volume": 4964018
  },
  {
    "time": "16-04-2020",
    "open": 119.01,
    "high": 119.75,
    "low": 114.42,
    "close": 115.73,
    "volume": 6438128
  },
  {
    "time": "15-04-2020",
    "open": 119.12,
    "high": 120.19,
    "low": 116.76,
    "close": 118.69,
    "volume": 5632156
  },
  {
    "time": "14-04-2020",
    "open": 123.46,
    "high": 125.18,
    "low": 122.62,
    "close": 123.91,
    "volume": 5087292
  },
  {
    "time": "13-04-2020",
    "open": 121.63,
    "high": 121.8,
    "low": 118.04,
    "close": 121.15,
    "volume": 5118777
  },
  {
    "time": "09-04-2020",
    "open": 120.48,
    "high": 122.92,
    "low": 120.17,
    "close": 121.5,
    "volume": 5576210
  },
  {
    "time": "08-04-2020",
    "open": 116.31,
    "high": 119.96,
    "low": 115.07,
    "close": 119.29,
    "volume": 5155987
  },
  {
    "time": "07-04-2020",
    "open": 118.8,
    "high": 119.57,
    "low": 114.87,
    "close": 114.94,
    "volume": 5592463
  },
  {
    "time": "06-04-2020",
    "open": 110.35,
    "high": 115.63,
    "low": 110.13,
    "close": 114.82,
    "volume": 7026457
  },
  {
    "time": "03-04-2020",
    "open": 108.92,
    "high": 110.07,
    "low": 104.92,
    "close": 106.34,
    "volume": 4538113
  },
  {
    "time": "02-04-2020",
    "open": 105.37,
    "high": 110.32,
    "low": 105.14,
    "close": 110,
    "volume": 6327540
  },
  {
    "time": "01-04-2020",
    "open": 106.36,
    "high": 109.92,
    "low": 104.52,
    "close": 105.14,
    "volume": 6111890
  },
  {
    "time": "31-03-2020",
    "open": 112,
    "high": 113.81,
    "low": 110.17,
    "close": 110.93,
    "volume": 6342167
  },
  {
    "time": "30-03-2020",
    "open": 108.09,
    "high": 113.46,
    "low": 107.81,
    "close": 112.93,
    "volume": 5562501
  },
  {
    "time": "27-03-2020",
    "open": 108.58,
    "high": 111.5,
    "low": 107.65,
    "close": 108.03,
    "volume": 6421470
  },
  {
    "time": "26-03-2020",
    "open": 106.91,
    "high": 113.15,
    "low": 105.57,
    "close": 112.89,
    "volume": 7153135
  },
  {
    "time": "25-03-2020",
    "open": 105.48,
    "high": 112.48,
    "low": 102.83,
    "close": 105.85,
    "volume": 8646557
  },
  {
    "time": "24-03-2020",
    "open": 99.99,
    "high": 106.04,
    "low": 99.26,
    "close": 105.48,
    "volume": 7967768
  },
  {
    "time": "23-03-2020",
    "open": 94.6,
    "high": 97.74,
    "low": 90.56,
    "close": 94.77,
    "volume": 9718602
  },
  {
    "time": "20-03-2020",
    "open": 100.92,
    "high": 100.99,
    "low": 94.71,
    "close": 95.39,
    "volume": 10543213
  },
  {
    "time": "19-03-2020",
    "open": 102.33,
    "high": 103.69,
    "low": 98.24,
    "close": 100.34,
    "volume": 8391294
  },
  {
    "time": "18-03-2020",
    "open": 99.41,
    "high": 106.93,
    "low": 99.4,
    "close": 103.55,
    "volume": 8751994
  },
  {
    "time": "17-03-2020",
    "open": 100.61,
    "high": 107.4,
    "low": 97.1,
    "close": 106.65,
    "volume": 8887501
  },
  {
    "time": "16-03-2020",
    "open": 98,
    "high": 107.41,
    "low": 95,
    "close": 99.08,
    "volume": 10564044
  },
  {
    "time": "13-03-2020",
    "open": 108.68,
    "high": 109.16,
    "low": 100.81,
    "close": 107.95,
    "volume": 12493419
  },
  {
    "time": "12-03-2020",
    "open": 109.65,
    "high": 109.8,
    "low": 102.28,
    "close": 102.81,
    "volume": 12485925
  },
  {
    "time": "11-03-2020",
    "open": 121.01,
    "high": 122.58,
    "low": 116.38,
    "close": 117.97,
    "volume": 8446480
  },
  {
    "time": "10-03-2020",
    "open": 122.78,
    "high": 124.88,
    "low": 115.76,
    "close": 124.77,
    "volume": 11403865
  },
  {
    "time": "09-03-2020",
    "open": 120.16,
    "high": 122.41,
    "low": 117.28,
    "close": 117.81,
    "volume": 10750357
  },
  {
    "time": "06-03-2020",
    "open": 126.7,
    "high": 128.33,
    "low": 124.52,
    "close": 127.73,
    "volume": 8192525
  },
  {
    "time": "05-03-2020",
    "open": 130.5,
    "high": 132.29,
    "low": 128.45,
    "close": 129.55,
    "volume": 5445193
  },
  {
    "time": "04-03-2020",
    "open": 131.58,
    "high": 134.28,
    "low": 129,
    "close": 134.22,
    "volume": 4994682
  },
  {
    "time": "03-03-2020",
    "open": 134.48,
    "high": 136.1,
    "low": 127.8,
    "close": 128.9,
    "volume": 7893390
  },
  {
    "time": "02-03-2020",
    "open": 130.75,
    "high": 134.44,
    "low": 127.95,
    "close": 134.3,
    "volume": 8528971
  },
  {
    "time": "28-02-2020",
    "open": 129.98,
    "high": 131.09,
    "low": 126.36,
    "close": 130.15,
    "volume": 13009236
  },
  {
    "time": "27-02-2020",
    "open": 137.24,
    "high": 138.69,
    "low": 133.01,
    "close": 133.11,
    "volume": 8404903
  },
  {
    "time": "26-02-2020",
    "open": 142.9,
    "high": 144.06,
    "low": 139.6,
    "close": 139.75,
    "volume": 5407690
  },
  {
    "time": "25-02-2020",
    "open": 146.51,
    "high": 147.07,
    "low": 141.26,
    "close": 141.71,
    "volume": 6210878
  },
  {
    "time": "24-02-2020",
    "open": 145.51,
    "high": 148.05,
    "low": 145.38,
    "close": 146.43,
    "volume": 5447948
  },
  {
    "time": "21-02-2020",
    "open": 151.04,
    "high": 151.04,
    "low": 148.84,
    "close": 149.84,
    "volume": 3536740
  },
  {
    "time": "20-02-2020",
    "open": 150.81,
    "high": 151.62,
    "low": 149.51,
    "close": 151.22,
    "volume": 2880483
  },
  {
    "time": "19-02-2020",
    "open": 151.54,
    "high": 151.89,
    "low": 150.31,
    "close": 150.86,
    "volume": 2732905
  },
  {
    "time": "18-02-2020",
    "open": 149.79,
    "high": 151.38,
    "low": 149.64,
    "close": 151.1,
    "volume": 3343038
  },
  {
    "time": "14-02-2020",
    "open": 154.42,
    "high": 154.56,
    "low": 149.77,
    "close": 150.7,
    "volume": 6095081
  },
  {
    "time": "13-02-2020",
    "open": 154.37,
    "high": 154.83,
    "low": 153.41,
    "close": 154.31,
    "volume": 3819757
  },
  {
    "time": "12-02-2020",
    "open": 153.75,
    "high": 155.6,
    "low": 153.52,
    "close": 155.31,
    "volume": 4437792
  },
  {
    "time": "11-02-2020",
    "open": 155.12,
    "high": 155.22,
    "low": 152.73,
    "close": 153.48,
    "volume": 3833574
  },
  {
    "time": "10-02-2020",
    "open": 152.97,
    "high": 154.44,
    "low": 151.58,
    "close": 154.43,
    "volume": 5237553
  },
  {
    "time": "07-02-2020",
    "open": 154.55,
    "high": 155.54,
    "low": 152.92,
    "close": 153.41,
    "volume": 6414553
  },
  {
    "time": "06-02-2020",
    "open": 156.82,
    "high": 158.75,
    "low": 155.42,
    "close": 156.76,
    "volume": 8394374
  },
  {
    "time": "05-02-2020",
    "open": 150.21,
    "high": 157.44,
    "low": 150.15,
    "close": 156.33,
    "volume": 14379714
  },
  {
    "time": "04-02-2020",
    "open": 147.78,
    "high": 149.38,
    "low": 146.88,
    "close": 149.11,
    "volume": 5619700
  },
  {
    "time": "03-02-2020",
    "open": 144.25,
    "high": 147.28,
    "low": 143.9,
    "close": 146.27,
    "volume": 8886414
  },
  {
    "time": "31-01-2020",
    "open": 142.9,
    "high": 144.05,
    "low": 140.79,
    "close": 143.73,
    "volume": 18987138
  },
  {
    "time": "30-01-2020",
    "open": 136.76,
    "high": 136.97,
    "low": 134.97,
    "close": 136.77,
    "volume": 5007038
  },
  {
    "time": "29-01-2020",
    "open": 139.17,
    "high": 139.58,
    "low": 137.6,
    "close": 137.69,
    "volume": 3053189
  },
  {
    "time": "28-01-2020",
    "open": 139.5,
    "high": 140.47,
    "low": 138.75,
    "close": 139.55,
    "volume": 3449000
  },
  {
    "time": "27-01-2020",
    "open": 138.5,
    "high": 140.07,
    "low": 138.1,
    "close": 138.62,
    "volume": 4165146
  },
  {
    "time": "24-01-2020",
    "open": 143.39,
    "high": 143.92,
    "low": 140.46,
    "close": 140.56,
    "volume": 5579292
  },
  {
    "time": "23-01-2020",
    "open": 144.2,
    "high": 144.41,
    "low": 142.15,
    "close": 142.87,
    "volume": 5654308
  },
  {
    "time": "22-01-2020",
    "open": 143.32,
    "high": 145.79,
    "low": 142.55,
    "close": 143.89,
    "volume": 16464948
  },
  {
    "time": "21-01-2020",
    "open": 137.81,
    "high": 139.35,
    "low": 137.6,
    "close": 139.17,
    "volume": 7130819
  },
  {
    "time": "17-01-2020",
    "open": 136.54,
    "high": 138.33,
    "low": 136.16,
    "close": 138.31,
    "volume": 5622251
  },
  {
    "time": "16-01-2020",
    "open": 137.32,
    "high": 138.19,
    "low": 137.01,
    "close": 137.98,
    "volume": 4319896
  },
  {
    "time": "15-01-2020",
    "open": 136,
    "high": 138.06,
    "low": 135.71,
    "close": 136.62,
    "volume": 4045591
  },
  {
    "time": "14-01-2020",
    "open": 136.28,
    "high": 137.14,
    "low": 135.55,
    "close": 135.82,
    "volume": 3680958
  },
  {
    "time": "13-01-2020",
    "open": 135.48,
    "high": 136.64,
    "low": 135.07,
    "close": 136.6,
    "volume": 3162843
  },
  {
    "time": "10-01-2020",
    "open": 137,
    "high": 137.87,
    "low": 136.31,
    "close": 136.69,
    "volume": 3255398
  },
  {
    "time": "09-01-2020",
    "open": 135.74,
    "high": 136.79,
    "low": 135.31,
    "close": 136.74,
    "volume": 3730549
  },
  {
    "time": "08-01-2020",
    "open": 134.51,
    "high": 135.86,
    "low": 133.92,
    "close": 135.31,
    "volume": 4345952
  },
  {
    "time": "07-01-2020",
    "open": 133.69,
    "high": 134.96,
    "low": 133.4,
    "close": 134.19,
    "volume": 3267592
  },
  {
    "time": "06-01-2020",
    "open": 133.42,
    "high": 134.24,
    "low": 133.2,
    "close": 134.1,
    "volume": 2421128
  },
  {
    "time": "03-01-2020",
    "open": 133.57,
    "high": 134.86,
    "low": 133.56,
    "close": 134.34,
    "volume": 2373470
  },
  {
    "time": "02-01-2020",
    "open": 135,
    "high": 135.92,
    "low": 134.77,
    "close": 135.42,
    "volume": 3148461
  },
  {
    "time": "31-12-2019",
    "open": 132.53,
    "high": 134.12,
    "low": 132.4,
    "close": 134.04,
    "volume": 3777504
  },
  {
    "time": "30-12-2019",
    "open": 135.2,
    "high": 135.3,
    "low": 132.5,
    "close": 132.81,
    "volume": 4118929
  },
  {
    "time": "27-12-2019",
    "open": 135,
    "high": 135.75,
    "low": 134.87,
    "close": 135.27,
    "volume": 2752185
  },
  {
    "time": "26-12-2019",
    "open": 134.98,
    "high": 135.31,
    "low": 134.65,
    "close": 134.91,
    "volume": 2129654
  },
  {
    "time": "24-12-2019",
    "open": 135.61,
    "high": 135.62,
    "low": 134.61,
    "close": 134.98,
    "volume": 1202087
  },
  {
    "time": "23-12-2019",
    "open": 135.78,
    "high": 136.15,
    "low": 135,
    "close": 135.55,
    "volume": 2803158
  },
  {
    "time": "20-12-2019",
    "open": 135.74,
    "high": 136.42,
    "low": 134.95,
    "close": 135.59,
    "volume": 7111779
  },
  {
    "time": "19-12-2019",
    "open": 134.46,
    "high": 134.73,
    "low": 134.19,
    "close": 134.56,
    "volume": 3866494
  },
  {
    "time": "18-12-2019",
    "open": 134.55,
    "high": 135,
    "low": 134.15,
    "close": 134.41,
    "volume": 3101819
  },
  {
    "time": "17-12-2019",
    "open": 134.28,
    "high": 134.69,
    "low": 133.46,
    "close": 134.22,
    "volume": 2907186
  },
  {
    "time": "16-12-2019",
    "open": 134.94,
    "high": 135.45,
    "low": 133.85,
    "close": 134.13,
    "volume": 3049224
  },
  {
    "time": "13-12-2019",
    "open": 134.75,
    "high": 135.5,
    "low": 134.01,
    "close": 134.21,
    "volume": 2534970
  },
  {
    "time": "12-12-2019",
    "open": 133.7,
    "high": 135.66,
    "low": 133.66,
    "close": 135.32,
    "volume": 4824103
  },
  {
    "time": "11-12-2019",
    "open": 134.11,
    "high": 134.51,
    "low": 133.67,
    "close": 133.76,
    "volume": 3698494
  },
  {
    "time": "10-12-2019",
    "open": 133.92,
    "high": 134.84,
    "low": 133.56,
    "close": 133.91,
    "volume": 3478362
  },
  {
    "time": "09-12-2019",
    "open": 133.35,
    "high": 134.59,
    "low": 133.26,
    "close": 133.92,
    "volume": 2968310
  },
  {
    "time": "06-12-2019",
    "open": 132.75,
    "high": 133.89,
    "low": 132.7,
    "close": 133.22,
    "volume": 3437438
  },
  {
    "time": "05-12-2019",
    "open": 132.99,
    "high": 133.24,
    "low": 131.63,
    "close": 131.91,
    "volume": 3595312
  },
  {
    "time": "04-12-2019",
    "open": 132.86,
    "high": 133.68,
    "low": 131.96,
    "close": 131.99,
    "volume": 3530028
  },
  {
    "time": "03-12-2019",
    "open": 132,
    "high": 132.44,
    "low": 130.69,
    "close": 132.12,
    "volume": 3708102
  },
  {
    "time": "02-12-2019",
    "open": 134.45,
    "high": 134.5,
    "low": 132.48,
    "close": 132.91,
    "volume": 3066813
  },
  {
    "time": "29-11-2019",
    "open": 133.6,
    "high": 134.52,
    "low": 133.03,
    "close": 134.45,
    "volume": 1825255
  },
  {
    "time": "27-11-2019",
    "open": 135.35,
    "high": 135.71,
    "low": 133.62,
    "close": 133.77,
    "volume": 3280699
  },
  {
    "time": "26-11-2019",
    "open": 136.15,
    "high": 136.15,
    "low": 134.77,
    "close": 135.09,
    "volume": 3806859
  },
  {
    "time": "25-11-2019",
    "open": 134.47,
    "high": 136,
    "low": 134.35,
    "close": 135.97,
    "volume": 2590146
  },
  {
    "time": "22-11-2019",
    "open": 134.19,
    "high": 134.65,
    "low": 133.59,
    "close": 134.34,
    "volume": 3297397
  },
  {
    "time": "21-11-2019",
    "open": 133.4,
    "high": 134.17,
    "low": 132.98,
    "close": 133.84,
    "volume": 3322158
  },
  {
    "time": "20-11-2019",
    "open": 134.06,
    "high": 134.46,
    "low": 132.75,
    "close": 133.2,
    "volume": 4138165
  },
  {
    "time": "19-11-2019",
    "open": 134.88,
    "high": 135.38,
    "low": 134.46,
    "close": 134.52,
    "volume": 2995004
  },
  {
    "time": "18-11-2019",
    "open": 134.3,
    "high": 134.48,
    "low": 133.23,
    "close": 134.31,
    "volume": 3210383
  },
  {
    "time": "15-11-2019",
    "open": 134.39,
    "high": 135.12,
    "low": 134.03,
    "close": 134.4,
    "volume": 3262504
  },
  {
    "time": "14-11-2019",
    "open": 134.13,
    "high": 134.33,
    "low": 133.33,
    "close": 134,
    "volume": 4231231
  },
  {
    "time": "13-11-2019",
    "open": 135.39,
    "high": 135.58,
    "low": 133.85,
    "close": 134.48,
    "volume": 3060919
  },
  {
    "time": "12-11-2019",
    "open": 135.87,
    "high": 136.66,
    "low": 135.02,
    "close": 135.59,
    "volume": 3046496
  },
  {
    "time": "11-11-2019",
    "open": 137.2,
    "high": 137.2,
    "low": 135,
    "close": 135.47,
    "volume": 2568022
  },
  {
    "time": "08-11-2019",
    "open": 137.58,
    "high": 137.61,
    "low": 136.16,
    "close": 137.61,
    "volume": 2278446
  },
  {
    "time": "07-11-2019",
    "open": 137.87,
    "high": 139.14,
    "low": 137.58,
    "close": 137.69,
    "volume": 4073697
  },
  {
    "time": "06-11-2019",
    "open": 138,
    "high": 138.78,
    "low": 137.55,
    "close": 138.78,
    "volume": 4476362
  },
  {
    "time": "05-11-2019",
    "open": 137.8,
    "high": 138.76,
    "low": 137.63,
    "close": 137.89,
    "volume": 3012926
  },
  {
    "time": "04-11-2019",
    "open": 136.24,
    "high": 137.74,
    "low": 136.23,
    "close": 137.67,
    "volume": 3333510
  },
  {
    "time": "01-11-2019",
    "open": 134.5,
    "high": 135.56,
    "low": 134.09,
    "close": 135.53,
    "volume": 3088769
  },
  {
    "time": "31-10-2019",
    "open": 135.11,
    "high": 135.25,
    "low": 133.23,
    "close": 133.73,
    "volume": 3410851
  },
  {
    "time": "30-10-2019",
    "open": 133.83,
    "high": 135.28,
    "low": 133.2,
    "close": 135.25,
    "volume": 2252680
  },
  {
    "time": "29-10-2019",
    "open": 135.42,
    "high": 135.57,
    "low": 133.44,
    "close": 133.82,
    "volume": 4158483
  },
  {
    "time": "28-10-2019",
    "open": 136,
    "high": 136.63,
    "low": 135.45,
    "close": 135.97,
    "volume": 3225692
  },
  {
    "time": "25-10-2019",
    "open": 134.12,
    "high": 135.93,
    "low": 134.1,
    "close": 135.44,
    "volume": 2577995
  },
  {
    "time": "24-10-2019",
    "open": 134.83,
    "high": 135.07,
    "low": 133.3,
    "close": 134.07,
    "volume": 2619190
  },
  {
    "time": "23-10-2019",
    "open": 133.52,
    "high": 134.57,
    "low": 132.7,
    "close": 134.38,
    "volume": 3626724
  },
  {
    "time": "22-10-2019",
    "open": 132.55,
    "high": 134.05,
    "low": 131.61,
    "close": 133.96,
    "volume": 4193653
  },
  {
    "time": "21-10-2019",
    "open": 132.61,
    "high": 133.1,
    "low": 130.9,
    "close": 132.58,
    "volume": 6370639
  },
  {
    "time": "18-10-2019",
    "open": 134.22,
    "high": 134.49,
    "low": 132.25,
    "close": 134.09,
    "volume": 7177468
  },
  {
    "time": "17-10-2019",
    "open": 135,
    "high": 136,
    "low": 132.91,
    "close": 134.26,
    "volume": 15824614
  },
  {
    "time": "16-10-2019",
    "open": 142.49,
    "high": 142.95,
    "low": 141.39,
    "close": 142.11,
    "volume": 5835941
  },
  {
    "time": "15-10-2019",
    "open": 142.54,
    "high": 143.72,
    "low": 141.81,
    "close": 143,
    "volume": 3044933
  },
  {
    "time": "14-10-2019",
    "open": 142.31,
    "high": 142.43,
    "low": 141.32,
    "close": 142.04,
    "volume": 2325055
  },
  {
    "time": "11-10-2019",
    "open": 142.77,
    "high": 144.5,
    "low": 142.61,
    "close": 142.76,
    "volume": 3015458
  },
  {
    "time": "10-10-2019",
    "open": 139.77,
    "high": 141.78,
    "low": 139.56,
    "close": 141.13,
    "volume": 2619287
  },
  {
    "time": "09-10-2019",
    "open": 139.77,
    "high": 140.33,
    "low": 138.79,
    "close": 139.67,
    "volume": 2631860
  },
  {
    "time": "08-10-2019",
    "open": 140.24,
    "high": 140.47,
    "low": 138.25,
    "close": 138.38,
    "volume": 3342266
  },
  {
    "time": "07-10-2019",
    "open": 142.26,
    "high": 142.7,
    "low": 141.22,
    "close": 141.28,
    "volume": 2487737
  },
  {
    "time": "04-10-2019",
    "open": 142.06,
    "high": 143.06,
    "low": 141.03,
    "close": 142.99,
    "volume": 2447901
  },
  {
    "time": "03-10-2019",
    "open": 141.62,
    "high": 142.07,
    "low": 139.18,
    "close": 142.02,
    "volume": 3138743
  },
  {
    "time": "02-10-2019",
    "open": 142.19,
    "high": 142.27,
    "low": 140.57,
    "close": 141.69,
    "volume": 3347129
  },
  {
    "time": "01-10-2019",
    "open": 145.59,
    "high": 147.35,
    "low": 143.54,
    "close": 143.66,
    "volume": 2973628
  },
  {
    "time": "30-09-2019",
    "open": 143.73,
    "high": 146.57,
    "low": 143.73,
    "close": 145.42,
    "volume": 4004019
  },
  {
    "time": "27-09-2019",
    "open": 144.41,
    "high": 145.09,
    "low": 142.38,
    "close": 143.24,
    "volume": 2356682
  },
  {
    "time": "26-09-2019",
    "open": 143.17,
    "high": 143.88,
    "low": 141.41,
    "close": 143.55,
    "volume": 2313892
  },
  {
    "time": "25-09-2019",
    "open": 141.74,
    "high": 143.63,
    "low": 140.86,
    "close": 143.16,
    "volume": 2544628
  },
  {
    "time": "24-09-2019",
    "open": 142.5,
    "high": 142.89,
    "low": 141.07,
    "close": 141.68,
    "volume": 3311403
  },
  {
    "time": "23-09-2019",
    "open": 141.19,
    "high": 142.22,
    "low": 140.56,
    "close": 142.07,
    "volume": 1849558
  },
  {
    "time": "20-09-2019",
    "open": 143.25,
    "high": 143.83,
    "low": 141.82,
    "close": 141.88,
    "volume": 5217726
  },
  {
    "time": "19-09-2019",
    "open": 142.46,
    "high": 145.03,
    "low": 142.45,
    "close": 142.97,
    "volume": 3181790
  },
  {
    "time": "18-09-2019",
    "open": 142.05,
    "high": 142.3,
    "low": 140.51,
    "close": 142.22,
    "volume": 1994714
  },
  {
    "time": "17-09-2019",
    "open": 142.48,
    "high": 142.48,
    "low": 140.66,
    "close": 142.2,
    "volume": 2940494
  },
  {
    "time": "16-09-2019",
    "open": 142.56,
    "high": 143.64,
    "low": 142.27,
    "close": 142.48,
    "volume": 1928269
  },
  {
    "time": "13-09-2019",
    "open": 144.32,
    "high": 144.65,
    "low": 143.26,
    "close": 143.67,
    "volume": 2206605
  },
  {
    "time": "12-09-2019",
    "open": 144.03,
    "high": 144.04,
    "low": 141.88,
    "close": 143.62,
    "volume": 2504162
  },
  {
    "time": "11-09-2019",
    "open": 144.85,
    "high": 145.12,
    "low": 142.71,
    "close": 143.6,
    "volume": 3867963
  },
  {
    "time": "10-09-2019",
    "open": 143,
    "high": 145.46,
    "low": 142.96,
    "close": 145.05,
    "volume": 4979341
  },
  {
    "time": "09-09-2019",
    "open": 140.59,
    "high": 143.02,
    "low": 140.46,
    "close": 142.6,
    "volume": 4218622
  },
  {
    "time": "06-09-2019",
    "open": 141.52,
    "high": 141.53,
    "low": 140.46,
    "close": 140.57,
    "volume": 2576441
  },
  {
    "time": "05-09-2019",
    "open": 138.3,
    "high": 141.7,
    "low": 138.05,
    "close": 140.97,
    "volume": 5011628
  },
  {
    "time": "04-09-2019",
    "open": 135.71,
    "high": 136.43,
    "low": 135.15,
    "close": 136.32,
    "volume": 2247924
  },
  {
    "time": "03-09-2019",
    "open": 134.85,
    "high": 134.9,
    "low": 133.33,
    "close": 134.1,
    "volume": 2827919
  },
  {
    "time": "30-08-2019",
    "open": 135.58,
    "high": 136.07,
    "low": 134.36,
    "close": 135.53,
    "volume": 2960594
  },
  {
    "time": "29-08-2019",
    "open": 134.18,
    "high": 135.69,
    "low": 134.04,
    "close": 134.88,
    "volume": 2972842
  },
  {
    "time": "28-08-2019",
    "open": 130.44,
    "high": 132.89,
    "low": 130.04,
    "close": 132.76,
    "volume": 2713251
  },
  {
    "time": "27-08-2019",
    "open": 131.2,
    "high": 131.7,
    "low": 130.31,
    "close": 131.17,
    "volume": 4728392
  },
  {
    "time": "26-08-2019",
    "open": 131.05,
    "high": 131.3,
    "low": 129.16,
    "close": 129.99,
    "volume": 2836837
  },
  {
    "time": "23-08-2019",
    "open": 133.63,
    "high": 134.26,
    "low": 128.83,
    "close": 129.57,
    "volume": 4818759
  },
  {
    "time": "22-08-2019",
    "open": 134.69,
    "high": 135.68,
    "low": 133.81,
    "close": 134.32,
    "volume": 2695009
  },
  {
    "time": "21-08-2019",
    "open": 134.96,
    "high": 135.94,
    "low": 133.8,
    "close": 134.25,
    "volume": 2806774
  },
  {
    "time": "20-08-2019",
    "open": 135.24,
    "high": 135.28,
    "low": 132.81,
    "close": 133,
    "volume": 3018934
  },
  {
    "time": "19-08-2019",
    "open": 134.88,
    "high": 136.33,
    "low": 134.88,
    "close": 135.04,
    "volume": 3165436
  },
  {
    "time": "16-08-2019",
    "open": 133.04,
    "high": 134.59,
    "low": 132.95,
    "close": 133.76,
    "volume": 3011993
  },
  {
    "time": "15-08-2019",
    "open": 131.22,
    "high": 132.37,
    "low": 130.25,
    "close": 131.92,
    "volume": 3738945
  },
  {
    "time": "14-08-2019",
    "open": 133.91,
    "high": 134.16,
    "low": 130.98,
    "close": 131.25,
    "volume": 5114159
  },
  {
    "time": "13-08-2019",
    "open": 133.8,
    "high": 136.57,
    "low": 132.81,
    "close": 135.79,
    "volume": 4551170
  },
  {
    "time": "12-08-2019",
    "open": 135.66,
    "high": 135.87,
    "low": 133.18,
    "close": 134.12,
    "volume": 4516866
  },
  {
    "time": "09-08-2019",
    "open": 139.27,
    "high": 139.31,
    "low": 135.35,
    "close": 136.13,
    "volume": 5244594
  },
  {
    "time": "08-08-2019",
    "open": 138.45,
    "high": 140.42,
    "low": 137.76,
    "close": 140.1,
    "volume": 5261444
  },
  {
    "time": "07-08-2019",
    "open": 138.74,
    "high": 139.58,
    "low": 136.41,
    "close": 139.11,
    "volume": 5931883
  },
  {
    "time": "06-08-2019",
    "open": 142.03,
    "high": 142.47,
    "low": 139.31,
    "close": 140.73,
    "volume": 5070650
  },
  {
    "time": "05-08-2019",
    "open": 144.98,
    "high": 145.1,
    "low": 139.15,
    "close": 140.76,
    "volume": 7114871
  },
  {
    "time": "02-08-2019",
    "open": 149.03,
    "high": 152.95,
    "low": 145.59,
    "close": 147.25,
    "volume": 8101720
  },
  {
    "time": "01-08-2019",
    "open": 148.9,
    "high": 152.82,
    "low": 148.5,
    "close": 150.32,
    "volume": 6344006
  },
  {
    "time": "31-07-2019",
    "open": 149.7,
    "high": 150.18,
    "low": 146.39,
    "close": 148.24,
    "volume": 3722907
  },
  {
    "time": "30-07-2019",
    "open": 150,
    "high": 150.4,
    "low": 149.22,
    "close": 149.77,
    "volume": 2632726
  },
  {
    "time": "29-07-2019",
    "open": 151.11,
    "high": 151.48,
    "low": 150.32,
    "close": 150.88,
    "volume": 2075528
  },
  {
    "time": "26-07-2019",
    "open": 151,
    "high": 151.41,
    "low": 150.1,
    "close": 151.36,
    "volume": 2904494
  },
  {
    "time": "25-07-2019",
    "open": 149.82,
    "high": 150.62,
    "low": 148.61,
    "close": 150.39,
    "volume": 3102113
  },
  {
    "time": "24-07-2019",
    "open": 150.12,
    "high": 151.45,
    "low": 149.2,
    "close": 150.03,
    "volume": 2952435
  },
  {
    "time": "23-07-2019",
    "open": 150.57,
    "high": 150.72,
    "low": 148.38,
    "close": 150.38,
    "volume": 3742204
  },
  {
    "time": "22-07-2019",
    "open": 150.16,
    "high": 151.94,
    "low": 149.7,
    "close": 149.74,
    "volume": 4093239
  },
  {
    "time": "19-07-2019",
    "open": 149.96,
    "high": 151.58,
    "low": 149.55,
    "close": 149.68,
    "volume": 7290678
  },
  {
    "time": "18-07-2019",
    "open": 142.5,
    "high": 150.31,
    "low": 142.2,
    "close": 149.63,
    "volume": 12695387
  },
  {
    "time": "17-07-2019",
    "open": 143.61,
    "high": 143.8,
    "low": 141.95,
    "close": 143.07,
    "volume": 6064265
  },
  {
    "time": "16-07-2019",
    "open": 143.17,
    "high": 144.04,
    "low": 143.02,
    "close": 143.53,
    "volume": 3474688
  },
  {
    "time": "15-07-2019",
    "open": 142.89,
    "high": 143.51,
    "low": 142.12,
    "close": 143.32,
    "volume": 2368309
  },
  {
    "time": "12-07-2019",
    "open": 141.92,
    "high": 142.92,
    "low": 141.66,
    "close": 142.74,
    "volume": 2786276
  },
  {
    "time": "11-07-2019",
    "open": 140.79,
    "high": 141.58,
    "low": 140.3,
    "close": 141.24,
    "volume": 2133327
  },
  {
    "time": "10-07-2019",
    "open": 140,
    "high": 141.91,
    "low": 139.79,
    "close": 140.47,
    "volume": 2513931
  },
  {
    "time": "09-07-2019",
    "open": 140.61,
    "high": 140.99,
    "low": 139.13,
    "close": 139.33,
    "volume": 3365227
  },
  {
    "time": "08-07-2019",
    "open": 141.05,
    "high": 141.29,
    "low": 140.27,
    "close": 140.57,
    "volume": 2395487
  },
  {
    "time": "05-07-2019",
    "open": 140.87,
    "high": 141.49,
    "low": 139.91,
    "close": 141.38,
    "volume": 2135085
  },
  {
    "time": "03-07-2019",
    "open": 140.72,
    "high": 141.82,
    "low": 140.43,
    "close": 141.54,
    "volume": 1930361
  },
  {
    "time": "02-07-2019",
    "open": 139.75,
    "high": 140.43,
    "low": 139.47,
    "close": 140.22,
    "volume": 2410092
  },
  {
    "time": "01-07-2019",
    "open": 139.6,
    "high": 141.49,
    "low": 139.28,
    "close": 139.88,
    "volume": 3387184
  },
  {
    "time": "28-06-2019",
    "open": 138.57,
    "high": 139.14,
    "low": 137.84,
    "close": 137.9,
    "volume": 6154039
  },
  {
    "time": "27-06-2019",
    "open": 138.72,
    "high": 139.3,
    "low": 137.95,
    "close": 138.52,
    "volume": 2149824
  },
  {
    "time": "26-06-2019",
    "open": 138.87,
    "high": 139.68,
    "low": 138.41,
    "close": 138.52,
    "volume": 2284567
  },
  {
    "time": "25-06-2019",
    "open": 139.46,
    "high": 139.55,
    "low": 138.13,
    "close": 138.36,
    "volume": 2801251
  },
  {
    "time": "24-06-2019",
    "open": 139.2,
    "high": 140.15,
    "low": 139.05,
    "close": 139.35,
    "volume": 2186137
  },
  {
    "time": "21-06-2019",
    "open": 138.44,
    "high": 139.54,
    "low": 138.39,
    "close": 139.2,
    "volume": 5288759
  },
  {
    "time": "20-06-2019",
    "open": 138.93,
    "high": 139.54,
    "low": 138.2,
    "close": 138.85,
    "volume": 3071017
  },
  {
    "time": "19-06-2019",
    "open": 136.43,
    "high": 138.36,
    "low": 136.24,
    "close": 137.08,
    "volume": 2382520
  },
  {
    "time": "18-06-2019",
    "open": 135.2,
    "high": 137.19,
    "low": 135.2,
    "close": 136.38,
    "volume": 2914698
  },
  {
    "time": "17-06-2019",
    "open": 135.39,
    "high": 136.03,
    "low": 134.7,
    "close": 134.95,
    "volume": 2194129
  },
  {
    "time": "14-06-2019",
    "open": 135.44,
    "high": 135.88,
    "low": 134.43,
    "close": 135.15,
    "volume": 2193367
  },
  {
    "time": "13-06-2019",
    "open": 135.13,
    "high": 136.27,
    "low": 135.09,
    "close": 135.76,
    "volume": 2955419
  },
  {
    "time": "12-06-2019",
    "open": 135.56,
    "high": 135.94,
    "low": 134.41,
    "close": 134.87,
    "volume": 2254741
  },
  {
    "time": "11-06-2019",
    "open": 135.32,
    "high": 136.46,
    "low": 135.05,
    "close": 135.95,
    "volume": 3407345
  },
  {
    "time": "10-06-2019",
    "open": 134.38,
    "high": 135.35,
    "low": 133.91,
    "close": 134.74,
    "volume": 2911327
  },
  {
    "time": "07-06-2019",
    "open": 132.47,
    "high": 134.72,
    "low": 132.21,
    "close": 133.31,
    "volume": 2553398
  },
  {
    "time": "06-06-2019",
    "open": 132.19,
    "high": 132.65,
    "low": 130.9,
    "close": 132.22,
    "volume": 2298248
  },
  {
    "time": "05-06-2019",
    "open": 133.38,
    "high": 133.61,
    "low": 130.47,
    "close": 131.49,
    "volume": 3161584
  },
  {
    "time": "04-06-2019",
    "open": 129.56,
    "high": 132.74,
    "low": 129.09,
    "close": 132.69,
    "volume": 3913535
  },
  {
    "time": "03-06-2019",
    "open": 127.1,
    "high": 128.56,
    "low": 127.06,
    "close": 128.27,
    "volume": 4267080
  },
  {
    "time": "31-05-2019",
    "open": 128.44,
    "high": 128.44,
    "low": 126.85,
    "close": 126.99,
    "volume": 3539343
  },
  {
    "time": "30-05-2019",
    "open": 129.74,
    "high": 129.97,
    "low": 128.93,
    "close": 129.57,
    "volume": 2741880
  },
  {
    "time": "29-05-2019",
    "open": 130,
    "high": 130.28,
    "low": 128.32,
    "close": 129.69,
    "volume": 3678978
  },
  {
    "time": "28-05-2019",
    "open": 132.13,
    "high": 132.64,
    "low": 130.27,
    "close": 130.46,
    "volume": 4909121
  },
  {
    "time": "24-05-2019",
    "open": 133.53,
    "high": 134.25,
    "low": 131.59,
    "close": 132.28,
    "volume": 2596121
  },
  {
    "time": "23-05-2019",
    "open": 135.13,
    "high": 135.13,
    "low": 130.44,
    "close": 132.39,
    "volume": 5343437
  },
  {
    "time": "22-05-2019",
    "open": 136,
    "high": 136.75,
    "low": 135.71,
    "close": 136.35,
    "volume": 1849821
  },
  {
    "time": "21-05-2019",
    "open": 136.12,
    "high": 137.06,
    "low": 135.79,
    "close": 136.45,
    "volume": 2503504
  },
  {
    "time": "20-05-2019",
    "open": 133.53,
    "high": 135.43,
    "low": 132.93,
    "close": 135.12,
    "volume": 3264649
  },
  {
    "time": "17-05-2019",
    "open": 134.68,
    "high": 135.41,
    "low": 133.94,
    "close": 134.32,
    "volume": 2619135
  },
  {
    "time": "16-05-2019",
    "open": 134.74,
    "high": 136.11,
    "low": 134.64,
    "close": 135.88,
    "volume": 3017472
  },
  {
    "time": "15-05-2019",
    "open": 132.59,
    "high": 134.59,
    "low": 132.37,
    "close": 134.4,
    "volume": 2551009
  },
  {
    "time": "14-05-2019",
    "open": 131.82,
    "high": 134.32,
    "low": 131.68,
    "close": 133.31,
    "volume": 3280374
  },
  {
    "time": "13-05-2019",
    "open": 133.28,
    "high": 133.57,
    "low": 130.96,
    "close": 131.42,
    "volume": 4958021
  },
  {
    "time": "10-05-2019",
    "open": 134.88,
    "high": 135.75,
    "low": 132.42,
    "close": 135.32,
    "volume": 3977686
  },
  {
    "time": "09-05-2019",
    "open": 134.89,
    "high": 135.58,
    "low": 133.03,
    "close": 135.34,
    "volume": 4192942
  },
  {
    "time": "08-05-2019",
    "open": 137.77,
    "high": 138.7,
    "low": 137.13,
    "close": 138,
    "volume": 3818909
  },
  {
    "time": "07-05-2019",
    "open": 139.15,
    "high": 139.51,
    "low": 136.19,
    "close": 137.64,
    "volume": 4726771
  },
  {
    "time": "06-05-2019",
    "open": 138.3,
    "high": 140.69,
    "low": 137.9,
    "close": 140.38,
    "volume": 2793655
  },
  {
    "time": "03-05-2019",
    "open": 139.7,
    "high": 141,
    "low": 139.63,
    "close": 140.25,
    "volume": 2580034
  },
  {
    "time": "02-05-2019",
    "open": 140.5,
    "high": 141.28,
    "low": 139.41,
    "close": 139.59,
    "volume": 3541352
  },
  {
    "time": "01-05-2019",
    "open": 140.55,
    "high": 141.81,
    "low": 140.17,
    "close": 140.56,
    "volume": 3053683
  },
  {
    "time": "30-04-2019",
    "open": 139.11,
    "high": 140.44,
    "low": 138.68,
    "close": 140.27,
    "volume": 4638828
  },
  {
    "time": "29-04-2019",
    "open": 139.15,
    "high": 139.63,
    "low": 138.81,
    "close": 139.05,
    "volume": 2958333
  },
  {
    "time": "26-04-2019",
    "open": 139.34,
    "high": 139.89,
    "low": 138.81,
    "close": 139.44,
    "volume": 2319834
  },
  {
    "time": "25-04-2019",
    "open": 139.7,
    "high": 139.75,
    "low": 137.71,
    "close": 138.63,
    "volume": 2910113
  },
  {
    "time": "24-04-2019",
    "open": 140.6,
    "high": 141.31,
    "low": 139.78,
    "close": 139.95,
    "volume": 2774867
  },
  {
    "time": "23-04-2019",
    "open": 139.15,
    "high": 140.92,
    "low": 138.94,
    "close": 140.44,
    "volume": 4462240
  },
  {
    "time": "22-04-2019",
    "open": 139.42,
    "high": 140.56,
    "low": 138.48,
    "close": 138.89,
    "volume": 4494333
  },
  {
    "time": "18-04-2019",
    "open": 139,
    "high": 140.49,
    "low": 138.7,
    "close": 140.33,
    "volume": 4960015
  },
  {
    "time": "17-04-2019",
    "open": 137.37,
    "high": 141.98,
    "low": 136.26,
    "close": 139.11,
    "volume": 12525693
  },
  {
    "time": "16-04-2019",
    "open": 144.4,
    "high": 145.39,
    "low": 144.02,
    "close": 145.14,
    "volume": 6301671
  },
  {
    "time": "15-04-2019",
    "open": 144.4,
    "high": 144.46,
    "low": 143.27,
    "close": 143.9,
    "volume": 3603158
  },
  {
    "time": "12-04-2019",
    "open": 144.26,
    "high": 144.44,
    "low": 143.7,
    "close": 144.35,
    "volume": 2898899
  },
  {
    "time": "11-04-2019",
    "open": 143.8,
    "high": 144.11,
    "low": 143.03,
    "close": 143.78,
    "volume": 2941985
  },
  {
    "time": "10-04-2019",
    "open": 142.2,
    "high": 143.5,
    "low": 141.98,
    "close": 143.02,
    "volume": 2678310
  },
  {
    "time": "09-04-2019",
    "open": 142.66,
    "high": 142.95,
    "low": 141.84,
    "close": 142.11,
    "volume": 3038460
  },
  {
    "time": "08-04-2019",
    "open": 143.02,
    "high": 143.42,
    "low": 142.87,
    "close": 143.39,
    "volume": 2118228
  },
  {
    "time": "05-04-2019",
    "open": 143.29,
    "high": 143.5,
    "low": 142.46,
    "close": 143.28,
    "volume": 2731985
  },
  {
    "time": "04-04-2019",
    "open": 143.62,
    "high": 144.14,
    "low": 142.54,
    "close": 142.78,
    "volume": 2771462
  },
  {
    "time": "03-04-2019",
    "open": 143.65,
    "high": 144.22,
    "low": 143.01,
    "close": 143.63,
    "volume": 2681645
  },
  {
    "time": "02-04-2019",
    "open": 143.35,
    "high": 143.95,
    "low": 142.6,
    "close": 143,
    "volume": 2404920
  },
  {
    "time": "01-04-2019",
    "open": 141.51,
    "high": 143.41,
    "low": 141.51,
    "close": 143.3,
    "volume": 3984250
  },
  {
    "time": "29-03-2019",
    "open": 140.5,
    "high": 141.22,
    "low": 140.15,
    "close": 141.1,
    "volume": 3101454
  },
  {
    "time": "28-03-2019",
    "open": 139.91,
    "high": 140.44,
    "low": 139.1,
    "close": 139.92,
    "volume": 2663884
  },
  {
    "time": "27-03-2019",
    "open": 140.41,
    "high": 140.49,
    "low": 138.4,
    "close": 139.24,
    "volume": 3098233
  },
  {
    "time": "26-03-2019",
    "open": 139.93,
    "high": 141.02,
    "low": 139.42,
    "close": 140.22,
    "volume": 2553708
  },
  {
    "time": "25-03-2019",
    "open": 139.06,
    "high": 139.91,
    "low": 138.35,
    "close": 139.18,
    "volume": 2839766
  },
  {
    "time": "22-03-2019",
    "open": 140.97,
    "high": 141.44,
    "low": 138.9,
    "close": 139.45,
    "volume": 3877616
  },
  {
    "time": "21-03-2019",
    "open": 139.1,
    "high": 142.12,
    "low": 138.88,
    "close": 141.44,
    "volume": 3605420
  },
  {
    "time": "20-03-2019",
    "open": 140.53,
    "high": 140.7,
    "low": 138.98,
    "close": 139.6,
    "volume": 3649455
  },
  {
    "time": "19-03-2019",
    "open": 140.96,
    "high": 141.7,
    "low": 140,
    "close": 140.49,
    "volume": 3482336
  },
  {
    "time": "18-03-2019",
    "open": 139.83,
    "high": 140.37,
    "low": 138.72,
    "close": 140.21,
    "volume": 3268510
  },
  {
    "time": "15-03-2019",
    "open": 139.37,
    "high": 140.33,
    "low": 139.23,
    "close": 139.43,
    "volume": 7153687
  },
  {
    "time": "14-03-2019",
    "open": 138.51,
    "high": 138.94,
    "low": 138.06,
    "close": 138.79,
    "volume": 2738790
  },
  {
    "time": "13-03-2019",
    "open": 138.05,
    "high": 139.32,
    "low": 137.85,
    "close": 138.56,
    "volume": 3173179
  },
  {
    "time": "12-03-2019",
    "open": 138.52,
    "high": 139.7,
    "low": 137.86,
    "close": 138.28,
    "volume": 3954653
  },
  {
    "time": "11-03-2019",
    "open": 136.03,
    "high": 137.73,
    "low": 135.84,
    "close": 137.71,
    "volume": 3939517
  },
  {
    "time": "08-03-2019",
    "open": 134.21,
    "high": 135.24,
    "low": 133.58,
    "close": 135.09,
    "volume": 3773722
  },
  {
    "time": "07-03-2019",
    "open": 136.61,
    "high": 136.89,
    "low": 134.63,
    "close": 135.36,
    "volume": 4358802
  },
  {
    "time": "06-03-2019",
    "open": 138.12,
    "high": 138.39,
    "low": 136.72,
    "close": 136.98,
    "volume": 2768256
  },
  {
    "time": "05-03-2019",
    "open": 138.37,
    "high": 138.74,
    "low": 137.56,
    "close": 137.88,
    "volume": 4250650
  },
  {
    "time": "04-03-2019",
    "open": 139.99,
    "high": 140.08,
    "low": 137.17,
    "close": 138.43,
    "volume": 3407217
  },
  {
    "time": "01-03-2019",
    "open": 139.31,
    "high": 140.04,
    "low": 138.64,
    "close": 139.2,
    "volume": 3030044
  },
  {
    "time": "28-02-2019",
    "open": 138.77,
    "high": 139.06,
    "low": 137.72,
    "close": 138.13,
    "volume": 3457788
  },
  {
    "time": "27-02-2019",
    "open": 139.25,
    "high": 139.57,
    "low": 138.4,
    "close": 139.17,
    "volume": 2530968
  },
  {
    "time": "26-02-2019",
    "open": 139.67,
    "high": 140.49,
    "low": 139.47,
    "close": 139.72,
    "volume": 3060381
  },
  {
    "time": "25-02-2019",
    "open": 140,
    "high": 140.47,
    "low": 139.32,
    "close": 139.46,
    "volume": 3194161
  },
  {
    "time": "22-02-2019",
    "open": 138.73,
    "high": 139.38,
    "low": 138.43,
    "close": 139.25,
    "volume": 3113698
  },
  {
    "time": "21-02-2019",
    "open": 137.82,
    "high": 138.35,
    "low": 137.35,
    "close": 137.84,
    "volume": 2937467
  },
  {
    "time": "20-02-2019",
    "open": 138.76,
    "high": 139.24,
    "low": 137.22,
    "close": 138,
    "volume": 3801979
  },
  {
    "time": "19-02-2019",
    "open": 137.81,
    "high": 138.7,
    "low": 137.36,
    "close": 138.7,
    "volume": 3385675
  },
  {
    "time": "15-02-2019",
    "open": 137.58,
    "high": 138.19,
    "low": 137.39,
    "close": 138.03,
    "volume": 3844062
  },
  {
    "time": "14-02-2019",
    "open": 137.17,
    "high": 137.6,
    "low": 136.21,
    "close": 136.48,
    "volume": 2789839
  },
  {
    "time": "13-02-2019",
    "open": 136.92,
    "high": 137.92,
    "low": 136.41,
    "close": 137.52,
    "volume": 4253042
  },
  {
    "time": "12-02-2019",
    "open": 135.15,
    "high": 136.2,
    "low": 134.86,
    "close": 136.05,
    "volume": 3317161
  },
  {
    "time": "11-02-2019",
    "open": 134.29,
    "high": 135.15,
    "low": 133.91,
    "close": 133.99,
    "volume": 3095069
  },
  {
    "time": "08-02-2019",
    "open": 132.34,
    "high": 133.71,
    "low": 132.19,
    "close": 133.71,
    "volume": 3250074
  },
  {
    "time": "07-02-2019",
    "open": 133.55,
    "high": 134.47,
    "low": 132.12,
    "close": 133.19,
    "volume": 4379439
  },
  {
    "time": "06-02-2019",
    "open": 135.22,
    "high": 136.65,
    "low": 135.17,
    "close": 136.32,
    "volume": 4879670
  },
  {
    "time": "05-02-2019",
    "open": 135.28,
    "high": 135.82,
    "low": 134.92,
    "close": 135.55,
    "volume": 5398907
  },
  {
    "time": "04-02-2019",
    "open": 134.02,
    "high": 135.2,
    "low": 132.99,
    "close": 135.19,
    "volume": 3966597
  },
  {
    "time": "01-02-2019",
    "open": 134.97,
    "high": 135.2,
    "low": 133.35,
    "close": 134.1,
    "volume": 3806047
  },
  {
    "time": "31-01-2019",
    "open": 134.45,
    "high": 134.72,
    "low": 133.74,
    "close": 134.42,
    "volume": 4884031
  },
  {
    "time": "30-01-2019",
    "open": 134,
    "high": 135.03,
    "low": 133.25,
    "close": 134.38,
    "volume": 4500919
  },
  {
    "time": "29-01-2019",
    "open": 134.29,
    "high": 135.41,
    "low": 133.6,
    "close": 134.33,
    "volume": 5037129
  },
  {
    "time": "28-01-2019",
    "open": 133.1,
    "high": 134.81,
    "low": 132.58,
    "close": 134.27,
    "volume": 5357736
  },
  {
    "time": "25-01-2019",
    "open": 132.87,
    "high": 134.44,
    "low": 132.43,
    "close": 133.97,
    "volume": 5708500
  },
  {
    "time": "24-01-2019",
    "open": 132.63,
    "high": 133.21,
    "low": 131.43,
    "close": 132.53,
    "volume": 6322928
  },
  {
    "time": "23-01-2019",
    "open": 131.37,
    "high": 135,
    "low": 130.31,
    "close": 132.89,
    "volume": 22063666
  },
  {
    "time": "22-01-2019",
    "open": 123.3,
    "high": 123.8,
    "low": 121.54,
    "close": 122.52,
    "volume": 10052384
  },
  {
    "time": "18-01-2019",
    "open": 123.27,
    "high": 124.72,
    "low": 122.71,
    "close": 123.82,
    "volume": 6008509
  },
  {
    "time": "17-01-2019",
    "open": 120.56,
    "high": 122.41,
    "low": 120.55,
    "close": 122.19,
    "volume": 5029871
  },
  {
    "time": "16-01-2019",
    "open": 121.58,
    "high": 122,
    "low": 120.83,
    "close": 121.62,
    "volume": 3841100
  },
  {
    "time": "15-01-2019",
    "open": 120.96,
    "high": 121.93,
    "low": 120.82,
    "close": 121.73,
    "volume": 3507719
  },
  {
    "time": "14-01-2019",
    "open": 120.51,
    "high": 120.65,
    "low": 119.76,
    "close": 120.39,
    "volume": 5228909
  },
  {
    "time": "11-01-2019",
    "open": 121.58,
    "high": 121.62,
    "low": 120.2,
    "close": 121.46,
    "volume": 3722370
  },
  {
    "time": "10-01-2019",
    "open": 120.08,
    "high": 121.86,
    "low": 119.95,
    "close": 121.79,
    "volume": 3909971
  },
  {
    "time": "09-01-2019",
    "open": 120.91,
    "high": 121.4,
    "low": 119.87,
    "close": 120.69,
    "volume": 3633663
  },
  {
    "time": "08-01-2019",
    "open": 119.66,
    "high": 120.57,
    "low": 118.98,
    "close": 119.83,
    "volume": 4763636
  },
  {
    "time": "07-01-2019",
    "open": 117.5,
    "high": 118.83,
    "low": 116.67,
    "close": 118.15,
    "volume": 3751204
  },
  {
    "time": "04-01-2019",
    "open": 114.91,
    "high": 117.49,
    "low": 114.44,
    "close": 117.32,
    "volume": 4477755
  },
  {
    "time": "03-01-2019",
    "open": 114.53,
    "high": 114.88,
    "low": 112.69,
    "close": 112.91,
    "volume": 4346659
  },
  {
    "time": "02-01-2019",
    "open": 112.01,
    "high": 115.98,
    "low": 111.69,
    "close": 115.21,
    "volume": 4239924
  },
  {
    "time": "31-12-2018",
    "open": 113.33,
    "high": 114.35,
    "low": 112.4201,
    "close": 113.67,
    "volume": 4982755
  },
  {
    "time": "28-12-2018",
    "open": 114.22,
    "high": 114.8,
    "low": 112.5,
    "close": 113.03,
    "volume": 5117533
  },
  {
    "time": "27-12-2018",
    "open": 109.99,
    "high": 113.78,
    "low": 109.47,
    "close": 113.78,
    "volume": 6045590
  },
  {
    "time": "26-12-2018",
    "open": 108,
    "high": 111.39,
    "low": 105.94,
    "close": 111.39,
    "volume": 6645794
  },
  {
    "time": "24-12-2018",
    "open": 109.9,
    "high": 111,
    "low": 107.5,
    "close": 107.57,
    "volume": 3821376
  },
  {
    "time": "21-12-2018",
    "open": 112.5,
    "high": 115.28,
    "low": 110.44,
    "close": 110.94,
    "volume": 10386453
  },
  {
    "time": "20-12-2018",
    "open": 115.7,
    "high": 116.45,
    "low": 111.7,
    "close": 113.02,
    "volume": 8454350
  },
  {
    "time": "19-12-2018",
    "open": 117.15,
    "high": 120.27,
    "low": 115.97,
    "close": 116.43,
    "volume": 6979823
  },
  {
    "time": "18-12-2018",
    "open": 116.9,
    "high": 118.23,
    "low": 116.02,
    "close": 116.65,
    "volume": 6290186
  },
  {
    "time": "17-12-2018",
    "open": 119.07,
    "high": 119.78,
    "low": 115.07,
    "close": 116.1,
    "volume": 7374476
  },
  {
    "time": "14-12-2018",
    "open": 119.5,
    "high": 121.11,
    "low": 119.44,
    "close": 119.9,
    "volume": 4554204
  },
  {
    "time": "13-12-2018",
    "open": 121.26,
    "high": 121.79,
    "low": 120.17,
    "close": 120.73,
    "volume": 3993597
  },
  {
    "time": "12-12-2018",
    "open": 122.51,
    "high": 122.88,
    "low": 121,
    "close": 121.16,
    "volume": 3560790
  },
  {
    "time": "11-12-2018",
    "open": 123.25,
    "high": 123.81,
    "low": 120,
    "close": 120.89,
    "volume": 5043081
  },
  {
    "time": "10-12-2018",
    "open": 119.74,
    "high": 121.68,
    "low": 118.52,
    "close": 121.13,
    "volume": 6549506
  },
  {
    "time": "07-12-2018",
    "open": 123.9,
    "high": 124.05,
    "low": 118.87,
    "close": 119.34,
    "volume": 7019616
  },
  {
    "time": "06-12-2018",
    "open": 119.72,
    "high": 123.91,
    "low": 119.301,
    "close": 123.91,
    "volume": 6938049
  },
  {
    "time": "04-12-2018",
    "open": 124.49,
    "high": 124.92,
    "low": 121.33,
    "close": 121.6,
    "volume": 6000697
  },
  {
    "time": "03-12-2018",
    "open": 125.67,
    "high": 126.59,
    "low": 124.36,
    "close": 125.31,
    "volume": 5843112
  },
  {
    "time": "30-11-2018",
    "open": 121.66,
    "high": 124.69,
    "low": 121.48,
    "close": 124.27,
    "volume": 7251590
  },
  {
    "time": "29-11-2018",
    "open": 122.44,
    "high": 123.12,
    "low": 121.4,
    "close": 121.48,
    "volume": 5200113
  },
  {
    "time": "28-11-2018",
    "open": 121.01,
    "high": 123.09,
    "low": 120.22,
    "close": 123,
    "volume": 5104520
  },
  {
    "time": "27-11-2018",
    "open": 118.38,
    "high": 120.04,
    "low": 118.37,
    "close": 120.03,
    "volume": 4903771
  },
  {
    "time": "26-11-2018",
    "open": 118.37,
    "high": 120.25,
    "low": 118.06,
    "close": 119.56,
    "volume": 5011130
  },
  {
    "time": "23-11-2018",
    "open": 118.09,
    "high": 118.3382,
    "low": 116.91,
    "close": 117.19,
    "volume": 2334730
  },
  {
    "time": "21-11-2018",
    "open": 117.61,
    "high": 119.51,
    "low": 117.39,
    "close": 118.57,
    "volume": 5335842
  },
  {
    "time": "20-11-2018",
    "open": 118.49,
    "high": 119.49,
    "low": 116.7,
    "close": 117.2,
    "volume": 6233777
  },
  {
    "time": "19-11-2018",
    "open": 121.63,
    "high": 122.18,
    "low": 119.77,
    "close": 120.31,
    "volume": 4131238
  },
  {
    "time": "16-11-2018",
    "open": 120.89,
    "high": 121.85,
    "low": 120.54,
    "close": 121.57,
    "volume": 4076315
  },
  {
    "time": "15-11-2018",
    "open": 119.66,
    "high": 121.44,
    "low": 119.51,
    "close": 121.44,
    "volume": 5207973
  },
  {
    "time": "14-11-2018",
    "open": 121.11,
    "high": 121.93,
    "low": 119.77,
    "close": 120.2,
    "volume": 4762275
  },
  {
    "time": "13-11-2018",
    "open": 121.19,
    "high": 122.25,
    "low": 119.9,
    "close": 120.84,
    "volume": 4084385
  },
  {
    "time": "12-11-2018",
    "open": 123.5,
    "high": 123.57,
    "low": 120.65,
    "close": 120.9,
    "volume": 5379113
  },
  {
    "time": "09-11-2018",
    "open": 123.61,
    "high": 124.57,
    "low": 121.96,
    "close": 123.54,
    "volume": 6961902
  },
  {
    "time": "08-11-2018",
    "open": 122.66,
    "high": 124.4,
    "low": 122.63,
    "close": 123.38,
    "volume": 10396439
  },
  {
    "time": "07-11-2018",
    "open": 123.79,
    "high": 125.06,
    "low": 122.33,
    "close": 124.84,
    "volume": 12382666
  },
  {
    "time": "06-11-2018",
    "open": 120.7,
    "high": 123.81,
    "low": 120.31,
    "close": 123.12,
    "volume": 9597392
  },
  {
    "time": "05-11-2018",
    "open": 116.1,
    "high": 120.77,
    "low": 116,
    "close": 120.06,
    "volume": 9144598
  },
  {
    "time": "02-11-2018",
    "open": 117.51,
    "high": 117.75,
    "low": 114.54,
    "close": 115.67,
    "volume": 10592681
  },
  {
    "time": "01-11-2018",
    "open": 115.5,
    "high": 117,
    "low": 115.11,
    "close": 116.83,
    "volume": 13626730
  },
  {
    "time": "31-10-2018",
    "open": 116.49,
    "high": 116.5,
    "low": 114.09,
    "close": 115.43,
    "volume": 21644978
  },
  {
    "time": "30-10-2018",
    "open": 120.48,
    "high": 121.5,
    "low": 115.15,
    "close": 115.4,
    "volume": 21458594
  },
  {
    "time": "29-10-2018",
    "open": 119.34,
    "high": 123.95,
    "low": 118.3,
    "close": 119.64,
    "volume": 20450448
  },
  {
    "time": "26-10-2018",
    "open": 125.21,
    "high": 125.78,
    "low": 123.71,
    "close": 124.79,
    "volume": 8767029
  },
  {
    "time": "25-10-2018",
    "open": 127.25,
    "high": 127.79,
    "low": 125.14,
    "close": 126.45,
    "volume": 10304327
  },
  {
    "time": "24-10-2018",
    "open": 131.17,
    "high": 131.69,
    "low": 127,
    "close": 127.21,
    "volume": 5710320
  },
  {
    "time": "23-10-2018",
    "open": 129.02,
    "high": 131.9,
    "low": 128.41,
    "close": 131.21,
    "volume": 6463580
  },
  {
    "time": "22-10-2018",
    "open": 129.58,
    "high": 130.44,
    "low": 128.4,
    "close": 130.02,
    "volume": 5719518
  },
  {
    "time": "19-10-2018",
    "open": 130.65,
    "high": 131.89,
    "low": 127.96,
    "close": 129.1,
    "volume": 7791636
  },
  {
    "time": "18-10-2018",
    "open": 132.73,
    "high": 133.4,
    "low": 130.04,
    "close": 130.55,
    "volume": 10011678
  },
  {
    "time": "17-10-2018",
    "open": 135.89,
    "high": 137.15,
    "low": 133.42,
    "close": 134.05,
    "volume": 19464000
  },
  {
    "time": "16-10-2018",
    "open": 142.93,
    "high": 145.45,
    "low": 141.58,
    "close": 145.12,
    "volume": 8695489
  },
  {
    "time": "15-10-2018",
    "open": 140.38,
    "high": 142.26,
    "low": 140.14,
    "close": 141.13,
    "volume": 4965533
  },
  {
    "time": "12-10-2018",
    "open": 141.12,
    "high": 141.54,
    "low": 139.08,
    "close": 140.85,
    "volume": 6563403
  },
  {
    "time": "11-10-2018",
    "open": 142.62,
    "high": 144.19,
    "low": 138.78,
    "close": 139.02,
    "volume": 8710491
  },
  {
    "time": "10-10-2018",
    "open": 147.5,
    "high": 147.69,
    "low": 142.5,
    "close": 142.69,
    "volume": 5547697
  },
  {
    "time": "09-10-2018",
    "open": 148.08,
    "high": 148.36,
    "low": 146.93,
    "close": 147.24,
    "volume": 3642496
  },
  {
    "time": "08-10-2018",
    "open": 148.5,
    "high": 149.07,
    "low": 147.18,
    "close": 148.39,
    "volume": 3239347
  },
  {
    "time": "05-10-2018",
    "open": 150.9,
    "high": 151.79,
    "low": 147.87,
    "close": 149.03,
    "volume": 4602252
  },
  {
    "time": "04-10-2018",
    "open": 151.69,
    "high": 152.8,
    "low": 150.36,
    "close": 151.31,
    "volume": 4238143
  },
  {
    "time": "03-10-2018",
    "open": 154,
    "high": 154.36,
    "low": 152.8,
    "close": 153.22,
    "volume": 3315298
  },
  {
    "time": "02-10-2018",
    "open": 152.99,
    "high": 153.84,
    "low": 152.5,
    "close": 153.75,
    "volume": 3030125
  },
  {
    "time": "01-10-2018",
    "open": 151.71,
    "high": 153.42,
    "low": 151.71,
    "close": 153,
    "volume": 3757419
  },
  {
    "time": "28-09-2018",
    "open": 151.16,
    "high": 151.63,
    "low": 150.79,
    "close": 151.21,
    "volume": 3305798
  },
  {
    "time": "27-09-2018",
    "open": 152.01,
    "high": 152.39,
    "low": 151.16,
    "close": 151.5,
    "volume": 3477643
  },
  {
    "time": "26-09-2018",
    "open": 152.4,
    "high": 153.35,
    "low": 151.34,
    "close": 151.61,
    "volume": 7401862
  },
  {
    "time": "25-09-2018",
    "open": 149.91,
    "high": 150.41,
    "low": 148.36,
    "close": 148.91,
    "volume": 4397457
  },
  {
    "time": "24-09-2018",
    "open": 151.1,
    "high": 151.2,
    "low": 149.46,
    "close": 150.03,
    "volume": 4264984
  },
  {
    "time": "21-09-2018",
    "open": 151.48,
    "high": 152.6,
    "low": 150.74,
    "close": 151.35,
    "volume": 19947878
  },
  {
    "time": "20-09-2018",
    "open": 149.58,
    "high": 151.38,
    "low": 149.44,
    "close": 151.15,
    "volume": 5952223
  },
  {
    "time": "19-09-2018",
    "open": 148.63,
    "high": 149.96,
    "low": 148.23,
    "close": 149.06,
    "volume": 4246382
  },
  {
    "time": "18-09-2018",
    "open": 147.76,
    "high": 148.99,
    "low": 147.47,
    "close": 148.56,
    "volume": 3629596
  },
  {
    "time": "17-09-2018",
    "open": 148.44,
    "high": 149.07,
    "low": 147.77,
    "close": 147.94,
    "volume": 3551882
  },
  {
    "time": "14-09-2018",
    "open": 148.85,
    "high": 149.3,
    "low": 147.78,
    "close": 148.33,
    "volume": 3452144
  },
  {
    "time": "13-09-2018",
    "open": 147.35,
    "high": 149,
    "low": 147.12,
    "close": 148.95,
    "volume": 5346540
  },
  {
    "time": "12-09-2018",
    "open": 146.37,
    "high": 147.98,
    "low": 146.03,
    "close": 146.57,
    "volume": 4506679
  },
  {
    "time": "11-09-2018",
    "open": 146,
    "high": 146.99,
    "low": 145.25,
    "close": 146.49,
    "volume": 3374774
  },
  {
    "time": "10-09-2018",
    "open": 146.6,
    "high": 147.3,
    "low": 145.56,
    "close": 145.65,
    "volume": 3618209
  },
  {
    "time": "07-09-2018",
    "open": 145.94,
    "high": 146.5,
    "low": 145.12,
    "close": 145.45,
    "volume": 4693127
  },
  {
    "time": "06-09-2018",
    "open": 146.88,
    "high": 147.66,
    "low": 145.54,
    "close": 146.39,
    "volume": 4248831
  },
  {
    "time": "05-09-2018",
    "open": 145.19,
    "high": 146.75,
    "low": 145.05,
    "close": 146.66,
    "volume": 3126540
  },
  {
    "time": "04-09-2018",
    "open": 145.98,
    "high": 146.19,
    "low": 144.81,
    "close": 145.68,
    "volume": 3326153
  },
  {
    "time": "31-08-2018",
    "open": 145.72,
    "high": 146.78,
    "low": 145.54,
    "close": 146.48,
    "volume": 3488476
  },
  {
    "time": "30-08-2018",
    "open": 147.03,
    "high": 147.3,
    "low": 145.25,
    "close": 145.93,
    "volume": 3340369
  },
  {
    "time": "29-08-2018",
    "open": 146.87,
    "high": 147.93,
    "low": 146.6,
    "close": 147.54,
    "volume": 3153845
  },
  {
    "time": "28-08-2018",
    "open": 146.95,
    "high": 146.99,
    "low": 146.19,
    "close": 146.59,
    "volume": 2455328
  },
  {
    "time": "27-08-2018",
    "open": 146.94,
    "high": 147.62,
    "low": 146.52,
    "close": 146.69,
    "volume": 3491565
  },
  {
    "time": "24-08-2018",
    "open": 145.46,
    "high": 146.09,
    "low": 144.87,
    "close": 146.04,
    "volume": 2776813
  },
  {
    "time": "23-08-2018",
    "open": 145.04,
    "high": 145.52,
    "low": 144.75,
    "close": 145.37,
    "volume": 2220603
  },
  {
    "time": "22-08-2018",
    "open": 146.01,
    "high": 146.16,
    "low": 145.19,
    "close": 145.24,
    "volume": 2388961
  },
  {
    "time": "21-08-2018",
    "open": 147,
    "high": 147.12,
    "low": 145.89,
    "close": 145.97,
    "volume": 3106876
  },
  {
    "time": "20-08-2018",
    "open": 146.37,
    "high": 147.16,
    "low": 146.15,
    "close": 146.51,
    "volume": 2499654
  },
  {
    "time": "17-08-2018",
    "open": 144.79,
    "high": 146.39,
    "low": 144.69,
    "close": 146.06,
    "volume": 2678820
  },
  {
    "time": "16-08-2018",
    "open": 144.37,
    "high": 145.68,
    "low": 144.37,
    "close": 145.34,
    "volume": 5250878
  },
  {
    "time": "15-08-2018",
    "open": 142.55,
    "high": 144,
    "low": 142,
    "close": 143.91,
    "volume": 4241496
  },
  {
    "time": "14-08-2018",
    "open": 143,
    "high": 143.82,
    "low": 142.93,
    "close": 143.36,
    "volume": 2860041
  },
  {
    "time": "13-08-2018",
    "open": 144.15,
    "high": 144.3,
    "low": 142.53,
    "close": 142.71,
    "volume": 2858842
  },
  {
    "time": "10-08-2018",
    "open": 144.5,
    "high": 145.07,
    "low": 143.88,
    "close": 144.48,
    "volume": 3003158
  },
  {
    "time": "09-08-2018",
    "open": 145.53,
    "high": 145.94,
    "low": 144.73,
    "close": 144.77,
    "volume": 3093504
  },
  {
    "time": "08-08-2018",
    "open": 147.33,
    "high": 147.43,
    "low": 146.24,
    "close": 146.89,
    "volume": 3581746
  },
  {
    "time": "07-08-2018",
    "open": 146.65,
    "high": 147.64,
    "low": 146.35,
    "close": 147.01,
    "volume": 3508758
  },
  {
    "time": "06-08-2018",
    "open": 146.95,
    "high": 147.2,
    "low": 145.57,
    "close": 146.03,
    "volume": 3913743
  },
  {
    "time": "03-08-2018",
    "open": 143.24,
    "high": 147.92,
    "low": 143,
    "close": 147.7,
    "volume": 8053567
  },
  {
    "time": "02-08-2018",
    "open": 142.86,
    "high": 143.36,
    "low": 142.33,
    "close": 142.96,
    "volume": 3841447
  },
  {
    "time": "01-08-2018",
    "open": 144.76,
    "high": 145.19,
    "low": 143.34,
    "close": 143.5,
    "volume": 3388386
  },
  {
    "time": "31-07-2018",
    "open": 145.74,
    "high": 145.8,
    "low": 144.16,
    "close": 144.93,
    "volume": 5474478
  },
  {
    "time": "30-07-2018",
    "open": 144.8,
    "high": 146.24,
    "low": 144.69,
    "close": 145.49,
    "volume": 3608283
  },
  {
    "time": "27-07-2018",
    "open": 146.89,
    "high": 147.14,
    "low": 144.66,
    "close": 145.15,
    "volume": 3749642
  },
  {
    "time": "26-07-2018",
    "open": 147.43,
    "high": 149.27,
    "low": 146.63,
    "close": 146.71,
    "volume": 4778022
  },
  {
    "time": "25-07-2018",
    "open": 146.01,
    "high": 146.65,
    "low": 145.5,
    "close": 146.62,
    "volume": 3623182
  },
  {
    "time": "24-07-2018",
    "open": 146.7,
    "high": 147.04,
    "low": 145.92,
    "close": 146.38,
    "volume": 3891625
  },
  {
    "time": "23-07-2018",
    "open": 146.35,
    "high": 146.7,
    "low": 145.01,
    "close": 145.7,
    "volume": 3897892
  },
  {
    "time": "20-07-2018",
    "open": 148.58,
    "high": 148.86,
    "low": 146.26,
    "close": 146.35,
    "volume": 6415972
  },
  {
    "time": "19-07-2018",
    "open": 147.85,
    "high": 150.54,
    "low": 147.25,
    "close": 149.24,
    "volume": 14655804
  },
  {
    "time": "18-07-2018",
    "open": 143.51,
    "high": 144.8,
    "low": 142.74,
    "close": 144.52,
    "volume": 6935288
  },
  {
    "time": "17-07-2018",
    "open": 144.75,
    "high": 145,
    "low": 143.34,
    "close": 143.49,
    "volume": 5096741
  },
  {
    "time": "16-07-2018",
    "open": 145.67,
    "high": 145.79,
    "low": 144.21,
    "close": 145.46,
    "volume": 3468817
  },
  {
    "time": "13-07-2018",
    "open": 146.45,
    "high": 146.98,
    "low": 145.8,
    "close": 145.9,
    "volume": 3067638
  },
  {
    "time": "12-07-2018",
    "open": 145.85,
    "high": 146.83,
    "low": 145.74,
    "close": 146.45,
    "volume": 3119505
  },
  {
    "time": "11-07-2018",
    "open": 144,
    "high": 146.19,
    "low": 144,
    "close": 144.94,
    "volume": 3526565
  },
  {
    "time": "10-07-2018",
    "open": 144.51,
    "high": 145.59,
    "low": 144.26,
    "close": 144.71,
    "volume": 3776991
  },
  {
    "time": "09-07-2018",
    "open": 142.59,
    "high": 144.72,
    "low": 142.47,
    "close": 144.39,
    "volume": 3904652
  },
  {
    "time": "06-07-2018",
    "open": 141.53,
    "high": 142.94,
    "low": 141.17,
    "close": 142.48,
    "volume": 2910426
  },
  {
    "time": "05-07-2018",
    "open": 140.48,
    "high": 141.43,
    "low": 139.93,
    "close": 141.43,
    "volume": 3744730
  },
  {
    "time": "03-07-2018",
    "open": 140.65,
    "high": 140.94,
    "low": 139.37,
    "close": 139.57,
    "volume": 1963232
  },
  {
    "time": "02-07-2018",
    "open": 138.28,
    "high": 140.22,
    "low": 138.2,
    "close": 139.86,
    "volume": 3405642
  },
  {
    "time": "29-06-2018",
    "open": 140.32,
    "high": 141.36,
    "low": 139.61,
    "close": 139.7,
    "volume": 3753684
  },
  {
    "time": "28-06-2018",
    "open": 138.1,
    "high": 140.54,
    "low": 137.95,
    "close": 140.04,
    "volume": 4523666
  },
  {
    "time": "27-06-2018",
    "open": 139.11,
    "high": 139.78,
    "low": 137.45,
    "close": 137.48,
    "volume": 4097588
  },
  {
    "time": "26-06-2018",
    "open": 139.36,
    "high": 139.85,
    "low": 138.33,
    "close": 139.08,
    "volume": 4139455
  },
  {
    "time": "25-06-2018",
    "open": 140.4,
    "high": 140.4,
    "low": 138.28,
    "close": 138.79,
    "volume": 8937085
  },
  {
    "time": "22-06-2018",
    "open": 141.64,
    "high": 141.81,
    "low": 140.78,
    "close": 141.28,
    "volume": 6013678
  },
  {
    "time": "21-06-2018",
    "open": 142.59,
    "high": 143.14,
    "low": 140.83,
    "close": 141.25,
    "volume": 3887650
  },
  {
    "time": "20-06-2018",
    "open": 144.22,
    "high": 144.52,
    "low": 142.41,
    "close": 142.63,
    "volume": 3329917
  },
  {
    "time": "19-06-2018",
    "open": 143.12,
    "high": 144.22,
    "low": 142.95,
    "close": 143.9,
    "volume": 3487015
  },
  {
    "time": "18-06-2018",
    "open": 144.18,
    "high": 144.52,
    "low": 143.14,
    "close": 144.48,
    "volume": 4286406
  },
  {
    "time": "15-06-2018",
    "open": 144.9,
    "high": 145.47,
    "low": 143.7,
    "close": 145.39,
    "volume": 9116048
  },
  {
    "time": "14-06-2018",
    "open": 146.65,
    "high": 146.98,
    "low": 145.09,
    "close": 145.2,
    "volume": 3326343
  },
  {
    "time": "13-06-2018",
    "open": 146.79,
    "high": 147.47,
    "low": 146.2,
    "close": 146.35,
    "volume": 2968180
  },
  {
    "time": "12-06-2018",
    "open": 147.1,
    "high": 147.52,
    "low": 146.62,
    "close": 146.87,
    "volume": 2640515
  },
  {
    "time": "11-06-2018",
    "open": 146.62,
    "high": 147.41,
    "low": 146.14,
    "close": 146.58,
    "volume": 3259986
  },
  {
    "time": "08-06-2018",
    "open": 145,
    "high": 146.43,
    "low": 144.37,
    "close": 146.14,
    "volume": 5210468
  },
  {
    "time": "07-06-2018",
    "open": 145,
    "high": 146.7,
    "low": 144.93,
    "close": 145.36,
    "volume": 4316484
  },
  {
    "time": "06-06-2018",
    "open": 144.34,
    "high": 144.72,
    "low": 143.54,
    "close": 144.71,
    "volume": 3220139
  },
  {
    "time": "05-06-2018",
    "open": 143.05,
    "high": 144.08,
    "low": 143.04,
    "close": 143.7,
    "volume": 2836017
  },
  {
    "time": "04-06-2018",
    "open": 142.3,
    "high": 143.17,
    "low": 142.18,
    "close": 143.04,
    "volume": 3035257
  },
  {
    "time": "01-06-2018",
    "open": 142.43,
    "high": 142.84,
    "low": 141.62,
    "close": 141.95,
    "volume": 3857530
  },
  {
    "time": "31-05-2018",
    "open": 142.81,
    "high": 142.81,
    "low": 141.05,
    "close": 141.31,
    "volume": 6830256
  },
  {
    "time": "30-05-2018",
    "open": 141.9,
    "high": 143.1,
    "low": 141.82,
    "close": 142.62,
    "volume": 5118530
  },
  {
    "time": "29-05-2018",
    "open": 142.53,
    "high": 142.89,
    "low": 140.21,
    "close": 141.22,
    "volume": 4537638
  },
  {
    "time": "25-05-2018",
    "open": 143.9,
    "high": 144.57,
    "low": 143.46,
    "close": 143.64,
    "volume": 4914294
  },
  {
    "time": "24-05-2018",
    "open": 144.39,
    "high": 144.48,
    "low": 142.95,
    "close": 144.07,
    "volume": 3398701
  },
  {
    "time": "23-05-2018",
    "open": 144.35,
    "high": 144.7,
    "low": 143.48,
    "close": 144.67,
    "volume": 3459747
  },
  {
    "time": "22-05-2018",
    "open": 145.52,
    "high": 146.2,
    "low": 145,
    "close": 145.09,
    "volume": 2284020
  },
  {
    "time": "21-05-2018",
    "open": 144.95,
    "high": 146.04,
    "low": 144.68,
    "close": 145.49,
    "volume": 2894698
  },
  {
    "time": "18-05-2018",
    "open": 144.47,
    "high": 144.94,
    "low": 143.99,
    "close": 144.08,
    "volume": 2682243
  },
  {
    "time": "17-05-2018",
    "open": 144.5,
    "high": 145.19,
    "low": 143.77,
    "close": 144.5,
    "volume": 3147659
  },
  {
    "time": "16-05-2018",
    "open": 143.7,
    "high": 145.03,
    "low": 143.27,
    "close": 144.63,
    "volume": 3168659
  },
  {
    "time": "15-05-2018",
    "open": 143.5,
    "high": 143.99,
    "low": 142.92,
    "close": 143.74,
    "volume": 4089397
  },
  {
    "time": "14-05-2018",
    "open": 144.47,
    "high": 145.12,
    "low": 144.14,
    "close": 144.3,
    "volume": 3216062
  },
  {
    "time": "11-05-2018",
    "open": 144.11,
    "high": 144.85,
    "low": 143.58,
    "close": 144.14,
    "volume": 3073167
  },
  {
    "time": "10-05-2018",
    "open": 143.15,
    "high": 144.33,
    "low": 143.01,
    "close": 144.24,
    "volume": 3726608
  },
  {
    "time": "09-05-2018",
    "open": 141.9,
    "high": 143.02,
    "low": 141.28,
    "close": 142.61,
    "volume": 4195953
  },
  {
    "time": "08-05-2018",
    "open": 143,
    "high": 143.51,
    "low": 142.06,
    "close": 143,
    "volume": 5369119
  },
  {
    "time": "07-05-2018",
    "open": 144,
    "high": 144.32,
    "low": 142.64,
    "close": 143.22,
    "volume": 3657009
  },
  {
    "time": "04-05-2018",
    "open": 141.1,
    "high": 144.35,
    "low": 140.28,
    "close": 143.91,
    "volume": 4402170
  },
  {
    "time": "03-05-2018",
    "open": 142.13,
    "high": 142.23,
    "low": 139.9,
    "close": 141.99,
    "volume": 5018592
  },
  {
    "time": "02-05-2018",
    "open": 144.46,
    "high": 144.82,
    "low": 142.07,
    "close": 142.45,
    "volume": 5194917
  },
  {
    "time": "01-05-2018",
    "open": 144.65,
    "high": 145.02,
    "low": 143.47,
    "close": 145,
    "volume": 4433393
  },
  {
    "time": "30-04-2018",
    "open": 146.86,
    "high": 147.38,
    "low": 144.96,
    "close": 144.96,
    "volume": 4415533
  },
  {
    "time": "27-04-2018",
    "open": 146.84,
    "high": 147.25,
    "low": 145.66,
    "close": 146.48,
    "volume": 3205482
  },
  {
    "time": "26-04-2018",
    "open": 146.6,
    "high": 147.36,
    "low": 146.2,
    "close": 146.72,
    "volume": 4265812
  },
  {
    "time": "25-04-2018",
    "open": 145.79,
    "high": 146.4,
    "low": 144.11,
    "close": 145.94,
    "volume": 7324654
  },
  {
    "time": "24-04-2018",
    "open": 146.51,
    "high": 147.3,
    "low": 144.53,
    "close": 145.56,
    "volume": 4916476
  },
  {
    "time": "23-04-2018",
    "open": 145.03,
    "high": 145.96,
    "low": 144.33,
    "close": 145.86,
    "volume": 5315625
  },
  {
    "time": "20-04-2018",
    "open": 147.9,
    "high": 147.97,
    "low": 144.51,
    "close": 144.9,
    "volume": 8265643
  },
  {
    "time": "19-04-2018",
    "open": 149.19,
    "high": 149.21,
    "low": 146.62,
    "close": 147.7,
    "volume": 8086056
  },
  {
    "time": "18-04-2018",
    "open": 152.14,
    "high": 152.63,
    "low": 148.26,
    "close": 148.79,
    "volume": 20381695
  },
  {
    "time": "17-04-2018",
    "open": 158.82,
    "high": 162,
    "low": 158.51,
    "close": 160.91,
    "volume": 10207534
  },
  {
    "time": "16-04-2018",
    "open": 157.99,
    "high": 159.14,
    "low": 157.67,
    "close": 157.89,
    "volume": 4186755
  },
  {
    "time": "13-04-2018",
    "open": 158.67,
    "high": 159.22,
    "low": 155.91,
    "close": 156.71,
    "volume": 4057058
  },
  {
    "time": "12-04-2018",
    "open": 156.75,
    "high": 158.98,
    "low": 156.67,
    "close": 158.07,
    "volume": 5639444
  },
  {
    "time": "11-04-2018",
    "open": 154.37,
    "high": 155.78,
    "low": 153.88,
    "close": 155.36,
    "volume": 3306539
  },
  {
    "time": "10-04-2018",
    "open": 155.03,
    "high": 156.6,
    "low": 154.75,
    "close": 155.39,
    "volume": 3955952
  },
  {
    "time": "09-04-2018",
    "open": 151.8,
    "high": 154.66,
    "low": 151.74,
    "close": 152.69,
    "volume": 4413178
  },
  {
    "time": "06-04-2018",
    "open": 153.46,
    "high": 153.95,
    "low": 149.54,
    "close": 150.57,
    "volume": 3672919
  },
  {
    "time": "05-04-2018",
    "open": 154.44,
    "high": 154.92,
    "low": 153.34,
    "close": 154.03,
    "volume": 3185388
  },
  {
    "time": "04-04-2018",
    "open": 147.89,
    "high": 154.47,
    "low": 147.45,
    "close": 154.12,
    "volume": 4805261
  },
  {
    "time": "03-04-2018",
    "open": 150.8,
    "high": 151,
    "low": 148.3,
    "close": 149.85,
    "volume": 4135696
  },
  {
    "time": "02-04-2018",
    "open": 153.34,
    "high": 153.38,
    "low": 148.43,
    "close": 150.07,
    "volume": 5150435
  },
  {
    "time": "29-03-2018",
    "open": 153.07,
    "high": 153.89,
    "low": 151.08,
    "close": 153.43,
    "volume": 3419959
  },
  {
    "time": "28-03-2018",
    "open": 152.07,
    "high": 153.86,
    "low": 151.89,
    "close": 152.52,
    "volume": 3664826
  },
  {
    "time": "27-03-2018",
    "open": 153.95,
    "high": 154.87,
    "low": 151.16,
    "close": 151.91,
    "volume": 3810994
  },
  {
    "time": "26-03-2018",
    "open": 151.21,
    "high": 153.66,
    "low": 150.28,
    "close": 153.37,
    "volume": 4038586
  },
  {
    "time": "23-03-2018",
    "open": 152.25,
    "high": 152.58,
    "low": 148.54,
    "close": 148.89,
    "volume": 4389015
  },
  {
    "time": "22-03-2018",
    "open": 155,
    "high": 155.25,
    "low": 152,
    "close": 152.09,
    "volume": 4617371
  },
  {
    "time": "21-03-2018",
    "open": 156.57,
    "high": 158.2,
    "low": 155.92,
    "close": 156.69,
    "volume": 3240695
  },
  {
    "time": "20-03-2018",
    "open": 157.52,
    "high": 157.93,
    "low": 155.29,
    "close": 156.2,
    "volume": 4296935
  },
  {
    "time": "19-03-2018",
    "open": 159.71,
    "high": 159.89,
    "low": 156.45,
    "close": 157.35,
    "volume": 5415258
  },
  {
    "time": "16-03-2018",
    "open": 160.06,
    "high": 161.98,
    "low": 159.64,
    "close": 160.26,
    "volume": 8447294
  },
  {
    "time": "15-03-2018",
    "open": 158.71,
    "high": 161.25,
    "low": 158.52,
    "close": 159.61,
    "volume": 3860479
  },
  {
    "time": "14-03-2018",
    "open": 160.17,
    "high": 160.68,
    "low": 157.74,
    "close": 158.12,
    "volume": 3614293
  },
  {
    "time": "13-03-2018",
    "open": 160.09,
    "high": 162.11,
    "low": 158.81,
    "close": 159.32,
    "volume": 4097662
  },
  {
    "time": "12-03-2018",
    "open": 159.64,
    "high": 161.02,
    "low": 158.87,
    "close": 160.26,
    "volume": 5056489
  },
  {
    "time": "09-03-2018",
    "open": 157.47,
    "high": 159.58,
    "low": 157.3,
    "close": 159.31,
    "volume": 4783247
  },
  {
    "time": "08-03-2018",
    "open": 159,
    "high": 159.57,
    "low": 155.07,
    "close": 156.21,
    "volume": 6095521
  },
  {
    "time": "07-03-2018",
    "open": 155,
    "high": 158.83,
    "low": 154.73,
    "close": 158.32,
    "volume": 4607677
  },
  {
    "time": "06-03-2018",
    "open": 157.28,
    "high": 157.89,
    "low": 155.16,
    "close": 155.72,
    "volume": 3685736
  },
  {
    "time": "05-03-2018",
    "open": 154.12,
    "high": 157.49,
    "low": 153.75,
    "close": 156.95,
    "volume": 3670630
  },
  {
    "time": "02-03-2018",
    "open": 152.79,
    "high": 154.76,
    "low": 151.88,
    "close": 154.49,
    "volume": 3261141
  },
  {
    "time": "01-03-2018",
    "open": 155.53,
    "high": 156.97,
    "low": 152.79,
    "close": 153.81,
    "volume": 4039339
  },
  {
    "time": "28-02-2018",
    "open": 157.5,
    "high": 158.14,
    "low": 155.8,
    "close": 155.83,
    "volume": 3761597
  },
  {
    "time": "27-02-2018",
    "open": 158.46,
    "high": 159.78,
    "low": 156.53,
    "close": 156.55,
    "volume": 4192962
  },
  {
    "time": "26-02-2018",
    "open": 155.81,
    "high": 158.88,
    "low": 155.51,
    "close": 158.58,
    "volume": 3576723
  },
  {
    "time": "23-02-2018",
    "open": 154.3,
    "high": 155.85,
    "low": 154.07,
    "close": 155.52,
    "volume": 3208908
  },
  {
    "time": "22-02-2018",
    "open": 154.5,
    "high": 155.04,
    "low": 152.79,
    "close": 153.18,
    "volume": 4017699
  },
  {
    "time": "21-02-2018",
    "open": 155.43,
    "high": 156.79,
    "low": 153.9,
    "close": 153.96,
    "volume": 3492694
  },
  {
    "time": "20-02-2018",
    "open": 155.79,
    "high": 155.96,
    "low": 153.87,
    "close": 155.16,
    "volume": 4267801
  },
  {
    "time": "16-02-2018",
    "open": 155.71,
    "high": 157.79,
    "low": 155.69,
    "close": 156.18,
    "volume": 4239560
  },
  {
    "time": "15-02-2018",
    "open": 155.5,
    "high": 156.86,
    "low": 153.7,
    "close": 156.01,
    "volume": 5525079
  },
  {
    "time": "14-02-2018",
    "open": 150.19,
    "high": 155,
    "low": 150,
    "close": 154.76,
    "volume": 5422862
  },
  {
    "time": "13-02-2018",
    "open": 150.9,
    "high": 151.18,
    "low": 149.64,
    "close": 150.75,
    "volume": 4854790
  },
  {
    "time": "12-02-2018",
    "open": 150.9,
    "high": 152.4,
    "low": 150.3,
    "close": 151.4,
    "volume": 5659623
  },
  {
    "time": "09-02-2018",
    "open": 148.6,
    "high": 150.54,
    "low": 144.4,
    "close": 149.51,
    "volume": 7763603
  },
  {
    "time": "08-02-2018",
    "open": 152.48,
    "high": 153.16,
    "low": 147.5,
    "close": 147.59,
    "volume": 6599136
  },
  {
    "time": "07-02-2018",
    "open": 154.17,
    "high": 155.34,
    "low": 153.28,
    "close": 153.85,
    "volume": 6069258
  },
  {
    "time": "06-02-2018",
    "open": 150.29,
    "high": 155.49,
    "low": 149.11,
    "close": 155.34,
    "volume": 9823824
  },
  {
    "time": "05-02-2018",
    "open": 157.89,
    "high": 158.5,
    "low": 150,
    "close": 152.53,
    "volume": 8600416
  },
  {
    "time": "02-02-2018",
    "open": 161.7,
    "high": 162,
    "low": 158.87,
    "close": 159.03,
    "volume": 5174779
  },
  {
    "time": "01-02-2018",
    "open": 163.19,
    "high": 164.13,
    "low": 161.9,
    "close": 162.4,
    "volume": 4422505
  },
  {
    "time": "31-01-2018",
    "open": 163.75,
    "high": 164.99,
    "low": 162.76,
    "close": 163.7,
    "volume": 4025105
  },
  {
    "time": "30-01-2018",
    "open": 166.21,
    "high": 166.77,
    "low": 163.16,
    "close": 163.62,
    "volume": 4796200
  },
  {
    "time": "29-01-2018",
    "open": 167.45,
    "high": 168.39,
    "low": 166.01,
    "close": 166.8,
    "volume": 3423903
  },
  {
    "time": "26-01-2018",
    "open": 166.12,
    "high": 167.41,
    "low": 165.79,
    "close": 167.34,
    "volume": 3664496
  },
  {
    "time": "25-01-2018",
    "open": 165.91,
    "high": 166.78,
    "low": 165.19,
    "close": 165.47,
    "volume": 3240177
  },
  {
    "time": "24-01-2018",
    "open": 166.75,
    "high": 168.72,
    "low": 164.82,
    "close": 165.37,
    "volume": 5507664
  },
  {
    "time": "23-01-2018",
    "open": 162.82,
    "high": 166.26,
    "low": 162.51,
    "close": 166.25,
    "volume": 7417268
  },
  {
    "time": "22-01-2018",
    "open": 161.51,
    "high": 163.98,
    "low": 161.11,
    "close": 162.6,
    "volume": 8340507
  },
  {
    "time": "19-01-2018",
    "open": 164.46,
    "high": 165.4,
    "low": 161.3,
    "close": 162.37,
    "volume": 21079530
  },
  {
    "time": "18-01-2018",
    "open": 170,
    "high": 171.13,
    "low": 168.15,
    "close": 169.12,
    "volume": 10948033
  },
  {
    "time": "17-01-2018",
    "open": 168.01,
    "high": 169.13,
    "low": 167.26,
    "close": 168.65,
    "volume": 11630539
  },
  {
    "time": "16-01-2018",
    "open": 165.1,
    "high": 165.29,
    "low": 163.35,
    "close": 163.85,
    "volume": 7588202
  },
  {
    "time": "12-01-2018",
    "open": 164.02,
    "high": 164.74,
    "low": 163.03,
    "close": 163.14,
    "volume": 5007254
  },
  {
    "time": "11-01-2018",
    "open": 164.8,
    "high": 164.94,
    "low": 163.3,
    "close": 164.2,
    "volume": 3783614
  },
  {
    "time": "10-01-2018",
    "open": 162.91,
    "high": 164.33,
    "low": 162.5,
    "close": 164.18,
    "volume": 4150836
  },
  {
    "time": "09-01-2018",
    "open": 163.9,
    "high": 164.53,
    "low": 163.06,
    "close": 163.83,
    "volume": 4333418
  },
  {
    "time": "08-01-2018",
    "open": 162.66,
    "high": 163.91,
    "low": 161.7,
    "close": 163.47,
    "volume": 5101023
  },
  {
    "time": "05-01-2018",
    "open": 162.44,
    "high": 162.9,
    "low": 161.1,
    "close": 162.49,
    "volume": 5162075
  },
  {
    "time": "04-01-2018",
    "open": 159.65,
    "high": 162.32,
    "low": 159.37,
    "close": 161.7,
    "volume": 7363843
  },
  {
    "time": "03-01-2018",
    "open": 157.34,
    "high": 159.81,
    "low": 156.33,
    "close": 158.49,
    "volume": 9439063
  },
  {
    "time": "02-01-2018",
    "open": 154.5,
    "high": 154.81,
    "low": 153.54,
    "close": 154.25,
    "volume": 4195225
  },
  {
    "time": "29-12-2017",
    "open": 154.17,
    "high": 154.72,
    "low": 153.42,
    "close": 153.42,
    "volume": 3310894
  },
  {
    "time": "28-12-2017",
    "open": 153.2,
    "high": 154.12,
    "low": 153.2,
    "close": 154.04,
    "volume": 2669076
  },
  {
    "time": "27-12-2017",
    "open": 152.95,
    "high": 153.18,
    "low": 152.61,
    "close": 153.13,
    "volume": 2116522
  },
  {
    "time": "26-12-2017",
    "open": 152.51,
    "high": 153.86,
    "low": 152.5,
    "close": 152.83,
    "volume": 2477240
  },
  {
    "time": "22-12-2017",
    "open": 151.82,
    "high": 153,
    "low": 151.5,
    "close": 152.5,
    "volume": 2982114
  },
  {
    "time": "21-12-2017",
    "open": 153.17,
    "high": 153.46,
    "low": 151.49,
    "close": 151.5,
    "volume": 4126632
  },
  {
    "time": "20-12-2017",
    "open": 153.65,
    "high": 153.89,
    "low": 152.78,
    "close": 152.95,
    "volume": 3771425
  },
  {
    "time": "19-12-2017",
    "open": 154.135,
    "high": 154.17,
    "low": 153.09,
    "close": 153.23,
    "volume": 4111495
  },
  {
    "time": "18-12-2017",
    "open": 153.41,
    "high": 154.18,
    "low": 153.21,
    "close": 153.33,
    "volume": 5054265
  },
  {
    "time": "15-12-2017",
    "open": 153.75,
    "high": 153.8,
    "low": 152.03,
    "close": 152.5,
    "volume": 10469312
  },
  {
    "time": "14-12-2017",
    "open": 154.5,
    "high": 155.11,
    "low": 153.7,
    "close": 154,
    "volume": 4623845
  },
  {
    "time": "13-12-2017",
    "open": 156.6,
    "high": 156.73,
    "low": 153.89,
    "close": 153.91,
    "volume": 5624428
  },
  {
    "time": "12-12-2017",
    "open": 155.2,
    "high": 157.85,
    "low": 155.16,
    "close": 156.74,
    "volume": 6314835
  },
  {
    "time": "11-12-2017",
    "open": 154.97,
    "high": 155.89,
    "low": 154.57,
    "close": 155.41,
    "volume": 4068982
  },
  {
    "time": "08-12-2017",
    "open": 153.94,
    "high": 155.03,
    "low": 153.55,
    "close": 154.81,
    "volume": 3440847
  },
  {
    "time": "07-12-2017",
    "open": 154.03,
    "high": 154.45,
    "low": 153.26,
    "close": 153.57,
    "volume": 3768443
  },
  {
    "time": "06-12-2017",
    "open": 155.69,
    "high": 156.22,
    "low": 154.09,
    "close": 154.1,
    "volume": 3345712
  },
  {
    "time": "05-12-2017",
    "open": 156.45,
    "high": 156.74,
    "low": 154.68,
    "close": 155.35,
    "volume": 5042724
  },
  {
    "time": "04-12-2017",
    "open": 155.67,
    "high": 156.8,
    "low": 155.07,
    "close": 156.46,
    "volume": 4639109
  },
  {
    "time": "01-12-2017",
    "open": 154.4,
    "high": 155.02,
    "low": 152.91,
    "close": 154.76,
    "volume": 5567852
  },
  {
    "time": "30-11-2017",
    "open": 153.65,
    "high": 154.41,
    "low": 153.08,
    "close": 153.97,
    "volume": 6500769
  },
  {
    "time": "29-11-2017",
    "open": 152.81,
    "high": 153.61,
    "low": 152.17,
    "close": 153.55,
    "volume": 5098702
  },
  {
    "time": "28-11-2017",
    "open": 152.35,
    "high": 152.93,
    "low": 151.7,
    "close": 152.47,
    "volume": 3915618
  },
  {
    "time": "27-11-2017",
    "open": 152.06,
    "high": 152.49,
    "low": 151.62,
    "close": 151.98,
    "volume": 3303663
  },
  {
    "time": "24-11-2017",
    "open": 151.95,
    "high": 152.2,
    "low": 151.33,
    "close": 151.84,
    "volume": 1193025
  },
  {
    "time": "22-11-2017",
    "open": 152,
    "high": 152.39,
    "low": 151.33,
    "close": 151.77,
    "volume": 3125416
  },
  {
    "time": "21-11-2017",
    "open": 151.3,
    "high": 152.45,
    "low": 151.2,
    "close": 151.95,
    "volume": 3756184
  },
  {
    "time": "20-11-2017",
    "open": 150.64,
    "high": 151.95,
    "low": 149.9,
    "close": 150.51,
    "volume": 5654091
  },
  {
    "time": "17-11-2017",
    "open": 149.34,
    "high": 150.15,
    "low": 148.83,
    "close": 148.97,
    "volume": 4865373
  },
  {
    "time": "16-11-2017",
    "open": 147.73,
    "high": 149.65,
    "low": 147.5,
    "close": 149.12,
    "volume": 5393609
  },
  {
    "time": "15-11-2017",
    "open": 148,
    "high": 148.705,
    "low": 146.211,
    "close": 147.1,
    "volume": 4695745
  },
  {
    "time": "14-11-2017",
    "open": 147.95,
    "high": 148.97,
    "low": 147.49,
    "close": 148.89,
    "volume": 3691934
  },
  {
    "time": "13-11-2017",
    "open": 148.88,
    "high": 149,
    "low": 147.92,
    "close": 148.4,
    "volume": 5085257
  },
  {
    "time": "10-11-2017",
    "open": 150.65,
    "high": 150.89,
    "low": 149.14,
    "close": 149.16,
    "volume": 4304369
  },
  {
    "time": "09-11-2017",
    "open": 149.93,
    "high": 151.8,
    "low": 149.86,
    "close": 150.3,
    "volume": 4770841
  },
  {
    "time": "08-11-2017",
    "open": 151.6,
    "high": 151.7944,
    "low": 150.28,
    "close": 151.57,
    "volume": 4630031
  },
  {
    "time": "07-11-2017",
    "open": 151.37,
    "high": 151.51,
    "low": 150.5,
    "close": 151.35,
    "volume": 3688378
  },
  {
    "time": "06-11-2017",
    "open": 151.77,
    "high": 151.82,
    "low": 150.28,
    "close": 150.84,
    "volume": 4498372
  },
  {
    "time": "03-11-2017",
    "open": 153.42,
    "high": 153.47,
    "low": 151.49,
    "close": 151.58,
    "volume": 4420582
  },
  {
    "time": "02-11-2017",
    "open": 154.18,
    "high": 154.49,
    "low": 152.91,
    "close": 153.35,
    "volume": 3388284
  },
  {
    "time": "01-11-2017",
    "open": 154.1,
    "high": 154.9,
    "low": 153.83,
    "close": 154.03,
    "volume": 3228302
  },
  {
    "time": "31-10-2017",
    "open": 154.33,
    "high": 154.93,
    "low": 152.93,
    "close": 154.06,
    "volume": 4224346
  },
  {
    "time": "30-10-2017",
    "open": 153.76,
    "high": 154.59,
    "low": 152.71,
    "close": 154.36,
    "volume": 4206189
  },
  {
    "time": "27-10-2017",
    "open": 154.54,
    "high": 154.63,
    "low": 152.911,
    "close": 153.68,
    "volume": 4893779
  },
  {
    "time": "26-10-2017",
    "open": 154.31,
    "high": 155.12,
    "low": 153.54,
    "close": 153.6,
    "volume": 4253664
  },
  {
    "time": "25-10-2017",
    "open": 156.01,
    "high": 156.37,
    "low": 153.06,
    "close": 153.495,
    "volume": 5443794
  },
  {
    "time": "24-10-2017",
    "open": 159.65,
    "high": 159.7,
    "low": 155.171,
    "close": 155.88,
    "volume": 8173017
  },
  {
    "time": "23-10-2017",
    "open": 162.05,
    "high": 162.51,
    "low": 159.54,
    "close": 159.55,
    "volume": 5765384
  },
  {
    "time": "20-10-2017",
    "open": 161.07,
    "high": 162.48,
    "low": 159.77,
    "close": 162.07,
    "volume": 7848320
  },
  {
    "time": "19-10-2017",
    "open": 159.8,
    "high": 160.96,
    "low": 159.085,
    "close": 160.9,
    "volume": 9878844
  },
  {
    "time": "18-10-2017",
    "open": 157.12,
    "high": 161.23,
    "low": 156.95,
    "close": 159.53,
    "volume": 30445283
  },
  {
    "time": "17-10-2017",
    "open": 146.63,
    "high": 147.12,
    "low": 146.18,
    "close": 146.54,
    "volume": 4941313
  },
  {
    "time": "16-10-2017",
    "open": 147.22,
    "high": 147.67,
    "low": 146.51,
    "close": 146.83,
    "volume": 3035184
  },
  {
    "time": "13-10-2017",
    "open": 147.48,
    "high": 147.85,
    "low": 146.94,
    "close": 147.1,
    "volume": 2490548
  },
  {
    "time": "12-10-2017",
    "open": 147.56,
    "high": 147.89,
    "low": 146.77,
    "close": 147.03,
    "volume": 3255559
  },
  {
    "time": "11-10-2017",
    "open": 148.4,
    "high": 148.47,
    "low": 147.28,
    "close": 147.62,
    "volume": 3674765
  },
  {
    "time": "10-10-2017",
    "open": 147.71,
    "high": 148.95,
    "low": 147.65,
    "close": 148.5,
    "volume": 4028877
  },
  {
    "time": "09-10-2017",
    "open": 146.5,
    "high": 147.79,
    "low": 146.35,
    "close": 147.39,
    "volume": 2678887
  },
  {
    "time": "06-10-2017",
    "open": 146.64,
    "high": 146.85,
    "low": 146.32,
    "close": 146.48,
    "volume": 2248474
  },
  {
    "time": "05-10-2017",
    "open": 146.68,
    "high": 147.54,
    "low": 146.48,
    "close": 146.72,
    "volume": 2536971
  },
  {
    "time": "04-10-2017",
    "open": 147,
    "high": 147.02,
    "low": 146.11,
    "close": 146.48,
    "volume": 2225673
  },
  {
    "time": "03-10-2017",
    "open": 146.69,
    "high": 147.1999,
    "low": 146.34,
    "close": 146.78,
    "volume": 2285725
  },
  {
    "time": "02-10-2017",
    "open": 145.35,
    "high": 146.865,
    "low": 145.21,
    "close": 146.66,
    "volume": 2955450
  },
  {
    "time": "29-09-2017",
    "open": 145.45,
    "high": 145.69,
    "low": 144.92,
    "close": 145.08,
    "volume": 2635887
  },
  {
    "time": "28-09-2017",
    "open": 145.33,
    "high": 145.86,
    "low": 144.21,
    "close": 145.66,
    "volume": 2763219
  },
  {
    "time": "27-09-2017",
    "open": 146.68,
    "high": 146.9,
    "low": 145.03,
    "close": 145.66,
    "volume": 3446073
  },
  {
    "time": "26-09-2017",
    "open": 146.36,
    "high": 147.42,
    "low": 145.94,
    "close": 146.56,
    "volume": 4106513
  },
  {
    "time": "25-09-2017",
    "open": 145.12,
    "high": 146.03,
    "low": 144.91,
    "close": 145.87,
    "volume": 5179476
  },
  {
    "time": "22-09-2017",
    "open": 145.59,
    "high": 145.59,
    "low": 144.59,
    "close": 145.13,
    "volume": 2741663
  },
  {
    "time": "21-09-2017",
    "open": 145.65,
    "high": 145.8911,
    "low": 145.1,
    "close": 145.26,
    "volume": 2289276
  },
  {
    "time": "20-09-2017",
    "open": 144.93,
    "high": 145.88,
    "low": 144.42,
    "close": 145.88,
    "volume": 5384615
  },
  {
    "time": "19-09-2017",
    "open": 144.79,
    "high": 144.98,
    "low": 144.22,
    "close": 144.39,
    "volume": 3456574
  },
  {
    "time": "18-09-2017",
    "open": 144.81,
    "high": 145.4799,
    "low": 144.27,
    "close": 144.55,
    "volume": 3018012
  },
  {
    "time": "15-09-2017",
    "open": 144.82,
    "high": 145.17,
    "low": 144.08,
    "close": 144.82,
    "volume": 7297773
  },
  {
    "time": "14-09-2017",
    "open": 145.86,
    "high": 146.38,
    "low": 145.51,
    "close": 145.54,
    "volume": 3742944
  },
  {
    "time": "13-09-2017",
    "open": 145.35,
    "high": 146.29,
    "low": 145.34,
    "close": 145.99,
    "volume": 4070378
  },
  {
    "time": "12-09-2017",
    "open": 145.11,
    "high": 146.37,
    "low": 144.51,
    "close": 145.76,
    "volume": 5222522
  },
  {
    "time": "11-09-2017",
    "open": 143.54,
    "high": 145.13,
    "low": 143.01,
    "close": 144.86,
    "volume": 4549334
  },
  {
    "time": "08-09-2017",
    "open": 143.15,
    "high": 143.15,
    "low": 141.64,
    "close": 142.45,
    "volume": 3344701
  },
  {
    "time": "07-09-2017",
    "open": 144.1,
    "high": 144.64,
    "low": 142.41,
    "close": 142.9,
    "volume": 4155968
  },
  {
    "time": "06-09-2017",
    "open": 143.88,
    "high": 144.47,
    "low": 143.5,
    "close": 143.82,
    "volume": 3205295
  },
  {
    "time": "05-09-2017",
    "open": 143.5,
    "high": 144.3575,
    "low": 142.7,
    "close": 143.04,
    "volume": 3097503
  },
  {
    "time": "01-09-2017",
    "open": 142.98,
    "high": 144.5,
    "low": 142.98,
    "close": 144.08,
    "volume": 3348503
  },
  {
    "time": "31-08-2017",
    "open": 143.02,
    "high": 143.66,
    "low": 142.63,
    "close": 143.03,
    "volume": 3614517
  },
  {
    "time": "30-08-2017",
    "open": 143.26,
    "high": 143.28,
    "low": 142.41,
    "close": 142.56,
    "volume": 2715466
  },
  {
    "time": "29-08-2017",
    "open": 142.1,
    "high": 143.425,
    "low": 141.9,
    "close": 143.14,
    "volume": 3000993
  },
  {
    "time": "28-08-2017",
    "open": 144.11,
    "high": 144.56,
    "low": 142.39,
    "close": 142.51,
    "volume": 5086015
  },
  {
    "time": "25-08-2017",
    "open": 143.3,
    "high": 144.19,
    "low": 143.25,
    "close": 143.74,
    "volume": 2866131
  },
  {
    "time": "24-08-2017",
    "open": 142.5,
    "high": 143.68,
    "low": 142.25,
    "close": 142.94,
    "volume": 3580245
  },
  {
    "time": "23-08-2017",
    "open": 140.7,
    "high": 143.05,
    "low": 140.68,
    "close": 142.14,
    "volume": 4035421
  },
  {
    "time": "22-08-2017",
    "open": 140.64,
    "high": 141.15,
    "low": 140.38,
    "close": 141.01,
    "volume": 2495902
  },
  {
    "time": "21-08-2017",
    "open": 139.59,
    "high": 140.42,
    "low": 139.13,
    "close": 140.33,
    "volume": 2949410
  },
  {
    "time": "18-08-2017",
    "open": 140.87,
    "high": 141.15,
    "low": 139.58,
    "close": 139.7,
    "volume": 4128761
  },
  {
    "time": "17-08-2017",
    "open": 142.52,
    "high": 142.89,
    "low": 140.7,
    "close": 140.7,
    "volume": 4342286
  },
  {
    "time": "16-08-2017",
    "open": 141.79,
    "high": 142.91,
    "low": 141.61,
    "close": 142.5,
    "volume": 3239516
  },
  {
    "time": "15-08-2017",
    "open": 142.55,
    "high": 143.34,
    "low": 140.58,
    "close": 142.07,
    "volume": 4652618
  },
  {
    "time": "14-08-2017",
    "open": 142,
    "high": 142.76,
    "low": 141.94,
    "close": 142.32,
    "volume": 2222304
  },
  {
    "time": "11-08-2017",
    "open": 142.45,
    "high": 142.59,
    "low": 141.52,
    "close": 141.84,
    "volume": 3004040
  },
  {
    "time": "10-08-2017",
    "open": 141.64,
    "high": 142.42,
    "low": 141.23,
    "close": 141.84,
    "volume": 4378186
  },
  {
    "time": "09-08-2017",
    "open": 141.75,
    "high": 142.03,
    "low": 141.19,
    "close": 141.77,
    "volume": 2920582
  },
  {
    "time": "08-08-2017",
    "open": 142,
    "high": 142.76,
    "low": 142,
    "close": 142.11,
    "volume": 3281972
  },
  {
    "time": "07-08-2017",
    "open": 145,
    "high": 145.09,
    "low": 142.75,
    "close": 143.47,
    "volume": 4576508
  },
  {
    "time": "04-08-2017",
    "open": 145,
    "high": 145.39,
    "low": 144.4,
    "close": 145.16,
    "volume": 2542800
  },
  {
    "time": "03-08-2017",
    "open": 144.43,
    "high": 145.34,
    "low": 144.43,
    "close": 144.94,
    "volume": 3028769
  },
  {
    "time": "02-08-2017",
    "open": 145.12,
    "high": 145.29,
    "low": 144.22,
    "close": 144.45,
    "volume": 3400024
  },
  {
    "time": "01-08-2017",
    "open": 145,
    "high": 145.67,
    "low": 144.72,
    "close": 145.3,
    "volume": 3197848
  },
  {
    "time": "31-07-2017",
    "open": 144.38,
    "high": 144.93,
    "low": 144.17,
    "close": 144.67,
    "volume": 4333680
  },
  {
    "time": "28-07-2017",
    "open": 144.81,
    "high": 145.04,
    "low": 143.84,
    "close": 144.29,
    "volume": 3022662
  },
  {
    "time": "27-07-2017",
    "open": 145,
    "high": 145.395,
    "low": 143.64,
    "close": 145.07,
    "volume": 6374650
  },
  {
    "time": "26-07-2017",
    "open": 146.27,
    "high": 146.46,
    "low": 144.47,
    "close": 145.36,
    "volume": 3806651
  },
  {
    "time": "25-07-2017",
    "open": 146.57,
    "high": 147.49,
    "low": 146.03,
    "close": 146.19,
    "volume": 4184435
  },
  {
    "time": "24-07-2017",
    "open": 147,
    "high": 147.04,
    "low": 145.8,
    "close": 145.99,
    "volume": 3818232
  },
  {
    "time": "21-07-2017",
    "open": 147.59,
    "high": 147.87,
    "low": 146.51,
    "close": 147.08,
    "volume": 6456514
  },
  {
    "time": "20-07-2017",
    "open": 147.53,
    "high": 148.83,
    "low": 147.03,
    "close": 147.66,
    "volume": 7105481
  },
  {
    "time": "19-07-2017",
    "open": 150.02,
    "high": 150.25,
    "low": 146.71,
    "close": 147.53,
    "volume": 14043000
  },
  {
    "time": "18-07-2017",
    "open": 152.36,
    "high": 154.29,
    "low": 152,
    "close": 154,
    "volume": 6867302
  },
  {
    "time": "17-07-2017",
    "open": 153.59,
    "high": 153.88,
    "low": 152.2399,
    "close": 153.01,
    "volume": 4751141
  },
  {
    "time": "14-07-2017",
    "open": 154.01,
    "high": 154.615,
    "low": 153.4,
    "close": 154.24,
    "volume": 3214337
  },
  {
    "time": "13-07-2017",
    "open": 153.7,
    "high": 154.19,
    "low": 153.19,
    "close": 153.63,
    "volume": 2474847
  },
  {
    "time": "12-07-2017",
    "open": 153.48,
    "high": 154.24,
    "low": 153.05,
    "close": 153.7,
    "volume": 3090315
  },
  {
    "time": "11-07-2017",
    "open": 153.26,
    "high": 153.65,
    "low": 152.05,
    "close": 153.19,
    "volume": 3431418
  },
  {
    "time": "10-07-2017",
    "open": 152.91,
    "high": 153.89,
    "low": 152.63,
    "close": 153.42,
    "volume": 2804780
  },
  {
    "time": "07-07-2017",
    "open": 152.62,
    "high": 153.49,
    "low": 152.14,
    "close": 152.94,
    "volume": 2345721
  },
  {
    "time": "06-07-2017",
    "open": 153.36,
    "high": 153.83,
    "low": 152.23,
    "close": 152.36,
    "volume": 2735717
  },
  {
    "time": "05-07-2017",
    "open": 155.77,
    "high": 155.89,
    "low": 153.63,
    "close": 153.67,
    "volume": 3558639
  },
  {
    "time": "03-07-2017",
    "open": 153.58,
    "high": 156.025,
    "low": 153.52,
    "close": 155.58,
    "volume": 2822499
  },
  {
    "time": "30-06-2017",
    "open": 154.28,
    "high": 154.5,
    "low": 153.14,
    "close": 153.83,
    "volume": 3501395
  },
  {
    "time": "29-06-2017",
    "open": 155.35,
    "high": 155.74,
    "low": 153.62,
    "close": 154.13,
    "volume": 3245649
  },
  {
    "time": "28-06-2017",
    "open": 155.15,
    "high": 155.55,
    "low": 154.78,
    "close": 155.32,
    "volume": 2203062
  },
  {
    "time": "27-06-2017",
    "open": 155,
    "high": 155.34,
    "low": 154.5,
    "close": 154.75,
    "volume": 2385619
  },
  {
    "time": "26-06-2017",
    "open": 154.71,
    "high": 155.75,
    "low": 154.63,
    "close": 155.23,
    "volume": 2129945
  },
  {
    "time": "23-06-2017",
    "open": 154.34,
    "high": 154.68,
    "low": 153.8,
    "close": 154.11,
    "volume": 3148468
  },
  {
    "time": "22-06-2017",
    "open": 154.55,
    "high": 154.99,
    "low": 153.69,
    "close": 154.4,
    "volume": 2361275
  },
  {
    "time": "21-06-2017",
    "open": 155.79,
    "high": 155.79,
    "low": 153.39,
    "close": 153.79,
    "volume": 3934281
  },
  {
    "time": "20-06-2017",
    "open": 154.48,
    "high": 155.389,
    "low": 154.25,
    "close": 154.95,
    "volume": 4469538
  },
  {
    "time": "19-06-2017",
    "open": 155.51,
    "high": 155.86,
    "low": 154.54,
    "close": 154.84,
    "volume": 4381436
  },
  {
    "time": "16-06-2017",
    "open": 154.23,
    "high": 155.42,
    "low": 152.9676,
    "close": 155.38,
    "volume": 6128190
  },
  {
    "time": "15-06-2017",
    "open": 153.29,
    "high": 154.69,
    "low": 153.29,
    "close": 154.22,
    "volume": 4626272
  },
  {
    "time": "14-06-2017",
    "open": 153.97,
    "high": 154.9399,
    "low": 152.94,
    "close": 153.81,
    "volume": 3035864
  },
  {
    "time": "13-06-2017",
    "open": 155.44,
    "high": 155.48,
    "low": 154.15,
    "close": 154.25,
    "volume": 3509558
  },
  {
    "time": "12-06-2017",
    "open": 154.19,
    "high": 157.2,
    "low": 154.02,
    "close": 155.18,
    "volume": 6461725
  },
  {
    "time": "09-06-2017",
    "open": 152,
    "high": 154.265,
    "low": 151.88,
    "close": 154.1,
    "volume": 4355401
  },
  {
    "time": "08-06-2017",
    "open": 151,
    "high": 152.82,
    "low": 150.92,
    "close": 152.1,
    "volume": 3681319
  },
  {
    "time": "07-06-2017",
    "open": 153.05,
    "high": 154.2,
    "low": 150.8,
    "close": 150.98,
    "volume": 4858631
  },
  {
    "time": "06-06-2017",
    "open": 152,
    "high": 152.89,
    "low": 152,
    "close": 152.37,
    "volume": 3786593
  },
  {
    "time": "05-06-2017",
    "open": 151.82,
    "high": 152.93,
    "low": 151.6826,
    "close": 152.41,
    "volume": 3971157
  },
  {
    "time": "02-06-2017",
    "open": 153.07,
    "high": 153.2,
    "low": 151.8,
    "close": 152.05,
    "volume": 3573193
  },
  {
    "time": "01-06-2017",
    "open": 152.8,
    "high": 152.87,
    "low": 151.63,
    "close": 152.67,
    "volume": 2904189
  },
  {
    "time": "31-05-2017",
    "open": 152.03,
    "high": 152.8,
    "low": 151.65,
    "close": 152.63,
    "volume": 3496466
  },
  {
    "time": "30-05-2017",
    "open": 151.95,
    "high": 152.67,
    "low": 151.59,
    "close": 151.73,
    "volume": 3609710
  },
  {
    "time": "26-05-2017",
    "open": 152.85,
    "high": 153,
    "low": 152.06,
    "close": 152.49,
    "volume": 2440117
  },
  {
    "time": "25-05-2017",
    "open": 153.25,
    "high": 153.73,
    "low": 152.9459,
    "close": 153.2,
    "volume": 2579828
  },
  {
    "time": "24-05-2017",
    "open": 152.21,
    "high": 152.76,
    "low": 151.23,
    "close": 152.51,
    "volume": 3731333
  },
  {
    "time": "23-05-2017",
    "open": 152.57,
    "high": 153.68,
    "low": 151.92,
    "close": 152.03,
    "volume": 2563975
  },
  {
    "time": "22-05-2017",
    "open": 152.1,
    "high": 152.84,
    "low": 151.5992,
    "close": 152.64,
    "volume": 3248372
  },
  {
    "time": "19-05-2017",
    "open": 151.01,
    "high": 152.46,
    "low": 150.89,
    "close": 151.98,
    "volume": 5617824
  },
  {
    "time": "18-05-2017",
    "open": 150.86,
    "high": 151.33,
    "low": 150.11,
    "close": 150.78,
    "volume": 3268772
  },
  {
    "time": "17-05-2017",
    "open": 153.3,
    "high": 154.14,
    "low": 150.89,
    "close": 150.93,
    "volume": 5048937
  },
  {
    "time": "16-05-2017",
    "open": 151.66,
    "high": 153.99,
    "low": 151.66,
    "close": 153.68,
    "volume": 5452539
  },
  {
    "time": "15-05-2017",
    "open": 150.62,
    "high": 151.83,
    "low": 150.4502,
    "close": 151.51,
    "volume": 4218359
  },
  {
    "time": "12-05-2017",
    "open": 150.3,
    "high": 151.265,
    "low": 150,
    "close": 150.37,
    "volume": 3651964
  },
  {
    "time": "11-05-2017",
    "open": 151.05,
    "high": 151.15,
    "low": 149.79,
    "close": 150.65,
    "volume": 5546379
  },
  {
    "time": "10-05-2017",
    "open": 151.651,
    "high": 152.37,
    "low": 151.13,
    "close": 151.25,
    "volume": 4995590
  },
  {
    "time": "09-05-2017",
    "open": 152.6,
    "high": 153.1,
    "low": 151.5552,
    "close": 152.11,
    "volume": 6823015
  },
  {
    "time": "08-05-2017",
    "open": 152.8,
    "high": 153.47,
    "low": 152.2,
    "close": 153.03,
    "volume": 7415765
  },
  {
    "time": "05-05-2017",
    "open": 153.52,
    "high": 155.78,
    "low": 153,
    "close": 155.05,
    "volume": 12415240
  },
  {
    "time": "04-05-2017",
    "open": 158.89,
    "high": 159.14,
    "low": 158.36,
    "close": 159.05,
    "volume": 4271669
  },
  {
    "time": "03-05-2017",
    "open": 158.74,
    "high": 159.45,
    "low": 158.52,
    "close": 158.63,
    "volume": 3990429
  },
  {
    "time": "02-05-2017",
    "open": 159.44,
    "high": 159.49,
    "low": 158.64,
    "close": 159.1,
    "volume": 3149949
  },
  {
    "time": "01-05-2017",
    "open": 160.05,
    "high": 160.42,
    "low": 158.7,
    "close": 158.84,
    "volume": 4919710
  },
  {
    "time": "28-04-2017",
    "open": 160.5,
    "high": 160.59,
    "low": 159.7,
    "close": 160.29,
    "volume": 4029533
  },
  {
    "time": "27-04-2017",
    "open": 160.29,
    "high": 160.48,
    "low": 159.63,
    "close": 160.32,
    "volume": 4081640
  },
  {
    "time": "26-04-2017",
    "open": 160.53,
    "high": 161.1,
    "low": 159.89,
    "close": 160.06,
    "volume": 4311484
  },
  {
    "time": "25-04-2017",
    "open": 161.78,
    "high": 162.04,
    "low": 160.38,
    "close": 160.39,
    "volume": 4842189
  },
  {
    "time": "24-04-2017",
    "open": 161.29,
    "high": 161.57,
    "low": 160.42,
    "close": 160.75,
    "volume": 5320608
  },
  {
    "time": "21-04-2017",
    "open": 162.05,
    "high": 162.11,
    "low": 160.38,
    "close": 160.38,
    "volume": 5607756
  },
  {
    "time": "20-04-2017",
    "open": 161.32,
    "high": 162.4,
    "low": 160.5,
    "close": 162.3,
    "volume": 6486809
  },
  {
    "time": "19-04-2017",
    "open": 161.76,
    "high": 162.64,
    "low": 159.6,
    "close": 161.69,
    "volume": 192843
  },
  {
    "time": "18-04-2017",
    "open": 170.79,
    "high": 171.69,
    "low": 169.83,
    "close": 170.05,
    "volume": 6345465
  },
  {
    "time": "17-04-2017",
    "open": 169.75,
    "high": 171.3,
    "low": 169.62,
    "close": 171.1,
    "volume": 3720218
  },
  {
    "time": "13-04-2017",
    "open": 169.92,
    "high": 171.36,
    "low": 169.53,
    "close": 169.53,
    "volume": 3205462
  },
  {
    "time": "12-04-2017",
    "open": 171.04,
    "high": 171.2,
    "low": 170.02,
    "close": 170.66,
    "volume": 3276931
  },
  {
    "time": "11-04-2017",
    "open": 170.65,
    "high": 171.23,
    "low": 168.98,
    "close": 170.58,
    "volume": 4890163
  },
  {
    "time": "10-04-2017",
    "open": 172.53,
    "high": 172.56,
    "low": 171,
    "close": 171.2,
    "volume": 3789936
  },
  {
    "time": "07-04-2017",
    "open": 172.08,
    "high": 172.93,
    "low": 171.28,
    "close": 172.14,
    "volume": 3588302
  },
  {
    "time": "06-04-2017",
    "open": 173.47,
    "high": 173.47,
    "low": 172.25,
    "close": 172.45,
    "volume": 3435403
  },
  {
    "time": "05-04-2017",
    "open": 174.7,
    "high": 176.33,
    "low": 172.81,
    "close": 172.88,
    "volume": 6238894
  },
  {
    "time": "04-04-2017",
    "open": 173.52,
    "high": 174.96,
    "low": 173.26,
    "close": 174.52,
    "volume": 3108855
  },
  {
    "time": "03-04-2017",
    "open": 173.82,
    "high": 174.87,
    "low": 173.38,
    "close": 174.5,
    "volume": 4286498
  },
  {
    "time": "31-03-2017",
    "open": 173.98,
    "high": 174.95,
    "low": 173.69,
    "close": 174.14,
    "volume": 2913684
  },
  {
    "time": "30-03-2017",
    "open": 173.86,
    "high": 174.59,
    "low": 173.69,
    "close": 173.86,
    "volume": 3168254
  },
  {
    "time": "29-03-2017",
    "open": 174.3,
    "high": 174.49,
    "low": 173.46,
    "close": 173.94,
    "volume": 3085606
  },
  {
    "time": "28-03-2017",
    "open": 173.94,
    "high": 175,
    "low": 173,
    "close": 174.51,
    "volume": 3523345
  },
  {
    "time": "27-03-2017",
    "open": 172.69,
    "high": 174.16,
    "low": 172.0937,
    "close": 173.77,
    "volume": 3259049
  },
  {
    "time": "24-03-2017",
    "open": 175.12,
    "high": 175.5,
    "low": 173.39,
    "close": 173.83,
    "volume": 3221672
  },
  {
    "time": "23-03-2017",
    "open": 174.43,
    "high": 175.67,
    "low": 173.56,
    "close": 174.82,
    "volume": 3739976
  },
  {
    "time": "22-03-2017",
    "open": 174.04,
    "high": 175.06,
    "low": 172.8,
    "close": 174.78,
    "volume": 3450512
  },
  {
    "time": "21-03-2017",
    "open": 176.01,
    "high": 176.23,
    "low": 173.84,
    "close": 173.88,
    "volume": 3927741
  },
  {
    "time": "20-03-2017",
    "open": 175.65,
    "high": 176.18,
    "low": 175.14,
    "close": 175.7,
    "volume": 2476125
  },
  {
    "time": "17-03-2017",
    "open": 176.29,
    "high": 176.79,
    "low": 175.65,
    "close": 175.65,
    "volume": 5920967
  },
  {
    "time": "16-03-2017",
    "open": 178.46,
    "high": 179,
    "low": 176.82,
    "close": 177.24,
    "volume": 4311848
  },
  {
    "time": "15-03-2017",
    "open": 175.71,
    "high": 176.28,
    "low": 174.75,
    "close": 175.81,
    "volume": 3815537
  },
  {
    "time": "14-03-2017",
    "open": 176.18,
    "high": 176.82,
    "low": 175.211,
    "close": 175.72,
    "volume": 3147497
  },
  {
    "time": "13-03-2017",
    "open": 177.85,
    "high": 178.06,
    "low": 176.415,
    "close": 176.46,
    "volume": 3482189
  },
  {
    "time": "10-03-2017",
    "open": 178.21,
    "high": 179.49,
    "low": 177.42,
    "close": 177.83,
    "volume": 3100116
  },
  {
    "time": "09-03-2017",
    "open": 179.15,
    "high": 179.25,
    "low": 175.88,
    "close": 177.18,
    "volume": 5444076
  },
  {
    "time": "08-03-2017",
    "open": 180.75,
    "high": 180.95,
    "low": 179.3,
    "close": 179.45,
    "volume": 3557396
  },
  {
    "time": "07-03-2017",
    "open": 180.71,
    "high": 181.29,
    "low": 180.1997,
    "close": 180.38,
    "volume": 2977596
  },
  {
    "time": "06-03-2017",
    "open": 179.72,
    "high": 180.99,
    "low": 179.57,
    "close": 180.47,
    "volume": 3204434
  },
  {
    "time": "03-03-2017",
    "open": 180.04,
    "high": 181.32,
    "low": 179.7649,
    "close": 180.05,
    "volume": 1825048
  },
  {
    "time": "02-03-2017",
    "open": 181.88,
    "high": 181.88,
    "low": 180.43,
    "close": 180.53,
    "volume": 2918153
  },
  {
    "time": "01-03-2017",
    "open": 180.48,
    "high": 182.55,
    "low": 180.03,
    "close": 181.95,
    "volume": 3005564
  },
  {
    "time": "28-02-2017",
    "open": 179.38,
    "high": 180.63,
    "low": 179.35,
    "close": 179.82,
    "volume": 3273335
  },
  {
    "time": "27-02-2017",
    "open": 181.19,
    "high": 181.25,
    "low": 179.28,
    "close": 179.4,
    "volume": 3691209
  },
  {
    "time": "24-02-2017",
    "open": 180.21,
    "high": 181.49,
    "low": 179.89,
    "close": 181.35,
    "volume": 3299019
  },
  {
    "time": "23-02-2017",
    "open": 182,
    "high": 182.5,
    "low": 180.92,
    "close": 181.65,
    "volume": 2282526
  },
  {
    "time": "22-02-2017",
    "open": 180.09,
    "high": 181.34,
    "low": 180.09,
    "close": 181.15,
    "volume": 3020540
  },
  {
    "time": "21-02-2017",
    "open": 180.6,
    "high": 180.79,
    "low": 179.12,
    "close": 180.26,
    "volume": 4480199
  },
  {
    "time": "17-02-2017",
    "open": 181.25,
    "high": 181.57,
    "low": 180,
    "close": 180.67,
    "volume": 3552305
  },
  {
    "time": "16-02-2017",
    "open": 181.45,
    "high": 182.79,
    "low": 180.87,
    "close": 181.43,
    "volume": 3251547
  },
  {
    "time": "15-02-2017",
    "open": 180.2,
    "high": 181.92,
    "low": 179.81,
    "close": 181.68,
    "volume": 2914292
  },
  {
    "time": "14-02-2017",
    "open": 178.57,
    "high": 180.13,
    "low": 178.35,
    "close": 180.13,
    "volume": 2775818
  },
  {
    "time": "13-02-2017",
    "open": 179.24,
    "high": 179.9,
    "low": 178.84,
    "close": 179.36,
    "volume": 3093372
  },
  {
    "time": "10-02-2017",
    "open": 177.37,
    "high": 178.8701,
    "low": 176.76,
    "close": 178.68,
    "volume": 2930710
  },
  {
    "time": "09-02-2017",
    "open": 176.17,
    "high": 177.8,
    "low": 175.91,
    "close": 177.21,
    "volume": 3090710
  },
  {
    "time": "08-02-2017",
    "open": 177.5,
    "high": 177.5,
    "low": 175.88,
    "close": 176.17,
    "volume": 2913126
  },
  {
    "time": "07-02-2017",
    "open": 176,
    "high": 178.62,
    "low": 175.9,
    "close": 178.46,
    "volume": 3873233
  },
  {
    "time": "06-02-2017",
    "open": 175.31,
    "high": 175.98,
    "low": 174.38,
    "close": 175.86,
    "volume": 2650509
  },
  {
    "time": "03-02-2017",
    "open": 175,
    "high": 176.34,
    "low": 174.89,
    "close": 175.82,
    "volume": 3309179
  },
  {
    "time": "02-02-2017",
    "open": 174.23,
    "high": 174.97,
    "low": 173.55,
    "close": 174.58,
    "volume": 2620526
  },
  {
    "time": "01-02-2017",
    "open": 175,
    "high": 175.7025,
    "low": 172.89,
    "close": 174.29,
    "volume": 2795445
  },
  {
    "time": "31-01-2017",
    "open": 175.05,
    "high": 175.58,
    "low": 173.61,
    "close": 174.52,
    "volume": 4134049
  },
  {
    "time": "30-01-2017",
    "open": 176.98,
    "high": 177.07,
    "low": 174.5768,
    "close": 175.8,
    "volume": 4093555
  },
  {
    "time": "27-01-2017",
    "open": 178.47,
    "high": 179.2,
    "low": 177.3,
    "close": 177.3,
    "volume": 3482105
  },
  {
    "time": "26-01-2017",
    "open": 178.06,
    "high": 178.88,
    "low": 177.51,
    "close": 178.66,
    "volume": 3063911
  },
  {
    "time": "25-01-2017",
    "open": 176.26,
    "high": 179.25,
    "low": 176.13,
    "close": 178.29,
    "volume": 5922462
  },
  {
    "time": "24-01-2017",
    "open": 171.36,
    "high": 176,
    "low": 171.16,
    "close": 175.9,
    "volume": 6952369
  },
  {
    "time": "23-01-2017",
    "open": 170.08,
    "high": 171.25,
    "low": 170.01,
    "close": 171.03,
    "volume": 5477314
  },
  {
    "time": "20-01-2017",
    "open": 167.81,
    "high": 170.64,
    "low": 166,
    "close": 170.55,
    "volume": 12606847
  },
  {
    "time": "19-01-2017",
    "open": 166.96,
    "high": 167.45,
    "low": 165.8,
    "close": 166.81,
    "volume": 6963386
  },
  {
    "time": "18-01-2017",
    "open": 167.45,
    "high": 168.59,
    "low": 166.69,
    "close": 166.8,
    "volume": 4005626
  },
  {
    "time": "17-01-2017",
    "open": 166.69,
    "high": 168.18,
    "low": 166.1201,
    "close": 167.89,
    "volume": 3315655
  },
  {
    "time": "13-01-2017",
    "open": 167.97,
    "high": 168.48,
    "low": 166.875,
    "close": 167.34,
    "volume": 2875319
  },
  {
    "time": "12-01-2017",
    "open": 167.77,
    "high": 168.01,
    "low": 165.56,
    "close": 167.95,
    "volume": 2927953
  },
  {
    "time": "11-01-2017",
    "open": 166.05,
    "high": 167.76,
    "low": 165.6,
    "close": 167.75,
    "volume": 3599464
  },
  {
    "time": "10-01-2017",
    "open": 167.98,
    "high": 168.09,
    "low": 165.34,
    "close": 165.52,
    "volume": 4118694
  },
  {
    "time": "09-01-2017",
    "open": 169.47,
    "high": 169.8,
    "low": 167.62,
    "close": 167.65,
    "volume": 3189413
  },
  {
    "time": "06-01-2017",
    "open": 168.69,
    "high": 169.92,
    "low": 167.52,
    "close": 169.53,
    "volume": 2945536
  },
  {
    "time": "05-01-2017",
    "open": 169.25,
    "high": 169.39,
    "low": 167.26,
    "close": 168.7,
    "volume": 2682181
  },
  {
    "time": "04-01-2017",
    "open": 167.77,
    "high": 169.87,
    "low": 167.36,
    "close": 169.26,
    "volume": 3381232
  },
  {
    "time": "03-01-2017",
    "open": 167,
    "high": 167.87,
    "low": 166.01,
    "close": 167.19,
    "volume": 2934299
  },
  {
    "time": "30-12-2016",
    "open": 166.44,
    "high": 166.7,
    "low": 165.5,
    "close": 165.99,
    "volume": 2952200
  },
  {
    "time": "29-12-2016",
    "open": 166.02,
    "high": 166.99,
    "low": 166,
    "close": 166.6,
    "volume": 1663542
  },
  {
    "time": "28-12-2016",
    "open": 167.29,
    "high": 167.74,
    "low": 166,
    "close": 166.19,
    "volume": 1757500
  },
  {
    "time": "27-12-2016",
    "open": 166.98,
    "high": 167.98,
    "low": 166.85,
    "close": 167.14,
    "volume": 1397455
  },
  {
    "time": "23-12-2016",
    "open": 167,
    "high": 167.49,
    "low": 166.45,
    "close": 166.71,
    "volume": 1701228
  },
  {
    "time": "22-12-2016",
    "open": 167.36,
    "high": 168.23,
    "low": 166.58,
    "close": 167.06,
    "volume": 2802631
  },
  {
    "time": "21-12-2016",
    "open": 166.25,
    "high": 167.94,
    "low": 165.25,
    "close": 167.33,
    "volume": 3575210
  },
  {
    "time": "20-12-2016",
    "open": 167.49,
    "high": 168.25,
    "low": 166.45,
    "close": 167.6,
    "volume": 2174569
  },
  {
    "time": "19-12-2016",
    "open": 166.83,
    "high": 167.26,
    "low": 166,
    "close": 166.68,
    "volume": 2955864
  },
  {
    "time": "16-12-2016",
    "open": 168.97,
    "high": 169.11,
    "low": 166.06,
    "close": 166.73,
    "volume": 7117050
  },
  {
    "time": "15-12-2016",
    "open": 168.01,
    "high": 169.85,
    "low": 167.78,
    "close": 168.02,
    "volume": 3388570
  },
  {
    "time": "14-12-2016",
    "open": 168.37,
    "high": 169.89,
    "low": 167.45,
    "close": 168.51,
    "volume": 4123821
  },
  {
    "time": "13-12-2016",
    "open": 165.68,
    "high": 169.952,
    "low": 165.68,
    "close": 168.29,
    "volume": 5932271
  },
  {
    "time": "12-12-2016",
    "open": 166.72,
    "high": 166.79,
    "low": 165.07,
    "close": 165.5,
    "volume": 3392030
  },
  {
    "time": "09-12-2016",
    "open": 165.18,
    "high": 166.72,
    "low": 164.6042,
    "close": 166.52,
    "volume": 3146930
  },
  {
    "time": "08-12-2016",
    "open": 164.87,
    "high": 166,
    "low": 164.22,
    "close": 165.36,
    "volume": 3266336
  },
  {
    "time": "07-12-2016",
    "open": 160.6,
    "high": 165.18,
    "low": 160.39,
    "close": 164.79,
    "volume": 4435050
  },
  {
    "time": "06-12-2016",
    "open": 160.13,
    "high": 160.79,
    "low": 158.93,
    "close": 160.35,
    "volume": 2858962
  },
  {
    "time": "05-12-2016",
    "open": 160.85,
    "high": 161.15,
    "low": 159.5875,
    "close": 159.84,
    "volume": 3447052
  },
  {
    "time": "02-12-2016",
    "open": 159,
    "high": 160.29,
    "low": 158.41,
    "close": 160.02,
    "volume": 2740782
  },
  {
    "time": "01-12-2016",
    "open": 161.95,
    "high": 162.195,
    "low": 158.3,
    "close": 159.82,
    "volume": 4634740
  },
  {
    "time": "30-11-2016",
    "open": 163.35,
    "high": 163.8,
    "low": 162.21,
    "close": 162.22,
    "volume": 4397777
  },
  {
    "time": "29-11-2016",
    "open": 164,
    "high": 164.41,
    "low": 163.03,
    "close": 163.53,
    "volume": 3148196
  },
  {
    "time": "28-11-2016",
    "open": 163.2,
    "high": 164.66,
    "low": 162.7,
    "close": 164.52,
    "volume": 4449355
  },
  {
    "time": "25-11-2016",
    "open": 161.83,
    "high": 163.19,
    "low": 161.83,
    "close": 163.14,
    "volume": 1605344
  },
  {
    "time": "23-11-2016",
    "open": 161.94,
    "high": 162.38,
    "low": 161.3557,
    "close": 161.98,
    "volume": 2256035
  },
  {
    "time": "22-11-2016",
    "open": 163,
    "high": 163,
    "low": 161.95,
    "close": 162.67,
    "volume": 2770736
  },
  {
    "time": "21-11-2016",
    "open": 160.69,
    "high": 163,
    "low": 160.3697,
    "close": 162.77,
    "volume": 4637097
  },
  {
    "time": "18-11-2016",
    "open": 159.8,
    "high": 160.72,
    "low": 159.21,
    "close": 160.39,
    "volume": 2981633
  },
  {
    "time": "17-11-2016",
    "open": 159.22,
    "high": 159.93,
    "low": 158.85,
    "close": 159.8,
    "volume": 2260984
  },
  {
    "time": "16-11-2016",
    "open": 158.46,
    "high": 159.55,
    "low": 158.03,
    "close": 159.29,
    "volume": 2252806
  },
  {
    "time": "15-11-2016",
    "open": 158.42,
    "high": 159.15,
    "low": 157.55,
    "close": 158.67,
    "volume": 3476996
  },
  {
    "time": "14-11-2016",
    "open": 161.25,
    "high": 161.86,
    "low": 157.58,
    "close": 158.21,
    "volume": 5196498
  },
  {
    "time": "11-11-2016",
    "open": 159.97,
    "high": 161.34,
    "low": 159.33,
    "close": 161.27,
    "volume": 4446630
  },
  {
    "time": "10-11-2016",
    "open": 157.66,
    "high": 161.16,
    "low": 157.59,
    "close": 160.22,
    "volume": 7715807
  },
  {
    "time": "09-11-2016",
    "open": 152.96,
    "high": 155.56,
    "low": 151,
    "close": 154.81,
    "volume": 5403688
  },
  {
    "time": "08-11-2016",
    "open": 154.56,
    "high": 155.93,
    "low": 153.06,
    "close": 155.17,
    "volume": 3921044
  },
  {
    "time": "07-11-2016",
    "open": 153.99,
    "high": 156.11,
    "low": 153.84,
    "close": 155.72,
    "volume": 3804943
  },
  {
    "time": "04-11-2016",
    "open": 152.4,
    "high": 153.64,
    "low": 151.87,
    "close": 152.43,
    "volume": 2470353
  },
  {
    "time": "03-11-2016",
    "open": 152.51,
    "high": 153.74,
    "low": 151.8,
    "close": 152.37,
    "volume": 2878728
  },
  {
    "time": "02-11-2016",
    "open": 152.48,
    "high": 153.345,
    "low": 151.67,
    "close": 151.95,
    "volume": 3074338
  },
  {
    "time": "01-11-2016",
    "open": 153.5,
    "high": 153.91,
    "low": 151.74,
    "close": 152.79,
    "volume": 3191924
  },
  {
    "time": "31-10-2016",
    "open": 152.76,
    "high": 154.33,
    "low": 152.76,
    "close": 153.69,
    "volume": 3553172
  },
  {
    "time": "28-10-2016",
    "open": 154.05,
    "high": 154.44,
    "low": 152.18,
    "close": 152.61,
    "volume": 3654446
  },
  {
    "time": "27-10-2016",
    "open": 152.82,
    "high": 154.06,
    "low": 152.02,
    "close": 153.35,
    "volume": 4229336
  },
  {
    "time": "26-10-2016",
    "open": 150.71,
    "high": 152.94,
    "low": 150.255,
    "close": 151.81,
    "volume": 2811421
  },
  {
    "time": "25-10-2016",
    "open": 150.69,
    "high": 151.16,
    "low": 149.83,
    "close": 150.88,
    "volume": 2647416
  },
  {
    "time": "24-10-2016",
    "open": 150.4,
    "high": 151.52,
    "low": 150.4,
    "close": 150.57,
    "volume": 2666740
  },
  {
    "time": "21-10-2016",
    "open": 150.58,
    "high": 151.15,
    "low": 149.56,
    "close": 149.63,
    "volume": 4414171
  },
  {
    "time": "20-10-2016",
    "open": 151.28,
    "high": 152.9,
    "low": 151.02,
    "close": 151.52,
    "volume": 4023075
  },
  {
    "time": "19-10-2016",
    "open": 151.27,
    "high": 152.45,
    "low": 150.83,
    "close": 151.26,
    "volume": 4632854
  },
  {
    "time": "18-10-2016",
    "open": 150.02,
    "high": 151,
    "low": 147.79,
    "close": 150.72,
    "volume": 12770570
  },
  {
    "time": "17-10-2016",
    "open": 154.45,
    "high": 155.89,
    "low": 154.34,
    "close": 154.77,
    "volume": 5890421
  },
  {
    "time": "14-10-2016",
    "open": 154.47,
    "high": 155.53,
    "low": 154.09,
    "close": 154.45,
    "volume": 4358147
  },
  {
    "time": "13-10-2016",
    "open": 153.7,
    "high": 154.22,
    "low": 152.27,
    "close": 153.72,
    "volume": 2909948
  },
  {
    "time": "12-10-2016",
    "open": 154.97,
    "high": 154.97,
    "low": 153.08,
    "close": 154.29,
    "volume": 2963843
  },
  {
    "time": "11-10-2016",
    "open": 156.73,
    "high": 156.95,
    "low": 153.89,
    "close": 154.79,
    "volume": 2901226
  },
  {
    "time": "10-10-2016",
    "open": 156.71,
    "high": 158.49,
    "low": 156.655,
    "close": 157.02,
    "volume": 2481288
  },
  {
    "time": "07-10-2016",
    "open": 157.14,
    "high": 157.7,
    "low": 154.86,
    "close": 155.67,
    "volume": 2671256
  },
  {
    "time": "06-10-2016",
    "open": 156.84,
    "high": 157.43,
    "low": 155.89,
    "close": 156.88,
    "volume": 1950106
  },
  {
    "time": "05-10-2016",
    "open": 157.07,
    "high": 157.83,
    "low": 156.72,
    "close": 157.08,
    "volume": 1684458
  },
  {
    "time": "04-10-2016",
    "open": 157.67,
    "high": 158.53,
    "low": 155.82,
    "close": 156.46,
    "volume": 2884146
  },
  {
    "time": "03-10-2016",
    "open": 158.06,
    "high": 158.37,
    "low": 157.02,
    "close": 157.61,
    "volume": 2227800
  },
  {
    "time": "30-09-2016",
    "open": 158.9,
    "high": 159.838,
    "low": 158.06,
    "close": 158.85,
    "volume": 3596878
  },
  {
    "time": "29-09-2016",
    "open": 158.63,
    "high": 165,
    "low": 157.46,
    "close": 158.11,
    "volume": 3374838
  },
  {
    "time": "28-09-2016",
    "open": 156.99,
    "high": 158.625,
    "low": 156.23,
    "close": 158.29,
    "volume": 3309408
  },
  {
    "time": "27-09-2016",
    "open": 154.32,
    "high": 156.77,
    "low": 153.81,
    "close": 156.77,
    "volume": 2987195
  },
  {
    "time": "26-09-2016",
    "open": 154.46,
    "high": 154.46,
    "low": 153.46,
    "close": 153.98,
    "volume": 2533325
  },
  {
    "time": "23-09-2016",
    "open": 155.62,
    "high": 156.04,
    "low": 154.71,
    "close": 154.98,
    "volume": 2702143
  },
  {
    "time": "22-09-2016",
    "open": 156.15,
    "high": 157.22,
    "low": 155.69,
    "close": 156.11,
    "volume": 2690915
  },
  {
    "time": "21-09-2016",
    "open": 154.91,
    "high": 155.68,
    "low": 153.87,
    "close": 155.53,
    "volume": 2473895
  },
  {
    "time": "20-09-2016",
    "open": 155.87,
    "high": 156.57,
    "low": 154.45,
    "close": 154.45,
    "volume": 2159092
  },
  {
    "time": "19-09-2016",
    "open": 154.87,
    "high": 156.19,
    "low": 154.55,
    "close": 154.87,
    "volume": 2450117
  },
  {
    "time": "16-09-2016",
    "open": 155.04,
    "high": 155.4,
    "low": 153.47,
    "close": 153.84,
    "volume": 6463213
  },
  {
    "time": "15-09-2016",
    "open": 154.3,
    "high": 156.33,
    "low": 153.21,
    "close": 155.66,
    "volume": 3662573
  },
  {
    "time": "14-09-2016",
    "open": 155.7,
    "high": 155.87,
    "low": 153.39,
    "close": 154.05,
    "volume": 3860553
  },
  {
    "time": "13-09-2016",
    "open": 157.63,
    "high": 157.93,
    "low": 155.5,
    "close": 155.81,
    "volume": 3636719
  },
  {
    "time": "12-09-2016",
    "open": 155.26,
    "high": 158.53,
    "low": 154.84,
    "close": 158.29,
    "volume": 4367529
  },
  {
    "time": "09-09-2016",
    "open": 158.03,
    "high": 158.4,
    "low": 155.65,
    "close": 155.69,
    "volume": 5185972
  },
  {
    "time": "08-09-2016",
    "open": 160.55,
    "high": 161.21,
    "low": 158.76,
    "close": 159,
    "volume": 3963164
  },
  {
    "time": "07-09-2016",
    "open": 160.19,
    "high": 161.76,
    "low": 160,
    "close": 161.64,
    "volume": 2866227
  },
  {
    "time": "06-09-2016",
    "open": 159.88,
    "high": 160.86,
    "low": 159.11,
    "close": 160.35,
    "volume": 2994056
  },
  {
    "time": "02-09-2016",
    "open": 159.88,
    "high": 160.57,
    "low": 159.15,
    "close": 159.55,
    "volume": 2315366
  },
  {
    "time": "01-09-2016",
    "open": 158.32,
    "high": 159.62,
    "low": 158.1,
    "close": 159.54,
    "volume": 2358385
  },
  {
    "time": "31-08-2016",
    "open": 159.66,
    "high": 159.66,
    "low": 158.26,
    "close": 158.88,
    "volume": 2323649
  },
  {
    "time": "30-08-2016",
    "open": 159.76,
    "high": 160.15,
    "low": 158.81,
    "close": 159.4,
    "volume": 1813343
  },
  {
    "time": "29-08-2016",
    "open": 158.83,
    "high": 160.21,
    "low": 158.5,
    "close": 159.72,
    "volume": 2475853
  },
  {
    "time": "26-08-2016",
    "open": 158.88,
    "high": 160.44,
    "low": 157.85,
    "close": 158.32,
    "volume": 2498909
  },
  {
    "time": "25-08-2016",
    "open": 159,
    "high": 159.54,
    "low": 158.42,
    "close": 158.63,
    "volume": 2575023
  },
  {
    "time": "24-08-2016",
    "open": 159.89,
    "high": 160.18,
    "low": 158.32,
    "close": 159.05,
    "volume": 3620365
  },
  {
    "time": "23-08-2016",
    "open": 160.33,
    "high": 161.34,
    "low": 160.23,
    "close": 160.26,
    "volume": 2838137
  },
  {
    "time": "22-08-2016",
    "open": 160,
    "high": 160.57,
    "low": 159.13,
    "close": 160,
    "volume": 2039331
  },
  {
    "time": "19-08-2016",
    "open": 160.84,
    "high": 160.91,
    "low": 159.52,
    "close": 160.04,
    "volume": 2815163
  },
  {
    "time": "18-08-2016",
    "open": 160.89,
    "high": 162,
    "low": 160.4401,
    "close": 161.36,
    "volume": 3652013
  },
  {
    "time": "17-08-2016",
    "open": 160.97,
    "high": 161.16,
    "low": 159.84,
    "close": 160.44,
    "volume": 3225919
  },
  {
    "time": "16-08-2016",
    "open": 161.11,
    "high": 161.93,
    "low": 160.69,
    "close": 160.7,
    "volume": 2210229
  },
  {
    "time": "15-08-2016",
    "open": 162.4,
    "high": 162.97,
    "low": 161.78,
    "close": 161.88,
    "volume": 2970415
  },
  {
    "time": "12-08-2016",
    "open": 163.19,
    "high": 163.46,
    "low": 161.52,
    "close": 161.95,
    "volume": 2543079
  },
  {
    "time": "11-08-2016",
    "open": 162.25,
    "high": 164.95,
    "low": 162.18,
    "close": 163.53,
    "volume": 8768585
  },
  {
    "time": "10-08-2016",
    "open": 162.19,
    "high": 162.66,
    "low": 161.955,
    "close": 162.08,
    "volume": 2466675
  },
  {
    "time": "09-08-2016",
    "open": 162.22,
    "high": 162.772,
    "low": 161.645,
    "close": 161.77,
    "volume": 2737530
  },
  {
    "time": "08-08-2016",
    "open": 162.73,
    "high": 163.27,
    "low": 161.58,
    "close": 162.04,
    "volume": 3039081
  },
  {
    "time": "05-08-2016",
    "open": 162,
    "high": 163.51,
    "low": 161.57,
    "close": 163.5,
    "volume": 3812370
  },
  {
    "time": "04-08-2016",
    "open": 160.86,
    "high": 161.7,
    "low": 160.12,
    "close": 161.55,
    "volume": 2488878
  },
  {
    "time": "03-08-2016",
    "open": 160.13,
    "high": 160.86,
    "low": 159.8,
    "close": 160.67,
    "volume": 2861558
  },
  {
    "time": "02-08-2016",
    "open": 161.27,
    "high": 161.4499,
    "low": 160.36,
    "close": 160.58,
    "volume": 3080899
  },
  {
    "time": "01-08-2016",
    "open": 160.65,
    "high": 161.65,
    "low": 159.84,
    "close": 161.45,
    "volume": 2823578
  },
  {
    "time": "29-07-2016",
    "open": 161.36,
    "high": 161.67,
    "low": 160.03,
    "close": 160.62,
    "volume": 3547395
  },
  {
    "time": "28-07-2016",
    "open": 161.38,
    "high": 161.81,
    "low": 160.71,
    "close": 161.37,
    "volume": 2340369
  },
  {
    "time": "27-07-2016",
    "open": 162.12,
    "high": 162.57,
    "low": 161.18,
    "close": 161.83,
    "volume": 2162680
  },
  {
    "time": "26-07-2016",
    "open": 162.65,
    "high": 163.6,
    "low": 161.37,
    "close": 162.12,
    "volume": 2892969
  },
  {
    "time": "25-07-2016",
    "open": 162,
    "high": 162.88,
    "low": 161.75,
    "close": 162.65,
    "volume": 2804578
  },
  {
    "time": "22-07-2016",
    "open": 160.78,
    "high": 162.17,
    "low": 160.479,
    "close": 162.07,
    "volume": 2520077
  },
  {
    "time": "21-07-2016",
    "open": 160.75,
    "high": 161.85,
    "low": 159.89,
    "close": 160.45,
    "volume": 4046030
  },
  {
    "time": "20-07-2016",
    "open": 159.97,
    "high": 161.71,
    "low": 159.6,
    "close": 161.36,
    "volume": 4373609
  },
  {
    "time": "19-07-2016",
    "open": 161.78,
    "high": 162.14,
    "low": 157.89,
    "close": 159.58,
    "volume": 7119917
  },
  {
    "time": "18-07-2016",
    "open": 159.3,
    "high": 160.75,
    "low": 158.73,
    "close": 159.86,
    "volume": 6716340
  },
  {
    "time": "15-07-2016",
    "open": 159.9,
    "high": 159.98,
    "low": 158.5,
    "close": 159.78,
    "volume": 4474358
  },
  {
    "time": "14-07-2016",
    "open": 158.81,
    "high": 161.4,
    "low": 158.58,
    "close": 160.28,
    "volume": 4637624
  },
  {
    "time": "13-07-2016",
    "open": 157.75,
    "high": 158.34,
    "low": 157.06,
    "close": 158.02,
    "volume": 2746089
  },
  {
    "time": "12-07-2016",
    "open": 156,
    "high": 157.12,
    "low": 155.52,
    "close": 157.04,
    "volume": 3843773
  },
  {
    "time": "11-07-2016",
    "open": 154.6,
    "high": 156.04,
    "low": 154.5,
    "close": 155.33,
    "volume": 2833612
  },
  {
    "time": "08-07-2016",
    "open": 154,
    "high": 154.6,
    "low": 153.08,
    "close": 154.46,
    "volume": 3040593
  },
  {
    "time": "07-07-2016",
    "open": 152.75,
    "high": 153.6891,
    "low": 151.89,
    "close": 152.6,
    "volume": 2138421
  },
  {
    "time": "06-07-2016",
    "open": 151.85,
    "high": 152.41,
    "low": 149.92,
    "close": 152.37,
    "volume": 2569393
  },
  {
    "time": "05-07-2016",
    "open": 151.19,
    "high": 152,
    "low": 150.76,
    "close": 151.68,
    "volume": 2464820
  },
  {
    "time": "01-07-2016",
    "open": 152.19,
    "high": 152.97,
    "low": 151.4,
    "close": 152.35,
    "volume": 2658280
  },
  {
    "time": "30-06-2016",
    "open": 148.86,
    "high": 151.91,
    "low": 148.67,
    "close": 151.78,
    "volume": 3967126
  },
  {
    "time": "29-06-2016",
    "open": 146.5,
    "high": 148.48,
    "low": 146.45,
    "close": 148.46,
    "volume": 3367736
  },
  {
    "time": "28-06-2016",
    "open": 144.78,
    "high": 145.73,
    "low": 144.22,
    "close": 145.7,
    "volume": 3996007
  },
  {
    "time": "27-06-2016",
    "open": 146.18,
    "high": 146.6,
    "low": 142.5,
    "close": 143.5,
    "volume": 5166665
  },
  {
    "time": "24-06-2016",
    "open": 149.97,
    "high": 150.61,
    "low": 146.18,
    "close": 146.59,
    "volume": 9081182
  },
  {
    "time": "23-06-2016",
    "open": 153.45,
    "high": 155.48,
    "low": 152.6,
    "close": 155.35,
    "volume": 3967419
  },
  {
    "time": "22-06-2016",
    "open": 153.75,
    "high": 154.71,
    "low": 152.27,
    "close": 152.92,
    "volume": 2833364
  },
  {
    "time": "21-06-2016",
    "open": 154.16,
    "high": 154.5689,
    "low": 153.69,
    "close": 154.05,
    "volume": 2760344
  },
  {
    "time": "20-06-2016",
    "open": 152.6,
    "high": 154.67,
    "low": 152.52,
    "close": 153.61,
    "volume": 3572102
  },
  {
    "time": "17-06-2016",
    "open": 150.96,
    "high": 152.72,
    "low": 150.6044,
    "close": 151.99,
    "volume": 4663870
  },
  {
    "time": "16-06-2016",
    "open": 150.19,
    "high": 151.295,
    "low": 149,
    "close": 151.06,
    "volume": 2449064
  },
  {
    "time": "15-06-2016",
    "open": 151.46,
    "high": 151.7925,
    "low": 150.6,
    "close": 150.68,
    "volume": 2260406
  },
  {
    "time": "14-06-2016",
    "open": 151.28,
    "high": 151.79,
    "low": 150.4,
    "close": 151.06,
    "volume": 2865833
  },
  {
    "time": "13-06-2016",
    "open": 151.63,
    "high": 152.5,
    "low": 150.28,
    "close": 151.28,
    "volume": 3610407
  },
  {
    "time": "10-06-2016",
    "open": 152.79,
    "high": 153.3299,
    "low": 151.86,
    "close": 152.37,
    "volume": 3120563
  },
  {
    "time": "09-06-2016",
    "open": 152.92,
    "high": 153.76,
    "low": 152.73,
    "close": 153.42,
    "volume": 2021796
  },
  {
    "time": "08-06-2016",
    "open": 153.53,
    "high": 154.09,
    "low": 153.04,
    "close": 154,
    "volume": 2935036
  },
  {
    "time": "07-06-2016",
    "open": 153.31,
    "high": 153.93,
    "low": 152.66,
    "close": 153.33,
    "volume": 4464985
  },
  {
    "time": "06-06-2016",
    "open": 153.09,
    "high": 153.95,
    "low": 152.34,
    "close": 152.73,
    "volume": 2378462
  },
  {
    "time": "03-06-2016",
    "open": 152.4,
    "high": 153.04,
    "low": 151.7,
    "close": 152.89,
    "volume": 2328056
  },
  {
    "time": "02-06-2016",
    "open": 152.59,
    "high": 153.5,
    "low": 152.12,
    "close": 153.5,
    "volume": 2642601
  },
  {
    "time": "01-06-2016",
    "open": 153,
    "high": 153.27,
    "low": 151.54,
    "close": 152.51,
    "volume": 2662937
  },
  {
    "time": "31-05-2016",
    "open": 152.56,
    "high": 153.81,
    "low": 152.27,
    "close": 153.74,
    "volume": 5836645
  },
  {
    "time": "27-05-2016",
    "open": 152.35,
    "high": 152.93,
    "low": 152.15,
    "close": 152.84,
    "volume": 2457037
  },
  {
    "time": "26-05-2016",
    "open": 151.55,
    "high": 152.51,
    "low": 151.05,
    "close": 152.44,
    "volume": 3062934
  },
  {
    "time": "25-05-2016",
    "open": 148.93,
    "high": 152.09,
    "low": 148.5001,
    "close": 151.69,
    "volume": 4358039
  },
  {
    "time": "24-05-2016",
    "open": 146.88,
    "high": 148.75,
    "low": 146.88,
    "close": 148.31,
    "volume": 2929132
  },
  {
    "time": "23-05-2016",
    "open": 147.61,
    "high": 147.95,
    "low": 146.66,
    "close": 146.77,
    "volume": 2103733
  },
  {
    "time": "20-05-2016",
    "open": 145.71,
    "high": 147.51,
    "low": 145.55,
    "close": 147.25,
    "volume": 3670262
  },
  {
    "time": "19-05-2016",
    "open": 146.48,
    "high": 146.93,
    "low": 143.955,
    "close": 144.93,
    "volume": 3685595
  },
  {
    "time": "18-05-2016",
    "open": 147.99,
    "high": 148.52,
    "low": 146.36,
    "close": 147.34,
    "volume": 2491086
  },
  {
    "time": "17-05-2016",
    "open": 149.21,
    "high": 149.5,
    "low": 147.29,
    "close": 148,
    "volume": 3490374
  },
  {
    "time": "16-05-2016",
    "open": 147.65,
    "high": 149.99,
    "low": 147.44,
    "close": 149.46,
    "volume": 3069126
  },
  {
    "time": "13-05-2016",
    "open": 148.79,
    "high": 149.86,
    "low": 147.42,
    "close": 147.72,
    "volume": 2397902
  },
  {
    "time": "12-05-2016",
    "open": 149.21,
    "high": 149.39,
    "low": 147.11,
    "close": 148.84,
    "volume": 3249032
  },
  {
    "time": "11-05-2016",
    "open": 149.71,
    "high": 151.09,
    "low": 148.74,
    "close": 148.95,
    "volume": 3092408
  },
  {
    "time": "10-05-2016",
    "open": 148.24,
    "high": 150.04,
    "low": 147.74,
    "close": 149.97,
    "volume": 3992536
  },
  {
    "time": "09-05-2016",
    "open": 147.7,
    "high": 148.2,
    "low": 147.01,
    "close": 147.34,
    "volume": 4302425
  },
  {
    "time": "06-05-2016",
    "open": 144.86,
    "high": 147.97,
    "low": 144.47,
    "close": 147.29,
    "volume": 4895684
  },
  {
    "time": "05-05-2016",
    "open": 145.95,
    "high": 147.3,
    "low": 145.45,
    "close": 146.47,
    "volume": 6500738
  },
  {
    "time": "04-05-2016",
    "open": 143.36,
    "high": 145,
    "low": 143.31,
    "close": 144.25,
    "volume": 2576786
  },
  {
    "time": "03-05-2016",
    "open": 144.65,
    "high": 144.9,
    "low": 142.9,
    "close": 144.13,
    "volume": 3584261
  },
  {
    "time": "02-05-2016",
    "open": 146.56,
    "high": 147,
    "low": 144.43,
    "close": 145.27,
    "volume": 3502443
  },
  {
    "time": "29-04-2016",
    "open": 146.49,
    "high": 147.34,
    "low": 144.191,
    "close": 145.94,
    "volume": 4225790
  },
  {
    "time": "28-04-2016",
    "open": 149.75,
    "high": 150.18,
    "low": 146.725,
    "close": 147.07,
    "volume": 3791337
  },
  {
    "time": "27-04-2016",
    "open": 149.35,
    "high": 150.78,
    "low": 148.97,
    "close": 150.47,
    "volume": 3110789
  },
  {
    "time": "26-04-2016",
    "open": 148.65,
    "high": 149.79,
    "low": 147.9,
    "close": 149.08,
    "volume": 2979699
  },
  {
    "time": "25-04-2016",
    "open": 148.16,
    "high": 148.9,
    "low": 147.11,
    "close": 148.81,
    "volume": 2848935
  },
  {
    "time": "22-04-2016",
    "open": 149.44,
    "high": 151,
    "low": 147.5,
    "close": 148.5,
    "volume": 5191802
  },
  {
    "time": "21-04-2016",
    "open": 146.58,
    "high": 150.12,
    "low": 146.46,
    "close": 149.3,
    "volume": 6015526
  },
  {
    "time": "20-04-2016",
    "open": 144.24,
    "high": 147.2,
    "low": 144,
    "close": 146.11,
    "volume": 6754189
  },
  {
    "time": "19-04-2016",
    "open": 146.47,
    "high": 146.95,
    "low": 142.61,
    "close": 144,
    "volume": 13188308
  },
  {
    "time": "18-04-2016",
    "open": 151.75,
    "high": 153.14,
    "low": 151.49,
    "close": 152.53,
    "volume": 6538819
  },
  {
    "time": "15-04-2016",
    "open": 151.04,
    "high": 152.1,
    "low": 150.96,
    "close": 151.72,
    "volume": 3657466
  },
  {
    "time": "14-04-2016",
    "open": 151.5,
    "high": 152.76,
    "low": 150.38,
    "close": 151.16,
    "volume": 3176300
  },
  {
    "time": "13-04-2016",
    "open": 150.75,
    "high": 151.23,
    "low": 149.63,
    "close": 151.23,
    "volume": 3167970
  },
  {
    "time": "12-04-2016",
    "open": 149.61,
    "high": 150.11,
    "low": 148.58,
    "close": 149.63,
    "volume": 3595549
  },
  {
    "time": "11-04-2016",
    "open": 150.26,
    "high": 151.95,
    "low": 149.22,
    "close": 149.25,
    "volume": 3845918
  },
  {
    "time": "08-04-2016",
    "open": 149.06,
    "high": 149.99,
    "low": 148.12,
    "close": 149.35,
    "volume": 3293512
  },
  {
    "time": "07-04-2016",
    "open": 149.07,
    "high": 149.6,
    "low": 148,
    "close": 148.25,
    "volume": 3662508
  },
  {
    "time": "06-04-2016",
    "open": 149.9,
    "high": 150.15,
    "low": 147.81,
    "close": 150.02,
    "volume": 4194265
  },
  {
    "time": "05-04-2016",
    "open": 151.43,
    "high": 151.6,
    "low": 149.65,
    "close": 150,
    "volume": 3336952
  },
  {
    "time": "04-04-2016",
    "open": 152.34,
    "high": 153.52,
    "low": 151.91,
    "close": 152.07,
    "volume": 3930319
  },
  {
    "time": "01-04-2016",
    "open": 150.51,
    "high": 152.96,
    "low": 150.25,
    "close": 152.52,
    "volume": 3925181
  },
  {
    "time": "31-03-2016",
    "open": 149.79,
    "high": 153.1,
    "low": 149.36,
    "close": 151.45,
    "volume": 6780132
  },
  {
    "time": "30-03-2016",
    "open": 149.95,
    "high": 150.41,
    "low": 148.32,
    "close": 148.41,
    "volume": 3330841
  },
  {
    "time": "29-03-2016",
    "open": 147.59,
    "high": 149.76,
    "low": 147.5,
    "close": 149.33,
    "volume": 3138545
  },
  {
    "time": "28-03-2016",
    "open": 147.75,
    "high": 148.65,
    "low": 147.23,
    "close": 148.4,
    "volume": 3265084
  },
  {
    "time": "24-03-2016",
    "open": 144.98,
    "high": 148.22,
    "low": 144.49,
    "close": 147.95,
    "volume": 5044335
  },
  {
    "time": "23-03-2016",
    "open": 148,
    "high": 148.03,
    "low": 145.13,
    "close": 145.4,
    "volume": 4763987
  },
  {
    "time": "22-03-2016",
    "open": 148.06,
    "high": 149.28,
    "low": 147.84,
    "close": 148.1,
    "volume": 4081924
  },
  {
    "time": "21-03-2016",
    "open": 147.3,
    "high": 148.71,
    "low": 146.7201,
    "close": 148.63,
    "volume": 3721463
  },
  {
    "time": "18-03-2016",
    "open": 147.4,
    "high": 147.51,
    "low": 145.51,
    "close": 147.09,
    "volume": 12088126
  },
  {
    "time": "17-03-2016",
    "open": 144.78,
    "high": 147.32,
    "low": 144.45,
    "close": 147.04,
    "volume": 5821180
  },
  {
    "time": "16-03-2016",
    "open": 142.62,
    "high": 144.88,
    "low": 142.11,
    "close": 144.79,
    "volume": 4221939
  },
  {
    "time": "15-03-2016",
    "open": 141.74,
    "high": 143.33,
    "low": 141.54,
    "close": 142.96,
    "volume": 3707538
  },
  {
    "time": "14-03-2016",
    "open": 142.01,
    "high": 143.19,
    "low": 141.04,
    "close": 142.78,
    "volume": 4047634
  },
  {
    "time": "11-03-2016",
    "open": 141.73,
    "high": 142.9236,
    "low": 140.51,
    "close": 142.36,
    "volume": 4421748
  },
  {
    "time": "10-03-2016",
    "open": 141.24,
    "high": 141.47,
    "low": 138.09,
    "close": 140.19,
    "volume": 4020024
  },
  {
    "time": "09-03-2016",
    "open": 139.31,
    "high": 142.17,
    "low": 139.23,
    "close": 140.41,
    "volume": 4406304
  },
  {
    "time": "08-03-2016",
    "open": 139.71,
    "high": 140.35,
    "low": 137.42,
    "close": 139.07,
    "volume": 5537856
  },
  {
    "time": "07-03-2016",
    "open": 137.28,
    "high": 140.51,
    "low": 136.8718,
    "close": 140.15,
    "volume": 5170228
  },
  {
    "time": "04-03-2016",
    "open": 137.535,
    "high": 139.42,
    "low": 137.02,
    "close": 137.8,
    "volume": 3968423
  },
  {
    "time": "03-03-2016",
    "open": 137.22,
    "high": 137.9602,
    "low": 136.07,
    "close": 137.8,
    "volume": 5155978
  },
  {
    "time": "02-03-2016",
    "open": 133.7,
    "high": 137.44,
    "low": 133.22,
    "close": 136.3,
    "volume": 5217230
  },
  {
    "time": "01-03-2016",
    "open": 132.24,
    "high": 134.64,
    "low": 132.03,
    "close": 134.37,
    "volume": 3788084
  },
  {
    "time": "29-02-2016",
    "open": 132,
    "high": 133.36,
    "low": 130.9,
    "close": 131.03,
    "volume": 4427472
  },
  {
    "time": "26-02-2016",
    "open": 134.51,
    "high": 134.92,
    "low": 131.95,
    "close": 132.03,
    "volume": 4385699
  },
  {
    "time": "25-02-2016",
    "open": 133.29,
    "high": 134.51,
    "low": 130.88,
    "close": 134.5,
    "volume": 4358205
  },
  {
    "time": "24-02-2016",
    "open": 131.55,
    "high": 133.49,
    "low": 129.68,
    "close": 132.8,
    "volume": 4122421
  },
  {
    "time": "23-02-2016",
    "open": 133.14,
    "high": 133.41,
    "low": 132.01,
    "close": 132.4,
    "volume": 3439631
  },
  {
    "time": "22-02-2016",
    "open": 133.84,
    "high": 134.31,
    "low": 132.41,
    "close": 133.77,
    "volume": 4457444
  },
  {
    "time": "19-02-2016",
    "open": 132.36,
    "high": 133.65,
    "low": 130.84,
    "close": 133.08,
    "volume": 5125276
  },
  {
    "time": "18-02-2016",
    "open": 130,
    "high": 134,
    "low": 129.75,
    "close": 132.45,
    "volume": 9951438
  },
  {
    "time": "17-02-2016",
    "open": 123.71,
    "high": 126.57,
    "low": 123.25,
    "close": 126.1,
    "volume": 4820982
  },
  {
    "time": "16-02-2016",
    "open": 121.83,
    "high": 123.09,
    "low": 121.34,
    "close": 122.74,
    "volume": 4061066
  },
  {
    "time": "12-02-2016",
    "open": 119.26,
    "high": 121.05,
    "low": 118.35,
    "close": 121.04,
    "volume": 4935670
  },
  {
    "time": "11-02-2016",
    "open": 118.78,
    "high": 119.66,
    "low": 116.901,
    "close": 117.85,
    "volume": 7307503
  },
  {
    "time": "10-02-2016",
    "open": 125,
    "high": 125.29,
    "low": 119.84,
    "close": 120.19,
    "volume": 6462375
  },
  {
    "time": "09-02-2016",
    "open": 126.12,
    "high": 126.93,
    "low": 123.77,
    "close": 124.07,
    "volume": 5956138
  },
  {
    "time": "08-02-2016",
    "open": 126,
    "high": 127.51,
    "low": 124.13,
    "close": 126.98,
    "volume": 7363893
  },
  {
    "time": "05-02-2016",
    "open": 127.22,
    "high": 128.64,
    "low": 125.85,
    "close": 128.57,
    "volume": 7176765
  },
  {
    "time": "04-02-2016",
    "open": 125.08,
    "high": 128.5,
    "low": 125,
    "close": 127.65,
    "volume": 5265650
  },
  {
    "time": "03-02-2016",
    "open": 123.99,
    "high": 125.4499,
    "low": 122.29,
    "close": 124.72,
    "volume": 6223424
  },
  {
    "time": "02-02-2016",
    "open": 123.67,
    "high": 124.14,
    "low": 122.64,
    "close": 122.94,
    "volume": 3287218
  },
  {
    "time": "01-02-2016",
    "open": 124.4,
    "high": 125.29,
    "low": 123.71,
    "close": 124.83,
    "volume": 3574834
  },
  {
    "time": "29-01-2016",
    "open": 123.28,
    "high": 124.79,
    "low": 122.96,
    "close": 124.79,
    "volume": 8248077
  },
  {
    "time": "28-01-2016",
    "open": 121.26,
    "high": 122.83,
    "low": 121.01,
    "close": 122.22,
    "volume": 3942537
  },
  {
    "time": "27-01-2016",
    "open": 122.73,
    "high": 123.37,
    "low": 120.65,
    "close": 120.96,
    "volume": 5025161
  },
  {
    "time": "26-01-2016",
    "open": 122.25,
    "high": 123.61,
    "low": 122.24,
    "close": 122.59,
    "volume": 4617414
  },
  {
    "time": "25-01-2016",
    "open": 122.1,
    "high": 124.23,
    "low": 121.64,
    "close": 122.08,
    "volume": 5445560
  },
  {
    "time": "22-01-2016",
    "open": 124.67,
    "high": 124.74,
    "low": 121.77,
    "close": 122.5,
    "volume": 9238219
  },
  {
    "time": "21-01-2016",
    "open": 121.3,
    "high": 125.1,
    "low": 120.5751,
    "close": 122.91,
    "volume": 8851531
  },
  {
    "time": "20-01-2016",
    "open": 118.46,
    "high": 123.99,
    "low": 118,
    "close": 121.86,
    "volume": 16157769
  },
  {
    "time": "19-01-2016",
    "open": 130.11,
    "high": 132.1,
    "low": 128.06,
    "close": 128.11,
    "volume": 9955973
  },
  {
    "time": "15-01-2016",
    "open": 130.01,
    "high": 130.88,
    "low": 128.87,
    "close": 130.03,
    "volume": 9007945
  },
  {
    "time": "14-01-2016",
    "open": 131.93,
    "high": 133.79,
    "low": 131.22,
    "close": 132.91,
    "volume": 5709426
  },
  {
    "time": "13-01-2016",
    "open": 133.5,
    "high": 134.2799,
    "low": 131.1,
    "close": 131.17,
    "volume": 4700115
  },
  {
    "time": "12-01-2016",
    "open": 133.65,
    "high": 133.78,
    "low": 131.13,
    "close": 132.9,
    "volume": 5078360
  },
  {
    "time": "11-01-2016",
    "open": 131.81,
    "high": 133.82,
    "low": 131.76,
    "close": 133.23,
    "volume": 4974236
  },
  {
    "time": "08-01-2016",
    "open": 133.18,
    "high": 133.816,
    "low": 131.32,
    "close": 131.63,
    "volume": 4762583
  },
  {
    "time": "07-01-2016",
    "open": 133.7,
    "high": 135.02,
    "low": 132.43,
    "close": 132.86,
    "volume": 7024500
  },
  {
    "time": "06-01-2016",
    "open": 134.38,
    "high": 135.58,
    "low": 133.62,
    "close": 135.17,
    "volume": 4310774
  },
  {
    "time": "05-01-2016",
    "open": 136.76,
    "high": 136.89,
    "low": 134.8501,
    "close": 135.85,
    "volume": 3924785
  },
  {
    "time": "04-01-2016",
    "open": 135.6,
    "high": 135.97,
    "low": 134.24,
    "close": 135.95,
    "volume": 5229231
  },
  {
    "time": "31-12-2015",
    "open": 139.07,
    "high": 139.1,
    "low": 137.57,
    "close": 137.62,
    "volume": 3462037
  },
  {
    "time": "30-12-2015",
    "open": 139.58,
    "high": 140.44,
    "low": 139.22,
    "close": 139.34,
    "volume": 2989367
  },
  {
    "time": "29-12-2015",
    "open": 138.25,
    "high": 140.06,
    "low": 138.2,
    "close": 139.78,
    "volume": 3943691
  },
  {
    "time": "28-12-2015",
    "open": 137.74,
    "high": 138.045,
    "low": 136.54,
    "close": 137.61,
    "volume": 3143412
  },
  {
    "time": "24-12-2015",
    "open": 138.43,
    "high": 138.88,
    "low": 138.11,
    "close": 138.25,
    "volume": 1495226
  },
  {
    "time": "23-12-2015",
    "open": 138.3,
    "high": 139.305,
    "low": 138.11,
    "close": 138.54,
    "volume": 5164909
  },
  {
    "time": "22-12-2015",
    "open": 135.88,
    "high": 138.19,
    "low": 135.65,
    "close": 137.93,
    "volume": 4263538
  },
  {
    "time": "21-12-2015",
    "open": 135.83,
    "high": 135.83,
    "low": 134.02,
    "close": 135.5,
    "volume": 5616636
  },
  {
    "time": "18-12-2015",
    "open": 136.41,
    "high": 136.96,
    "low": 134.27,
    "close": 134.9,
    "volume": 10008280
  },
  {
    "time": "17-12-2015",
    "open": 139.35,
    "high": 139.5,
    "low": 136.3075,
    "close": 136.75,
    "volume": 4089539
  },
  {
    "time": "16-12-2015",
    "open": 139.12,
    "high": 139.65,
    "low": 137.79,
    "close": 139.29,
    "volume": 4345474
  },
  {
    "time": "15-12-2015",
    "open": 137.4,
    "high": 138.97,
    "low": 137.28,
    "close": 137.79,
    "volume": 4238546
  },
  {
    "time": "14-12-2015",
    "open": 135.31,
    "high": 136.14,
    "low": 134.02,
    "close": 135.93,
    "volume": 5141549
  },
  {
    "time": "11-12-2015",
    "open": 135.23,
    "high": 135.44,
    "low": 133.91,
    "close": 134.57,
    "volume": 5333567
  },
  {
    "time": "10-12-2015",
    "open": 137.03,
    "high": 137.85,
    "low": 135.7201,
    "close": 136.78,
    "volume": 4221948
  },
  {
    "time": "09-12-2015",
    "open": 137.38,
    "high": 139.84,
    "low": 136.23,
    "close": 136.61,
    "volume": 4614993
  },
  {
    "time": "08-12-2015",
    "open": 138.28,
    "high": 139.06,
    "low": 137.53,
    "close": 138.05,
    "volume": 3904755
  },
  {
    "time": "07-12-2015",
    "open": 140.16,
    "high": 140.41,
    "low": 138.81,
    "close": 139.55,
    "volume": 3279300
  },
  {
    "time": "04-12-2015",
    "open": 138.09,
    "high": 141.02,
    "low": 137.99,
    "close": 140.43,
    "volume": 4567752
  },
  {
    "time": "03-12-2015",
    "open": 140.1,
    "high": 140.73,
    "low": 138.19,
    "close": 138.92,
    "volume": 5905763
  },
  {
    "time": "02-12-2015",
    "open": 140.93,
    "high": 141.21,
    "low": 139.5,
    "close": 139.7,
    "volume": 3725193
  },
  {
    "time": "01-12-2015",
    "open": 139.58,
    "high": 141.4,
    "low": 139.58,
    "close": 141.28,
    "volume": 4195119
  },
  {
    "time": "30-11-2015",
    "open": 138.61,
    "high": 139.9,
    "low": 138.52,
    "close": 139.42,
    "volume": 4542014
  },
  {
    "time": "27-11-2015",
    "open": 138,
    "high": 138.81,
    "low": 137.21,
    "close": 138.46,
    "volume": 1415840
  },
  {
    "time": "25-11-2015",
    "open": 138.37,
    "high": 138.43,
    "low": 137.375,
    "close": 138,
    "volume": 3238166
  },
  {
    "time": "24-11-2015",
    "open": 137.65,
    "high": 139.34,
    "low": 137.31,
    "close": 138.6,
    "volume": 3407603
  },
  {
    "time": "23-11-2015",
    "open": 138.53,
    "high": 138.87,
    "low": 137.12,
    "close": 138.46,
    "volume": 5137872
  },
  {
    "time": "20-11-2015",
    "open": 137.37,
    "high": 138.915,
    "low": 137.25,
    "close": 138.5,
    "volume": 5176255
  },
  {
    "time": "19-11-2015",
    "open": 136.21,
    "high": 137.7355,
    "low": 136.01,
    "close": 136.74,
    "volume": 4753503
  },
  {
    "time": "18-11-2015",
    "open": 134.79,
    "high": 135.91,
    "low": 134.26,
    "close": 135.82,
    "volume": 4148771
  },
  {
    "time": "17-11-2015",
    "open": 134.22,
    "high": 134.82,
    "low": 133.31,
    "close": 133.82,
    "volume": 2814487
  },
  {
    "time": "16-11-2015",
    "open": 131.79,
    "high": 134.09,
    "low": 131.79,
    "close": 133.71,
    "volume": 4190777
  },
  {
    "time": "13-11-2015",
    "open": 132.95,
    "high": 133,
    "low": 131.65,
    "close": 131.75,
    "volume": 4609621
  },
  {
    "time": "12-11-2015",
    "open": 134.7,
    "high": 134.7,
    "low": 133.01,
    "close": 133.04,
    "volume": 4700465
  },
  {
    "time": "11-11-2015",
    "open": 136.09,
    "high": 136.2,
    "low": 134.5,
    "close": 135.02,
    "volume": 3164806
  },
  {
    "time": "10-11-2015",
    "open": 135.36,
    "high": 135.735,
    "low": 134.12,
    "close": 135.47,
    "volume": 4520387
  },
  {
    "time": "09-11-2015",
    "open": 137.92,
    "high": 138.08,
    "low": 134.88,
    "close": 135.31,
    "volume": 8074476
  },
  {
    "time": "06-11-2015",
    "open": 138.6,
    "high": 139.55,
    "low": 137.87,
    "close": 138.25,
    "volume": 4744493
  },
  {
    "time": "05-11-2015",
    "open": 141.61,
    "high": 142.38,
    "low": 139.63,
    "close": 139.89,
    "volume": 5093471
  },
  {
    "time": "04-11-2015",
    "open": 142.55,
    "high": 142.65,
    "low": 141.3,
    "close": 141.63,
    "volume": 3516890
  },
  {
    "time": "03-11-2015",
    "open": 140.02,
    "high": 142.8,
    "low": 139.9,
    "close": 141.88,
    "volume": 4349317
  },
  {
    "time": "02-11-2015",
    "open": 140.5,
    "high": 140.5165,
    "low": 139.57,
    "close": 140.37,
    "volume": 3822212
  },
  {
    "time": "30-10-2015",
    "open": 140.44,
    "high": 141.33,
    "low": 139.9,
    "close": 140.08,
    "volume": 3983990
  },
  {
    "time": "29-10-2015",
    "open": 140.36,
    "high": 140.82,
    "low": 139.0201,
    "close": 140.55,
    "volume": 3710245
  },
  {
    "time": "28-10-2015",
    "open": 137.92,
    "high": 141.23,
    "low": 137.5,
    "close": 140.83,
    "volume": 8510027
  },
  {
    "time": "27-10-2015",
    "open": 143.51,
    "high": 143.83,
    "low": 137.33,
    "close": 137.86,
    "volume": 15005072
  },
  {
    "time": "26-10-2015",
    "open": 144.75,
    "high": 145,
    "low": 143.21,
    "close": 143.66,
    "volume": 3489428
  },
  {
    "time": "23-10-2015",
    "open": 144.61,
    "high": 145.49,
    "low": 143.7,
    "close": 144.68,
    "volume": 5370439
  },
  {
    "time": "22-10-2015",
    "open": 141.74,
    "high": 145.07,
    "low": 141.62,
    "close": 144.09,
    "volume": 5583076
  },
  {
    "time": "21-10-2015",
    "open": 140.25,
    "high": 142.66,
    "low": 139.3,
    "close": 140.92,
    "volume": 7134526
  },
  {
    "time": "20-10-2015",
    "open": 142.49,
    "high": 142.88,
    "low": 140.27,
    "close": 140.64,
    "volume": 16025591
  },
  {
    "time": "19-10-2015",
    "open": 149.85,
    "high": 149.97,
    "low": 148.38,
    "close": 149.22,
    "volume": 7954684
  },
  {
    "time": "16-10-2015",
    "open": 150.45,
    "high": 151.2,
    "low": 149.26,
    "close": 150.39,
    "volume": 3483599
  },
  {
    "time": "15-10-2015",
    "open": 150.91,
    "high": 151.24,
    "low": 148.58,
    "close": 150.09,
    "volume": 3476219
  },
  {
    "time": "14-10-2015",
    "open": 149.6,
    "high": 150.65,
    "low": 149.02,
    "close": 150.01,
    "volume": 3352327
  },
  {
    "time": "13-10-2015",
    "open": 150.78,
    "high": 150.78,
    "low": 149.18,
    "close": 149.62,
    "volume": 3915707
  },
  {
    "time": "12-10-2015",
    "open": 152.4,
    "high": 152.58,
    "low": 150.85,
    "close": 151.14,
    "volume": 3227636
  },
  {
    "time": "09-10-2015",
    "open": 152.46,
    "high": 153.147,
    "low": 151.27,
    "close": 152.39,
    "volume": 3531107
  },
  {
    "time": "08-10-2015",
    "open": 149.69,
    "high": 153.019,
    "low": 149.29,
    "close": 152.28,
    "volume": 4794357
  },
  {
    "time": "07-10-2015",
    "open": 150.04,
    "high": 150.73,
    "low": 148.8565,
    "close": 150.09,
    "volume": 2989915
  },
  {
    "time": "06-10-2015",
    "open": 149.06,
    "high": 150.15,
    "low": 148.49,
    "close": 148.78,
    "volume": 3018074
  },
  {
    "time": "05-10-2015",
    "open": 145.82,
    "high": 149.8925,
    "low": 145.82,
    "close": 149.04,
    "volume": 5131669
  },
  {
    "time": "02-10-2015",
    "open": 141.05,
    "high": 144.59,
    "low": 140.56,
    "close": 144.58,
    "volume": 3053928
  },
  {
    "time": "01-10-2015",
    "open": 145.31,
    "high": 145.67,
    "low": 141.59,
    "close": 143.59,
    "volume": 3880747
  },
  {
    "time": "30-09-2015",
    "open": 143.69,
    "high": 145.71,
    "low": 143.66,
    "close": 144.97,
    "volume": 3923866
  },
  {
    "time": "29-09-2015",
    "open": 141.99,
    "high": 142.66,
    "low": 141.1501,
    "close": 142.47,
    "volume": 4763509
  },
  {
    "time": "28-09-2015",
    "open": 144.42,
    "high": 145.38,
    "low": 142.47,
    "close": 142.52,
    "volume": 4292421
  },
  {
    "time": "25-09-2015",
    "open": 145.55,
    "high": 146.27,
    "low": 144.53,
    "close": 145.42,
    "volume": 3474279
  },
  {
    "time": "24-09-2015",
    "open": 142.6,
    "high": 145.07,
    "low": 141.95,
    "close": 144.41,
    "volume": 3278207
  },
  {
    "time": "23-09-2015",
    "open": 144.21,
    "high": 144.57,
    "low": 142.75,
    "close": 143.66,
    "volume": 2674225
  },
  {
    "time": "22-09-2015",
    "open": 144.62,
    "high": 145.06,
    "low": 143.7699,
    "close": 144.43,
    "volume": 3564015
  },
  {
    "time": "21-09-2015",
    "open": 145.39,
    "high": 146.98,
    "low": 144.92,
    "close": 146.48,
    "volume": 3824858
  },
  {
    "time": "18-09-2015",
    "open": 146.05,
    "high": 146.385,
    "low": 143.98,
    "close": 144.51,
    "volume": 7975845
  },
  {
    "time": "17-09-2015",
    "open": 148.1,
    "high": 149.68,
    "low": 147.3,
    "close": 148.14,
    "volume": 4002952
  },
  {
    "time": "16-09-2015",
    "open": 147.84,
    "high": 148.89,
    "low": 147.54,
    "close": 148.41,
    "volume": 2799222
  },
  {
    "time": "15-09-2015",
    "open": 146.6,
    "high": 147.93,
    "low": 145.76,
    "close": 147.53,
    "volume": 2717060
  },
  {
    "time": "14-09-2015",
    "open": 147.37,
    "high": 147.37,
    "low": 145.41,
    "close": 145.65,
    "volume": 3226747
  },
  {
    "time": "11-09-2015",
    "open": 145.91,
    "high": 147.5,
    "low": 145.67,
    "close": 147.37,
    "volume": 3113953
  },
  {
    "time": "10-09-2015",
    "open": 145.85,
    "high": 147.16,
    "low": 144.51,
    "close": 146.2,
    "volume": 3461658
  },
  {
    "time": "09-09-2015",
    "open": 148.74,
    "high": 149.04,
    "low": 144.85,
    "close": 145.05,
    "volume": 3407761
  },
  {
    "time": "08-09-2015",
    "open": 145.86,
    "high": 147.34,
    "low": 145.66,
    "close": 147.23,
    "volume": 3933010
  },
  {
    "time": "04-09-2015",
    "open": 144.57,
    "high": 145.4,
    "low": 143.32,
    "close": 143.7,
    "volume": 4201068
  },
  {
    "time": "03-09-2015",
    "open": 146.05,
    "high": 148.03,
    "low": 145.77,
    "close": 146.78,
    "volume": 3603518
  },
  {
    "time": "02-09-2015",
    "open": 144.92,
    "high": 145.08,
    "low": 143.18,
    "close": 145.05,
    "volume": 4251778
  },
  {
    "time": "01-09-2015",
    "open": 144.84,
    "high": 144.98,
    "low": 141.85,
    "close": 142.68,
    "volume": 5271696
  },
  {
    "time": "31-08-2015",
    "open": 147.26,
    "high": 148.4,
    "low": 146.26,
    "close": 147.89,
    "volume": 4093078
  },
  {
    "time": "28-08-2015",
    "open": 147.75,
    "high": 148.2,
    "low": 147.18,
    "close": 147.98,
    "volume": 4076211
  },
  {
    "time": "27-08-2015",
    "open": 148.63,
    "high": 148.97,
    "low": 145.66,
    "close": 148.54,
    "volume": 4975966
  },
  {
    "time": "26-08-2015",
    "open": 144.09,
    "high": 146.98,
    "low": 142.14,
    "close": 146.7,
    "volume": 6197229
  },
  {
    "time": "25-08-2015",
    "open": 146.94,
    "high": 147.11,
    "low": 140.62,
    "close": 140.96,
    "volume": 7071869
  },
  {
    "time": "24-08-2015",
    "open": 143.47,
    "high": 147.76,
    "low": 142.32,
    "close": 143.47,
    "volume": 10186817
  },
  {
    "time": "21-08-2015",
    "open": 151.5,
    "high": 153.19,
    "low": 148.7,
    "close": 148.85,
    "volume": 7360898
  },
  {
    "time": "20-08-2015",
    "open": 152.74,
    "high": 153.91,
    "low": 152.5,
    "close": 152.66,
    "volume": 4011514
  },
  {
    "time": "19-08-2015",
    "open": 155.15,
    "high": 155.67,
    "low": 153.41,
    "close": 153.94,
    "volume": 4205904
  },
  {
    "time": "18-08-2015",
    "open": 155.51,
    "high": 156.52,
    "low": 155.25,
    "close": 156.01,
    "volume": 2018337
  },
  {
    "time": "17-08-2015",
    "open": 155.2,
    "high": 156.69,
    "low": 154.7,
    "close": 156.31,
    "volume": 2249554
  },
  {
    "time": "14-08-2015",
    "open": 155,
    "high": 156.21,
    "low": 154.58,
    "close": 155.75,
    "volume": 3226434
  },
  {
    "time": "13-08-2015",
    "open": 156.06,
    "high": 156.09,
    "low": 154.3171,
    "close": 155.07,
    "volume": 2533318
  },
  {
    "time": "12-08-2015",
    "open": 154.26,
    "high": 156.52,
    "low": 153.95,
    "close": 156.16,
    "volume": 3624852
  },
  {
    "time": "11-08-2015",
    "open": 155.96,
    "high": 155.99,
    "low": 154.86,
    "close": 155.51,
    "volume": 3167111
  },
  {
    "time": "10-08-2015",
    "open": 156.77,
    "high": 157.4652,
    "low": 156.12,
    "close": 156.75,
    "volume": 4613371
  },
  {
    "time": "07-08-2015",
    "open": 155.89,
    "high": 156.25,
    "low": 154.335,
    "close": 155.12,
    "volume": 3794897
  },
  {
    "time": "06-08-2015",
    "open": 157.13,
    "high": 157.54,
    "low": 156.18,
    "close": 156.32,
    "volume": 3004487
  },
  {
    "time": "05-08-2015",
    "open": 159.1,
    "high": 159.54,
    "low": 157.48,
    "close": 157.9,
    "volume": 3463034
  },
  {
    "time": "04-08-2015",
    "open": 158.4,
    "high": 158.82,
    "low": 156.87,
    "close": 157.6,
    "volume": 5260575
  },
  {
    "time": "03-08-2015",
    "open": 161.7,
    "high": 161.85,
    "low": 157.9,
    "close": 158.71,
    "volume": 4615511
  },
  {
    "time": "31-07-2015",
    "open": 161.4,
    "high": 162.06,
    "low": 160.63,
    "close": 161.99,
    "volume": 3580226
  },
  {
    "time": "30-07-2015",
    "open": 160.5,
    "high": 161.4,
    "low": 159.75,
    "close": 160.96,
    "volume": 1994660
  },
  {
    "time": "29-07-2015",
    "open": 160,
    "high": 161.5,
    "low": 159.39,
    "close": 161.09,
    "volume": 3378396
  },
  {
    "time": "28-07-2015",
    "open": 159.91,
    "high": 160.19,
    "low": 158.5,
    "close": 160.05,
    "volume": 2720890
  },
  {
    "time": "27-07-2015",
    "open": 159,
    "high": 160.18,
    "low": 158.6,
    "close": 159.07,
    "volume": 3706240
  },
  {
    "time": "24-07-2015",
    "open": 161.29,
    "high": 161.93,
    "low": 159.41,
    "close": 159.75,
    "volume": 3777631
  },
  {
    "time": "23-07-2015",
    "open": 160.27,
    "high": 162.75,
    "low": 159.83,
    "close": 161.73,
    "volume": 5034246
  },
  {
    "time": "22-07-2015",
    "open": 162.73,
    "high": 163.0695,
    "low": 159.7,
    "close": 160.35,
    "volume": 7180385
  },
  {
    "time": "21-07-2015",
    "open": 164.34,
    "high": 166.35,
    "low": 162.02,
    "close": 163.07,
    "volume": 13747503
  },
  {
    "time": "20-07-2015",
    "open": 172.79,
    "high": 173.78,
    "low": 172.14,
    "close": 173.22,
    "volume": 7335491
  },
  {
    "time": "17-07-2015",
    "open": 170.33,
    "high": 172.52,
    "low": 170.1,
    "close": 172.51,
    "volume": 4356391
  },
  {
    "time": "16-07-2015",
    "open": 169.47,
    "high": 171.09,
    "low": 169.16,
    "close": 171,
    "volume": 3529874
  },
  {
    "time": "15-07-2015",
    "open": 168.48,
    "high": 169.27,
    "low": 168,
    "close": 168.53,
    "volume": 1998004
  },
  {
    "time": "14-07-2015",
    "open": 169.43,
    "high": 169.54,
    "low": 168.24,
    "close": 168.61,
    "volume": 3025263
  },
  {
    "time": "13-07-2015",
    "open": 167.93,
    "high": 169.89,
    "low": 167.52,
    "close": 169.38,
    "volume": 4228963
  },
  {
    "time": "10-07-2015",
    "open": 165.66,
    "high": 167.4,
    "low": 165.515,
    "close": 166.95,
    "volume": 4538349
  },
  {
    "time": "09-07-2015",
    "open": 165.34,
    "high": 165.51,
    "low": 163.7,
    "close": 163.85,
    "volume": 4199708
  },
  {
    "time": "08-07-2015",
    "open": 164,
    "high": 164.94,
    "low": 162.68,
    "close": 163.16,
    "volume": 2776286
  },
  {
    "time": "07-07-2015",
    "open": 164.94,
    "high": 165.35,
    "low": 162.2317,
    "close": 165,
    "volume": 3549861
  },
  {
    "time": "06-07-2015",
    "open": 163.83,
    "high": 165.23,
    "low": 163.52,
    "close": 164.73,
    "volume": 3079782
  },
  {
    "time": "02-07-2015",
    "open": 165.05,
    "high": 165.32,
    "low": 164.42,
    "close": 165.09,
    "volume": 2852696
  },
  {
    "time": "01-07-2015",
    "open": 163.97,
    "high": 165.61,
    "low": 163.6,
    "close": 164.49,
    "volume": 3214547
  },
  {
    "time": "30-06-2015",
    "open": 163.99,
    "high": 164,
    "low": 162.12,
    "close": 162.66,
    "volume": 3597288
  },
  {
    "time": "29-06-2015",
    "open": 163.92,
    "high": 165.35,
    "low": 162.89,
    "close": 162.97,
    "volume": 3314679
  },
  {
    "time": "26-06-2015",
    "open": 166.23,
    "high": 166.8116,
    "low": 165.23,
    "close": 165.46,
    "volume": 8945485
  },
  {
    "time": "25-06-2015",
    "open": 167.8,
    "high": 168.13,
    "low": 166.08,
    "close": 166.08,
    "volume": 2408780
  },
  {
    "time": "24-06-2015",
    "open": 168.71,
    "high": 168.84,
    "low": 166.8301,
    "close": 166.97,
    "volume": 3548079
  },
  {
    "time": "23-06-2015",
    "open": 168.38,
    "high": 169.97,
    "low": 167.573,
    "close": 168.62,
    "volume": 3701637
  },
  {
    "time": "22-06-2015",
    "open": 167.65,
    "high": 168.34,
    "low": 167.2,
    "close": 167.73,
    "volume": 2335796
  },
  {
    "time": "19-06-2015",
    "open": 167.62,
    "high": 168.42,
    "low": 166.77,
    "close": 166.99,
    "volume": 7072020
  },
  {
    "time": "18-06-2015",
    "open": 167.05,
    "high": 168.72,
    "low": 167.05,
    "close": 168.25,
    "volume": 3330864
  },
  {
    "time": "17-06-2015",
    "open": 167,
    "high": 167.8476,
    "low": 166.1,
    "close": 167.17,
    "volume": 2862993
  },
  {
    "time": "16-06-2015",
    "open": 166.33,
    "high": 167.4,
    "low": 165.91,
    "close": 166.84,
    "volume": 3249842
  },
  {
    "time": "15-06-2015",
    "open": 165.33,
    "high": 166.44,
    "low": 164.25,
    "close": 166.26,
    "volume": 4245657
  },
  {
    "time": "12-06-2015",
    "open": 168.23,
    "high": 168.3,
    "low": 166.69,
    "close": 166.99,
    "volume": 3065085
  },
  {
    "time": "11-06-2015",
    "open": 169.26,
    "high": 170.44,
    "low": 168.54,
    "close": 168.78,
    "volume": 3464013
  },
  {
    "time": "10-06-2015",
    "open": 166.49,
    "high": 169.39,
    "low": 166.06,
    "close": 168.92,
    "volume": 4680545
  },
  {
    "time": "09-06-2015",
    "open": 165.34,
    "high": 166.02,
    "low": 163.37,
    "close": 165.68,
    "volume": 3395901
  },
  {
    "time": "08-06-2015",
    "open": 167.17,
    "high": 167.28,
    "low": 165.02,
    "close": 165.34,
    "volume": 3758726
  },
  {
    "time": "05-06-2015",
    "open": 168.25,
    "high": 168.9103,
    "low": 167.2,
    "close": 167.4,
    "volume": 3100505
  },
  {
    "time": "04-06-2015",
    "open": 169.53,
    "high": 170.6,
    "low": 167.93,
    "close": 168.38,
    "volume": 3079334
  },
  {
    "time": "03-06-2015",
    "open": 170.5,
    "high": 171.5601,
    "low": 169.63,
    "close": 169.92,
    "volume": 2131031
  },
  {
    "time": "02-06-2015",
    "open": 169.66,
    "high": 170.45,
    "low": 168.43,
    "close": 169.65,
    "volume": 2571762
  },
  {
    "time": "01-06-2015",
    "open": 170.21,
    "high": 171.04,
    "low": 169.03,
    "close": 170.18,
    "volume": 2985479
  },
  {
    "time": "29-05-2015",
    "open": 171.35,
    "high": 171.35,
    "low": 169.65,
    "close": 169.65,
    "volume": 4091431
  },
  {
    "time": "28-05-2015",
    "open": 171.45,
    "high": 171.84,
    "low": 170.66,
    "close": 171.71,
    "volume": 1731372
  },
  {
    "time": "27-05-2015",
    "open": 171.16,
    "high": 172.48,
    "low": 170.49,
    "close": 172,
    "volume": 2764378
  },
  {
    "time": "26-05-2015",
    "open": 172.11,
    "high": 172.12,
    "low": 169.13,
    "close": 170.13,
    "volume": 3848738
  },
  {
    "time": "22-05-2015",
    "open": 173.04,
    "high": 173.39,
    "low": 172.19,
    "close": 172.22,
    "volume": 2849692
  },
  {
    "time": "21-05-2015",
    "open": 173.32,
    "high": 174.14,
    "low": 173.035,
    "close": 173.34,
    "volume": 2295590
  },
  {
    "time": "20-05-2015",
    "open": 173.33,
    "high": 174.44,
    "low": 172.46,
    "close": 173.76,
    "volume": 2300693
  },
  {
    "time": "19-05-2015",
    "open": 172.97,
    "high": 173.75,
    "low": 171.93,
    "close": 173.48,
    "volume": 2523002
  },
  {
    "time": "18-05-2015",
    "open": 173.44,
    "high": 173.49,
    "low": 172.3,
    "close": 173.06,
    "volume": 1970630
  },
  {
    "time": "15-05-2015",
    "open": 173.91,
    "high": 174.41,
    "low": 172.6,
    "close": 173.26,
    "volume": 2916115
  },
  {
    "time": "14-05-2015",
    "open": 173.5,
    "high": 174.4,
    "low": 173.22,
    "close": 174.05,
    "volume": 2438669
  },
  {
    "time": "13-05-2015",
    "open": 171.24,
    "high": 172.74,
    "low": 170.75,
    "close": 172.28,
    "volume": 2457521
  },
  {
    "time": "12-05-2015",
    "open": 170.55,
    "high": 171.4899,
    "low": 168.84,
    "close": 170.55,
    "volume": 2961739
  },
  {
    "time": "11-05-2015",
    "open": 172.65,
    "high": 172.99,
    "low": 170.86,
    "close": 171.12,
    "volume": 2661030
  },
  {
    "time": "08-05-2015",
    "open": 172.94,
    "high": 173.33,
    "low": 172.24,
    "close": 172.68,
    "volume": 3092599
  },
  {
    "time": "07-05-2015",
    "open": 169.63,
    "high": 171.98,
    "low": 169.04,
    "close": 170.99,
    "volume": 2472627
  },
  {
    "time": "06-05-2015",
    "open": 172.9,
    "high": 174.05,
    "low": 168.86,
    "close": 170.05,
    "volume": 3610900
  },
  {
    "time": "05-05-2015",
    "open": 173.51,
    "high": 174.23,
    "low": 171.96,
    "close": 173.08,
    "volume": 3593465
  },
  {
    "time": "04-05-2015",
    "open": 174.47,
    "high": 176.3,
    "low": 173.7,
    "close": 173.97,
    "volume": 4023850
  },
  {
    "time": "01-05-2015",
    "open": 173.2,
    "high": 174,
    "low": 172.42,
    "close": 173.67,
    "volume": 3312052
  },
  {
    "time": "30-04-2015",
    "open": 173.77,
    "high": 174.28,
    "low": 170.3,
    "close": 171.29,
    "volume": 5062613
  },
  {
    "time": "29-04-2015",
    "open": 173.4,
    "high": 175.1303,
    "low": 172.8,
    "close": 174.4,
    "volume": 4517190
  },
  {
    "time": "28-04-2015",
    "open": 170.83,
    "high": 174.69,
    "low": 170.02,
    "close": 173.92,
    "volume": 5816308
  },
  {
    "time": "27-04-2015",
    "open": 170.89,
    "high": 171.49,
    "low": 170.3,
    "close": 170.73,
    "volume": 3166588
  },
  {
    "time": "24-04-2015",
    "open": 170.23,
    "high": 170.85,
    "low": 168.74,
    "close": 169.78,
    "volume": 3888439
  },
  {
    "time": "23-04-2015",
    "open": 165.23,
    "high": 171.91,
    "low": 165.14,
    "close": 170.24,
    "volume": 8230392
  },
  {
    "time": "22-04-2015",
    "open": 164.39,
    "high": 166,
    "low": 162.89,
    "close": 165.36,
    "volume": 4024674
  },
  {
    "time": "21-04-2015",
    "open": 166.07,
    "high": 167.38,
    "low": 164.05,
    "close": 164.26,
    "volume": 9683592
  },
  {
    "time": "20-04-2015",
    "open": 162.1,
    "high": 166.64,
    "low": 161.9,
    "close": 166.16,
    "volume": 9609204
  },
  {
    "time": "17-04-2015",
    "open": 161.75,
    "high": 161.86,
    "low": 160.03,
    "close": 160.67,
    "volume": 4308880
  },
  {
    "time": "16-04-2015",
    "open": 163.31,
    "high": 163.98,
    "low": 162.54,
    "close": 163.13,
    "volume": 3136947
  },
  {
    "time": "15-04-2015",
    "open": 162.63,
    "high": 164.96,
    "low": 162.5,
    "close": 164.13,
    "volume": 3498756
  },
  {
    "time": "14-04-2015",
    "open": 162.42,
    "high": 162.7352,
    "low": 160.79,
    "close": 162.3,
    "volume": 2719287
  },
  {
    "time": "13-04-2015",
    "open": 162.37,
    "high": 164,
    "low": 162.36,
    "close": 162.38,
    "volume": 3868911
  },
  {
    "time": "10-04-2015",
    "open": 162.34,
    "high": 163.33,
    "low": 161.25,
    "close": 162.86,
    "volume": 2515603
  },
  {
    "time": "09-04-2015",
    "open": 161.7,
    "high": 162.47,
    "low": 160.72,
    "close": 162.34,
    "volume": 2263490
  },
  {
    "time": "08-04-2015",
    "open": 161.72,
    "high": 163.55,
    "low": 161.01,
    "close": 161.85,
    "volume": 2524323
  },
  {
    "time": "07-04-2015",
    "open": 161.67,
    "high": 163.84,
    "low": 161.62,
    "close": 162.07,
    "volume": 3147966
  },
  {
    "time": "06-04-2015",
    "open": 159.69,
    "high": 162.8,
    "low": 158.702,
    "close": 162.04,
    "volume": 3465681
  },
  {
    "time": "02-04-2015",
    "open": 159.52,
    "high": 162.54,
    "low": 158.89,
    "close": 160.45,
    "volume": 4671578
  },
  {
    "time": "01-04-2015",
    "open": 160.23,
    "high": 160.62,
    "low": 158.39,
    "close": 159.18,
    "volume": 3700791
  },
  {
    "time": "31-03-2015",
    "open": 161.08,
    "high": 162.33,
    "low": 160.4,
    "close": 160.5,
    "volume": 4255158
  },
  {
    "time": "30-03-2015",
    "open": 161.31,
    "high": 163.6,
    "low": 161.25,
    "close": 162.67,
    "volume": 4138018
  },
  {
    "time": "27-03-2015",
    "open": 160.79,
    "high": 160.9,
    "low": 159.23,
    "close": 160.4,
    "volume": 3477826
  },
  {
    "time": "26-03-2015",
    "open": 159.27,
    "high": 161.25,
    "low": 158.91,
    "close": 160.59,
    "volume": 4396024
  },
  {
    "time": "25-03-2015",
    "open": 163.08,
    "high": 163.08,
    "low": 159.2,
    "close": 159.2,
    "volume": 5428730
  },
  {
    "time": "24-03-2015",
    "open": 164.79,
    "high": 164.89,
    "low": 162.9,
    "close": 163,
    "volume": 4336706
  },
  {
    "time": "23-03-2015",
    "open": 162.77,
    "high": 165.35,
    "low": 162.73,
    "close": 164.63,
    "volume": 5929927
  },
  {
    "time": "20-03-2015",
    "open": 160.95,
    "high": 163,
    "low": 160.35,
    "close": 162.88,
    "volume": 9006417
  },
  {
    "time": "19-03-2015",
    "open": 159.7,
    "high": 160.92,
    "low": 158.81,
    "close": 159.81,
    "volume": 3878559
  },
  {
    "time": "18-03-2015",
    "open": 156.85,
    "high": 160.7,
    "low": 155.8,
    "close": 159.81,
    "volume": 5738466
  },
  {
    "time": "17-03-2015",
    "open": 156.2,
    "high": 157.29,
    "low": 155.37,
    "close": 156.96,
    "volume": 3311792
  },
  {
    "time": "16-03-2015",
    "open": 155.05,
    "high": 157.09,
    "low": 154.8,
    "close": 157.08,
    "volume": 3749638
  },
  {
    "time": "13-03-2015",
    "open": 157.46,
    "high": 157.52,
    "low": 153.4,
    "close": 154.28,
    "volume": 6063970
  },
  {
    "time": "12-03-2015",
    "open": 156.27,
    "high": 158.72,
    "low": 156.27,
    "close": 157.98,
    "volume": 4567346
  },
  {
    "time": "11-03-2015",
    "open": 158.57,
    "high": 159.14,
    "low": 155.935,
    "close": 156.8,
    "volume": 5709290
  },
  {
    "time": "10-03-2015",
    "open": 159.6,
    "high": 160.23,
    "low": 157.81,
    "close": 157.81,
    "volume": 4600968
  },
  {
    "time": "09-03-2015",
    "open": 158.2,
    "high": 161.15,
    "low": 158.2,
    "close": 160.77,
    "volume": 5193874
  },
  {
    "time": "06-03-2015",
    "open": 160.19,
    "high": 161.44,
    "low": 158.06,
    "close": 158.5,
    "volume": 4540174
  },
  {
    "time": "05-03-2015",
    "open": 159.86,
    "high": 161.5,
    "low": 159.703,
    "close": 161.18,
    "volume": 3791660
  },
  {
    "time": "04-03-2015",
    "open": 160.54,
    "high": 160.56,
    "low": 158.72,
    "close": 159.42,
    "volume": 3633335
  },
  {
    "time": "03-03-2015",
    "open": 160.27,
    "high": 161.75,
    "low": 160,
    "close": 161.03,
    "volume": 3234068
  },
  {
    "time": "02-03-2015",
    "open": 161.68,
    "high": 161.89,
    "low": 159.8101,
    "close": 160.48,
    "volume": 5917812
  },
  {
    "time": "27-02-2015",
    "open": 161.05,
    "high": 162.29,
    "low": 160.16,
    "close": 161.94,
    "volume": 4410037
  },
  {
    "time": "26-02-2015",
    "open": 163.74,
    "high": 164.14,
    "low": 159.77,
    "close": 160.87,
    "volume": 7110571
  },
  {
    "time": "25-02-2015",
    "open": 164.94,
    "high": 164.96,
    "low": 162.58,
    "close": 162.81,
    "volume": 4004559
  },
  {
    "time": "24-02-2015",
    "open": 162.49,
    "high": 164.99,
    "low": 161.51,
    "close": 164.83,
    "volume": 4701626
  },
  {
    "time": "23-02-2015",
    "open": 164.23,
    "high": 164.4,
    "low": 162.5,
    "close": 162.91,
    "volume": 2714434
  },
  {
    "time": "20-02-2015",
    "open": 163.64,
    "high": 164.34,
    "low": 162.09,
    "close": 163.65,
    "volume": 3352781
  },
  {
    "time": "19-02-2015",
    "open": 162.05,
    "high": 164.49,
    "low": 161.44,
    "close": 163.89,
    "volume": 3504269
  },
  {
    "time": "18-02-2015",
    "open": 161.86,
    "high": 162.47,
    "low": 160.64,
    "close": 162.19,
    "volume": 4358252
  },
  {
    "time": "17-02-2015",
    "open": 159.75,
    "high": 161.41,
    "low": 159.73,
    "close": 160.96,
    "volume": 2893590
  },
  {
    "time": "13-02-2015",
    "open": 158.78,
    "high": 160.8,
    "low": 158.64,
    "close": 160.4,
    "volume": 3706919
  },
  {
    "time": "12-02-2015",
    "open": 158.72,
    "high": 159.5,
    "low": 158.09,
    "close": 158.52,
    "volume": 3333063
  },
  {
    "time": "11-02-2015",
    "open": 157.76,
    "high": 159.09,
    "low": 157.17,
    "close": 158.2,
    "volume": 3626658
  },
  {
    "time": "10-02-2015",
    "open": 156.74,
    "high": 158.56,
    "low": 155.08,
    "close": 158.56,
    "volume": 4440614
  },
  {
    "time": "09-02-2015",
    "open": 156,
    "high": 157.5,
    "low": 155.4,
    "close": 155.75,
    "volume": 3057502
  },
  {
    "time": "06-02-2015",
    "open": 157.34,
    "high": 158.08,
    "low": 156.23,
    "close": 156.72,
    "volume": 3256440
  },
  {
    "time": "05-02-2015",
    "open": 157.29,
    "high": 158.59,
    "low": 157.1501,
    "close": 157.91,
    "volume": 5253628
  },
  {
    "time": "04-02-2015",
    "open": 157.21,
    "high": 158.71,
    "low": 156.7,
    "close": 156.96,
    "volume": 3678339
  },
  {
    "time": "03-02-2015",
    "open": 154.75,
    "high": 158.6,
    "low": 154.75,
    "close": 158.47,
    "volume": 5539406
  },
  {
    "time": "02-02-2015",
    "open": 154,
    "high": 154.66,
    "low": 151.51,
    "close": 154.66,
    "volume": 4712017
  },
  {
    "time": "30-01-2015",
    "open": 153.91,
    "high": 155.24,
    "low": 153.04,
    "close": 153.31,
    "volume": 6563447
  },
  {
    "time": "29-01-2015",
    "open": 151.38,
    "high": 155.58,
    "low": 149.52,
    "close": 155.48,
    "volume": 8320622
  },
  {
    "time": "28-01-2015",
    "open": 154,
    "high": 154.53,
    "low": 151.55,
    "close": 151.55,
    "volume": 4495633
  },
  {
    "time": "27-01-2015",
    "open": 154.94,
    "high": 155.09,
    "low": 152.59,
    "close": 153.67,
    "volume": 5659568
  },
  {
    "time": "26-01-2015",
    "open": 158.26,
    "high": 159.46,
    "low": 155.77,
    "close": 156.36,
    "volume": 7888037
  },
  {
    "time": "23-01-2015",
    "open": 155.03,
    "high": 157.6,
    "low": 154.89,
    "close": 155.87,
    "volume": 4834447
  },
  {
    "time": "22-01-2015",
    "open": 151.94,
    "high": 155.72,
    "low": 151.76,
    "close": 155.39,
    "volume": 6119523
  },
  {
    "time": "21-01-2015",
    "open": 153.03,
    "high": 154.5,
    "low": 151.07,
    "close": 152.09,
    "volume": 11896012
  },
  {
    "time": "20-01-2015",
    "open": 156.7,
    "high": 157.33,
    "low": 154.03,
    "close": 156.95,
    "volume": 8380815
  },
  {
    "time": "16-01-2015",
    "open": 153.82,
    "high": 157.63,
    "low": 153.82,
    "close": 157.14,
    "volume": 5756041
  },
  {
    "time": "15-01-2015",
    "open": 156.69,
    "high": 156.97,
    "low": 154.16,
    "close": 154.57,
    "volume": 4251698
  },
  {
    "time": "14-01-2015",
    "open": 154.86,
    "high": 156.49,
    "low": 153.74,
    "close": 155.8,
    "volume": 4689740
  },
  {
    "time": "13-01-2015",
    "open": 157.26,
    "high": 159.97,
    "low": 155.68,
    "close": 156.81,
    "volume": 4386989
  },
  {
    "time": "12-01-2015",
    "open": 159,
    "high": 159.25,
    "low": 155.76,
    "close": 156.44,
    "volume": 4187588
  },
  {
    "time": "09-01-2015",
    "open": 158.42,
    "high": 160.34,
    "low": 157.25,
    "close": 159.11,
    "volume": 4472701
  },
  {
    "time": "08-01-2015",
    "open": 156.24,
    "high": 159.044,
    "low": 155.55,
    "close": 158.42,
    "volume": 4240585
  },
  {
    "time": "07-01-2015",
    "open": 157.2,
    "high": 157.2,
    "low": 154.03,
    "close": 155.05,
    "volume": 4701015
  },
  {
    "time": "06-01-2015",
    "open": 159.67,
    "high": 159.96,
    "low": 155.17,
    "close": 156.07,
    "volume": 6145670
  },
  {
    "time": "05-01-2015",
    "open": 161.27,
    "high": 161.27,
    "low": 159.19,
    "close": 159.51,
    "volume": 4880389
  },
  {
    "time": "02-01-2015",
    "open": 161.31,
    "high": 163.31,
    "low": 161,
    "close": 162.06,
    "volume": 5525341
  },
  {
    "time": "31-12-2014",
    "open": 160.41,
    "high": 161.5,
    "low": 160.38,
    "close": 160.44,
    "volume": 4011909
  },
  {
    "time": "30-12-2014",
    "open": 160.02,
    "high": 160.82,
    "low": 159.79,
    "close": 160.05,
    "volume": 2829904
  },
  {
    "time": "29-12-2014",
    "open": 162,
    "high": 162.34,
    "low": 159.45,
    "close": 160.51,
    "volume": 3331833
  },
  {
    "time": "26-12-2014",
    "open": 162.27,
    "high": 163.09,
    "low": 162.01,
    "close": 162.34,
    "volume": 1912310
  },
  {
    "time": "24-12-2014",
    "open": 162.88,
    "high": 162.99,
    "low": 161.61,
    "close": 161.82,
    "volume": 1869977
  },
  {
    "time": "23-12-2014",
    "open": 162.23,
    "high": 162.9,
    "low": 161.61,
    "close": 162.24,
    "volume": 4044120
  },
  {
    "time": "22-12-2014",
    "open": 158.33,
    "high": 161.91,
    "low": 158.33,
    "close": 161.44,
    "volume": 4682509
  },
  {
    "time": "19-12-2014",
    "open": 157.49,
    "high": 160.41,
    "low": 157.49,
    "close": 158.51,
    "volume": 8864850
  },
  {
    "time": "18-12-2014",
    "open": 153.58,
    "high": 157.68,
    "low": 153.3,
    "close": 157.68,
    "volume": 7301971
  },
  {
    "time": "17-12-2014",
    "open": 151.52,
    "high": 153.308,
    "low": 151.11,
    "close": 151.93,
    "volume": 5139465
  },
  {
    "time": "16-12-2014",
    "open": 152.03,
    "high": 154.89,
    "low": 150.5,
    "close": 151.41,
    "volume": 6782173
  },
  {
    "time": "15-12-2014",
    "open": 155.93,
    "high": 156.739,
    "low": 152.84,
    "close": 153.06,
    "volume": 6500311
  },
  {
    "time": "12-12-2014",
    "open": 160.25,
    "high": 160.5,
    "low": 155.33,
    "close": 155.38,
    "volume": 8611249
  },
  {
    "time": "11-12-2014",
    "open": 160.82,
    "high": 162.93,
    "low": 160.52,
    "close": 161.07,
    "volume": 3987625
  },
  {
    "time": "10-12-2014",
    "open": 163.01,
    "high": 163.33,
    "low": 160.03,
    "close": 160.51,
    "volume": 4081714
  },
  {
    "time": "09-12-2014",
    "open": 160.84,
    "high": 163.02,
    "low": 160.8,
    "close": 162.99,
    "volume": 3865785
  },
  {
    "time": "08-12-2014",
    "open": 163.29,
    "high": 163.29,
    "low": 161.54,
    "close": 161.86,
    "volume": 2851383
  },
  {
    "time": "05-12-2014",
    "open": 163.61,
    "high": 164.5,
    "low": 162.91,
    "close": 163.27,
    "volume": 3013500
  },
  {
    "time": "04-12-2014",
    "open": 164.01,
    "high": 164.5,
    "low": 163.01,
    "close": 164.05,
    "volume": 3862816
  },
  {
    "time": "03-12-2014",
    "open": 162.47,
    "high": 164.52,
    "low": 162,
    "close": 164.52,
    "volume": 6434994
  },
  {
    "time": "02-12-2014",
    "open": 162.47,
    "high": 162.73,
    "low": 161.64,
    "close": 162.67,
    "volume": 3465747
  },
  {
    "time": "01-12-2014",
    "open": 161.64,
    "high": 163.315,
    "low": 161.35,
    "close": 161.54,
    "volume": 4168384
  },
  {
    "time": "28-11-2014",
    "open": 162.75,
    "high": 163.37,
    "low": 161.44,
    "close": 162.17,
    "volume": 2405512
  },
  {
    "time": "26-11-2014",
    "open": 161.93,
    "high": 162.1,
    "low": 161.01,
    "close": 161.95,
    "volume": 3967223
  },
  {
    "time": "25-11-2014",
    "open": 162.65,
    "high": 163.5,
    "low": 161.56,
    "close": 161.76,
    "volume": 4062378
  },
  {
    "time": "24-11-2014",
    "open": 161.54,
    "high": 163.86,
    "low": 161.06,
    "close": 162.15,
    "volume": 6618779
  },
  {
    "time": "21-11-2014",
    "open": 161.83,
    "high": 161.95,
    "low": 160.75,
    "close": 160.92,
    "volume": 4076868
  },
  {
    "time": "20-11-2014",
    "open": 160.95,
    "high": 161.5,
    "low": 159.8,
    "close": 160.64,
    "volume": 4182746
  },
  {
    "time": "19-11-2014",
    "open": 162.05,
    "high": 162.1,
    "low": 160.96,
    "close": 161.43,
    "volume": 3802175
  },
  {
    "time": "18-11-2014",
    "open": 164.73,
    "high": 164.75,
    "low": 161.89,
    "close": 161.89,
    "volume": 5412495
  },
  {
    "time": "17-11-2014",
    "open": 164.16,
    "high": 164.97,
    "low": 163.72,
    "close": 164.16,
    "volume": 4798933
  },
  {
    "time": "14-11-2014",
    "open": 162.1,
    "high": 164.49,
    "low": 161.69,
    "close": 164.16,
    "volume": 4976697
  },
  {
    "time": "13-11-2014",
    "open": 162,
    "high": 162.8,
    "low": 161.8,
    "close": 162.79,
    "volume": 3239726
  },
  {
    "time": "12-11-2014",
    "open": 162.28,
    "high": 163,
    "low": 161.76,
    "close": 161.92,
    "volume": 3377798
  },
  {
    "time": "11-11-2014",
    "open": 163.7,
    "high": 163.9,
    "low": 162.6,
    "close": 163.3,
    "volume": 3535694
  },
  {
    "time": "10-11-2014",
    "open": 161.9,
    "high": 164.47,
    "low": 161.61,
    "close": 163.49,
    "volume": 4958236
  },
  {
    "time": "07-11-2014",
    "open": 161.42,
    "high": 162.2069,
    "low": 160.85,
    "close": 162.07,
    "volume": 3494803
  },
  {
    "time": "06-11-2014",
    "open": 161.28,
    "high": 161.53,
    "low": 160.05,
    "close": 161.46,
    "volume": 4067587
  },
  {
    "time": "05-11-2014",
    "open": 163.13,
    "high": 163.54,
    "low": 161.56,
    "close": 161.82,
    "volume": 4103003
  },
  {
    "time": "04-11-2014",
    "open": 164.34,
    "high": 164.36,
    "low": 162.24,
    "close": 162.65,
    "volume": 4246854
  },
  {
    "time": "03-11-2014",
    "open": 164.25,
    "high": 164.54,
    "low": 163.375,
    "close": 164.36,
    "volume": 4691074
  },
  {
    "time": "31-10-2014",
    "open": 165.48,
    "high": 165.59,
    "low": 163.6165,
    "close": 164.4,
    "volume": 5818375
  },
  {
    "time": "30-10-2014",
    "open": 163.5,
    "high": 164.62,
    "low": 163.02,
    "close": 164.35,
    "volume": 3895979
  },
  {
    "time": "29-10-2014",
    "open": 164.33,
    "high": 164.615,
    "low": 162.76,
    "close": 163.46,
    "volume": 4741689
  },
  {
    "time": "28-10-2014",
    "open": 162,
    "high": 163.6,
    "low": 161.8,
    "close": 163.6,
    "volume": 7896863
  },
  {
    "time": "27-10-2014",
    "open": 162,
    "high": 162.91,
    "low": 161.81,
    "close": 161.87,
    "volume": 4989213
  },
  {
    "time": "24-10-2014",
    "open": 162.08,
    "high": 162.44,
    "low": 161.45,
    "close": 162.08,
    "volume": 6652321
  },
  {
    "time": "23-10-2014",
    "open": 162.12,
    "high": 162.83,
    "low": 161.54,
    "close": 162.18,
    "volume": 7599510
  },
  {
    "time": "22-10-2014",
    "open": 162.41,
    "high": 165.41,
    "low": 161.1,
    "close": 161.79,
    "volume": 11088493
  },
  {
    "time": "21-10-2014",
    "open": 166.4,
    "high": 166.68,
    "low": 161.68,
    "close": 163.23,
    "volume": 20952120
  },
  {
    "time": "20-10-2014",
    "open": 166.845,
    "high": 170.331,
    "low": 166.69,
    "close": 169.1,
    "volume": 23408902
  },
  {
    "time": "17-10-2014",
    "open": 181.24,
    "high": 182.84,
    "low": 180.22,
    "close": 182.05,
    "volume": 4354442
  },
  {
    "time": "16-10-2014",
    "open": 179.8,
    "high": 181.48,
    "low": 178.69,
    "close": 179.84,
    "volume": 5578572
  },
  {
    "time": "15-10-2014",
    "open": 182.46,
    "high": 183.79,
    "low": 178.7488,
    "close": 181.75,
    "volume": 6897656
  },
  {
    "time": "14-10-2014",
    "open": 184.89,
    "high": 185.72,
    "low": 183.59,
    "close": 183.8,
    "volume": 3924974
  },
  {
    "time": "13-10-2014",
    "open": 185.49,
    "high": 186.65,
    "low": 183.42,
    "close": 183.52,
    "volume": 3599924
  },
  {
    "time": "10-10-2014",
    "open": 185.86,
    "high": 187.74,
    "low": 185.1,
    "close": 185.93,
    "volume": 5090177
  },
  {
    "time": "09-10-2014",
    "open": 189.12,
    "high": 189.5,
    "low": 186.09,
    "close": 186.42,
    "volume": 2625509
  },
  {
    "time": "08-10-2014",
    "open": 185.97,
    "high": 189.6,
    "low": 185.61,
    "close": 189.36,
    "volume": 2985329
  },
  {
    "time": "07-10-2014",
    "open": 187.77,
    "high": 188.12,
    "low": 185.54,
    "close": 185.71,
    "volume": 2994065
  },
  {
    "time": "06-10-2014",
    "open": 189.69,
    "high": 190.89,
    "low": 188.71,
    "close": 189.04,
    "volume": 2100191
  },
  {
    "time": "03-10-2014",
    "open": 188.11,
    "high": 189.37,
    "low": 187.56,
    "close": 188.67,
    "volume": 3071521
  },
  {
    "time": "02-10-2014",
    "open": 187.66,
    "high": 187.78,
    "low": 186.24,
    "close": 186.91,
    "volume": 2283619
  },
  {
    "time": "01-10-2014",
    "open": 189.91,
    "high": 190.4,
    "low": 186.79,
    "close": 187.17,
    "volume": 3723166
  },
  {
    "time": "30-09-2014",
    "open": 189.64,
    "high": 190.85,
    "low": 189.15,
    "close": 189.83,
    "volume": 2929335
  },
  {
    "time": "29-09-2014",
    "open": 188.51,
    "high": 189.96,
    "low": 188.12,
    "close": 189.64,
    "volume": 2344723
  },
  {
    "time": "26-09-2014",
    "open": 188.93,
    "high": 190.33,
    "low": 188.61,
    "close": 190.06,
    "volume": 2494024
  },
  {
    "time": "25-09-2014",
    "open": 192.05,
    "high": 192.5,
    "low": 188.97,
    "close": 189.01,
    "volume": 4114751
  },
  {
    "time": "24-09-2014",
    "open": 191,
    "high": 192.45,
    "low": 189.88,
    "close": 192.31,
    "volume": 3076722
  },
  {
    "time": "23-09-2014",
    "open": 192.75,
    "high": 193.07,
    "low": 191.52,
    "close": 191.62,
    "volume": 3257449
  },
  {
    "time": "22-09-2014",
    "open": 193.72,
    "high": 194.14,
    "low": 192.63,
    "close": 193.11,
    "volume": 3266955
  },
  {
    "time": "19-09-2014",
    "open": 194.54,
    "high": 195,
    "low": 193.19,
    "close": 194,
    "volume": 8852000
  },
  {
    "time": "18-09-2014",
    "open": 192.79,
    "high": 194.13,
    "low": 192.71,
    "close": 193.75,
    "volume": 2963300
  },
  {
    "time": "17-09-2014",
    "open": 193.22,
    "high": 193.64,
    "low": 192.3,
    "close": 192.8,
    "volume": 3210800
  },
  {
    "time": "16-09-2014",
    "open": 191.25,
    "high": 193.57,
    "low": 190.82,
    "close": 192.96,
    "volume": 2561500
  },
  {
    "time": "15-09-2014",
    "open": 191.42,
    "high": 192.49,
    "low": 190.58,
    "close": 191.81,
    "volume": 2456400
  },
  {
    "time": "12-09-2014",
    "open": 191.47,
    "high": 191.6,
    "low": 190.57,
    "close": 191.28,
    "volume": 2901000
  },
  {
    "time": "11-09-2014",
    "open": 191.08,
    "high": 192.78,
    "low": 190.12,
    "close": 191.72,
    "volume": 2298500
  },
  {
    "time": "10-09-2014",
    "open": 190.12,
    "high": 192.15,
    "low": 190.1,
    "close": 191.54,
    "volume": 2764000
  },
  {
    "time": "09-09-2014",
    "open": 190.34,
    "high": 190.74,
    "low": 189.78,
    "close": 189.99,
    "volume": 2390400
  },
  {
    "time": "08-09-2014",
    "open": 190.75,
    "high": 191.2,
    "low": 189.51,
    "close": 190.14,
    "volume": 2524200
  },
  {
    "time": "05-09-2014",
    "open": 190.53,
    "high": 191.75,
    "low": 190.09,
    "close": 191.2,
    "volume": 2260200
  },
  {
    "time": "04-09-2014",
    "open": 191.69,
    "high": 192.69,
    "low": 190.06,
    "close": 190.68,
    "volume": 2865000
  },
  {
    "time": "03-09-2014",
    "open": 192.39,
    "high": 192.95,
    "low": 191.86,
    "close": 191.95,
    "volume": 1822800
  },
  {
    "time": "02-09-2014",
    "open": 192.68,
    "high": 192.97,
    "low": 190.93,
    "close": 191.56,
    "volume": 2679700
  },
  {
    "time": "29-08-2014",
    "open": 192.28,
    "high": 192.75,
    "low": 191.14,
    "close": 192.3,
    "volume": 2909400
  },
  {
    "time": "28-08-2014",
    "open": 191.47,
    "high": 192.2986,
    "low": 190.66,
    "close": 192,
    "volume": 1503600
  },
  {
    "time": "27-08-2014",
    "open": 193.03,
    "high": 194.133,
    "low": 191.56,
    "close": 192.25,
    "volume": 2190200
  },
  {
    "time": "26-08-2014",
    "open": 191.02,
    "high": 193.53,
    "low": 191.02,
    "close": 192.99,
    "volume": 3160900
  },
  {
    "time": "25-08-2014",
    "open": 191.39,
    "high": 191.81,
    "low": 190.71,
    "close": 191.16,
    "volume": 1723600
  },
  {
    "time": "22-08-2014",
    "open": 190.77,
    "high": 191.88,
    "low": 190.29,
    "close": 190.41,
    "volume": 1940300
  },
  {
    "time": "21-08-2014",
    "open": 190.9,
    "high": 192.07,
    "low": 190.5,
    "close": 191.23,
    "volume": 2496500
  },
  {
    "time": "20-08-2014",
    "open": 189.86,
    "high": 190.39,
    "low": 189.25,
    "close": 190.1,
    "volume": 2177600
  },
  {
    "time": "19-08-2014",
    "open": 190.04,
    "high": 190.25,
    "low": 189.36,
    "close": 190.07,
    "volume": 2040100
  },
  {
    "time": "18-08-2014",
    "open": 188.1,
    "high": 189.81,
    "low": 187.76,
    "close": 189.36,
    "volume": 2420300
  },
  {
    "time": "15-08-2014",
    "open": 187.85,
    "high": 188.88,
    "low": 186.79,
    "close": 187.38,
    "volume": 2814800
  },
  {
    "time": "14-08-2014",
    "open": 187.57,
    "high": 188.17,
    "low": 186.93,
    "close": 187.88,
    "volume": 1929500
  },
  {
    "time": "13-08-2014",
    "open": 188,
    "high": 188.48,
    "low": 187.37,
    "close": 187.95,
    "volume": 1797000
  },
  {
    "time": "12-08-2014",
    "open": 187.45,
    "high": 188.69,
    "low": 186.81,
    "close": 187.34,
    "volume": 1858600
  },
  {
    "time": "11-08-2014",
    "open": 187.81,
    "high": 188.36,
    "low": 186.28,
    "close": 187.47,
    "volume": 2527200
  },
  {
    "time": "08-08-2014",
    "open": 184.4,
    "high": 186.67,
    "low": 183.58,
    "close": 186.63,
    "volume": 2781500
  },
  {
    "time": "07-08-2014",
    "open": 186.64,
    "high": 186.68,
    "low": 183.58,
    "close": 184.3,
    "volume": 2708600
  },
  {
    "time": "06-08-2014",
    "open": 185.36,
    "high": 186.88,
    "low": 184.44,
    "close": 185.97,
    "volume": 3847000
  },
  {
    "time": "05-08-2014",
    "open": 188.75,
    "high": 189.196,
    "low": 186.44,
    "close": 187.1,
    "volume": 3307900
  },
  {
    "time": "04-08-2014",
    "open": 189.35,
    "high": 189.95,
    "low": 188.6,
    "close": 189.64,
    "volume": 2125900
  },
  {
    "time": "01-08-2014",
    "open": 190.5,
    "high": 191.5,
    "low": 188.86,
    "close": 189.15,
    "volume": 5181100
  },
  {
    "time": "31-07-2014",
    "open": 192.84,
    "high": 194.29,
    "low": 191.54,
    "close": 191.67,
    "volume": 4207500
  },
  {
    "time": "30-07-2014",
    "open": 195.2,
    "high": 195.99,
    "low": 192.9,
    "close": 194,
    "volume": 3943800
  },
  {
    "time": "29-07-2014",
    "open": 195.3,
    "high": 195.89,
    "low": 194.54,
    "close": 194.57,
    "volume": 3264100
  },
  {
    "time": "28-07-2014",
    "open": 194.3,
    "high": 196.4,
    "low": 193.65,
    "close": 195.78,
    "volume": 3242400
  },
  {
    "time": "25-07-2014",
    "open": 195.3,
    "high": 195.9,
    "low": 193.7901,
    "close": 194.4,
    "volume": 3376400
  },
  {
    "time": "24-07-2014",
    "open": 193.95,
    "high": 195.62,
    "low": 193.75,
    "close": 195.24,
    "volume": 3612700
  },
  {
    "time": "23-07-2014",
    "open": 194.11,
    "high": 194.9,
    "low": 193.57,
    "close": 193.63,
    "volume": 3584200
  },
  {
    "time": "22-07-2014",
    "open": 191.59,
    "high": 194.72,
    "low": 191.56,
    "close": 194.09,
    "volume": 4853000
  },
  {
    "time": "21-07-2014",
    "open": 191.3,
    "high": 191.7,
    "low": 189.25,
    "close": 190.85,
    "volume": 4154400
  },
  {
    "time": "18-07-2014",
    "open": 191.96,
    "high": 193.44,
    "low": 190,
    "close": 192.5,
    "volume": 8166400
  },
  {
    "time": "17-07-2014",
    "open": 192.36,
    "high": 195.95,
    "low": 192,
    "close": 192.49,
    "volume": 8872300
  },
  {
    "time": "16-07-2014",
    "open": 192.24,
    "high": 193.36,
    "low": 190.76,
    "close": 192.36,
    "volume": 7812600
  },
  {
    "time": "15-07-2014",
    "open": 189.54,
    "high": 190.08,
    "low": 188.21,
    "close": 188.49,
    "volume": 4864700
  },
  {
    "time": "14-07-2014",
    "open": 188.55,
    "high": 190.44,
    "low": 188.53,
    "close": 189.86,
    "volume": 4501100
  },
  {
    "time": "11-07-2014",
    "open": 187.73,
    "high": 188.35,
    "low": 186.705,
    "close": 188,
    "volume": 2402500
  },
  {
    "time": "10-07-2014",
    "open": 186.44,
    "high": 188.05,
    "low": 186.21,
    "close": 187.7,
    "volume": 3177800
  },
  {
    "time": "09-07-2014",
    "open": 187.68,
    "high": 188.9,
    "low": 186.89,
    "close": 188.42,
    "volume": 3309600
  },
  {
    "time": "08-07-2014",
    "open": 187.65,
    "high": 188.08,
    "low": 186.37,
    "close": 187.22,
    "volume": 3135100
  },
  {
    "time": "07-07-2014",
    "open": 187.61,
    "high": 188.27,
    "low": 187.44,
    "close": 188.04,
    "volume": 2958700
  },
  {
    "time": "03-07-2014",
    "open": 188.39,
    "high": 188.806,
    "low": 187.35,
    "close": 188.53,
    "volume": 2422400
  },
  {
    "time": "02-07-2014",
    "open": 186.34,
    "high": 188.99,
    "low": 186.17,
    "close": 188.39,
    "volume": 5093000
  },
  {
    "time": "01-07-2014",
    "open": 181.7,
    "high": 187.27,
    "low": 181.7,
    "close": 186.35,
    "volume": 6643100
  },
  {
    "time": "30-06-2014",
    "open": 181.33,
    "high": 181.93,
    "low": 180.26,
    "close": 181.27,
    "volume": 4223800
  },
  {
    "time": "27-06-2014",
    "open": 179.77,
    "high": 182.46,
    "low": 179.66,
    "close": 181.71,
    "volume": 4575500
  },
  {
    "time": "26-06-2014",
    "open": 180.87,
    "high": 181.37,
    "low": 179.27,
    "close": 180.37,
    "volume": 3258500
  },
  {
    "time": "25-06-2014",
    "open": 180.25,
    "high": 180.97,
    "low": 180.06,
    "close": 180.72,
    "volume": 2762800
  },
  {
    "time": "24-06-2014",
    "open": 181.5,
    "high": 183,
    "low": 180.65,
    "close": 180.88,
    "volume": 3875400
  },
  {
    "time": "23-06-2014",
    "open": 181.92,
    "high": 182.25,
    "low": 181,
    "close": 182.14,
    "volume": 3231700
  },
  {
    "time": "20-06-2014",
    "open": 182.59,
    "high": 182.67,
    "low": 181.4,
    "close": 181.55,
    "volume": 10686800
  },
  {
    "time": "19-06-2014",
    "open": 184.12,
    "high": 184.47,
    "low": 182.356,
    "close": 182.82,
    "volume": 3551100
  },
  {
    "time": "18-06-2014",
    "open": 182.04,
    "high": 183.61,
    "low": 181.79,
    "close": 183.6,
    "volume": 3931800
  },
  {
    "time": "17-06-2014",
    "open": 181.9,
    "high": 182.81,
    "low": 181.56,
    "close": 182.26,
    "volume": 2460900
  },
  {
    "time": "16-06-2014",
    "open": 182.4,
    "high": 182.71,
    "low": 181.235,
    "close": 182.35,
    "volume": 3556200
  },
  {
    "time": "13-06-2014",
    "open": 182,
    "high": 183,
    "low": 181.52,
    "close": 182.56,
    "volume": 2773600
  },
  {
    "time": "12-06-2014",
    "open": 182.48,
    "high": 182.55,
    "low": 180.91,
    "close": 181.22,
    "volume": 4425300
  },
  {
    "time": "11-06-2014",
    "open": 183.61,
    "high": 184.2,
    "low": 182.01,
    "close": 182.25,
    "volume": 4061700
  },
  {
    "time": "10-06-2014",
    "open": 186.2,
    "high": 186.2199,
    "low": 183.82,
    "close": 184.29,
    "volume": 4154900
  },
  {
    "time": "09-06-2014",
    "open": 186.22,
    "high": 187.64,
    "low": 185.96,
    "close": 186.22,
    "volume": 2728400
  },
  {
    "time": "06-06-2014",
    "open": 186.47,
    "high": 187.65,
    "low": 185.9,
    "close": 186.37,
    "volume": 3296700
  },
  {
    "time": "05-06-2014",
    "open": 184.66,
    "high": 186.09,
    "low": 183.92,
    "close": 185.98,
    "volume": 2852200
  },
  {
    "time": "04-06-2014",
    "open": 184.71,
    "high": 185.45,
    "low": 184.2,
    "close": 184.51,
    "volume": 2376800
  },
  {
    "time": "03-06-2014",
    "open": 185.55,
    "high": 185.76,
    "low": 184.12,
    "close": 184.37,
    "volume": 2517100
  },
  {
    "time": "02-06-2014",
    "open": 184.76,
    "high": 186.2801,
    "low": 184.67,
    "close": 185.69,
    "volume": 3200500
  },
  {
    "time": "30-05-2014",
    "open": 183.38,
    "high": 184.57,
    "low": 182.49,
    "close": 184.36,
    "volume": 4620500
  },
  {
    "time": "29-05-2014",
    "open": 183.64,
    "high": 183.78,
    "low": 182.33,
    "close": 183.76,
    "volume": 2759900
  },
  {
    "time": "28-05-2014",
    "open": 184.48,
    "high": 184.88,
    "low": 182.94,
    "close": 183.08,
    "volume": 3721600
  },
  {
    "time": "27-05-2014",
    "open": 184.8,
    "high": 185.65,
    "low": 183.93,
    "close": 184.78,
    "volume": 4793800
  },
  {
    "time": "23-05-2014",
    "open": 185.84,
    "high": 186.14,
    "low": 185.3101,
    "close": 185.94,
    "volume": 2562900
  },
  {
    "time": "22-05-2014",
    "open": 186.45,
    "high": 186.45,
    "low": 185.03,
    "close": 185.68,
    "volume": 2218000
  },
  {
    "time": "21-05-2014",
    "open": 185.85,
    "high": 187.16,
    "low": 184.95,
    "close": 186.39,
    "volume": 2988000
  },
  {
    "time": "20-05-2014",
    "open": 186.25,
    "high": 186.7,
    "low": 184.7,
    "close": 184.89,
    "volume": 3026500
  },
  {
    "time": "19-05-2014",
    "open": 186.61,
    "high": 187.13,
    "low": 186.04,
    "close": 187,
    "volume": 3270700
  },
  {
    "time": "16-05-2014",
    "open": 186.27,
    "high": 187.41,
    "low": 185.93,
    "close": 187.06,
    "volume": 3949500
  },
  {
    "time": "15-05-2014",
    "open": 189.07,
    "high": 189.08,
    "low": 186.18,
    "close": 186.46,
    "volume": 4202200
  },
  {
    "time": "14-05-2014",
    "open": 191.91,
    "high": 191.99,
    "low": 188.17,
    "close": 188.72,
    "volume": 5251500
  },
  {
    "time": "13-05-2014",
    "open": 192.79,
    "high": 193.03,
    "low": 191.76,
    "close": 192.19,
    "volume": 2226600
  },
  {
    "time": "12-05-2014",
    "open": 191.12,
    "high": 193.02,
    "low": 190.94,
    "close": 192.57,
    "volume": 3777400
  },
  {
    "time": "09-05-2014",
    "open": 188.98,
    "high": 190.5854,
    "low": 188.37,
    "close": 190.08,
    "volume": 2261600
  },
  {
    "time": "08-05-2014",
    "open": 189.16,
    "high": 190.57,
    "low": 188.23,
    "close": 188.91,
    "volume": 2431900
  },
  {
    "time": "07-05-2014",
    "open": 189.34,
    "high": 190.3,
    "low": 186.93,
    "close": 189.3,
    "volume": 3821000
  },
  {
    "time": "06-05-2014",
    "open": 190.93,
    "high": 190.95,
    "low": 189.25,
    "close": 190.03,
    "volume": 3188700
  },
  {
    "time": "05-05-2014",
    "open": 191.05,
    "high": 191.49,
    "low": 189.8,
    "close": 191.26,
    "volume": 2222100
  },
  {
    "time": "02-05-2014",
    "open": 193.75,
    "high": 193.84,
    "low": 191.25,
    "close": 191.44,
    "volume": 3049900
  },
  {
    "time": "01-05-2014",
    "open": 196.31,
    "high": 196.74,
    "low": 193.15,
    "close": 193.53,
    "volume": 3674000
  },
  {
    "time": "30-04-2014",
    "open": 194.38,
    "high": 196.86,
    "low": 194.35,
    "close": 196.47,
    "volume": 4206800
  },
  {
    "time": "29-04-2014",
    "open": 193.59,
    "high": 195.94,
    "low": 193.41,
    "close": 195.11,
    "volume": 4626300
  },
  {
    "time": "28-04-2014",
    "open": 191.14,
    "high": 193.99,
    "low": 190.58,
    "close": 193.14,
    "volume": 5638300
  },
  {
    "time": "25-04-2014",
    "open": 190.54,
    "high": 190.67,
    "low": 189.11,
    "close": 189.63,
    "volume": 3871000
  },
  {
    "time": "24-04-2014",
    "open": 192.22,
    "high": 192.22,
    "low": 190.03,
    "close": 190.22,
    "volume": 4407100
  },
  {
    "time": "23-04-2014",
    "open": 191.79,
    "high": 192.41,
    "low": 190.11,
    "close": 191.73,
    "volume": 4736400
  },
  {
    "time": "22-04-2014",
    "open": 192.01,
    "high": 193,
    "low": 191.2,
    "close": 192.15,
    "volume": 3878600
  },
  {
    "time": "21-04-2014",
    "open": 189.8,
    "high": 192.805,
    "low": 189.74,
    "close": 192.27,
    "volume": 5419700
  },
  {
    "time": "17-04-2014",
    "open": 187.25,
    "high": 190.7,
    "low": 187.01,
    "close": 190.01,
    "volume": 11255500
  },
  {
    "time": "16-04-2014",
    "open": 198.05,
    "high": 198.71,
    "low": 195,
    "close": 196.4,
    "volume": 8527300
  },
  {
    "time": "15-04-2014",
    "open": 195.98,
    "high": 197.41,
    "low": 195.42,
    "close": 197.02,
    "volume": 5351300
  },
  {
    "time": "14-04-2014",
    "open": 196.24,
    "high": 198.24,
    "low": 195.88,
    "close": 197.77,
    "volume": 5431100
  },
  {
    "time": "11-04-2014",
    "open": 195.04,
    "high": 197,
    "low": 194.27,
    "close": 195.19,
    "volume": 4835000
  },
  {
    "time": "10-04-2014",
    "open": 196.06,
    "high": 199.21,
    "low": 195.66,
    "close": 195.68,
    "volume": 8417900
  },
  {
    "time": "09-04-2014",
    "open": 193.88,
    "high": 197,
    "low": 193.6,
    "close": 196.64,
    "volume": 5480100
  },
  {
    "time": "08-04-2014",
    "open": 193.89,
    "high": 194.1675,
    "low": 192.6898,
    "close": 193.29,
    "volume": 5125000
  },
  {
    "time": "07-04-2014",
    "open": 191.72,
    "high": 195.309,
    "low": 191.57,
    "close": 194.52,
    "volume": 6740500
  },
  {
    "time": "04-04-2014",
    "open": 193.12,
    "high": 193.97,
    "low": 191.28,
    "close": 191.77,
    "volume": 6089900
  },
  {
    "time": "03-04-2014",
    "open": 193.2,
    "high": 193.38,
    "low": 192.35,
    "close": 192.69,
    "volume": 4091100
  },
  {
    "time": "02-04-2014",
    "open": 194.24,
    "high": 194.5,
    "low": 192.49,
    "close": 193.55,
    "volume": 4924100
  },
  {
    "time": "01-04-2014",
    "open": 193.12,
    "high": 195.13,
    "low": 192.78,
    "close": 194.5,
    "volume": 5394100
  },
  {
    "time": "31-03-2014",
    "open": 191.64,
    "high": 193.77,
    "low": 191.4,
    "close": 192.49,
    "volume": 8537300
  },
  {
    "time": "28-03-2014",
    "open": 189.94,
    "high": 192.62,
    "low": 189.11,
    "close": 190.45,
    "volume": 5194400
  },
  {
    "time": "27-03-2014",
    "open": 191.91,
    "high": 192.67,
    "low": 189.322,
    "close": 189.83,
    "volume": 6767700
  },
  {
    "time": "26-03-2014",
    "open": 194.98,
    "high": 195.63,
    "low": 191.96,
    "close": 192.62,
    "volume": 6851700
  },
  {
    "time": "25-03-2014",
    "open": 188.76,
    "high": 195.395,
    "low": 188.7,
    "close": 195.04,
    "volume": 10912700
  },
  {
    "time": "24-03-2014",
    "open": 187.43,
    "high": 189.44,
    "low": 187.37,
    "close": 188.25,
    "volume": 5738500
  },
  {
    "time": "21-03-2014",
    "open": 188.5,
    "high": 188.7,
    "low": 186.4,
    "close": 186.67,
    "volume": 12535200
  },
  {
    "time": "20-03-2014",
    "open": 184.47,
    "high": 188.26,
    "low": 184.17,
    "close": 187.9,
    "volume": 5062600
  },
  {
    "time": "19-03-2014",
    "open": 185.6,
    "high": 186.7,
    "low": 183.5001,
    "close": 184.71,
    "volume": 4323900
  },
  {
    "time": "18-03-2014",
    "open": 185.99,
    "high": 187.9,
    "low": 185.54,
    "close": 186.81,
    "volume": 3961300
  },
  {
    "time": "17-03-2014",
    "open": 182.66,
    "high": 186.35,
    "low": 182.66,
    "close": 185.81,
    "volume": 4938100
  },
  {
    "time": "14-03-2014",
    "open": 183.89,
    "high": 184.29,
    "low": 182.21,
    "close": 182.21,
    "volume": 5490300
  },
  {
    "time": "13-03-2014",
    "open": 186.41,
    "high": 187,
    "low": 183.71,
    "close": 183.9,
    "volume": 4913200
  },
  {
    "time": "12-03-2014",
    "open": 186.01,
    "high": 187.45,
    "low": 185.89,
    "close": 186.22,
    "volume": 4833000
  },
  {
    "time": "11-03-2014",
    "open": 187.41,
    "high": 188.45,
    "low": 186.04,
    "close": 186.76,
    "volume": 4229500
  },
  {
    "time": "10-03-2014",
    "open": 187.55,
    "high": 188.37,
    "low": 185.849,
    "close": 186.39,
    "volume": 4591200
  },
  {
    "time": "07-03-2014",
    "open": 188.35,
    "high": 188.92,
    "low": 187.18,
    "close": 187.68,
    "volume": 4117800
  },
  {
    "time": "06-03-2014",
    "open": 187.37,
    "high": 188.39,
    "low": 186.9,
    "close": 187.64,
    "volume": 3817300
  },
  {
    "time": "05-03-2014",
    "open": 186.57,
    "high": 187.95,
    "low": 186.4,
    "close": 187.14,
    "volume": 3485200
  },
  {
    "time": "04-03-2014",
    "open": 185.81,
    "high": 187.16,
    "low": 185.68,
    "close": 186.44,
    "volume": 4733000
  },
  {
    "time": "03-03-2014",
    "open": 183.33,
    "high": 184.64,
    "low": 182.82,
    "close": 184.26,
    "volume": 3950100
  },
  {
    "time": "28-02-2014",
    "open": 185.38,
    "high": 186.12,
    "low": 183.65,
    "close": 185.17,
    "volume": 4667900
  },
  {
    "time": "27-02-2014",
    "open": 183.83,
    "high": 185.95,
    "low": 183.81,
    "close": 185.27,
    "volume": 3827800
  },
  {
    "time": "26-02-2014",
    "open": 183.94,
    "high": 185.28,
    "low": 183.56,
    "close": 184.06,
    "volume": 3740700
  },
  {
    "time": "25-02-2014",
    "open": 183.52,
    "high": 184.6,
    "low": 182.88,
    "close": 183.23,
    "volume": 3902300
  },
  {
    "time": "24-02-2014",
    "open": 182.82,
    "high": 185.16,
    "low": 182.82,
    "close": 183.45,
    "volume": 4595300
  },
  {
    "time": "21-02-2014",
    "open": 184.25,
    "high": 185.71,
    "low": 182.62,
    "close": 182.79,
    "volume": 5699300
  },
  {
    "time": "20-02-2014",
    "open": 182.96,
    "high": 184.8599,
    "low": 182.79,
    "close": 184.26,
    "volume": 4968200
  },
  {
    "time": "19-02-2014",
    "open": 182.74,
    "high": 185.65,
    "low": 182.41,
    "close": 182.95,
    "volume": 4671500
  },
  {
    "time": "18-02-2014",
    "open": 183.18,
    "high": 184,
    "low": 182.32,
    "close": 183.19,
    "volume": 4435200
  },
  {
    "time": "14-02-2014",
    "open": 181.26,
    "high": 184.43,
    "low": 180.99,
    "close": 183.69,
    "volume": 4659900
  },
  {
    "time": "13-02-2014",
    "open": 178.22,
    "high": 182.36,
    "low": 177.86,
    "close": 181.84,
    "volume": 4482000
  },
  {
    "time": "12-02-2014",
    "open": 179.52,
    "high": 181.25,
    "low": 179.26,
    "close": 180.24,
    "volume": 3983000
  },
  {
    "time": "11-02-2014",
    "open": 176.81,
    "high": 180.39,
    "low": 176.8,
    "close": 179.7,
    "volume": 4647300
  },
  {
    "time": "10-02-2014",
    "open": 176.97,
    "high": 177.65,
    "low": 176.25,
    "close": 177.14,
    "volume": 3540400
  },
  {
    "time": "07-02-2014",
    "open": 175.64,
    "high": 177.56,
    "low": 175.07,
    "close": 177.25,
    "volume": 4692900
  },
  {
    "time": "06-02-2014",
    "open": 173.97,
    "high": 174.85,
    "low": 173.79,
    "close": 174.67,
    "volume": 4292200
  },
  {
    "time": "05-02-2014",
    "open": 172.19,
    "high": 174.97,
    "low": 172.19,
    "close": 174.24,
    "volume": 4712300
  },
  {
    "time": "04-02-2014",
    "open": 173.53,
    "high": 173.75,
    "low": 172.36,
    "close": 172.84,
    "volume": 4349800
  },
  {
    "time": "03-02-2014",
    "open": 176.02,
    "high": 176.02,
    "low": 172.72,
    "close": 172.9,
    "volume": 7186800
  },
  {
    "time": "31-01-2014",
    "open": 176.11,
    "high": 177.835,
    "low": 175.34,
    "close": 176.68,
    "volume": 5193400
  },
  {
    "time": "30-01-2014",
    "open": 177.17,
    "high": 177.86,
    "low": 176.36,
    "close": 177.36,
    "volume": 4853700
  },
  {
    "time": "29-01-2014",
    "open": 175.98,
    "high": 178.53,
    "low": 175.89,
    "close": 176.4,
    "volume": 4970900
  },
  {
    "time": "28-01-2014",
    "open": 178.05,
    "high": 178.45,
    "low": 176.16,
    "close": 176.85,
    "volume": 5333300
  },
  {
    "time": "27-01-2014",
    "open": 179.605,
    "high": 179.65,
    "low": 177.66,
    "close": 177.9,
    "volume": 5208600
  },
  {
    "time": "24-01-2014",
    "open": 181.25,
    "high": 182.8141,
    "low": 179.64,
    "close": 179.64,
    "volume": 6890600
  },
  {
    "time": "23-01-2014",
    "open": 181.43,
    "high": 183.72,
    "low": 180.71,
    "close": 182.73,
    "volume": 6973900
  },
  {
    "time": "22-01-2014",
    "open": 181.28,
    "high": 183.5,
    "low": 179.67,
    "close": 182.25,
    "volume": 13796100
  },
  {
    "time": "21-01-2014",
    "open": 190.23,
    "high": 190.39,
    "low": 186.79,
    "close": 188.43,
    "volume": 11076300
  },
  {
    "time": "17-01-2014",
    "open": 188.04,
    "high": 190.81,
    "low": 187.86,
    "close": 190.09,
    "volume": 7644600
  },
  {
    "time": "16-01-2014",
    "open": 187.53,
    "high": 188.99,
    "low": 186.8,
    "close": 188.76,
    "volume": 4770300
  },
  {
    "time": "15-01-2014",
    "open": 185.82,
    "high": 188.65,
    "low": 185.49,
    "close": 187.74,
    "volume": 4816500
  },
  {
    "time": "14-01-2014",
    "open": 185.06,
    "high": 186.428,
    "low": 183.8818,
    "close": 185.92,
    "volume": 4619200
  },
  {
    "time": "13-01-2014",
    "open": 186.26,
    "high": 186.95,
    "low": 183.86,
    "close": 184.16,
    "volume": 5784700
  },
  {
    "time": "10-01-2014",
    "open": 188.31,
    "high": 188.57,
    "low": 186.28,
    "close": 187.26,
    "volume": 4022400
  },
  {
    "time": "09-01-2014",
    "open": 189.02,
    "high": 189.5,
    "low": 186.55,
    "close": 187.38,
    "volume": 4321300
  },
  {
    "time": "08-01-2014",
    "open": 189.33,
    "high": 189.4175,
    "low": 187.26,
    "close": 187.97,
    "volume": 4603700
  },
  {
    "time": "07-01-2014",
    "open": 186.39,
    "high": 190.35,
    "low": 186.38,
    "close": 189.71,
    "volume": 5932300
  },
  {
    "time": "06-01-2014",
    "open": 187.15,
    "high": 187.355,
    "low": 185.3,
    "close": 186,
    "volume": 4067800
  },
  {
    "time": "03-01-2014",
    "open": 185.83,
    "high": 187.35,
    "low": 185.3,
    "close": 186.64,
    "volume": 4063200
  },
  {
    "time": "02-01-2014",
    "open": 187.21,
    "high": 187.4,
    "low": 185.2,
    "close": 185.53,
    "volume": 4546500
  },
  {
    "time": "31-12-2013",
    "open": 186.49,
    "high": 187.79,
    "low": 186.3,
    "close": 187.57,
    "volume": 3619700
  },
  {
    "time": "30-12-2013",
    "open": 185.32,
    "high": 186.7,
    "low": 184.67,
    "close": 186.41,
    "volume": 3018600
  },
  {
    "time": "27-12-2013",
    "open": 185.84,
    "high": 186.5,
    "low": 184.56,
    "close": 185.08,
    "volume": 3381600
  },
  {
    "time": "26-12-2013",
    "open": 183.56,
    "high": 185.56,
    "low": 183.51,
    "close": 185.35,
    "volume": 3325700
  },
  {
    "time": "24-12-2013",
    "open": 181.955,
    "high": 183.5,
    "low": 181.91,
    "close": 183.22,
    "volume": 1613600
  },
  {
    "time": "23-12-2013",
    "open": 181.05,
    "high": 182.93,
    "low": 180.61,
    "close": 182.23,
    "volume": 4079900
  },
  {
    "time": "20-12-2013",
    "open": 180.12,
    "high": 182,
    "low": 180.02,
    "close": 180.02,
    "volume": 7653500
  },
  {
    "time": "19-12-2013",
    "open": 177.88,
    "high": 180.54,
    "low": 177.88,
    "close": 180.22,
    "volume": 5927000
  },
  {
    "time": "18-12-2013",
    "open": 176.45,
    "high": 178.74,
    "low": 174.851,
    "close": 178.7,
    "volume": 5697700
  },
  {
    "time": "17-12-2013",
    "open": 177.36,
    "high": 177.49,
    "low": 175.12,
    "close": 175.76,
    "volume": 5471900
  },
  {
    "time": "16-12-2013",
    "open": 173.22,
    "high": 178.35,
    "low": 172.73,
    "close": 177.85,
    "volume": 7517000
  },
  {
    "time": "13-12-2013",
    "open": 173.52,
    "high": 174.379,
    "low": 172.73,
    "close": 172.8,
    "volume": 4184400
  },
  {
    "time": "12-12-2013",
    "open": 175.06,
    "high": 175.8,
    "low": 173.3,
    "close": 173.37,
    "volume": 5671600
  },
  {
    "time": "11-12-2013",
    "open": 177,
    "high": 177.76,
    "low": 175.1,
    "close": 175.2,
    "volume": 4090400
  },
  {
    "time": "10-12-2013",
    "open": 176.9,
    "high": 177.67,
    "low": 176.06,
    "close": 177.12,
    "volume": 4127800
  },
  {
    "time": "09-12-2013",
    "open": 177.99,
    "high": 178.152,
    "low": 176.83,
    "close": 177.46,
    "volume": 3796600
  },
  {
    "time": "06-12-2013",
    "open": 176.7,
    "high": 178,
    "low": 176.01,
    "close": 177.67,
    "volume": 4739800
  },
  {
    "time": "05-12-2013",
    "open": 176.15,
    "high": 176.86,
    "low": 175.28,
    "close": 176.08,
    "volume": 4384900
  },
  {
    "time": "04-12-2013",
    "open": 175.37,
    "high": 177.5,
    "low": 175.16,
    "close": 175.74,
    "volume": 5267400
  },
  {
    "time": "03-12-2013",
    "open": 177,
    "high": 178.23,
    "low": 175.64,
    "close": 176.08,
    "volume": 5864000
  },
  {
    "time": "02-12-2013",
    "open": 179.46,
    "high": 179.59,
    "low": 177.12,
    "close": 177.48,
    "volume": 4560000
  },
  {
    "time": "29-11-2013",
    "open": 179.21,
    "high": 180.76,
    "low": 179,
    "close": 179.68,
    "volume": 2870500
  },
  {
    "time": "27-11-2013",
    "open": 177.83,
    "high": 180.18,
    "low": 177.82,
    "close": 178.97,
    "volume": 4596500
  },
  {
    "time": "26-11-2013",
    "open": 178.67,
    "high": 178.94,
    "low": 177.31,
    "close": 177.31,
    "volume": 5756000
  },
  {
    "time": "25-11-2013",
    "open": 180.25,
    "high": 180.75,
    "low": 177.82,
    "close": 178.94,
    "volume": 7161900
  },
  {
    "time": "22-11-2013",
    "open": 183.5001,
    "high": 184.99,
    "low": 179.92,
    "close": 181.3,
    "volume": 7610200
  },
  {
    "time": "21-11-2013",
    "open": 185.54,
    "high": 185.75,
    "low": 183.411,
    "close": 184.13,
    "volume": 4459500
  },
  {
    "time": "20-11-2013",
    "open": 185.22,
    "high": 186.24,
    "low": 184.645,
    "close": 185.19,
    "volume": 3645800
  },
  {
    "time": "19-11-2013",
    "open": 184.63,
    "high": 186.2,
    "low": 184.15,
    "close": 185.25,
    "volume": 4577000
  },
  {
    "time": "18-11-2013",
    "open": 183.52,
    "high": 184.99,
    "low": 183.27,
    "close": 184.47,
    "volume": 5344900
  },
  {
    "time": "15-11-2013",
    "open": 182.38,
    "high": 183.28,
    "low": 181.16,
    "close": 183.19,
    "volume": 5176100
  },
  {
    "time": "14-11-2013",
    "open": 180.48,
    "high": 183.2,
    "low": 179.66,
    "close": 182.21,
    "volume": 6321500
  },
  {
    "time": "13-11-2013",
    "open": 182.27,
    "high": 183.55,
    "low": 181.59,
    "close": 183.55,
    "volume": 4704400
  },
  {
    "time": "12-11-2013",
    "open": 182.53,
    "high": 184.0487,
    "low": 182.26,
    "close": 183.07,
    "volume": 4258500
  },
  {
    "time": "11-11-2013",
    "open": 180.19,
    "high": 183.39,
    "low": 180.04,
    "close": 182.88,
    "volume": 5222300
  },
  {
    "time": "08-11-2013",
    "open": 178.83,
    "high": 180.08,
    "low": 177.35,
    "close": 179.99,
    "volume": 6275000
  },
  {
    "time": "07-11-2013",
    "open": 179.6,
    "high": 181.39,
    "low": 179.6,
    "close": 180,
    "volume": 5219500
  },
  {
    "time": "06-11-2013",
    "open": 177.91,
    "high": 179.75,
    "low": 177.78,
    "close": 179.19,
    "volume": 4560700
  },
  {
    "time": "05-11-2013",
    "open": 179.54,
    "high": 179.8,
    "low": 177.71,
    "close": 177.85,
    "volume": 6096800
  },
  {
    "time": "04-11-2013",
    "open": 179.9,
    "high": 180.8,
    "low": 179.34,
    "close": 180.27,
    "volume": 3483300
  },
  {
    "time": "01-11-2013",
    "open": 179.81,
    "high": 180.339,
    "low": 178.88,
    "close": 179.23,
    "volume": 3644500
  },
  {
    "time": "31-10-2013",
    "open": 179.65,
    "high": 181.67,
    "low": 179.04,
    "close": 179.21,
    "volume": 4318100
  },
  {
    "time": "30-10-2013",
    "open": 181.69,
    "high": 182.18,
    "low": 179.86,
    "close": 180.15,
    "volume": 5273700
  },
  {
    "time": "29-10-2013",
    "open": 177.62,
    "high": 182.32,
    "low": 177.5,
    "close": 182.12,
    "volume": 8904600
  },
  {
    "time": "28-10-2013",
    "open": 177.04,
    "high": 177.89,
    "low": 176.2,
    "close": 177.35,
    "volume": 3712700
  },
  {
    "time": "25-10-2013",
    "open": 178.43,
    "high": 179.1,
    "low": 176.26,
    "close": 176.85,
    "volume": 4842800
  },
  {
    "time": "24-10-2013",
    "open": 176.43,
    "high": 177.89,
    "low": 176.25,
    "close": 177.8,
    "volume": 5533300
  },
  {
    "time": "23-10-2013",
    "open": 175.09,
    "high": 175.99,
    "low": 174.4,
    "close": 175.77,
    "volume": 5409400
  },
  {
    "time": "22-10-2013",
    "open": 173.35,
    "high": 175.57,
    "low": 172.9512,
    "close": 174.97,
    "volume": 6977300
  },
  {
    "time": "21-10-2013",
    "open": 174.42,
    "high": 174.75,
    "low": 172.6301,
    "close": 172.86,
    "volume": 7098700
  },
  {
    "time": "18-10-2013",
    "open": 174.8,
    "high": 175,
    "low": 173.25,
    "close": 173.78,
    "volume": 10548000
  },
  {
    "time": "17-10-2013",
    "open": 173.84,
    "high": 177,
    "low": 172.57,
    "close": 174.83,
    "volume": 22368900
  },
  {
    "time": "16-10-2013",
    "open": 185.42,
    "high": 186.73,
    "low": 184.994,
    "close": 186.73,
    "volume": 6718000
  },
  {
    "time": "15-10-2013",
    "open": 185.74,
    "high": 185.94,
    "low": 184.22,
    "close": 184.66,
    "volume": 3365100
  },
  {
    "time": "14-10-2013",
    "open": 185.41,
    "high": 186.99,
    "low": 184.42,
    "close": 186.97,
    "volume": 2663100
  },
  {
    "time": "11-10-2013",
    "open": 185.25,
    "high": 186.23,
    "low": 184.12,
    "close": 186.16,
    "volume": 3232600
  },
  {
    "time": "10-10-2013",
    "open": 183.17,
    "high": 184.77,
    "low": 182.358,
    "close": 184.77,
    "volume": 3658900
  },
  {
    "time": "09-10-2013",
    "open": 179.37,
    "high": 181.67,
    "low": 179.1,
    "close": 181.32,
    "volume": 4423500
  },
  {
    "time": "08-10-2013",
    "open": 181.89,
    "high": 181.99,
    "low": 178.71,
    "close": 178.72,
    "volume": 5578300
  },
  {
    "time": "07-10-2013",
    "open": 181.85,
    "high": 183.31,
    "low": 181.85,
    "close": 182.01,
    "volume": 3966400
  },
  {
    "time": "04-10-2013",
    "open": 184.17,
    "high": 185.13,
    "low": 183.58,
    "close": 184.1,
    "volume": 2863600
  },
  {
    "time": "03-10-2013",
    "open": 184.7,
    "high": 184.96,
    "low": 183,
    "close": 183.86,
    "volume": 3211800
  },
  {
    "time": "02-10-2013",
    "open": 185.54,
    "high": 186.3099,
    "low": 184.41,
    "close": 184.96,
    "volume": 3617100
  },
  {
    "time": "01-10-2013",
    "open": 185.34,
    "high": 186.65,
    "low": 184.65,
    "close": 186.38,
    "volume": 2681200
  },
  {
    "time": "30-09-2013",
    "open": 185.56,
    "high": 186.74,
    "low": 184.34,
    "close": 185.18,
    "volume": 3978200
  },
  {
    "time": "27-09-2013",
    "open": 188.92,
    "high": 188.94,
    "low": 186.45,
    "close": 186.92,
    "volume": 3905500
  },
  {
    "time": "26-09-2013",
    "open": 190.1,
    "high": 191.76,
    "low": 189.455,
    "close": 190.22,
    "volume": 2093600
  },
  {
    "time": "25-09-2013",
    "open": 190.14,
    "high": 190.62,
    "low": 188.5,
    "close": 189.47,
    "volume": 2654500
  },
  {
    "time": "24-09-2013",
    "open": 190.93,
    "high": 191.56,
    "low": 189.66,
    "close": 189.97,
    "volume": 3085300
  },
  {
    "time": "23-09-2013",
    "open": 190.06,
    "high": 192.41,
    "low": 189.3301,
    "close": 190.99,
    "volume": 3340800
  },
  {
    "time": "20-09-2013",
    "open": 193.96,
    "high": 194.4299,
    "low": 190.02,
    "close": 190.02,
    "volume": 8525900
  },
  {
    "time": "19-09-2013",
    "open": 194.18,
    "high": 194.89,
    "low": 193.22,
    "close": 193.39,
    "volume": 3178100
  },
  {
    "time": "18-09-2013",
    "open": 192.6,
    "high": 194.89,
    "low": 192,
    "close": 194.42,
    "volume": 3841800
  },
  {
    "time": "17-09-2013",
    "open": 193.42,
    "high": 194.15,
    "low": 191.83,
    "close": 192.16,
    "volume": 2930900
  },
  {
    "time": "16-09-2013",
    "open": 193.7,
    "high": 194.81,
    "low": 192.61,
    "close": 193.15,
    "volume": 3902400
  },
  {
    "time": "13-09-2013",
    "open": 191.21,
    "high": 193.1,
    "low": 191,
    "close": 192.17,
    "volume": 3710400
  },
  {
    "time": "12-09-2013",
    "open": 190.96,
    "high": 191.32,
    "low": 189.85,
    "close": 190.73,
    "volume": 3354800
  },
  {
    "time": "11-09-2013",
    "open": 186.83,
    "high": 190.87,
    "low": 186.82,
    "close": 190.7,
    "volume": 4962900
  },
  {
    "time": "10-09-2013",
    "open": 187.2,
    "high": 187.65,
    "low": 186.37,
    "close": 186.6,
    "volume": 3149600
  },
  {
    "time": "09-09-2013",
    "open": 183.68,
    "high": 185.49,
    "low": 183.31,
    "close": 184.98,
    "volume": 3017200
  },
  {
    "time": "06-09-2013",
    "open": 184.65,
    "high": 184.99,
    "low": 182.65,
    "close": 183.03,
    "volume": 2903500
  },
  {
    "time": "05-09-2013",
    "open": 183.35,
    "high": 185,
    "low": 183.07,
    "close": 184.15,
    "volume": 2867600
  },
  {
    "time": "04-09-2013",
    "open": 183.58,
    "high": 184.19,
    "low": 182.31,
    "close": 183.13,
    "volume": 2597900
  },
  {
    "time": "03-09-2013",
    "open": 183.63,
    "high": 184.32,
    "low": 182.51,
    "close": 183.96,
    "volume": 3487200
  },
  {
    "time": "30-08-2013",
    "open": 182.75,
    "high": 182.99,
    "low": 181.51,
    "close": 182.27,
    "volume": 2731000
  },
  {
    "time": "29-08-2013",
    "open": 181.96,
    "high": 183.7,
    "low": 181.44,
    "close": 182.64,
    "volume": 2980900
  },
  {
    "time": "28-08-2013",
    "open": 182.68,
    "high": 183.47,
    "low": 181.1,
    "close": 182.16,
    "volume": 3979200
  },
  {
    "time": "27-08-2013",
    "open": 183.63,
    "high": 184.5,
    "low": 182.5701,
    "close": 182.74,
    "volume": 3190700
  },
  {
    "time": "26-08-2013",
    "open": 185.27,
    "high": 187,
    "low": 184.68,
    "close": 184.74,
    "volume": 2170400
  },
  {
    "time": "23-08-2013",
    "open": 185.34,
    "high": 185.74,
    "low": 184.57,
    "close": 185.42,
    "volume": 2292700
  },
  {
    "time": "22-08-2013",
    "open": 185.65,
    "high": 186.25,
    "low": 184.25,
    "close": 185.19,
    "volume": 2354300
  },
  {
    "time": "21-08-2013",
    "open": 184.67,
    "high": 186.57,
    "low": 184.28,
    "close": 184.86,
    "volume": 3551000
  },
  {
    "time": "20-08-2013",
    "open": 184.37,
    "high": 185.63,
    "low": 183.1685,
    "close": 184.56,
    "volume": 3117800
  },
  {
    "time": "19-08-2013",
    "open": 185.3,
    "high": 186.5,
    "low": 183.9787,
    "close": 184.23,
    "volume": 3248900
  },
  {
    "time": "16-08-2013",
    "open": 185.54,
    "high": 186.46,
    "low": 185.34,
    "close": 185.34,
    "volume": 3426400
  },
  {
    "time": "15-08-2013",
    "open": 186.23,
    "high": 187,
    "low": 185.45,
    "close": 185.79,
    "volume": 3692400
  },
  {
    "time": "14-08-2013",
    "open": 188.58,
    "high": 188.926,
    "low": 187.3,
    "close": 187.53,
    "volume": 2892300
  },
  {
    "time": "13-08-2013",
    "open": 189.54,
    "high": 189.99,
    "low": 187.56,
    "close": 188.42,
    "volume": 3542900
  },
  {
    "time": "12-08-2013",
    "open": 186.97,
    "high": 189.556,
    "low": 186.89,
    "close": 189.09,
    "volume": 3026500
  },
  {
    "time": "09-08-2013",
    "open": 188,
    "high": 189.16,
    "low": 187,
    "close": 187.82,
    "volume": 3249600
  },
  {
    "time": "08-08-2013",
    "open": 189.45,
    "high": 189.87,
    "low": 186.79,
    "close": 187.93,
    "volume": 4547600
  },
  {
    "time": "07-08-2013",
    "open": 189.6,
    "high": 189.93,
    "low": 188.05,
    "close": 188.56,
    "volume": 3901700
  },
  {
    "time": "06-08-2013",
    "open": 192.26,
    "high": 192.51,
    "low": 190.27,
    "close": 190.99,
    "volume": 5938500
  },
  {
    "time": "05-08-2013",
    "open": 195.16,
    "high": 195.88,
    "low": 194.35,
    "close": 195.5,
    "volume": 2490900
  },
  {
    "time": "02-08-2013",
    "open": 195.5,
    "high": 195.5,
    "low": 193.2215,
    "close": 195.16,
    "volume": 3874000
  },
  {
    "time": "01-08-2013",
    "open": 196.65,
    "high": 197.17,
    "low": 195.41,
    "close": 195.81,
    "volume": 2877700
  },
  {
    "time": "31-07-2013",
    "open": 194.49,
    "high": 196.9099,
    "low": 194.49,
    "close": 195.04,
    "volume": 3810000
  },
  {
    "time": "30-07-2013",
    "open": 196.99,
    "high": 197.83,
    "low": 195.81,
    "close": 196.01,
    "volume": 2663200
  },
  {
    "time": "29-07-2013",
    "open": 196.83,
    "high": 197.19,
    "low": 195.53,
    "close": 196.21,
    "volume": 2113700
  },
  {
    "time": "26-07-2013",
    "open": 196.59,
    "high": 197.37,
    "low": 195,
    "close": 197.35,
    "volume": 2485100
  },
  {
    "time": "25-07-2013",
    "open": 196.3,
    "high": 197.83,
    "low": 195.66,
    "close": 197.22,
    "volume": 3014300
  },
  {
    "time": "24-07-2013",
    "open": 195.95,
    "high": 197.3,
    "low": 195.861,
    "close": 196.61,
    "volume": 2957900
  },
  {
    "time": "23-07-2013",
    "open": 194.21,
    "high": 196.43,
    "low": 194.1,
    "close": 194.98,
    "volume": 2863800
  },
  {
    "time": "22-07-2013",
    "open": 193.4,
    "high": 195.79,
    "low": 193.2804,
    "close": 194.09,
    "volume": 3398000
  },
  {
    "time": "19-07-2013",
    "open": 197.91,
    "high": 197.99,
    "low": 193.24,
    "close": 193.54,
    "volume": 6997600
  },
  {
    "time": "18-07-2013",
    "open": 198.27,
    "high": 200.94,
    "low": 195.99,
    "close": 197.99,
    "volume": 8393400
  },
  {
    "time": "17-07-2013",
    "open": 194.72,
    "high": 194.89,
    "low": 193.03,
    "close": 194.55,
    "volume": 6868400
  },
  {
    "time": "16-07-2013",
    "open": 194.04,
    "high": 194.58,
    "low": 192.685,
    "close": 193.85,
    "volume": 3745300
  },
  {
    "time": "15-07-2013",
    "open": 192.42,
    "high": 194.89,
    "low": 191.68,
    "close": 194,
    "volume": 5674700
  },
  {
    "time": "12-07-2013",
    "open": 193.07,
    "high": 193.6999,
    "low": 191.5901,
    "close": 192.07,
    "volume": 4494700
  },
  {
    "time": "11-07-2013",
    "open": 193.78,
    "high": 194.11,
    "low": 192.61,
    "close": 192.8,
    "volume": 4177500
  },
  {
    "time": "10-07-2013",
    "open": 191.51,
    "high": 193.43,
    "low": 191.2714,
    "close": 192.25,
    "volume": 3892000
  },
  {
    "time": "09-07-2013",
    "open": 191.88,
    "high": 192.8,
    "low": 190.78,
    "close": 191.3,
    "volume": 5204200
  },
  {
    "time": "08-07-2013",
    "open": 195.6,
    "high": 195.78,
    "low": 194.61,
    "close": 194.98,
    "volume": 2947500
  },
  {
    "time": "05-07-2013",
    "open": 194.49,
    "high": 195.16,
    "low": 192.35,
    "close": 194.93,
    "volume": 2405400
  },
  {
    "time": "03-07-2013",
    "open": 190.65,
    "high": 194.22,
    "low": 190.51,
    "close": 193.25,
    "volume": 1872600
  },
  {
    "time": "02-07-2013",
    "open": 190.78,
    "high": 193.23,
    "low": 190.26,
    "close": 191.5,
    "volume": 3657700
  },
  {
    "time": "01-07-2013",
    "open": 192.15,
    "high": 193.8,
    "low": 191.12,
    "close": 191.28,
    "volume": 4442400
  },
  {
    "time": "28-06-2013",
    "open": 191.73,
    "high": 192.66,
    "low": 188.41,
    "close": 191.11,
    "volume": 10055800
  },
  {
    "time": "27-06-2013",
    "open": 196.05,
    "high": 196.9,
    "low": 195.29,
    "close": 195.65,
    "volume": 3514200
  },
  {
    "time": "26-06-2013",
    "open": 195.97,
    "high": 196.23,
    "low": 194.5,
    "close": 194.86,
    "volume": 3320200
  },
  {
    "time": "25-06-2013",
    "open": 195.31,
    "high": 195.59,
    "low": 193.15,
    "close": 194.98,
    "volume": 3846200
  },
  {
    "time": "24-06-2013",
    "open": 193.99,
    "high": 194.91,
    "low": 191.34,
    "close": 193.54,
    "volume": 4356200
  },
  {
    "time": "21-06-2013",
    "open": 198.5,
    "high": 198.52,
    "low": 193.5401,
    "close": 195.46,
    "volume": 8914800
  },
  {
    "time": "20-06-2013",
    "open": 200.67,
    "high": 201.7,
    "low": 197.28,
    "close": 197.35,
    "volume": 4514800
  },
  {
    "time": "19-06-2013",
    "open": 204.44,
    "high": 205.03,
    "low": 201.93,
    "close": 201.94,
    "volume": 2846100
  },
  {
    "time": "18-06-2013",
    "open": 203.02,
    "high": 206.09,
    "low": 202.87,
    "close": 204.87,
    "volume": 3277800
  },
  {
    "time": "17-06-2013",
    "open": 203.44,
    "high": 205.1713,
    "low": 202.55,
    "close": 203.04,
    "volume": 3219900
  },
  {
    "time": "14-06-2013",
    "open": 203.97,
    "high": 204.745,
    "low": 201.81,
    "close": 202.2,
    "volume": 2804500
  },
  {
    "time": "13-06-2013",
    "open": 201.01,
    "high": 204.4,
    "low": 200.34,
    "close": 203.77,
    "volume": 2899500
  },
  {
    "time": "12-06-2013",
    "open": 204.59,
    "high": 205.23,
    "low": 200.51,
    "close": 201.2,
    "volume": 3409800
  },
  {
    "time": "11-06-2013",
    "open": 203.17,
    "high": 205.95,
    "low": 202.51,
    "close": 203.98,
    "volume": 2814400
  },
  {
    "time": "10-06-2013",
    "open": 206.97,
    "high": 206.98,
    "low": 204.9,
    "close": 205.02,
    "volume": 2652300
  },
  {
    "time": "07-06-2013",
    "open": 204.85,
    "high": 206.35,
    "low": 204.11,
    "close": 206.35,
    "volume": 2953400
  },
  {
    "time": "06-06-2013",
    "open": 203,
    "high": 204.2,
    "low": 201.47,
    "close": 203.8,
    "volume": 3679400
  },
  {
    "time": "05-06-2013",
    "open": 205.16,
    "high": 206.29,
    "low": 202.4,
    "close": 202.74,
    "volume": 4215400
  },
  {
    "time": "04-06-2013",
    "open": 208.6,
    "high": 209,
    "low": 205.08,
    "close": 206.19,
    "volume": 3598300
  },
  {
    "time": "03-06-2013",
    "open": 208.25,
    "high": 210.05,
    "low": 207.33,
    "close": 208.95,
    "volume": 3322000
  },
  {
    "time": "31-05-2013",
    "open": 208.59,
    "high": 211.98,
    "low": 208.02,
    "close": 208.02,
    "volume": 4949000
  },
  {
    "time": "30-05-2013",
    "open": 206.97,
    "high": 210.99,
    "low": 206.97,
    "close": 209.36,
    "volume": 4399700
  },
  {
    "time": "29-05-2013",
    "open": 206.22,
    "high": 208.42,
    "low": 206.04,
    "close": 207.92,
    "volume": 3439400
  },
  {
    "time": "28-05-2013",
    "open": 207.44,
    "high": 208.59,
    "low": 207.16,
    "close": 207.78,
    "volume": 4011500
  },
  {
    "time": "24-05-2013",
    "open": 204.69,
    "high": 206.43,
    "low": 204.42,
    "close": 205.72,
    "volume": 3295800
  },
  {
    "time": "23-05-2013",
    "open": 205.48,
    "high": 208.61,
    "low": 205.13,
    "close": 206.16,
    "volume": 5179100
  },
  {
    "time": "22-05-2013",
    "open": 208.5,
    "high": 210.15,
    "low": 206.121,
    "close": 206.99,
    "volume": 5186800
  },
  {
    "time": "21-05-2013",
    "open": 207.24,
    "high": 209.48,
    "low": 207,
    "close": 208.65,
    "volume": 2982900
  },
  {
    "time": "20-05-2013",
    "open": 208.02,
    "high": 209.15,
    "low": 207.42,
    "close": 207.6,
    "volume": 3614500
  },
  {
    "time": "17-05-2013",
    "open": 205.25,
    "high": 209.5,
    "low": 204.99,
    "close": 208.44,
    "volume": 5704100
  },
  {
    "time": "16-05-2013",
    "open": 204,
    "high": 206.59,
    "low": 204,
    "close": 204.69,
    "volume": 4507000
  },
  {
    "time": "15-05-2013",
    "open": 202.25,
    "high": 203.6799,
    "low": 202.04,
    "close": 203.32,
    "volume": 4028100
  },
  {
    "time": "14-05-2013",
    "open": 202.09,
    "high": 203.67,
    "low": 202.08,
    "close": 203.21,
    "volume": 3699700
  },
  {
    "time": "13-05-2013",
    "open": 204.18,
    "high": 204.47,
    "low": 202.22,
    "close": 202.47,
    "volume": 3648400
  },
  {
    "time": "10-05-2013",
    "open": 203.37,
    "high": 204.53,
    "low": 202.82,
    "close": 204.47,
    "volume": 3279200
  },
  {
    "time": "09-05-2013",
    "open": 204.69,
    "high": 205,
    "low": 202.72,
    "close": 203.24,
    "volume": 3542300
  },
  {
    "time": "08-05-2013",
    "open": 202.94,
    "high": 204.85,
    "low": 202.51,
    "close": 204.82,
    "volume": 3601700
  },
  {
    "time": "07-05-2013",
    "open": 202.81,
    "high": 203.73,
    "low": 201.65,
    "close": 203.63,
    "volume": 3451000
  },
  {
    "time": "06-05-2013",
    "open": 203.79,
    "high": 203.99,
    "low": 201.52,
    "close": 202.78,
    "volume": 4826500
  },
  {
    "time": "03-05-2013",
    "open": 203.94,
    "high": 205.319,
    "low": 203.75,
    "close": 204.51,
    "volume": 4492700
  },
  {
    "time": "02-05-2013",
    "open": 200.12,
    "high": 202.43,
    "low": 199.75,
    "close": 202.39,
    "volume": 3945100
  },
  {
    "time": "01-05-2013",
    "open": 201.87,
    "high": 202.17,
    "low": 199.2,
    "close": 199.63,
    "volume": 4899100
  },
  {
    "time": "30-04-2013",
    "open": 199.13,
    "high": 202.59,
    "low": 197.35,
    "close": 202.54,
    "volume": 7539900
  },
  {
    "time": "29-04-2013",
    "open": 194.78,
    "high": 199.68,
    "low": 194.65,
    "close": 199.15,
    "volume": 5932400
  },
  {
    "time": "26-04-2013",
    "open": 194.16,
    "high": 194.71,
    "low": 193.25,
    "close": 194.31,
    "volume": 3490300
  },
  {
    "time": "25-04-2013",
    "open": 192.69,
    "high": 195.17,
    "low": 192.32,
    "close": 193.95,
    "volume": 4650900
  },
  {
    "time": "24-04-2013",
    "open": 192.16,
    "high": 192.5,
    "low": 191.09,
    "close": 191.71,
    "volume": 3693900
  },
  {
    "time": "23-04-2013",
    "open": 189.27,
    "high": 192.555,
    "low": 188.61,
    "close": 191.61,
    "volume": 6296100
  },
  {
    "time": "22-04-2013",
    "open": 191.15,
    "high": 191.38,
    "low": 187.68,
    "close": 187.83,
    "volume": 9845400
  },
  {
    "time": "19-04-2013",
    "open": 195.74,
    "high": 196.5,
    "low": 189.76,
    "close": 190,
    "volume": 18847000
  },
  {
    "time": "18-04-2013",
    "open": 210.11,
    "high": 210.25,
    "low": 206.15,
    "close": 207.15,
    "volume": 6502000
  },
  {
    "time": "17-04-2013",
    "open": 210.53,
    "high": 211.09,
    "low": 209.5,
    "close": 209.67,
    "volume": 3269800
  },
  {
    "time": "16-04-2013",
    "open": 210.84,
    "high": 212,
    "low": 209.2,
    "close": 212,
    "volume": 2859500
  },
  {
    "time": "15-04-2013",
    "open": 210.44,
    "high": 211.69,
    "low": 209.26,
    "close": 209.26,
    "volume": 4218100
  },
  {
    "time": "12-04-2013",
    "open": 210.79,
    "high": 211.49,
    "low": 209.78,
    "close": 211.38,
    "volume": 3165400
  },
  {
    "time": "11-04-2013",
    "open": 211.5,
    "high": 213.09,
    "low": 210.85,
    "close": 212.92,
    "volume": 3655600
  },
  {
    "time": "10-04-2013",
    "open": 211.86,
    "high": 212.51,
    "low": 210.47,
    "close": 212,
    "volume": 3524200
  },
  {
    "time": "09-04-2013",
    "open": 209.56,
    "high": 210,
    "low": 208.61,
    "close": 209.22,
    "volume": 2577300
  },
  {
    "time": "08-04-2013",
    "open": 209.07,
    "high": 209.32,
    "low": 207.33,
    "close": 209.32,
    "volume": 2351200
  },
  {
    "time": "05-04-2013",
    "open": 209.1,
    "high": 209.84,
    "low": 206.34,
    "close": 209.41,
    "volume": 4148000
  },
  {
    "time": "04-04-2013",
    "open": 212.77,
    "high": 212.93,
    "low": 210.04,
    "close": 211.31,
    "volume": 3709500
  },
  {
    "time": "03-04-2013",
    "open": 214.32,
    "high": 214.89,
    "low": 212.6275,
    "close": 212.66,
    "volume": 3128800
  },
  {
    "time": "02-04-2013",
    "open": 212.92,
    "high": 214.71,
    "low": 211.57,
    "close": 214.36,
    "volume": 3116800
  },
  {
    "time": "01-04-2013",
    "open": 212.8,
    "high": 213.5,
    "low": 211.25,
    "close": 212.38,
    "volume": 2144500
  },
  {
    "time": "28-03-2013",
    "open": 209.83,
    "high": 213.44,
    "low": 209.735,
    "close": 213.3,
    "volume": 3749900
  },
  {
    "time": "27-03-2013",
    "open": 210.96,
    "high": 212.16,
    "low": 210.1,
    "close": 210.89,
    "volume": 3223400
  },
  {
    "time": "26-03-2013",
    "open": 211.77,
    "high": 212.504,
    "low": 211.5,
    "close": 212.36,
    "volume": 2300300
  },
  {
    "time": "25-03-2013",
    "open": 212.54,
    "high": 212.81,
    "low": 210.05,
    "close": 210.74,
    "volume": 3242500
  },
  {
    "time": "22-03-2013",
    "open": 212.21,
    "high": 213.17,
    "low": 211.619,
    "close": 212.08,
    "volume": 3031500
  },
  {
    "time": "21-03-2013",
    "open": 212.96,
    "high": 213,
    "low": 210.11,
    "close": 212.26,
    "volume": 5830400
  },
  {
    "time": "20-03-2013",
    "open": 214.76,
    "high": 215.82,
    "low": 214.3,
    "close": 215.06,
    "volume": 3019000
  },
  {
    "time": "19-03-2013",
    "open": 214.13,
    "high": 215.12,
    "low": 211.829,
    "close": 213.44,
    "volume": 3198600
  },
  {
    "time": "18-03-2013",
    "open": 212.9,
    "high": 214.5,
    "low": 212.64,
    "close": 213.21,
    "volume": 3006000
  },
  {
    "time": "15-03-2013",
    "open": 215.38,
    "high": 215.9,
    "low": 213.41,
    "close": 214.92,
    "volume": 7930300
  },
  {
    "time": "14-03-2013",
    "open": 212.15,
    "high": 215.86,
    "low": 212.15,
    "close": 215.8,
    "volume": 5505500
  },
  {
    "time": "13-03-2013",
    "open": 210.2001,
    "high": 212.36,
    "low": 209.77,
    "close": 212.06,
    "volume": 3355900
  },
  {
    "time": "12-03-2013",
    "open": 209.4,
    "high": 210.73,
    "low": 209.09,
    "close": 210.55,
    "volume": 3591600
  },
  {
    "time": "11-03-2013",
    "open": 210.04,
    "high": 210.1999,
    "low": 209.04,
    "close": 210.08,
    "volume": 3049700
  },
  {
    "time": "08-03-2013",
    "open": 209.85,
    "high": 210.74,
    "low": 209.43,
    "close": 210.38,
    "volume": 3700500
  },
  {
    "time": "07-03-2013",
    "open": 208.29,
    "high": 209.6,
    "low": 208.24,
    "close": 209.42,
    "volume": 3884400
  },
  {
    "time": "06-03-2013",
    "open": 207.03,
    "high": 208.49,
    "low": 206.66,
    "close": 208.38,
    "volume": 3594800
  },
  {
    "time": "05-03-2013",
    "open": 205.86,
    "high": 207.7,
    "low": 205.69,
    "close": 206.53,
    "volume": 3807800
  },
  {
    "time": "04-03-2013",
    "open": 202.59,
    "high": 205.19,
    "low": 202.55,
    "close": 205.19,
    "volume": 3693400
  },
  {
    "time": "01-03-2013",
    "open": 200.65,
    "high": 202.94,
    "low": 199.36,
    "close": 202.91,
    "volume": 3308300
  },
  {
    "time": "28-02-2013",
    "open": 202.18,
    "high": 203.123,
    "low": 200.79,
    "close": 200.83,
    "volume": 4689200
  },
  {
    "time": "27-02-2013",
    "open": 198.89,
    "high": 202.75,
    "low": 198.6,
    "close": 202.33,
    "volume": 4185100
  },
  {
    "time": "26-02-2013",
    "open": 198.63,
    "high": 199.9,
    "low": 197.84,
    "close": 199.14,
    "volume": 3391600
  },
  {
    "time": "25-02-2013",
    "open": 201.67,
    "high": 202.49,
    "low": 197.51,
    "close": 197.51,
    "volume": 3844800
  },
  {
    "time": "22-02-2013",
    "open": 199.23,
    "high": 201.09,
    "low": 198.84,
    "close": 201.09,
    "volume": 3107900
  },
  {
    "time": "21-02-2013",
    "open": 198.63,
    "high": 199.07,
    "low": 198.11,
    "close": 198.33,
    "volume": 3922900
  },
  {
    "time": "20-02-2013",
    "open": 200.62,
    "high": 201.72,
    "low": 198.86,
    "close": 199.31,
    "volume": 3715400
  },
  {
    "time": "19-02-2013",
    "open": 200.6,
    "high": 201.89,
    "low": 200.22,
    "close": 200.32,
    "volume": 2998300
  },
  {
    "time": "15-02-2013",
    "open": 199.98,
    "high": 201.25,
    "low": 199.82,
    "close": 200.98,
    "volume": 3627800
  },
  {
    "time": "14-02-2013",
    "open": 199.73,
    "high": 200.32,
    "low": 199.26,
    "close": 199.65,
    "volume": 3294200
  },
  {
    "time": "13-02-2013",
    "open": 200.65,
    "high": 200.95,
    "low": 199.57,
    "close": 200.09,
    "volume": 2169800
  },
  {
    "time": "12-02-2013",
    "open": 200.01,
    "high": 200.735,
    "low": 199.02,
    "close": 200.04,
    "volume": 2461800
  },
  {
    "time": "11-02-2013",
    "open": 200.98,
    "high": 201.95,
    "low": 199.75,
    "close": 200.16,
    "volume": 2944700
  },
  {
    "time": "08-02-2013",
    "open": 199.97,
    "high": 202.09,
    "low": 199.68,
    "close": 201.68,
    "volume": 2893300
  },
  {
    "time": "07-02-2013",
    "open": 200.62,
    "high": 200.91,
    "low": 198.68,
    "close": 199.74,
    "volume": 3076700
  },
  {
    "time": "06-02-2013",
    "open": 200.39,
    "high": 201.29,
    "low": 199.56,
    "close": 201.02,
    "volume": 3624200
  },
  {
    "time": "05-02-2013",
    "open": 204.31,
    "high": 204.75,
    "low": 202.51,
    "close": 202.79,
    "volume": 3636900
  },
  {
    "time": "04-02-2013",
    "open": 204.19,
    "high": 205.02,
    "low": 203.57,
    "close": 203.79,
    "volume": 3188800
  },
  {
    "time": "01-02-2013",
    "open": 204.65,
    "high": 205.35,
    "low": 203.84,
    "close": 205.18,
    "volume": 3370700
  },
  {
    "time": "31-01-2013",
    "open": 203.32,
    "high": 204.4699,
    "low": 202.96,
    "close": 203.07,
    "volume": 3091400
  },
  {
    "time": "30-01-2013",
    "open": 203.69,
    "high": 204.88,
    "low": 203.186,
    "close": 203.52,
    "volume": 2998400
  },
  {
    "time": "29-01-2013",
    "open": 204.34,
    "high": 205.7301,
    "low": 203.64,
    "close": 203.9,
    "volume": 3617100
  },
  {
    "time": "28-01-2013",
    "open": 204.85,
    "high": 206.22,
    "low": 204.29,
    "close": 204.93,
    "volume": 2823700
  },
  {
    "time": "25-01-2013",
    "open": 204.45,
    "high": 205.18,
    "low": 204.13,
    "close": 204.97,
    "volume": 3358900
  },
  {
    "time": "24-01-2013",
    "open": 203.91,
    "high": 205.06,
    "low": 203.08,
    "close": 204.42,
    "volume": 4447200
  },
  {
    "time": "23-01-2013",
    "open": 203.5,
    "high": 208.58,
    "low": 203.36,
    "close": 204.72,
    "volume": 12530500
  },
  {
    "time": "22-01-2013",
    "open": 194.36,
    "high": 196.08,
    "low": 194.01,
    "close": 196.08,
    "volume": 7172300
  },
  {
    "time": "18-01-2013",
    "open": 194.03,
    "high": 195,
    "low": 193.8,
    "close": 194.47,
    "volume": 4559600
  },
  {
    "time": "17-01-2013",
    "open": 193.85,
    "high": 194.46,
    "low": 193.24,
    "close": 193.65,
    "volume": 3881600
  },
  {
    "time": "16-01-2013",
    "open": 192,
    "high": 193.18,
    "low": 191.35,
    "close": 192.59,
    "volume": 2962900
  },
  {
    "time": "15-01-2013",
    "open": 191.31,
    "high": 192.73,
    "low": 190.39,
    "close": 192.5,
    "volume": 4172100
  },
  {
    "time": "14-01-2013",
    "open": 192.82,
    "high": 193.28,
    "low": 191.75,
    "close": 192.62,
    "volume": 4172200
  },
  {
    "time": "11-01-2013",
    "open": 194.15,
    "high": 195,
    "low": 192.9,
    "close": 194.45,
    "volume": 3880400
  },
  {
    "time": "10-01-2013",
    "open": 192.65,
    "high": 192.96,
    "low": 191.28,
    "close": 192.88,
    "volume": 3608100
  },
  {
    "time": "09-01-2013",
    "open": 193.48,
    "high": 193.49,
    "low": 191.65,
    "close": 192.32,
    "volume": 3212000
  },
  {
    "time": "08-01-2013",
    "open": 192.92,
    "high": 193.3,
    "low": 191.605,
    "close": 192.87,
    "volume": 3026900
  },
  {
    "time": "07-01-2013",
    "open": 193.4,
    "high": 193.78,
    "low": 192.34,
    "close": 193.14,
    "volume": 2862300
  },
  {
    "time": "04-01-2013",
    "open": 194.19,
    "high": 194.46,
    "low": 192.78,
    "close": 193.99,
    "volume": 3380200
  },
  {
    "time": "03-01-2013",
    "open": 195.67,
    "high": 196.29,
    "low": 194.44,
    "close": 195.27,
    "volume": 3644700
  },
  {
    "time": "02-01-2013",
    "open": 194.09,
    "high": 196.35,
    "low": 193.8,
    "close": 196.35,
    "volume": 4234100
  },
  {
    "time": "31-12-2012",
    "open": 189.23,
    "high": 191.68,
    "low": 188.84,
    "close": 191.55,
    "volume": 4684900
  },
  {
    "time": "28-12-2012",
    "open": 191.11,
    "high": 192.22,
    "low": 189.83,
    "close": 189.83,
    "volume": 3536600
  },
  {
    "time": "27-12-2012",
    "open": 191.53,
    "high": 193.28,
    "low": 190.7,
    "close": 192.71,
    "volume": 3967600
  },
  {
    "time": "26-12-2012",
    "open": 192.21,
    "high": 193.53,
    "low": 190.95,
    "close": 191.95,
    "volume": 2776500
  },
  {
    "time": "24-12-2012",
    "open": 193.05,
    "high": 193.83,
    "low": 192.02,
    "close": 192.4,
    "volume": 1631200
  },
  {
    "time": "21-12-2012",
    "open": 193.1,
    "high": 194.5,
    "low": 191.57,
    "close": 193.42,
    "volume": 8225300
  },
  {
    "time": "20-12-2012",
    "open": 194.47,
    "high": 194.95,
    "low": 193.16,
    "close": 194.77,
    "volume": 4184500
  },
  {
    "time": "19-12-2012",
    "open": 195.46,
    "high": 196.45,
    "low": 195,
    "close": 195.08,
    "volume": 4270500
  },
  {
    "time": "18-12-2012",
    "open": 193.85,
    "high": 195.92,
    "low": 193.84,
    "close": 195.69,
    "volume": 4125900
  },
  {
    "time": "17-12-2012",
    "open": 191.76,
    "high": 193.91,
    "low": 191.76,
    "close": 193.62,
    "volume": 3792400
  },
  {
    "time": "14-12-2012",
    "open": 191.69,
    "high": 193.4,
    "low": 191.26,
    "close": 191.76,
    "volume": 3826900
  },
  {
    "time": "13-12-2012",
    "open": 192.18,
    "high": 193.05,
    "low": 191.37,
    "close": 191.99,
    "volume": 3623200
  },
  {
    "time": "12-12-2012",
    "open": 194.6,
    "high": 194.75,
    "low": 192.46,
    "close": 192.95,
    "volume": 4241900
  },
  {
    "time": "11-12-2012",
    "open": 193.18,
    "high": 194.8,
    "low": 193.15,
    "close": 194.2,
    "volume": 4144000
  },
  {
    "time": "10-12-2012",
    "open": 192.17,
    "high": 193.54,
    "low": 191.65,
    "close": 192.62,
    "volume": 3438500
  },
  {
    "time": "07-12-2012",
    "open": 190.14,
    "high": 192.2,
    "low": 190.11,
    "close": 191.95,
    "volume": 4091300
  },
  {
    "time": "06-12-2012",
    "open": 189.17,
    "high": 190.18,
    "low": 188.41,
    "close": 189.7,
    "volume": 3216600
  },
  {
    "time": "05-12-2012",
    "open": 189.01,
    "high": 189.46,
    "low": 186.94,
    "close": 188.65,
    "volume": 4202100
  },
  {
    "time": "04-12-2012",
    "open": 189.71,
    "high": 190.75,
    "low": 189.02,
    "close": 189.36,
    "volume": 3990700
  },
  {
    "time": "03-12-2012",
    "open": 190.76,
    "high": 191.3,
    "low": 188.36,
    "close": 189.48,
    "volume": 3349600
  },
  {
    "time": "30-11-2012",
    "open": 191.75,
    "high": 192,
    "low": 189.501,
    "close": 190.07,
    "volume": 4936400
  },
  {
    "time": "29-11-2012",
    "open": 192.75,
    "high": 192.9,
    "low": 190.2,
    "close": 191.53,
    "volume": 4077900
  },
  {
    "time": "28-11-2012",
    "open": 190.98,
    "high": 192.04,
    "low": 189.27,
    "close": 191.98,
    "volume": 3603600
  },
  {
    "time": "27-11-2012",
    "open": 192.08,
    "high": 193.05,
    "low": 191,
    "close": 191.23,
    "volume": 3290900
  },
  {
    "time": "26-11-2012",
    "open": 192.45,
    "high": 193.37,
    "low": 191.77,
    "close": 192.88,
    "volume": 5210300
  },
  {
    "time": "23-11-2012",
    "open": 191,
    "high": 193.49,
    "low": 190.8,
    "close": 193.49,
    "volume": 3877600
  },
  {
    "time": "21-11-2012",
    "open": 189.3,
    "high": 190.89,
    "low": 188.95,
    "close": 190.29,
    "volume": 5189600
  },
  {
    "time": "20-11-2012",
    "open": 189.88,
    "high": 190.11,
    "low": 188.25,
    "close": 189.2,
    "volume": 3170200
  },
  {
    "time": "19-11-2012",
    "open": 188.62,
    "high": 190.39,
    "low": 188.3,
    "close": 190.35,
    "volume": 3588900
  },
  {
    "time": "16-11-2012",
    "open": 186.33,
    "high": 187.56,
    "low": 185.1,
    "close": 186.94,
    "volume": 4660800
  },
  {
    "time": "15-11-2012",
    "open": 185.83,
    "high": 187.2,
    "low": 184.78,
    "close": 185.85,
    "volume": 3411700
  },
  {
    "time": "14-11-2012",
    "open": 189.14,
    "high": 189.27,
    "low": 185.28,
    "close": 185.51,
    "volume": 4404400
  },
  {
    "time": "13-11-2012",
    "open": 188.39,
    "high": 190.88,
    "low": 188.25,
    "close": 188.32,
    "volume": 3406700
  },
  {
    "time": "12-11-2012",
    "open": 190.03,
    "high": 190.77,
    "low": 187.407,
    "close": 189.25,
    "volume": 3067400
  },
  {
    "time": "09-11-2012",
    "open": 189.85,
    "high": 191.64,
    "low": 188.88,
    "close": 189.64,
    "volume": 3671600
  },
  {
    "time": "08-11-2012",
    "open": 191.4,
    "high": 191.81,
    "low": 190.09,
    "close": 190.1,
    "volume": 3542200
  },
  {
    "time": "07-11-2012",
    "open": 193.36,
    "high": 193.68,
    "low": 191.16,
    "close": 191.16,
    "volume": 4746200
  },
  {
    "time": "06-11-2012",
    "open": 195.26,
    "high": 196.75,
    "low": 194.68,
    "close": 195.07,
    "volume": 3281000
  },
  {
    "time": "05-11-2012",
    "open": 192.36,
    "high": 194.97,
    "low": 192.01,
    "close": 194.14,
    "volume": 2736200
  },
  {
    "time": "02-11-2012",
    "open": 197.53,
    "high": 198,
    "low": 193.29,
    "close": 193.43,
    "volume": 4260100
  },
  {
    "time": "01-11-2012",
    "open": 194.96,
    "high": 197.89,
    "low": 194.55,
    "close": 197.15,
    "volume": 3758800
  },
  {
    "time": "31-10-2012",
    "open": 194.8,
    "high": 196.41,
    "low": 193.63,
    "close": 194.53,
    "volume": 6052300
  },
  {
    "time": "26-10-2012",
    "open": 191.49,
    "high": 193.87,
    "low": 191.47,
    "close": 193.27,
    "volume": 4006500
  },
  {
    "time": "25-10-2012",
    "open": 191.41,
    "high": 192.44,
    "low": 190.71,
    "close": 191.6,
    "volume": 3249600
  },
  {
    "time": "24-10-2012",
    "open": 192.44,
    "high": 193.29,
    "low": 190.56,
    "close": 190.72,
    "volume": 3738000
  },
  {
    "time": "23-10-2012",
    "open": 193.53,
    "high": 194,
    "low": 190.94,
    "close": 191.25,
    "volume": 4427400
  },
  {
    "time": "22-10-2012",
    "open": 193.71,
    "high": 194.68,
    "low": 192.44,
    "close": 194.4,
    "volume": 4402500
  },
  {
    "time": "19-10-2012",
    "open": 195.29,
    "high": 196.08,
    "low": 193.18,
    "close": 193.36,
    "volume": 6620300
  },
  {
    "time": "18-10-2012",
    "open": 199.91,
    "high": 200.14,
    "low": 194.07,
    "close": 194.96,
    "volume": 9295900
  },
  {
    "time": "17-10-2012",
    "open": 201.21,
    "high": 203.41,
    "low": 198.55,
    "close": 200.63,
    "volume": 12673900
  },
  {
    "time": "16-10-2012",
    "open": 209.94,
    "high": 211,
    "low": 209.474,
    "close": 211,
    "volume": 5632100
  },
  {
    "time": "15-10-2012",
    "open": 208.88,
    "high": 209.4,
    "low": 207.82,
    "close": 208.93,
    "volume": 3060900
  },
  {
    "time": "12-10-2012",
    "open": 205.64,
    "high": 207.96,
    "low": 205.56,
    "close": 207.8,
    "volume": 2712000
  },
  {
    "time": "11-10-2012",
    "open": 206.57,
    "high": 207.27,
    "low": 205.63,
    "close": 205.76,
    "volume": 2910000
  },
  {
    "time": "10-10-2012",
    "open": 207.82,
    "high": 208.23,
    "low": 205.05,
    "close": 205.82,
    "volume": 4024500
  },
  {
    "time": "09-10-2012",
    "open": 209.95,
    "high": 210.74,
    "low": 207.7,
    "close": 207.99,
    "volume": 3580400
  },
  {
    "time": "08-10-2012",
    "open": 210.02,
    "high": 210.64,
    "low": 209.21,
    "close": 209.82,
    "volume": 1982600
  },
  {
    "time": "05-10-2012",
    "open": 211.15,
    "high": 211.79,
    "low": 210.06,
    "close": 210.59,
    "volume": 2858400
  },
  {
    "time": "04-10-2012",
    "open": 210.28,
    "high": 210.97,
    "low": 208.53,
    "close": 210.39,
    "volume": 2957500
  },
  {
    "time": "03-10-2012",
    "open": 209.94,
    "high": 211.305,
    "low": 209.3,
    "close": 210.51,
    "volume": 3192300
  },
  {
    "time": "02-10-2012",
    "open": 210.96,
    "high": 211.47,
    "low": 208.5,
    "close": 209.84,
    "volume": 3321000
  },
  {
    "time": "01-10-2012",
    "open": 208.01,
    "high": 211.75,
    "low": 207.94,
    "close": 210.47,
    "volume": 5456900
  },
  {
    "time": "28-09-2012",
    "open": 205.66,
    "high": 208.32,
    "low": 205.31,
    "close": 207.45,
    "volume": 4910900
  },
  {
    "time": "27-09-2012",
    "open": 204.37,
    "high": 206.9,
    "low": 204.14,
    "close": 205.91,
    "volume": 2771900
  },
  {
    "time": "26-09-2012",
    "open": 205.05,
    "high": 205.98,
    "low": 203.9,
    "close": 204,
    "volume": 3423300
  },
  {
    "time": "25-09-2012",
    "open": 205.6,
    "high": 207.32,
    "low": 204.95,
    "close": 204.98,
    "volume": 3345200
  },
  {
    "time": "24-09-2012",
    "open": 205.02,
    "high": 206.06,
    "low": 204.9,
    "close": 205.29,
    "volume": 3511100
  },
  {
    "time": "21-09-2012",
    "open": 207.36,
    "high": 207.94,
    "low": 205.921,
    "close": 205.98,
    "volume": 12771500
  },
  {
    "time": "20-09-2012",
    "open": 205.85,
    "high": 206.99,
    "low": 205.3,
    "close": 206.18,
    "volume": 3739100
  },
  {
    "time": "19-09-2012",
    "open": 207.55,
    "high": 207.57,
    "low": 206.01,
    "close": 206.43,
    "volume": 2605600
  },
  {
    "time": "18-09-2012",
    "open": 206.88,
    "high": 207.8798,
    "low": 206.48,
    "close": 207.07,
    "volume": 2476100
  },
  {
    "time": "17-09-2012",
    "open": 205.55,
    "high": 207.99,
    "low": 205.55,
    "close": 207.15,
    "volume": 3275100
  },
  {
    "time": "14-09-2012",
    "open": 206.24,
    "high": 207.65,
    "low": 206.05,
    "close": 206.81,
    "volume": 4057100
  },
  {
    "time": "13-09-2012",
    "open": 204.38,
    "high": 206.81,
    "low": 203.46,
    "close": 206.36,
    "volume": 3879600
  },
  {
    "time": "12-09-2012",
    "open": 203.52,
    "high": 204.65,
    "low": 202.96,
    "close": 203.77,
    "volume": 3284000
  },
  {
    "time": "11-09-2012",
    "open": 200.55,
    "high": 203.46,
    "low": 200.51,
    "close": 203.27,
    "volume": 3910600
  },
  {
    "time": "10-09-2012",
    "open": 199.39,
    "high": 201.82,
    "low": 198.73,
    "close": 200.95,
    "volume": 4208000
  },
  {
    "time": "07-09-2012",
    "open": 199.12,
    "high": 199.5,
    "low": 198.08,
    "close": 199.5,
    "volume": 3413700
  },
  {
    "time": "06-09-2012",
    "open": 196.26,
    "high": 199.46,
    "low": 196.11,
    "close": 199.1,
    "volume": 3931700
  },
  {
    "time": "05-09-2012",
    "open": 194.41,
    "high": 195.85,
    "low": 193.89,
    "close": 195.04,
    "volume": 3312500
  },
  {
    "time": "04-09-2012",
    "open": 196.61,
    "high": 197.17,
    "low": 193.25,
    "close": 194.54,
    "volume": 4514400
  },
  {
    "time": "31-08-2012",
    "open": 194.31,
    "high": 195.95,
    "low": 193.46,
    "close": 194.85,
    "volume": 3193300
  },
  {
    "time": "30-08-2012",
    "open": 194.77,
    "high": 195.47,
    "low": 193.18,
    "close": 193.37,
    "volume": 2740900
  },
  {
    "time": "29-08-2012",
    "open": 195.13,
    "high": 196.0399,
    "low": 194.9,
    "close": 195.08,
    "volume": 2141400
  },
  {
    "time": "28-08-2012",
    "open": 195.56,
    "high": 196.11,
    "low": 194.5,
    "close": 194.87,
    "volume": 2539200
  },
  {
    "time": "27-08-2012",
    "open": 197.96,
    "high": 198.3,
    "low": 195.61,
    "close": 195.69,
    "volume": 2498800
  },
  {
    "time": "24-08-2012",
    "open": 194.96,
    "high": 198.11,
    "low": 194.2,
    "close": 197.77,
    "volume": 2639500
  },
  {
    "time": "23-08-2012",
    "open": 197.04,
    "high": 197.37,
    "low": 195.44,
    "close": 195.7,
    "volume": 2757400
  },
  {
    "time": "22-08-2012",
    "open": 198.64,
    "high": 198.65,
    "low": 196.905,
    "close": 197.25,
    "volume": 3296100
  },
  {
    "time": "21-08-2012",
    "open": 200.99,
    "high": 201,
    "low": 198.37,
    "close": 198.65,
    "volume": 3111300
  },
  {
    "time": "20-08-2012",
    "open": 200.69,
    "high": 201.13,
    "low": 200.03,
    "close": 200.5,
    "volume": 2336000
  },
  {
    "time": "17-08-2012",
    "open": 201.08,
    "high": 202,
    "low": 200.66,
    "close": 201.22,
    "volume": 2551800
  },
  {
    "time": "16-08-2012",
    "open": 198.77,
    "high": 201.324,
    "low": 198.12,
    "close": 200.84,
    "volume": 2729500
  },
  {
    "time": "15-08-2012",
    "open": 198.9,
    "high": 199.33,
    "low": 197.92,
    "close": 198.4,
    "volume": 2111800
  },
  {
    "time": "14-08-2012",
    "open": 198.88,
    "high": 199.33,
    "low": 197.72,
    "close": 198.29,
    "volume": 2562300
  },
  {
    "time": "13-08-2012",
    "open": 198.88,
    "high": 199.98,
    "low": 197.79,
    "close": 199.01,
    "volume": 2418300
  },
  {
    "time": "10-08-2012",
    "open": 197.87,
    "high": 199.37,
    "low": 197.24,
    "close": 199.29,
    "volume": 2600500
  },
  {
    "time": "09-08-2012",
    "open": 198.62,
    "high": 199.46,
    "low": 197.89,
    "close": 198.42,
    "volume": 2160100
  },
  {
    "time": "08-08-2012",
    "open": 198.27,
    "high": 199.69,
    "low": 198.16,
    "close": 199.03,
    "volume": 2068300
  },
  {
    "time": "07-08-2012",
    "open": 199.43,
    "high": 200.88,
    "low": 198.8,
    "close": 199.93,
    "volume": 3209400
  },
  {
    "time": "06-08-2012",
    "open": 198.76,
    "high": 199.94,
    "low": 198.52,
    "close": 198.76,
    "volume": 2337800
  },
  {
    "time": "03-08-2012",
    "open": 196.48,
    "high": 198.95,
    "low": 196.16,
    "close": 198.52,
    "volume": 3278100
  },
  {
    "time": "02-08-2012",
    "open": 194.16,
    "high": 196.6,
    "low": 193.02,
    "close": 194.45,
    "volume": 2812600
  },
  {
    "time": "01-08-2012",
    "open": 196.96,
    "high": 197.85,
    "low": 194.72,
    "close": 195.18,
    "volume": 2559300
  },
  {
    "time": "31-07-2012",
    "open": 196.5,
    "high": 197.58,
    "low": 195.88,
    "close": 195.98,
    "volume": 3507000
  },
  {
    "time": "30-07-2012",
    "open": 196.32,
    "high": 197.84,
    "low": 195.92,
    "close": 196.68,
    "volume": 2787000
  },
  {
    "time": "27-07-2012",
    "open": 195.1,
    "high": 197.41,
    "low": 193.95,
    "close": 196.39,
    "volume": 4177300
  },
  {
    "time": "26-07-2012",
    "open": 193.49,
    "high": 194.95,
    "low": 192.57,
    "close": 193.95,
    "volume": 3282900
  },
  {
    "time": "25-07-2012",
    "open": 190.31,
    "high": 192.77,
    "low": 189.32,
    "close": 191.08,
    "volume": 3833800
  },
  {
    "time": "24-07-2012",
    "open": 190.92,
    "high": 191.32,
    "low": 188.56,
    "close": 190.34,
    "volume": 3597100
  },
  {
    "time": "23-07-2012",
    "open": 189.78,
    "high": 191.3,
    "low": 188.2,
    "close": 190.83,
    "volume": 3904500
  },
  {
    "time": "20-07-2012",
    "open": 194.09,
    "high": 194.9,
    "low": 192.17,
    "close": 192.45,
    "volume": 4789700
  },
  {
    "time": "19-07-2012",
    "open": 193.4,
    "high": 196.85,
    "low": 192.97,
    "close": 195.34,
    "volume": 10395400
  },
  {
    "time": "18-07-2012",
    "open": 184.15,
    "high": 188.59,
    "low": 183.55,
    "close": 188.25,
    "volume": 8019500
  },
  {
    "time": "17-07-2012",
    "open": 185.73,
    "high": 186.29,
    "low": 183.2,
    "close": 183.65,
    "volume": 5158600
  },
  {
    "time": "16-07-2012",
    "open": 185.58,
    "high": 186.1,
    "low": 184.58,
    "close": 184.79,
    "volume": 3144400
  },
  {
    "time": "13-07-2012",
    "open": 183.46,
    "high": 186.33,
    "low": 183.03,
    "close": 186.01,
    "volume": 3933000
  },
  {
    "time": "12-07-2012",
    "open": 184.25,
    "high": 184.39,
    "low": 181.85,
    "close": 183.09,
    "volume": 4931300
  },
  {
    "time": "11-07-2012",
    "open": 186.22,
    "high": 187.36,
    "low": 183.51,
    "close": 185.25,
    "volume": 5456100
  },
  {
    "time": "10-07-2012",
    "open": 190.3,
    "high": 191.14,
    "low": 185.6,
    "close": 186.26,
    "volume": 4690300
  },
  {
    "time": "09-07-2012",
    "open": 190.76,
    "high": 190.9991,
    "low": 188.05,
    "close": 189.67,
    "volume": 3988100
  },
  {
    "time": "06-07-2012",
    "open": 193.92,
    "high": 193.94,
    "low": 189.74,
    "close": 191.41,
    "volume": 4952900
  },
  {
    "time": "05-07-2012",
    "open": 194.88,
    "high": 196.85,
    "low": 193.63,
    "close": 195.29,
    "volume": 2690200
  },
  {
    "time": "03-07-2012",
    "open": 195.46,
    "high": 196.34,
    "low": 194.91,
    "close": 195.93,
    "volume": 1450400
  },
  {
    "time": "02-07-2012",
    "open": 196.36,
    "high": 197.2,
    "low": 194.85,
    "close": 195.83,
    "volume": 2827000
  },
  {
    "time": "29-06-2012",
    "open": 194.55,
    "high": 195.81,
    "low": 192.41,
    "close": 195.58,
    "volume": 5259600
  },
  {
    "time": "28-06-2012",
    "open": 191.23,
    "high": 192.08,
    "low": 188.9,
    "close": 191.4,
    "volume": 3967300
  },
  {
    "time": "27-06-2012",
    "open": 192.14,
    "high": 193.7,
    "low": 190.9,
    "close": 193,
    "volume": 2762700
  },
  {
    "time": "26-06-2012",
    "open": 193.54,
    "high": 193.68,
    "low": 190.75,
    "close": 191.95,
    "volume": 3435900
  },
  {
    "time": "25-06-2012",
    "open": 192.48,
    "high": 193.39,
    "low": 191.68,
    "close": 192.86,
    "volume": 3211600
  },
  {
    "time": "22-06-2012",
    "open": 193.87,
    "high": 194.88,
    "low": 193.22,
    "close": 193.7,
    "volume": 8823000
  },
  {
    "time": "21-06-2012",
    "open": 199.07,
    "high": 199.45,
    "low": 193.19,
    "close": 193.39,
    "volume": 4316600
  },
  {
    "time": "20-06-2012",
    "open": 198.7,
    "high": 199.74,
    "low": 197.5,
    "close": 198.78,
    "volume": 3378200
  },
  {
    "time": "19-06-2012",
    "open": 198.83,
    "high": 199.99,
    "low": 198.8,
    "close": 198.93,
    "volume": 3297600
  },
  {
    "time": "18-06-2012",
    "open": 197.57,
    "high": 199.64,
    "low": 197.17,
    "close": 198.29,
    "volume": 3378900
  },
  {
    "time": "15-06-2012",
    "open": 196.08,
    "high": 199.27,
    "low": 195.4,
    "close": 199.1,
    "volume": 6389000
  },
  {
    "time": "14-06-2012",
    "open": 193,
    "high": 195.83,
    "low": 192.74,
    "close": 195.1,
    "volume": 3585000
  },
  {
    "time": "13-06-2012",
    "open": 194.36,
    "high": 195.19,
    "low": 192.345,
    "close": 193.1,
    "volume": 3247700
  },
  {
    "time": "12-06-2012",
    "open": 192.14,
    "high": 194.67,
    "low": 192.14,
    "close": 194.55,
    "volume": 3023700
  },
  {
    "time": "11-06-2012",
    "open": 196.6,
    "high": 196.7,
    "low": 192.37,
    "close": 192.51,
    "volume": 3273300
  },
  {
    "time": "08-06-2012",
    "open": 193.71,
    "high": 195.14,
    "low": 192.7,
    "close": 195.14,
    "volume": 2479200
  },
  {
    "time": "07-06-2012",
    "open": 195.28,
    "high": 195.8299,
    "low": 193.94,
    "close": 194.44,
    "volume": 3701100
  },
  {
    "time": "06-06-2012",
    "open": 190.78,
    "high": 194,
    "low": 190.02,
    "close": 193.99,
    "volume": 3641300
  },
  {
    "time": "05-06-2012",
    "open": 187.92,
    "high": 189.85,
    "low": 187.56,
    "close": 189.2,
    "volume": 3556500
  },
  {
    "time": "04-06-2012",
    "open": 188.34,
    "high": 189.38,
    "low": 187,
    "close": 188.54,
    "volume": 4624500
  },
  {
    "time": "01-06-2012",
    "open": 190.12,
    "high": 191.72,
    "low": 188.6,
    "close": 189.08,
    "volume": 5206400
  },
  {
    "time": "31-05-2012",
    "open": 194.1,
    "high": 194.93,
    "low": 192,
    "close": 192.9,
    "volume": 9287500
  },
  {
    "time": "30-05-2012",
    "open": 194.8,
    "high": 195.47,
    "low": 193.77,
    "close": 194.53,
    "volume": 3602500
  },
  {
    "time": "29-05-2012",
    "open": 195.22,
    "high": 198.08,
    "low": 195.22,
    "close": 196.46,
    "volume": 3618500
  },
  {
    "time": "25-05-2012",
    "open": 195.11,
    "high": 196,
    "low": 194,
    "close": 194.3,
    "volume": 2901900
  },
  {
    "time": "24-05-2012",
    "open": 195.99,
    "high": 196.28,
    "low": 194,
    "close": 196.09,
    "volume": 3569300
  },
  {
    "time": "23-05-2012",
    "open": 195.53,
    "high": 196.49,
    "low": 193.2,
    "close": 196.12,
    "volume": 4100000
  },
  {
    "time": "22-05-2012",
    "open": 198.04,
    "high": 198.26,
    "low": 196.06,
    "close": 196.82,
    "volume": 3567100
  },
  {
    "time": "21-05-2012",
    "open": 195.98,
    "high": 197.93,
    "low": 194.63,
    "close": 197.76,
    "volume": 3831700
  },
  {
    "time": "18-05-2012",
    "open": 198.78,
    "high": 198.78,
    "low": 195.16,
    "close": 195.88,
    "volume": 5737100
  },
  {
    "time": "17-05-2012",
    "open": 199.67,
    "high": 200.79,
    "low": 197.64,
    "close": 197.89,
    "volume": 5626300
  },
  {
    "time": "16-05-2012",
    "open": 199.42,
    "high": 201.35,
    "low": 198.18,
    "close": 199.73,
    "volume": 5001000
  },
  {
    "time": "15-05-2012",
    "open": 199.44,
    "high": 201.4679,
    "low": 198.76,
    "close": 199.04,
    "volume": 2987400
  },
  {
    "time": "14-05-2012",
    "open": 199.92,
    "high": 200.71,
    "low": 198.56,
    "close": 199.44,
    "volume": 3029200
  },
  {
    "time": "11-05-2012",
    "open": 199.96,
    "high": 202.12,
    "low": 199.95,
    "close": 201.17,
    "volume": 2391500
  },
  {
    "time": "10-05-2012",
    "open": 201,
    "high": 203.25,
    "low": 200.02,
    "close": 200.6,
    "volume": 3830500
  },
  {
    "time": "09-05-2012",
    "open": 199.99,
    "high": 203,
    "low": 199.06,
    "close": 201.23,
    "volume": 3742500
  },
  {
    "time": "08-05-2012",
    "open": 202.44,
    "high": 203.06,
    "low": 199.718,
    "close": 201.48,
    "volume": 3881500
  },
  {
    "time": "07-05-2012",
    "open": 203.96,
    "high": 204.77,
    "low": 202.9,
    "close": 203.75,
    "volume": 3373400
  },
  {
    "time": "04-05-2012",
    "open": 206.47,
    "high": 207.11,
    "low": 204.4,
    "close": 204.99,
    "volume": 4023600
  },
  {
    "time": "03-05-2012",
    "open": 208.27,
    "high": 208.92,
    "low": 206.55,
    "close": 207.24,
    "volume": 2826600
  },
  {
    "time": "02-05-2012",
    "open": 206.97,
    "high": 208.27,
    "low": 206.91,
    "close": 208.06,
    "volume": 2360300
  },
  {
    "time": "01-05-2012",
    "open": 207.18,
    "high": 208.929,
    "low": 206.71,
    "close": 208,
    "volume": 2715900
  },
  {
    "time": "30-04-2012",
    "open": 206.71,
    "high": 207.96,
    "low": 206.32,
    "close": 207.08,
    "volume": 2773200
  },
  {
    "time": "27-04-2012",
    "open": 206.47,
    "high": 208.17,
    "low": 205.91,
    "close": 206.81,
    "volume": 3496900
  },
  {
    "time": "26-04-2012",
    "open": 203.4,
    "high": 206.21,
    "low": 203.4,
    "close": 205.58,
    "volume": 3302700
  },
  {
    "time": "25-04-2012",
    "open": 202.02,
    "high": 203.69,
    "low": 201.8,
    "close": 203.57,
    "volume": 4003400
  },
  {
    "time": "24-04-2012",
    "open": 199.06,
    "high": 202.48,
    "low": 198.87,
    "close": 200,
    "volume": 5626300
  },
  {
    "time": "23-04-2012",
    "open": 197.89,
    "high": 199.1,
    "low": 196.791,
    "close": 198.62,
    "volume": 3618800
  },
  {
    "time": "20-04-2012",
    "open": 199.83,
    "high": 200.12,
    "low": 199.09,
    "close": 199.6,
    "volume": 4758200
  },
  {
    "time": "19-04-2012",
    "open": 200.03,
    "high": 201.26,
    "low": 197.521,
    "close": 199.51,
    "volume": 5762900
  },
  {
    "time": "18-04-2012",
    "open": 202.43,
    "high": 204.07,
    "low": 200,
    "close": 200.13,
    "volume": 10153500
  },
  {
    "time": "17-04-2012",
    "open": 204.57,
    "high": 207.64,
    "low": 204.15,
    "close": 207.45,
    "volume": 6065500
  },
  {
    "time": "16-04-2012",
    "open": 204.74,
    "high": 205.97,
    "low": 202.72,
    "close": 202.72,
    "volume": 3826600
  },
  {
    "time": "13-04-2012",
    "open": 204.32,
    "high": 204.6499,
    "low": 202.8,
    "close": 202.8,
    "volume": 3767000
  },
  {
    "time": "12-04-2012",
    "open": 202.8,
    "high": 205.56,
    "low": 202.64,
    "close": 205.32,
    "volume": 2929600
  },
  {
    "time": "11-04-2012",
    "open": 203.99,
    "high": 204.32,
    "low": 202.4593,
    "close": 202.58,
    "volume": 3366200
  },
  {
    "time": "10-04-2012",
    "open": 204.94,
    "high": 205.74,
    "low": 202.17,
    "close": 202.33,
    "volume": 4132900
  },
  {
    "time": "09-04-2012",
    "open": 204.4,
    "high": 206.17,
    "low": 203.37,
    "close": 204.94,
    "volume": 3326900
  },
  {
    "time": "05-04-2012",
    "open": 205.15,
    "high": 205.68,
    "low": 204.46,
    "close": 205.47,
    "volume": 3809000
  },
  {
    "time": "04-04-2012",
    "open": 206.22,
    "high": 206.9802,
    "low": 205.19,
    "close": 206.05,
    "volume": 4404000
  },
  {
    "time": "03-04-2012",
    "open": 209.31,
    "high": 210.69,
    "low": 208.54,
    "close": 209.5,
    "volume": 4091500
  },
  {
    "time": "02-04-2012",
    "open": 208.96,
    "high": 209.69,
    "low": 208.09,
    "close": 209.47,
    "volume": 3387900
  },
  {
    "time": "30-03-2012",
    "open": 208.74,
    "high": 209.12,
    "low": 208.012,
    "close": 208.65,
    "volume": 3215200
  },
  {
    "time": "29-03-2012",
    "open": 206.09,
    "high": 208.5,
    "low": 206.02,
    "close": 208.27,
    "volume": 3403700
  },
  {
    "time": "28-03-2012",
    "open": 207.44,
    "high": 208.69,
    "low": 206.27,
    "close": 207.29,
    "volume": 3265700
  },
  {
    "time": "27-03-2012",
    "open": 208.46,
    "high": 208.66,
    "low": 207,
    "close": 207.18,
    "volume": 2704500
  },
  {
    "time": "26-03-2012",
    "open": 206.45,
    "high": 207.92,
    "low": 206.32,
    "close": 207.77,
    "volume": 3418200
  },
  {
    "time": "23-03-2012",
    "open": 205.89,
    "high": 205.96,
    "low": 204.56,
    "close": 205.48,
    "volume": 3036200
  },
  {
    "time": "22-03-2012",
    "open": 204,
    "high": 205.99,
    "low": 203.96,
    "close": 205.49,
    "volume": 3307800
  },
  {
    "time": "21-03-2012",
    "open": 204.32,
    "high": 205.77,
    "low": 204.3,
    "close": 204.69,
    "volume": 3329900
  },
  {
    "time": "20-03-2012",
    "open": 204.74,
    "high": 205,
    "low": 203.702,
    "close": 204.25,
    "volume": 3695100
  },
  {
    "time": "19-03-2012",
    "open": 205.71,
    "high": 206.53,
    "low": 204.5,
    "close": 205.72,
    "volume": 3763200
  },
  {
    "time": "16-03-2012",
    "open": 207.03,
    "high": 207.52,
    "low": 206.01,
    "close": 206.01,
    "volume": 9637000
  },
  {
    "time": "15-03-2012",
    "open": 204.94,
    "high": 206.18,
    "low": 204.74,
    "close": 206,
    "volume": 3621100
  },
  {
    "time": "14-03-2012",
    "open": 203.78,
    "high": 205.15,
    "low": 203.64,
    "close": 204.72,
    "volume": 4602300
  },
  {
    "time": "13-03-2012",
    "open": 201.72,
    "high": 203.9,
    "low": 201.6107,
    "close": 203.78,
    "volume": 4866000
  },
  {
    "time": "12-03-2012",
    "open": 200.93,
    "high": 201.57,
    "low": 200.23,
    "close": 201,
    "volume": 3119700
  },
  {
    "time": "09-03-2012",
    "open": 199.98,
    "high": 201.1,
    "low": 199.72,
    "close": 200.62,
    "volume": 3178200
  },
  {
    "time": "08-03-2012",
    "open": 199.24,
    "high": 199.99,
    "low": 198.65,
    "close": 199.81,
    "volume": 3145800
  },
  {
    "time": "07-03-2012",
    "open": 198.09,
    "high": 198.57,
    "low": 197.31,
    "close": 197.77,
    "volume": 3346900
  },
  {
    "time": "06-03-2012",
    "open": 199.14,
    "high": 199.64,
    "low": 196.812,
    "close": 197.26,
    "volume": 5214700
  },
  {
    "time": "05-03-2012",
    "open": 198.36,
    "high": 201.19,
    "low": 198.3,
    "close": 200.66,
    "volume": 6938000
  },
  {
    "time": "02-03-2012",
    "open": 197.1,
    "high": 198.89,
    "low": 197.06,
    "close": 198.81,
    "volume": 3727400
  },
  {
    "time": "01-03-2012",
    "open": 197.23,
    "high": 198.47,
    "low": 196.93,
    "close": 197.53,
    "volume": 3560300
  },
  {
    "time": "29-02-2012",
    "open": 197.86,
    "high": 199.17,
    "low": 196.45,
    "close": 196.73,
    "volume": 5117100
  },
  {
    "time": "28-02-2012",
    "open": 197.75,
    "high": 198.96,
    "low": 197.4322,
    "close": 197.98,
    "volume": 3167400
  },
  {
    "time": "27-02-2012",
    "open": 196.78,
    "high": 198.71,
    "low": 196.1,
    "close": 197.53,
    "volume": 3758400
  },
  {
    "time": "24-02-2012",
    "open": 198.38,
    "high": 198.56,
    "low": 196.61,
    "close": 197.76,
    "volume": 3353200
  },
  {
    "time": "23-02-2012",
    "open": 193.87,
    "high": 199.23,
    "low": 193.28,
    "close": 197.61,
    "volume": 8032700
  },
  {
    "time": "22-02-2012",
    "open": 193.03,
    "high": 194.74,
    "low": 192.75,
    "close": 193.87,
    "volume": 3275800
  },
  {
    "time": "21-02-2012",
    "open": 193.73,
    "high": 194.24,
    "low": 192.46,
    "close": 193.39,
    "volume": 3293600
  },
  {
    "time": "17-02-2012",
    "open": 193.71,
    "high": 193.86,
    "low": 192.3,
    "close": 193.42,
    "volume": 3746500
  },
  {
    "time": "16-02-2012",
    "open": 192.61,
    "high": 193.25,
    "low": 192,
    "close": 193.02,
    "volume": 3525500
  },
  {
    "time": "15-02-2012",
    "open": 192.31,
    "high": 193.44,
    "low": 191.26,
    "close": 192.25,
    "volume": 4020900
  },
  {
    "time": "14-02-2012",
    "open": 191.76,
    "high": 192.6,
    "low": 190.831,
    "close": 192.22,
    "volume": 3398100
  },
  {
    "time": "13-02-2012",
    "open": 193.31,
    "high": 193.69,
    "low": 191.74,
    "close": 192.62,
    "volume": 2458800
  },
  {
    "time": "10-02-2012",
    "open": 191.76,
    "high": 192.43,
    "low": 191.28,
    "close": 192.42,
    "volume": 3391100
  },
  {
    "time": "09-02-2012",
    "open": 193.03,
    "high": 194.46,
    "low": 192.55,
    "close": 193.13,
    "volume": 3876400
  },
  {
    "time": "08-02-2012",
    "open": 192.78,
    "high": 193.58,
    "low": 191.73,
    "close": 192.95,
    "volume": 3803800
  },
  {
    "time": "07-02-2012",
    "open": 192.45,
    "high": 194.14,
    "low": 191.97,
    "close": 193.35,
    "volume": 3433000
  },
  {
    "time": "06-02-2012",
    "open": 192.48,
    "high": 193.76,
    "low": 192,
    "close": 192.82,
    "volume": 3639800
  },
  {
    "time": "03-02-2012",
    "open": 192.93,
    "high": 194.13,
    "low": 192.54,
    "close": 193.64,
    "volume": 4521700
  },
  {
    "time": "02-02-2012",
    "open": 192.72,
    "high": 193.33,
    "low": 191.33,
    "close": 191.53,
    "volume": 3907300
  },
  {
    "time": "01-02-2012",
    "open": 193.21,
    "high": 194.81,
    "low": 192.41,
    "close": 192.62,
    "volume": 5088800
  },
  {
    "time": "31-01-2012",
    "open": 193.09,
    "high": 193.1,
    "low": 191,
    "close": 192.6,
    "volume": 4826800
  },
  {
    "time": "30-01-2012",
    "open": 189.39,
    "high": 192.73,
    "low": 188.22,
    "close": 192.5,
    "volume": 4359000
  },
  {
    "time": "27-01-2012",
    "open": 190.01,
    "high": 191.77,
    "low": 189.81,
    "close": 190.46,
    "volume": 3360400
  },
  {
    "time": "26-01-2012",
    "open": 191.79,
    "high": 192.79,
    "low": 190.47,
    "close": 190.98,
    "volume": 4004700
  },
  {
    "time": "25-01-2012",
    "open": 191.33,
    "high": 192.2399,
    "low": 189.61,
    "close": 191.73,
    "volume": 4359700
  },
  {
    "time": "24-01-2012",
    "open": 188.63,
    "high": 192.305,
    "low": 188.5241,
    "close": 191.93,
    "volume": 5345700
  },
  {
    "time": "23-01-2012",
    "open": 187.91,
    "high": 190.52,
    "low": 187.67,
    "close": 189.98,
    "volume": 5751700
  },
  {
    "time": "20-01-2012",
    "open": 185.77,
    "high": 188.97,
    "low": 184.75,
    "close": 188.52,
    "volume": 12849700
  },
  {
    "time": "19-01-2012",
    "open": 181.79,
    "high": 182.3599,
    "low": 180.35,
    "close": 180.52,
    "volume": 8567200
  },
  {
    "time": "18-01-2012",
    "open": 179.83,
    "high": 181.6,
    "low": 179.5,
    "close": 181.07,
    "volume": 4600600
  },
  {
    "time": "17-01-2012",
    "open": 180.36,
    "high": 182,
    "low": 179.32,
    "close": 180,
    "volume": 6003400
  },
  {
    "time": "13-01-2012",
    "open": 179.48,
    "high": 179.61,
    "low": 177.3472,
    "close": 179.16,
    "volume": 5279200
  },
  {
    "time": "12-01-2012",
    "open": 181.86,
    "high": 181.91,
    "low": 178.38,
    "close": 180.55,
    "volume": 6881000
  },
  {
    "time": "11-01-2012",
    "open": 180.73,
    "high": 182.81,
    "low": 180.5,
    "close": 182.32,
    "volume": 4110800
  },
  {
    "time": "10-01-2012",
    "open": 183.23,
    "high": 183.72,
    "low": 181.2,
    "close": 181.31,
    "volume": 5161000
  },
  {
    "time": "09-01-2012",
    "open": 182.2,
    "high": 182.27,
    "low": 180.27,
    "close": 181.59,
    "volume": 5201200
  },
  {
    "time": "06-01-2012",
    "open": 184.39,
    "high": 184.48,
    "low": 182.31,
    "close": 182.54,
    "volume": 4897100
  },
  {
    "time": "05-01-2012",
    "open": 184.81,
    "high": 185.03,
    "low": 183.1,
    "close": 184.66,
    "volume": 4463100
  },
  {
    "time": "04-01-2012",
    "open": 185.57,
    "high": 186.33,
    "low": 184.94,
    "close": 185.54,
    "volume": 4346700
  },
  {
    "time": "03-01-2012",
    "open": 186.73,
    "high": 188.71,
    "low": 186,
    "close": 186.3,
    "volume": 5646000
  },
  {
    "time": "30-12-2011",
    "open": 186.33,
    "high": 186.48,
    "low": 183.85,
    "close": 183.88,
    "volume": 3530900
  },
  {
    "time": "29-12-2011",
    "open": 184.07,
    "high": 186.23,
    "low": 184.01,
    "close": 186.18,
    "volume": 2941600
  },
  {
    "time": "28-12-2011",
    "open": 185.19,
    "high": 185.4,
    "low": 183.34,
    "close": 183.99,
    "volume": 2872000
  },
  {
    "time": "27-12-2011",
    "open": 184.97,
    "high": 185.85,
    "low": 184.39,
    "close": 184.95,
    "volume": 2775200
  },
  {
    "time": "23-12-2011",
    "open": 183.11,
    "high": 184.85,
    "low": 181.89,
    "close": 184.75,
    "volume": 3052300
  },
  {
    "time": "22-12-2011",
    "open": 182.71,
    "high": 182.8,
    "low": 181,
    "close": 182.04,
    "volume": 5052000
  },
  {
    "time": "21-12-2011",
    "open": 183.22,
    "high": 183.5,
    "low": 179.04,
    "close": 181.47,
    "volume": 9282500
  },
  {
    "time": "20-12-2011",
    "open": 185.5,
    "high": 187.33,
    "low": 184.755,
    "close": 187.24,
    "volume": 4740100
  },
  {
    "time": "19-12-2011",
    "open": 184.51,
    "high": 184.69,
    "low": 182.25,
    "close": 182.89,
    "volume": 5039900
  },
  {
    "time": "16-12-2011",
    "open": 188.01,
    "high": 188.01,
    "low": 181.91,
    "close": 183.57,
    "volume": 11849400
  },
  {
    "time": "15-12-2011",
    "open": 190.48,
    "high": 191.2,
    "low": 186.89,
    "close": 187.48,
    "volume": 4474500
  },
  {
    "time": "14-12-2011",
    "open": 189.84,
    "high": 190.28,
    "low": 188,
    "close": 188.72,
    "volume": 5057700
  },
  {
    "time": "13-12-2011",
    "open": 193.46,
    "high": 194.3,
    "low": 190.64,
    "close": 191.15,
    "volume": 5008400
  },
  {
    "time": "12-12-2011",
    "open": 193.64,
    "high": 193.9,
    "low": 191.22,
    "close": 192.18,
    "volume": 3796100
  },
  {
    "time": "09-12-2011",
    "open": 192.91,
    "high": 194.87,
    "low": 192.4,
    "close": 194.56,
    "volume": 4847900
  },
  {
    "time": "08-12-2011",
    "open": 192.48,
    "high": 193.8,
    "low": 191.07,
    "close": 191.58,
    "volume": 4369000
  },
  {
    "time": "07-12-2011",
    "open": 191.99,
    "high": 194.9,
    "low": 191.28,
    "close": 194.05,
    "volume": 5081700
  },
  {
    "time": "06-12-2011",
    "open": 190.65,
    "high": 193.53,
    "low": 190.32,
    "close": 192.94,
    "volume": 4144900
  },
  {
    "time": "05-12-2011",
    "open": 191.18,
    "high": 193.61,
    "low": 190.38,
    "close": 190.84,
    "volume": 5699400
  },
  {
    "time": "02-12-2011",
    "open": 189.92,
    "high": 191.33,
    "low": 189.45,
    "close": 189.66,
    "volume": 4962200
  },
  {
    "time": "01-12-2011",
    "open": 187.01,
    "high": 189.99,
    "low": 186.77,
    "close": 189.45,
    "volume": 4858800
  },
  {
    "time": "30-11-2011",
    "open": 184.57,
    "high": 188.35,
    "low": 184.21,
    "close": 188,
    "volume": 7836800
  },
  {
    "time": "29-11-2011",
    "open": 182.02,
    "high": 183.31,
    "low": 180.84,
    "close": 180.94,
    "volume": 3661600
  },
  {
    "time": "28-11-2011",
    "open": 182.71,
    "high": 182.93,
    "low": 180.67,
    "close": 182.21,
    "volume": 4524200
  },
  {
    "time": "25-11-2011",
    "open": 178.01,
    "high": 179.56,
    "low": 177.06,
    "close": 177.06,
    "volume": 2232800
  },
  {
    "time": "23-11-2011",
    "open": 180.67,
    "high": 181.49,
    "low": 177.52,
    "close": 177.95,
    "volume": 4800900
  },
  {
    "time": "22-11-2011",
    "open": 182.22,
    "high": 182.98,
    "low": 180.29,
    "close": 181.31,
    "volume": 4178600
  },
  {
    "time": "21-11-2011",
    "open": 183.37,
    "high": 183.96,
    "low": 180,
    "close": 181.48,
    "volume": 5145100
  },
  {
    "time": "18-11-2011",
    "open": 186.71,
    "high": 186.97,
    "low": 184.66,
    "close": 185.24,
    "volume": 4440900
  },
  {
    "time": "17-11-2011",
    "open": 186.81,
    "high": 188.83,
    "low": 183.39,
    "close": 185.73,
    "volume": 5840500
  },
  {
    "time": "16-11-2011",
    "open": 187.82,
    "high": 189.9,
    "low": 186.3,
    "close": 186.62,
    "volume": 4616800
  },
  {
    "time": "15-11-2011",
    "open": 187.49,
    "high": 189.97,
    "low": 185.64,
    "close": 188.75,
    "volume": 4585300
  },
  {
    "time": "14-11-2011",
    "open": 189.17,
    "high": 189.84,
    "low": 186.85,
    "close": 187.35,
    "volume": 6038600
  },
  {
    "time": "11-11-2011",
    "open": 185.27,
    "high": 187.83,
    "low": 185.14,
    "close": 187.38,
    "volume": 3665300
  },
  {
    "time": "10-11-2011",
    "open": 183.58,
    "high": 184.77,
    "low": 181.87,
    "close": 183.35,
    "volume": 3881600
  },
  {
    "time": "09-11-2011",
    "open": 184.2,
    "high": 185.24,
    "low": 181.16,
    "close": 182.24,
    "volume": 5494800
  },
  {
    "time": "08-11-2011",
    "open": 187.48,
    "high": 187.88,
    "low": 185.51,
    "close": 187.25,
    "volume": 4330600
  },
  {
    "time": "07-11-2011",
    "open": 186.22,
    "high": 187.73,
    "low": 184.75,
    "close": 187.32,
    "volume": 3530800
  },
  {
    "time": "04-11-2011",
    "open": 186.27,
    "high": 187.14,
    "low": 184.74,
    "close": 186.38,
    "volume": 3237600
  },
  {
    "time": "03-11-2011",
    "open": 185.13,
    "high": 187.78,
    "low": 184.02,
    "close": 187.3,
    "volume": 4953500
  },
  {
    "time": "02-11-2011",
    "open": 184,
    "high": 185.25,
    "low": 183.03,
    "close": 183.92,
    "volume": 4136600
  },
  {
    "time": "01-11-2011",
    "open": 181.55,
    "high": 183.26,
    "low": 180.74,
    "close": 181.35,
    "volume": 5881200
  },
  {
    "time": "31-10-2011",
    "open": 185.59,
    "high": 186.91,
    "low": 184.63,
    "close": 184.63,
    "volume": 4710000
  },
  {
    "time": "28-10-2011",
    "open": 185.57,
    "high": 188.07,
    "low": 185.28,
    "close": 187.45,
    "volume": 5009800
  },
  {
    "time": "27-10-2011",
    "open": 184.99,
    "high": 187,
    "low": 183.18,
    "close": 186.34,
    "volume": 7057800
  },
  {
    "time": "26-10-2011",
    "open": 181.74,
    "high": 182.28,
    "low": 179.03,
    "close": 181.97,
    "volume": 5387700
  },
  {
    "time": "25-10-2011",
    "open": 181.66,
    "high": 182.97,
    "low": 180.01,
    "close": 180.36,
    "volume": 5373100
  },
  {
    "time": "24-10-2011",
    "open": 181.51,
    "high": 183.39,
    "low": 180.62,
    "close": 182.25,
    "volume": 5890600
  },
  {
    "time": "21-10-2011",
    "open": 179.11,
    "high": 181.67,
    "low": 178.75,
    "close": 181.63,
    "volume": 8054200
  },
  {
    "time": "20-10-2011",
    "open": 178.13,
    "high": 179.24,
    "low": 176.17,
    "close": 177.25,
    "volume": 7513800
  },
  {
    "time": "19-10-2011",
    "open": 179.68,
    "high": 179.81,
    "low": 177.01,
    "close": 177.39,
    "volume": 6708300
  },
  {
    "time": "18-10-2011",
    "open": 178,
    "high": 179.38,
    "low": 176.25,
    "close": 178.9,
    "volume": 15729400
  },
  {
    "time": "17-10-2011",
    "open": 189.71,
    "high": 190.16,
    "low": 185.9,
    "close": 186.59,
    "volume": 8790300
  },
  {
    "time": "14-10-2011",
    "open": 188.19,
    "high": 190.53,
    "low": 187.7314,
    "close": 190.53,
    "volume": 5368200
  },
  {
    "time": "13-10-2011",
    "open": 184.97,
    "high": 187.5,
    "low": 184.5,
    "close": 186.82,
    "volume": 4399200
  },
  {
    "time": "12-10-2011",
    "open": 186.08,
    "high": 188,
    "low": 185.65,
    "close": 186.12,
    "volume": 5338200
  },
  {
    "time": "11-10-2011",
    "open": 184.59,
    "high": 186,
    "low": 184.11,
    "close": 185,
    "volume": 5330500
  },
  {
    "time": "10-10-2011",
    "open": 183,
    "high": 186.63,
    "low": 182.9,
    "close": 186.62,
    "volume": 5784800
  },
  {
    "time": "07-10-2011",
    "open": 182.32,
    "high": 183.72,
    "low": 181.64,
    "close": 182.39,
    "volume": 6842600
  },
  {
    "time": "06-10-2011",
    "open": 176.81,
    "high": 181.99,
    "low": 175.66,
    "close": 181.69,
    "volume": 7180900
  },
  {
    "time": "05-10-2011",
    "open": 174.57,
    "high": 177.2961,
    "low": 172.68,
    "close": 176.85,
    "volume": 5851700
  },
  {
    "time": "04-10-2011",
    "open": 171.54,
    "high": 175.07,
    "low": 168.88,
    "close": 174.74,
    "volume": 9200000
  },
  {
    "time": "03-10-2011",
    "open": 174.36,
    "high": 177.92,
    "low": 173.04,
    "close": 173.29,
    "volume": 9042600
  },
  {
    "time": "30-09-2011",
    "open": 176.01,
    "high": 178.27,
    "low": 174.75,
    "close": 174.87,
    "volume": 7807400
  },
  {
    "time": "29-09-2011",
    "open": 179.69,
    "high": 180.91,
    "low": 175.53,
    "close": 179.17,
    "volume": 6944300
  },
  {
    "time": "28-09-2011",
    "open": 177.97,
    "high": 180.75,
    "low": 177.08,
    "close": 177.55,
    "volume": 7732200
  },
  {
    "time": "27-09-2011",
    "open": 176.82,
    "high": 179.62,
    "low": 176.43,
    "close": 177.71,
    "volume": 7638800
  },
  {
    "time": "26-09-2011",
    "open": 170.96,
    "high": 174.7,
    "low": 169.86,
    "close": 174.51,
    "volume": 6745700
  },
  {
    "time": "23-09-2011",
    "open": 166.99,
    "high": 169.54,
    "low": 165.76,
    "close": 169.34,
    "volume": 5586600
  },
  {
    "time": "22-09-2011",
    "open": 168.33,
    "high": 169.97,
    "low": 166.5,
    "close": 168.62,
    "volume": 8195600
  },
  {
    "time": "21-09-2011",
    "open": 175,
    "high": 177.67,
    "low": 172.62,
    "close": 173.02,
    "volume": 7043100
  },
  {
    "time": "20-09-2011",
    "open": 173.57,
    "high": 177.43,
    "low": 172.7,
    "close": 174.72,
    "volume": 6209600
  },
  {
    "time": "19-09-2011",
    "open": 169.5,
    "high": 173.87,
    "low": 169.43,
    "close": 173.13,
    "volume": 4755600
  },
  {
    "time": "16-09-2011",
    "open": 171.33,
    "high": 172.99,
    "low": 169.74,
    "close": 172.99,
    "volume": 11048100
  },
  {
    "time": "15-09-2011",
    "open": 168.64,
    "high": 170.16,
    "low": 167.23,
    "close": 170.09,
    "volume": 5464400
  },
  {
    "time": "14-09-2011",
    "open": 164.01,
    "high": 169.66,
    "low": 161.99,
    "close": 167.24,
    "volume": 6980700
  },
  {
    "time": "13-09-2011",
    "open": 163.64,
    "high": 163.92,
    "low": 161.54,
    "close": 163.43,
    "volume": 4723800
  },
  {
    "time": "12-09-2011",
    "open": 159.64,
    "high": 162.44,
    "low": 158.76,
    "close": 162.42,
    "volume": 5247200
  },
  {
    "time": "09-09-2011",
    "open": 164.57,
    "high": 165.19,
    "low": 160.81,
    "close": 161.37,
    "volume": 6743900
  },
  {
    "time": "08-09-2011",
    "open": 167,
    "high": 169.58,
    "low": 165.1,
    "close": 165.25,
    "volume": 6027200
  },
  {
    "time": "07-09-2011",
    "open": 167.28,
    "high": 167.9,
    "low": 166.16,
    "close": 167.31,
    "volume": 6796600
  },
  {
    "time": "06-09-2011",
    "open": 163.06,
    "high": 165.55,
    "low": 162.2,
    "close": 165.11,
    "volume": 5761200
  },
  {
    "time": "02-09-2011",
    "open": 167.69,
    "high": 168.93,
    "low": 166,
    "close": 166.98,
    "volume": 4979100
  },
  {
    "time": "01-09-2011",
    "open": 172.71,
    "high": 173.68,
    "low": 170.12,
    "close": 170.33,
    "volume": 5463900
  },
  {
    "time": "31-08-2011",
    "open": 173.29,
    "high": 173.72,
    "low": 170.71,
    "close": 171.91,
    "volume": 6074200
  },
  {
    "time": "30-08-2011",
    "open": 172.06,
    "high": 173.51,
    "low": 170.88,
    "close": 172.51,
    "volume": 4477700
  },
  {
    "time": "29-08-2011",
    "open": 170.58,
    "high": 172.72,
    "low": 170.36,
    "close": 172.62,
    "volume": 5293400
  },
  {
    "time": "26-08-2011",
    "open": 164.85,
    "high": 169.33,
    "low": 163.28,
    "close": 169.14,
    "volume": 6438200
  },
  {
    "time": "25-08-2011",
    "open": 167.52,
    "high": 168.53,
    "low": 164.85,
    "close": 165.58,
    "volume": 6751600
  },
  {
    "time": "24-08-2011",
    "open": 164.13,
    "high": 166.83,
    "low": 163.35,
    "close": 166.76,
    "volume": 6427000
  },
  {
    "time": "23-08-2011",
    "open": 160.65,
    "high": 164.59,
    "low": 159.53,
    "close": 164.32,
    "volume": 6923300
  },
  {
    "time": "22-08-2011",
    "open": 161.35,
    "high": 161.68,
    "low": 157.62,
    "close": 158.98,
    "volume": 7249100
  },
  {
    "time": "19-08-2011",
    "open": 162.17,
    "high": 163.94,
    "low": 157.13,
    "close": 157.54,
    "volume": 14068600
  },
  {
    "time": "18-08-2011",
    "open": 166.32,
    "high": 166.46,
    "low": 160.16,
    "close": 163.83,
    "volume": 15082600
  },
  {
    "time": "17-08-2011",
    "open": 171.57,
    "high": 172.99,
    "low": 169.89,
    "close": 171.48,
    "volume": 5129700
  },
  {
    "time": "16-08-2011",
    "open": 172.19,
    "high": 172.19,
    "low": 169,
    "close": 171.24,
    "volume": 5585400
  },
  {
    "time": "15-08-2011",
    "open": 171.32,
    "high": 174,
    "low": 170.39,
    "close": 172.99,
    "volume": 7645400
  },
  {
    "time": "12-08-2011",
    "open": 168.5,
    "high": 169.38,
    "low": 165.83,
    "close": 168.2,
    "volume": 5187000
  },
  {
    "time": "11-08-2011",
    "open": 164.1,
    "high": 168.77,
    "low": 162.94,
    "close": 166.73,
    "volume": 9175600
  },
  {
    "time": "10-08-2011",
    "open": 168.18,
    "high": 169.23,
    "low": 161.85,
    "close": 162.54,
    "volume": 13993600
  },
  {
    "time": "09-08-2011",
    "open": 167.46,
    "high": 171.05,
    "low": 162,
    "close": 170.61,
    "volume": 12517600
  },
  {
    "time": "08-08-2011",
    "open": 168.83,
    "high": 172.61,
    "low": 166,
    "close": 166.22,
    "volume": 15804900
  },
  {
    "time": "05-08-2011",
    "open": 172.1,
    "high": 174.22,
    "low": 166.52,
    "close": 172.98,
    "volume": 11871800
  },
  {
    "time": "04-08-2011",
    "open": 176.5,
    "high": 177.92,
    "low": 171.18,
    "close": 171.48,
    "volume": 11022300
  },
  {
    "time": "03-08-2011",
    "open": 177.67,
    "high": 179.23,
    "low": 175.41,
    "close": 178.83,
    "volume": 6722000
  },
  {
    "time": "02-08-2011",
    "open": 179.95,
    "high": 182.29,
    "low": 177.86,
    "close": 178.05,
    "volume": 5770400
  },
  {
    "time": "01-08-2011",
    "open": 182.6,
    "high": 183.69,
    "low": 178.5,
    "close": 180.75,
    "volume": 5842700
  },
  {
    "time": "29-07-2011",
    "open": 180.26,
    "high": 183.43,
    "low": 179.73,
    "close": 181.85,
    "volume": 6665600
  },
  {
    "time": "28-07-2011",
    "open": 181.06,
    "high": 183.27,
    "low": 180.88,
    "close": 181.8,
    "volume": 4478800
  },
  {
    "time": "27-07-2011",
    "open": 182.01,
    "high": 182.91,
    "low": 180.93,
    "close": 181.35,
    "volume": 5806800
  },
  {
    "time": "26-07-2011",
    "open": 182.94,
    "high": 184.05,
    "low": 182.65,
    "close": 182.93,
    "volume": 4356200
  },
  {
    "time": "25-07-2011",
    "open": 183.89,
    "high": 184.96,
    "low": 183.28,
    "close": 183.7,
    "volume": 4987000
  },
  {
    "time": "22-07-2011",
    "open": 184.64,
    "high": 185.63,
    "low": 184.26,
    "close": 185.18,
    "volume": 4516100
  },
  {
    "time": "21-07-2011",
    "open": 183.59,
    "high": 185.5,
    "low": 182.9,
    "close": 184.9,
    "volume": 4958400
  },
  {
    "time": "20-07-2011",
    "open": 183.84,
    "high": 184.42,
    "low": 183,
    "close": 183.65,
    "volume": 5901100
  },
  {
    "time": "19-07-2011",
    "open": 178.74,
    "high": 185.21,
    "low": 178.65,
    "close": 185.21,
    "volume": 13245600
  },
  {
    "time": "18-07-2011",
    "open": 174.73,
    "high": 176.46,
    "low": 173.58,
    "close": 175.28,
    "volume": 8879700
  },
  {
    "time": "15-07-2011",
    "open": 175.08,
    "high": 175.94,
    "low": 174.07,
    "close": 175.54,
    "volume": 5347100
  },
  {
    "time": "14-07-2011",
    "open": 174.4,
    "high": 176.1,
    "low": 173.84,
    "close": 174.23,
    "volume": 4613100
  },
  {
    "time": "13-07-2011",
    "open": 174.9,
    "high": 176.32,
    "low": 174,
    "close": 174.32,
    "volume": 4074800
  },
  {
    "time": "12-07-2011",
    "open": 174.93,
    "high": 175.37,
    "low": 173.89,
    "close": 174.05,
    "volume": 5036800
  },
  {
    "time": "11-07-2011",
    "open": 174.9,
    "high": 176.15,
    "low": 174.61,
    "close": 174.99,
    "volume": 4766500
  },
  {
    "time": "08-07-2011",
    "open": 175.49,
    "high": 176.49,
    "low": 175.01,
    "close": 176.49,
    "volume": 4399900
  },
  {
    "time": "07-07-2011",
    "open": 176.78,
    "high": 177.27,
    "low": 176.12,
    "close": 176.48,
    "volume": 5905600
  },
  {
    "time": "06-07-2011",
    "open": 175.19,
    "high": 177.77,
    "low": 175.09,
    "close": 177.71,
    "volume": 5885900
  },
  {
    "time": "05-07-2011",
    "open": 173.52,
    "high": 175.4309,
    "low": 173.52,
    "close": 175.43,
    "volume": 4586500
  },
  {
    "time": "01-07-2011",
    "open": 171.61,
    "high": 174.65,
    "low": 171.49,
    "close": 174.54,
    "volume": 5181700
  },
  {
    "time": "30-06-2011",
    "open": 171.11,
    "high": 172.4468,
    "low": 170.75,
    "close": 171.55,
    "volume": 5105200
  },
  {
    "time": "29-06-2011",
    "open": 170.61,
    "high": 170.86,
    "low": 169.82,
    "close": 170.54,
    "volume": 3817100
  },
  {
    "time": "28-06-2011",
    "open": 168.33,
    "high": 170.7,
    "low": 168.01,
    "close": 170.01,
    "volume": 4850000
  },
  {
    "time": "27-06-2011",
    "open": 165.74,
    "high": 168.24,
    "low": 165.21,
    "close": 167.62,
    "volume": 4446700
  },
  {
    "time": "24-06-2011",
    "open": 165.85,
    "high": 165.94,
    "low": 164.57,
    "close": 165.07,
    "volume": 4402600
  },
  {
    "time": "23-06-2011",
    "open": 164.06,
    "high": 166.73,
    "low": 163.8,
    "close": 166.12,
    "volume": 5271500
  },
  {
    "time": "22-06-2011",
    "open": 165.59,
    "high": 166.81,
    "low": 165.1,
    "close": 165.68,
    "volume": 3960500
  },
  {
    "time": "21-06-2011",
    "open": 165.2,
    "high": 166.75,
    "low": 164,
    "close": 166.22,
    "volume": 5387300
  },
  {
    "time": "20-06-2011",
    "open": 163.7,
    "high": 165.61,
    "low": 163.59,
    "close": 165.02,
    "volume": 3956100
  },
  {
    "time": "17-06-2011",
    "open": 164.24,
    "high": 165.1,
    "low": 163.58,
    "close": 164.44,
    "volume": 7149700
  },
  {
    "time": "16-06-2011",
    "open": 162.06,
    "high": 163.63,
    "low": 161.78,
    "close": 162.67,
    "volume": 4861100
  },
  {
    "time": "15-06-2011",
    "open": 162.75,
    "high": 163.41,
    "low": 161.52,
    "close": 162.33,
    "volume": 5225600
  },
  {
    "time": "14-06-2011",
    "open": 163.87,
    "high": 164.57,
    "low": 163.65,
    "close": 164.12,
    "volume": 7050000
  },
  {
    "time": "13-06-2011",
    "open": 164.44,
    "high": 164.46,
    "low": 162.73,
    "close": 163.17,
    "volume": 5099200
  },
  {
    "time": "10-06-2011",
    "open": 164.57,
    "high": 164.84,
    "low": 162.87,
    "close": 163.18,
    "volume": 4683300
  },
  {
    "time": "09-06-2011",
    "open": 165.01,
    "high": 165.96,
    "low": 164.76,
    "close": 164.84,
    "volume": 4299800
  },
  {
    "time": "08-06-2011",
    "open": 163.27,
    "high": 164.85,
    "low": 163.26,
    "close": 164.34,
    "volume": 3850800
  },
  {
    "time": "07-06-2011",
    "open": 165.11,
    "high": 165.24,
    "low": 163.61,
    "close": 163.69,
    "volume": 4187000
  },
  {
    "time": "06-06-2011",
    "open": 164.76,
    "high": 165.58,
    "low": 164.27,
    "close": 164.75,
    "volume": 3619700
  },
  {
    "time": "03-06-2011",
    "open": 164.3,
    "high": 165.89,
    "low": 164.13,
    "close": 165.05,
    "volume": 5230500
  },
  {
    "time": "02-06-2011",
    "open": 166.44,
    "high": 167.1,
    "low": 165.71,
    "close": 166.09,
    "volume": 3854100
  },
  {
    "time": "01-06-2011",
    "open": 168.9,
    "high": 169.58,
    "low": 166.5,
    "close": 166.56,
    "volume": 5134600
  },
  {
    "time": "31-05-2011",
    "open": 168.44,
    "high": 169.89,
    "low": 167.82,
    "close": 168.93,
    "volume": 9123400
  },
  {
    "time": "27-05-2011",
    "open": 167.91,
    "high": 168.47,
    "low": 167,
    "close": 167.5,
    "volume": 3277600
  },
  {
    "time": "26-05-2011",
    "open": 167.46,
    "high": 167.5,
    "low": 165.9,
    "close": 167.18,
    "volume": 5771300
  },
  {
    "time": "25-05-2011",
    "open": 167.63,
    "high": 168.4,
    "low": 167.51,
    "close": 167.75,
    "volume": 3882100
  },
  {
    "time": "24-05-2011",
    "open": 168.5,
    "high": 168.67,
    "low": 167.51,
    "close": 167.99,
    "volume": 4643600
  },
  {
    "time": "23-05-2011",
    "open": 168.58,
    "high": 168.69,
    "low": 167.07,
    "close": 168.26,
    "volume": 5144000
  },
  {
    "time": "20-05-2011",
    "open": 170.4,
    "high": 171.15,
    "low": 169.38,
    "close": 170.16,
    "volume": 4869900
  },
  {
    "time": "19-05-2011",
    "open": 170.86,
    "high": 171.4,
    "low": 169.32,
    "close": 170.59,
    "volume": 3538300
  },
  {
    "time": "18-05-2011",
    "open": 170.1,
    "high": 171.19,
    "low": 169.46,
    "close": 170.44,
    "volume": 4154300
  },
  {
    "time": "17-05-2011",
    "open": 167.85,
    "high": 171.41,
    "low": 166.53,
    "close": 170.5,
    "volume": 8773200
  },
  {
    "time": "16-05-2011",
    "open": 169.81,
    "high": 170.64,
    "low": 168.31,
    "close": 168.86,
    "volume": 4662200
  },
  {
    "time": "13-05-2011",
    "open": 171.7,
    "high": 172.15,
    "low": 169.44,
    "close": 169.92,
    "volume": 5167400
  },
  {
    "time": "12-05-2011",
    "open": 169.65,
    "high": 172.77,
    "low": 168.65,
    "close": 172.24,
    "volume": 5138500
  },
  {
    "time": "11-05-2011",
    "open": 170.3,
    "high": 170.52,
    "low": 167.82,
    "close": 169.5,
    "volume": 4663200
  },
  {
    "time": "10-05-2011",
    "open": 169.25,
    "high": 170.9,
    "low": 169.22,
    "close": 170.38,
    "volume": 4934800
  },
  {
    "time": "09-05-2011",
    "open": 168.39,
    "high": 169.85,
    "low": 168.31,
    "close": 169.1,
    "volume": 3594800
  },
  {
    "time": "06-05-2011",
    "open": 169.03,
    "high": 170.15,
    "low": 168.24,
    "close": 168.89,
    "volume": 4839400
  },
  {
    "time": "05-05-2011",
    "open": 170.47,
    "high": 170.87,
    "low": 167.5,
    "close": 168.46,
    "volume": 7332000
  },
  {
    "time": "04-05-2011",
    "open": 172.36,
    "high": 172.62,
    "low": 169.59,
    "close": 170.62,
    "volume": 6890300
  },
  {
    "time": "03-05-2011",
    "open": 172,
    "high": 173.48,
    "low": 171.23,
    "close": 172.87,
    "volume": 5007700
  },
  {
    "time": "02-05-2011",
    "open": 172.11,
    "high": 173.54,
    "low": 171.49,
    "close": 172.15,
    "volume": 5396200
  },
  {
    "time": "29-04-2011",
    "open": 171.1,
    "high": 173,
    "low": 170.48,
    "close": 170.58,
    "volume": 5587700
  },
  {
    "time": "28-04-2011",
    "open": 169.99,
    "high": 171.38,
    "low": 169.7,
    "close": 170.78,
    "volume": 5087700
  },
  {
    "time": "27-04-2011",
    "open": 168.61,
    "high": 170.59,
    "low": 168.46,
    "close": 170.37,
    "volume": 5299700
  },
  {
    "time": "26-04-2011",
    "open": 167.99,
    "high": 169.2,
    "low": 167.4,
    "close": 168.49,
    "volume": 7300000
  },
  {
    "time": "25-04-2011",
    "open": 167.65,
    "high": 168.77,
    "low": 167.23,
    "close": 167.67,
    "volume": 3583800
  },
  {
    "time": "21-04-2011",
    "open": 165.63,
    "high": 168.45,
    "low": 164.86,
    "close": 168.28,
    "volume": 6158400
  },
  {
    "time": "20-04-2011",
    "open": 163.1,
    "high": 165.89,
    "low": 162.19,
    "close": 164.75,
    "volume": 10891800
  },
  {
    "time": "19-04-2011",
    "open": 165.71,
    "high": 166.38,
    "low": 164.44,
    "close": 165.4,
    "volume": 6547100
  },
  {
    "time": "18-04-2011",
    "open": 164.64,
    "high": 166.16,
    "low": 162.86,
    "close": 165.94,
    "volume": 6160700
  },
  {
    "time": "15-04-2011",
    "open": 165,
    "high": 166.34,
    "low": 164.87,
    "close": 166.21,
    "volume": 5287400
  },
  {
    "time": "14-04-2011",
    "open": 163.84,
    "high": 165.36,
    "low": 163.16,
    "close": 164.97,
    "volume": 4699100
  },
  {
    "time": "13-04-2011",
    "open": 163.97,
    "high": 164.93,
    "low": 163.66,
    "close": 163.95,
    "volume": 4181900
  },
  {
    "time": "12-04-2011",
    "open": 163.36,
    "high": 163.77,
    "low": 162.3,
    "close": 163.25,
    "volume": 3860600
  },
  {
    "time": "11-04-2011",
    "open": 163.44,
    "high": 164,
    "low": 163.11,
    "close": 163.95,
    "volume": 4139000
  },
  {
    "time": "08-04-2011",
    "open": 164.35,
    "high": 164.38,
    "low": 163.16,
    "close": 164.05,
    "volume": 3324100
  },
  {
    "time": "07-04-2011",
    "open": 164.1,
    "high": 164.5463,
    "low": 163.28,
    "close": 164.38,
    "volume": 3666700
  },
  {
    "time": "06-04-2011",
    "open": 164.34,
    "high": 164.75,
    "low": 163.81,
    "close": 164.04,
    "volume": 3774800
  },
  {
    "time": "05-04-2011",
    "open": 163.81,
    "high": 164.7,
    "low": 163.62,
    "close": 163.99,
    "volume": 3612000
  },
  {
    "time": "04-04-2011",
    "open": 164.42,
    "high": 164.73,
    "low": 163.58,
    "close": 164.25,
    "volume": 3225100
  },
  {
    "time": "01-04-2011",
    "open": 163.7,
    "high": 164.42,
    "low": 163.04,
    "close": 164.27,
    "volume": 4381900
  },
  {
    "time": "31-03-2011",
    "open": 163.4,
    "high": 164.06,
    "low": 163.07,
    "close": 163.07,
    "volume": 3860800
  },
  {
    "time": "30-03-2011",
    "open": 163.55,
    "high": 163.99,
    "low": 163.02,
    "close": 163.6,
    "volume": 3394100
  },
  {
    "time": "29-03-2011",
    "open": 161.34,
    "high": 163.09,
    "low": 161.15,
    "close": 162.88,
    "volume": 3456700
  },
  {
    "time": "28-03-2011",
    "open": 161.54,
    "high": 162.58,
    "low": 161.12,
    "close": 161.37,
    "volume": 3972800
  },
  {
    "time": "25-03-2011",
    "open": 160.98,
    "high": 162.74,
    "low": 160.76,
    "close": 162.18,
    "volume": 5348100
  },
  {
    "time": "24-03-2011",
    "open": 160.23,
    "high": 160.71,
    "low": 158.84,
    "close": 160.04,
    "volume": 5145800
  },
  {
    "time": "23-03-2011",
    "open": 158,
    "high": 160.08,
    "low": 157.53,
    "close": 159.53,
    "volume": 4782500
  },
  {
    "time": "22-03-2011",
    "open": 158.56,
    "high": 158.61,
    "low": 157.52,
    "close": 158,
    "volume": 4311400
  },
  {
    "time": "21-03-2011",
    "open": 157.64,
    "high": 159.63,
    "low": 157.07,
    "close": 157.68,
    "volume": 5784000
  },
  {
    "time": "18-03-2011",
    "open": 156.5,
    "high": 156.95,
    "low": 154.32,
    "close": 155.89,
    "volume": 11450000
  },
  {
    "time": "17-03-2011",
    "open": 154,
    "high": 154.5,
    "low": 152.7,
    "close": 154.18,
    "volume": 7376400
  },
  {
    "time": "16-03-2011",
    "open": 156.52,
    "high": 156.98,
    "low": 151.71,
    "close": 153,
    "volume": 12195300
  },
  {
    "time": "15-03-2011",
    "open": 157.35,
    "high": 159.64,
    "low": 156.88,
    "close": 159.02,
    "volume": 6475800
  },
  {
    "time": "14-03-2011",
    "open": 161.16,
    "high": 161.9804,
    "low": 160.09,
    "close": 161.39,
    "volume": 4300500
  },
  {
    "time": "11-03-2011",
    "open": 162.4,
    "high": 163.24,
    "low": 160.84,
    "close": 162.43,
    "volume": 4271000
  },
  {
    "time": "10-03-2011",
    "open": 164.64,
    "high": 164.67,
    "low": 161.38,
    "close": 162.02,
    "volume": 7041400
  },
  {
    "time": "09-03-2011",
    "open": 163.47,
    "high": 167.72,
    "low": 163.28,
    "close": 165.86,
    "volume": 9086600
  },
  {
    "time": "08-03-2011",
    "open": 159.7,
    "high": 163.43,
    "low": 159.21,
    "close": 162.28,
    "volume": 5384800
  },
  {
    "time": "07-03-2011",
    "open": 161.6,
    "high": 162.98,
    "low": 158.85,
    "close": 159.93,
    "volume": 5312000
  },
  {
    "time": "04-03-2011",
    "open": 163.4,
    "high": 164.31,
    "low": 160.65,
    "close": 161.83,
    "volume": 4910700
  },
  {
    "time": "03-03-2011",
    "open": 161.42,
    "high": 164,
    "low": 161.27,
    "close": 163.48,
    "volume": 4057800
  },
  {
    "time": "02-03-2011",
    "open": 159.43,
    "high": 161.11,
    "low": 159.41,
    "close": 160.16,
    "volume": 3413000
  },
  {
    "time": "01-03-2011",
    "open": 163.15,
    "high": 163.15,
    "low": 159.88,
    "close": 159.97,
    "volume": 4382800
  },
  {
    "time": "28-02-2011",
    "open": 162.36,
    "high": 162.99,
    "low": 161.24,
    "close": 161.88,
    "volume": 4773000
  },
  {
    "time": "25-02-2011",
    "open": 161.06,
    "high": 162.34,
    "low": 160.86,
    "close": 162.28,
    "volume": 4518400
  },
  {
    "time": "24-02-2011",
    "open": 159.63,
    "high": 161.27,
    "low": 159.03,
    "close": 160.77,
    "volume": 5707500
  },
  {
    "time": "23-02-2011",
    "open": 161.81,
    "high": 162.68,
    "low": 160.14,
    "close": 160.18,
    "volume": 5998100
  },
  {
    "time": "22-02-2011",
    "open": 163.57,
    "high": 164.26,
    "low": 161.78,
    "close": 161.95,
    "volume": 5209300
  },
  {
    "time": "18-02-2011",
    "open": 164.46,
    "high": 164.84,
    "low": 164.1,
    "close": 164.84,
    "volume": 4245000
  },
  {
    "time": "17-02-2011",
    "open": 163.3,
    "high": 164.67,
    "low": 162.85,
    "close": 164.24,
    "volume": 3230500
  },
  {
    "time": "16-02-2011",
    "open": 163.33,
    "high": 163.6,
    "low": 162.75,
    "close": 163.4,
    "volume": 3216000
  },
  {
    "time": "15-02-2011",
    "open": 162.89,
    "high": 163.57,
    "low": 162.52,
    "close": 162.84,
    "volume": 3768700
  },
  {
    "time": "14-02-2011",
    "open": 164.18,
    "high": 164.38,
    "low": 162.85,
    "close": 163.22,
    "volume": 4129800
  },
  {
    "time": "11-02-2011",
    "open": 163.98,
    "high": 165.01,
    "low": 163.31,
    "close": 163.85,
    "volume": 5185200
  },
  {
    "time": "10-02-2011",
    "open": 163.9,
    "high": 165,
    "low": 163.18,
    "close": 164.09,
    "volume": 5737800
  },
  {
    "time": "09-02-2011",
    "open": 165.62,
    "high": 165.97,
    "low": 164.1,
    "close": 164.65,
    "volume": 4633600
  },
  {
    "time": "08-02-2011",
    "open": 164.82,
    "high": 166.25,
    "low": 164.32,
    "close": 166.05,
    "volume": 5612600
  },
  {
    "time": "07-02-2011",
    "open": 164.08,
    "high": 164.99,
    "low": 164.02,
    "close": 164.82,
    "volume": 4928100
  },
  {
    "time": "04-02-2011",
    "open": 163.48,
    "high": 164.14,
    "low": 163.22,
    "close": 164,
    "volume": 3755200
  },
  {
    "time": "03-02-2011",
    "open": 163.16,
    "high": 164.2,
    "low": 162.81,
    "close": 163.53,
    "volume": 4683400
  },
  {
    "time": "02-02-2011",
    "open": 163.4,
    "high": 163.6,
    "low": 162.61,
    "close": 163.3,
    "volume": 3904000
  },
  {
    "time": "01-02-2011",
    "open": 162.11,
    "high": 163.94,
    "low": 162,
    "close": 163.56,
    "volume": 5831300
  },
  {
    "time": "31-01-2011",
    "open": 159.18,
    "high": 162,
    "low": 158.68,
    "close": 162,
    "volume": 7197200
  },
  {
    "time": "28-01-2011",
    "open": 161.05,
    "high": 161.9201,
    "low": 158.67,
    "close": 159.21,
    "volume": 6725600
  },
  {
    "time": "27-01-2011",
    "open": 161.43,
    "high": 162.18,
    "low": 160.86,
    "close": 161.07,
    "volume": 4878300
  },
  {
    "time": "26-01-2011",
    "open": 161.67,
    "high": 161.904,
    "low": 160.42,
    "close": 161.04,
    "volume": 5353100
  },
  {
    "time": "25-01-2011",
    "open": 159.21,
    "high": 164.35,
    "low": 159,
    "close": 161.44,
    "volume": 8260800
  },
  {
    "time": "24-01-2011",
    "open": 155.42,
    "high": 159.79,
    "low": 155.33,
    "close": 159.63,
    "volume": 7285100
  },
  {
    "time": "21-01-2011",
    "open": 156.4,
    "high": 156.78,
    "low": 154.96,
    "close": 155.5,
    "volume": 7009000
  },
  {
    "time": "20-01-2011",
    "open": 154.53,
    "high": 155.96,
    "low": 154.45,
    "close": 155.8,
    "volume": 7439900
  },
  {
    "time": "19-01-2011",
    "open": 153.26,
    "high": 156.13,
    "low": 152.8301,
    "close": 155.69,
    "volume": 12141000
  },
  {
    "time": "18-01-2011",
    "open": 149.82,
    "high": 151.465,
    "low": 149.38,
    "close": 150.65,
    "volume": 9176900
  },
  {
    "time": "14-01-2011",
    "open": 148.89,
    "high": 150,
    "low": 148.47,
    "close": 150,
    "volume": 4544200
  },
  {
    "time": "13-01-2011",
    "open": 149.24,
    "high": 149.29,
    "low": 148.25,
    "close": 148.82,
    "volume": 3445800
  },
  {
    "time": "12-01-2011",
    "open": 147.99,
    "high": 149.29,
    "low": 147.67,
    "close": 149.1,
    "volume": 4091500
  },
  {
    "time": "11-01-2011",
    "open": 148.2,
    "high": 148.35,
    "low": 146.75,
    "close": 147.28,
    "volume": 4163600
  },
  {
    "time": "10-01-2011",
    "open": 147.58,
    "high": 148.06,
    "low": 147.23,
    "close": 147.64,
    "volume": 3633400
  },
  {
    "time": "07-01-2011",
    "open": 148.79,
    "high": 148.86,
    "low": 146.94,
    "close": 147.93,
    "volume": 4135700
  },
  {
    "time": "06-01-2011",
    "open": 147.13,
    "high": 148.79,
    "low": 146.82,
    "close": 148.66,
    "volume": 5029200
  },
  {
    "time": "05-01-2011",
    "open": 147.34,
    "high": 147.48,
    "low": 146.73,
    "close": 147.05,
    "volume": 4657400
  },
  {
    "time": "04-01-2011",
    "open": 147.56,
    "high": 148.22,
    "low": 146.64,
    "close": 147.64,
    "volume": 5060100
  },
  {
    "time": "03-01-2011",
    "open": 147.21,
    "high": 148.2,
    "low": 147.14,
    "close": 147.48,
    "volume": 4603800
  },
  {
    "time": "31-12-2010",
    "open": 146.73,
    "high": 147.07,
    "low": 145.96,
    "close": 146.76,
    "volume": 2969800
  },
  {
    "time": "30-12-2010",
    "open": 146.36,
    "high": 147.11,
    "low": 146.25,
    "close": 146.67,
    "volume": 3039100
  },
  {
    "time": "29-12-2010",
    "open": 146,
    "high": 147.5,
    "low": 145.71,
    "close": 146.52,
    "volume": 3661100
  },
  {
    "time": "28-12-2010",
    "open": 145.31,
    "high": 146.15,
    "low": 145.06,
    "close": 145.71,
    "volume": 2699600
  },
  {
    "time": "27-12-2010",
    "open": 145.12,
    "high": 145.7,
    "low": 145,
    "close": 145.34,
    "volume": 2314700
  },
  {
    "time": "23-12-2010",
    "open": 145.89,
    "high": 146.33,
    "low": 145.33,
    "close": 145.89,
    "volume": 2424500
  },
  {
    "time": "22-12-2010",
    "open": 145.66,
    "high": 146.4,
    "low": 145.58,
    "close": 145.95,
    "volume": 3388800
  },
  {
    "time": "21-12-2010",
    "open": 144.99,
    "high": 145.99,
    "low": 144.33,
    "close": 145.74,
    "volume": 3777100
  },
  {
    "time": "20-12-2010",
    "open": 145.39,
    "high": 145.54,
    "low": 144.39,
    "close": 144.51,
    "volume": 3995000
  },
  {
    "time": "17-12-2010",
    "open": 144.69,
    "high": 145.5,
    "low": 144.4,
    "close": 145,
    "volume": 10027000
  },
  {
    "time": "16-12-2010",
    "open": 144.84,
    "high": 145.32,
    "low": 144.15,
    "close": 144.55,
    "volume": 4178600
  },
  {
    "time": "15-12-2010",
    "open": 145.13,
    "high": 145.72,
    "low": 144.31,
    "close": 144.72,
    "volume": 4447400
  },
  {
    "time": "14-12-2010",
    "open": 144.26,
    "high": 146.01,
    "low": 144.26,
    "close": 145.82,
    "volume": 4800800
  },
  {
    "time": "13-12-2010",
    "open": 145.14,
    "high": 145.4,
    "low": 144.21,
    "close": 144.28,
    "volume": 4741000
  },
  {
    "time": "10-12-2010",
    "open": 144.88,
    "high": 144.95,
    "low": 143.73,
    "close": 144.82,
    "volume": 3503800
  },
  {
    "time": "09-12-2010",
    "open": 145.94,
    "high": 145.94,
    "low": 143.52,
    "close": 144.3,
    "volume": 4405300
  },
  {
    "time": "08-12-2010",
    "open": 144.35,
    "high": 145.65,
    "low": 143.84,
    "close": 144.98,
    "volume": 4961400
  },
  {
    "time": "07-12-2010",
    "open": 146.02,
    "high": 146.2999,
    "low": 143.87,
    "close": 144.02,
    "volume": 4828600
  },
  {
    "time": "06-12-2010",
    "open": 144.54,
    "high": 145.87,
    "low": 144.52,
    "close": 144.99,
    "volume": 3321800
  },
  {
    "time": "03-12-2010",
    "open": 144.25,
    "high": 145.68,
    "low": 144.25,
    "close": 145.38,
    "volume": 3710600
  },
  {
    "time": "02-12-2010",
    "open": 144.33,
    "high": 145.85,
    "low": 144.3,
    "close": 145.18,
    "volume": 5374000
  },
  {
    "time": "01-12-2010",
    "open": 143.61,
    "high": 145.13,
    "low": 143.51,
    "close": 144.41,
    "volume": 6822800
  },
  {
    "time": "30-11-2010",
    "open": 142.24,
    "high": 142.76,
    "low": 141.28,
    "close": 141.46,
    "volume": 7674800
  },
  {
    "time": "29-11-2010",
    "open": 143.53,
    "high": 143.67,
    "low": 141.5,
    "close": 142.89,
    "volume": 5040300
  },
  {
    "time": "26-11-2010",
    "open": 145.3,
    "high": 145.3,
    "low": 143.57,
    "close": 143.9,
    "volume": 2081300
  },
  {
    "time": "24-11-2010",
    "open": 143.95,
    "high": 146.44,
    "low": 143.82,
    "close": 145.81,
    "volume": 4874100
  },
  {
    "time": "23-11-2010",
    "open": 144.24,
    "high": 144.53,
    "low": 142.33,
    "close": 143.18,
    "volume": 4570300
  },
  {
    "time": "22-11-2010",
    "open": 144,
    "high": 145.43,
    "low": 143.56,
    "close": 145.39,
    "volume": 3730700
  },
  {
    "time": "19-11-2010",
    "open": 144.38,
    "high": 145.35,
    "low": 143.99,
    "close": 145.05,
    "volume": 5015900
  },
  {
    "time": "18-11-2010",
    "open": 143.04,
    "high": 144.99,
    "low": 142.75,
    "close": 144.36,
    "volume": 4236200
  },
  {
    "time": "17-11-2010",
    "open": 142.49,
    "high": 142.49,
    "low": 141.39,
    "close": 141.95,
    "volume": 4785900
  },
  {
    "time": "16-11-2010",
    "open": 142.93,
    "high": 143.38,
    "low": 141.18,
    "close": 142.24,
    "volume": 6342100
  },
  {
    "time": "15-11-2010",
    "open": 143.89,
    "high": 144.75,
    "low": 143.27,
    "close": 143.64,
    "volume": 3827700
  },
  {
    "time": "12-11-2010",
    "open": 144.59,
    "high": 145.77,
    "low": 143.55,
    "close": 143.74,
    "volume": 4731500
  },
  {
    "time": "11-11-2010",
    "open": 144.7,
    "high": 145.5,
    "low": 143.35,
    "close": 145.43,
    "volume": 4752200
  },
  {
    "time": "10-11-2010",
    "open": 146.09,
    "high": 146.79,
    "low": 145.63,
    "close": 146.55,
    "volume": 5172000
  },
  {
    "time": "09-11-2010",
    "open": 146.7,
    "high": 147.53,
    "low": 145.63,
    "close": 146.14,
    "volume": 5700600
  },
  {
    "time": "08-11-2010",
    "open": 145.35,
    "high": 146.58,
    "low": 145.25,
    "close": 146.46,
    "volume": 4459700
  },
  {
    "time": "05-11-2010",
    "open": 145.94,
    "high": 146.93,
    "low": 145.67,
    "close": 146.92,
    "volume": 4995800
  },
  {
    "time": "04-11-2010",
    "open": 144.82,
    "high": 146.84,
    "low": 144.71,
    "close": 146.79,
    "volume": 6622500
  },
  {
    "time": "03-11-2010",
    "open": 144.24,
    "high": 144.46,
    "low": 142.63,
    "close": 144.17,
    "volume": 5182100
  },
  {
    "time": "02-11-2010",
    "open": 143.85,
    "high": 144.71,
    "low": 143.71,
    "close": 143.84,
    "volume": 4626400
  },
  {
    "time": "01-11-2010",
    "open": 143.64,
    "high": 144.26,
    "low": 142.32,
    "close": 143.32,
    "volume": 5674600
  },
  {
    "time": "29-10-2010",
    "open": 140.9,
    "high": 144,
    "low": 140.75,
    "close": 143.6,
    "volume": 8439300
  },
  {
    "time": "28-10-2010",
    "open": 141.84,
    "high": 141.95,
    "low": 140.42,
    "close": 140.9,
    "volume": 5481600
  },
  {
    "time": "27-10-2010",
    "open": 139.52,
    "high": 141.57,
    "low": 139,
    "close": 141.43,
    "volume": 6465300
  },
  {
    "time": "26-10-2010",
    "open": 139.29,
    "high": 142,
    "low": 138.53,
    "close": 140.67,
    "volume": 7327100
  },
  {
    "time": "25-10-2010",
    "open": 140.42,
    "high": 141.4,
    "low": 139.81,
    "close": 139.84,
    "volume": 4870000
  },
  {
    "time": "22-10-2010",
    "open": 140.15,
    "high": 140.75,
    "low": 139.46,
    "close": 139.67,
    "volume": 4105300
  },
  {
    "time": "21-10-2010",
    "open": 139.81,
    "high": 140.49,
    "low": 138.4,
    "close": 139.83,
    "volume": 6221200
  },
  {
    "time": "20-10-2010",
    "open": 138.28,
    "high": 139.87,
    "low": 138.13,
    "close": 139.07,
    "volume": 6945500
  },
  {
    "time": "19-10-2010",
    "open": 137.3,
    "high": 139.34,
    "low": 136.7,
    "close": 138.03,
    "volume": 14159100
  },
  {
    "time": "18-10-2010",
    "open": 140.9,
    "high": 143.03,
    "low": 140.84,
    "close": 142.83,
    "volume": 10322700
  },
  {
    "time": "15-10-2010",
    "open": 142.1,
    "high": 142.1,
    "low": 140.54,
    "close": 141.06,
    "volume": 7220400
  },
  {
    "time": "14-10-2010",
    "open": 140.35,
    "high": 141.5,
    "low": 139.69,
    "close": 141.5,
    "volume": 5653100
  },
  {
    "time": "13-10-2010",
    "open": 139.91,
    "high": 141.48,
    "low": 139.78,
    "close": 140.37,
    "volume": 8784300
  },
  {
    "time": "12-10-2010",
    "open": 138.4,
    "high": 139.99,
    "low": 138.27,
    "close": 139.85,
    "volume": 5637300
  },
  {
    "time": "11-10-2010",
    "open": 138.79,
    "high": 139.94,
    "low": 138.64,
    "close": 139.66,
    "volume": 4004300
  },
  {
    "time": "08-10-2010",
    "open": 138.4,
    "high": 139.09,
    "low": 138.06,
    "close": 138.85,
    "volume": 4540800
  },
  {
    "time": "07-10-2010",
    "open": 137.92,
    "high": 138.88,
    "low": 137.52,
    "close": 138.72,
    "volume": 6440800
  },
  {
    "time": "06-10-2010",
    "open": 136.88,
    "high": 137.96,
    "low": 136.83,
    "close": 137.84,
    "volume": 4783000
  },
  {
    "time": "05-10-2010",
    "open": 136.18,
    "high": 137.9015,
    "low": 136.12,
    "close": 137.66,
    "volume": 6202500
  },
  {
    "time": "04-10-2010",
    "open": 135.23,
    "high": 135.92,
    "low": 134.39,
    "close": 135.25,
    "volume": 4059900
  },
  {
    "time": "01-10-2010",
    "open": 135.51,
    "high": 136.28,
    "low": 135.09,
    "close": 135.64,
    "volume": 5621200
  },
  {
    "time": "30-09-2010",
    "open": 135.5,
    "high": 136.11,
    "low": 133.67,
    "close": 134.14,
    "volume": 6425800
  },
  {
    "time": "29-09-2010",
    "open": 134.4,
    "high": 135.805,
    "low": 134.34,
    "close": 135.48,
    "volume": 5151200
  },
  {
    "time": "28-09-2010",
    "open": 134.67,
    "high": 135.14,
    "low": 133.71,
    "close": 134.89,
    "volume": 7481300
  },
  {
    "time": "27-09-2010",
    "open": 133.51,
    "high": 135,
    "low": 133.51,
    "close": 134.65,
    "volume": 6155400
  },
  {
    "time": "24-09-2010",
    "open": 132.42,
    "high": 134.15,
    "low": 132.34,
    "close": 134.11,
    "volume": 7122400
  },
  {
    "time": "23-09-2010",
    "open": 131.42,
    "high": 132.78,
    "low": 131.22,
    "close": 131.67,
    "volume": 3898800
  },
  {
    "time": "22-09-2010",
    "open": 131.61,
    "high": 132.58,
    "low": 131.4,
    "close": 132.57,
    "volume": 4922400
  },
  {
    "time": "21-09-2010",
    "open": 131.45,
    "high": 132.8,
    "low": 130.78,
    "close": 131.98,
    "volume": 5267900
  },
  {
    "time": "20-09-2010",
    "open": 130.24,
    "high": 132.09,
    "low": 130.11,
    "close": 131.79,
    "volume": 7145500
  },
  {
    "time": "17-09-2010",
    "open": 129.92,
    "high": 130.6,
    "low": 129.7,
    "close": 130.19,
    "volume": 10397400
  },
  {
    "time": "16-09-2010",
    "open": 129.2,
    "high": 129.95,
    "low": 128.8,
    "close": 129.67,
    "volume": 4685000
  },
  {
    "time": "15-09-2010",
    "open": 128.65,
    "high": 129.96,
    "low": 128.54,
    "close": 129.43,
    "volume": 4762500
  },
  {
    "time": "14-09-2010",
    "open": 128.43,
    "high": 129.92,
    "low": 128.43,
    "close": 128.85,
    "volume": 4850500
  },
  {
    "time": "13-09-2010",
    "open": 128.63,
    "high": 130,
    "low": 128.52,
    "close": 129.61,
    "volume": 5271300
  },
  {
    "time": "10-09-2010",
    "open": 126.99,
    "high": 128.29,
    "low": 126.26,
    "close": 127.99,
    "volume": 4418700
  },
  {
    "time": "09-09-2010",
    "open": 127.27,
    "high": 127.57,
    "low": 126.15,
    "close": 126.36,
    "volume": 3436700
  },
  {
    "time": "08-09-2010",
    "open": 126.02,
    "high": 126.5199,
    "low": 125.39,
    "close": 126.08,
    "volume": 4477500
  },
  {
    "time": "07-09-2010",
    "open": 126.99,
    "high": 127.36,
    "low": 125.8801,
    "close": 125.95,
    "volume": 4353300
  },
  {
    "time": "03-09-2010",
    "open": 126.22,
    "high": 127.6,
    "low": 125.9001,
    "close": 127.58,
    "volume": 5142900
  },
  {
    "time": "02-09-2010",
    "open": 126.03,
    "high": 126.23,
    "low": 124.65,
    "close": 125.04,
    "volume": 5103300
  },
  {
    "time": "01-09-2010",
    "open": 125.31,
    "high": 126.31,
    "low": 124.52,
    "close": 125.77,
    "volume": 5623100
  },
  {
    "time": "31-08-2010",
    "open": 123.31,
    "high": 123.99,
    "low": 122.28,
    "close": 123.13,
    "volume": 5624200
  },
  {
    "time": "30-08-2010",
    "open": 125.08,
    "high": 125.2699,
    "low": 123.4,
    "close": 123.4,
    "volume": 3604000
  },
  {
    "time": "27-08-2010",
    "open": 123.83,
    "high": 124.8,
    "low": 122.42,
    "close": 124.73,
    "volume": 6214200
  },
  {
    "time": "26-08-2010",
    "open": 125.78,
    "high": 125.78,
    "low": 122.78,
    "close": 122.78,
    "volume": 7746600
  },
  {
    "time": "25-08-2010",
    "open": 124.57,
    "high": 125.6,
    "low": 124,
    "close": 125.27,
    "volume": 5235300
  },
  {
    "time": "24-08-2010",
    "open": 125.21,
    "high": 126.02,
    "low": 124.57,
    "close": 124.9,
    "volume": 5621200
  },
  {
    "time": "23-08-2010",
    "open": 127.57,
    "high": 128.03,
    "low": 126.44,
    "close": 126.47,
    "volume": 4034400
  },
  {
    "time": "20-08-2010",
    "open": 128.72,
    "high": 128.98,
    "low": 126.96,
    "close": 127.5,
    "volume": 6246300
  },
  {
    "time": "19-08-2010",
    "open": 128.67,
    "high": 129.59,
    "low": 128.02,
    "close": 128.9,
    "volume": 5402400
  },
  {
    "time": "18-08-2010",
    "open": 128.87,
    "high": 130.195,
    "low": 127.8,
    "close": 129.3925,
    "volume": 4883200
  },
  {
    "time": "17-08-2010",
    "open": 128.83,
    "high": 129.85,
    "low": 127.905,
    "close": 128.45,
    "volume": 4298300
  },
  {
    "time": "16-08-2010",
    "open": 127.47,
    "high": 128.23,
    "low": 126.96,
    "close": 127.77,
    "volume": 4009200
  },
  {
    "time": "13-08-2010",
    "open": 127.96,
    "high": 128.46,
    "low": 127.33,
    "close": 127.87,
    "volume": 4669400
  },
  {
    "time": "12-08-2010",
    "open": 127.69,
    "high": 128.78,
    "low": 127.52,
    "close": 128.3,
    "volume": 5132200
  },
  {
    "time": "11-08-2010",
    "open": 130.69,
    "high": 130.69,
    "low": 129.461,
    "close": 129.83,
    "volume": 6316100
  },
  {
    "time": "10-08-2010",
    "open": 131.18,
    "high": 132.49,
    "low": 130.77,
    "close": 131.84,
    "volume": 5471700
  },
  {
    "time": "09-08-2010",
    "open": 130.79,
    "high": 132.34,
    "low": 130.4,
    "close": 132,
    "volume": 6131700
  },
  {
    "time": "06-08-2010",
    "open": 130.41,
    "high": 130.48,
    "low": 128.76,
    "close": 130.14,
    "volume": 6136200
  },
  {
    "time": "05-08-2010",
    "open": 130.73,
    "high": 131.98,
    "low": 130.53,
    "close": 131.83,
    "volume": 4520600
  },
  {
    "time": "04-08-2010",
    "open": 130.46,
    "high": 131.5,
    "low": 129.85,
    "close": 131.27,
    "volume": 4567500
  },
  {
    "time": "03-08-2010",
    "open": 130.03,
    "high": 131.04,
    "low": 129.33,
    "close": 130.37,
    "volume": 5091800
  },
  {
    "time": "02-08-2010",
    "open": 129.25,
    "high": 131.2,
    "low": 129.25,
    "close": 130.76,
    "volume": 6437500
  },
  {
    "time": "30-07-2010",
    "open": 127.43,
    "high": 128.98,
    "low": 127.04,
    "close": 128.4,
    "volume": 6021000
  },
  {
    "time": "29-07-2010",
    "open": 129.06,
    "high": 129.5,
    "low": 127.14,
    "close": 128.02,
    "volume": 8994200
  },
  {
    "time": "28-07-2010",
    "open": 128.67,
    "high": 129.35,
    "low": 127.88,
    "close": 128.43,
    "volume": 4248800
  },
  {
    "time": "27-07-2010",
    "open": 128.78,
    "high": 129.17,
    "low": 127.89,
    "close": 128.63,
    "volume": 4648200
  },
  {
    "time": "26-07-2010",
    "open": 128.18,
    "high": 128.43,
    "low": 127.14,
    "close": 128.41,
    "volume": 5174900
  },
  {
    "time": "23-07-2010",
    "open": 127.3,
    "high": 128.8,
    "low": 127,
    "close": 128.38,
    "volume": 5078000
  },
  {
    "time": "22-07-2010",
    "open": 126.32,
    "high": 127.78,
    "low": 126.05,
    "close": 127.47,
    "volume": 6909000
  },
  {
    "time": "21-07-2010",
    "open": 126.44,
    "high": 126.5,
    "low": 124.62,
    "close": 125.27,
    "volume": 8615500
  },
  {
    "time": "20-07-2010",
    "open": 122.97,
    "high": 126.56,
    "low": 122.93,
    "close": 126.55,
    "volume": 16337400
  },
  {
    "time": "19-07-2010",
    "open": 128.67,
    "high": 130.38,
    "low": 128.37,
    "close": 129.79,
    "volume": 8388600
  },
  {
    "time": "16-07-2010",
    "open": 129.96,
    "high": 130.15,
    "low": 127.85,
    "close": 128.03,
    "volume": 7002900
  },
  {
    "time": "15-07-2010",
    "open": 129.87,
    "high": 130.92,
    "low": 129.55,
    "close": 130.72,
    "volume": 6186800
  },
  {
    "time": "14-07-2010",
    "open": 129.32,
    "high": 131.6,
    "low": 129.14,
    "close": 130.72,
    "volume": 6607200
  },
  {
    "time": "13-07-2010",
    "open": 128.97,
    "high": 130.98,
    "low": 128.69,
    "close": 130.48,
    "volume": 6687700
  },
  {
    "time": "12-07-2010",
    "open": 127.37,
    "high": 128.83,
    "low": 127.16,
    "close": 128.67,
    "volume": 4206900
  },
  {
    "time": "09-07-2010",
    "open": 127.9,
    "high": 128.2,
    "low": 127.29,
    "close": 127.96,
    "volume": 3898000
  },
  {
    "time": "08-07-2010",
    "open": 127.37,
    "high": 128.15,
    "low": 126.74,
    "close": 127.97,
    "volume": 5439300
  },
  {
    "time": "07-07-2010",
    "open": 123.47,
    "high": 127.12,
    "low": 123.47,
    "close": 127,
    "volume": 7090300
  },
  {
    "time": "06-07-2010",
    "open": 123.58,
    "high": 124.63,
    "low": 122.17,
    "close": 123.46,
    "volume": 6348700
  },
  {
    "time": "02-07-2010",
    "open": 123.29,
    "high": 123.29,
    "low": 120.61,
    "close": 121.86,
    "volume": 6454500
  },
  {
    "time": "01-07-2010",
    "open": 123.55,
    "high": 124.21,
    "low": 121.61,
    "close": 122.57,
    "volume": 9742100
  },
  {
    "time": "30-06-2010",
    "open": 124.83,
    "high": 125.22,
    "low": 123,
    "close": 123.48,
    "volume": 8018000
  },
  {
    "time": "29-06-2010",
    "open": 127.35,
    "high": 128.4,
    "low": 124.12,
    "close": 125.09,
    "volume": 9376200
  },
  {
    "time": "28-06-2010",
    "open": 127.65,
    "high": 129.47,
    "low": 127.22,
    "close": 128.98,
    "volume": 6335100
  },
  {
    "time": "25-06-2010",
    "open": 128.34,
    "high": 129.095,
    "low": 127.12,
    "close": 127.12,
    "volume": 10420400
  },
  {
    "time": "24-06-2010",
    "open": 129.57,
    "high": 129.73,
    "low": 127.7,
    "close": 128.19,
    "volume": 5565500
  },
  {
    "time": "23-06-2010",
    "open": 129.25,
    "high": 131.47,
    "low": 129.09,
    "close": 130.11,
    "volume": 6855800
  },
  {
    "time": "22-06-2010",
    "open": 130.37,
    "high": 131.47,
    "low": 129.07,
    "close": 129.3,
    "volume": 6030200
  },
  {
    "time": "21-06-2010",
    "open": 131.42,
    "high": 131.94,
    "low": 130.22,
    "close": 130.65,
    "volume": 6857800
  },
  {
    "time": "18-06-2010",
    "open": 131.02,
    "high": 131.25,
    "low": 130.13,
    "close": 130.15,
    "volume": 9581400
  },
  {
    "time": "17-06-2010",
    "open": 130.07,
    "high": 131.03,
    "low": 129.86,
    "close": 130.98,
    "volume": 5571000
  },
  {
    "time": "16-06-2010",
    "open": 128.34,
    "high": 130.68,
    "low": 128.34,
    "close": 130.35,
    "volume": 6401000
  },
  {
    "time": "15-06-2010",
    "open": 128.93,
    "high": 129.95,
    "low": 128.37,
    "close": 129.79,
    "volume": 6652500
  },
  {
    "time": "14-06-2010",
    "open": 128.5,
    "high": 129.97,
    "low": 128.49,
    "close": 128.5,
    "volume": 6753000
  },
  {
    "time": "11-06-2010",
    "open": 126.73,
    "high": 128.8,
    "low": 126.44,
    "close": 128.45,
    "volume": 5820200
  },
  {
    "time": "10-06-2010",
    "open": 125.99,
    "high": 128.22,
    "low": 125.8,
    "close": 127.68,
    "volume": 7479700
  },
  {
    "time": "09-06-2010",
    "open": 124.83,
    "high": 125.84,
    "low": 123.58,
    "close": 123.9,
    "volume": 7795500
  },
  {
    "time": "08-06-2010",
    "open": 124.26,
    "high": 124.46,
    "low": 122.82,
    "close": 123.72,
    "volume": 8399200
  },
  {
    "time": "07-06-2010",
    "open": 125.57,
    "high": 125.86,
    "low": 124.13,
    "close": 124.13,
    "volume": 6951400
  },
  {
    "time": "04-06-2010",
    "open": 126.37,
    "high": 127.1,
    "low": 124.67,
    "close": 125.28,
    "volume": 9669200
  },
  {
    "time": "03-06-2010",
    "open": 127.75,
    "high": 128.22,
    "low": 126.46,
    "close": 127.96,
    "volume": 6645300
  },
  {
    "time": "02-06-2010",
    "open": 124.85,
    "high": 127.5,
    "low": 124.35,
    "close": 127.41,
    "volume": 7705300
  },
  {
    "time": "01-06-2010",
    "open": 124.69,
    "high": 126.88,
    "low": 124.2,
    "close": 124.34,
    "volume": 7135400
  },
  {
    "time": "28-05-2010",
    "open": 125.96,
    "high": 126.2794,
    "low": 124.29,
    "close": 125.26,
    "volume": 7421900
  },
  {
    "time": "27-05-2010",
    "open": 125.05,
    "high": 126.39,
    "low": 124.77,
    "close": 126.39,
    "volume": 7725900
  },
  {
    "time": "26-05-2010",
    "open": 124.89,
    "high": 125.94,
    "low": 123,
    "close": 123.23,
    "volume": 9083200
  },
  {
    "time": "25-05-2010",
    "open": 121.47,
    "high": 124.95,
    "low": 121.47,
    "close": 124.52,
    "volume": 9494800
  },
  {
    "time": "24-05-2010",
    "open": 125.26,
    "high": 126.02,
    "low": 124.04,
    "close": 124.45,
    "volume": 6868600
  },
  {
    "time": "21-05-2010",
    "open": 122.16,
    "high": 125.61,
    "low": 121.4,
    "close": 125.42,
    "volume": 12639500
  },
  {
    "time": "20-05-2010",
    "open": 127.22,
    "high": 127.96,
    "low": 123.68,
    "close": 123.8,
    "volume": 13176500
  },
  {
    "time": "19-05-2010",
    "open": 129.37,
    "high": 130.5,
    "low": 127.82,
    "close": 128.86,
    "volume": 8669300
  },
  {
    "time": "18-05-2010",
    "open": 131.26,
    "high": 131.99,
    "low": 129.9,
    "close": 129.95,
    "volume": 9330000
  },
  {
    "time": "17-05-2010",
    "open": 130.68,
    "high": 131.76,
    "low": 128.7,
    "close": 130.44,
    "volume": 8923400
  },
  {
    "time": "14-05-2010",
    "open": 131.06,
    "high": 131.67,
    "low": 129.41,
    "close": 131.19,
    "volume": 9920100
  },
  {
    "time": "13-05-2010",
    "open": 130.93,
    "high": 133.1,
    "low": 130.85,
    "close": 131.48,
    "volume": 10497800
  },
  {
    "time": "12-05-2010",
    "open": 127.16,
    "high": 132.85,
    "low": 127.01,
    "close": 132.68,
    "volume": 16626900
  },
  {
    "time": "11-05-2010",
    "open": 125.21,
    "high": 128.42,
    "low": 125.15,
    "close": 126.89,
    "volume": 6498900
  },
  {
    "time": "10-05-2010",
    "open": 126.27,
    "high": 126.67,
    "low": 125.06,
    "close": 126.27,
    "volume": 8463900
  },
  {
    "time": "07-05-2010",
    "open": 123.36,
    "high": 124.39,
    "low": 120,
    "close": 122.1,
    "volume": 10584800
  },
  {
    "time": "06-05-2010",
    "open": 126.29,
    "high": 127.93,
    "low": 116,
    "close": 123.92,
    "volume": 13168800
  },
  {
    "time": "05-05-2010",
    "open": 127.12,
    "high": 128.23,
    "low": 126.87,
    "close": 127.46,
    "volume": 6072800
  },
  {
    "time": "04-05-2010",
    "open": 128.89,
    "high": 128.93,
    "low": 126.5754,
    "close": 128.12,
    "volume": 8285000
  },
  {
    "time": "03-05-2010",
    "open": 129.39,
    "high": 130.14,
    "low": 128.8,
    "close": 129.6,
    "volume": 4992100
  },
  {
    "time": "30-04-2010",
    "open": 130.43,
    "high": 130.636,
    "low": 128.84,
    "close": 129,
    "volume": 6266700
  },
  {
    "time": "29-04-2010",
    "open": 130.55,
    "high": 131.21,
    "low": 130.15,
    "close": 130.46,
    "volume": 5786900
  },
  {
    "time": "28-04-2010",
    "open": 129.4,
    "high": 130.47,
    "low": 129.03,
    "close": 130.1,
    "volume": 7122300
  },
  {
    "time": "27-04-2010",
    "open": 129.9,
    "high": 132,
    "low": 128.71,
    "close": 128.82,
    "volume": 10916700
  },
  {
    "time": "26-04-2010",
    "open": 129.76,
    "high": 131.04,
    "low": 129.54,
    "close": 130.73,
    "volume": 5285400
  },
  {
    "time": "23-04-2010",
    "open": 129.08,
    "high": 130.1,
    "low": 128.71,
    "close": 129.99,
    "volume": 6197300
  },
  {
    "time": "22-04-2010",
    "open": 128.64,
    "high": 129.36,
    "low": 127.77,
    "close": 129.13,
    "volume": 6018500
  },
  {
    "time": "21-04-2010",
    "open": 129.87,
    "high": 130.27,
    "low": 128.5,
    "close": 128.99,
    "volume": 7559200
  },
  {
    "time": "20-04-2010",
    "open": 129.2,
    "high": 130.33,
    "low": 128.26,
    "close": 129.69,
    "volume": 15216500
  },
  {
    "time": "19-04-2010",
    "open": 130.38,
    "high": 132.28,
    "low": 130.38,
    "close": 132.23,
    "volume": 11353700
  },
  {
    "time": "16-04-2010",
    "open": 130.68,
    "high": 132.17,
    "low": 130.25,
    "close": 130.63,
    "volume": 9546200
  },
  {
    "time": "15-04-2010",
    "open": 130.53,
    "high": 131.14,
    "low": 130.1902,
    "close": 130.89,
    "volume": 6425300
  },
  {
    "time": "14-04-2010",
    "open": 129.73,
    "high": 131.42,
    "low": 129.46,
    "close": 131.25,
    "volume": 8545800
  },
  {
    "time": "13-04-2010",
    "open": 128.26,
    "high": 129.435,
    "low": 127.84,
    "close": 129.03,
    "volume": 6819100
  },
  {
    "time": "12-04-2010",
    "open": 128.57,
    "high": 128.956,
    "low": 128.24,
    "close": 128.36,
    "volume": 3992300
  },
  {
    "time": "09-04-2010",
    "open": 127.88,
    "high": 128.87,
    "low": 127.12,
    "close": 128.76,
    "volume": 5185100
  },
  {
    "time": "08-04-2010",
    "open": 128.04,
    "high": 128.23,
    "low": 127.2,
    "close": 127.61,
    "volume": 6006900
  },
  {
    "time": "07-04-2010",
    "open": 128.53,
    "high": 129.27,
    "low": 128.01,
    "close": 128.48,
    "volume": 5157000
  },
  {
    "time": "06-04-2010",
    "open": 128.68,
    "high": 129.3,
    "low": 128.05,
    "close": 128.93,
    "volume": 3926300
  },
  {
    "time": "05-04-2010",
    "open": 128.38,
    "high": 129.8,
    "low": 128.14,
    "close": 129.35,
    "volume": 4118700
  },
  {
    "time": "01-04-2010",
    "open": 128.95,
    "high": 129.31,
    "low": 127.55,
    "close": 128.25,
    "volume": 4980500
  },
  {
    "time": "31-03-2010",
    "open": 128.23,
    "high": 128.75,
    "low": 127.65,
    "close": 128.25,
    "volume": 4904800
  },
  {
    "time": "30-03-2010",
    "open": 128.9,
    "high": 129.13,
    "low": 128.25,
    "close": 128.77,
    "volume": 3426600
  },
  {
    "time": "29-03-2010",
    "open": 129.3,
    "high": 129.95,
    "low": 128.26,
    "close": 128.59,
    "volume": 4643000
  },
  {
    "time": "26-03-2010",
    "open": 128.93,
    "high": 129.78,
    "low": 128.7205,
    "close": 129.26,
    "volume": 5550800
  },
  {
    "time": "25-03-2010",
    "open": 129.36,
    "high": 130.73,
    "low": 129.13,
    "close": 129.24,
    "volume": 7603200
  },
  {
    "time": "24-03-2010",
    "open": 128.63,
    "high": 129.95,
    "low": 128.47,
    "close": 128.53,
    "volume": 6669300
  },
  {
    "time": "23-03-2010",
    "open": 127.94,
    "high": 129.43,
    "low": 127.64,
    "close": 129.37,
    "volume": 5978500
  },
  {
    "time": "22-03-2010",
    "open": 127.11,
    "high": 128.39,
    "low": 126.57,
    "close": 127.98,
    "volume": 5651900
  },
  {
    "time": "19-03-2010",
    "open": 128.84,
    "high": 128.93,
    "low": 126.78,
    "close": 127.71,
    "volume": 10744300
  },
  {
    "time": "18-03-2010",
    "open": 127.46,
    "high": 128.75,
    "low": 127.45,
    "close": 128.38,
    "volume": 4954400
  },
  {
    "time": "17-03-2010",
    "open": 128.9,
    "high": 128.93,
    "low": 127.36,
    "close": 127.76,
    "volume": 6348400
  },
  {
    "time": "16-03-2010",
    "open": 128,
    "high": 128.88,
    "low": 127.45,
    "close": 128.67,
    "volume": 6134900
  },
  {
    "time": "15-03-2010",
    "open": 127.4,
    "high": 128.34,
    "low": 127.28,
    "close": 127.83,
    "volume": 4497700
  },
  {
    "time": "12-03-2010",
    "open": 127.91,
    "high": 128.37,
    "low": 127.51,
    "close": 127.94,
    "volume": 5169500
  },
  {
    "time": "11-03-2010",
    "open": 125.71,
    "high": 127.81,
    "low": 125.71,
    "close": 127.6,
    "volume": 7922200
  },
  {
    "time": "10-03-2010",
    "open": 125.985,
    "high": 126.36,
    "low": 125.21,
    "close": 125.62,
    "volume": 6916200
  },
  {
    "time": "09-03-2010",
    "open": 126.27,
    "high": 126.29,
    "low": 125.2,
    "close": 125.55,
    "volume": 7528800
  },
  {
    "time": "08-03-2010",
    "open": 127.06,
    "high": 127.5,
    "low": 126.36,
    "close": 126.41,
    "volume": 6199300
  },
  {
    "time": "05-03-2010",
    "open": 127.17,
    "high": 127.55,
    "low": 127.04,
    "close": 127.25,
    "volume": 6140200
  },
  {
    "time": "04-03-2010",
    "open": 126.88,
    "high": 127.07,
    "low": 125.47,
    "close": 126.72,
    "volume": 6032300
  },
  {
    "time": "03-03-2010",
    "open": 127.73,
    "high": 128.02,
    "low": 126.68,
    "close": 126.88,
    "volume": 6390000
  },
  {
    "time": "02-03-2010",
    "open": 128.7,
    "high": 129.09,
    "low": 127.13,
    "close": 127.42,
    "volume": 6013300
  },
  {
    "time": "01-03-2010",
    "open": 127.5,
    "high": 128.83,
    "low": 127.47,
    "close": 128.57,
    "volume": 4577700
  },
  {
    "time": "26-02-2010",
    "open": 127.01,
    "high": 128,
    "low": 126.74,
    "close": 127.16,
    "volume": 4784200
  },
  {
    "time": "25-02-2010",
    "open": 126.05,
    "high": 127.24,
    "low": 125.57,
    "close": 127.07,
    "volume": 5658700
  },
  {
    "time": "24-02-2010",
    "open": 127.02,
    "high": 128.27,
    "low": 126.81,
    "close": 127.59,
    "volume": 4782000
  },
  {
    "time": "23-02-2010",
    "open": 126.48,
    "high": 127.66,
    "low": 126,
    "close": 126.46,
    "volume": 4591900
  },
  {
    "time": "22-02-2010",
    "open": 127.3,
    "high": 127.43,
    "low": 126.31,
    "close": 126.85,
    "volume": 3808100
  },
  {
    "time": "19-02-2010",
    "open": 127.35,
    "high": 128.06,
    "low": 126.8724,
    "close": 127.19,
    "volume": 6303100
  },
  {
    "time": "18-02-2010",
    "open": 126.13,
    "high": 128,
    "low": 126,
    "close": 127.81,
    "volume": 5525900
  },
  {
    "time": "17-02-2010",
    "open": 125.5,
    "high": 126.53,
    "low": 125.21,
    "close": 126.33,
    "volume": 5827400
  },
  {
    "time": "16-02-2010",
    "open": 124.91,
    "high": 125.23,
    "low": 124.11,
    "close": 125.23,
    "volume": 6777300
  },
  {
    "time": "12-02-2010",
    "open": 123.01,
    "high": 124.05,
    "low": 121.61,
    "close": 124,
    "volume": 8017700
  },
  {
    "time": "11-02-2010",
    "open": 122.58,
    "high": 124.2,
    "low": 122.06,
    "close": 123.73,
    "volume": 5089000
  },
  {
    "time": "10-02-2010",
    "open": 122.94,
    "high": 123.65,
    "low": 122.21,
    "close": 122.81,
    "volume": 5219100
  },
  {
    "time": "09-02-2010",
    "open": 122.65,
    "high": 124.2,
    "low": 122.46,
    "close": 123.21,
    "volume": 6044500
  },
  {
    "time": "08-02-2010",
    "open": 123.15,
    "high": 123.22,
    "low": 121.74,
    "close": 121.88,
    "volume": 5718500
  },
  {
    "time": "05-02-2010",
    "open": 123.04,
    "high": 123.72,
    "low": 121.83,
    "close": 122.97,
    "volume": 8617000
  },
  {
    "time": "04-02-2010",
    "open": 125.19,
    "high": 125.44,
    "low": 122.9,
    "close": 123,
    "volume": 9126900
  },
  {
    "time": "03-02-2010",
    "open": 125.16,
    "high": 126.07,
    "low": 125.07,
    "close": 125.66,
    "volume": 4177100
  },
  {
    "time": "02-02-2010",
    "open": 124.79,
    "high": 125.81,
    "low": 123.95,
    "close": 125.53,
    "volume": 5899900
  },
  {
    "time": "01-02-2010",
    "open": 123.23,
    "high": 124.95,
    "low": 122.78,
    "close": 124.67,
    "volume": 7242900
  },
  {
    "time": "29-01-2010",
    "open": 124.32,
    "high": 125,
    "low": 121.9,
    "close": 122.39,
    "volume": 11571200
  },
  {
    "time": "28-01-2010",
    "open": 127.03,
    "high": 127.04,
    "low": 123.05,
    "close": 123.75,
    "volume": 9622200
  },
  {
    "time": "27-01-2010",
    "open": 125.82,
    "high": 126.96,
    "low": 125.04,
    "close": 126.33,
    "volume": 8719200
  },
  {
    "time": "26-01-2010",
    "open": 125.92,
    "high": 127.75,
    "low": 125.41,
    "close": 125.75,
    "volume": 7135300
  },
  {
    "time": "25-01-2010",
    "open": 126.33,
    "high": 126.895,
    "low": 125.71,
    "close": 126.12,
    "volume": 5738500
  },
  {
    "time": "22-01-2010",
    "open": 128.67,
    "high": 128.89,
    "low": 125.37,
    "close": 125.5,
    "volume": 10088600
  },
  {
    "time": "21-01-2010",
    "open": 130.47,
    "high": 130.69,
    "low": 128.06,
    "close": 129,
    "volume": 9608600
  },
  {
    "time": "20-01-2010",
    "open": 130.46,
    "high": 131.15,
    "low": 128.95,
    "close": 130.25,
    "volume": 15197500
  },
  {
    "time": "19-01-2010",
    "open": 131.63,
    "high": 134.25,
    "low": 131.56,
    "close": 134.14,
    "volume": 13916200
  },
  {
    "time": "15-01-2010",
    "open": 132.03,
    "high": 132.89,
    "low": 131.089,
    "close": 131.78,
    "volume": 8494400
  },
  {
    "time": "14-01-2010",
    "open": 130.55,
    "high": 132.71,
    "low": 129.91,
    "close": 132.31,
    "volume": 7111800
  },
  {
    "time": "13-01-2010",
    "open": 130.39,
    "high": 131.12,
    "low": 129.16,
    "close": 130.23,
    "volume": 6455400
  },
  {
    "time": "12-01-2010",
    "open": 129.03,
    "high": 131.33,
    "low": 129,
    "close": 130.51,
    "volume": 8081500
  },
  {
    "time": "11-01-2010",
    "open": 131.06,
    "high": 131.06,
    "low": 128.67,
    "close": 129.48,
    "volume": 5730400
  },
  {
    "time": "08-01-2010",
    "open": 129.07,
    "high": 130.9199,
    "low": 129.05,
    "close": 130.85,
    "volume": 4197200
  },
  {
    "time": "07-01-2010",
    "open": 129.87,
    "high": 130.25,
    "low": 128.91,
    "close": 129.55,
    "volume": 5840600
  },
  {
    "time": "06-01-2010",
    "open": 130.68,
    "high": 131.49,
    "low": 129.81,
    "close": 130,
    "volume": 5605300
  },
  {
    "time": "05-01-2010",
    "open": 131.68,
    "high": 131.85,
    "low": 130.1,
    "close": 130.85,
    "volume": 6841400
  },
  {
    "time": "04-01-2010",
    "open": 131.18,
    "high": 132.97,
    "low": 130.85,
    "close": 132.45,
    "volume": 6155300
  },
  {
    "time": "31-12-2009",
    "open": 132.41,
    "high": 132.85,
    "low": 130.75,
    "close": 130.9,
    "volume": 4223400
  },
  {
    "time": "30-12-2009",
    "open": 131.23,
    "high": 132.68,
    "low": 130.68,
    "close": 132.57,
    "volume": 3867000
  },
  {
    "time": "29-12-2009",
    "open": 132.28,
    "high": 132.37,
    "low": 131.8,
    "close": 131.85,
    "volume": 4184200
  },
  {
    "time": "28-12-2009",
    "open": 130.99,
    "high": 132.31,
    "low": 130.72,
    "close": 132.31,
    "volume": 5800400
  },
  {
    "time": "24-12-2009",
    "open": 129.89,
    "high": 130.57,
    "low": 129.48,
    "close": 130.57,
    "volume": 4265100
  },
  {
    "time": "23-12-2009",
    "open": 129.7,
    "high": 130,
    "low": 129.3,
    "close": 130,
    "volume": 4127600
  },
  {
    "time": "22-12-2009",
    "open": 129.3,
    "high": 129.98,
    "low": 129.19,
    "close": 129.93,
    "volume": 5535500
  },
  {
    "time": "21-12-2009",
    "open": 127.8,
    "high": 128.94,
    "low": 127.68,
    "close": 128.65,
    "volume": 4772500
  },
  {
    "time": "18-12-2009",
    "open": 127.97,
    "high": 128.39,
    "low": 127,
    "close": 127.91,
    "volume": 9106600
  },
  {
    "time": "17-12-2009",
    "open": 128,
    "high": 128.56,
    "low": 127.12,
    "close": 127.4,
    "volume": 5909500
  },
  {
    "time": "16-12-2009",
    "open": 128.74,
    "high": 129.6,
    "low": 128.35,
    "close": 128.71,
    "volume": 6372500
  },
  {
    "time": "15-12-2009",
    "open": 129.46,
    "high": 129.86,
    "low": 127.94,
    "close": 128.49,
    "volume": 7862600
  },
  {
    "time": "14-12-2009",
    "open": 129.65,
    "high": 129.98,
    "low": 129.6,
    "close": 129.93,
    "volume": 5201300
  },
  {
    "time": "11-12-2009",
    "open": 129.01,
    "high": 129.77,
    "low": 128.71,
    "close": 129.68,
    "volume": 6597200
  },
  {
    "time": "10-12-2009",
    "open": 128.13,
    "high": 129.47,
    "low": 128.09,
    "close": 129.34,
    "volume": 7077800
  },
  {
    "time": "09-12-2009",
    "open": 126.7,
    "high": 128.39,
    "low": 126.11,
    "close": 128.39,
    "volume": 6071900
  },
  {
    "time": "08-12-2009",
    "open": 126.97,
    "high": 127.35,
    "low": 126.16,
    "close": 126.8,
    "volume": 5351400
  },
  {
    "time": "07-12-2009",
    "open": 126.88,
    "high": 127.53,
    "low": 126.59,
    "close": 127.04,
    "volume": 4144400
  },
  {
    "time": "04-12-2009",
    "open": 128.4,
    "high": 128.9,
    "low": 126,
    "close": 127.25,
    "volume": 7068500
  },
  {
    "time": "03-12-2009",
    "open": 127.6,
    "high": 128.47,
    "low": 127.25,
    "close": 127.55,
    "volume": 5760000
  },
  {
    "time": "02-12-2009",
    "open": 127.32,
    "high": 128.39,
    "low": 127.16,
    "close": 127.21,
    "volume": 4597900
  },
  {
    "time": "01-12-2009",
    "open": 127.29,
    "high": 128.39,
    "low": 126.85,
    "close": 127.94,
    "volume": 6578600
  },
  {
    "time": "30-11-2009",
    "open": 125.12,
    "high": 126.6,
    "low": 124.92,
    "close": 126.35,
    "volume": 6302700
  },
  {
    "time": "27-11-2009",
    "open": 124.59,
    "high": 126.62,
    "low": 124.26,
    "close": 125.7,
    "volume": 3319200
  },
  {
    "time": "25-11-2009",
    "open": 127.88,
    "high": 128.22,
    "low": 127.08,
    "close": 127.28,
    "volume": 3967700
  },
  {
    "time": "24-11-2009",
    "open": 127.9,
    "high": 128.32,
    "low": 127.14,
    "close": 127.93,
    "volume": 4816500
  },
  {
    "time": "23-11-2009",
    "open": 127.7,
    "high": 128.94,
    "low": 127.65,
    "close": 128.2,
    "volume": 7003500
  },
  {
    "time": "20-11-2009",
    "open": 127.17,
    "high": 127.69,
    "low": 126.46,
    "close": 126.96,
    "volume": 5354200
  },
  {
    "time": "19-11-2009",
    "open": 127.28,
    "high": 127.84,
    "low": 126.51,
    "close": 127.54,
    "volume": 5308400
  },
  {
    "time": "18-11-2009",
    "open": 128.05,
    "high": 128.35,
    "low": 127.55,
    "close": 128.15,
    "volume": 3970100
  },
  {
    "time": "17-11-2009",
    "open": 127.43,
    "high": 128.655,
    "low": 127.4,
    "close": 128.63,
    "volume": 1027500
  },
  {
    "time": "16-11-2009",
    "open": 127.17,
    "high": 128.24,
    "low": 127.03,
    "close": 128.21,
    "volume": 7822500
  },
  {
    "time": "13-11-2009",
    "open": 126.41,
    "high": 127.8,
    "low": 126.27,
    "close": 127.03,
    "volume": 4979700
  },
  {
    "time": "12-11-2009",
    "open": 126.61,
    "high": 127.65,
    "low": 126.11,
    "close": 126.26,
    "volume": 5345400
  },
  {
    "time": "11-11-2009",
    "open": 126.55,
    "high": 127.3,
    "low": 125.94,
    "close": 127.19,
    "volume": 6530800
  },
  {
    "time": "10-11-2009",
    "open": 125.3,
    "high": 127.05,
    "low": 125.17,
    "close": 126.91,
    "volume": 6444500
  },
  {
    "time": "09-11-2009",
    "open": 123.92,
    "high": 126,
    "low": 123.49,
    "close": 126,
    "volume": 7764600
  },
  {
    "time": "06-11-2009",
    "open": 122.51,
    "high": 123.49,
    "low": 122.23,
    "close": 123.49,
    "volume": 4979600
  },
  {
    "time": "05-11-2009",
    "open": 122.12,
    "high": 123.5,
    "low": 122,
    "close": 122.55,
    "volume": 6701800
  },
  {
    "time": "04-11-2009",
    "open": 121.46,
    "high": 122.5,
    "low": 121.16,
    "close": 121.29,
    "volume": 6353300
  },
  {
    "time": "03-11-2009",
    "open": 119.53,
    "high": 121.19,
    "low": 119.53,
    "close": 121.16,
    "volume": 5706800
  },
  {
    "time": "02-11-2009",
    "open": 120.61,
    "high": 121.93,
    "low": 119.5,
    "close": 120.56,
    "volume": 7049400
  },
  {
    "time": "30-10-2009",
    "open": 122.63,
    "high": 124.3,
    "low": 120.48,
    "close": 120.61,
    "volume": 7711700
  },
  {
    "time": "29-10-2009",
    "open": 122.15,
    "high": 123.03,
    "low": 121.6,
    "close": 122.87,
    "volume": 6309400
  },
  {
    "time": "28-10-2009",
    "open": 120.47,
    "high": 121.68,
    "low": 119.87,
    "close": 121.5,
    "volume": 8780400
  },
  {
    "time": "27-10-2009",
    "open": 120.18,
    "high": 122.14,
    "low": 119.15,
    "close": 120.65,
    "volume": 9445400
  },
  {
    "time": "26-10-2009",
    "open": 120.61,
    "high": 122.44,
    "low": 119.55,
    "close": 120.11,
    "volume": 6538500
  },
  {
    "time": "23-10-2009",
    "open": 122.95,
    "high": 123.06,
    "low": 120,
    "close": 120.36,
    "volume": 6799200
  },
  {
    "time": "22-10-2009",
    "open": 120.75,
    "high": 123,
    "low": 120.34,
    "close": 122.69,
    "volume": 6027000
  },
  {
    "time": "21-10-2009",
    "open": 122.18,
    "high": 124.11,
    "low": 120.86,
    "close": 120.87,
    "volume": 7171000
  },
  {
    "time": "20-10-2009",
    "open": 123.36,
    "high": 123.5,
    "low": 121.5,
    "close": 122.82,
    "volume": 8780700
  },
  {
    "time": "19-10-2009",
    "open": 121.76,
    "high": 123.36,
    "low": 121.61,
    "close": 123.06,
    "volume": 7514100
  },
  {
    "time": "16-10-2009",
    "open": 123.15,
    "high": 123.7,
    "low": 121.25,
    "close": 121.64,
    "volume": 19216900
  },
  {
    "time": "15-10-2009",
    "open": 127.56,
    "high": 128,
    "low": 126.32,
    "close": 127.98,
    "volume": 11904600
  },
  {
    "time": "14-10-2009",
    "open": 127.71,
    "high": 128.61,
    "low": 126.6,
    "close": 128.35,
    "volume": 9545500
  },
  {
    "time": "13-10-2009",
    "open": 126.22,
    "high": 127.39,
    "low": 126.2,
    "close": 127.02,
    "volume": 7581300
  },
  {
    "time": "12-10-2009",
    "open": 125.44,
    "high": 127.1,
    "low": 125.2,
    "close": 127.04,
    "volume": 7730500
  },
  {
    "time": "09-10-2009",
    "open": 122.43,
    "high": 126,
    "low": 122.3,
    "close": 125.93,
    "volume": 13123100
  },
  {
    "time": "08-10-2009",
    "open": 122.52,
    "high": 122.98,
    "low": 121.85,
    "close": 122.29,
    "volume": 6498000
  },
  {
    "time": "07-10-2009",
    "open": 121.12,
    "high": 122.85,
    "low": 120.94,
    "close": 122.78,
    "volume": 5872000
  },
  {
    "time": "06-10-2009",
    "open": 120.39,
    "high": 121.59,
    "low": 120.22,
    "close": 121.35,
    "volume": 6659100
  },
  {
    "time": "05-10-2009",
    "open": 118.9,
    "high": 120.18,
    "low": 118.13,
    "close": 119.75,
    "volume": 5673300
  },
  {
    "time": "02-10-2009",
    "open": 117.4,
    "high": 119.66,
    "low": 117.26,
    "close": 119.02,
    "volume": 7581900
  },
  {
    "time": "01-10-2009",
    "open": 119.39,
    "high": 119.61,
    "low": 117.56,
    "close": 117.9,
    "volume": 7067500
  },
  {
    "time": "30-09-2009",
    "open": 119.18,
    "high": 120,
    "low": 117.55,
    "close": 119.61,
    "volume": 7733300
  },
  {
    "time": "29-09-2009",
    "open": 119.36,
    "high": 119.94,
    "low": 118.6,
    "close": 118.81,
    "volume": 6748800
  },
  {
    "time": "28-09-2009",
    "open": 120.52,
    "high": 122,
    "low": 118.94,
    "close": 119.33,
    "volume": 8248600
  },
  {
    "time": "25-09-2009",
    "open": 120.27,
    "high": 121.65,
    "low": 120.1,
    "close": 121.08,
    "volume": 5187900
  },
  {
    "time": "24-09-2009",
    "open": 120.69,
    "high": 121.65,
    "low": 120.4,
    "close": 120.94,
    "volume": 5426800
  },
  {
    "time": "23-09-2009",
    "open": 121.26,
    "high": 122.74,
    "low": 120.71,
    "close": 120.82,
    "volume": 5538100
  },
  {
    "time": "22-09-2009",
    "open": 122.09,
    "high": 122.11,
    "low": 120.98,
    "close": 121.61,
    "volume": 5564700
  },
  {
    "time": "21-09-2009",
    "open": 121.03,
    "high": 122.08,
    "low": 120.88,
    "close": 121.57,
    "volume": 4515100
  },
  {
    "time": "18-09-2009",
    "open": 122.45,
    "high": 122.88,
    "low": 121.62,
    "close": 122.11,
    "volume": 9935800
  },
  {
    "time": "17-09-2009",
    "open": 121.14,
    "high": 122.78,
    "low": 121.05,
    "close": 121.88,
    "volume": 8392600
  },
  {
    "time": "16-09-2009",
    "open": 119.5,
    "high": 122,
    "low": 119.3,
    "close": 121.82,
    "volume": 11236700
  },
  {
    "time": "15-09-2009",
    "open": 118.78,
    "high": 119.45,
    "low": 118.16,
    "close": 119.35,
    "volume": 6536000
  },
  {
    "time": "14-09-2009",
    "open": 117,
    "high": 118.99,
    "low": 116.94,
    "close": 118.88,
    "volume": 4758600
  },
  {
    "time": "11-09-2009",
    "open": 117.8,
    "high": 119.25,
    "low": 117.47,
    "close": 118.05,
    "volume": 5893700
  },
  {
    "time": "10-09-2009",
    "open": 116.99,
    "high": 117.83,
    "low": 116.75,
    "close": 117.67,
    "volume": 4889400
  },
  {
    "time": "09-09-2009",
    "open": 117.03,
    "high": 117.67,
    "low": 116.16,
    "close": 116.76,
    "volume": 5248100
  },
  {
    "time": "08-09-2009",
    "open": 117.94,
    "high": 118.17,
    "low": 116.63,
    "close": 117.16,
    "volume": 5797500
  },
  {
    "time": "04-09-2009",
    "open": 116.58,
    "high": 117.5,
    "low": 116.21,
    "close": 117.46,
    "volume": 3451700
  },
  {
    "time": "03-09-2009",
    "open": 116.55,
    "high": 116.73,
    "low": 115.15,
    "close": 116.33,
    "volume": 4936200
  },
  {
    "time": "02-09-2009",
    "open": 116.34,
    "high": 117.24,
    "low": 116,
    "close": 116.09,
    "volume": 5178800
  },
  {
    "time": "01-09-2009",
    "open": 117.67,
    "high": 118.93,
    "low": 116.5,
    "close": 116.69,
    "volume": 6303800
  },
  {
    "time": "31-08-2009",
    "open": 117.1,
    "high": 118.06,
    "low": 116.92,
    "close": 118.05,
    "volume": 5439400
  },
  {
    "time": "28-08-2009",
    "open": 120.1,
    "high": 120.23,
    "low": 117.77,
    "close": 118.22,
    "volume": 6235700
  },
  {
    "time": "27-08-2009",
    "open": 118.73,
    "high": 119.59,
    "low": 117.85,
    "close": 119.43,
    "volume": 4356900
  },
  {
    "time": "26-08-2009",
    "open": 118.64,
    "high": 119.85,
    "low": 117.51,
    "close": 119.47,
    "volume": 4515500
  },
  {
    "time": "25-08-2009",
    "open": 119.25,
    "high": 120.15,
    "low": 118.71,
    "close": 118.83,
    "volume": 4652200
  },
  {
    "time": "24-08-2009",
    "open": 119.92,
    "high": 120.88,
    "low": 119.1,
    "close": 119.32,
    "volume": 6363400
  },
  {
    "time": "21-08-2009",
    "open": 119.65,
    "high": 120.01,
    "low": 118.55,
    "close": 119.9,
    "volume": 8306800
  },
  {
    "time": "20-08-2009",
    "open": 118.44,
    "high": 119.41,
    "low": 118.1,
    "close": 118.95,
    "volume": 4841700
  },
  {
    "time": "19-08-2009",
    "open": 116.36,
    "high": 118.77,
    "low": 116.12,
    "close": 118.57,
    "volume": 4837900
  },
  {
    "time": "18-08-2009",
    "open": 116.81,
    "high": 118,
    "low": 116.73,
    "close": 117.63,
    "volume": 4286200
  },
  {
    "time": "17-08-2009",
    "open": 116.69,
    "high": 117.44,
    "low": 116.21,
    "close": 116.86,
    "volume": 5349600
  },
  {
    "time": "14-08-2009",
    "open": 119.29,
    "high": 119.74,
    "low": 117.62,
    "close": 118.57,
    "volume": 4796400
  },
  {
    "time": "13-08-2009",
    "open": 119.28,
    "high": 119.78,
    "low": 118.63,
    "close": 119.58,
    "volume": 5489200
  },
  {
    "time": "12-08-2009",
    "open": 117.6,
    "high": 119.96,
    "low": 117.42,
    "close": 119.29,
    "volume": 6289700
  },
  {
    "time": "11-08-2009",
    "open": 118.03,
    "high": 118.5,
    "low": 117.32,
    "close": 117.79,
    "volume": 4844500
  },
  {
    "time": "10-08-2009",
    "open": 118.17,
    "high": 119.19,
    "low": 117.97,
    "close": 118.7,
    "volume": 3981300
  },
  {
    "time": "07-08-2009",
    "open": 118.11,
    "high": 119.91,
    "low": 118.11,
    "close": 119.33,
    "volume": 5763200
  },
  {
    "time": "06-08-2009",
    "open": 118.31,
    "high": 118.4,
    "low": 116.7,
    "close": 117.38,
    "volume": 6003100
  },
  {
    "time": "05-08-2009",
    "open": 118.515,
    "high": 119,
    "low": 117.86,
    "close": 117.92,
    "volume": 5025800
  },
  {
    "time": "04-08-2009",
    "open": 119.17,
    "high": 119.8,
    "low": 119.02,
    "close": 119.6,
    "volume": 4993500
  },
  {
    "time": "03-08-2009",
    "open": 118.88,
    "high": 119.96,
    "low": 118.69,
    "close": 119.92,
    "volume": 6108200
  },
  {
    "time": "31-07-2009",
    "open": 117.56,
    "high": 118.99,
    "low": 117.56,
    "close": 117.93,
    "volume": 5329600
  },
  {
    "time": "30-07-2009",
    "open": 118,
    "high": 119.37,
    "low": 117.67,
    "close": 117.86,
    "volume": 6803100
  },
  {
    "time": "29-07-2009",
    "open": 116.36,
    "high": 117.28,
    "low": 116.05,
    "close": 117.26,
    "volume": 5100900
  },
  {
    "time": "28-07-2009",
    "open": 116.63,
    "high": 117.52,
    "low": 116.34,
    "close": 117.28,
    "volume": 5782900
  },
  {
    "time": "27-07-2009",
    "open": 116.95,
    "high": 117.92,
    "low": 116.57,
    "close": 117.63,
    "volume": 5069200
  },
  {
    "time": "24-07-2009",
    "open": 116.68,
    "high": 118.15,
    "low": 116.63,
    "close": 117.64,
    "volume": 6261000
  },
  {
    "time": "23-07-2009",
    "open": 115.06,
    "high": 118,
    "low": 115.06,
    "close": 117.06,
    "volume": 8461400
  },
  {
    "time": "22-07-2009",
    "open": 116.18,
    "high": 117.2,
    "low": 115.09,
    "close": 115.57,
    "volume": 8813400
  },
  {
    "time": "21-07-2009",
    "open": 115.87,
    "high": 117.04,
    "low": 115.38,
    "close": 117.04,
    "volume": 8301700
  },
  {
    "time": "20-07-2009",
    "open": 114.53,
    "high": 116.88,
    "low": 114.39,
    "close": 116.44,
    "volume": 10682500
  },
  {
    "time": "17-07-2009",
    "open": 113.41,
    "high": 115.53,
    "low": 113.16,
    "close": 115.42,
    "volume": 20188900
  },
  {
    "time": "16-07-2009",
    "open": 106.84,
    "high": 110.97,
    "low": 106.79,
    "close": 110.64,
    "volume": 14997900
  },
  {
    "time": "15-07-2009",
    "open": 104.75,
    "high": 107.22,
    "low": 104.6,
    "close": 107.22,
    "volume": 8699100
  },
  {
    "time": "14-07-2009",
    "open": 103.42,
    "high": 103.62,
    "low": 102.52,
    "close": 103.25,
    "volume": 5413500
  },
  {
    "time": "13-07-2009",
    "open": 101.28,
    "high": 103.65,
    "low": 100.19,
    "close": 103.62,
    "volume": 9494300
  },
  {
    "time": "10-07-2009",
    "open": 100.97,
    "high": 101.72,
    "low": 99.8,
    "close": 100.83,
    "volume": 7465900
  },
  {
    "time": "09-07-2009",
    "open": 101.12,
    "high": 102.78,
    "low": 100.85,
    "close": 102.08,
    "volume": 6153200
  },
  {
    "time": "08-07-2009",
    "open": 100.29,
    "high": 101.17,
    "low": 99.5,
    "close": 100.68,
    "volume": 7380800
  },
  {
    "time": "07-07-2009",
    "open": 101.34,
    "high": 101.96,
    "low": 100.03,
    "close": 100.19,
    "volume": 6685600
  },
  {
    "time": "06-07-2009",
    "open": 101.57,
    "high": 102.23,
    "low": 100.47,
    "close": 101.65,
    "volume": 6522500
  },
  {
    "time": "02-07-2009",
    "open": 103.8,
    "high": 103.91,
    "low": 101.73,
    "close": 101.73,
    "volume": 7026700
  },
  {
    "time": "01-07-2009",
    "open": 105,
    "high": 106.27,
    "low": 104.73,
    "close": 104.84,
    "volume": 5537300
  },
  {
    "time": "30-06-2009",
    "open": 105.69,
    "high": 106.03,
    "low": 103.81,
    "close": 104.42,
    "volume": 7512600
  },
  {
    "time": "29-06-2009",
    "open": 105.99,
    "high": 106.18,
    "low": 105.16,
    "close": 105.83,
    "volume": 4681800
  },
  {
    "time": "26-06-2009",
    "open": 106.5,
    "high": 106.5,
    "low": 105.05,
    "close": 105.68,
    "volume": 5127900
  },
  {
    "time": "25-06-2009",
    "open": 103.7,
    "high": 106.785,
    "low": 103.51,
    "close": 106.06,
    "volume": 6900600
  },
  {
    "time": "24-06-2009",
    "open": 105.39,
    "high": 106.48,
    "low": 103.72,
    "close": 104.15,
    "volume": 6395200
  },
  {
    "time": "23-06-2009",
    "open": 104.75,
    "high": 104.87,
    "low": 103.79,
    "close": 104.44,
    "volume": 5152600
  },
  {
    "time": "22-06-2009",
    "open": 105.18,
    "high": 105.88,
    "low": 104.23,
    "close": 104.52,
    "volume": 7295400
  },
  {
    "time": "19-06-2009",
    "open": 106.31,
    "high": 106.65,
    "low": 105.5,
    "close": 105.89,
    "volume": 13559000
  },
  {
    "time": "18-06-2009",
    "open": 106.93,
    "high": 107.53,
    "low": 106.12,
    "close": 106.33,
    "volume": 6206900
  },
  {
    "time": "17-06-2009",
    "open": 107.46,
    "high": 107.88,
    "low": 106.88,
    "close": 107,
    "volume": 7366200
  },
  {
    "time": "16-06-2009",
    "open": 108.47,
    "high": 109.14,
    "low": 107.27,
    "close": 107.32,
    "volume": 7231000
  },
  {
    "time": "15-06-2009",
    "open": 107.63,
    "high": 108.83,
    "low": 107.24,
    "close": 107.62,
    "volume": 8567600
  },
  {
    "time": "12-06-2009",
    "open": 108.4,
    "high": 109.28,
    "low": 107.75,
    "close": 108.21,
    "volume": 7250900
  },
  {
    "time": "11-06-2009",
    "open": 108.75,
    "high": 110.64,
    "low": 108.56,
    "close": 109.4,
    "volume": 9134300
  },
  {
    "time": "10-06-2009",
    "open": 109.2,
    "high": 109.28,
    "low": 107.05,
    "close": 108.35,
    "volume": 7578100
  },
  {
    "time": "09-06-2009",
    "open": 108.15,
    "high": 109.14,
    "low": 107.99,
    "close": 108.14,
    "volume": 8585900
  },
  {
    "time": "08-06-2009",
    "open": 106.64,
    "high": 107.98,
    "low": 105.5,
    "close": 107.49,
    "volume": 6363800
  },
  {
    "time": "05-06-2009",
    "open": 107.33,
    "high": 108.4,
    "low": 106.75,
    "close": 107.24,
    "volume": 7665000
  },
  {
    "time": "04-06-2009",
    "open": 106.17,
    "high": 106.87,
    "low": 105.6,
    "close": 106.33,
    "volume": 5468500
  },
  {
    "time": "03-06-2009",
    "open": 106.53,
    "high": 106.6,
    "low": 105.11,
    "close": 106.49,
    "volume": 7688000
  },
  {
    "time": "02-06-2009",
    "open": 107.51,
    "high": 108.8,
    "low": 106.66,
    "close": 106.83,
    "volume": 7045800
  },
  {
    "time": "01-06-2009",
    "open": 106.94,
    "high": 108.67,
    "low": 106.67,
    "close": 108.37,
    "volume": 7725000
  },
  {
    "time": "29-05-2009",
    "open": 104.47,
    "high": 106.5,
    "low": 103.95,
    "close": 106.28,
    "volume": 6871800
  },
  {
    "time": "28-05-2009",
    "open": 103.34,
    "high": 105.14,
    "low": 102.68,
    "close": 104.69,
    "volume": 6127500
  },
  {
    "time": "27-05-2009",
    "open": 104.34,
    "high": 105.17,
    "low": 102.91,
    "close": 102.93,
    "volume": 7119600
  },
  {
    "time": "26-05-2009",
    "open": 101.32,
    "high": 105.63,
    "low": 101.02,
    "close": 105.02,
    "volume": 7211200
  },
  {
    "time": "22-05-2009",
    "open": 102.67,
    "high": 103.31,
    "low": 101.75,
    "close": 101.89,
    "volume": 5596200
  },
  {
    "time": "21-05-2009",
    "open": 103.07,
    "high": 103.25,
    "low": 101.74,
    "close": 102.82,
    "volume": 7411500
  },
  {
    "time": "20-05-2009",
    "open": 105.51,
    "high": 105.98,
    "low": 103.81,
    "close": 104.05,
    "volume": 7083100
  },
  {
    "time": "19-05-2009",
    "open": 104.33,
    "high": 106.12,
    "low": 104,
    "close": 105.51,
    "volume": 7079200
  },
  {
    "time": "18-05-2009",
    "open": 102,
    "high": 104.71,
    "low": 101.94,
    "close": 104.58,
    "volume": 7429500
  },
  {
    "time": "15-05-2009",
    "open": 100.9,
    "high": 103.07,
    "low": 100.57,
    "close": 101.37,
    "volume": 8367400
  },
  {
    "time": "14-05-2009",
    "open": 101.72,
    "high": 102.71,
    "low": 100.77,
    "close": 101.05,
    "volume": 9445100
  },
  {
    "time": "13-05-2009",
    "open": 102.88,
    "high": 103.1,
    "low": 101.35,
    "close": 102.26,
    "volume": 8202100
  },
  {
    "time": "12-05-2009",
    "open": 102.98,
    "high": 104.38,
    "low": 102.37,
    "close": 103.94,
    "volume": 7961600
  },
  {
    "time": "11-05-2009",
    "open": 101.09,
    "high": 103.877,
    "low": 100.95,
    "close": 102.9,
    "volume": 8253000
  },
  {
    "time": "08-05-2009",
    "open": 103.58,
    "high": 103.75,
    "low": 99.83,
    "close": 101.49,
    "volume": 11575500
  },
  {
    "time": "07-05-2009",
    "open": 104.65,
    "high": 104.7964,
    "low": 101.83,
    "close": 102.59,
    "volume": 8521400
  },
  {
    "time": "06-05-2009",
    "open": 105.23,
    "high": 105.5,
    "low": 103.71,
    "close": 104.62,
    "volume": 7530900
  },
  {
    "time": "05-05-2009",
    "open": 106,
    "high": 106.82,
    "low": 105.51,
    "close": 105.85,
    "volume": 6750000
  },
  {
    "time": "04-05-2009",
    "open": 105.26,
    "high": 106.75,
    "low": 104.61,
    "close": 106.19,
    "volume": 8313900
  },
  {
    "time": "01-05-2009",
    "open": 103.78,
    "high": 104.86,
    "low": 102.94,
    "close": 104.61,
    "volume": 6234200
  },
  {
    "time": "30-04-2009",
    "open": 104.55,
    "high": 106.05,
    "low": 102.85,
    "close": 103.21,
    "volume": 10654500
  },
  {
    "time": "29-04-2009",
    "open": 101.98,
    "high": 105,
    "low": 101.67,
    "close": 104.04,
    "volume": 9783700
  },
  {
    "time": "28-04-2009",
    "open": 99.36,
    "high": 102.73,
    "low": 99.25,
    "close": 101.94,
    "volume": 11110500
  },
  {
    "time": "27-04-2009",
    "open": 99.61,
    "high": 101.69,
    "low": 99.29,
    "close": 99.95,
    "volume": 8611400
  },
  {
    "time": "24-04-2009",
    "open": 101.17,
    "high": 101.58,
    "low": 99.5,
    "close": 100.08,
    "volume": 10157500
  },
  {
    "time": "23-04-2009",
    "open": 102.65,
    "high": 102.78,
    "low": 99.8,
    "close": 101.42,
    "volume": 9440400
  },
  {
    "time": "22-04-2009",
    "open": 101.17,
    "high": 103.86,
    "low": 101.06,
    "close": 102.55,
    "volume": 12120000
  },
  {
    "time": "21-04-2009",
    "open": 98.41,
    "high": 102.48,
    "low": 98.2,
    "close": 102.31,
    "volume": 15226700
  },
  {
    "time": "20-04-2009",
    "open": 100.29,
    "high": 101.1899,
    "low": 99.21,
    "close": 100.43,
    "volume": 12524700
  },
  {
    "time": "17-04-2009",
    "open": 101.18,
    "high": 102.04,
    "low": 99.69,
    "close": 101.27,
    "volume": 10214200
  },
  {
    "time": "16-04-2009",
    "open": 99.74,
    "high": 101.92,
    "low": 99.18,
    "close": 101.43,
    "volume": 9259500
  },
  {
    "time": "15-04-2009",
    "open": 98.23,
    "high": 99.06,
    "low": 96.44,
    "close": 98.85,
    "volume": 8164200
  },
  {
    "time": "14-04-2009",
    "open": 99.08,
    "high": 99.95,
    "low": 98.27,
    "close": 99.27,
    "volume": 6276700
  },
  {
    "time": "13-04-2009",
    "open": 100.28,
    "high": 101.65,
    "low": 99.04,
    "close": 99.95,
    "volume": 7797200
  },
  {
    "time": "09-04-2009",
    "open": 102.01,
    "high": 102.45,
    "low": 100.25,
    "close": 101.7,
    "volume": 8013700
  },
  {
    "time": "08-04-2009",
    "open": 99.45,
    "high": 101.55,
    "low": 99,
    "close": 101.19,
    "volume": 7285200
  },
  {
    "time": "07-04-2009",
    "open": 100.1725,
    "high": 100.5,
    "low": 98.52,
    "close": 98.75,
    "volume": 8768400
  },
  {
    "time": "06-04-2009",
    "open": 100.9,
    "high": 101.85,
    "low": 100,
    "close": 101.56,
    "volume": 8819800
  },
  {
    "time": "03-04-2009",
    "open": 99.73,
    "high": 102.66,
    "low": 99.71,
    "close": 102.22,
    "volume": 10962900
  },
  {
    "time": "02-04-2009",
    "open": 99.15,
    "high": 101.99,
    "low": 98.38,
    "close": 100.82,
    "volume": 16219000
  },
  {
    "time": "01-04-2009",
    "open": 96.13,
    "high": 98,
    "low": 95.7,
    "close": 97.61,
    "volume": 12212900
  },
  {
    "time": "31-03-2009",
    "open": 94.96,
    "high": 98.66,
    "low": 94.85,
    "close": 96.89,
    "volume": 14553700
  },
  {
    "time": "30-03-2009",
    "open": 92,
    "high": 94.74,
    "low": 91.8,
    "close": 94.52,
    "volume": 10964000
  },
  {
    "time": "27-03-2009",
    "open": 95.99,
    "high": 96,
    "low": 92.75,
    "close": 94.15,
    "volume": 15745200
  },
  {
    "time": "26-03-2009",
    "open": 98.47,
    "high": 99.2,
    "low": 97.75,
    "close": 98.78,
    "volume": 11434600
  },
  {
    "time": "25-03-2009",
    "open": 98.47,
    "high": 99.86,
    "low": 96.09,
    "close": 97.95,
    "volume": 12017000
  },
  {
    "time": "24-03-2009",
    "open": 97.69,
    "high": 99.55,
    "low": 97.52,
    "close": 98.3,
    "volume": 9346700
  },
  {
    "time": "23-03-2009",
    "open": 93.7,
    "high": 98.71,
    "low": 93.7,
    "close": 98.71,
    "volume": 12407200
  },
  {
    "time": "20-03-2009",
    "open": 93.16,
    "high": 95,
    "low": 92.18,
    "close": 92.51,
    "volume": 12193900
  },
  {
    "time": "19-03-2009",
    "open": 93.08,
    "high": 93.35,
    "low": 91.51,
    "close": 92.66,
    "volume": 9899300
  },
  {
    "time": "18-03-2009",
    "open": 89.41,
    "high": 92.9,
    "low": 89.41,
    "close": 91.95,
    "volume": 17954300
  },
  {
    "time": "17-03-2009",
    "open": 91.11,
    "high": 92.91,
    "low": 90.45,
    "close": 92.91,
    "volume": 11489700
  },
  {
    "time": "16-03-2009",
    "open": 91.34,
    "high": 93,
    "low": 90.54,
    "close": 91.22,
    "volume": 11793700
  },
  {
    "time": "13-03-2009",
    "open": 90.4,
    "high": 90.65,
    "low": 89.29,
    "close": 90.36,
    "volume": 9111900
  },
  {
    "time": "12-03-2009",
    "open": 88.59,
    "high": 90.6,
    "low": 87.49,
    "close": 90.4,
    "volume": 11829200
  },
  {
    "time": "11-03-2009",
    "open": 87.51,
    "high": 89.54,
    "low": 86.72,
    "close": 88.62,
    "volume": 11823900
  },
  {
    "time": "10-03-2009",
    "open": 85.11,
    "high": 87.25,
    "low": 83.64,
    "close": 87.25,
    "volume": 15914800
  },
  {
    "time": "09-03-2009",
    "open": 84.7,
    "high": 86.8,
    "low": 83.02,
    "close": 83.48,
    "volume": 13151800
  },
  {
    "time": "06-03-2009",
    "open": 87.47,
    "high": 88.25,
    "low": 83.81,
    "close": 85.81,
    "volume": 15186000
  },
  {
    "time": "05-03-2009",
    "open": 88.13,
    "high": 90.16,
    "low": 87.02,
    "close": 87.48,
    "volume": 14930100
  },
  {
    "time": "04-03-2009",
    "open": 88.97,
    "high": 91.24,
    "low": 87.602,
    "close": 89.49,
    "volume": 15391700
  },
  {
    "time": "03-03-2009",
    "open": 90.16,
    "high": 90.18,
    "low": 87.19,
    "close": 87.77,
    "volume": 13744100
  },
  {
    "time": "02-03-2009",
    "open": 91.17,
    "high": 91.9,
    "low": 88.75,
    "close": 89.05,
    "volume": 15231700
  },
  {
    "time": "27-02-2009",
    "open": 88.01,
    "high": 93.28,
    "low": 88.01,
    "close": 92.03,
    "volume": 21311200
  },
  {
    "time": "26-02-2009",
    "open": 87,
    "high": 90.2,
    "low": 86.33,
    "close": 88.97,
    "volume": 17423200
  },
  {
    "time": "25-02-2009",
    "open": 86,
    "high": 87.12,
    "low": 82.85,
    "close": 85.9,
    "volume": 12646400
  },
  {
    "time": "24-02-2009",
    "open": 84.42,
    "high": 87.08,
    "low": 84.1,
    "close": 86.4,
    "volume": 12915300
  },
  {
    "time": "23-02-2009",
    "open": 89.3,
    "high": 89.33,
    "low": 84.19,
    "close": 84.37,
    "volume": 13509600
  },
  {
    "time": "20-02-2009",
    "open": 88.05,
    "high": 89.9,
    "low": 87.34,
    "close": 88.79,
    "volume": 12400700
  },
  {
    "time": "19-02-2009",
    "open": 91.69,
    "high": 92.16,
    "low": 88.45,
    "close": 88.93,
    "volume": 12622700
  },
  {
    "time": "18-02-2009",
    "open": 91.17,
    "high": 92.25,
    "low": 90.4406,
    "close": 91.51,
    "volume": 8492600
  },
  {
    "time": "17-02-2009",
    "open": 91.73,
    "high": 92.49,
    "low": 90.5,
    "close": 90.67,
    "volume": 8903500
  },
  {
    "time": "13-02-2009",
    "open": 94.79,
    "high": 95.18,
    "low": 93.57,
    "close": 93.84,
    "volume": 6742400
  },
  {
    "time": "12-02-2009",
    "open": 94.41,
    "high": 95.2,
    "low": 92.2,
    "close": 95.07,
    "volume": 9917700
  },
  {
    "time": "11-02-2009",
    "open": 93.48,
    "high": 95.77,
    "low": 93,
    "close": 95.16,
    "volume": 10765700
  },
  {
    "time": "10-02-2009",
    "open": 95.58,
    "high": 96.82,
    "low": 92.96,
    "close": 93.27,
    "volume": 13541300
  },
  {
    "time": "09-02-2009",
    "open": 95.93,
    "high": 96.98,
    "low": 94.72,
    "close": 96.82,
    "volume": 8165800
  },
  {
    "time": "06-02-2009",
    "open": 92.51,
    "high": 97.1,
    "low": 91.65,
    "close": 96.14,
    "volume": 13055900
  },
  {
    "time": "05-02-2009",
    "open": 92.13,
    "high": 93.2,
    "low": 91.22,
    "close": 92.41,
    "volume": 11622900
  },
  {
    "time": "04-02-2009",
    "open": 93.74,
    "high": 94.91,
    "low": 91.82,
    "close": 92.83,
    "volume": 10189800
  },
  {
    "time": "03-02-2009",
    "open": 91.07,
    "high": 93.73,
    "low": 90.16,
    "close": 93.48,
    "volume": 8864500
  },
  {
    "time": "02-02-2009",
    "open": 90.6,
    "high": 91.86,
    "low": 89.69,
    "close": 90.93,
    "volume": 9926400
  },
  {
    "time": "30-01-2009",
    "open": 92.23,
    "high": 93.48,
    "low": 91.25,
    "close": 91.65,
    "volume": 9617000
  },
  {
    "time": "29-01-2009",
    "open": 93.58,
    "high": 94.58,
    "low": 92.02,
    "close": 92.51,
    "volume": 9233500
  },
  {
    "time": "28-01-2009",
    "open": 92.7,
    "high": 94.94,
    "low": 91.91,
    "close": 94.82,
    "volume": 13407200
  },
  {
    "time": "27-01-2009",
    "open": 91.77,
    "high": 91.97,
    "low": 90.5,
    "close": 91.66,
    "volume": 8720800
  },
  {
    "time": "26-01-2009",
    "open": 89.77,
    "high": 92.67,
    "low": 89.52,
    "close": 91.6,
    "volume": 9840800
  },
  {
    "time": "23-01-2009",
    "open": 88.4,
    "high": 90.7,
    "low": 87.81,
    "close": 89.49,
    "volume": 10253700
  },
  {
    "time": "22-01-2009",
    "open": 89.83,
    "high": 90.74,
    "low": 88.11,
    "close": 90.07,
    "volume": 12350700
  },
  {
    "time": "21-01-2009",
    "open": 86.35,
    "high": 91.6,
    "low": 86,
    "close": 91.42,
    "volume": 24905000
  },
  {
    "time": "20-01-2009",
    "open": 84.76,
    "high": 84.92,
    "low": 81.76,
    "close": 81.98,
    "volume": 13800100
  },
  {
    "time": "16-01-2009",
    "open": 84.61,
    "high": 85.74,
    "low": 83.08,
    "close": 84.92,
    "volume": 11245300
  },
  {
    "time": "15-01-2009",
    "open": 83.34,
    "high": 84.83,
    "low": 82.256,
    "close": 84.12,
    "volume": 11734800
  },
  {
    "time": "14-01-2009",
    "open": 84.2,
    "high": 84.82,
    "low": 82.11,
    "close": 83.19,
    "volume": 9470000
  },
  {
    "time": "13-01-2009",
    "open": 85.5,
    "high": 86.59,
    "low": 84.79,
    "close": 85.34,
    "volume": 7657200
  },
  {
    "time": "12-01-2009",
    "open": 84.57,
    "high": 86.6,
    "low": 84.42,
    "close": 85.71,
    "volume": 8072700
  },
  {
    "time": "09-01-2009",
    "open": 87.05,
    "high": 87.5,
    "low": 84.25,
    "close": 84.7,
    "volume": 7440300
  },
  {
    "time": "08-01-2009",
    "open": 87.81,
    "high": 88.14,
    "low": 85.98,
    "close": 87.18,
    "volume": 7231800
  },
  {
    "time": "07-01-2009",
    "open": 87.83,
    "high": 88.8,
    "low": 87.12,
    "close": 87.79,
    "volume": 8455100
  },
  {
    "time": "06-01-2009",
    "open": 87.11,
    "high": 90.41,
    "low": 86.37,
    "close": 89.23,
    "volume": 9649500
  },
  {
    "time": "05-01-2009",
    "open": 86.42,
    "high": 87.67,
    "low": 86.18,
    "close": 86.82,
    "volume": 8315700
  },
  {
    "time": "02-01-2009",
    "open": 83.89,
    "high": 87.59,
    "low": 83.89,
    "close": 87.37,
    "volume": 7558200
  },
  {
    "time": "31-12-2008",
    "open": 83.5,
    "high": 85,
    "low": 83.5,
    "close": 84.16,
    "volume": 6667700
  },
  {
    "time": "30-12-2008",
    "open": 81.83,
    "high": 83.64,
    "low": 81.52,
    "close": 83.55,
    "volume": 5774400
  },
  {
    "time": "29-12-2008",
    "open": 81.72,
    "high": 81.72,
    "low": 79.68,
    "close": 81.25,
    "volume": 6062600
  },
  {
    "time": "26-12-2008",
    "open": 80.93,
    "high": 81.8,
    "low": 80.55,
    "close": 81.33,
    "volume": 3101300
  },
  {
    "time": "24-12-2008",
    "open": 80.55,
    "high": 81.22,
    "low": 79.92,
    "close": 80.52,
    "volume": 2415200
  },
  {
    "time": "23-12-2008",
    "open": 82.35,
    "high": 82.81,
    "low": 80.13,
    "close": 80.6,
    "volume": 6374400
  },
  {
    "time": "22-12-2008",
    "open": 83.27,
    "high": 83.45,
    "low": 80.32,
    "close": 81.99,
    "volume": 7083300
  },
  {
    "time": "19-12-2008",
    "open": 84.59,
    "high": 85.22,
    "low": 82.97,
    "close": 83.52,
    "volume": 13098600
  },
  {
    "time": "18-12-2008",
    "open": 85.77,
    "high": 86.64,
    "low": 82.96,
    "close": 84,
    "volume": 8029500
  },
  {
    "time": "17-12-2008",
    "open": 85.44,
    "high": 87.27,
    "low": 84.6566,
    "close": 85.84,
    "volume": 8592000
  },
  {
    "time": "16-12-2008",
    "open": 83.11,
    "high": 86.84,
    "low": 82.87,
    "close": 86.4,
    "volume": 11559800
  },
  {
    "time": "15-12-2008",
    "open": 82.51,
    "high": 83.54,
    "low": 80,
    "close": 82.77,
    "volume": 8848200
  },
  {
    "time": "12-12-2008",
    "open": 78.68,
    "high": 82.94,
    "low": 78.06,
    "close": 82.2,
    "volume": 10381700
  },
  {
    "time": "11-12-2008",
    "open": 81.5,
    "high": 82.86,
    "low": 79.77,
    "close": 80.58,
    "volume": 10682400
  },
  {
    "time": "10-12-2008",
    "open": 83.95,
    "high": 84.99,
    "low": 81.83,
    "close": 82.86,
    "volume": 8187000
  },
  {
    "time": "09-12-2008",
    "open": 83.82,
    "high": 85.434,
    "low": 82.2,
    "close": 82.69,
    "volume": 9356400
  },
  {
    "time": "08-12-2008",
    "open": 82.57,
    "high": 85.88,
    "low": 81.73,
    "close": 84.86,
    "volume": 11177600
  },
  {
    "time": "05-12-2008",
    "open": 76.78,
    "high": 81.5,
    "low": 75.31,
    "close": 80.59,
    "volume": 11212000
  },
  {
    "time": "04-12-2008",
    "open": 80.03,
    "high": 80.83,
    "low": 76.18,
    "close": 77.44,
    "volume": 10914000
  },
  {
    "time": "03-12-2008",
    "open": 78.62,
    "high": 81,
    "low": 76.99,
    "close": 80.67,
    "volume": 9757800
  },
  {
    "time": "02-12-2008",
    "open": 77.8,
    "high": 80,
    "low": 76.14,
    "close": 79.84,
    "volume": 9305200
  },
  {
    "time": "01-12-2008",
    "open": 80.95,
    "high": 81.36,
    "low": 76.79,
    "close": 76.9,
    "volume": 10265000
  },
  {
    "time": "28-11-2008",
    "open": 81,
    "high": 81.9,
    "low": 80.1,
    "close": 81.6,
    "volume": 3908300
  },
  {
    "time": "26-11-2008",
    "open": 79.52,
    "high": 81.79,
    "low": 78.61,
    "close": 81.67,
    "volume": 8240600
  },
  {
    "time": "25-11-2008",
    "open": 81.37,
    "high": 82.4,
    "low": 78.22,
    "close": 80.65,
    "volume": 12519700
  },
  {
    "time": "24-11-2008",
    "open": 75.74,
    "high": 82.09,
    "low": 75,
    "close": 79.89,
    "volume": 15023200
  },
  {
    "time": "21-11-2008",
    "open": 72.74,
    "high": 76.98,
    "low": 69.5,
    "close": 74.88,
    "volume": 19804500
  },
  {
    "time": "20-11-2008",
    "open": 75,
    "high": 78.39,
    "low": 70.51,
    "close": 71.74,
    "volume": 17634700
  },
  {
    "time": "19-11-2008",
    "open": 79.93,
    "high": 81,
    "low": 75.73,
    "close": 75.97,
    "volume": 12606400
  },
  {
    "time": "18-11-2008",
    "open": 79.13,
    "high": 80.93,
    "low": 77,
    "close": 80.08,
    "volume": 13050600
  },
  {
    "time": "17-11-2008",
    "open": 79.4,
    "high": 79.74,
    "low": 77.16,
    "close": 77.48,
    "volume": 10315300
  },
  {
    "time": "14-11-2008",
    "open": 82.63,
    "high": 85.4,
    "low": 79.5,
    "close": 80.33,
    "volume": 12023700
  },
  {
    "time": "13-11-2008",
    "open": 79.62,
    "high": 84.39,
    "low": 75.4,
    "close": 84.21,
    "volume": 15103600
  },
  {
    "time": "12-11-2008",
    "open": 80.73,
    "high": 82.4,
    "low": 79.57,
    "close": 79.74,
    "volume": 11368400
  },
  {
    "time": "11-11-2008",
    "open": 83.25,
    "high": 84.29,
    "low": 80.25,
    "close": 82.74,
    "volume": 10013600
  },
  {
    "time": "10-11-2008",
    "open": 87.99,
    "high": 88.1,
    "low": 82.74,
    "close": 83.87,
    "volume": 9685100
  },
  {
    "time": "07-11-2008",
    "open": 85.78,
    "high": 86.71,
    "low": 84.25,
    "close": 86.27,
    "volume": 7927800
  },
  {
    "time": "06-11-2008",
    "open": 87.68,
    "high": 88.83,
    "low": 84.28,
    "close": 85.15,
    "volume": 12522000
  },
  {
    "time": "05-11-2008",
    "open": 92.93,
    "high": 93.4,
    "low": 89.7,
    "close": 89.94,
    "volume": 8706200
  },
  {
    "time": "04-11-2008",
    "open": 94.76,
    "high": 94.76,
    "low": 92.07,
    "close": 93.4,
    "volume": 10173000
  },
  {
    "time": "03-11-2008",
    "open": 92.64,
    "high": 94.67,
    "low": 92.17,
    "close": 92.68,
    "volume": 7686300
  },
  {
    "time": "31-10-2008",
    "open": 90.9,
    "high": 94.19,
    "low": 89.03,
    "close": 92.97,
    "volume": 10506300
  },
  {
    "time": "30-10-2008",
    "open": 91.01,
    "high": 92.4,
    "low": 88.9,
    "close": 90.69,
    "volume": 12033800
  },
  {
    "time": "29-10-2008",
    "open": 87.74,
    "high": 91,
    "low": 86.25,
    "close": 88.2,
    "volume": 12692000
  },
  {
    "time": "28-10-2008",
    "open": 81.4,
    "high": 87.61,
    "low": 79.52,
    "close": 87.28,
    "volume": 14205900
  },
  {
    "time": "27-10-2008",
    "open": 80.27,
    "high": 84.5,
    "low": 79.01,
    "close": 79.66,
    "volume": 11099100
  },
  {
    "time": "24-10-2008",
    "open": 79.27,
    "high": 84.31,
    "low": 78.82,
    "close": 82.07,
    "volume": 12287300
  },
  {
    "time": "23-10-2008",
    "open": 84.09,
    "high": 85.89,
    "low": 81,
    "close": 84.35,
    "volume": 12358000
  },
  {
    "time": "22-10-2008",
    "open": 87,
    "high": 87.59,
    "low": 80.8,
    "close": 83.6,
    "volume": 14198100
  },
  {
    "time": "21-10-2008",
    "open": 92.22,
    "high": 92.46,
    "low": 88.57,
    "close": 88.86,
    "volume": 9716400
  },
  {
    "time": "20-10-2008",
    "open": 92.21,
    "high": 93.31,
    "low": 89.33,
    "close": 92.51,
    "volume": 9747100
  },
  {
    "time": "17-10-2008",
    "open": 91.75,
    "high": 95.91,
    "low": 87.71,
    "close": 90.78,
    "volume": 15230700
  },
  {
    "time": "16-10-2008",
    "open": 89.38,
    "high": 92,
    "low": 84.35,
    "close": 91.52,
    "volume": 16271300
  },
  {
    "time": "15-10-2008",
    "open": 92.77,
    "high": 95.29,
    "low": 87.71,
    "close": 88.29,
    "volume": 11330800
  },
  {
    "time": "14-10-2008",
    "open": 96.42,
    "high": 99,
    "low": 92,
    "close": 93.6,
    "volume": 15114200
  },
  {
    "time": "13-10-2008",
    "open": 90.44,
    "high": 93.61,
    "low": 86.95,
    "close": 92.21,
    "volume": 17101600
  },
  {
    "time": "10-10-2008",
    "open": 87.41,
    "high": 91.66,
    "low": 83.51,
    "close": 87.75,
    "volume": 24637400
  },
  {
    "time": "09-10-2008",
    "open": 95.29,
    "high": 95.31,
    "low": 88.23,
    "close": 89,
    "volume": 21293600
  },
  {
    "time": "08-10-2008",
    "open": 91.51,
    "high": 97.48,
    "low": 89.98,
    "close": 90.55,
    "volume": 17980400
  },
  {
    "time": "07-10-2008",
    "open": 101.5,
    "high": 102.62,
    "low": 94.24,
    "close": 95.65,
    "volume": 11416100
  },
  {
    "time": "06-10-2008",
    "open": 101.21,
    "high": 103,
    "low": 96.6,
    "close": 100.62,
    "volume": 11521400
  },
  {
    "time": "03-10-2008",
    "open": 105.16,
    "high": 110.95,
    "low": 103.03,
    "close": 103.44,
    "volume": 9637900
  },
  {
    "time": "02-10-2008",
    "open": 108.48,
    "high": 109.97,
    "low": 103.04,
    "close": 104.74,
    "volume": 11458200
  },
  {
    "time": "01-10-2008",
    "open": 115.51,
    "high": 116.8,
    "low": 107.75,
    "close": 110.13,
    "volume": 13134400
  },
  {
    "time": "30-09-2008",
    "open": 115.83,
    "high": 116.96,
    "low": 111.81,
    "close": 116.96,
    "volume": 18991900
  },
  {
    "time": "29-09-2008",
    "open": 117.44,
    "high": 118.43,
    "low": 109.95,
    "close": 114.46,
    "volume": 9586700
  },
  {
    "time": "26-09-2008",
    "open": 117.21,
    "high": 121.01,
    "low": 117.01,
    "close": 119.42,
    "volume": 4760500
  },
  {
    "time": "25-09-2008",
    "open": 117.75,
    "high": 121.99,
    "low": 116.12,
    "close": 120.11,
    "volume": 6539200
  },
  {
    "time": "24-09-2008",
    "open": 116.93,
    "high": 118.17,
    "low": 114.26,
    "close": 116.46,
    "volume": 6574100
  },
  {
    "time": "23-09-2008",
    "open": 116.21,
    "high": 118.17,
    "low": 114.14,
    "close": 115.36,
    "volume": 8012800
  },
  {
    "time": "22-09-2008",
    "open": 118.45,
    "high": 120.14,
    "low": 116.02,
    "close": 116.21,
    "volume": 8844000
  },
  {
    "time": "19-09-2008",
    "open": 119.59,
    "high": 124,
    "low": 117.5,
    "close": 118.85,
    "volume": 14432600
  },
  {
    "time": "18-09-2008",
    "open": 112.91,
    "high": 118.59,
    "low": 111.04,
    "close": 115.12,
    "volume": 15995000
  },
  {
    "time": "17-09-2008",
    "open": 114.79,
    "high": 115.5,
    "low": 110.61,
    "close": 111.47,
    "volume": 12298000
  },
  {
    "time": "16-09-2008",
    "open": 113.73,
    "high": 117.64,
    "low": 113.32,
    "close": 116.05,
    "volume": 13673200
  },
  {
    "time": "15-09-2008",
    "open": 115.36,
    "high": 118.41,
    "low": 115.19,
    "close": 115.19,
    "volume": 9908300
  },
  {
    "time": "12-09-2008",
    "open": 118.66,
    "high": 119.28,
    "low": 116.82,
    "close": 118.97,
    "volume": 6635100
  },
  {
    "time": "11-09-2008",
    "open": 116.6,
    "high": 119.37,
    "low": 116.11,
    "close": 119.2,
    "volume": 9737400
  },
  {
    "time": "10-09-2008",
    "open": 116.4,
    "high": 119.95,
    "low": 116.1,
    "close": 118.04,
    "volume": 11852200
  },
  {
    "time": "09-09-2008",
    "open": 117.78,
    "high": 118.1,
    "low": 115,
    "close": 115.04,
    "volume": 8106300
  },
  {
    "time": "08-09-2008",
    "open": 118,
    "high": 118.28,
    "low": 115.14,
    "close": 117.29,
    "volume": 10452800
  },
  {
    "time": "05-09-2008",
    "open": 114.15,
    "high": 115.09,
    "low": 113.17,
    "close": 114.33,
    "volume": 8811400
  },
  {
    "time": "04-09-2008",
    "open": 117.99,
    "high": 118.9,
    "low": 114.9,
    "close": 115,
    "volume": 10133800
  },
  {
    "time": "03-09-2008",
    "open": 118.34,
    "high": 118.65,
    "low": 115.15,
    "close": 118.34,
    "volume": 11316600
  },
  {
    "time": "02-09-2008",
    "open": 122.87,
    "high": 124,
    "low": 117.9,
    "close": 118.41,
    "volume": 11749800
  },
  {
    "time": "29-08-2008",
    "open": 123.71,
    "high": 123.99,
    "low": 121.63,
    "close": 121.73,
    "volume": 7156000
  },
  {
    "time": "28-08-2008",
    "open": 124.06,
    "high": 125.45,
    "low": 123.58,
    "close": 124.58,
    "volume": 5448200
  },
  {
    "time": "27-08-2008",
    "open": 122.42,
    "high": 124.85,
    "low": 122.18,
    "close": 123.38,
    "volume": 5843800
  },
  {
    "time": "26-08-2008",
    "open": 122.58,
    "high": 122.85,
    "low": 121.5,
    "close": 122.5,
    "volume": 6218200
  },
  {
    "time": "25-08-2008",
    "open": 124.48,
    "high": 124.93,
    "low": 122.7,
    "close": 122.86,
    "volume": 5827400
  },
  {
    "time": "22-08-2008",
    "open": 123.3,
    "high": 125.35,
    "low": 122.8,
    "close": 124.93,
    "volume": 5989200
  },
  {
    "time": "21-08-2008",
    "open": 121.58,
    "high": 123.43,
    "low": 121.55,
    "close": 122.99,
    "volume": 6315000
  },
  {
    "time": "20-08-2008",
    "open": 123.5,
    "high": 123.97,
    "low": 122.01,
    "close": 122.51,
    "volume": 6871600
  },
  {
    "time": "19-08-2008",
    "open": 123.63,
    "high": 124.79,
    "low": 122.01,
    "close": 122.56,
    "volume": 9311600
  },
  {
    "time": "18-08-2008",
    "open": 126.49,
    "high": 127,
    "low": 123.68,
    "close": 124.59,
    "volume": 5970000
  },
  {
    "time": "15-08-2008",
    "open": 127.07,
    "high": 127.19,
    "low": 125.35,
    "close": 126.36,
    "volume": 5566200
  },
  {
    "time": "14-08-2008",
    "open": 125.01,
    "high": 128.25,
    "low": 124.8,
    "close": 126.94,
    "volume": 6813000
  },
  {
    "time": "13-08-2008",
    "open": 125,
    "high": 126.99,
    "low": 124.75,
    "close": 125.8,
    "volume": 5857800
  },
  {
    "time": "12-08-2008",
    "open": 126.01,
    "high": 126.5,
    "low": 124.4,
    "close": 125.22,
    "volume": 6996800
  },
  {
    "time": "11-08-2008",
    "open": 128.43,
    "high": 128.43,
    "low": 125.75,
    "close": 126.6,
    "volume": 7490400
  },
  {
    "time": "08-08-2008",
    "open": 129.05,
    "high": 129.88,
    "low": 127.63,
    "close": 128.81,
    "volume": 8561800
  },
  {
    "time": "07-08-2008",
    "open": 129.16,
    "high": 129.97,
    "low": 127.9,
    "close": 129.05,
    "volume": 6633500
  },
  {
    "time": "06-08-2008",
    "open": 128.45,
    "high": 129.5,
    "low": 127.75,
    "close": 129.16,
    "volume": 6253800
  },
  {
    "time": "05-08-2008",
    "open": 127.56,
    "high": 128.9,
    "low": 127.08,
    "close": 128.87,
    "volume": 6969900
  },
  {
    "time": "04-08-2008",
    "open": 126.77,
    "high": 128.1,
    "low": 126.25,
    "close": 127.56,
    "volume": 5994900
  },
  {
    "time": "01-08-2008",
    "open": 128.52,
    "high": 129.3,
    "low": 126.28,
    "close": 126.64,
    "volume": 4939700
  },
  {
    "time": "31-07-2008",
    "open": 128.1,
    "high": 129.5,
    "low": 127.74,
    "close": 127.98,
    "volume": 5857300
  },
  {
    "time": "30-07-2008",
    "open": 128.12,
    "high": 129,
    "low": 127.09,
    "close": 128.86,
    "volume": 5826100
  },
  {
    "time": "29-07-2008",
    "open": 126.54,
    "high": 128.168,
    "low": 126.25,
    "close": 127.66,
    "volume": 5979400
  },
  {
    "time": "28-07-2008",
    "open": 127.69,
    "high": 128.44,
    "low": 126.13,
    "close": 126.25,
    "volume": 6096800
  },
  {
    "time": "25-07-2008",
    "open": 129,
    "high": 129.9,
    "low": 128,
    "close": 128.53,
    "volume": 7565500
  },
  {
    "time": "24-07-2008",
    "open": 129.06,
    "high": 130.93,
    "low": 128.8,
    "close": 130,
    "volume": 8946300
  },
  {
    "time": "23-07-2008",
    "open": 129.78,
    "high": 130.33,
    "low": 128.29,
    "close": 129.52,
    "volume": 10230300
  },
  {
    "time": "22-07-2008",
    "open": 127.5,
    "high": 130,
    "low": 127.26,
    "close": 130,
    "volume": 11428600
  },
  {
    "time": "21-07-2008",
    "open": 129.83,
    "high": 129.9,
    "low": 127.64,
    "close": 128.66,
    "volume": 7779600
  },
  {
    "time": "18-07-2008",
    "open": 125.81,
    "high": 130,
    "low": 125.42,
    "close": 129.89,
    "volume": 18317800
  },
  {
    "time": "17-07-2008",
    "open": 126.39,
    "high": 126.96,
    "low": 124,
    "close": 126.52,
    "volume": 12298200
  },
  {
    "time": "16-07-2008",
    "open": 122.81,
    "high": 126.89,
    "low": 122.25,
    "close": 125.94,
    "volume": 9898800
  },
  {
    "time": "15-07-2008",
    "open": 121,
    "high": 124.5,
    "low": 119.9,
    "close": 123.2,
    "volume": 10710500
  },
  {
    "time": "14-07-2008",
    "open": 121.8,
    "high": 124.25,
    "low": 121.24,
    "close": 121.54,
    "volume": 8317400
  },
  {
    "time": "11-07-2008",
    "open": 121.8,
    "high": 123.74,
    "low": 120.57,
    "close": 122.12,
    "volume": 8841400
  },
  {
    "time": "10-07-2008",
    "open": 120.66,
    "high": 123.54,
    "low": 120.66,
    "close": 123.18,
    "volume": 10053600
  },
  {
    "time": "09-07-2008",
    "open": 124.21,
    "high": 124.5,
    "low": 120.4,
    "close": 120.4,
    "volume": 9506700
  },
  {
    "time": "08-07-2008",
    "open": 120.55,
    "high": 123.99,
    "low": 120.55,
    "close": 123.88,
    "volume": 9813300
  },
  {
    "time": "07-07-2008",
    "open": 119.62,
    "high": 122.64,
    "low": 119.62,
    "close": 121.5,
    "volume": 9131100
  },
  {
    "time": "03-07-2008",
    "open": 119.95,
    "high": 120.78,
    "low": 117.89,
    "close": 119.54,
    "volume": 6115200
  },
  {
    "time": "02-07-2008",
    "open": 118.41,
    "high": 120.55,
    "low": 118.12,
    "close": 119.1,
    "volume": 8093200
  },
  {
    "time": "01-07-2008",
    "open": 117.5,
    "high": 119.36,
    "low": 116.6,
    "close": 119.27,
    "volume": 10069400
  },
  {
    "time": "30-06-2008",
    "open": 119.99,
    "high": 120.22,
    "low": 118.15,
    "close": 118.53,
    "volume": 8439000
  },
  {
    "time": "27-06-2008",
    "open": 121.02,
    "high": 122.05,
    "low": 118.26,
    "close": 120.05,
    "volume": 11660400
  },
  {
    "time": "26-06-2008",
    "open": 123.43,
    "high": 123.82,
    "low": 120.76,
    "close": 121.13,
    "volume": 9710600
  },
  {
    "time": "25-06-2008",
    "open": 123.67,
    "high": 125.83,
    "low": 123.2,
    "close": 124.58,
    "volume": 7131500
  },
  {
    "time": "24-06-2008",
    "open": 123.08,
    "high": 124.25,
    "low": 121.9,
    "close": 123.46,
    "volume": 7552900
  },
  {
    "time": "23-06-2008",
    "open": 123,
    "high": 124.5,
    "low": 122.4,
    "close": 123.46,
    "volume": 5852300
  },
  {
    "time": "20-06-2008",
    "open": 123.14,
    "high": 125.02,
    "low": 122.5,
    "close": 122.74,
    "volume": 9624900
  },
  {
    "time": "19-06-2008",
    "open": 123.84,
    "high": 125.62,
    "low": 122.36,
    "close": 125.02,
    "volume": 7570200
  },
  {
    "time": "18-06-2008",
    "open": 124.55,
    "high": 125.4312,
    "low": 123.7,
    "close": 124.16,
    "volume": 6614700
  },
  {
    "time": "17-06-2008",
    "open": 127.1,
    "high": 127.1,
    "low": 124.62,
    "close": 125.1,
    "volume": 5277900
  },
  {
    "time": "16-06-2008",
    "open": 125.46,
    "high": 127.14,
    "low": 124.6466,
    "close": 126.71,
    "volume": 6280200
  },
  {
    "time": "13-06-2008",
    "open": 124.42,
    "high": 126.57,
    "low": 124.15,
    "close": 126.15,
    "volume": 6379600
  },
  {
    "time": "12-06-2008",
    "open": 123.85,
    "high": 125.09,
    "low": 123.13,
    "close": 123.85,
    "volume": 6748600
  },
  {
    "time": "11-06-2008",
    "open": 125.9,
    "high": 125.9,
    "low": 122.86,
    "close": 123.25,
    "volume": 7207200
  },
  {
    "time": "10-06-2008",
    "open": 124.88,
    "high": 126.722,
    "low": 124.63,
    "close": 125.94,
    "volume": 5384200
  },
  {
    "time": "09-06-2008",
    "open": 125.38,
    "high": 126.18,
    "low": 124.06,
    "close": 125.86,
    "volume": 5584000
  },
  {
    "time": "06-06-2008",
    "open": 127.6,
    "high": 128.14,
    "low": 124.74,
    "close": 124.94,
    "volume": 7886200
  },
  {
    "time": "05-06-2008",
    "open": 127.41,
    "high": 129.04,
    "low": 127.2,
    "close": 128.47,
    "volume": 6153300
  },
  {
    "time": "04-06-2008",
    "open": 127.24,
    "high": 128.5,
    "low": 126.45,
    "close": 127.55,
    "volume": 6432300
  },
  {
    "time": "03-06-2008",
    "open": 127.48,
    "high": 129,
    "low": 127.46,
    "close": 127.84,
    "volume": 7238200
  },
  {
    "time": "02-06-2008",
    "open": 128.67,
    "high": 129.37,
    "low": 126.7,
    "close": 127.36,
    "volume": 7599300
  },
  {
    "time": "30-05-2008",
    "open": 129.22,
    "high": 129.99,
    "low": 128.8,
    "close": 129.43,
    "volume": 8652900
  },
  {
    "time": "29-05-2008",
    "open": 129.04,
    "high": 129.99,
    "low": 128.66,
    "close": 129.71,
    "volume": 7561500
  },
  {
    "time": "28-05-2008",
    "open": 127.5,
    "high": 129.54,
    "low": 126.99,
    "close": 129.54,
    "volume": 9875600
  },
  {
    "time": "27-05-2008",
    "open": 124.01,
    "high": 127.38,
    "low": 124,
    "close": 127.32,
    "volume": 6989200
  },
  {
    "time": "23-05-2008",
    "open": 124.35,
    "high": 124.91,
    "low": 123.77,
    "close": 124.2,
    "volume": 4701500
  },
  {
    "time": "22-05-2008",
    "open": 123.55,
    "high": 125.35,
    "low": 123.55,
    "close": 124.7,
    "volume": 5031300
  },
  {
    "time": "21-05-2008",
    "open": 125.05,
    "high": 126.4,
    "low": 123.02,
    "close": 123.62,
    "volume": 8333900
  },
  {
    "time": "20-05-2008",
    "open": 126.05,
    "high": 126.05,
    "low": 124.45,
    "close": 125.18,
    "volume": 7017700
  },
  {
    "time": "19-05-2008",
    "open": 127.68,
    "high": 128.21,
    "low": 126.05,
    "close": 126.49,
    "volume": 6385200
  },
  {
    "time": "16-05-2008",
    "open": 128.46,
    "high": 128.475,
    "low": 126.8,
    "close": 127.82,
    "volume": 6372100
  },
  {
    "time": "15-05-2008",
    "open": 127.06,
    "high": 128.68,
    "low": 126.9,
    "close": 128.46,
    "volume": 7227900
  },
  {
    "time": "14-05-2008",
    "open": 126.61,
    "high": 128.83,
    "low": 126.2,
    "close": 127.52,
    "volume": 8888300
  },
  {
    "time": "13-05-2008",
    "open": 125.31,
    "high": 126.7,
    "low": 125.03,
    "close": 126.58,
    "volume": 9693400
  },
  {
    "time": "12-05-2008",
    "open": 123.8,
    "high": 125.99,
    "low": 123.56,
    "close": 125.24,
    "volume": 8666800
  },
  {
    "time": "09-05-2008",
    "open": 124.37,
    "high": 124.65,
    "low": 123.63,
    "close": 124.06,
    "volume": 5502900
  },
  {
    "time": "08-05-2008",
    "open": 124.2,
    "high": 125.17,
    "low": 123.7,
    "close": 124.92,
    "volume": 9794000
  },
  {
    "time": "07-05-2008",
    "open": 122.78,
    "high": 124.98,
    "low": 122.41,
    "close": 124.14,
    "volume": 11406800
  },
  {
    "time": "06-05-2008",
    "open": 121.5,
    "high": 123.34,
    "low": 120.78,
    "close": 122.82,
    "volume": 5910400
  },
  {
    "time": "05-05-2008",
    "open": 122.76,
    "high": 123.25,
    "low": 121.74,
    "close": 122.03,
    "volume": 6359300
  },
  {
    "time": "02-05-2008",
    "open": 123.3,
    "high": 124,
    "low": 121.76,
    "close": 123.18,
    "volume": 6916600
  },
  {
    "time": "01-05-2008",
    "open": 121.06,
    "high": 124.43,
    "low": 121.06,
    "close": 123.61,
    "volume": 8230200
  },
  {
    "time": "30-04-2008",
    "open": 123.34,
    "high": 123.5,
    "low": 120.5,
    "close": 120.7,
    "volume": 8128300
  },
  {
    "time": "29-04-2008",
    "open": 122.05,
    "high": 123.2,
    "low": 122.04,
    "close": 122.85,
    "volume": 7226000
  },
  {
    "time": "28-04-2008",
    "open": 122.98,
    "high": 123.05,
    "low": 121.64,
    "close": 121.69,
    "volume": 5605200
  },
  {
    "time": "25-04-2008",
    "open": 124.5,
    "high": 124.5,
    "low": 122.06,
    "close": 123.08,
    "volume": 6313400
  },
  {
    "time": "24-04-2008",
    "open": 123.38,
    "high": 124.9,
    "low": 122.73,
    "close": 124.19,
    "volume": 5996700
  },
  {
    "time": "23-04-2008",
    "open": 123.69,
    "high": 124.78,
    "low": 122.64,
    "close": 123.6,
    "volume": 7593300
  },
  {
    "time": "22-04-2008",
    "open": 123.87,
    "high": 124.35,
    "low": 123.01,
    "close": 123.67,
    "volume": 5417500
  },
  {
    "time": "21-04-2008",
    "open": 122.05,
    "high": 124.51,
    "low": 122.05,
    "close": 124.35,
    "volume": 6952800
  },
  {
    "time": "18-04-2008",
    "open": 123.85,
    "high": 125,
    "low": 123.15,
    "close": 124.4,
    "volume": 12490800
  },
  {
    "time": "17-04-2008",
    "open": 122.39,
    "high": 123.72,
    "low": 120.7,
    "close": 123.08,
    "volume": 18999100
  },
  {
    "time": "16-04-2008",
    "open": 118.21,
    "high": 120.47,
    "low": 117.17,
    "close": 120.47,
    "volume": 19317700
  },
  {
    "time": "15-04-2008",
    "open": 117.1,
    "high": 117.67,
    "low": 115.54,
    "close": 117.17,
    "volume": 8642000
  },
  {
    "time": "14-04-2008",
    "open": 116.2,
    "high": 117.94,
    "low": 115.95,
    "close": 117.28,
    "volume": 7745600
  },
  {
    "time": "11-04-2008",
    "open": 117.33,
    "high": 117.8,
    "low": 115.51,
    "close": 116,
    "volume": 9556800
  },
  {
    "time": "10-04-2008",
    "open": 117.5,
    "high": 119.22,
    "low": 116.8789,
    "close": 118.78,
    "volume": 9149300
  },
  {
    "time": "09-04-2008",
    "open": 116.5,
    "high": 117.72,
    "low": 115.72,
    "close": 116.77,
    "volume": 6819100
  },
  {
    "time": "08-04-2008",
    "open": 115.5,
    "high": 116.88,
    "low": 115.28,
    "close": 116.27,
    "volume": 7283300
  },
  {
    "time": "07-04-2008",
    "open": 116.37,
    "high": 117.37,
    "low": 115.82,
    "close": 116.31,
    "volume": 6181200
  },
  {
    "time": "04-04-2008",
    "open": 116.05,
    "high": 116.58,
    "low": 114.6,
    "close": 115.76,
    "volume": 5733200
  },
  {
    "time": "03-04-2008",
    "open": 114.35,
    "high": 116.82,
    "low": 113.86,
    "close": 116.02,
    "volume": 7052900
  },
  {
    "time": "02-04-2008",
    "open": 116.84,
    "high": 116.88,
    "low": 113.98,
    "close": 114.81,
    "volume": 8007600
  },
  {
    "time": "01-04-2008",
    "open": 115.14,
    "high": 118.37,
    "low": 114.97,
    "close": 116.49,
    "volume": 11875400
  },
  {
    "time": "31-03-2008",
    "open": 113.95,
    "high": 115.87,
    "low": 113.34,
    "close": 115.14,
    "volume": 9707200
  },
  {
    "time": "28-03-2008",
    "open": 116.01,
    "high": 116.75,
    "low": 114.21,
    "close": 114.57,
    "volume": 6600900
  },
  {
    "time": "27-03-2008",
    "open": 116.1,
    "high": 116.2,
    "low": 114.51,
    "close": 115.52,
    "volume": 8437200
  },
  {
    "time": "26-03-2008",
    "open": 117.48,
    "high": 117.97,
    "low": 114.96,
    "close": 116.91,
    "volume": 9617100
  },
  {
    "time": "25-03-2008",
    "open": 119.1,
    "high": 119.1,
    "low": 117.15,
    "close": 117.97,
    "volume": 8443600
  },
  {
    "time": "24-03-2008",
    "open": 118.57,
    "high": 119.79,
    "low": 118.13,
    "close": 119.06,
    "volume": 8398000
  },
  {
    "time": "20-03-2008",
    "open": 117.6,
    "high": 118.48,
    "low": 116.65,
    "close": 118.33,
    "volume": 11417900
  },
  {
    "time": "19-03-2008",
    "open": 118.29,
    "high": 118.5,
    "low": 116.65,
    "close": 116.94,
    "volume": 9314400
  },
  {
    "time": "18-03-2008",
    "open": 116.76,
    "high": 118.43,
    "low": 113.81,
    "close": 118.41,
    "volume": 9966500
  },
  {
    "time": "17-03-2008",
    "open": 113,
    "high": 117.04,
    "low": 113,
    "close": 115.55,
    "volume": 9280300
  },
  {
    "time": "14-03-2008",
    "open": 116.1,
    "high": 116.52,
    "low": 112.69,
    "close": 115.2301,
    "volume": 9734600
  },
  {
    "time": "13-03-2008",
    "open": 115.69,
    "high": 116.78,
    "low": 113.64,
    "close": 115.91,
    "volume": 9548900
  },
  {
    "time": "12-03-2008",
    "open": 116.1,
    "high": 118,
    "low": 115.11,
    "close": 117.07,
    "volume": 9083200
  },
  {
    "time": "11-03-2008",
    "open": 115.8,
    "high": 116.56,
    "low": 114.04,
    "close": 116.49,
    "volume": 10480600
  },
  {
    "time": "10-03-2008",
    "open": 113.12,
    "high": 115.99,
    "low": 113.12,
    "close": 114.01,
    "volume": 9747300
  },
  {
    "time": "07-03-2008",
    "open": 112.1,
    "high": 114.75,
    "low": 111.8,
    "close": 113.94,
    "volume": 8124900
  },
  {
    "time": "06-03-2008",
    "open": 115.07,
    "high": 115.15,
    "low": 112.25,
    "close": 112.52,
    "volume": 8018400
  },
  {
    "time": "05-03-2008",
    "open": 115.15,
    "high": 116.41,
    "low": 114.25,
    "close": 115.39,
    "volume": 8434200
  },
  {
    "time": "04-03-2008",
    "open": 113.25,
    "high": 115.72,
    "low": 112.75,
    "close": 115.71,
    "volume": 8593300
  },
  {
    "time": "03-03-2008",
    "open": 114.09,
    "high": 114.81,
    "low": 113.48,
    "close": 114.23,
    "volume": 6335700
  },
  {
    "time": "29-02-2008",
    "open": 114.05,
    "high": 115.14,
    "low": 113.35,
    "close": 113.86,
    "volume": 8475100
  },
  {
    "time": "28-02-2008",
    "open": 115.9,
    "high": 115.9,
    "low": 114.56,
    "close": 115.24,
    "volume": 7227200
  },
  {
    "time": "27-02-2008",
    "open": 112.97,
    "high": 116.63,
    "low": 112.91,
    "close": 116.46,
    "volume": 11854900
  },
  {
    "time": "26-02-2008",
    "open": 109.42,
    "high": 114.99,
    "low": 109.05,
    "close": 114.38,
    "volume": 18651500
  },
  {
    "time": "25-02-2008",
    "open": 107.68,
    "high": 110.32,
    "low": 107.43,
    "close": 110.08,
    "volume": 8158000
  },
  {
    "time": "22-02-2008",
    "open": 107.14,
    "high": 108.48,
    "low": 105.71,
    "close": 108.07,
    "volume": 7917200
  },
  {
    "time": "21-02-2008",
    "open": 108.1,
    "high": 109.6,
    "low": 106.43,
    "close": 106.93,
    "volume": 8328000
  },
  {
    "time": "20-02-2008",
    "open": 104.6,
    "high": 108.21,
    "low": 104.53,
    "close": 107.85,
    "volume": 7671000
  },
  {
    "time": "19-02-2008",
    "open": 106.79,
    "high": 107.62,
    "low": 104.64,
    "close": 105,
    "volume": 7376400
  },
  {
    "time": "15-02-2008",
    "open": 105.83,
    "high": 106.25,
    "low": 105,
    "close": 106.16,
    "volume": 6235600
  },
  {
    "time": "14-02-2008",
    "open": 107.99,
    "high": 108.5,
    "low": 105.5,
    "close": 106.13,
    "volume": 7340600
  },
  {
    "time": "13-02-2008",
    "open": 106.92,
    "high": 108.93,
    "low": 106.8,
    "close": 108.42,
    "volume": 6608200
  },
  {
    "time": "12-02-2008",
    "open": 105.16,
    "high": 107.33,
    "low": 104.7,
    "close": 106.53,
    "volume": 7650200
  },
  {
    "time": "11-02-2008",
    "open": 102.9,
    "high": 105.55,
    "low": 102.87,
    "close": 105.14,
    "volume": 6098300
  },
  {
    "time": "08-02-2008",
    "open": 102.34,
    "high": 103.67,
    "low": 102.07,
    "close": 103.27,
    "volume": 6085600
  },
  {
    "time": "07-02-2008",
    "open": 102.89,
    "high": 104,
    "low": 100.6,
    "close": 102.34,
    "volume": 11255800
  },
  {
    "time": "06-02-2008",
    "open": 105.05,
    "high": 106.49,
    "low": 103.58,
    "close": 103.59,
    "volume": 8265700
  },
  {
    "time": "05-02-2008",
    "open": 106.87,
    "high": 108.05,
    "low": 104.68,
    "close": 105.02,
    "volume": 9048900
  },
  {
    "time": "04-02-2008",
    "open": 108.4,
    "high": 109,
    "low": 106.4567,
    "close": 107.93,
    "volume": 5985500
  },
  {
    "time": "01-02-2008",
    "open": 107.21,
    "high": 109.4,
    "low": 105.86,
    "close": 109.08,
    "volume": 8047100
  },
  {
    "time": "31-01-2008",
    "open": 104.36,
    "high": 107.97,
    "low": 103.7,
    "close": 107.11,
    "volume": 9054600
  },
  {
    "time": "30-01-2008",
    "open": 105.42,
    "high": 107.65,
    "low": 104.855,
    "close": 105.65,
    "volume": 7431100
  },
  {
    "time": "29-01-2008",
    "open": 105.53,
    "high": 106.8,
    "low": 104.6,
    "close": 106.1,
    "volume": 6616200
  },
  {
    "time": "28-01-2008",
    "open": 104.41,
    "high": 105.77,
    "low": 103.83,
    "close": 104.98,
    "volume": 7858500
  },
  {
    "time": "25-01-2008",
    "open": 107.03,
    "high": 107.79,
    "low": 104.1,
    "close": 104.52,
    "volume": 10025400
  },
  {
    "time": "24-01-2008",
    "open": 106.38,
    "high": 107.51,
    "low": 104.68,
    "close": 106.91,
    "volume": 13025700
  },
  {
    "time": "23-01-2008",
    "open": 100.05,
    "high": 106.335,
    "low": 98.5,
    "close": 106.1,
    "volume": 19588100
  },
  {
    "time": "22-01-2008",
    "open": 99.16,
    "high": 103.09,
    "low": 98.55,
    "close": 101.22,
    "volume": 15129500
  },
  {
    "time": "18-01-2008",
    "open": 106.51,
    "high": 106.72,
    "low": 102.5,
    "close": 103.4,
    "volume": 23871700
  },
  {
    "time": "17-01-2008",
    "open": 102.67,
    "high": 103.45,
    "low": 100.05,
    "close": 101.1,
    "volume": 11934000
  },
  {
    "time": "16-01-2008",
    "open": 100.48,
    "high": 102.86,
    "low": 100.14,
    "close": 101.63,
    "volume": 12681700
  },
  {
    "time": "15-01-2008",
    "open": 102.04,
    "high": 104.64,
    "low": 101.23,
    "close": 101.83,
    "volume": 11629500
  },
  {
    "time": "14-01-2008",
    "open": 105,
    "high": 105.59,
    "low": 101.33,
    "close": 102.93,
    "volume": 18009400
  },
  {
    "time": "11-01-2008",
    "open": 99.25,
    "high": 99.46,
    "low": 97.04,
    "close": 97.67,
    "volume": 8959900
  },
  {
    "time": "10-01-2008",
    "open": 97.39,
    "high": 100.86,
    "low": 97.15,
    "close": 99.92,
    "volume": 10634700
  },
  {
    "time": "09-01-2008",
    "open": 98.08,
    "high": 99.15,
    "low": 97.16,
    "close": 98.31,
    "volume": 10922100
  },
  {
    "time": "08-01-2008",
    "open": 100.05,
    "high": 100.38,
    "low": 97.17,
    "close": 97.59,
    "volume": 9434400
  },
  {
    "time": "07-01-2008",
    "open": 100.355,
    "high": 101,
    "low": 99.03,
    "close": 100.05,
    "volume": 12650400
  },
  {
    "time": "04-01-2008",
    "open": 103.95,
    "high": 103.95,
    "low": 100.48,
    "close": 101.13,
    "volume": 11034600
  },
  {
    "time": "03-01-2008",
    "open": 104.41,
    "high": 105.57,
    "low": 103.98,
    "close": 104.9,
    "volume": 7528900
  },
  {
    "time": "02-01-2008",
    "open": 108.99,
    "high": 108.99,
    "low": 104.17,
    "close": 104.69,
    "volume": 9503500
  },
  {
    "time": "31-12-2007",
    "open": 109.25,
    "high": 110,
    "low": 107.26,
    "close": 108.1,
    "volume": 5732300
  },
  {
    "time": "28-12-2007",
    "open": 110.76,
    "high": 110.76,
    "low": 109.108,
    "close": 110.09,
    "volume": 5834500
  },
  {
    "time": "27-12-2007",
    "open": 110.75,
    "high": 111.3,
    "low": 109.49,
    "close": 109.6,
    "volume": 4513600
  },
  {
    "time": "26-12-2007",
    "open": 111.25,
    "high": 112.19,
    "low": 110.9,
    "close": 111.56,
    "volume": 3849400
  },
  {
    "time": "24-12-2007",
    "open": 111.2,
    "high": 112,
    "low": 110.55,
    "close": 111.65,
    "volume": 2342400
  },
  {
    "time": "21-12-2007",
    "open": 109.75,
    "high": 111.16,
    "low": 109.15,
    "close": 111.05,
    "volume": 13246600
  },
  {
    "time": "20-12-2007",
    "open": 108.42,
    "high": 109.49,
    "low": 107.61,
    "close": 108.84,
    "volume": 8030900
  },
  {
    "time": "19-12-2007",
    "open": 106.54,
    "high": 108.38,
    "low": 105.87,
    "close": 107.14,
    "volume": 7031100
  },
  {
    "time": "18-12-2007",
    "open": 104.4,
    "high": 106.97,
    "low": 104.13,
    "close": 106.31,
    "volume": 8042000
  },
  {
    "time": "17-12-2007",
    "open": 105.37,
    "high": 105.99,
    "low": 104,
    "close": 104.53,
    "volume": 7131100
  },
  {
    "time": "14-12-2007",
    "open": 107.31,
    "high": 107.56,
    "low": 104.1,
    "close": 105.77,
    "volume": 9711200
  },
  {
    "time": "13-12-2007",
    "open": 108,
    "high": 108.64,
    "low": 106.94,
    "close": 108.18,
    "volume": 6252800
  },
  {
    "time": "12-12-2007",
    "open": 108.95,
    "high": 110.2,
    "low": 107.38,
    "close": 108.47,
    "volume": 7879800
  },
  {
    "time": "11-12-2007",
    "open": 108.9,
    "high": 110.4,
    "low": 106.46,
    "close": 106.99,
    "volume": 8439700
  },
  {
    "time": "10-12-2007",
    "open": 109,
    "high": 110.26,
    "low": 109,
    "close": 109.39,
    "volume": 4442200
  },
  {
    "time": "07-12-2007",
    "open": 109.68,
    "high": 110,
    "low": 107.95,
    "close": 108.86,
    "volume": 5985500
  },
  {
    "time": "06-12-2007",
    "open": 108,
    "high": 110,
    "low": 107.93,
    "close": 109.7,
    "volume": 5315500
  },
  {
    "time": "05-12-2007",
    "open": 107.46,
    "high": 108.52,
    "low": 106.98,
    "close": 108.16,
    "volume": 6090400
  },
  {
    "time": "04-12-2007",
    "open": 105.83,
    "high": 107.42,
    "low": 104.72,
    "close": 106.63,
    "volume": 6361800
  },
  {
    "time": "03-12-2007",
    "open": 105.45,
    "high": 107.14,
    "low": 104.58,
    "close": 105.83,
    "volume": 7918800
  },
  {
    "time": "30-11-2007",
    "open": 107.529,
    "high": 109.19,
    "low": 103.93,
    "close": 105.18,
    "volume": 12900200
  },
  {
    "time": "29-11-2007",
    "open": 106.8,
    "high": 107.87,
    "low": 106.25,
    "close": 107.5,
    "volume": 7813800
  },
  {
    "time": "28-11-2007",
    "open": 104.82,
    "high": 108.27,
    "low": 104.36,
    "close": 107.37,
    "volume": 9397700
  },
  {
    "time": "27-11-2007",
    "open": 101.5,
    "high": 104.58,
    "low": 101.5,
    "close": 103.83,
    "volume": 9117500
  },
  {
    "time": "26-11-2007",
    "open": 104.4,
    "high": 105.15,
    "low": 101.72,
    "close": 101.97,
    "volume": 7340700
  },
  {
    "time": "23-11-2007",
    "open": 103.1,
    "high": 104.14,
    "low": 102.32,
    "close": 104.05,
    "volume": 2827700
  },
  {
    "time": "21-11-2007",
    "open": 101.41,
    "high": 104.09,
    "low": 101.41,
    "close": 102.22,
    "volume": 7097200
  },
  {
    "time": "20-11-2007",
    "open": 102.59,
    "high": 104.97,
    "low": 101.38,
    "close": 103.42,
    "volume": 8393400
  },
  {
    "time": "19-11-2007",
    "open": 104.34,
    "high": 105.39,
    "low": 101.34,
    "close": 102.22,
    "volume": 9502200
  },
  {
    "time": "16-11-2007",
    "open": 104.51,
    "high": 105,
    "low": 103.25,
    "close": 104.79,
    "volume": 9294000
  },
  {
    "time": "15-11-2007",
    "open": 103.24,
    "high": 105.64,
    "low": 103.05,
    "close": 103.6,
    "volume": 8023600
  },
  {
    "time": "14-11-2007",
    "open": 106.12,
    "high": 106.42,
    "low": 102.84,
    "close": 103.44,
    "volume": 8453600
  },
  {
    "time": "13-11-2007",
    "open": 103.5,
    "high": 105.74,
    "low": 102.5,
    "close": 105.27,
    "volume": 10777900
  },
  {
    "time": "12-11-2007",
    "open": 101.89,
    "high": 104.19,
    "low": 100.7,
    "close": 101.45,
    "volume": 13553600
  },
  {
    "time": "09-11-2007",
    "open": 104.92,
    "high": 104.92,
    "low": 99.27,
    "close": 100.2542,
    "volume": 18084100
  },
  {
    "time": "08-11-2007",
    "open": 110.1,
    "high": 110.32,
    "low": 103.99,
    "close": 106.11,
    "volume": 23092200
  },
  {
    "time": "07-11-2007",
    "open": 113.56,
    "high": 113.64,
    "low": 110.9,
    "close": 111.08,
    "volume": 7087000
  },
  {
    "time": "06-11-2007",
    "open": 113.49,
    "high": 113.95,
    "low": 111.67,
    "close": 113.17,
    "volume": 7299700
  },
  {
    "time": "05-11-2007",
    "open": 115.11,
    "high": 115.11,
    "low": 112.83,
    "close": 113.4,
    "volume": 7155300
  },
  {
    "time": "02-11-2007",
    "open": 114.42,
    "high": 115.15,
    "low": 113.57,
    "close": 114.59,
    "volume": 6114800
  },
  {
    "time": "01-11-2007",
    "open": 115.5,
    "high": 116.09,
    "low": 113.32,
    "close": 113.65,
    "volume": 7594400
  },
  {
    "time": "31-10-2007",
    "open": 114.75,
    "high": 116.25,
    "low": 113.28,
    "close": 116.12,
    "volume": 7216800
  },
  {
    "time": "30-10-2007",
    "open": 114.5,
    "high": 114.9,
    "low": 113.75,
    "close": 114.12,
    "volume": 4015500
  },
  {
    "time": "29-10-2007",
    "open": 113.9,
    "high": 115.01,
    "low": 113.85,
    "close": 114.8,
    "volume": 5103200
  },
  {
    "time": "26-10-2007",
    "open": 113,
    "high": 114,
    "low": 112.07,
    "close": 113.73,
    "volume": 5030300
  },
  {
    "time": "25-10-2007",
    "open": 113.32,
    "high": 114.4,
    "low": 111.69,
    "close": 112.81,
    "volume": 6519900
  },
  {
    "time": "24-10-2007",
    "open": 114.2,
    "high": 114.45,
    "low": 111.68,
    "close": 112.95,
    "volume": 8072600
  },
  {
    "time": "23-10-2007",
    "open": 113.78,
    "high": 114.8,
    "low": 113.5,
    "close": 114.68,
    "volume": 5562900
  },
  {
    "time": "22-10-2007",
    "open": 110.97,
    "high": 113.88,
    "low": 110.96,
    "close": 113.37,
    "volume": 7576900
  },
  {
    "time": "19-10-2007",
    "open": 113.98,
    "high": 114.93,
    "low": 111.8,
    "close": 112.28,
    "volume": 10327200
  },
  {
    "time": "18-10-2007",
    "open": 114.82,
    "high": 116.41,
    "low": 114.44,
    "close": 114.8,
    "volume": 7736500
  },
  {
    "time": "17-10-2007",
    "open": 118.01,
    "high": 118.01,
    "low": 114.15,
    "close": 115.78,
    "volume": 18100500
  },
  {
    "time": "16-10-2007",
    "open": 118.02,
    "high": 119.94,
    "low": 117.5,
    "close": 119.6,
    "volume": 11527300
  },
  {
    "time": "15-10-2007",
    "open": 117.95,
    "high": 118.37,
    "low": 116.75,
    "close": 118.03,
    "volume": 6267300
  },
  {
    "time": "12-10-2007",
    "open": 118.5,
    "high": 118.89,
    "low": 117.28,
    "close": 117.81,
    "volume": 5656200
  },
  {
    "time": "11-10-2007",
    "open": 118.99,
    "high": 121.46,
    "low": 117.37,
    "close": 118.05,
    "volume": 13018800
  },
  {
    "time": "10-10-2007",
    "open": 118,
    "high": 118.8,
    "low": 117.68,
    "close": 118.62,
    "volume": 5913800
  },
  {
    "time": "09-10-2007",
    "open": 117.85,
    "high": 118.5,
    "low": 116.42,
    "close": 118.3,
    "volume": 5670600
  },
  {
    "time": "08-10-2007",
    "open": 116.1,
    "high": 118.23,
    "low": 115.88,
    "close": 117.77,
    "volume": 4822900
  },
  {
    "time": "05-10-2007",
    "open": 116.17,
    "high": 116.71,
    "low": 115.47,
    "close": 116.3,
    "volume": 5749400
  },
  {
    "time": "04-10-2007",
    "open": 116.2,
    "high": 116.75,
    "low": 115,
    "close": 115.69,
    "volume": 6177500
  },
  {
    "time": "03-10-2007",
    "open": 117.65,
    "high": 117.84,
    "low": 116.28,
    "close": 116.4,
    "volume": 7914100
  },
  {
    "time": "02-10-2007",
    "open": 119.31,
    "high": 119.6,
    "low": 118.14,
    "close": 118.36,
    "volume": 6911500
  },
  {
    "time": "01-10-2007",
    "open": 117.61,
    "high": 119.5,
    "low": 117.5,
    "close": 119.03,
    "volume": 6777300
  },
  {
    "time": "28-09-2007",
    "open": 117.14,
    "high": 118.5,
    "low": 117.1,
    "close": 117.8,
    "volume": 7720200
  },
  {
    "time": "27-09-2007",
    "open": 117.5,
    "high": 118.21,
    "low": 117.04,
    "close": 117.71,
    "volume": 5834500
  },
  {
    "time": "26-09-2007",
    "open": 116.94,
    "high": 118.08,
    "low": 116.75,
    "close": 117.3,
    "volume": 6695200
  },
  {
    "time": "25-09-2007",
    "open": 116.02,
    "high": 117.59,
    "low": 115.79,
    "close": 116.51,
    "volume": 7045200
  },
  {
    "time": "24-09-2007",
    "open": 116.9,
    "high": 117.43,
    "low": 115.85,
    "close": 116.25,
    "volume": 5734300
  },
  {
    "time": "21-09-2007",
    "open": 117.89,
    "high": 118.1,
    "low": 116.35,
    "close": 116.78,
    "volume": 9097300
  },
  {
    "time": "20-09-2007",
    "open": 116.27,
    "high": 117.65,
    "low": 116.2,
    "close": 116.86,
    "volume": 5806400
  },
  {
    "time": "19-09-2007",
    "open": 116.9,
    "high": 117.19,
    "low": 115.78,
    "close": 116.67,
    "volume": 10311200
  },
  {
    "time": "18-09-2007",
    "open": 115,
    "high": 116.78,
    "low": 114.83,
    "close": 116.63,
    "volume": 7839500
  },
  {
    "time": "17-09-2007",
    "open": 114.7,
    "high": 115.25,
    "low": 114.3,
    "close": 114.52,
    "volume": 5022400
  },
  {
    "time": "14-09-2007",
    "open": 115.05,
    "high": 116,
    "low": 114.65,
    "close": 115.13,
    "volume": 6904400
  },
  {
    "time": "13-09-2007",
    "open": 116.3,
    "high": 117,
    "low": 115.74,
    "close": 115.95,
    "volume": 4802700
  },
  {
    "time": "12-09-2007",
    "open": 116.95,
    "high": 117.4,
    "low": 115.69,
    "close": 116,
    "volume": 6287200
  },
  {
    "time": "11-09-2007",
    "open": 116.35,
    "high": 117.78,
    "low": 116.04,
    "close": 117.35,
    "volume": 8098700
  },
  {
    "time": "10-09-2007",
    "open": 116.15,
    "high": 117.5,
    "low": 115.32,
    "close": 115.8,
    "volume": 6118300
  },
  {
    "time": "07-09-2007",
    "open": 116.2,
    "high": 116.97,
    "low": 115.33,
    "close": 115.55,
    "volume": 7511700
  },
  {
    "time": "06-09-2007",
    "open": 117.92,
    "high": 118.75,
    "low": 116.24,
    "close": 117.62,
    "volume": 6778600
  },
  {
    "time": "05-09-2007",
    "open": 117.48,
    "high": 118.15,
    "low": 116.55,
    "close": 117.88,
    "volume": 6555500
  },
  {
    "time": "04-09-2007",
    "open": 116.34,
    "high": 118.89,
    "low": 116.15,
    "close": 118.19,
    "volume": 8070500
  },
  {
    "time": "31-08-2007",
    "open": 115.99,
    "high": 117.35,
    "low": 115.55,
    "close": 116.69,
    "volume": 6883600
  },
  {
    "time": "30-08-2007",
    "open": 113.62,
    "high": 116,
    "low": 113.62,
    "close": 115.37,
    "volume": 7756100
  },
  {
    "time": "29-08-2007",
    "open": 112.88,
    "high": 114.98,
    "low": 112.07,
    "close": 114.57,
    "volume": 7049800
  },
  {
    "time": "28-08-2007",
    "open": 112.9,
    "high": 113.59,
    "low": 111.63,
    "close": 112,
    "volume": 6006600
  },
  {
    "time": "27-08-2007",
    "open": 113,
    "high": 113.85,
    "low": 112.8,
    "close": 113.44,
    "volume": 4921800
  },
  {
    "time": "24-08-2007",
    "open": 111.45,
    "high": 113.25,
    "low": 110.81,
    "close": 113.24,
    "volume": 7058700
  },
  {
    "time": "23-08-2007",
    "open": 110.4,
    "high": 111.68,
    "low": 110.01,
    "close": 111.45,
    "volume": 8271000
  },
  {
    "time": "22-08-2007",
    "open": 110.09,
    "high": 110.5,
    "low": 109.15,
    "close": 110,
    "volume": 6329700
  },
  {
    "time": "21-08-2007",
    "open": 108.9,
    "high": 110.11,
    "low": 108.08,
    "close": 109.04,
    "volume": 8458500
  },
  {
    "time": "20-08-2007",
    "open": 110.9,
    "high": 111.1,
    "low": 109.13,
    "close": 109.22,
    "volume": 9839800
  },
  {
    "time": "17-08-2007",
    "open": 111.9,
    "high": 112.67,
    "low": 108.66,
    "close": 110.9,
    "volume": 15274600
  },
  {
    "time": "16-08-2007",
    "open": 110.98,
    "high": 111.42,
    "low": 103.7,
    "close": 109.69,
    "volume": 16723000
  },
  {
    "time": "15-08-2007",
    "open": 111,
    "high": 112.85,
    "low": 109.91,
    "close": 111.23,
    "volume": 11640200
  },
  {
    "time": "14-08-2007",
    "open": 112.83,
    "high": 113.15,
    "low": 111.74,
    "close": 112.05,
    "volume": 5615600
  },
  {
    "time": "13-08-2007",
    "open": 112.99,
    "high": 113.95,
    "low": 112.31,
    "close": 112.71,
    "volume": 6135500
  },
  {
    "time": "10-08-2007",
    "open": 109.75,
    "high": 113.5,
    "low": 109.7,
    "close": 112.64,
    "volume": 9592200
  },
  {
    "time": "09-08-2007",
    "open": 110.77,
    "high": 114.49,
    "low": 110.73,
    "close": 110.73,
    "volume": 9086000
  },
  {
    "time": "08-08-2007",
    "open": 114.3,
    "high": 114.6,
    "low": 112.26,
    "close": 112.98,
    "volume": 7681400
  },
  {
    "time": "07-08-2007",
    "open": 113.02,
    "high": 114.17,
    "low": 112.25,
    "close": 113.53,
    "volume": 8021400
  },
  {
    "time": "06-08-2007",
    "open": 111.97,
    "high": 113.89,
    "low": 111.7,
    "close": 113.89,
    "volume": 7245500
  },
  {
    "time": "03-08-2007",
    "open": 113.24,
    "high": 114.18,
    "low": 111.7,
    "close": 111.89,
    "volume": 9571300
  },
  {
    "time": "02-08-2007",
    "open": 112.1,
    "high": 114.05,
    "low": 111.5,
    "close": 113.23,
    "volume": 11318900
  },
  {
    "time": "01-08-2007",
    "open": 110.39,
    "high": 112.73,
    "low": 110.06,
    "close": 112.04,
    "volume": 12390300
  },
  {
    "time": "31-07-2007",
    "open": 114.72,
    "high": 114.72,
    "low": 110.02,
    "close": 110.65,
    "volume": 17816200
  },
  {
    "time": "30-07-2007",
    "open": 115.62,
    "high": 115.75,
    "low": 113.52,
    "close": 114.52,
    "volume": 15095400
  },
  {
    "time": "27-07-2007",
    "open": 116.62,
    "high": 117.95,
    "low": 115.62,
    "close": 115.62,
    "volume": 18138700
  },
  {
    "time": "26-07-2007",
    "open": 117.01,
    "high": 118.82,
    "low": 115.52,
    "close": 116.53,
    "volume": 16822100
  },
  {
    "time": "25-07-2007",
    "open": 116.19,
    "high": 118.38,
    "low": 115.24,
    "close": 118.1,
    "volume": 11340000
  },
  {
    "time": "24-07-2007",
    "open": 115.32,
    "high": 117,
    "low": 115.02,
    "close": 116.17,
    "volume": 10499300
  },
  {
    "time": "23-07-2007",
    "open": 114.88,
    "high": 116.43,
    "low": 114.85,
    "close": 116.38,
    "volume": 8040900
  },
  {
    "time": "20-07-2007",
    "open": 114.85,
    "high": 115.86,
    "low": 113.94,
    "close": 114.81,
    "volume": 11706300
  },
  {
    "time": "19-07-2007",
    "open": 115,
    "high": 116.48,
    "low": 114.65,
    "close": 115.86,
    "volume": 17281600
  },
  {
    "time": "18-07-2007",
    "open": 110.25,
    "high": 111.88,
    "low": 110.04,
    "close": 111.08,
    "volume": 10154100
  },
  {
    "time": "17-07-2007",
    "open": 109.66,
    "high": 111.47,
    "low": 109.45,
    "close": 110.77,
    "volume": 7619900
  },
  {
    "time": "16-07-2007",
    "open": 108.57,
    "high": 110.04,
    "low": 108.42,
    "close": 109.66,
    "volume": 8864200
  },
  {
    "time": "13-07-2007",
    "open": 108.75,
    "high": 109.2,
    "low": 108.1,
    "close": 108.6,
    "volume": 7124100
  },
  {
    "time": "12-07-2007",
    "open": 109.35,
    "high": 109.65,
    "low": 108.22,
    "close": 109.28,
    "volume": 9544100
  },
  {
    "time": "11-07-2007",
    "open": 108.3,
    "high": 109.6,
    "low": 108.25,
    "close": 109.1,
    "volume": 5887800
  },
  {
    "time": "10-07-2007",
    "open": 108.55,
    "high": 109.15,
    "low": 108.25,
    "close": 108.63,
    "volume": 6209300
  },
  {
    "time": "09-07-2007",
    "open": 108.73,
    "high": 109.35,
    "low": 108.2,
    "close": 108.97,
    "volume": 6046300
  },
  {
    "time": "06-07-2007",
    "open": 107.79,
    "high": 109.66,
    "low": 107.66,
    "close": 109.03,
    "volume": 8977200
  },
  {
    "time": "05-07-2007",
    "open": 106.31,
    "high": 108.39,
    "low": 105.98,
    "close": 108.05,
    "volume": 6670900
  },
  {
    "time": "03-07-2007",
    "open": 105.5,
    "high": 107.08,
    "low": 105.24,
    "close": 106.58,
    "volume": 4217900
  },
  {
    "time": "02-07-2007",
    "open": 105.39,
    "high": 105.8,
    "low": 104.58,
    "close": 105.01,
    "volume": 4701300
  },
  {
    "time": "29-06-2007",
    "open": 105.96,
    "high": 106.58,
    "low": 104.1,
    "close": 105.25,
    "volume": 7018400
  },
  {
    "time": "28-06-2007",
    "open": 105.52,
    "high": 106.92,
    "low": 105.22,
    "close": 105.95,
    "volume": 5430900
  },
  {
    "time": "27-06-2007",
    "open": 104.4,
    "high": 105.47,
    "low": 104.4,
    "close": 105.43,
    "volume": 5403800
  },
  {
    "time": "26-06-2007",
    "open": 105.1,
    "high": 106.06,
    "low": 104.82,
    "close": 105.33,
    "volume": 5229600
  },
  {
    "time": "25-06-2007",
    "open": 104.73,
    "high": 105.77,
    "low": 104.5,
    "close": 105.1,
    "volume": 8476400
  },
  {
    "time": "22-06-2007",
    "open": 105.79,
    "high": 106.44,
    "low": 104.44,
    "close": 104.44,
    "volume": 10808500
  },
  {
    "time": "21-06-2007",
    "open": 105.77,
    "high": 107,
    "low": 105.61,
    "close": 106.6,
    "volume": 5587800
  },
  {
    "time": "20-06-2007",
    "open": 106.5,
    "high": 107.02,
    "low": 105.95,
    "close": 106,
    "volume": 6541500
  },
  {
    "time": "19-06-2007",
    "open": 104.93,
    "high": 106.66,
    "low": 104.9,
    "close": 106.5,
    "volume": 5754000
  },
  {
    "time": "18-06-2007",
    "open": 105.27,
    "high": 105.65,
    "low": 104.89,
    "close": 105.33,
    "volume": 6359200
  },
  {
    "time": "15-06-2007",
    "open": 104.4,
    "high": 105.19,
    "low": 103.88,
    "close": 105.09,
    "volume": 9432300
  },
  {
    "time": "14-06-2007",
    "open": 103.12,
    "high": 104.24,
    "low": 102.81,
    "close": 103.85,
    "volume": 5165600
  },
  {
    "time": "13-06-2007",
    "open": 102.91,
    "high": 103.26,
    "low": 102.1,
    "close": 103.12,
    "volume": 6437600
  },
  {
    "time": "12-06-2007",
    "open": 103.11,
    "high": 103.18,
    "low": 102.15,
    "close": 102.34,
    "volume": 5578400
  },
  {
    "time": "11-06-2007",
    "open": 102.87,
    "high": 104,
    "low": 102.5,
    "close": 103.22,
    "volume": 7903100
  },
  {
    "time": "08-06-2007",
    "open": 101.8,
    "high": 103.21,
    "low": 101.75,
    "close": 103.07,
    "volume": 8216300
  },
  {
    "time": "07-06-2007",
    "open": 102.46,
    "high": 103.17,
    "low": 101.56,
    "close": 101.8,
    "volume": 14384600
  },
  {
    "time": "06-06-2007",
    "open": 105.5,
    "high": 105.51,
    "low": 102.39,
    "close": 102.41,
    "volume": 30770700
  },
  {
    "time": "05-06-2007",
    "open": 106.55,
    "high": 106.55,
    "low": 105.54,
    "close": 105.84,
    "volume": 5244200
  },
  {
    "time": "04-06-2007",
    "open": 106.5,
    "high": 106.59,
    "low": 105.58,
    "close": 106.23,
    "volume": 4569900
  },
  {
    "time": "01-06-2007",
    "open": 106.62,
    "high": 107.24,
    "low": 106.02,
    "close": 106.54,
    "volume": 5289200
  },
  {
    "time": "31-05-2007",
    "open": 107.23,
    "high": 107.67,
    "low": 106,
    "close": 106.6,
    "volume": 5097700
  },
  {
    "time": "30-05-2007",
    "open": 105.4,
    "high": 107.1,
    "low": 105.21,
    "close": 106.93,
    "volume": 7401000
  },
  {
    "time": "29-05-2007",
    "open": 105.57,
    "high": 106.63,
    "low": 105.3,
    "close": 105.91,
    "volume": 5420300
  },
  {
    "time": "25-05-2007",
    "open": 103.9,
    "high": 105.43,
    "low": 103.85,
    "close": 105.18,
    "volume": 6123000
  },
  {
    "time": "24-05-2007",
    "open": 105.25,
    "high": 105.98,
    "low": 103.57,
    "close": 103.95,
    "volume": 6926300
  },
  {
    "time": "23-05-2007",
    "open": 106.8,
    "high": 107,
    "low": 105.25,
    "close": 105.58,
    "volume": 4396900
  },
  {
    "time": "22-05-2007",
    "open": 106.95,
    "high": 107.25,
    "low": 106.01,
    "close": 106.7,
    "volume": 5313300
  },
  {
    "time": "21-05-2007",
    "open": 107.15,
    "high": 108,
    "low": 106.87,
    "close": 107.04,
    "volume": 7512200
  },
  {
    "time": "18-05-2007",
    "open": 105.84,
    "high": 108.05,
    "low": 105.6,
    "close": 107.99,
    "volume": 9857500
  },
  {
    "time": "17-05-2007",
    "open": 105.61,
    "high": 106.23,
    "low": 104.73,
    "close": 105.31,
    "volume": 7706400
  },
  {
    "time": "16-05-2007",
    "open": 105.1,
    "high": 105.89,
    "low": 104.53,
    "close": 105.87,
    "volume": 5613500
  },
  {
    "time": "15-05-2007",
    "open": 105.4,
    "high": 105.8,
    "low": 104.7,
    "close": 104.83,
    "volume": 6104200
  },
  {
    "time": "14-05-2007",
    "open": 105.81,
    "high": 106.25,
    "low": 104.79,
    "close": 105.57,
    "volume": 5732600
  },
  {
    "time": "11-05-2007",
    "open": 104.69,
    "high": 105.99,
    "low": 104.67,
    "close": 105.98,
    "volume": 6026100
  },
  {
    "time": "10-05-2007",
    "open": 103.82,
    "high": 105.11,
    "low": 103.71,
    "close": 104.68,
    "volume": 8959800
  },
  {
    "time": "09-05-2007",
    "open": 103.93,
    "high": 104.75,
    "low": 103.7,
    "close": 104.38,
    "volume": 8408100
  },
  {
    "time": "08-05-2007",
    "open": 102.4,
    "high": 103.39,
    "low": 102.21,
    "close": 103.29,
    "volume": 5965000
  },
  {
    "time": "07-05-2007",
    "open": 102.76,
    "high": 103.17,
    "low": 102.4,
    "close": 102.76,
    "volume": 5620100
  },
  {
    "time": "04-05-2007",
    "open": 102.75,
    "high": 103.03,
    "low": 102.25,
    "close": 102.96,
    "volume": 5464800
  },
  {
    "time": "03-05-2007",
    "open": 102.1,
    "high": 103,
    "low": 101.35,
    "close": 102.8,
    "volume": 7302200
  },
  {
    "time": "02-05-2007",
    "open": 102.8,
    "high": 103.14,
    "low": 102.13,
    "close": 102.22,
    "volume": 6820300
  },
  {
    "time": "01-05-2007",
    "open": 102.06,
    "high": 103.17,
    "low": 102.05,
    "close": 103.17,
    "volume": 8670100
  },
  {
    "time": "30-04-2007",
    "open": 101.11,
    "high": 103,
    "low": 100.97,
    "close": 102.21,
    "volume": 10050400
  },
  {
    "time": "27-04-2007",
    "open": 100.3,
    "high": 101.17,
    "low": 100.06,
    "close": 101.17,
    "volume": 6141400
  },
  {
    "time": "26-04-2007",
    "open": 100.4,
    "high": 101.5,
    "low": 100.21,
    "close": 100.9,
    "volume": 8608500
  },
  {
    "time": "25-04-2007",
    "open": 98.75,
    "high": 101.7,
    "low": 98.73,
    "close": 101.46,
    "volume": 14827300
  },
  {
    "time": "24-04-2007",
    "open": 99.55,
    "high": 99.9,
    "low": 97.23,
    "close": 98.49,
    "volume": 21450700
  },
  {
    "time": "23-04-2007",
    "open": 95.35,
    "high": 95.73,
    "low": 95.05,
    "close": 95.21,
    "volume": 7247400
  },
  {
    "time": "20-04-2007",
    "open": 95.02,
    "high": 95.15,
    "low": 93.911,
    "close": 94.58,
    "volume": 9340300
  },
  {
    "time": "19-04-2007",
    "open": 94.5,
    "high": 94.87,
    "low": 94,
    "close": 94.29,
    "volume": 9445600
  },
  {
    "time": "18-04-2007",
    "open": 94.8,
    "high": 95.91,
    "low": 94.4,
    "close": 94.8,
    "volume": 15871700
  },
  {
    "time": "17-04-2007",
    "open": 96.3,
    "high": 97.66,
    "low": 96.02,
    "close": 97.12,
    "volume": 10264000
  },
  {
    "time": "16-04-2007",
    "open": 94.99,
    "high": 96.3,
    "low": 94.72,
    "close": 96.18,
    "volume": 6865100
  },
  {
    "time": "13-04-2007",
    "open": 95.75,
    "high": 95.75,
    "low": 94.59,
    "close": 94.93,
    "volume": 6027800
  },
  {
    "time": "12-04-2007",
    "open": 94.86,
    "high": 95.82,
    "low": 94.53,
    "close": 95.67,
    "volume": 7085300
  },
  {
    "time": "11-04-2007",
    "open": 96.46,
    "high": 96.7,
    "low": 94.95,
    "close": 95.16,
    "volume": 5936900
  },
  {
    "time": "10-04-2007",
    "open": 96.4,
    "high": 96.8,
    "low": 96.1642,
    "close": 96.46,
    "volume": 3596400
  },
  {
    "time": "09-04-2007",
    "open": 96.43,
    "high": 96.94,
    "low": 96.12,
    "close": 96.62,
    "volume": 4850600
  },
  {
    "time": "05-04-2007",
    "open": 95.98,
    "high": 96.75,
    "low": 95.75,
    "close": 96.52,
    "volume": 3307100
  },
  {
    "time": "04-04-2007",
    "open": 95.8,
    "high": 96.45,
    "low": 95.57,
    "close": 96.21,
    "volume": 4276200
  },
  {
    "time": "03-04-2007",
    "open": 95.45,
    "high": 96.23,
    "low": 95.33,
    "close": 96.1,
    "volume": 5730800
  },
  {
    "time": "02-04-2007",
    "open": 94.51,
    "high": 95.5,
    "low": 94.06,
    "close": 95.21,
    "volume": 5883700
  },
  {
    "time": "30-03-2007",
    "open": 94.24,
    "high": 94.75,
    "low": 93.57,
    "close": 94.26,
    "volume": 7638100
  },
  {
    "time": "29-03-2007",
    "open": 94.95,
    "high": 95.11,
    "low": 93.96,
    "close": 94.57,
    "volume": 6851500
  },
  {
    "time": "28-03-2007",
    "open": 93.9,
    "high": 95.02,
    "low": 93.8,
    "close": 94.26,
    "volume": 8128000
  },
  {
    "time": "27-03-2007",
    "open": 94.71,
    "high": 95.03,
    "low": 94.2,
    "close": 94.73,
    "volume": 4695800
  },
  {
    "time": "26-03-2007",
    "open": 94.84,
    "high": 95.25,
    "low": 94.32,
    "close": 95,
    "volume": 6729400
  },
  {
    "time": "23-03-2007",
    "open": 94.86,
    "high": 95.22,
    "low": 94.65,
    "close": 95.03,
    "volume": 4412500
  },
  {
    "time": "22-03-2007",
    "open": 95.22,
    "high": 95.44,
    "low": 94.65,
    "close": 95.19,
    "volume": 5700400
  },
  {
    "time": "21-03-2007",
    "open": 94.5,
    "high": 95.81,
    "low": 93.83,
    "close": 95.36,
    "volume": 7665600
  },
  {
    "time": "20-03-2007",
    "open": 94,
    "high": 94.53,
    "low": 93.55,
    "close": 94.5,
    "volume": 4560000
  },
  {
    "time": "19-03-2007",
    "open": 93.95,
    "high": 94.32,
    "low": 93.39,
    "close": 93.99,
    "volume": 4568100
  },
  {
    "time": "16-03-2007",
    "open": 93.49,
    "high": 94.45,
    "low": 93.02,
    "close": 93.25,
    "volume": 8728700
  },
  {
    "time": "15-03-2007",
    "open": 93.4,
    "high": 93.97,
    "low": 93.1,
    "close": 93.45,
    "volume": 5910000
  },
  {
    "time": "14-03-2007",
    "open": 92.71,
    "high": 94.03,
    "low": 92.1,
    "close": 93.76,
    "volume": 8713900
  },
  {
    "time": "13-03-2007",
    "open": 93.86,
    "high": 94.47,
    "low": 92.62,
    "close": 92.71,
    "volume": 7539100
  },
  {
    "time": "12-03-2007",
    "open": 93.29,
    "high": 94.17,
    "low": 92.97,
    "close": 94.11,
    "volume": 5531300
  },
  {
    "time": "09-03-2007",
    "open": 93.48,
    "high": 94,
    "low": 92.82,
    "close": 93.28,
    "volume": 6333000
  },
  {
    "time": "08-03-2007",
    "open": 94.25,
    "high": 94.82,
    "low": 92.519,
    "close": 93,
    "volume": 7403900
  },
  {
    "time": "07-03-2007",
    "open": 93.97,
    "high": 94.85,
    "low": 93.62,
    "close": 93.94,
    "volume": 10269100
  },
  {
    "time": "06-03-2007",
    "open": 92.4,
    "high": 93.89,
    "low": 92.26,
    "close": 93.8,
    "volume": 9184200
  },
  {
    "time": "05-03-2007",
    "open": 90.25,
    "high": 92.15,
    "low": 90.1,
    "close": 91.81,
    "volume": 10314900
  },
  {
    "time": "02-03-2007",
    "open": 91.86,
    "high": 92.37,
    "low": 90.84,
    "close": 90.9,
    "volume": 9451300
  },
  {
    "time": "01-03-2007",
    "open": 90.25,
    "high": 93.05,
    "low": 88.77,
    "close": 92.27,
    "volume": 12865700
  },
  {
    "time": "28-02-2007",
    "open": 93.71,
    "high": 94.09,
    "low": 92.47,
    "close": 92.94,
    "volume": 12191600
  },
  {
    "time": "27-02-2007",
    "open": 96,
    "high": 96.01,
    "low": 92.88,
    "close": 93.96,
    "volume": 11996700
  },
  {
    "time": "26-02-2007",
    "open": 97.86,
    "high": 97.86,
    "low": 96.46,
    "close": 96.91,
    "volume": 5922100
  },
  {
    "time": "23-02-2007",
    "open": 98.4,
    "high": 98.44,
    "low": 97.41,
    "close": 97.73,
    "volume": 6355800
  },
  {
    "time": "22-02-2007",
    "open": 98.8,
    "high": 99.5,
    "low": 97.77,
    "close": 98.5,
    "volume": 5900100
  },
  {
    "time": "21-02-2007",
    "open": 98.9,
    "high": 99.37,
    "low": 98.7,
    "close": 99.09,
    "volume": 4302400
  },
  {
    "time": "20-02-2007",
    "open": 98.66,
    "high": 99.46,
    "low": 98.55,
    "close": 99.35,
    "volume": 4124200
  },
  {
    "time": "16-02-2007",
    "open": 99.12,
    "high": 99.25,
    "low": 98.63,
    "close": 98.99,
    "volume": 4800700
  },
  {
    "time": "15-02-2007",
    "open": 99.06,
    "high": 99.52,
    "low": 98.48,
    "close": 98.92,
    "volume": 5568600
  },
  {
    "time": "14-02-2007",
    "open": 98.5,
    "high": 99.43,
    "low": 98.25,
    "close": 99.2,
    "volume": 5644800
  },
  {
    "time": "13-02-2007",
    "open": 98.6,
    "high": 98.74,
    "low": 97.8,
    "close": 98.29,
    "volume": 5702800
  },
  {
    "time": "12-02-2007",
    "open": 98.8,
    "high": 99.2,
    "low": 98.22,
    "close": 98.58,
    "volume": 5331000
  },
  {
    "time": "09-02-2007",
    "open": 99.35,
    "high": 99.7,
    "low": 97.81,
    "close": 98.55,
    "volume": 6100400
  },
  {
    "time": "08-02-2007",
    "open": 98.9,
    "high": 99.74,
    "low": 98.65,
    "close": 99.62,
    "volume": 6152200
  },
  {
    "time": "07-02-2007",
    "open": 99.8,
    "high": 100.36,
    "low": 99.12,
    "close": 99.54,
    "volume": 7698200
  },
  {
    "time": "06-02-2007",
    "open": 100,
    "high": 100.4,
    "low": 99.54,
    "close": 99.55,
    "volume": 6532800
  },
  {
    "time": "05-02-2007",
    "open": 99.17,
    "high": 100.44,
    "low": 98.9,
    "close": 100.38,
    "volume": 8184800
  },
  {
    "time": "02-02-2007",
    "open": 99.1,
    "high": 99.73,
    "low": 98.88,
    "close": 99.17,
    "volume": 6656300
  },
  {
    "time": "01-02-2007",
    "open": 98.97,
    "high": 99.18,
    "low": 97.96,
    "close": 99,
    "volume": 6610700
  },
  {
    "time": "31-01-2007",
    "open": 98.8,
    "high": 99.48,
    "low": 98.35,
    "close": 99.15,
    "volume": 6432600
  },
  {
    "time": "30-01-2007",
    "open": 98.62,
    "high": 99.45,
    "low": 98.5,
    "close": 99.37,
    "volume": 7177900
  },
  {
    "time": "29-01-2007",
    "open": 98.1,
    "high": 98.66,
    "low": 97.45,
    "close": 98.54,
    "volume": 7294800
  },
  {
    "time": "26-01-2007",
    "open": 97.49,
    "high": 97.83,
    "low": 96.84,
    "close": 97.45,
    "volume": 5771100
  },
  {
    "time": "25-01-2007",
    "open": 97.22,
    "high": 97.92,
    "low": 97.22,
    "close": 97.51,
    "volume": 6201300
  },
  {
    "time": "24-01-2007",
    "open": 97.14,
    "high": 97.58,
    "low": 96.58,
    "close": 97.4,
    "volume": 5700000
  },
  {
    "time": "23-01-2007",
    "open": 96.91,
    "high": 97.38,
    "low": 96.2,
    "close": 97.08,
    "volume": 10337400
  },
  {
    "time": "22-01-2007",
    "open": 96.42,
    "high": 97.23,
    "low": 96.12,
    "close": 97.11,
    "volume": 13539300
  },
  {
    "time": "19-01-2007",
    "open": 95,
    "high": 96.85,
    "low": 94.55,
    "close": 96.17,
    "volume": 26035800
  },
  {
    "time": "18-01-2007",
    "open": 99.79,
    "high": 99.95,
    "low": 98.91,
    "close": 99.45,
    "volume": 14636100
  },
  {
    "time": "17-01-2007",
    "open": 100.69,
    "high": 100.9,
    "low": 99.9,
    "close": 100.02,
    "volume": 8200700
  },
  {
    "time": "16-01-2007",
    "open": 99.4,
    "high": 100.84,
    "low": 99.3,
    "close": 100.82,
    "volume": 9602200
  },
  {
    "time": "12-01-2007",
    "open": 98.99,
    "high": 99.69,
    "low": 98.5,
    "close": 99.34,
    "volume": 6636500
  },
  {
    "time": "11-01-2007",
    "open": 99.85,
    "high": 99.9,
    "low": 98.5,
    "close": 98.65,
    "volume": 8000700
  },
  {
    "time": "10-01-2007",
    "open": 99.03,
    "high": 99.05,
    "low": 97.93,
    "close": 98.89,
    "volume": 8744800
  },
  {
    "time": "09-01-2007",
    "open": 99.24,
    "high": 100.33,
    "low": 99.07,
    "close": 100.07,
    "volume": 11108200
  },
  {
    "time": "08-01-2007",
    "open": 98.5,
    "high": 99.5,
    "low": 98.35,
    "close": 98.9,
    "volume": 10340000
  },
  {
    "time": "05-01-2007",
    "open": 97.6,
    "high": 97.95,
    "low": 96.91,
    "close": 97.42,
    "volume": 7221300
  },
  {
    "time": "04-01-2007",
    "open": 96.95,
    "high": 98.79,
    "low": 96.88,
    "close": 98.31,
    "volume": 10524500
  },
  {
    "time": "03-01-2007",
    "open": 97.17,
    "high": 98.4,
    "low": 96.26,
    "close": 97.27,
    "volume": 9196800
  },
  {
    "time": "29-12-2006",
    "open": 97,
    "high": 97.88,
    "low": 96.83,
    "close": 97.15,
    "volume": 4455900
  },
  {
    "time": "28-12-2006",
    "open": 97.11,
    "high": 97.4,
    "low": 96.87,
    "close": 96.97,
    "volume": 4501700
  },
  {
    "time": "27-12-2006",
    "open": 96.3,
    "high": 97.23,
    "low": 96.2728,
    "close": 97.2,
    "volume": 4571600
  },
  {
    "time": "26-12-2006",
    "open": 95,
    "high": 95.81,
    "low": 94.92,
    "close": 95.66,
    "volume": 2278700
  },
  {
    "time": "22-12-2006",
    "open": 95.75,
    "high": 95.98,
    "low": 95.24,
    "close": 95.25,
    "volume": 3031500
  },
  {
    "time": "21-12-2006",
    "open": 95.86,
    "high": 96.5,
    "low": 95.53,
    "close": 95.91,
    "volume": 4405600
  },
  {
    "time": "20-12-2006",
    "open": 96.31,
    "high": 96.5,
    "low": 95.7,
    "close": 96,
    "volume": 3654600
  },
  {
    "time": "19-12-2006",
    "open": 95.1,
    "high": 96.39,
    "low": 94.91,
    "close": 96,
    "volume": 4933200
  },
  {
    "time": "18-12-2006",
    "open": 94.95,
    "high": 96.16,
    "low": 94.8,
    "close": 95.44,
    "volume": 7984900
  },
  {
    "time": "15-12-2006",
    "open": 95.61,
    "high": 95.8,
    "low": 95.05,
    "close": 95.3,
    "volume": 8086500
  },
  {
    "time": "14-12-2006",
    "open": 94.76,
    "high": 95.72,
    "low": 94.54,
    "close": 95.36,
    "volume": 4644100
  },
  {
    "time": "13-12-2006",
    "open": 94.78,
    "high": 95.33,
    "low": 94.5,
    "close": 94.77,
    "volume": 6441300
  },
  {
    "time": "12-12-2006",
    "open": 93.6,
    "high": 94.85,
    "low": 93.3,
    "close": 94.12,
    "volume": 7783800
  },
  {
    "time": "11-12-2006",
    "open": 93.72,
    "high": 94.35,
    "low": 93.56,
    "close": 93.64,
    "volume": 3459200
  },
  {
    "time": "08-12-2006",
    "open": 93.65,
    "high": 94.39,
    "low": 93.47,
    "close": 93.86,
    "volume": 4140000
  },
  {
    "time": "07-12-2006",
    "open": 94,
    "high": 94.43,
    "low": 93.5521,
    "close": 94.23,
    "volume": 4310800
  },
  {
    "time": "06-12-2006",
    "open": 94.7,
    "high": 94.87,
    "low": 94.01,
    "close": 94.12,
    "volume": 5947300
  },
  {
    "time": "05-12-2006",
    "open": 93.6,
    "high": 94.64,
    "low": 93.44,
    "close": 94.48,
    "volume": 6886200
  },
  {
    "time": "04-12-2006",
    "open": 92.5,
    "high": 93.95,
    "low": 92.25,
    "close": 93.51,
    "volume": 7507900
  },
  {
    "time": "01-12-2006",
    "open": 91.9,
    "high": 91.91,
    "low": 90.55,
    "close": 91.25,
    "volume": 5445400
  },
  {
    "time": "30-11-2006",
    "open": 91.35,
    "high": 92.48,
    "low": 91.25,
    "close": 91.92,
    "volume": 6470700
  },
  {
    "time": "29-11-2006",
    "open": 91.35,
    "high": 91.66,
    "low": 91.01,
    "close": 91.52,
    "volume": 4623000
  },
  {
    "time": "28-11-2006",
    "open": 91.05,
    "high": 91.57,
    "low": 90.43,
    "close": 91.35,
    "volume": 4962100
  },
  {
    "time": "27-11-2006",
    "open": 92.71,
    "high": 93.24,
    "low": 91.12,
    "close": 91.45,
    "volume": 6284000
  },
  {
    "time": "24-11-2006",
    "open": 93.05,
    "high": 93.75,
    "low": 92.98,
    "close": 93.35,
    "volume": 2129800
  },
  {
    "time": "22-11-2006",
    "open": 92.9,
    "high": 93.74,
    "low": 92.8,
    "close": 93.52,
    "volume": 3351700
  },
  {
    "time": "21-11-2006",
    "open": 92.85,
    "high": 93.43,
    "low": 92.85,
    "close": 93.08,
    "volume": 3067800
  },
  {
    "time": "20-11-2006",
    "open": 93.77,
    "high": 93.8,
    "low": 93.01,
    "close": 93.25,
    "volume": 5010600
  },
  {
    "time": "17-11-2006",
    "open": 93.42,
    "high": 94.05,
    "low": 93.31,
    "close": 93.81,
    "volume": 5251000
  },
  {
    "time": "16-11-2006",
    "open": 93.07,
    "high": 93.6,
    "low": 92.6,
    "close": 93.47,
    "volume": 4171500
  },
  {
    "time": "15-11-2006",
    "open": 93.08,
    "high": 93.28,
    "low": 92.54,
    "close": 93.11,
    "volume": 4172800
  },
  {
    "time": "14-11-2006",
    "open": 92,
    "high": 93.29,
    "low": 91.65,
    "close": 93.29,
    "volume": 5873000
  },
  {
    "time": "13-11-2006",
    "open": 91.75,
    "high": 92.43,
    "low": 91.41,
    "close": 92.07,
    "volume": 4358300
  },
  {
    "time": "10-11-2006",
    "open": 92.3,
    "high": 92.35,
    "low": 91.63,
    "close": 91.76,
    "volume": 5369600
  },
  {
    "time": "09-11-2006",
    "open": 92.59,
    "high": 93.19,
    "low": 92.37,
    "close": 92.42,
    "volume": 4518900
  },
  {
    "time": "08-11-2006",
    "open": 92.25,
    "high": 92.92,
    "low": 92.01,
    "close": 92.59,
    "volume": 6022200
  },
  {
    "time": "07-11-2006",
    "open": 92.42,
    "high": 93.4,
    "low": 92.41,
    "close": 92.45,
    "volume": 7305200
  },
  {
    "time": "06-11-2006",
    "open": 91.5,
    "high": 92.74,
    "low": 91.5,
    "close": 92.6,
    "volume": 6240600
  },
  {
    "time": "03-11-2006",
    "open": 91.76,
    "high": 92.34,
    "low": 91.1816,
    "close": 91.41,
    "volume": 4506400
  },
  {
    "time": "02-11-2006",
    "open": 91.8,
    "high": 91.95,
    "low": 91.25,
    "close": 91.68,
    "volume": 4895100
  },
  {
    "time": "01-11-2006",
    "open": 92.5,
    "high": 92.68,
    "low": 91.5,
    "close": 91.8,
    "volume": 5845700
  },
  {
    "time": "31-10-2006",
    "open": 91.5,
    "high": 92.68,
    "low": 91.5,
    "close": 92.33,
    "volume": 9267300
  },
  {
    "time": "30-10-2006",
    "open": 90.3,
    "high": 92,
    "low": 89.8,
    "close": 91.5,
    "volume": 5594000
  },
  {
    "time": "27-10-2006",
    "open": 90.91,
    "high": 91.89,
    "low": 90.58,
    "close": 90.76,
    "volume": 5840300
  },
  {
    "time": "26-10-2006",
    "open": 91.63,
    "high": 91.93,
    "low": 91.25,
    "close": 91.54,
    "volume": 4990300
  },
  {
    "time": "25-10-2006",
    "open": 91.21,
    "high": 91.99,
    "low": 90.94,
    "close": 91.83,
    "volume": 6122100
  },
  {
    "time": "24-10-2006",
    "open": 90.91,
    "high": 91.95,
    "low": 90.8,
    "close": 91.49,
    "volume": 9222300
  },
  {
    "time": "23-10-2006",
    "open": 90.05,
    "high": 92,
    "low": 90.01,
    "close": 91.56,
    "volume": 8862300
  },
  {
    "time": "20-10-2006",
    "open": 90.04,
    "high": 90.7,
    "low": 89.55,
    "close": 90.48,
    "volume": 7390900
  },
  {
    "time": "19-10-2006",
    "open": 89.45,
    "high": 90.98,
    "low": 89.31,
    "close": 89.86,
    "volume": 10671400
  },
  {
    "time": "18-10-2006",
    "open": 90.6,
    "high": 92.04,
    "low": 89.73,
    "close": 89.82,
    "volume": 27875200
  },
  {
    "time": "17-10-2006",
    "open": 86.35,
    "high": 87.94,
    "low": 86.22,
    "close": 86.95,
    "volume": 13295500
  },
  {
    "time": "16-10-2006",
    "open": 86.08,
    "high": 87.6,
    "low": 85.75,
    "close": 86.71,
    "volume": 8633100
  },
  {
    "time": "13-10-2006",
    "open": 84.45,
    "high": 86.21,
    "low": 84.44,
    "close": 86.08,
    "volume": 7788600
  },
  {
    "time": "12-10-2006",
    "open": 84.2,
    "high": 84.9,
    "low": 84.1,
    "close": 84.7,
    "volume": 4661700
  },
  {
    "time": "11-10-2006",
    "open": 84.15,
    "high": 84.6869,
    "low": 83.79,
    "close": 84.19,
    "volume": 5210600
  },
  {
    "time": "10-10-2006",
    "open": 83.71,
    "high": 84.63,
    "low": 83.56,
    "close": 84.19,
    "volume": 4712900
  },
  {
    "time": "09-10-2006",
    "open": 83.14,
    "high": 84.08,
    "low": 83,
    "close": 84,
    "volume": 4490200
  },
  {
    "time": "06-10-2006",
    "open": 82.93,
    "high": 83.78,
    "low": 82.5,
    "close": 83.14,
    "volume": 5340900
  },
  {
    "time": "05-10-2006",
    "open": 82.81,
    "high": 83.21,
    "low": 82.6,
    "close": 82.92,
    "volume": 4956600
  },
  {
    "time": "04-10-2006",
    "open": 81.65,
    "high": 83.25,
    "low": 81.631,
    "close": 83.1,
    "volume": 6119300
  },
  {
    "time": "03-10-2006",
    "open": 81.88,
    "high": 82.26,
    "low": 81.56,
    "close": 81.65,
    "volume": 4838500
  },
  {
    "time": "02-10-2006",
    "open": 81.76,
    "high": 82.47,
    "low": 81.58,
    "close": 81.87,
    "volume": 4483300
  },
  {
    "time": "29-09-2006",
    "open": 81.9,
    "high": 82.79,
    "low": 81.77,
    "close": 81.94,
    "volume": 4770700
  },
  {
    "time": "28-09-2006",
    "open": 81.85,
    "high": 82.35,
    "low": 81.72,
    "close": 81.99,
    "volume": 4592300
  },
  {
    "time": "27-09-2006",
    "open": 82,
    "high": 82.67,
    "low": 81.71,
    "close": 82.09,
    "volume": 5880500
  },
  {
    "time": "26-09-2006",
    "open": 81.9,
    "high": 82.66,
    "low": 81.7,
    "close": 82.5,
    "volume": 4731800
  },
  {
    "time": "25-09-2006",
    "open": 81.64,
    "high": 82.28,
    "low": 81.28,
    "close": 82,
    "volume": 5683000
  },
  {
    "time": "22-09-2006",
    "open": 81.75,
    "high": 82.12,
    "low": 81.01,
    "close": 81.21,
    "volume": 4350200
  },
  {
    "time": "21-09-2006",
    "open": 82.61,
    "high": 83.25,
    "low": 81.35,
    "close": 81.61,
    "volume": 8909000
  },
  {
    "time": "20-09-2006",
    "open": 82.37,
    "high": 83.79,
    "low": 82.2,
    "close": 83.42,
    "volume": 7524600
  },
  {
    "time": "19-09-2006",
    "open": 81.95,
    "high": 82.09,
    "low": 81.42,
    "close": 81.87,
    "volume": 5088600
  },
  {
    "time": "18-09-2006",
    "open": 82.56,
    "high": 83.21,
    "low": 82.17,
    "close": 82.24,
    "volume": 4417600
  },
  {
    "time": "15-09-2006",
    "open": 83.21,
    "high": 83.59,
    "low": 82.79,
    "close": 82.94,
    "volume": 8203800
  },
  {
    "time": "14-09-2006",
    "open": 81.9,
    "high": 83,
    "low": 81.74,
    "close": 82.47,
    "volume": 4265300
  },
  {
    "time": "13-09-2006",
    "open": 82.25,
    "high": 82.7,
    "low": 81.84,
    "close": 82.21,
    "volume": 4920200
  },
  {
    "time": "12-09-2006",
    "open": 81.14,
    "high": 82.5,
    "low": 81.06,
    "close": 82.28,
    "volume": 5722200
  },
  {
    "time": "11-09-2006",
    "open": 80.6,
    "high": 81.2,
    "low": 80.25,
    "close": 80.93,
    "volume": 4314300
  },
  {
    "time": "08-09-2006",
    "open": 79.6,
    "high": 80.86,
    "low": 79.35,
    "close": 80.66,
    "volume": 4498600
  },
  {
    "time": "07-09-2006",
    "open": 79.9,
    "high": 80.38,
    "low": 79.31,
    "close": 79.4,
    "volume": 3913300
  },
  {
    "time": "06-09-2006",
    "open": 80.29,
    "high": 80.69,
    "low": 80.17,
    "close": 80.28,
    "volume": 3727600
  },
  {
    "time": "05-09-2006",
    "open": 81.19,
    "high": 81.54,
    "low": 80.6,
    "close": 80.85,
    "volume": 3968500
  },
  {
    "time": "01-09-2006",
    "open": 81.13,
    "high": 81.57,
    "low": 80.81,
    "close": 81.41,
    "volume": 3294000
  },
  {
    "time": "31-08-2006",
    "open": 81.3,
    "high": 81.5,
    "low": 80.78,
    "close": 80.97,
    "volume": 3786200
  },
  {
    "time": "30-08-2006",
    "open": 81.18,
    "high": 81.68,
    "low": 80.84,
    "close": 81.22,
    "volume": 4688800
  },
  {
    "time": "29-08-2006",
    "open": 80.06,
    "high": 81.48,
    "low": 80.06,
    "close": 81.4,
    "volume": 5208500
  },
  {
    "time": "28-08-2006",
    "open": 79.91,
    "high": 80.47,
    "low": 79.76,
    "close": 80.32,
    "volume": 5259400
  },
  {
    "time": "25-08-2006",
    "open": 79.2,
    "high": 80,
    "low": 79.14,
    "close": 79.88,
    "volume": 3427400
  },
  {
    "time": "24-08-2006",
    "open": 78.9,
    "high": 79.5,
    "low": 78.87,
    "close": 79.38,
    "volume": 3473300
  },
  {
    "time": "23-08-2006",
    "open": 78.95,
    "high": 79.47,
    "low": 78.42,
    "close": 78.67,
    "volume": 3425700
  },
  {
    "time": "22-08-2006",
    "open": 79.28,
    "high": 79.55,
    "low": 78.61,
    "close": 78.95,
    "volume": 4057200
  },
  {
    "time": "21-08-2006",
    "open": 79.51,
    "high": 79.88,
    "low": 78.87,
    "close": 79.28,
    "volume": 3676500
  },
  {
    "time": "18-08-2006",
    "open": 79.38,
    "high": 79.97,
    "low": 78.63,
    "close": 79.9,
    "volume": 5399200
  },
  {
    "time": "17-08-2006",
    "open": 78.93,
    "high": 79.56,
    "low": 78.53,
    "close": 79.37,
    "volume": 5535400
  },
  {
    "time": "16-08-2006",
    "open": 77.45,
    "high": 79.28,
    "low": 77.35,
    "close": 79.09,
    "volume": 7386500
  },
  {
    "time": "15-08-2006",
    "open": 76.9,
    "high": 77.14,
    "low": 76.31,
    "close": 77.08,
    "volume": 5985400
  },
  {
    "time": "14-08-2006",
    "open": 75.85,
    "high": 77.49,
    "low": 75.6,
    "close": 76.68,
    "volume": 4997400
  },
  {
    "time": "11-08-2006",
    "open": 75.2,
    "high": 75.78,
    "low": 75.2,
    "close": 75.48,
    "volume": 3695800
  },
  {
    "time": "10-08-2006",
    "open": 75.2,
    "high": 76.08,
    "low": 75.05,
    "close": 75.74,
    "volume": 3213600
  },
  {
    "time": "09-08-2006",
    "open": 75.75,
    "high": 76.28,
    "low": 75.39,
    "close": 75.39,
    "volume": 4480400
  },
  {
    "time": "08-08-2006",
    "open": 75.63,
    "high": 76.26,
    "low": 75.1116,
    "close": 75.33,
    "volume": 3794700
  },
  {
    "time": "07-08-2006",
    "open": 75.63,
    "high": 76.2,
    "low": 75.28,
    "close": 75.52,
    "volume": 3870200
  },
  {
    "time": "04-08-2006",
    "open": 76.65,
    "high": 76.77,
    "low": 75.32,
    "close": 75.91,
    "volume": 5062400
  },
  {
    "time": "03-08-2006",
    "open": 76,
    "high": 76.73,
    "low": 74.9,
    "close": 76.33,
    "volume": 2765800
  },
  {
    "time": "02-08-2006",
    "open": 76.21,
    "high": 76.93,
    "low": 76.2,
    "close": 76.32,
    "volume": 3283800
  },
  {
    "time": "01-08-2006",
    "open": 76.86,
    "high": 77.38,
    "low": 75.75,
    "close": 76.14,
    "volume": 4406700
  },
  {
    "time": "31-07-2006",
    "open": 76.44,
    "high": 77.5,
    "low": 76.15,
    "close": 77.41,
    "volume": 5381800
  },
  {
    "time": "28-07-2006",
    "open": 76.38,
    "high": 77.3,
    "low": 76.26,
    "close": 76.96,
    "volume": 4165700
  },
  {
    "time": "27-07-2006",
    "open": 75.83,
    "high": 77.29,
    "low": 75.75,
    "close": 76.15,
    "volume": 6439100
  },
  {
    "time": "26-07-2006",
    "open": 75.48,
    "high": 76.3,
    "low": 75.36,
    "close": 75.83,
    "volume": 3870100
  },
  {
    "time": "25-07-2006",
    "open": 75.99,
    "high": 76.41,
    "low": 75.31,
    "close": 75.89,
    "volume": 5580300
  },
  {
    "time": "24-07-2006",
    "open": 74.92,
    "high": 76.15,
    "low": 74.92,
    "close": 75.99,
    "volume": 5611200
  },
  {
    "time": "21-07-2006",
    "open": 75.49,
    "high": 75.5,
    "low": 74.5,
    "close": 74.86,
    "volume": 8372500
  },
  {
    "time": "20-07-2006",
    "open": 75.73,
    "high": 75.88,
    "low": 75.2,
    "close": 75.48,
    "volume": 12214900
  },
  {
    "time": "19-07-2006",
    "open": 76,
    "high": 77.06,
    "low": 76,
    "close": 76.07,
    "volume": 14536900
  },
  {
    "time": "18-07-2006",
    "open": 73.7,
    "high": 75.42,
    "low": 72.73,
    "close": 74.26,
    "volume": 8667500
  },
  {
    "time": "17-07-2006",
    "open": 73.34,
    "high": 73.94,
    "low": 73.2,
    "close": 73.7,
    "volume": 6822700
  },
  {
    "time": "14-07-2006",
    "open": 74.24,
    "high": 74.3,
    "low": 73.37,
    "close": 73.57,
    "volume": 6957800
  },
  {
    "time": "13-07-2006",
    "open": 75.01,
    "high": 75.28,
    "low": 73.83,
    "close": 74.24,
    "volume": 8754700
  },
  {
    "time": "12-07-2006",
    "open": 76.07,
    "high": 76.3,
    "low": 74.82,
    "close": 75.48,
    "volume": 8372100
  },
  {
    "time": "11-07-2006",
    "open": 76.4,
    "high": 76.6499,
    "low": 75.44,
    "close": 76.47,
    "volume": 6086500
  },
  {
    "time": "10-07-2006",
    "open": 76.18,
    "high": 76.83,
    "low": 75.87,
    "close": 76.67,
    "volume": 7154700
  },
  {
    "time": "07-07-2006",
    "open": 78.09,
    "high": 78.09,
    "low": 76.12,
    "close": 76.42,
    "volume": 6641600
  },
  {
    "time": "06-07-2006",
    "open": 77.59,
    "high": 78.53,
    "low": 77.57,
    "close": 78.09,
    "volume": 4333100
  },
  {
    "time": "05-07-2006",
    "open": 78,
    "high": 78.39,
    "low": 77.52,
    "close": 77.77,
    "volume": 4047800
  },
  {
    "time": "03-07-2006",
    "open": 77.54,
    "high": 78.27,
    "low": 77.27,
    "close": 78.02,
    "volume": 3177300
  },
  {
    "time": "30-06-2006",
    "open": 77.7,
    "high": 77.93,
    "low": 76.79,
    "close": 76.82,
    "volume": 7911700
  },
  {
    "time": "29-06-2006",
    "open": 76.66,
    "high": 77.8,
    "low": 76.56,
    "close": 77.59,
    "volume": 5448900
  },
  {
    "time": "28-06-2006",
    "open": 76.53,
    "high": 76.65,
    "low": 76.06,
    "close": 76.56,
    "volume": 4392100
  },
  {
    "time": "27-06-2006",
    "open": 77.32,
    "high": 77.6,
    "low": 76.32,
    "close": 76.63,
    "volume": 5596800
  },
  {
    "time": "26-06-2006",
    "open": 77.04,
    "high": 77.52,
    "low": 76.8,
    "close": 77.15,
    "volume": 3448000
  },
  {
    "time": "23-06-2006",
    "open": 77.15,
    "high": 77.84,
    "low": 76.94,
    "close": 77.1,
    "volume": 4520600
  },
  {
    "time": "22-06-2006",
    "open": 78.16,
    "high": 78.37,
    "low": 77,
    "close": 77.19,
    "volume": 5563700
  },
  {
    "time": "21-06-2006",
    "open": 77.99,
    "high": 78.56,
    "low": 77.98,
    "close": 78.3,
    "volume": 5559100
  },
  {
    "time": "20-06-2006",
    "open": 77.84,
    "high": 78.3,
    "low": 77.25,
    "close": 77.99,
    "volume": 6124500
  },
  {
    "time": "19-06-2006",
    "open": 78.06,
    "high": 78.3,
    "low": 77.34,
    "close": 77.67,
    "volume": 5302100
  },
  {
    "time": "16-06-2006",
    "open": 78.26,
    "high": 78.53,
    "low": 77.57,
    "close": 77.95,
    "volume": 8133500
  },
  {
    "time": "15-06-2006",
    "open": 78.2,
    "high": 78.78,
    "low": 76.95,
    "close": 78.56,
    "volume": 6679400
  },
  {
    "time": "14-06-2006",
    "open": 77.1,
    "high": 77.85,
    "low": 76.93,
    "close": 77.71,
    "volume": 6038600
  },
  {
    "time": "13-06-2006",
    "open": 77.27,
    "high": 77.93,
    "low": 76.82,
    "close": 76.93,
    "volume": 7374100
  },
  {
    "time": "12-06-2006",
    "open": 77.68,
    "high": 78.09,
    "low": 76.85,
    "close": 77.02,
    "volume": 4924900
  },
  {
    "time": "09-06-2006",
    "open": 77.33,
    "high": 78.1,
    "low": 77.05,
    "close": 78.01,
    "volume": 6135400
  },
  {
    "time": "08-06-2006",
    "open": 78.74,
    "high": 79.58,
    "low": 76.75,
    "close": 77.03,
    "volume": 13474400
  },
  {
    "time": "07-06-2006",
    "open": 79.78,
    "high": 80.74,
    "low": 79.12,
    "close": 79.15,
    "volume": 7924800
  },
  {
    "time": "06-06-2006",
    "open": 79.06,
    "high": 79.9,
    "low": 78.5,
    "close": 79.76,
    "volume": 7250600
  },
  {
    "time": "05-06-2006",
    "open": 79.1,
    "high": 80.04,
    "low": 79.01,
    "close": 79.06,
    "volume": 5555800
  },
  {
    "time": "02-06-2006",
    "open": 80.5,
    "high": 80.56,
    "low": 79.15,
    "close": 79.52,
    "volume": 7232300
  },
  {
    "time": "01-06-2006",
    "open": 79.89,
    "high": 80.87,
    "low": 79.81,
    "close": 80.69,
    "volume": 4866500
  },
  {
    "time": "31-05-2006",
    "open": 80.15,
    "high": 80.83,
    "low": 79.61,
    "close": 79.9,
    "volume": 6384900
  },
  {
    "time": "30-05-2006",
    "open": 80.35,
    "high": 80.96,
    "low": 80.15,
    "close": 80.16,
    "volume": 5049100
  },
  {
    "time": "26-05-2006",
    "open": 80.16,
    "high": 80.86,
    "low": 79.7,
    "close": 80.75,
    "volume": 3580800
  },
  {
    "time": "25-05-2006",
    "open": 79.89,
    "high": 80.18,
    "low": 79.65,
    "close": 80.14,
    "volume": 3995000
  },
  {
    "time": "24-05-2006",
    "open": 79.4,
    "high": 80.04,
    "low": 79.06,
    "close": 79.78,
    "volume": 7389600
  },
  {
    "time": "23-05-2006",
    "open": 80.25,
    "high": 81.3,
    "low": 79,
    "close": 79.83,
    "volume": 5006400
  },
  {
    "time": "22-05-2006",
    "open": 79.94,
    "high": 80.34,
    "low": 79.4,
    "close": 80.02,
    "volume": 6250900
  },
  {
    "time": "19-05-2006",
    "open": 80.87,
    "high": 81,
    "low": 79.51,
    "close": 80.28,
    "volume": 7019100
  },
  {
    "time": "18-05-2006",
    "open": 80.5,
    "high": 81.35,
    "low": 80.03,
    "close": 80.66,
    "volume": 5443600
  },
  {
    "time": "17-05-2006",
    "open": 81.85,
    "high": 82.2117,
    "low": 81.02,
    "close": 81.27,
    "volume": 5936000
  },
  {
    "time": "16-05-2006",
    "open": 83,
    "high": 83.25,
    "low": 82.07,
    "close": 82.16,
    "volume": 4454800
  },
  {
    "time": "15-05-2006",
    "open": 81.8,
    "high": 83.15,
    "low": 81.8,
    "close": 82.89,
    "volume": 5776400
  },
  {
    "time": "12-05-2006",
    "open": 82.4,
    "high": 83.69,
    "low": 82.3,
    "close": 82.39,
    "volume": 5685700
  },
  {
    "time": "11-05-2006",
    "open": 82.65,
    "high": 83.09,
    "low": 82.45,
    "close": 82.46,
    "volume": 5274500
  },
  {
    "time": "10-05-2006",
    "open": 82.9,
    "high": 83.33,
    "low": 82.6,
    "close": 82.9,
    "volume": 3430400
  },
  {
    "time": "09-05-2006",
    "open": 82.49,
    "high": 83.29,
    "low": 82.405,
    "close": 83.23,
    "volume": 4072800
  },
  {
    "time": "08-05-2006",
    "open": 82.6,
    "high": 83.22,
    "low": 82.3,
    "close": 82.89,
    "volume": 3856500
  },
  {
    "time": "05-05-2006",
    "open": 82.95,
    "high": 83.55,
    "low": 82.7,
    "close": 82.98,
    "volume": 4504500
  },
  {
    "time": "04-05-2006",
    "open": 82.71,
    "high": 83.09,
    "low": 82.4,
    "close": 82.43,
    "volume": 3966900
  },
  {
    "time": "03-05-2006",
    "open": 82.54,
    "high": 83.29,
    "low": 82.45,
    "close": 82.7,
    "volume": 5721100
  },
  {
    "time": "02-05-2006",
    "open": 82.11,
    "high": 82.74,
    "low": 82,
    "close": 82.42,
    "volume": 4336400
  },
  {
    "time": "01-05-2006",
    "open": 82.59,
    "high": 83.31,
    "low": 82.1,
    "close": 82.23,
    "volume": 6011100
  },
  {
    "time": "28-04-2006",
    "open": 83.51,
    "high": 83.62,
    "low": 81.98,
    "close": 82.34,
    "volume": 8201800
  },
  {
    "time": "27-04-2006",
    "open": 83.01,
    "high": 84.4,
    "low": 82.7,
    "close": 83.88,
    "volume": 8174300
  },
  {
    "time": "26-04-2006",
    "open": 82.84,
    "high": 83.48,
    "low": 82.71,
    "close": 83.35,
    "volume": 6648800
  },
  {
    "time": "25-04-2006",
    "open": 82.16,
    "high": 82.9,
    "low": 81.6,
    "close": 82.67,
    "volume": 6856300
  },
  {
    "time": "24-04-2006",
    "open": 81.3,
    "high": 82.41,
    "low": 81.1,
    "close": 82.11,
    "volume": 6604100
  },
  {
    "time": "21-04-2006",
    "open": 82.49,
    "high": 82.5,
    "low": 81.38,
    "close": 81.66,
    "volume": 6117400
  },
  {
    "time": "20-04-2006",
    "open": 81.65,
    "high": 82.85,
    "low": 81.64,
    "close": 82.02,
    "volume": 6237200
  },
  {
    "time": "19-04-2006",
    "open": 83.95,
    "high": 84,
    "low": 81.26,
    "close": 81.86,
    "volume": 10554700
  },
  {
    "time": "18-04-2006",
    "open": 81.89,
    "high": 83.53,
    "low": 81.7,
    "close": 83.31,
    "volume": 7905800
  },
  {
    "time": "17-04-2006",
    "open": 81.77,
    "high": 82.92,
    "low": 81.4,
    "close": 81.64,
    "volume": 4634200
  },
  {
    "time": "13-04-2006",
    "open": 81.18,
    "high": 82.6,
    "low": 81.12,
    "close": 81.98,
    "volume": 4799700
  },
  {
    "time": "12-04-2006",
    "open": 81.1,
    "high": 81.37,
    "low": 80.63,
    "close": 80.75,
    "volume": 3905200
  },
  {
    "time": "11-04-2006",
    "open": 81.83,
    "high": 82.06,
    "low": 80.75,
    "close": 81.16,
    "volume": 5959700
  },
  {
    "time": "10-04-2006",
    "open": 82.49,
    "high": 82.74,
    "low": 82,
    "close": 82.1,
    "volume": 3627700
  },
  {
    "time": "07-04-2006",
    "open": 83.6,
    "high": 83.8,
    "low": 82.44,
    "close": 82.48,
    "volume": 5337500
  },
  {
    "time": "06-04-2006",
    "open": 84.1,
    "high": 84.32,
    "low": 83.36,
    "close": 83.81,
    "volume": 4944100
  },
  {
    "time": "05-04-2006",
    "open": 83.33,
    "high": 84.45,
    "low": 83.32,
    "close": 84.17,
    "volume": 5523900
  },
  {
    "time": "04-04-2006",
    "open": 83.1,
    "high": 83.78,
    "low": 82.8,
    "close": 83.45,
    "volume": 3853000
  },
  {
    "time": "03-04-2006",
    "open": 82.72,
    "high": 83.59,
    "low": 82.65,
    "close": 83.06,
    "volume": 4121600
  },
  {
    "time": "31-03-2006",
    "open": 82.95,
    "high": 83.6,
    "low": 82.29,
    "close": 82.47,
    "volume": 5862000
  },
  {
    "time": "30-03-2006",
    "open": 82.88,
    "high": 83.7,
    "low": 82.83,
    "close": 83.2,
    "volume": 4467000
  },
  {
    "time": "29-03-2006",
    "open": 82.2,
    "high": 83.55,
    "low": 82.2,
    "close": 83.13,
    "volume": 4392100
  },
  {
    "time": "28-03-2006",
    "open": 82.86,
    "high": 83.39,
    "low": 82.29,
    "close": 82.43,
    "volume": 4508600
  },
  {
    "time": "27-03-2006",
    "open": 82.98,
    "high": 83.26,
    "low": 82.58,
    "close": 83.08,
    "volume": 3910100
  },
  {
    "time": "24-03-2006",
    "open": 83.1,
    "high": 83.72,
    "low": 82.99,
    "close": 83.36,
    "volume": 4198800
  },
  {
    "time": "23-03-2006",
    "open": 84.23,
    "high": 84.27,
    "low": 82.93,
    "close": 83.2,
    "volume": 5105800
  },
  {
    "time": "22-03-2006",
    "open": 83.66,
    "high": 84.68,
    "low": 83.35,
    "close": 84.45,
    "volume": 4968600
  },
  {
    "time": "21-03-2006",
    "open": 83.82,
    "high": 84.99,
    "low": 83.58,
    "close": 83.81,
    "volume": 7404300
  },
  {
    "time": "20-03-2006",
    "open": 83.11,
    "high": 83.75,
    "low": 82.99,
    "close": 83.58,
    "volume": 3870300
  },
  {
    "time": "17-03-2006",
    "open": 83.28,
    "high": 83.41,
    "low": 82.82,
    "close": 83.3,
    "volume": 6414400
  },
  {
    "time": "16-03-2006",
    "open": 83.43,
    "high": 83.72,
    "low": 82.61,
    "close": 82.87,
    "volume": 4299500
  },
  {
    "time": "15-03-2006",
    "open": 82.65,
    "high": 83.76,
    "low": 82.64,
    "close": 83.38,
    "volume": 5370400
  },
  {
    "time": "14-03-2006",
    "open": 81.51,
    "high": 82.99,
    "low": 81.47,
    "close": 82.88,
    "volume": 4518000
  },
  {
    "time": "13-03-2006",
    "open": 81.56,
    "high": 82.4,
    "low": 81.45,
    "close": 81.93,
    "volume": 4571400
  },
  {
    "time": "10-03-2006",
    "open": 81.03,
    "high": 82.25,
    "low": 81.03,
    "close": 81.57,
    "volume": 4787200
  },
  {
    "time": "09-03-2006",
    "open": 81.15,
    "high": 81.98,
    "low": 80.8,
    "close": 81.02,
    "volume": 4310700
  },
  {
    "time": "08-03-2006",
    "open": 80.39,
    "high": 81.6,
    "low": 80.25,
    "close": 81.14,
    "volume": 5569900
  },
  {
    "time": "07-03-2006",
    "open": 79.86,
    "high": 80.79,
    "low": 79.86,
    "close": 80.29,
    "volume": 4330800
  },
  {
    "time": "06-03-2006",
    "open": 80.05,
    "high": 80.64,
    "low": 79.9,
    "close": 80,
    "volume": 4709500
  },
  {
    "time": "03-03-2006",
    "open": 79.51,
    "high": 80.83,
    "low": 79.51,
    "close": 79.96,
    "volume": 4354400
  },
  {
    "time": "02-03-2006",
    "open": 79.9,
    "high": 80.21,
    "low": 79.54,
    "close": 79.94,
    "volume": 4418800
  },
  {
    "time": "01-03-2006",
    "open": 80.01,
    "high": 80.81,
    "low": 79.78,
    "close": 79.9,
    "volume": 5172300
  },
  {
    "time": "28-02-2006",
    "open": 80.1,
    "high": 80.55,
    "low": 79.71,
    "close": 80.24,
    "volume": 5960700
  },
  {
    "time": "27-02-2006",
    "open": 80,
    "high": 80.89,
    "low": 79.95,
    "close": 80.63,
    "volume": 3787000
  },
  {
    "time": "24-02-2006",
    "open": 80.65,
    "high": 80.65,
    "low": 79.85,
    "close": 80.1,
    "volume": 3903200
  },
  {
    "time": "23-02-2006",
    "open": 81.05,
    "high": 81.34,
    "low": 80.04,
    "close": 80.2,
    "volume": 4979400
  },
  {
    "time": "22-02-2006",
    "open": 80,
    "high": 81.65,
    "low": 79.97,
    "close": 81.35,
    "volume": 5294300
  },
  {
    "time": "21-02-2006",
    "open": 80.1,
    "high": 80.75,
    "low": 79.97,
    "close": 80.5,
    "volume": 5838000
  },
  {
    "time": "17-02-2006",
    "open": 80.91,
    "high": 81.03,
    "low": 80.13,
    "close": 80.71,
    "volume": 5298800
  },
  {
    "time": "16-02-2006",
    "open": 80.68,
    "high": 80.91,
    "low": 80.13,
    "close": 80.91,
    "volume": 5310500
  },
  {
    "time": "15-02-2006",
    "open": 80.86,
    "high": 81.5,
    "low": 80.5,
    "close": 80.85,
    "volume": 5052000
  },
  {
    "time": "14-02-2006",
    "open": 80.55,
    "high": 81.45,
    "low": 80.35,
    "close": 81.09,
    "volume": 6075500
  },
  {
    "time": "13-02-2006",
    "open": 81.33,
    "high": 81.91,
    "low": 80.18,
    "close": 80.44,
    "volume": 5257400
  },
  {
    "time": "10-02-2006",
    "open": 80.4,
    "high": 81.46,
    "low": 80.2,
    "close": 81.33,
    "volume": 5371900
  },
  {
    "time": "09-02-2006",
    "open": 80.2,
    "high": 80.8,
    "low": 79.5,
    "close": 80.4,
    "volume": 7609200
  },
  {
    "time": "08-02-2006",
    "open": 80.6,
    "high": 80.9,
    "low": 80.24,
    "close": 80.8,
    "volume": 5029200
  },
  {
    "time": "07-02-2006",
    "open": 79.5,
    "high": 80.19,
    "low": 79.3,
    "close": 79.65,
    "volume": 5247300
  },
  {
    "time": "06-02-2006",
    "open": 79.94,
    "high": 79.95,
    "low": 78.93,
    "close": 79.51,
    "volume": 9568500
  },
  {
    "time": "03-02-2006",
    "open": 80.55,
    "high": 80.77,
    "low": 79.72,
    "close": 79.97,
    "volume": 9852000
  },
  {
    "time": "02-02-2006",
    "open": 81.45,
    "high": 81.59,
    "low": 80.9,
    "close": 81.23,
    "volume": 6198000
  },
  {
    "time": "01-02-2006",
    "open": 80.9,
    "high": 82.24,
    "low": 80.65,
    "close": 81.94,
    "volume": 5419300
  },
  {
    "time": "31-01-2006",
    "open": 81.5,
    "high": 82,
    "low": 81.17,
    "close": 81.3,
    "volume": 6771600
  },
  {
    "time": "30-01-2006",
    "open": 81.02,
    "high": 81.81,
    "low": 80.21,
    "close": 81.63,
    "volume": 5325100
  },
  {
    "time": "27-01-2006",
    "open": 80.75,
    "high": 81.77,
    "low": 80.75,
    "close": 81.02,
    "volume": 6103400
  },
  {
    "time": "26-01-2006",
    "open": 81.5,
    "high": 81.65,
    "low": 80.59,
    "close": 80.72,
    "volume": 7810200
  },
  {
    "time": "25-01-2006",
    "open": 81.05,
    "high": 81.62,
    "low": 80.61,
    "close": 80.91,
    "volume": 6374300
  },
  {
    "time": "24-01-2006",
    "open": 81.39,
    "high": 82.15,
    "low": 80.8,
    "close": 80.85,
    "volume": 6069000
  },
  {
    "time": "23-01-2006",
    "open": 81.33,
    "high": 81.92,
    "low": 80.92,
    "close": 81.41,
    "volume": 6114100
  },
  {
    "time": "20-01-2006",
    "open": 83.04,
    "high": 83.05,
    "low": 81.25,
    "close": 81.36,
    "volume": 8605800
  },
  {
    "time": "19-01-2006",
    "open": 84.14,
    "high": 84.39,
    "low": 83.02,
    "close": 83.09,
    "volume": 6483500
  },
  {
    "time": "18-01-2006",
    "open": 84,
    "high": 84.7,
    "low": 83.52,
    "close": 84.46,
    "volume": 10984800
  },
  {
    "time": "17-01-2006",
    "open": 82.8,
    "high": 83.16,
    "low": 82.54,
    "close": 83,
    "volume": 8718400
  },
  {
    "time": "13-01-2006",
    "open": 83,
    "high": 83.45,
    "low": 82.5,
    "close": 83.17,
    "volume": 6921600
  },
  {
    "time": "12-01-2006",
    "open": 83.82,
    "high": 83.96,
    "low": 83.4,
    "close": 83.57,
    "volume": 4924100
  },
  {
    "time": "11-01-2006",
    "open": 84.37,
    "high": 84.81,
    "low": 83.4,
    "close": 84.17,
    "volume": 5776400
  },
  {
    "time": "10-01-2006",
    "open": 83.15,
    "high": 84.12,
    "low": 83.12,
    "close": 84.07,
    "volume": 5700000
  },
  {
    "time": "09-01-2006",
    "open": 83.9,
    "high": 84.25,
    "low": 83.38,
    "close": 83.73,
    "volume": 6851100
  },
  {
    "time": "06-01-2006",
    "open": 83.95,
    "high": 85.03,
    "low": 83.41,
    "close": 84.95,
    "volume": 8196900
  },
  {
    "time": "05-01-2006",
    "open": 81.4,
    "high": 82.9,
    "low": 80.999,
    "close": 82.5,
    "volume": 7213400
  },
  {
    "time": "04-01-2006",
    "open": 82.2,
    "high": 82.5,
    "low": 81.33,
    "close": 81.95,
    "volume": 9832800
  },
  {
    "time": "03-01-2006",
    "open": 82.45,
    "high": 82.55,
    "low": 80.81,
    "close": 82.06,
    "volume": 11715100
  },
  {
    "time": "30-12-2005",
    "open": 81.85,
    "high": 82.49,
    "low": 81.56,
    "close": 82.2,
    "volume": 5449000
  },
  {
    "time": "29-12-2005",
    "open": 82.8,
    "high": 83.06,
    "low": 82.22,
    "close": 82.4,
    "volume": 3958400
  },
  {
    "time": "28-12-2005",
    "open": 83.1,
    "high": 83.57,
    "low": 82.8,
    "close": 83.04,
    "volume": 3836800
  },
  {
    "time": "27-12-2005",
    "open": 83.48,
    "high": 84.5,
    "low": 82.89,
    "close": 82.99,
    "volume": 4377800
  },
  {
    "time": "23-12-2005",
    "open": 84,
    "high": 84.2,
    "low": 83.39,
    "close": 83.48,
    "volume": 3616200
  },
  {
    "time": "22-12-2005",
    "open": 82.61,
    "high": 83.23,
    "low": 82.3,
    "close": 83.22,
    "volume": 6573600
  },
  {
    "time": "21-12-2005",
    "open": 82.46,
    "high": 84,
    "low": 82.46,
    "close": 83.12,
    "volume": 6846800
  },
  {
    "time": "20-12-2005",
    "open": 82.95,
    "high": 83.1,
    "low": 82.06,
    "close": 82.48,
    "volume": 5276200
  },
  {
    "time": "19-12-2005",
    "open": 83.23,
    "high": 83.6,
    "low": 82.65,
    "close": 82.76,
    "volume": 4985400
  },
  {
    "time": "16-12-2005",
    "open": 83.89,
    "high": 84,
    "low": 83,
    "close": 83.37,
    "volume": 7398200
  },
  {
    "time": "15-12-2005",
    "open": 82.88,
    "high": 83.63,
    "low": 82.75,
    "close": 83.53,
    "volume": 6155900
  },
  {
    "time": "14-12-2005",
    "open": 83.65,
    "high": 83.95,
    "low": 82.95,
    "close": 83.13,
    "volume": 7161800
  },
  {
    "time": "13-12-2005",
    "open": 84.5,
    "high": 84.9,
    "low": 83.5,
    "close": 83.71,
    "volume": 12545300
  },
  {
    "time": "12-12-2005",
    "open": 87.01,
    "high": 87.35,
    "low": 85.76,
    "close": 85.96,
    "volume": 6044100
  },
  {
    "time": "09-12-2005",
    "open": 86.8,
    "high": 87.1,
    "low": 86.33,
    "close": 86.97,
    "volume": 6461800
  },
  {
    "time": "08-12-2005",
    "open": 88.57,
    "high": 88.91,
    "low": 86.67,
    "close": 87.5,
    "volume": 5787000
  },
  {
    "time": "07-12-2005",
    "open": 89,
    "high": 89.92,
    "low": 88.45,
    "close": 88.72,
    "volume": 5284000
  },
  {
    "time": "06-12-2005",
    "open": 88.56,
    "high": 89.84,
    "low": 88.56,
    "close": 89.14,
    "volume": 4871500
  },
  {
    "time": "05-12-2005",
    "open": 88.4,
    "high": 88.65,
    "low": 87.71,
    "close": 88.43,
    "volume": 4023600
  },
  {
    "time": "02-12-2005",
    "open": 89,
    "high": 89.15,
    "low": 88.36,
    "close": 88.65,
    "volume": 4730500
  },
  {
    "time": "01-12-2005",
    "open": 89.15,
    "high": 89.74,
    "low": 89.05,
    "close": 89.21,
    "volume": 4668500
  },
  {
    "time": "30-11-2005",
    "open": 89.05,
    "high": 89.64,
    "low": 88.73,
    "close": 88.9,
    "volume": 6021500
  },
  {
    "time": "29-11-2005",
    "open": 89.18,
    "high": 89.94,
    "low": 89.02,
    "close": 89.1,
    "volume": 5147000
  },
  {
    "time": "28-11-2005",
    "open": 88.8,
    "high": 89.43,
    "low": 88.75,
    "close": 89.11,
    "volume": 5197900
  },
  {
    "time": "25-11-2005",
    "open": 88.95,
    "high": 89.32,
    "low": 88.62,
    "close": 88.8,
    "volume": 2199300
  },
  {
    "time": "23-11-2005",
    "open": 88.12,
    "high": 89.39,
    "low": 87.95,
    "close": 88.8,
    "volume": 5399300
  },
  {
    "time": "22-11-2005",
    "open": 86.88,
    "high": 88.08,
    "low": 86.88,
    "close": 87.99,
    "volume": 5786600
  },
  {
    "time": "21-11-2005",
    "open": 87.6,
    "high": 87.87,
    "low": 87.01,
    "close": 87.29,
    "volume": 4807900
  },
  {
    "time": "18-11-2005",
    "open": 88,
    "high": 88,
    "low": 87.17,
    "close": 87.77,
    "volume": 7985800
  },
  {
    "time": "17-11-2005",
    "open": 86.18,
    "high": 86.98,
    "low": 85.76,
    "close": 86.89,
    "volume": 5293600
  },
  {
    "time": "16-11-2005",
    "open": 85.45,
    "high": 86.58,
    "low": 85.39,
    "close": 86.54,
    "volume": 6694100
  },
  {
    "time": "15-11-2005",
    "open": 84.03,
    "high": 85.9,
    "low": 84.03,
    "close": 85.53,
    "volume": 6830800
  },
  {
    "time": "14-11-2005",
    "open": 84.25,
    "high": 85,
    "low": 84.11,
    "close": 84.36,
    "volume": 3915300
  },
  {
    "time": "11-11-2005",
    "open": 84.2,
    "high": 84.84,
    "low": 84.15,
    "close": 84.55,
    "volume": 4841300
  },
  {
    "time": "10-11-2005",
    "open": 82.7,
    "high": 84.3,
    "low": 82.3,
    "close": 83.99,
    "volume": 4732000
  },
  {
    "time": "09-11-2005",
    "open": 82.71,
    "high": 83.33,
    "low": 82.49,
    "close": 82.84,
    "volume": 3936200
  },
  {
    "time": "08-11-2005",
    "open": 83.41,
    "high": 83.9,
    "low": 83.11,
    "close": 83.15,
    "volume": 3798000
  },
  {
    "time": "07-11-2005",
    "open": 83.1,
    "high": 83.85,
    "low": 83.07,
    "close": 83.61,
    "volume": 4455100
  },
  {
    "time": "04-11-2005",
    "open": 82.91,
    "high": 83.3,
    "low": 82.6454,
    "close": 83,
    "volume": 3831400
  },
  {
    "time": "03-11-2005",
    "open": 81.55,
    "high": 83.03,
    "low": 81.31,
    "close": 82.87,
    "volume": 7690900
  },
  {
    "time": "02-11-2005",
    "open": 81.2,
    "high": 81.89,
    "low": 80.64,
    "close": 81.06,
    "volume": 6447400
  },
  {
    "time": "01-11-2005",
    "open": 81.85,
    "high": 82.15,
    "low": 81.29,
    "close": 81.59,
    "volume": 5653100
  },
  {
    "time": "31-10-2005",
    "open": 81.51,
    "high": 82.3,
    "low": 81.3,
    "close": 81.88,
    "volume": 7531200
  },
  {
    "time": "28-10-2005",
    "open": 82.32,
    "high": 82.43,
    "low": 80.5,
    "close": 81.42,
    "volume": 6941300
  },
  {
    "time": "27-10-2005",
    "open": 82.53,
    "high": 82.93,
    "low": 82.25,
    "close": 82.31,
    "volume": 3705800
  },
  {
    "time": "26-10-2005",
    "open": 82.97,
    "high": 83.87,
    "low": 82.72,
    "close": 82.89,
    "volume": 4270800
  },
  {
    "time": "25-10-2005",
    "open": 83.08,
    "high": 83.95,
    "low": 82.71,
    "close": 83.36,
    "volume": 5393300
  },
  {
    "time": "24-10-2005",
    "open": 83.15,
    "high": 83.61,
    "low": 82.6,
    "close": 83.47,
    "volume": 5482200
  },
  {
    "time": "21-10-2005",
    "open": 83.08,
    "high": 84,
    "low": 82.91,
    "close": 83.33,
    "volume": 6020700
  },
  {
    "time": "20-10-2005",
    "open": 84,
    "high": 84.19,
    "low": 82.88,
    "close": 83.17,
    "volume": 5752700
  },
  {
    "time": "19-10-2005",
    "open": 82.41,
    "high": 84.2,
    "low": 82.38,
    "close": 84.17,
    "volume": 7502600
  },
  {
    "time": "18-10-2005",
    "open": 84.33,
    "high": 84.6,
    "low": 83.35,
    "close": 83.48,
    "volume": 12045300
  },
  {
    "time": "17-10-2005",
    "open": 82.36,
    "high": 82.99,
    "low": 81.93,
    "close": 82.59,
    "volume": 7964800
  },
  {
    "time": "14-10-2005",
    "open": 82.6,
    "high": 82.71,
    "low": 81.6,
    "close": 82.35,
    "volume": 5573700
  },
  {
    "time": "13-10-2005",
    "open": 81.7,
    "high": 83.2,
    "low": 81.7,
    "close": 82.2,
    "volume": 5530600
  },
  {
    "time": "12-10-2005",
    "open": 82.75,
    "high": 82.93,
    "low": 81.95,
    "close": 82.19,
    "volume": 7580800
  },
  {
    "time": "11-10-2005",
    "open": 82.25,
    "high": 83.52,
    "low": 81.97,
    "close": 83.19,
    "volume": 9781300
  },
  {
    "time": "10-10-2005",
    "open": 81.24,
    "high": 82.1,
    "low": 81.08,
    "close": 81.25,
    "volume": 7882900
  },
  {
    "time": "07-10-2005",
    "open": 80.25,
    "high": 81.12,
    "low": 80.09,
    "close": 80.5,
    "volume": 6130100
  },
  {
    "time": "06-10-2005",
    "open": 79.82,
    "high": 80.27,
    "low": 78.7,
    "close": 79.7,
    "volume": 8130200
  },
  {
    "time": "05-10-2005",
    "open": 80.11,
    "high": 80.68,
    "low": 79.56,
    "close": 79.82,
    "volume": 4998800
  },
  {
    "time": "04-10-2005",
    "open": 80.65,
    "high": 81.47,
    "low": 79.93,
    "close": 80.11,
    "volume": 6365100
  },
  {
    "time": "03-10-2005",
    "open": 80.22,
    "high": 80.6,
    "low": 79.5,
    "close": 80.45,
    "volume": 5297600
  },
  {
    "time": "30-09-2005",
    "open": 80.02,
    "high": 80.5,
    "low": 79.97,
    "close": 80.22,
    "volume": 5536500
  },
  {
    "time": "29-09-2005",
    "open": 79.25,
    "high": 80.55,
    "low": 78.87,
    "close": 80.36,
    "volume": 5899700
  },
  {
    "time": "28-09-2005",
    "open": 78.06,
    "high": 79.73,
    "low": 78.06,
    "close": 79.5,
    "volume": 7747700
  },
  {
    "time": "27-09-2005",
    "open": 77.17,
    "high": 78.6,
    "low": 77.16,
    "close": 77.99,
    "volume": 5499600
  },
  {
    "time": "26-09-2005",
    "open": 78.31,
    "high": 78.41,
    "low": 76.93,
    "close": 77.41,
    "volume": 5433200
  },
  {
    "time": "23-09-2005",
    "open": 77.7,
    "high": 78.11,
    "low": 77.5,
    "close": 78,
    "volume": 5434600
  },
  {
    "time": "22-09-2005",
    "open": 77.56,
    "high": 78.5,
    "low": 77.3,
    "close": 78.21,
    "volume": 6870400
  },
  {
    "time": "21-09-2005",
    "open": 78,
    "high": 78.46,
    "low": 77.37,
    "close": 77.56,
    "volume": 7126000
  },
  {
    "time": "20-09-2005",
    "open": 79.27,
    "high": 79.65,
    "low": 78.63,
    "close": 78.7,
    "volume": 7122300
  },
  {
    "time": "19-09-2005",
    "open": 79.74,
    "high": 79.98,
    "low": 79.01,
    "close": 79.43,
    "volume": 5148500
  },
  {
    "time": "16-09-2005",
    "open": 80.38,
    "high": 80.5,
    "low": 79.83,
    "close": 80.33,
    "volume": 7624900
  },
  {
    "time": "15-09-2005",
    "open": 80.43,
    "high": 80.65,
    "low": 79.67,
    "close": 80.01,
    "volume": 4864100
  },
  {
    "time": "14-09-2005",
    "open": 80.75,
    "high": 81.4,
    "low": 80.42,
    "close": 80.48,
    "volume": 3887700
  },
  {
    "time": "13-09-2005",
    "open": 81.49,
    "high": 81.49,
    "low": 80.52,
    "close": 80.75,
    "volume": 5040900
  },
  {
    "time": "12-09-2005",
    "open": 81.03,
    "high": 82.11,
    "low": 81,
    "close": 81.48,
    "volume": 3692800
  },
  {
    "time": "09-09-2005",
    "open": 80.86,
    "high": 81.49,
    "low": 80.52,
    "close": 81.44,
    "volume": 3982200
  },
  {
    "time": "08-09-2005",
    "open": 81.28,
    "high": 81.52,
    "low": 80.5,
    "close": 80.8,
    "volume": 4277300
  },
  {
    "time": "07-09-2005",
    "open": 80.7,
    "high": 81.13,
    "low": 80.32,
    "close": 80.98,
    "volume": 4116300
  },
  {
    "time": "06-09-2005",
    "open": 79.7,
    "high": 81.19,
    "low": 79.7,
    "close": 81.02,
    "volume": 6305500
  },
  {
    "time": "02-09-2005",
    "open": 79.5,
    "high": 80.01,
    "low": 79.46,
    "close": 79.46,
    "volume": 3680600
  },
  {
    "time": "01-09-2005",
    "open": 80.16,
    "high": 80.32,
    "low": 79.34,
    "close": 79.54,
    "volume": 6390600
  },
  {
    "time": "31-08-2005",
    "open": 80.35,
    "high": 80.79,
    "low": 79.87,
    "close": 80.62,
    "volume": 5400800
  },
  {
    "time": "30-08-2005",
    "open": 81,
    "high": 81,
    "low": 79.98,
    "close": 80.54,
    "volume": 5674200
  },
  {
    "time": "29-08-2005",
    "open": 80,
    "high": 81.75,
    "low": 79.9,
    "close": 81.34,
    "volume": 4025300
  },
  {
    "time": "26-08-2005",
    "open": 80.81,
    "high": 81.02,
    "low": 80.26,
    "close": 80.38,
    "volume": 5029300
  },
  {
    "time": "25-08-2005",
    "open": 81.45,
    "high": 81.45,
    "low": 80.72,
    "close": 81.1,
    "volume": 3606100
  },
  {
    "time": "24-08-2005",
    "open": 81.53,
    "high": 82.4,
    "low": 81.23,
    "close": 81.32,
    "volume": 4417600
  },
  {
    "time": "23-08-2005",
    "open": 82.7,
    "high": 83.14,
    "low": 81.6,
    "close": 82.03,
    "volume": 4000300
  },
  {
    "time": "22-08-2005",
    "open": 82.75,
    "high": 83.35,
    "low": 81.85,
    "close": 82.6,
    "volume": 5157200
  },
  {
    "time": "19-08-2005",
    "open": 81.36,
    "high": 83.3,
    "low": 81.16,
    "close": 82.76,
    "volume": 6481000
  },
  {
    "time": "18-08-2005",
    "open": 81.1,
    "high": 82,
    "low": 80.8,
    "close": 81.15,
    "volume": 3713300
  },
  {
    "time": "17-08-2005",
    "open": 81.4,
    "high": 81.94,
    "low": 80.84,
    "close": 81.3,
    "volume": 4853000
  },
  {
    "time": "16-08-2005",
    "open": 82.07,
    "high": 82.43,
    "low": 81.09,
    "close": 81.3,
    "volume": 4407400
  },
  {
    "time": "15-08-2005",
    "open": 81.9,
    "high": 82.94,
    "low": 81.61,
    "close": 82.5,
    "volume": 3431100
  },
  {
    "time": "12-08-2005",
    "open": 82.15,
    "high": 82.58,
    "low": 82.0572,
    "close": 82.19,
    "volume": 4378200
  },
  {
    "time": "11-08-2005",
    "open": 81.93,
    "high": 82.75,
    "low": 81.62,
    "close": 82.66,
    "volume": 4570400
  },
  {
    "time": "10-08-2005",
    "open": 83.65,
    "high": 84.01,
    "low": 81.97,
    "close": 82.02,
    "volume": 5402400
  },
  {
    "time": "09-08-2005",
    "open": 83.4,
    "high": 83.95,
    "low": 82.94,
    "close": 83.5,
    "volume": 3793500
  },
  {
    "time": "08-08-2005",
    "open": 83.41,
    "high": 83.89,
    "low": 83.02,
    "close": 83.36,
    "volume": 4424300
  },
  {
    "time": "05-08-2005",
    "open": 82.75,
    "high": 83.74,
    "low": 82.73,
    "close": 83.36,
    "volume": 3714600
  },
  {
    "time": "04-08-2005",
    "open": 83.75,
    "high": 83.89,
    "low": 82.98,
    "close": 83.12,
    "volume": 5249900
  },
  {
    "time": "03-08-2005",
    "open": 83.31,
    "high": 84.2,
    "low": 83.11,
    "close": 84.06,
    "volume": 4256200
  },
  {
    "time": "02-08-2005",
    "open": 83.27,
    "high": 83.86,
    "low": 83.04,
    "close": 83.31,
    "volume": 5380700
  },
  {
    "time": "01-08-2005",
    "open": 83,
    "high": 83.85,
    "low": 82.85,
    "close": 83.43,
    "volume": 3664300
  },
  {
    "time": "29-07-2005",
    "open": 83.3,
    "high": 83.95,
    "low": 83.22,
    "close": 83.46,
    "volume": 4330300
  },
  {
    "time": "28-07-2005",
    "open": 83.55,
    "high": 83.97,
    "low": 83.27,
    "close": 83.8,
    "volume": 6189700
  },
  {
    "time": "27-07-2005",
    "open": 83.8,
    "high": 84.13,
    "low": 82.81,
    "close": 83.87,
    "volume": 4862200
  },
  {
    "time": "26-07-2005",
    "open": 84.15,
    "high": 84.35,
    "low": 83.85,
    "close": 84.12,
    "volume": 4542900
  },
  {
    "time": "25-07-2005",
    "open": 83.98,
    "high": 84.52,
    "low": 83.92,
    "close": 84.2,
    "volume": 4457800
  },
  {
    "time": "22-07-2005",
    "open": 83.98,
    "high": 84.63,
    "low": 83.86,
    "close": 84.44,
    "volume": 4570400
  },
  {
    "time": "21-07-2005",
    "open": 84.6,
    "high": 84.95,
    "low": 83.4,
    "close": 84.4,
    "volume": 8265300
  },
  {
    "time": "20-07-2005",
    "open": 83.2,
    "high": 84.96,
    "low": 82.99,
    "close": 84.6,
    "volume": 9323200
  },
  {
    "time": "19-07-2005",
    "open": 84.72,
    "high": 85.11,
    "low": 83.7,
    "close": 83.7,
    "volume": 14149700
  },
  {
    "time": "18-07-2005",
    "open": 81.99,
    "high": 83.94,
    "low": 81.68,
    "close": 81.81,
    "volume": 8705600
  },
  {
    "time": "15-07-2005",
    "open": 82.43,
    "high": 82.75,
    "low": 81.52,
    "close": 82.38,
    "volume": 7644100
  },
  {
    "time": "14-07-2005",
    "open": 82,
    "high": 82.67,
    "low": 82,
    "close": 82.42,
    "volume": 8546200
  },
  {
    "time": "13-07-2005",
    "open": 81.16,
    "high": 81.75,
    "low": 81.16,
    "close": 81.45,
    "volume": 11340000
  },
  {
    "time": "12-07-2005",
    "open": 79.2,
    "high": 80.49,
    "low": 79.18,
    "close": 80.04,
    "volume": 10945500
  },
  {
    "time": "11-07-2005",
    "open": 79.4,
    "high": 79.52,
    "low": 78.52,
    "close": 78.96,
    "volume": 7186700
  },
  {
    "time": "08-07-2005",
    "open": 77.38,
    "high": 79.52,
    "low": 77.14,
    "close": 79.3,
    "volume": 13440500
  },
  {
    "time": "07-07-2005",
    "open": 75.15,
    "high": 77.53,
    "low": 75,
    "close": 77.38,
    "volume": 10757200
  },
  {
    "time": "06-07-2005",
    "open": 74.8,
    "high": 76.15,
    "low": 74.4,
    "close": 75.81,
    "volume": 8009300
  },
  {
    "time": "05-07-2005",
    "open": 74.38,
    "high": 74.97,
    "low": 74.16,
    "close": 74.79,
    "volume": 5181800
  },
  {
    "time": "01-07-2005",
    "open": 74.3,
    "high": 75.33,
    "low": 74.3,
    "close": 74.67,
    "volume": 4353100
  },
  {
    "time": "30-06-2005",
    "open": 74.8,
    "high": 75.49,
    "low": 74.07,
    "close": 74.2,
    "volume": 6127000
  },
  {
    "time": "29-06-2005",
    "open": 75.26,
    "high": 75.68,
    "low": 74.62,
    "close": 74.73,
    "volume": 5519100
  },
  {
    "time": "28-06-2005",
    "open": 73.83,
    "high": 75.301,
    "low": 73.45,
    "close": 75.3,
    "volume": 8188800
  },
  {
    "time": "27-06-2005",
    "open": 74.01,
    "high": 74.77,
    "low": 73.5,
    "close": 73.88,
    "volume": 6489800
  },
  {
    "time": "24-06-2005",
    "open": 75.17,
    "high": 75.4,
    "low": 74,
    "close": 74.01,
    "volume": 10792100
  },
  {
    "time": "23-06-2005",
    "open": 76.81,
    "high": 76.97,
    "low": 75.06,
    "close": 75.41,
    "volume": 7766700
  },
  {
    "time": "22-06-2005",
    "open": 76.83,
    "high": 77.49,
    "low": 76.67,
    "close": 77.23,
    "volume": 5646300
  },
  {
    "time": "21-06-2005",
    "open": 76.7,
    "high": 77,
    "low": 76.11,
    "close": 76.41,
    "volume": 5339100
  },
  {
    "time": "20-06-2005",
    "open": 76.03,
    "high": 76.98,
    "low": 75.57,
    "close": 76.55,
    "volume": 3745600
  },
  {
    "time": "17-06-2005",
    "open": 77.7,
    "high": 77.73,
    "low": 76.38,
    "close": 76.39,
    "volume": 8593800
  },
  {
    "time": "16-06-2005",
    "open": 76.4,
    "high": 77.25,
    "low": 76.31,
    "close": 77.05,
    "volume": 7840000
  },
  {
    "time": "15-06-2005",
    "open": 75.7,
    "high": 76.5,
    "low": 75.15,
    "close": 76.3,
    "volume": 7103600
  },
  {
    "time": "14-06-2005",
    "open": 75.05,
    "high": 75.43,
    "low": 74.73,
    "close": 74.89,
    "volume": 4314900
  },
  {
    "time": "13-06-2005",
    "open": 74.5,
    "high": 75.93,
    "low": 74.45,
    "close": 75.05,
    "volume": 5715700
  },
  {
    "time": "10-06-2005",
    "open": 74.25,
    "high": 75.05,
    "low": 74.1,
    "close": 74.77,
    "volume": 4895800
  },
  {
    "time": "09-06-2005",
    "open": 74.58,
    "high": 75.47,
    "low": 74.23,
    "close": 74.93,
    "volume": 4423200
  },
  {
    "time": "08-06-2005",
    "open": 75.15,
    "high": 75.4,
    "low": 74.63,
    "close": 74.8,
    "volume": 4280000
  },
  {
    "time": "07-06-2005",
    "open": 75,
    "high": 76.09,
    "low": 75,
    "close": 75.04,
    "volume": 5226600
  },
  {
    "time": "06-06-2005",
    "open": 75.8,
    "high": 75.9,
    "low": 74.92,
    "close": 75,
    "volume": 5978600
  },
  {
    "time": "03-06-2005",
    "open": 77.06,
    "high": 77.1,
    "low": 75.74,
    "close": 75.79,
    "volume": 6149900
  },
  {
    "time": "02-06-2005",
    "open": 76.75,
    "high": 77.39,
    "low": 76.68,
    "close": 77.35,
    "volume": 4025600
  },
  {
    "time": "01-06-2005",
    "open": 75.57,
    "high": 77.5,
    "low": 75.57,
    "close": 76.84,
    "volume": 7380600
  },
  {
    "time": "31-05-2005",
    "open": 76.6,
    "high": 77.41,
    "low": 75.5,
    "close": 75.55,
    "volume": 6419000
  },
  {
    "time": "27-05-2005",
    "open": 77.11,
    "high": 77.24,
    "low": 76.53,
    "close": 77.1,
    "volume": 3163900
  },
  {
    "time": "26-05-2005",
    "open": 76.45,
    "high": 77.41,
    "low": 76.29,
    "close": 77.14,
    "volume": 5832000
  },
  {
    "time": "25-05-2005",
    "open": 75.42,
    "high": 76.03,
    "low": 75.17,
    "close": 76,
    "volume": 5483400
  },
  {
    "time": "24-05-2005",
    "open": 76.14,
    "high": 76.51,
    "low": 75.56,
    "close": 75.81,
    "volume": 5660700
  },
  {
    "time": "23-05-2005",
    "open": 76.3,
    "high": 76.95,
    "low": 76,
    "close": 76.51,
    "volume": 4759800
  },
  {
    "time": "20-05-2005",
    "open": 77.28,
    "high": 77.28,
    "low": 76.36,
    "close": 76.41,
    "volume": 6329800
  },
  {
    "time": "19-05-2005",
    "open": 76.5,
    "high": 77.64,
    "low": 76.37,
    "close": 77.16,
    "volume": 7030400
  },
  {
    "time": "18-05-2005",
    "open": 75,
    "high": 76.816,
    "low": 74.69,
    "close": 76.36,
    "volume": 10309600
  },
  {
    "time": "17-05-2005",
    "open": 73.93,
    "high": 74.43,
    "low": 73.33,
    "close": 74.29,
    "volume": 5135600
  },
  {
    "time": "16-05-2005",
    "open": 73.09,
    "high": 74.49,
    "low": 73.09,
    "close": 74.34,
    "volume": 5501500
  },
  {
    "time": "13-05-2005",
    "open": 72.53,
    "high": 73.86,
    "low": 72.52,
    "close": 73.16,
    "volume": 7150600
  },
  {
    "time": "12-05-2005",
    "open": 73.53,
    "high": 73.8,
    "low": 72.5,
    "close": 72.62,
    "volume": 6061900
  },
  {
    "time": "11-05-2005",
    "open": 73.63,
    "high": 73.69,
    "low": 72.51,
    "close": 73.28,
    "volume": 6915700
  },
  {
    "time": "10-05-2005",
    "open": 74.75,
    "high": 74.76,
    "low": 73.04,
    "close": 73.3,
    "volume": 7982200
  },
  {
    "time": "09-05-2005",
    "open": 75.26,
    "high": 75.46,
    "low": 74.71,
    "close": 74.98,
    "volume": 5616700
  },
  {
    "time": "06-05-2005",
    "open": 75.56,
    "high": 75.92,
    "low": 74.97,
    "close": 75.26,
    "volume": 7750300
  },
  {
    "time": "05-05-2005",
    "open": 78,
    "high": 78.11,
    "low": 75.33,
    "close": 75.5,
    "volume": 12371800
  },
  {
    "time": "04-05-2005",
    "open": 76.6,
    "high": 77.2,
    "low": 76.5,
    "close": 77.08,
    "volume": 5512300
  },
  {
    "time": "03-05-2005",
    "open": 76.78,
    "high": 76.96,
    "low": 75.93,
    "close": 76.47,
    "volume": 6196300
  },
  {
    "time": "02-05-2005",
    "open": 76.88,
    "high": 77.29,
    "low": 76.03,
    "close": 76.51,
    "volume": 7232500
  },
  {
    "time": "29-04-2005",
    "open": 77.05,
    "high": 77.08,
    "low": 75.91,
    "close": 76.38,
    "volume": 8147700
  },
  {
    "time": "28-04-2005",
    "open": 77.05,
    "high": 77.11,
    "low": 75.65,
    "close": 75.91,
    "volume": 8629200
  },
  {
    "time": "27-04-2005",
    "open": 75.69,
    "high": 77.18,
    "low": 75.5,
    "close": 77.05,
    "volume": 11631400
  },
  {
    "time": "26-04-2005",
    "open": 74.68,
    "high": 76.98,
    "low": 74.65,
    "close": 75.43,
    "volume": 12484300
  },
  {
    "time": "25-04-2005",
    "open": 75.24,
    "high": 75.72,
    "low": 74.05,
    "close": 74.61,
    "volume": 10219400
  },
  {
    "time": "22-04-2005",
    "open": 74.05,
    "high": 74.7,
    "low": 73.26,
    "close": 74.21,
    "volume": 10984400
  },
  {
    "time": "21-04-2005",
    "open": 72.99,
    "high": 74.1,
    "low": 72.8,
    "close": 74,
    "volume": 16224400
  },
  {
    "time": "20-04-2005",
    "open": 75.48,
    "high": 75.87,
    "low": 71.85,
    "close": 72.01,
    "volume": 20366300
  },
  {
    "time": "19-04-2005",
    "open": 76.98,
    "high": 77.2,
    "low": 75.25,
    "close": 75.48,
    "volume": 9259900
  },
  {
    "time": "18-04-2005",
    "open": 77.15,
    "high": 77.75,
    "low": 76.14,
    "close": 76.65,
    "volume": 13255000
  },
  {
    "time": "15-04-2005",
    "open": 79.49,
    "high": 79.66,
    "low": 76.33,
    "close": 76.7,
    "volume": 27906300
  },
  {
    "time": "14-04-2005",
    "open": 84.63,
    "high": 85.41,
    "low": 83.47,
    "close": 83.64,
    "volume": 10421200
  },
  {
    "time": "13-04-2005",
    "open": 85.76,
    "high": 85.9722,
    "low": 84.24,
    "close": 84.57,
    "volume": 7090700
  },
  {
    "time": "12-04-2005",
    "open": 85.9,
    "high": 86.24,
    "low": 85.17,
    "close": 85.75,
    "volume": 8429700
  },
  {
    "time": "11-04-2005",
    "open": 87.35,
    "high": 87.56,
    "low": 86.09,
    "close": 86.2,
    "volume": 8296500
  },
  {
    "time": "08-04-2005",
    "open": 88.28,
    "high": 88.46,
    "low": 87.5,
    "close": 87.6,
    "volume": 5179200
  },
  {
    "time": "07-04-2005",
    "open": 89,
    "high": 89.2,
    "low": 88.1,
    "close": 88.44,
    "volume": 6355700
  },
  {
    "time": "06-04-2005",
    "open": 89.05,
    "high": 89.38,
    "low": 88.706,
    "close": 89,
    "volume": 7836800
  },
  {
    "time": "05-04-2005",
    "open": 90.23,
    "high": 90.33,
    "low": 89.26,
    "close": 89.57,
    "volume": 4568400
  },
  {
    "time": "04-04-2005",
    "open": 90.08,
    "high": 90.62,
    "low": 89.773,
    "close": 90.32,
    "volume": 3737800
  },
  {
    "time": "01-04-2005",
    "open": 91.49,
    "high": 91.76,
    "low": 90.04,
    "close": 90.44,
    "volume": 5721200
  },
  {
    "time": "31-03-2005",
    "open": 90.46,
    "high": 91.41,
    "low": 90.22,
    "close": 91.38,
    "volume": 4418600
  },
  {
    "time": "30-03-2005",
    "open": 90.32,
    "high": 90.88,
    "low": 90.32,
    "close": 90.68,
    "volume": 5253900
  },
  {
    "time": "29-03-2005",
    "open": 90.74,
    "high": 91.07,
    "low": 90.18,
    "close": 90.6,
    "volume": 6070400
  },
  {
    "time": "28-03-2005",
    "open": 90.71,
    "high": 91.63,
    "low": 90.62,
    "close": 91.04,
    "volume": 4088900
  },
  {
    "time": "24-03-2005",
    "open": 90.8,
    "high": 91.55,
    "low": 90.7,
    "close": 90.7,
    "volume": 4577100
  },
  {
    "time": "23-03-2005",
    "open": 89.97,
    "high": 91.17,
    "low": 89.82,
    "close": 90.52,
    "volume": 5472100
  },
  {
    "time": "22-03-2005",
    "open": 89.61,
    "high": 90.34,
    "low": 89.26,
    "close": 89.5,
    "volume": 4452500
  },
  {
    "time": "21-03-2005",
    "open": 89.42,
    "high": 90.28,
    "low": 89.27,
    "close": 89.51,
    "volume": 4402400
  },
  {
    "time": "18-03-2005",
    "open": 89.86,
    "high": 89.9,
    "low": 89.09,
    "close": 89.28,
    "volume": 7541500
  },
  {
    "time": "17-03-2005",
    "open": 90.49,
    "high": 90.68,
    "low": 89.7,
    "close": 89.86,
    "volume": 5562100
  },
  {
    "time": "16-03-2005",
    "open": 90.74,
    "high": 91.4,
    "low": 90.25,
    "close": 90.65,
    "volume": 5065300
  },
  {
    "time": "15-03-2005",
    "open": 92,
    "high": 92.27,
    "low": 91.2,
    "close": 91.38,
    "volume": 3663100
  },
  {
    "time": "14-03-2005",
    "open": 91.5,
    "high": 92.04,
    "low": 91.5,
    "close": 91.9,
    "volume": 3935500
  },
  {
    "time": "11-03-2005",
    "open": 92.25,
    "high": 92.41,
    "low": 91.2,
    "close": 91.51,
    "volume": 4494300
  },
  {
    "time": "10-03-2005",
    "open": 92.35,
    "high": 92.8,
    "low": 92.09,
    "close": 92.41,
    "volume": 3422500
  },
  {
    "time": "09-03-2005",
    "open": 92.02,
    "high": 93,
    "low": 92.01,
    "close": 92.35,
    "volume": 4511200
  },
  {
    "time": "08-03-2005",
    "open": 91.7,
    "high": 92.56,
    "low": 91.7,
    "close": 92.13,
    "volume": 5087800
  },
  {
    "time": "07-03-2005",
    "open": 92.35,
    "high": 92.51,
    "low": 91.59,
    "close": 91.6,
    "volume": 7046900
  },
  {
    "time": "04-03-2005",
    "open": 92.94,
    "high": 93.18,
    "low": 92.36,
    "close": 92.37,
    "volume": 4754000
  },
  {
    "time": "03-03-2005",
    "open": 93.15,
    "high": 93.21,
    "low": 92.2,
    "close": 92.41,
    "volume": 4037800
  },
  {
    "time": "02-03-2005",
    "open": 92.75,
    "high": 93.73,
    "low": 92.75,
    "close": 92.92,
    "volume": 4467400
  },
  {
    "time": "01-03-2005",
    "open": 92.64,
    "high": 93.43,
    "low": 92.59,
    "close": 93.3,
    "volume": 3819900
  },
  {
    "time": "28-02-2005",
    "open": 92.53,
    "high": 92.76,
    "low": 92.41,
    "close": 92.58,
    "volume": 4949800
  },
  {
    "time": "25-02-2005",
    "open": 92.35,
    "high": 92.8,
    "low": 92.29,
    "close": 92.8,
    "volume": 4078500
  },
  {
    "time": "24-02-2005",
    "open": 91.98,
    "high": 93.09,
    "low": 91.85,
    "close": 92.64,
    "volume": 4153900
  },
  {
    "time": "23-02-2005",
    "open": 92.29,
    "high": 92.44,
    "low": 91.55,
    "close": 92.1,
    "volume": 5622800
  },
  {
    "time": "22-02-2005",
    "open": 92.67,
    "high": 93.5,
    "low": 92.23,
    "close": 92.32,
    "volume": 4825100
  },
  {
    "time": "18-02-2005",
    "open": 93.75,
    "high": 94.25,
    "low": 92.55,
    "close": 93.27,
    "volume": 4265300
  },
  {
    "time": "17-02-2005",
    "open": 94.5,
    "high": 94.76,
    "low": 93.74,
    "close": 93.75,
    "volume": 4254200
  },
  {
    "time": "16-02-2005",
    "open": 94.23,
    "high": 94.97,
    "low": 94.2,
    "close": 94.62,
    "volume": 4466500
  },
  {
    "time": "15-02-2005",
    "open": 93.5,
    "high": 94.67,
    "low": 93.48,
    "close": 94.33,
    "volume": 4148000
  },
  {
    "time": "14-02-2005",
    "open": 93.16,
    "high": 94.02,
    "low": 93.16,
    "close": 93.57,
    "volume": 2868800
  },
  {
    "time": "11-02-2005",
    "open": 92.7,
    "high": 93.97,
    "low": 92.5,
    "close": 93.3,
    "volume": 4229300
  },
  {
    "time": "10-02-2005",
    "open": 92.95,
    "high": 93.1,
    "low": 92.3,
    "close": 92.76,
    "volume": 4838400
  },
  {
    "time": "09-02-2005",
    "open": 94.14,
    "high": 94.83,
    "low": 92.54,
    "close": 92.7,
    "volume": 5657100
  },
  {
    "time": "08-02-2005",
    "open": 94.28,
    "high": 94.64,
    "low": 94.13,
    "close": 94.13,
    "volume": 3541100
  },
  {
    "time": "07-02-2005",
    "open": 94.35,
    "high": 94.9,
    "low": 94.33,
    "close": 94.53,
    "volume": 2837800
  },
  {
    "time": "04-02-2005",
    "open": 93.01,
    "high": 94.74,
    "low": 93,
    "close": 94.51,
    "volume": 3807600
  },
  {
    "time": "03-02-2005",
    "open": 93.87,
    "high": 93.94,
    "low": 93.06,
    "close": 93.54,
    "volume": 3928700
  },
  {
    "time": "02-02-2005",
    "open": 93.78,
    "high": 94.35,
    "low": 93.63,
    "close": 94.3,
    "volume": 3597400
  },
  {
    "time": "01-02-2005",
    "open": 93.67,
    "high": 94,
    "low": 93.37,
    "close": 93.86,
    "volume": 3637400
  },
  {
    "time": "31-01-2005",
    "open": 93.65,
    "high": 93.95,
    "low": 93.05,
    "close": 93.42,
    "volume": 4759900
  },
  {
    "time": "28-01-2005",
    "open": 92.68,
    "high": 93.59,
    "low": 92.48,
    "close": 92.89,
    "volume": 5960600
  },
  {
    "time": "27-01-2005",
    "open": 91.5,
    "high": 92.22,
    "low": 91.44,
    "close": 91.98,
    "volume": 4746900
  },
  {
    "time": "26-01-2005",
    "open": 92.3,
    "high": 92.87,
    "low": 91.94,
    "close": 91.95,
    "volume": 5352000
  },
  {
    "time": "25-01-2005",
    "open": 91.98,
    "high": 92.59,
    "low": 91.95,
    "close": 92.19,
    "volume": 5070700
  },
  {
    "time": "24-01-2005",
    "open": 92.7,
    "high": 92.85,
    "low": 91.76,
    "close": 91.79,
    "volume": 6537300
  },
  {
    "time": "21-01-2005",
    "open": 93,
    "high": 93.3,
    "low": 92.23,
    "close": 92.38,
    "volume": 7002600
  },
  {
    "time": "20-01-2005",
    "open": 92.61,
    "high": 93.84,
    "low": 92.6,
    "close": 93,
    "volume": 5708600
  },
  {
    "time": "19-01-2005",
    "open": 94.95,
    "high": 95.15,
    "low": 92.93,
    "close": 93.1,
    "volume": 7352700
  },
  {
    "time": "18-01-2005",
    "open": 93.65,
    "high": 95.34,
    "low": 93.62,
    "close": 94.9,
    "volume": 8492100
  },
  {
    "time": "14-01-2005",
    "open": 94.01,
    "high": 94.25,
    "low": 93.55,
    "close": 94.1,
    "volume": 5520800
  },
  {
    "time": "13-01-2005",
    "open": 95.39,
    "high": 96.2,
    "low": 93.7,
    "close": 94.45,
    "volume": 5339400
  },
  {
    "time": "12-01-2005",
    "open": 95,
    "high": 95.28,
    "low": 94.06,
    "close": 95.21,
    "volume": 5828600
  },
  {
    "time": "11-01-2005",
    "open": 95.68,
    "high": 95.79,
    "low": 94.71,
    "close": 95,
    "volume": 4746400
  },
  {
    "time": "10-01-2005",
    "open": 95.78,
    "high": 96.09,
    "low": 95.24,
    "close": 95.68,
    "volume": 4625100
  },
  {
    "time": "07-01-2005",
    "open": 96.5,
    "high": 96.8,
    "low": 95.47,
    "close": 95.78,
    "volume": 6200700
  },
  {
    "time": "06-01-2005",
    "open": 96.54,
    "high": 96.98,
    "low": 96.05,
    "close": 96.2,
    "volume": 4561700
  },
  {
    "time": "05-01-2005",
    "open": 96.6,
    "high": 97.83,
    "low": 96.4,
    "close": 96.5,
    "volume": 5646700
  },
  {
    "time": "04-01-2005",
    "open": 97.74,
    "high": 98.42,
    "low": 96.52,
    "close": 96.7,
    "volume": 5711000
  },
  {
    "time": "03-01-2005",
    "open": 98.97,
    "high": 99.1,
    "low": 97.25,
    "close": 97.75,
    "volume": 5295200
  },
  {
    "time": "31-12-2004",
    "open": 98.6,
    "high": 98.91,
    "low": 98.49,
    "close": 98.58,
    "volume": 2793200
  },
  {
    "time": "30-12-2004",
    "open": 98.1,
    "high": 99,
    "low": 98.07,
    "close": 98.3,
    "volume": 3812400
  },
  {
    "time": "29-12-2004",
    "open": 97.81,
    "high": 98.47,
    "low": 97.8,
    "close": 98.18,
    "volume": 3296300
  },
  {
    "time": "28-12-2004",
    "open": 97.4,
    "high": 98.55,
    "low": 97.37,
    "close": 98.3,
    "volume": 4336400
  },
  {
    "time": "27-12-2004",
    "open": 97.69,
    "high": 97.97,
    "low": 97.38,
    "close": 97.5,
    "volume": 3262900
  },
  {
    "time": "23-12-2004",
    "open": 97.5,
    "high": 98,
    "low": 97.5,
    "close": 97.72,
    "volume": 3590600
  },
  {
    "time": "22-12-2004",
    "open": 97.35,
    "high": 97.98,
    "low": 97.29,
    "close": 97.61,
    "volume": 4950100
  },
  {
    "time": "21-12-2004",
    "open": 96.59,
    "high": 97.15,
    "low": 96.51,
    "close": 97.02,
    "volume": 4841800
  },
  {
    "time": "20-12-2004",
    "open": 96.35,
    "high": 97.57,
    "low": 96.35,
    "close": 96.55,
    "volume": 4769900
  },
  {
    "time": "17-12-2004",
    "open": 97,
    "high": 98,
    "low": 96.2,
    "close": 96.2,
    "volume": 8853100
  },
  {
    "time": "16-12-2004",
    "open": 96.8,
    "high": 98.15,
    "low": 96.8,
    "close": 97.45,
    "volume": 5660100
  },
  {
    "time": "15-12-2004",
    "open": 96.92,
    "high": 97.7,
    "low": 96.81,
    "close": 97.33,
    "volume": 3914500
  },
  {
    "time": "14-12-2004",
    "open": 96.3,
    "high": 97.7,
    "low": 96.2884,
    "close": 97.31,
    "volume": 4493200
  },
  {
    "time": "13-12-2004",
    "open": 96.85,
    "high": 97.2,
    "low": 96.08,
    "close": 96.45,
    "volume": 4799500
  },
  {
    "time": "10-12-2004",
    "open": 96.1,
    "high": 97.99,
    "low": 96.1,
    "close": 96.67,
    "volume": 4188300
  },
  {
    "time": "09-12-2004",
    "open": 96.2,
    "high": 97.6,
    "low": 95.71,
    "close": 97.51,
    "volume": 5713700
  },
  {
    "time": "08-12-2004",
    "open": 96.43,
    "high": 97.35,
    "low": 95.77,
    "close": 96.65,
    "volume": 5310700
  },
  {
    "time": "07-12-2004",
    "open": 97.7,
    "high": 98.25,
    "low": 95.99,
    "close": 96.1,
    "volume": 6477100
  },
  {
    "time": "06-12-2004",
    "open": 96.88,
    "high": 97.9,
    "low": 96.65,
    "close": 97.67,
    "volume": 5263000
  },
  {
    "time": "03-12-2004",
    "open": 96.55,
    "high": 97.63,
    "low": 96.55,
    "close": 97.08,
    "volume": 7026800
  },
  {
    "time": "02-12-2004",
    "open": 95.65,
    "high": 96.78,
    "low": 95.49,
    "close": 95.76,
    "volume": 5152300
  },
  {
    "time": "01-12-2004",
    "open": 94.5,
    "high": 96.07,
    "low": 94.47,
    "close": 95.88,
    "volume": 5664500
  },
  {
    "time": "30-11-2004",
    "open": 95.15,
    "high": 95.65,
    "low": 94.24,
    "close": 94.24,
    "volume": 5870300
  },
  {
    "time": "29-11-2004",
    "open": 94.94,
    "high": 96.38,
    "low": 94.94,
    "close": 95.5,
    "volume": 5699800
  },
  {
    "time": "26-11-2004",
    "open": 95.05,
    "high": 95.38,
    "low": 94.58,
    "close": 94.72,
    "volume": 2204300
  },
  {
    "time": "24-11-2004",
    "open": 95.04,
    "high": 95.79,
    "low": 95.0375,
    "close": 95.46,
    "volume": 3750600
  },
  {
    "time": "23-11-2004",
    "open": 94.7,
    "high": 95.37,
    "low": 94.55,
    "close": 95.28,
    "volume": 5529900
  },
  {
    "time": "22-11-2004",
    "open": 94.3,
    "high": 95.4,
    "low": 94.16,
    "close": 95.11,
    "volume": 5814100
  },
  {
    "time": "19-11-2004",
    "open": 94.95,
    "high": 95.16,
    "low": 94.25,
    "close": 94.45,
    "volume": 5679100
  },
  {
    "time": "18-11-2004",
    "open": 95.36,
    "high": 95.7,
    "low": 95.03,
    "close": 95.1,
    "volume": 4655900
  },
  {
    "time": "17-11-2004",
    "open": 95.3,
    "high": 96.63,
    "low": 95.3,
    "close": 95.46,
    "volume": 6353200
  },
  {
    "time": "16-11-2004",
    "open": 95.25,
    "high": 95.5,
    "low": 94.65,
    "close": 94.89,
    "volume": 5684100
  },
  {
    "time": "15-11-2004",
    "open": 95.08,
    "high": 96,
    "low": 94.8,
    "close": 95.92,
    "volume": 4887600
  },
  {
    "time": "12-11-2004",
    "open": 94.66,
    "high": 95.5,
    "low": 94.56,
    "close": 95.32,
    "volume": 4958400
  },
  {
    "time": "11-11-2004",
    "open": 93.6,
    "high": 95.22,
    "low": 93.54,
    "close": 94.79,
    "volume": 7453400
  },
  {
    "time": "10-11-2004",
    "open": 92.92,
    "high": 94.3,
    "low": 92.92,
    "close": 93.61,
    "volume": 6258400
  },
  {
    "time": "09-11-2004",
    "open": 93,
    "high": 93.95,
    "low": 93,
    "close": 93.37,
    "volume": 4513100
  },
  {
    "time": "08-11-2004",
    "open": 92.5,
    "high": 93.7,
    "low": 92.5,
    "close": 93.37,
    "volume": 4907300
  },
  {
    "time": "05-11-2004",
    "open": 92.4,
    "high": 93.52,
    "low": 92.4,
    "close": 93.28,
    "volume": 6708400
  },
  {
    "time": "04-11-2004",
    "open": 91.05,
    "high": 92.7,
    "low": 90.82,
    "close": 92.38,
    "volume": 6951600
  },
  {
    "time": "03-11-2004",
    "open": 91.25,
    "high": 91.9,
    "low": 90.97,
    "close": 91.2,
    "volume": 6553300
  },
  {
    "time": "02-11-2004",
    "open": 89.55,
    "high": 91.22,
    "low": 89.5,
    "close": 90.47,
    "volume": 5388700
  },
  {
    "time": "01-11-2004",
    "open": 89.33,
    "high": 90.6,
    "low": 89.23,
    "close": 90.11,
    "volume": 5160600
  },
  {
    "time": "29-10-2004",
    "open": 89.4,
    "high": 89.9,
    "low": 88.95,
    "close": 89.75,
    "volume": 4518500
  },
  {
    "time": "28-10-2004",
    "open": 89.8,
    "high": 90.24,
    "low": 89.43,
    "close": 89.5,
    "volume": 4226500
  },
  {
    "time": "27-10-2004",
    "open": 88.58,
    "high": 90.27,
    "low": 88.5,
    "close": 90,
    "volume": 6035100
  },
  {
    "time": "26-10-2004",
    "open": 88.33,
    "high": 89.57,
    "low": 88.25,
    "close": 89,
    "volume": 7335800
  },
  {
    "time": "25-10-2004",
    "open": 87.36,
    "high": 88.9,
    "low": 87.31,
    "close": 88.43,
    "volume": 5774500
  },
  {
    "time": "22-10-2004",
    "open": 88.22,
    "high": 88.45,
    "low": 87.29,
    "close": 87.39,
    "volume": 5988700
  },
  {
    "time": "21-10-2004",
    "open": 88.4,
    "high": 88.76,
    "low": 87.66,
    "close": 88.1,
    "volume": 6137500
  },
  {
    "time": "20-10-2004",
    "open": 88.45,
    "high": 89.19,
    "low": 88.29,
    "close": 88.82,
    "volume": 6926800
  },
  {
    "time": "19-10-2004",
    "open": 88.2,
    "high": 89.73,
    "low": 88,
    "close": 89.37,
    "volume": 13692200
  },
  {
    "time": "18-10-2004",
    "open": 84.3,
    "high": 86.15,
    "low": 84.29,
    "close": 85.92,
    "volume": 7182600
  },
  {
    "time": "15-10-2004",
    "open": 84.78,
    "high": 85.25,
    "low": 84.6,
    "close": 84.85,
    "volume": 5928500
  },
  {
    "time": "14-10-2004",
    "open": 84.75,
    "high": 84.98,
    "low": 84.3,
    "close": 84.78,
    "volume": 4233700
  },
  {
    "time": "13-10-2004",
    "open": 86.26,
    "high": 86.48,
    "low": 84.43,
    "close": 84.98,
    "volume": 6651400
  },
  {
    "time": "12-10-2004",
    "open": 86.02,
    "high": 86.2,
    "low": 85.58,
    "close": 86,
    "volume": 4626600
  },
  {
    "time": "11-10-2004",
    "open": 86.77,
    "high": 87.2,
    "low": 86.27,
    "close": 86.63,
    "volume": 3016300
  },
  {
    "time": "08-10-2004",
    "open": 87.43,
    "high": 87.91,
    "low": 86.51,
    "close": 86.71,
    "volume": 4090000
  },
  {
    "time": "07-10-2004",
    "open": 88.04,
    "high": 88.1,
    "low": 87.4,
    "close": 87.42,
    "volume": 3076900
  },
  {
    "time": "06-10-2004",
    "open": 87.14,
    "high": 88.1,
    "low": 87.1,
    "close": 88.04,
    "volume": 3984400
  },
  {
    "time": "05-10-2004",
    "open": 87.95,
    "high": 88.03,
    "low": 87.13,
    "close": 87.32,
    "volume": 5150700
  },
  {
    "time": "04-10-2004",
    "open": 87,
    "high": 88.1,
    "low": 86.72,
    "close": 87.16,
    "volume": 5001400
  },
  {
    "time": "01-10-2004",
    "open": 85.95,
    "high": 86.98,
    "low": 85.88,
    "close": 86.72,
    "volume": 4538000
  },
  {
    "time": "30-09-2004",
    "open": 85.14,
    "high": 85.98,
    "low": 85.01,
    "close": 85.74,
    "volume": 5198000
  },
  {
    "time": "29-09-2004",
    "open": 84.48,
    "high": 84.98,
    "low": 84.15,
    "close": 84.98,
    "volume": 4204500
  },
  {
    "time": "28-09-2004",
    "open": 84.35,
    "high": 84.65,
    "low": 83.88,
    "close": 84.48,
    "volume": 3874200
  },
  {
    "time": "27-09-2004",
    "open": 84.1,
    "high": 84.44,
    "low": 83.98,
    "close": 84.16,
    "volume": 4650300
  },
  {
    "time": "24-09-2004",
    "open": 83.8,
    "high": 84.74,
    "low": 83.78,
    "close": 84.43,
    "volume": 4899500
  },
  {
    "time": "23-09-2004",
    "open": 84.04,
    "high": 84.27,
    "low": 83.24,
    "close": 83.88,
    "volume": 4801200
  },
  {
    "time": "22-09-2004",
    "open": 85.35,
    "high": 85.44,
    "low": 84.17,
    "close": 84.31,
    "volume": 5037100
  },
  {
    "time": "21-09-2004",
    "open": 85.7,
    "high": 86.11,
    "low": 85.34,
    "close": 85.72,
    "volume": 4049700
  },
  {
    "time": "20-09-2004",
    "open": 85.4,
    "high": 86.43,
    "low": 85.4,
    "close": 85.7,
    "volume": 4380400
  },
  {
    "time": "17-09-2004",
    "open": 86.4,
    "high": 86.5,
    "low": 85.44,
    "close": 85.74,
    "volume": 6198700
  },
  {
    "time": "16-09-2004",
    "open": 86.2,
    "high": 86.96,
    "low": 85.93,
    "close": 86.12,
    "volume": 3623000
  },
  {
    "time": "15-09-2004",
    "open": 85.94,
    "high": 86.5,
    "low": 85.89,
    "close": 86.37,
    "volume": 4631200
  },
  {
    "time": "14-09-2004",
    "open": 86.6,
    "high": 86.88,
    "low": 86.15,
    "close": 86.72,
    "volume": 3953500
  },
  {
    "time": "13-09-2004",
    "open": 87,
    "high": 87.28,
    "low": 86.08,
    "close": 86.49,
    "volume": 4801400
  },
  {
    "time": "10-09-2004",
    "open": 86.43,
    "high": 87,
    "low": 86.25,
    "close": 86.76,
    "volume": 3753500
  },
  {
    "time": "09-09-2004",
    "open": 85.86,
    "high": 86.79,
    "low": 85.86,
    "close": 86.44,
    "volume": 4517400
  },
  {
    "time": "08-09-2004",
    "open": 84.9,
    "high": 86.51,
    "low": 84.88,
    "close": 85.86,
    "volume": 5721200
  },
  {
    "time": "07-09-2004",
    "open": 84.7,
    "high": 85.44,
    "low": 84.6,
    "close": 84.97,
    "volume": 4002800
  },
  {
    "time": "03-09-2004",
    "open": 84.3,
    "high": 84.69,
    "low": 83.96,
    "close": 84.39,
    "volume": 3479800
  },
  {
    "time": "02-09-2004",
    "open": 84.22,
    "high": 84.78,
    "low": 83.85,
    "close": 84.57,
    "volume": 3380400
  },
  {
    "time": "01-09-2004",
    "open": 84.05,
    "high": 85.09,
    "low": 84.01,
    "close": 84.22,
    "volume": 4748500
  },
  {
    "time": "31-08-2004",
    "open": 84.55,
    "high": 84.69,
    "low": 83.65,
    "close": 84.69,
    "volume": 3399500
  },
  {
    "time": "30-08-2004",
    "open": 84.57,
    "high": 84.99,
    "low": 84.39,
    "close": 84.4,
    "volume": 2277900
  },
  {
    "time": "27-08-2004",
    "open": 84.65,
    "high": 84.95,
    "low": 84.59,
    "close": 84.94,
    "volume": 2444800
  },
  {
    "time": "26-08-2004",
    "open": 85,
    "high": 85.04,
    "low": 84.69,
    "close": 84.69,
    "volume": 3134400
  },
  {
    "time": "25-08-2004",
    "open": 85,
    "high": 85.27,
    "low": 84.55,
    "close": 85.07,
    "volume": 4405600
  },
  {
    "time": "24-08-2004",
    "open": 85,
    "high": 85.15,
    "low": 84.35,
    "close": 84.71,
    "volume": 2710400
  },
  {
    "time": "23-08-2004",
    "open": 85.23,
    "high": 85.45,
    "low": 84.65,
    "close": 84.65,
    "volume": 4260600
  },
  {
    "time": "20-08-2004",
    "open": 84.52,
    "high": 85.25,
    "low": 84.52,
    "close": 85.25,
    "volume": 4501400
  },
  {
    "time": "19-08-2004",
    "open": 84.75,
    "high": 85.35,
    "low": 84.45,
    "close": 84.89,
    "volume": 4704500
  },
  {
    "time": "18-08-2004",
    "open": 83.6,
    "high": 85.13,
    "low": 83.57,
    "close": 85.13,
    "volume": 4397500
  },
  {
    "time": "17-08-2004",
    "open": 84.1,
    "high": 84.54,
    "low": 83.66,
    "close": 84.04,
    "volume": 3559400
  },
  {
    "time": "16-08-2004",
    "open": 83.7,
    "high": 84.56,
    "low": 83.51,
    "close": 84.02,
    "volume": 4361500
  },
  {
    "time": "13-08-2004",
    "open": 82.54,
    "high": 83.94,
    "low": 82.51,
    "close": 83.91,
    "volume": 5801900
  },
  {
    "time": "12-08-2004",
    "open": 83.05,
    "high": 83.05,
    "low": 81.9,
    "close": 82.21,
    "volume": 7134900
  },
  {
    "time": "11-08-2004",
    "open": 83.7,
    "high": 83.98,
    "low": 83.1,
    "close": 83.69,
    "volume": 5288000
  },
  {
    "time": "10-08-2004",
    "open": 84,
    "high": 84.99,
    "low": 83.81,
    "close": 84.99,
    "volume": 4351000
  },
  {
    "time": "09-08-2004",
    "open": 83.48,
    "high": 83.96,
    "low": 82.87,
    "close": 83.55,
    "volume": 3621100
  },
  {
    "time": "06-08-2004",
    "open": 84.38,
    "high": 84.76,
    "low": 83.42,
    "close": 83.48,
    "volume": 5219200
  },
  {
    "time": "05-08-2004",
    "open": 85.97,
    "high": 86.42,
    "low": 85.01,
    "close": 85.19,
    "volume": 3879900
  },
  {
    "time": "04-08-2004",
    "open": 85.3,
    "high": 86.65,
    "low": 85.25,
    "close": 85.97,
    "volume": 4160600
  },
  {
    "time": "03-08-2004",
    "open": 86.7,
    "high": 86.8,
    "low": 85.44,
    "close": 85.71,
    "volume": 4147200
  },
  {
    "time": "02-08-2004",
    "open": 86.87,
    "high": 87.39,
    "low": 86.42,
    "close": 86.69,
    "volume": 3408400
  },
  {
    "time": "30-07-2004",
    "open": 86.45,
    "high": 87.4,
    "low": 86.3875,
    "close": 87.07,
    "volume": 3508500
  },
  {
    "time": "29-07-2004",
    "open": 86.25,
    "high": 87.22,
    "low": 86.23,
    "close": 86.77,
    "volume": 4232300
  },
  {
    "time": "28-07-2004",
    "open": 85.88,
    "high": 86.28,
    "low": 84.88,
    "close": 85.85,
    "volume": 6566200
  },
  {
    "time": "27-07-2004",
    "open": 85.6,
    "high": 86.3,
    "low": 85.45,
    "close": 85.88,
    "volume": 4098500
  },
  {
    "time": "26-07-2004",
    "open": 84.85,
    "high": 85.58,
    "low": 84.51,
    "close": 85.09,
    "volume": 4517500
  },
  {
    "time": "23-07-2004",
    "open": 85.7,
    "high": 86.06,
    "low": 84.5,
    "close": 84.85,
    "volume": 4309400
  },
  {
    "time": "22-07-2004",
    "open": 85,
    "high": 86.4,
    "low": 84.68,
    "close": 86.06,
    "volume": 5972600
  },
  {
    "time": "21-07-2004",
    "open": 86.75,
    "high": 87.11,
    "low": 85.3,
    "close": 85.3,
    "volume": 5534000
  },
  {
    "time": "20-07-2004",
    "open": 85.63,
    "high": 86.49,
    "low": 85.3,
    "close": 86.36,
    "volume": 5651300
  },
  {
    "time": "19-07-2004",
    "open": 84.5,
    "high": 85.85,
    "low": 84.4,
    "close": 85.3,
    "volume": 6596000
  },
  {
    "time": "16-07-2004",
    "open": 86.05,
    "high": 86.48,
    "low": 84.28,
    "close": 84.28,
    "volume": 10441400
  },
  {
    "time": "15-07-2004",
    "open": 84.13,
    "high": 84.63,
    "low": 83.61,
    "close": 84.02,
    "volume": 7086500
  },
  {
    "time": "14-07-2004",
    "open": 84.84,
    "high": 85.24,
    "low": 83.78,
    "close": 84.13,
    "volume": 6298600
  },
  {
    "time": "13-07-2004",
    "open": 85.9,
    "high": 86.09,
    "low": 85.2,
    "close": 85.25,
    "volume": 6001000
  },
  {
    "time": "12-07-2004",
    "open": 84,
    "high": 85.25,
    "low": 83.42,
    "close": 84.95,
    "volume": 6009200
  },
  {
    "time": "09-07-2004",
    "open": 84.4,
    "high": 84.5,
    "low": 83.51,
    "close": 83.89,
    "volume": 6595200
  },
  {
    "time": "08-07-2004",
    "open": 85,
    "high": 85.01,
    "low": 83.58,
    "close": 83.65,
    "volume": 7529000
  },
  {
    "time": "07-07-2004",
    "open": 85.3,
    "high": 85.94,
    "low": 85.05,
    "close": 85.35,
    "volume": 5677300
  },
  {
    "time": "06-07-2004",
    "open": 86.5,
    "high": 86.73,
    "low": 85.13,
    "close": 85.7,
    "volume": 6408100
  },
  {
    "time": "02-07-2004",
    "open": 87.45,
    "high": 87.55,
    "low": 86.6,
    "close": 87.04,
    "volume": 2874500
  },
  {
    "time": "01-07-2004",
    "open": 88.28,
    "high": 88.44,
    "low": 86.57,
    "close": 87.5,
    "volume": 5093900
  },
  {
    "time": "30-06-2004",
    "open": 88.3,
    "high": 88.5,
    "low": 87.7,
    "close": 88.15,
    "volume": 4724000
  },
  {
    "time": "29-06-2004",
    "open": 88.35,
    "high": 88.49,
    "low": 87.9,
    "close": 88.29,
    "volume": 4394800
  },
  {
    "time": "28-06-2004",
    "open": 89.71,
    "high": 89.9,
    "low": 88.36,
    "close": 88.71,
    "volume": 4423200
  },
  {
    "time": "25-06-2004",
    "open": 89.95,
    "high": 90.23,
    "low": 88.94,
    "close": 89.55,
    "volume": 5604700
  },
  {
    "time": "24-06-2004",
    "open": 90.28,
    "high": 90.92,
    "low": 89.84,
    "close": 89.99,
    "volume": 4069400
  },
  {
    "time": "23-06-2004",
    "open": 90.1,
    "high": 90.84,
    "low": 89.84,
    "close": 90.79,
    "volume": 4354700
  },
  {
    "time": "22-06-2004",
    "open": 89.28,
    "high": 90.24,
    "low": 89.13,
    "close": 90.02,
    "volume": 4149300
  },
  {
    "time": "21-06-2004",
    "open": 90.4,
    "high": 90.43,
    "low": 89.31,
    "close": 89.49,
    "volume": 3843400
  },
  {
    "time": "18-06-2004",
    "open": 90.2,
    "high": 90.8,
    "low": 89.9,
    "close": 90.06,
    "volume": 6470800
  },
  {
    "time": "17-06-2004",
    "open": 90.5,
    "high": 90.56,
    "low": 90.07,
    "close": 90.44,
    "volume": 4180200
  },
  {
    "time": "16-06-2004",
    "open": 90.25,
    "high": 90.93,
    "low": 90.09,
    "close": 90.38,
    "volume": 3145700
  },
  {
    "time": "15-06-2004",
    "open": 90.49,
    "high": 91.21,
    "low": 90.2315,
    "close": 90.54,
    "volume": 4508300
  },
  {
    "time": "14-06-2004",
    "open": 90.05,
    "high": 90.58,
    "low": 89.62,
    "close": 90.07,
    "volume": 4121400
  },
  {
    "time": "10-06-2004",
    "open": 90.23,
    "high": 90.75,
    "low": 89.891,
    "close": 90.46,
    "volume": 3468700
  },
  {
    "time": "09-06-2004",
    "open": 89.9,
    "high": 90.55,
    "low": 89.81,
    "close": 90.09,
    "volume": 5233400
  },
  {
    "time": "08-06-2004",
    "open": 88.64,
    "high": 90.5,
    "low": 88.4,
    "close": 90.04,
    "volume": 5400300
  },
  {
    "time": "07-06-2004",
    "open": 88.75,
    "high": 88.99,
    "low": 88.01,
    "close": 88.64,
    "volume": 4264500
  },
  {
    "time": "04-06-2004",
    "open": 87.95,
    "high": 88.49,
    "low": 87.5,
    "close": 87.56,
    "volume": 3803400
  },
  {
    "time": "03-06-2004",
    "open": 87.85,
    "high": 88.1,
    "low": 87.35,
    "close": 87.35,
    "volume": 3011500
  },
  {
    "time": "02-06-2004",
    "open": 88.64,
    "high": 88.64,
    "low": 87.89,
    "close": 87.98,
    "volume": 3912600
  },
  {
    "time": "01-06-2004",
    "open": 88.09,
    "high": 88.48,
    "low": 87.3,
    "close": 88.12,
    "volume": 4884300
  },
  {
    "time": "28-05-2004",
    "open": 88.48,
    "high": 88.85,
    "low": 88.25,
    "close": 88.59,
    "volume": 3203800
  },
  {
    "time": "27-05-2004",
    "open": 88.75,
    "high": 89.08,
    "low": 87.98,
    "close": 88.59,
    "volume": 4094600
  },
  {
    "time": "26-05-2004",
    "open": 88.64,
    "high": 88.85,
    "low": 88.04,
    "close": 88.35,
    "volume": 4308200
  },
  {
    "time": "25-05-2004",
    "open": 86.75,
    "high": 88.92,
    "low": 86.55,
    "close": 88.7,
    "volume": 5358100
  },
  {
    "time": "24-05-2004",
    "open": 87.75,
    "high": 87.84,
    "low": 86.55,
    "close": 87.1,
    "volume": 3778100
  },
  {
    "time": "21-05-2004",
    "open": 87.59,
    "high": 88.18,
    "low": 86.91,
    "close": 87.13,
    "volume": 5130000
  },
  {
    "time": "20-05-2004",
    "open": 87.06,
    "high": 87.95,
    "low": 87.05,
    "close": 87.42,
    "volume": 4031500
  },
  {
    "time": "19-05-2004",
    "open": 87.4,
    "high": 88.88,
    "low": 86.5,
    "close": 87.05,
    "volume": 7431800
  },
  {
    "time": "18-05-2004",
    "open": 86.08,
    "high": 86.49,
    "low": 86,
    "close": 86.06,
    "volume": 4401100
  },
  {
    "time": "17-05-2004",
    "open": 85.3,
    "high": 86.03,
    "low": 85.15,
    "close": 85.53,
    "volume": 5700000
  },
  {
    "time": "14-05-2004",
    "open": 86.65,
    "high": 87.35,
    "low": 86.07,
    "close": 86.41,
    "volume": 5197800
  },
  {
    "time": "13-05-2004",
    "open": 86.79,
    "high": 87.41,
    "low": 86.26,
    "close": 87.19,
    "volume": 4708400
  },
  {
    "time": "12-05-2004",
    "open": 87.25,
    "high": 87.25,
    "low": 85.12,
    "close": 86.81,
    "volume": 8445800
  },
  {
    "time": "11-05-2004",
    "open": 87.2,
    "high": 88.15,
    "low": 86.82,
    "close": 87.13,
    "volume": 6575000
  },
  {
    "time": "10-05-2004",
    "open": 87.6,
    "high": 88.1025,
    "low": 86.37,
    "close": 86.88,
    "volume": 6692400
  },
  {
    "time": "07-05-2004",
    "open": 87.75,
    "high": 89.4,
    "low": 87.62,
    "close": 88.19,
    "volume": 5407400
  },
  {
    "time": "06-05-2004",
    "open": 88.1,
    "high": 89.1,
    "low": 87.59,
    "close": 88.36,
    "volume": 4319100
  },
  {
    "time": "05-05-2004",
    "open": 89.75,
    "high": 89.75,
    "low": 88.51,
    "close": 88.96,
    "volume": 4402300
  },
  {
    "time": "04-05-2004",
    "open": 88.02,
    "high": 89.74,
    "low": 88,
    "close": 89,
    "volume": 5451800
  },
  {
    "time": "03-05-2004",
    "open": 88.13,
    "high": 88.52,
    "low": 87.2012,
    "close": 88.02,
    "volume": 6070100
  },
  {
    "time": "30-04-2004",
    "open": 89.01,
    "high": 89.2,
    "low": 88.01,
    "close": 88.17,
    "volume": 5019500
  },
  {
    "time": "29-04-2004",
    "open": 90.01,
    "high": 90.26,
    "low": 88.19,
    "close": 89.08,
    "volume": 7763200
  },
  {
    "time": "28-04-2004",
    "open": 90.3,
    "high": 90.98,
    "low": 90.21,
    "close": 90.41,
    "volume": 4706000
  },
  {
    "time": "27-04-2004",
    "open": 90.65,
    "high": 91.99,
    "low": 90.56,
    "close": 91.11,
    "volume": 4768000
  },
  {
    "time": "26-04-2004",
    "open": 90.75,
    "high": 91.56,
    "low": 90.349,
    "close": 90.43,
    "volume": 4533600
  },
  {
    "time": "23-04-2004",
    "open": 91,
    "high": 91.61,
    "low": 90.36,
    "close": 91.28,
    "volume": 5063200
  },
  {
    "time": "22-04-2004",
    "open": 90.5,
    "high": 91.51,
    "low": 89.7,
    "close": 90.74,
    "volume": 7988000
  },
  {
    "time": "21-04-2004",
    "open": 91.15,
    "high": 91.42,
    "low": 90.69,
    "close": 91.26,
    "volume": 4623400
  },
  {
    "time": "20-04-2004",
    "open": 92,
    "high": 92.48,
    "low": 90.65,
    "close": 90.65,
    "volume": 4260200
  },
  {
    "time": "19-04-2004",
    "open": 91.9,
    "high": 92.3,
    "low": 91.7,
    "close": 91.94,
    "volume": 4159400
  },
  {
    "time": "16-04-2004",
    "open": 92.3,
    "high": 92.35,
    "low": 91.04,
    "close": 92.28,
    "volume": 11118000
  },
  {
    "time": "15-04-2004",
    "open": 93.8,
    "high": 94.09,
    "low": 93.06,
    "close": 93.97,
    "volume": 6844200
  },
  {
    "time": "14-04-2004",
    "open": 92.1,
    "high": 93.86,
    "low": 92.1,
    "close": 93.7,
    "volume": 5316300
  },
  {
    "time": "13-04-2004",
    "open": 93.79,
    "high": 94.04,
    "low": 92.5,
    "close": 93.04,
    "volume": 5013600
  },
  {
    "time": "12-04-2004",
    "open": 93.5,
    "high": 94.36,
    "low": 93.4,
    "close": 93.74,
    "volume": 3112600
  },
  {
    "time": "08-04-2004",
    "open": 94,
    "high": 94.39,
    "low": 92.55,
    "close": 93.12,
    "volume": 3868000
  },
  {
    "time": "07-04-2004",
    "open": 93.37,
    "high": 93.58,
    "low": 92.51,
    "close": 93.08,
    "volume": 4537300
  },
  {
    "time": "06-04-2004",
    "open": 93.6,
    "high": 93.79,
    "low": 93.02,
    "close": 93.7,
    "volume": 3860100
  },
  {
    "time": "05-04-2004",
    "open": 93.95,
    "high": 94.37,
    "low": 93.51,
    "close": 94.18,
    "volume": 3936000
  },
  {
    "time": "02-04-2004",
    "open": 93.55,
    "high": 94.55,
    "low": 93.33,
    "close": 94.2,
    "volume": 5894500
  },
  {
    "time": "01-04-2004",
    "open": 91.67,
    "high": 92.68,
    "low": 91.62,
    "close": 92.37,
    "volume": 4246900
  },
  {
    "time": "31-03-2004",
    "open": 92.07,
    "high": 92.24,
    "low": 91.51,
    "close": 91.84,
    "volume": 4613500
  },
  {
    "time": "30-03-2004",
    "open": 92.67,
    "high": 92.67,
    "low": 91.35,
    "close": 92.32,
    "volume": 5415700
  },
  {
    "time": "29-03-2004",
    "open": 92.99,
    "high": 93.61,
    "low": 92.18,
    "close": 92.68,
    "volume": 4876300
  },
  {
    "time": "26-03-2004",
    "open": 92.39,
    "high": 93.25,
    "low": 92.16,
    "close": 92.77,
    "volume": 4397700
  },
  {
    "time": "25-03-2004",
    "open": 92.15,
    "high": 92.63,
    "low": 91.45,
    "close": 92.39,
    "volume": 5585800
  },
  {
    "time": "24-03-2004",
    "open": 91.57,
    "high": 92.49,
    "low": 91.04,
    "close": 91.77,
    "volume": 6210400
  },
  {
    "time": "23-03-2004",
    "open": 91.6,
    "high": 92.16,
    "low": 90.68,
    "close": 91.32,
    "volume": 5065300
  },
  {
    "time": "22-03-2004",
    "open": 91.27,
    "high": 91.48,
    "low": 90.28,
    "close": 91.02,
    "volume": 6223500
  },
  {
    "time": "19-03-2004",
    "open": 92.86,
    "high": 92.97,
    "low": 91.51,
    "close": 91.62,
    "volume": 7058700
  },
  {
    "time": "18-03-2004",
    "open": 93.05,
    "high": 93.18,
    "low": 91.9,
    "close": 92.85,
    "volume": 4605200
  },
  {
    "time": "17-03-2004",
    "open": 92.57,
    "high": 93.79,
    "low": 92.45,
    "close": 93.39,
    "volume": 4663000
  },
  {
    "time": "16-03-2004",
    "open": 92.4,
    "high": 92.7,
    "low": 91.42,
    "close": 92.45,
    "volume": 4627600
  },
  {
    "time": "15-03-2004",
    "open": 92.6,
    "high": 92.69,
    "low": 90.88,
    "close": 91.82,
    "volume": 6523000
  },
  {
    "time": "12-03-2004",
    "open": 92,
    "high": 93.38,
    "low": 91.68,
    "close": 93.3,
    "volume": 6290600
  },
  {
    "time": "11-03-2004",
    "open": 92,
    "high": 92.98,
    "low": 91.15,
    "close": 91.21,
    "volume": 8560800
  },
  {
    "time": "10-03-2004",
    "open": 94.38,
    "high": 94.74,
    "low": 92.68,
    "close": 93.06,
    "volume": 6536300
  },
  {
    "time": "09-03-2004",
    "open": 94.3,
    "high": 95.28,
    "low": 93.77,
    "close": 94.53,
    "volume": 6390600
  },
  {
    "time": "08-03-2004",
    "open": 96.49,
    "high": 96.88,
    "low": 94.59,
    "close": 94.59,
    "volume": 5084200
  },
  {
    "time": "05-03-2004",
    "open": 95.95,
    "high": 96.98,
    "low": 95.56,
    "close": 96.45,
    "volume": 3970900
  },
  {
    "time": "04-03-2004",
    "open": 96.58,
    "high": 96.92,
    "low": 96.132,
    "close": 96.39,
    "volume": 3547900
  },
  {
    "time": "03-03-2004",
    "open": 96.57,
    "high": 96.89,
    "low": 95.6,
    "close": 96.84,
    "volume": 4806500
  },
  {
    "time": "02-03-2004",
    "open": 97.6,
    "high": 97.6,
    "low": 96.62,
    "close": 96.82,
    "volume": 4976200
  },
  {
    "time": "01-03-2004",
    "open": 96.5,
    "high": 97.25,
    "low": 96.15,
    "close": 97.04,
    "volume": 4454000
  },
  {
    "time": "27-02-2004",
    "open": 96.8,
    "high": 97.38,
    "low": 96.1,
    "close": 96.5,
    "volume": 4715000
  },
  {
    "time": "26-02-2004",
    "open": 96.27,
    "high": 97.26,
    "low": 96.25,
    "close": 96.79,
    "volume": 3965700
  },
  {
    "time": "25-02-2004",
    "open": 96.5,
    "high": 97.09,
    "low": 96.23,
    "close": 96.54,
    "volume": 3362500
  },
  {
    "time": "24-02-2004",
    "open": 95.2,
    "high": 97.46,
    "low": 95.2,
    "close": 96.79,
    "volume": 7008300
  },
  {
    "time": "23-02-2004",
    "open": 97.4,
    "high": 97.51,
    "low": 95.46,
    "close": 95.96,
    "volume": 6629100
  },
  {
    "time": "20-02-2004",
    "open": 98.6,
    "high": 98.6,
    "low": 97.19,
    "close": 97.31,
    "volume": 5690200
  },
  {
    "time": "19-02-2004",
    "open": 98.42,
    "high": 99.23,
    "low": 97.52,
    "close": 97.8,
    "volume": 5447200
  },
  {
    "time": "18-02-2004",
    "open": 99.31,
    "high": 99.77,
    "low": 98.15,
    "close": 98.42,
    "volume": 4830300
  },
  {
    "time": "17-02-2004",
    "open": 99.99,
    "high": 100,
    "low": 99.32,
    "close": 99.37,
    "volume": 3930400
  },
  {
    "time": "13-02-2004",
    "open": 99.1,
    "high": 100.09,
    "low": 99.0828,
    "close": 99.71,
    "volume": 3562400
  },
  {
    "time": "12-02-2004",
    "open": 100.06,
    "high": 100.3,
    "low": 99.3,
    "close": 99.3,
    "volume": 3611500
  },
  {
    "time": "11-02-2004",
    "open": 99.2,
    "high": 100.31,
    "low": 98.8,
    "close": 99.96,
    "volume": 5505700
  },
  {
    "time": "10-02-2004",
    "open": 98.45,
    "high": 99.97,
    "low": 98.41,
    "close": 99.61,
    "volume": 4057500
  },
  {
    "time": "09-02-2004",
    "open": 99.31,
    "high": 99.44,
    "low": 98.6,
    "close": 98.95,
    "volume": 3742400
  },
  {
    "time": "06-02-2004",
    "open": 98.85,
    "high": 99.24,
    "low": 98.25,
    "close": 98.94,
    "volume": 5516900
  },
  {
    "time": "05-02-2004",
    "open": 100,
    "high": 100.09,
    "low": 98.26,
    "close": 98.86,
    "volume": 5975000
  },
  {
    "time": "04-02-2004",
    "open": 99.38,
    "high": 100.43,
    "low": 99.3,
    "close": 100.19,
    "volume": 8387500
  },
  {
    "time": "03-02-2004",
    "open": 99,
    "high": 100,
    "low": 98.95,
    "close": 100,
    "volume": 5604300
  },
  {
    "time": "02-02-2004",
    "open": 99.15,
    "high": 99.94,
    "low": 98.5,
    "close": 99.39,
    "volume": 6200000
  },
  {
    "time": "30-01-2004",
    "open": 98.02,
    "high": 99.33,
    "low": 97.84,
    "close": 99.23,
    "volume": 6137600
  },
  {
    "time": "29-01-2004",
    "open": 98.1,
    "high": 98.6,
    "low": 96.55,
    "close": 98.01,
    "volume": 6642500
  },
  {
    "time": "28-01-2004",
    "open": 99.15,
    "high": 99.42,
    "low": 97.28,
    "close": 97.38,
    "volume": 6549700
  },
  {
    "time": "27-01-2004",
    "open": 99.4,
    "high": 99.67,
    "low": 98.7,
    "close": 98.8,
    "volume": 5345300
  },
  {
    "time": "26-01-2004",
    "open": 97.9,
    "high": 99.85,
    "low": 97.56,
    "close": 99.85,
    "volume": 6189400
  },
  {
    "time": "23-01-2004",
    "open": 97.82,
    "high": 98.21,
    "low": 97.1,
    "close": 97.9,
    "volume": 5283900
  },
  {
    "time": "22-01-2004",
    "open": 97.84,
    "high": 98.16,
    "low": 97.32,
    "close": 97.51,
    "volume": 4371600
  },
  {
    "time": "21-01-2004",
    "open": 97.23,
    "high": 98.04,
    "low": 96.64,
    "close": 97.7,
    "volume": 6949000
  },
  {
    "time": "20-01-2004",
    "open": 96,
    "high": 97.44,
    "low": 95.73,
    "close": 97.1,
    "volume": 9285100
  },
  {
    "time": "16-01-2004",
    "open": 95,
    "high": 95.35,
    "low": 94.71,
    "close": 95.32,
    "volume": 9302000
  },
  {
    "time": "15-01-2004",
    "open": 95.07,
    "high": 95.65,
    "low": 93.55,
    "close": 94.02,
    "volume": 19812000
  },
  {
    "time": "14-01-2004",
    "open": 89.9,
    "high": 90.46,
    "low": 89.75,
    "close": 90.31,
    "volume": 5505100
  },
  {
    "time": "13-01-2004",
    "open": 91.45,
    "high": 91.51,
    "low": 89.01,
    "close": 89.7,
    "volume": 8842500
  },
  {
    "time": "12-01-2004",
    "open": 91.21,
    "high": 92.14,
    "low": 91.21,
    "close": 91.55,
    "volume": 5130400
  },
  {
    "time": "09-01-2004",
    "open": 91.75,
    "high": 92.35,
    "low": 91,
    "close": 91.21,
    "volume": 7930900
  },
  {
    "time": "08-01-2004",
    "open": 93.21,
    "high": 93.21,
    "low": 92.031,
    "close": 93.04,
    "volume": 6179800
  },
  {
    "time": "07-01-2004",
    "open": 93.14,
    "high": 93.38,
    "low": 92.47,
    "close": 92.78,
    "volume": 4927600
  },
  {
    "time": "06-01-2004",
    "open": 92.2,
    "high": 93.19,
    "low": 92.14,
    "close": 93.06,
    "volume": 4380000
  },
  {
    "time": "05-01-2004",
    "open": 92,
    "high": 93.09,
    "low": 92,
    "close": 93.05,
    "volume": 5276300
  },
  {
    "time": "02-01-2004",
    "open": 92.86,
    "high": 93.05,
    "low": 91.2,
    "close": 91.55,
    "volume": 5327800
  },
  {
    "time": "31-12-2003",
    "open": 92.66,
    "high": 92.85,
    "low": 92.16,
    "close": 92.68,
    "volume": 4726900
  },
  {
    "time": "30-12-2003",
    "open": 93.27,
    "high": 93.5,
    "low": 92.36,
    "close": 92.63,
    "volume": 4003400
  },
  {
    "time": "29-12-2003",
    "open": 93.1,
    "high": 93.73,
    "low": 93.03,
    "close": 93.52,
    "volume": 4033900
  },
  {
    "time": "26-12-2003",
    "open": 92.37,
    "high": 93,
    "low": 92.3,
    "close": 92.9,
    "volume": 1408500
  },
  {
    "time": "24-12-2003",
    "open": 92.8,
    "high": 92.8,
    "low": 92.18,
    "close": 92.27,
    "volume": 1760500
  },
  {
    "time": "23-12-2003",
    "open": 93.07,
    "high": 93.44,
    "low": 92.34,
    "close": 92.79,
    "volume": 3677000
  },
  {
    "time": "22-12-2003",
    "open": 92.83,
    "high": 93.5,
    "low": 92.78,
    "close": 93.39,
    "volume": 4332100
  },
  {
    "time": "19-12-2003",
    "open": 93.23,
    "high": 93.25,
    "low": 92.67,
    "close": 93.14,
    "volume": 7090500
  },
  {
    "time": "18-12-2003",
    "open": 93,
    "high": 93.38,
    "low": 92.5,
    "close": 92.73,
    "volume": 6873600
  },
  {
    "time": "17-12-2003",
    "open": 93.85,
    "high": 93.87,
    "low": 92.85,
    "close": 93.4,
    "volume": 6052900
  },
  {
    "time": "16-12-2003",
    "open": 92.11,
    "high": 94.12,
    "low": 91.88,
    "close": 93.98,
    "volume": 7501100
  },
  {
    "time": "15-12-2003",
    "open": 93.43,
    "high": 93.97,
    "low": 92.08,
    "close": 92.11,
    "volume": 5964500
  },
  {
    "time": "12-12-2003",
    "open": 92.7,
    "high": 93.16,
    "low": 92.24,
    "close": 92.71,
    "volume": 5079800
  },
  {
    "time": "11-12-2003",
    "open": 91.96,
    "high": 92.97,
    "low": 91.92,
    "close": 92.4,
    "volume": 6412100
  },
  {
    "time": "10-12-2003",
    "open": 90.69,
    "high": 92.74,
    "low": 90.65,
    "close": 91.73,
    "volume": 7138000
  },
  {
    "time": "09-12-2003",
    "open": 91.15,
    "high": 92.16,
    "low": 90.61,
    "close": 90.63,
    "volume": 6971500
  },
  {
    "time": "08-12-2003",
    "open": 90.52,
    "high": 91.47,
    "low": 90.52,
    "close": 91.2,
    "volume": 5342000
  },
  {
    "time": "05-12-2003",
    "open": 91.3,
    "high": 91.3,
    "low": 90.5,
    "close": 90.64,
    "volume": 5878400
  },
  {
    "time": "04-12-2003",
    "open": 90.05,
    "high": 91.44,
    "low": 90.03,
    "close": 91.42,
    "volume": 5360400
  },
  {
    "time": "03-12-2003",
    "open": 91.15,
    "high": 91.44,
    "low": 90.3,
    "close": 90.3,
    "volume": 6169800
  },
  {
    "time": "02-12-2003",
    "open": 90.57,
    "high": 91.48,
    "low": 90.52,
    "close": 90.75,
    "volume": 5091800
  },
  {
    "time": "01-12-2003",
    "open": 90.9,
    "high": 91.36,
    "low": 90.48,
    "close": 91.01,
    "volume": 5234900
  },
  {
    "time": "28-11-2003",
    "open": 89.9,
    "high": 90.68,
    "low": 89.62,
    "close": 90.54,
    "volume": 2195500
  },
  {
    "time": "26-11-2003",
    "open": 89.79,
    "high": 90.09,
    "low": 89.11,
    "close": 89.91,
    "volume": 4146800
  },
  {
    "time": "25-11-2003",
    "open": 89.66,
    "high": 89.85,
    "low": 89.27,
    "close": 89.43,
    "volume": 4305700
  },
  {
    "time": "24-11-2003",
    "open": 89.03,
    "high": 89.74,
    "low": 88.95,
    "close": 89.66,
    "volume": 4722200
  },
  {
    "time": "21-11-2003",
    "open": 88.4,
    "high": 88.85,
    "low": 88.23,
    "close": 88.63,
    "volume": 5935400
  },
  {
    "time": "20-11-2003",
    "open": 89.3,
    "high": 89.79,
    "low": 88.38,
    "close": 88.39,
    "volume": 5111000
  },
  {
    "time": "19-11-2003",
    "open": 88.32,
    "high": 89.83,
    "low": 88.32,
    "close": 89.4,
    "volume": 4156600
  },
  {
    "time": "18-11-2003",
    "open": 89.92,
    "high": 90.18,
    "low": 88.9,
    "close": 88.95,
    "volume": 5284300
  },
  {
    "time": "17-11-2003",
    "open": 89.9,
    "high": 90.05,
    "low": 89.03,
    "close": 89.8,
    "volume": 4748200
  },
  {
    "time": "14-11-2003",
    "open": 91.02,
    "high": 91.42,
    "low": 89.81,
    "close": 90.25,
    "volume": 4510200
  },
  {
    "time": "13-11-2003",
    "open": 90.4,
    "high": 91.48,
    "low": 90.16,
    "close": 91.09,
    "volume": 5277900
  },
  {
    "time": "12-11-2003",
    "open": 89.45,
    "high": 91.16,
    "low": 89.31,
    "close": 90.69,
    "volume": 5901700
  },
  {
    "time": "11-11-2003",
    "open": 90,
    "high": 90.03,
    "low": 88.89,
    "close": 89.36,
    "volume": 4841200
  },
  {
    "time": "10-11-2003",
    "open": 89.55,
    "high": 90.47,
    "low": 89.55,
    "close": 89.95,
    "volume": 8349700
  },
  {
    "time": "07-11-2003",
    "open": 89.4,
    "high": 89.6,
    "low": 88.26,
    "close": 88.26,
    "volume": 5543500
  },
  {
    "time": "06-11-2003",
    "open": 88.33,
    "high": 89.59,
    "low": 87.72,
    "close": 89.34,
    "volume": 5668600
  },
  {
    "time": "05-11-2003",
    "open": 88.52,
    "high": 88.88,
    "low": 88,
    "close": 88.49,
    "volume": 6296300
  },
  {
    "time": "04-11-2003",
    "open": 89.68,
    "high": 89.8,
    "low": 88.86,
    "close": 89.14,
    "volume": 5146100
  },
  {
    "time": "03-11-2003",
    "open": 89.9,
    "high": 90.84,
    "low": 89.48,
    "close": 89.68,
    "volume": 5825200
  },
  {
    "time": "31-10-2003",
    "open": 90.71,
    "high": 90.71,
    "low": 89.2,
    "close": 89.48,
    "volume": 4836200
  },
  {
    "time": "30-10-2003",
    "open": 90,
    "high": 90.17,
    "low": 89.06,
    "close": 89.12,
    "volume": 6535700
  },
  {
    "time": "29-10-2003",
    "open": 89.6,
    "high": 89.94,
    "low": 89.15,
    "close": 89.34,
    "volume": 5180700
  },
  {
    "time": "28-10-2003",
    "open": 89.05,
    "high": 89.9,
    "low": 88.52,
    "close": 89.9,
    "volume": 6959000
  },
  {
    "time": "27-10-2003",
    "open": 88.8,
    "high": 89.1,
    "low": 88.05,
    "close": 88.52,
    "volume": 5099200
  },
  {
    "time": "24-10-2003",
    "open": 88.15,
    "high": 88.64,
    "low": 87.72,
    "close": 88.42,
    "volume": 5471700
  },
  {
    "time": "23-10-2003",
    "open": 87.6,
    "high": 88.4,
    "low": 87.53,
    "close": 88.15,
    "volume": 6046400
  },
  {
    "time": "22-10-2003",
    "open": 88.3,
    "high": 89.01,
    "low": 87.9,
    "close": 88.46,
    "volume": 5600100
  },
  {
    "time": "21-10-2003",
    "open": 89.25,
    "high": 89.34,
    "low": 88.7,
    "close": 88.93,
    "volume": 5287100
  },
  {
    "time": "20-10-2003",
    "open": 89.35,
    "high": 89.4,
    "low": 88.86,
    "close": 89,
    "volume": 6971300
  },
  {
    "time": "17-10-2003",
    "open": 89.28,
    "high": 89.68,
    "low": 88.81,
    "close": 89.23,
    "volume": 9506200
  },
  {
    "time": "16-10-2003",
    "open": 89.27,
    "high": 89.7,
    "low": 88.66,
    "close": 89.28,
    "volume": 16994000
  },
  {
    "time": "15-10-2003",
    "open": 93.9,
    "high": 94.54,
    "low": 92.74,
    "close": 92.74,
    "volume": 11558700
  },
  {
    "time": "14-10-2003",
    "open": 92.65,
    "high": 92.95,
    "low": 92.32,
    "close": 92.72,
    "volume": 4699500
  },
  {
    "time": "13-10-2003",
    "open": 93.1,
    "high": 93.54,
    "low": 92.58,
    "close": 93.2,
    "volume": 4839200
  },
  {
    "time": "10-10-2003",
    "open": 92.4,
    "high": 93.18,
    "low": 92.36,
    "close": 92.67,
    "volume": 4500500
  },
  {
    "time": "09-10-2003",
    "open": 93.3,
    "high": 93.6,
    "low": 92.2,
    "close": 92.45,
    "volume": 7593000
  },
  {
    "time": "08-10-2003",
    "open": 92,
    "high": 92.87,
    "low": 91.95,
    "close": 92.66,
    "volume": 6418900
  },
  {
    "time": "07-10-2003",
    "open": 90.5,
    "high": 91.7,
    "low": 90.4,
    "close": 91.7,
    "volume": 5369800
  },
  {
    "time": "06-10-2003",
    "open": 90.74,
    "high": 91.76,
    "low": 90.51,
    "close": 91.18,
    "volume": 3845400
  },
  {
    "time": "03-10-2003",
    "open": 91,
    "high": 91.95,
    "low": 90.57,
    "close": 90.64,
    "volume": 7479300
  },
  {
    "time": "02-10-2003",
    "open": 89.55,
    "high": 90.45,
    "low": 89.22,
    "close": 90.08,
    "volume": 7428300
  },
  {
    "time": "01-10-2003",
    "open": 88.75,
    "high": 90.6,
    "low": 88.7,
    "close": 90.35,
    "volume": 7925200
  },
  {
    "time": "30-09-2003",
    "open": 89.2,
    "high": 89.25,
    "low": 87.9,
    "close": 88.33,
    "volume": 7056700
  },
  {
    "time": "29-09-2003",
    "open": 89.25,
    "high": 90.18,
    "low": 89.07,
    "close": 89.45,
    "volume": 5723200
  },
  {
    "time": "26-09-2003",
    "open": 89.41,
    "high": 90.14,
    "low": 89,
    "close": 89.05,
    "volume": 5095800
  },
  {
    "time": "25-09-2003",
    "open": 89.4,
    "high": 90.97,
    "low": 89.2,
    "close": 89.41,
    "volume": 6585500
  },
  {
    "time": "24-09-2003",
    "open": 91.08,
    "high": 91.82,
    "low": 89.33,
    "close": 89.4,
    "volume": 7575800
  },
  {
    "time": "23-09-2003",
    "open": 91.5,
    "high": 92.09,
    "low": 90.56,
    "close": 91.34,
    "volume": 7935200
  },
  {
    "time": "22-09-2003",
    "open": 92.24,
    "high": 92.24,
    "low": 90.9,
    "close": 91.39,
    "volume": 7302600
  },
  {
    "time": "19-09-2003",
    "open": 92.45,
    "high": 93.47,
    "low": 91.8,
    "close": 93.28,
    "volume": 9412500
  },
  {
    "time": "18-09-2003",
    "open": 90.63,
    "high": 92.43,
    "low": 90.46,
    "close": 92,
    "volume": 8338700
  },
  {
    "time": "17-09-2003",
    "open": 90.4,
    "high": 91.94,
    "low": 90.2,
    "close": 90.63,
    "volume": 9086600
  },
  {
    "time": "16-09-2003",
    "open": 88.49,
    "high": 90.41,
    "low": 88.49,
    "close": 90.29,
    "volume": 7896100
  },
  {
    "time": "15-09-2003",
    "open": 89.7,
    "high": 89.96,
    "low": 88.39,
    "close": 88.49,
    "volume": 7305400
  },
  {
    "time": "12-09-2003",
    "open": 87.6,
    "high": 89.01,
    "low": 87.2,
    "close": 88.7,
    "volume": 7209200
  },
  {
    "time": "11-09-2003",
    "open": 86.75,
    "high": 88.42,
    "low": 86.4,
    "close": 87.92,
    "volume": 9753500
  },
  {
    "time": "10-09-2003",
    "open": 88.99,
    "high": 89,
    "low": 87.61,
    "close": 87.84,
    "volume": 7322600
  },
  {
    "time": "09-09-2003",
    "open": 89.2,
    "high": 89.97,
    "low": 88.93,
    "close": 89.42,
    "volume": 8102500
  },
  {
    "time": "08-09-2003",
    "open": 88.45,
    "high": 89.66,
    "low": 88.35,
    "close": 89.1,
    "volume": 9213400
  },
  {
    "time": "05-09-2003",
    "open": 87,
    "high": 88.26,
    "low": 86.75,
    "close": 86.95,
    "volume": 7896600
  },
  {
    "time": "04-09-2003",
    "open": 86.33,
    "high": 88.29,
    "low": 85.69,
    "close": 87.91,
    "volume": 9889100
  },
  {
    "time": "03-09-2003",
    "open": 86.24,
    "high": 87.3,
    "low": 86,
    "close": 86.33,
    "volume": 10835700
  },
  {
    "time": "02-09-2003",
    "open": 82.4,
    "high": 85.96,
    "low": 82.3,
    "close": 85.76,
    "volume": 12406400
  },
  {
    "time": "29-08-2003",
    "open": 81.95,
    "high": 82.11,
    "low": 81.56,
    "close": 82.01,
    "volume": 4222700
  },
  {
    "time": "28-08-2003",
    "open": 82,
    "high": 82.34,
    "low": 81.27,
    "close": 81.9,
    "volume": 5516300
  },
  {
    "time": "27-08-2003",
    "open": 82,
    "high": 82.53,
    "low": 81.9,
    "close": 82,
    "volume": 3834100
  },
  {
    "time": "26-08-2003",
    "open": 81.65,
    "high": 82.54,
    "low": 81.27,
    "close": 82.5,
    "volume": 5569600
  },
  {
    "time": "25-08-2003",
    "open": 82.85,
    "high": 83.03,
    "low": 81.75,
    "close": 81.96,
    "volume": 5150900
  },
  {
    "time": "22-08-2003",
    "open": 84.25,
    "high": 84.72,
    "low": 82.96,
    "close": 82.97,
    "volume": 7434900
  },
  {
    "time": "21-08-2003",
    "open": 83.39,
    "high": 83.85,
    "low": 82.85,
    "close": 82.85,
    "volume": 5185200
  },
  {
    "time": "20-08-2003",
    "open": 82,
    "high": 83.31,
    "low": 82,
    "close": 83.05,
    "volume": 4829900
  },
  {
    "time": "19-08-2003",
    "open": 83.1,
    "high": 83.81,
    "low": 82.56,
    "close": 82.85,
    "volume": 6318300
  },
  {
    "time": "18-08-2003",
    "open": 82.05,
    "high": 83.68,
    "low": 81.9,
    "close": 83.52,
    "volume": 6847200
  },
  {
    "time": "15-08-2003",
    "open": 81.8,
    "high": 81.98,
    "low": 81.2,
    "close": 81.79,
    "volume": 3623600
  },
  {
    "time": "14-08-2003",
    "open": 81,
    "high": 81.75,
    "low": 80.69,
    "close": 81.56,
    "volume": 4562700
  },
  {
    "time": "13-08-2003",
    "open": 81.65,
    "high": 82.19,
    "low": 80.58,
    "close": 81.2,
    "volume": 5138400
  },
  {
    "time": "12-08-2003",
    "open": 81.45,
    "high": 81.54,
    "low": 80.65,
    "close": 81.51,
    "volume": 4634200
  },
  {
    "time": "11-08-2003",
    "open": 80.87,
    "high": 81.5,
    "low": 80.28,
    "close": 81.02,
    "volume": 4261200
  },
  {
    "time": "08-08-2003",
    "open": 81.05,
    "high": 81.27,
    "low": 80.35,
    "close": 80.88,
    "volume": 4613800
  },
  {
    "time": "07-08-2003",
    "open": 79.69,
    "high": 80.82,
    "low": 79.38,
    "close": 80.69,
    "volume": 5298500
  },
  {
    "time": "06-08-2003",
    "open": 79.7,
    "high": 80.68,
    "low": 78.73,
    "close": 79.75,
    "volume": 7472200
  },
  {
    "time": "05-08-2003",
    "open": 81.13,
    "high": 81.33,
    "low": 79.8,
    "close": 79.85,
    "volume": 7018100
  },
  {
    "time": "04-08-2003",
    "open": 80.75,
    "high": 81.36,
    "low": 80.06,
    "close": 81.13,
    "volume": 5888000
  },
  {
    "time": "01-08-2003",
    "open": 81.15,
    "high": 81.27,
    "low": 80.05,
    "close": 81.27,
    "volume": 6475400
  },
  {
    "time": "31-07-2003",
    "open": 81.8,
    "high": 82.54,
    "low": 81.1,
    "close": 81.25,
    "volume": 8050300
  },
  {
    "time": "30-07-2003",
    "open": 82.45,
    "high": 82.45,
    "low": 80.58,
    "close": 80.96,
    "volume": 6654600
  },
  {
    "time": "29-07-2003",
    "open": 82.55,
    "high": 82.88,
    "low": 81.6,
    "close": 81.8,
    "volume": 7282700
  },
  {
    "time": "28-07-2003",
    "open": 83.67,
    "high": 83.7,
    "low": 82.04,
    "close": 82.54,
    "volume": 6423500
  },
  {
    "time": "25-07-2003",
    "open": 81.6,
    "high": 83.74,
    "low": 81.57,
    "close": 83.55,
    "volume": 6204700
  },
  {
    "time": "24-07-2003",
    "open": 82.95,
    "high": 82.99,
    "low": 81.51,
    "close": 81.51,
    "volume": 5859100
  },
  {
    "time": "23-07-2003",
    "open": 82,
    "high": 82.77,
    "low": 81.73,
    "close": 82.3,
    "volume": 5326800
  },
  {
    "time": "22-07-2003",
    "open": 82.5,
    "high": 83.26,
    "low": 81.52,
    "close": 81.85,
    "volume": 8744300
  },
  {
    "time": "21-07-2003",
    "open": 83.2,
    "high": 83.43,
    "low": 82.21,
    "close": 82.5,
    "volume": 7036100
  },
  {
    "time": "18-07-2003",
    "open": 83.85,
    "high": 84.02,
    "low": 83.21,
    "close": 83.72,
    "volume": 8567200
  },
  {
    "time": "17-07-2003",
    "open": 82.7,
    "high": 83.94,
    "low": 82.5,
    "close": 83.33,
    "volume": 13515700
  },
  {
    "time": "16-07-2003",
    "open": 87,
    "high": 87.03,
    "low": 85.85,
    "close": 86.74,
    "volume": 8837600
  },
  {
    "time": "15-07-2003",
    "open": 86.15,
    "high": 87,
    "low": 85.82,
    "close": 86.44,
    "volume": 7910400
  },
  {
    "time": "14-07-2003",
    "open": 86,
    "high": 86.58,
    "low": 85.42,
    "close": 85.42,
    "volume": 6455200
  },
  {
    "time": "11-07-2003",
    "open": 84.1,
    "high": 85.28,
    "low": 83.7,
    "close": 84.89,
    "volume": 4534700
  },
  {
    "time": "10-07-2003",
    "open": 85,
    "high": 85.22,
    "low": 83.47,
    "close": 84.03,
    "volume": 7509200
  },
  {
    "time": "09-07-2003",
    "open": 86.1,
    "high": 86.35,
    "low": 84.85,
    "close": 85.47,
    "volume": 6709700
  },
  {
    "time": "08-07-2003",
    "open": 86.09,
    "high": 86.75,
    "low": 85.19,
    "close": 86.25,
    "volume": 5873900
  },
  {
    "time": "07-07-2003",
    "open": 84.8,
    "high": 86.45,
    "low": 84.74,
    "close": 86.09,
    "volume": 7614900
  },
  {
    "time": "03-07-2003",
    "open": 84.25,
    "high": 84.69,
    "low": 83.48,
    "close": 83.95,
    "volume": 3009500
  },
  {
    "time": "02-07-2003",
    "open": 83.95,
    "high": 84.89,
    "low": 83.75,
    "close": 84.74,
    "volume": 6009700
  },
  {
    "time": "01-07-2003",
    "open": 81.4,
    "high": 83.66,
    "low": 81.4,
    "close": 83.59,
    "volume": 5785800
  },
  {
    "time": "30-06-2003",
    "open": 83.9,
    "high": 84.2,
    "low": 82.5,
    "close": 82.5,
    "volume": 7950900
  },
  {
    "time": "27-06-2003",
    "open": 84.15,
    "high": 84.65,
    "low": 83.22,
    "close": 83.42,
    "volume": 4532900
  },
  {
    "time": "26-06-2003",
    "open": 83.7,
    "high": 84.38,
    "low": 82.82,
    "close": 84.35,
    "volume": 6374400
  },
  {
    "time": "25-06-2003",
    "open": 83.55,
    "high": 84.16,
    "low": 82.09,
    "close": 82.49,
    "volume": 5548000
  },
  {
    "time": "24-06-2003",
    "open": 82.95,
    "high": 84.18,
    "low": 82.85,
    "close": 83.63,
    "volume": 5062800
  },
  {
    "time": "23-06-2003",
    "open": 84.79,
    "high": 84.79,
    "low": 82.97,
    "close": 83.18,
    "volume": 6311600
  },
  {
    "time": "20-06-2003",
    "open": 85,
    "high": 85.24,
    "low": 84.69,
    "close": 84.92,
    "volume": 11900000
  },
  {
    "time": "19-06-2003",
    "open": 84.95,
    "high": 85.29,
    "low": 84.5,
    "close": 84.55,
    "volume": 7277100
  },
  {
    "time": "18-06-2003",
    "open": 84.15,
    "high": 84.99,
    "low": 83.22,
    "close": 84.7,
    "volume": 5941400
  },
  {
    "time": "17-06-2003",
    "open": 84.95,
    "high": 84.95,
    "low": 83.8,
    "close": 84.3,
    "volume": 5873700
  },
  {
    "time": "16-06-2003",
    "open": 83.1,
    "high": 84.55,
    "low": 82.55,
    "close": 84.5,
    "volume": 6492100
  },
  {
    "time": "13-06-2003",
    "open": 84.15,
    "high": 84.29,
    "low": 82.51,
    "close": 82.75,
    "volume": 5727500
  },
  {
    "time": "12-06-2003",
    "open": 84.8,
    "high": 84.98,
    "low": 83.55,
    "close": 83.95,
    "volume": 7072400
  },
  {
    "time": "11-06-2003",
    "open": 82.75,
    "high": 84.15,
    "low": 82.29,
    "close": 83.97,
    "volume": 9815800
  },
  {
    "time": "10-06-2003",
    "open": 82.35,
    "high": 82.92,
    "low": 81.21,
    "close": 81.71,
    "volume": 8896000
  },
  {
    "time": "09-06-2003",
    "open": 80,
    "high": 82,
    "low": 79.81,
    "close": 82,
    "volume": 9756300
  },
  {
    "time": "06-06-2003",
    "open": 82.25,
    "high": 83.23,
    "low": 79.84,
    "close": 80.05,
    "volume": 15160900
  },
  {
    "time": "05-06-2003",
    "open": 84,
    "high": 84.25,
    "low": 81.75,
    "close": 81.9,
    "volume": 15256300
  },
  {
    "time": "04-06-2003",
    "open": 84,
    "high": 84.96,
    "low": 83.83,
    "close": 84.25,
    "volume": 10734400
  },
  {
    "time": "03-06-2003",
    "open": 84.32,
    "high": 85.24,
    "low": 83.24,
    "close": 83.82,
    "volume": 21106600
  },
  {
    "time": "02-06-2003",
    "open": 89,
    "high": 89.08,
    "low": 87.25,
    "close": 87.33,
    "volume": 7697200
  },
  {
    "time": "30-05-2003",
    "open": 87.45,
    "high": 88.68,
    "low": 87.45,
    "close": 88.04,
    "volume": 6184200
  },
  {
    "time": "29-05-2003",
    "open": 87.57,
    "high": 88.59,
    "low": 87.2,
    "close": 87.36,
    "volume": 6898900
  },
  {
    "time": "28-05-2003",
    "open": 87.6,
    "high": 88.7,
    "low": 87.4,
    "close": 87.57,
    "volume": 6539900
  },
  {
    "time": "27-05-2003",
    "open": 84.51,
    "high": 87.9,
    "low": 84.51,
    "close": 87.69,
    "volume": 7163400
  },
  {
    "time": "23-05-2003",
    "open": 85.75,
    "high": 85.95,
    "low": 85.1,
    "close": 85.26,
    "volume": 5187200
  },
  {
    "time": "22-05-2003",
    "open": 86.25,
    "high": 86.8,
    "low": 85.8,
    "close": 86.08,
    "volume": 6277300
  },
  {
    "time": "21-05-2003",
    "open": 85.77,
    "high": 86.18,
    "low": 85.08,
    "close": 86.18,
    "volume": 6741000
  },
  {
    "time": "20-05-2003",
    "open": 86.58,
    "high": 87.24,
    "low": 84.89,
    "close": 85.77,
    "volume": 7331900
  },
  {
    "time": "19-05-2003",
    "open": 88.05,
    "high": 88.47,
    "low": 86.41,
    "close": 86.45,
    "volume": 7298400
  },
  {
    "time": "16-05-2003",
    "open": 89.67,
    "high": 89.89,
    "low": 88.95,
    "close": 88.99,
    "volume": 6831600
  },
  {
    "time": "15-05-2003",
    "open": 89.5,
    "high": 89.96,
    "low": 89,
    "close": 89.9,
    "volume": 7102900
  },
  {
    "time": "14-05-2003",
    "open": 90,
    "high": 90.4,
    "low": 88.3,
    "close": 88.7,
    "volume": 7752200
  },
  {
    "time": "13-05-2003",
    "open": 88.78,
    "high": 90.1,
    "low": 88.56,
    "close": 89.98,
    "volume": 8668300
  },
  {
    "time": "12-05-2003",
    "open": 87.55,
    "high": 89.26,
    "low": 87.1,
    "close": 89,
    "volume": 8445700
  },
  {
    "time": "09-05-2003",
    "open": 86.9,
    "high": 87.7,
    "low": 86.4,
    "close": 87.55,
    "volume": 6222600
  },
  {
    "time": "08-05-2003",
    "open": 86.37,
    "high": 86.78,
    "low": 85.75,
    "close": 86.05,
    "volume": 7188200
  },
  {
    "time": "07-05-2003",
    "open": 86.95,
    "high": 87.81,
    "low": 86.5,
    "close": 86.68,
    "volume": 6754600
  },
  {
    "time": "06-05-2003",
    "open": 86.3,
    "high": 88.34,
    "low": 86,
    "close": 87.51,
    "volume": 7459600
  },
  {
    "time": "05-05-2003",
    "open": 87.2,
    "high": 88,
    "low": 86.52,
    "close": 86.52,
    "volume": 7632500
  },
  {
    "time": "02-05-2003",
    "open": 84.45,
    "high": 87.57,
    "low": 84.45,
    "close": 87.57,
    "volume": 8381900
  },
  {
    "time": "01-05-2003",
    "open": 85.3,
    "high": 86.23,
    "low": 84.47,
    "close": 85.89,
    "volume": 7485700
  },
  {
    "time": "30-04-2003",
    "open": 85.66,
    "high": 85.67,
    "low": 84.68,
    "close": 84.9,
    "volume": 8444900
  },
  {
    "time": "29-04-2003",
    "open": 85.34,
    "high": 86.49,
    "low": 84.97,
    "close": 85.66,
    "volume": 8682900
  },
  {
    "time": "28-04-2003",
    "open": 84.05,
    "high": 85.5,
    "low": 83.62,
    "close": 85.12,
    "volume": 6363600
  },
  {
    "time": "25-04-2003",
    "open": 85.21,
    "high": 85.28,
    "low": 83.86,
    "close": 83.88,
    "volume": 6790700
  },
  {
    "time": "24-04-2003",
    "open": 84.65,
    "high": 85.7,
    "low": 84.63,
    "close": 85.2,
    "volume": 5943500
  },
  {
    "time": "23-04-2003",
    "open": 85.24,
    "high": 85.97,
    "low": 84.96,
    "close": 85.75,
    "volume": 7084500
  },
  {
    "time": "22-04-2003",
    "open": 83.36,
    "high": 86.06,
    "low": 82.6,
    "close": 85.63,
    "volume": 9542600
  },
  {
    "time": "21-04-2003",
    "open": 84.4,
    "high": 84.51,
    "low": 83.1,
    "close": 83.36,
    "volume": 6703400
  },
  {
    "time": "17-04-2003",
    "open": 81.89,
    "high": 84.4,
    "low": 81.89,
    "close": 84.26,
    "volume": 8802100
  },
  {
    "time": "16-04-2003",
    "open": 83.38,
    "high": 84.4,
    "low": 82.74,
    "close": 82.88,
    "volume": 10790700
  },
  {
    "time": "15-04-2003",
    "open": 80.07,
    "high": 82.85,
    "low": 79.71,
    "close": 82.79,
    "volume": 13613500
  },
  {
    "time": "14-04-2003",
    "open": 78.5,
    "high": 80.07,
    "low": 78.16,
    "close": 80.07,
    "volume": 9006000
  },
  {
    "time": "11-04-2003",
    "open": 80.1,
    "high": 81.29,
    "low": 78.45,
    "close": 78.75,
    "volume": 7573500
  },
  {
    "time": "10-04-2003",
    "open": 79.1,
    "high": 79.25,
    "low": 78.13,
    "close": 79.01,
    "volume": 6880100
  },
  {
    "time": "09-04-2003",
    "open": 79.7,
    "high": 80.74,
    "low": 78.63,
    "close": 78.71,
    "volume": 8543200
  },
  {
    "time": "08-04-2003",
    "open": 80.62,
    "high": 80.7,
    "low": 78.47,
    "close": 80.07,
    "volume": 8057600
  },
  {
    "time": "07-04-2003",
    "open": 82.6,
    "high": 82.9,
    "low": 80.25,
    "close": 80.47,
    "volume": 8881400
  },
  {
    "time": "04-04-2003",
    "open": 82.35,
    "high": 82.55,
    "low": 80.04,
    "close": 80.79,
    "volume": 8985000
  },
  {
    "time": "03-04-2003",
    "open": 82.32,
    "high": 83.48,
    "low": 81.71,
    "close": 81.91,
    "volume": 9623600
  },
  {
    "time": "02-04-2003",
    "open": 79.9,
    "high": 81.97,
    "low": 79.9,
    "close": 81.46,
    "volume": 9700100
  },
  {
    "time": "01-04-2003",
    "open": 78.5,
    "high": 79.56,
    "low": 78.12,
    "close": 78.73,
    "volume": 7861400
  },
  {
    "time": "31-03-2003",
    "open": 79.26,
    "high": 79.61,
    "low": 78.31,
    "close": 78.43,
    "volume": 10169200
  },
  {
    "time": "28-03-2003",
    "open": 81.05,
    "high": 81.99,
    "low": 80.5,
    "close": 80.85,
    "volume": 6649200
  },
  {
    "time": "27-03-2003",
    "open": 80.75,
    "high": 82.33,
    "low": 80.51,
    "close": 81.45,
    "volume": 7265800
  },
  {
    "time": "26-03-2003",
    "open": 83,
    "high": 83.25,
    "low": 81.18,
    "close": 81.55,
    "volume": 10531600
  },
  {
    "time": "25-03-2003",
    "open": 82,
    "high": 84,
    "low": 81.74,
    "close": 83.45,
    "volume": 8777100
  },
  {
    "time": "24-03-2003",
    "open": 82.46,
    "high": 83.35,
    "low": 82.23,
    "close": 82.25,
    "volume": 9352600
  },
  {
    "time": "21-03-2003",
    "open": 82.76,
    "high": 84.9,
    "low": 82.76,
    "close": 84.9,
    "volume": 12625400
  },
  {
    "time": "20-03-2003",
    "open": 81.71,
    "high": 82.49,
    "low": 80.64,
    "close": 82.2,
    "volume": 9046400
  },
  {
    "time": "19-03-2003",
    "open": 82.09,
    "high": 82.5,
    "low": 81.08,
    "close": 82,
    "volume": 10473100
  },
  {
    "time": "18-03-2003",
    "open": 82.46,
    "high": 83.5,
    "low": 81.49,
    "close": 82.47,
    "volume": 10795400
  },
  {
    "time": "17-03-2003",
    "open": 78,
    "high": 82.67,
    "low": 77.84,
    "close": 82.46,
    "volume": 15193000
  },
  {
    "time": "14-03-2003",
    "open": 78.45,
    "high": 79.48,
    "low": 78.11,
    "close": 79,
    "volume": 10117700
  },
  {
    "time": "13-03-2003",
    "open": 75.99,
    "high": 78.68,
    "low": 75.31,
    "close": 78.45,
    "volume": 10452500
  },
  {
    "time": "12-03-2003",
    "open": 75.25,
    "high": 75.63,
    "low": 73.17,
    "close": 75.18,
    "volume": 12773700
  },
  {
    "time": "11-03-2003",
    "open": 75.82,
    "high": 76.33,
    "low": 75.2,
    "close": 75.35,
    "volume": 8119200
  },
  {
    "time": "10-03-2003",
    "open": 77.45,
    "high": 77.45,
    "low": 75.5,
    "close": 75.7,
    "volume": 6641300
  },
  {
    "time": "07-03-2003",
    "open": 75.71,
    "high": 77.99,
    "low": 75.71,
    "close": 77.9,
    "volume": 8129200
  },
  {
    "time": "06-03-2003",
    "open": 77,
    "high": 77.78,
    "low": 76.7,
    "close": 77.07,
    "volume": 5876300
  },
  {
    "time": "05-03-2003",
    "open": 76.7,
    "high": 77.73,
    "low": 76.25,
    "close": 77.73,
    "volume": 6658000
  },
  {
    "time": "04-03-2003",
    "open": 77.6,
    "high": 77.75,
    "low": 76.53,
    "close": 76.7,
    "volume": 5672200
  },
  {
    "time": "03-03-2003",
    "open": 78.9,
    "high": 79,
    "low": 77.12,
    "close": 77.33,
    "volume": 6618300
  },
  {
    "time": "28-02-2003",
    "open": 77,
    "high": 78.47,
    "low": 77,
    "close": 77.95,
    "volume": 6585200
  },
  {
    "time": "27-02-2003",
    "open": 77.9,
    "high": 78.59,
    "low": 76.75,
    "close": 77.28,
    "volume": 9926500
  },
  {
    "time": "26-02-2003",
    "open": 78.45,
    "high": 79.05,
    "low": 77.3,
    "close": 77.4,
    "volume": 8587000
  },
  {
    "time": "25-02-2003",
    "open": 77.9,
    "high": 79.37,
    "low": 76.9,
    "close": 79.07,
    "volume": 7675900
  },
  {
    "time": "24-02-2003",
    "open": 79.7,
    "high": 79.99,
    "low": 78.56,
    "close": 78.56,
    "volume": 6874700
  },
  {
    "time": "21-02-2003",
    "open": 79.49,
    "high": 80,
    "low": 78.16,
    "close": 79.95,
    "volume": 7453900
  },
  {
    "time": "20-02-2003",
    "open": 79.57,
    "high": 80.05,
    "low": 78.71,
    "close": 79.15,
    "volume": 6285100
  },
  {
    "time": "19-02-2003",
    "open": 79.08,
    "high": 79.7,
    "low": 78.82,
    "close": 79.51,
    "volume": 5440300
  },
  {
    "time": "18-02-2003",
    "open": 78.02,
    "high": 79.5,
    "low": 77.91,
    "close": 79.33,
    "volume": 7791200
  },
  {
    "time": "14-02-2003",
    "open": 76.4,
    "high": 77.45,
    "low": 75.35,
    "close": 77.45,
    "volume": 8299500
  },
  {
    "time": "13-02-2003",
    "open": 76.1,
    "high": 76.35,
    "low": 74.31,
    "close": 75.86,
    "volume": 11218400
  },
  {
    "time": "12-02-2003",
    "open": 77.5,
    "high": 78.09,
    "low": 76.5,
    "close": 76.5,
    "volume": 5609200
  },
  {
    "time": "11-02-2003",
    "open": 78.3,
    "high": 78.97,
    "low": 77.31,
    "close": 77.39,
    "volume": 8598200
  },
  {
    "time": "10-02-2003",
    "open": 77.1,
    "high": 78.02,
    "low": 76.45,
    "close": 77.91,
    "volume": 7949100
  },
  {
    "time": "07-02-2003",
    "open": 77.97,
    "high": 78.15,
    "low": 76.61,
    "close": 77.1,
    "volume": 8296400
  },
  {
    "time": "06-02-2003",
    "open": 77.11,
    "high": 78.2,
    "low": 76.51,
    "close": 77.51,
    "volume": 7755200
  },
  {
    "time": "05-02-2003",
    "open": 77.85,
    "high": 78.9,
    "low": 76.87,
    "close": 77.26,
    "volume": 7427200
  },
  {
    "time": "04-02-2003",
    "open": 78.18,
    "high": 78.18,
    "low": 76.25,
    "close": 77.09,
    "volume": 7216200
  },
  {
    "time": "03-02-2003",
    "open": 78.2,
    "high": 78.87,
    "low": 77.75,
    "close": 78.18,
    "volume": 6683500
  },
  {
    "time": "31-01-2003",
    "open": 77.7,
    "high": 78.34,
    "low": 77.02,
    "close": 78.2,
    "volume": 9744100
  },
  {
    "time": "30-01-2003",
    "open": 81.3,
    "high": 81.3,
    "low": 78.3,
    "close": 78.3,
    "volume": 7349500
  },
  {
    "time": "29-01-2003",
    "open": 79.5,
    "high": 80.7,
    "low": 78.57,
    "close": 80.32,
    "volume": 7755000
  },
  {
    "time": "28-01-2003",
    "open": 78.5,
    "high": 80.47,
    "low": 78.36,
    "close": 80.11,
    "volume": 8017400
  },
  {
    "time": "27-01-2003",
    "open": 77.99,
    "high": 79.5,
    "low": 77.61,
    "close": 78.42,
    "volume": 9307800
  },
  {
    "time": "24-01-2003",
    "open": 80.6,
    "high": 81.5,
    "low": 78.82,
    "close": 78.99,
    "volume": 7748000
  },
  {
    "time": "23-01-2003",
    "open": 80.15,
    "high": 81.36,
    "low": 79.8,
    "close": 81.05,
    "volume": 8371400
  },
  {
    "time": "22-01-2003",
    "open": 80,
    "high": 80.77,
    "low": 79.39,
    "close": 79.7,
    "volume": 9432400
  },
  {
    "time": "21-01-2003",
    "open": 81.3,
    "high": 81.85,
    "low": 80.5,
    "close": 80.54,
    "volume": 8926900
  },
  {
    "time": "17-01-2003",
    "open": 83.74,
    "high": 83.74,
    "low": 81.18,
    "close": 81.3,
    "volume": 17331200
  },
  {
    "time": "16-01-2003",
    "open": 87.4,
    "high": 87.71,
    "low": 85.38,
    "close": 86.05,
    "volume": 9904300
  },
  {
    "time": "15-01-2003",
    "open": 88.58,
    "high": 88.65,
    "low": 86.95,
    "close": 87.59,
    "volume": 8146600
  },
  {
    "time": "14-01-2003",
    "open": 87.23,
    "high": 88.59,
    "low": 87.22,
    "close": 88.58,
    "volume": 7569100
  },
  {
    "time": "13-01-2003",
    "open": 88.31,
    "high": 88.95,
    "low": 87.35,
    "close": 87.51,
    "volume": 10499000
  },
  {
    "time": "10-01-2003",
    "open": 85.85,
    "high": 88.04,
    "low": 85.7,
    "close": 87.68,
    "volume": 9955400
  },
  {
    "time": "09-01-2003",
    "open": 84.75,
    "high": 87.02,
    "low": 84.75,
    "close": 87,
    "volume": 10711600
  },
  {
    "time": "08-01-2003",
    "open": 85.55,
    "high": 85.69,
    "low": 84.07,
    "close": 84.19,
    "volume": 9508800
  },
  {
    "time": "07-01-2003",
    "open": 83.95,
    "high": 86.18,
    "low": 83.75,
    "close": 86,
    "volume": 11906900
  },
  {
    "time": "06-01-2003",
    "open": 81.9,
    "high": 84.8,
    "low": 81.81,
    "close": 83.59,
    "volume": 7921300
  },
  {
    "time": "03-01-2003",
    "open": 80.7,
    "high": 81.65,
    "low": 80.21,
    "close": 81.65,
    "volume": 5962300
  },
  {
    "time": "02-01-2003",
    "open": 78.8,
    "high": 80.57,
    "low": 78.19,
    "close": 80.57,
    "volume": 7864500
  },
  {
    "time": "31-12-2002",
    "open": 77.1,
    "high": 78.46,
    "low": 77.1,
    "close": 77.5,
    "volume": 7871400
  },
  {
    "time": "30-12-2002",
    "open": 77,
    "high": 77.43,
    "low": 75.6,
    "close": 76.25,
    "volume": 8066600
  },
  {
    "time": "27-12-2002",
    "open": 78.5,
    "high": 79.18,
    "low": 76.61,
    "close": 77.36,
    "volume": 6334100
  },
  {
    "time": "26-12-2002",
    "open": 79.81,
    "high": 80.7,
    "low": 78.26,
    "close": 78.5,
    "volume": 5784300
  },
  {
    "time": "24-12-2002",
    "open": 80.2,
    "high": 81,
    "low": 79.76,
    "close": 79.76,
    "volume": 2420200
  },
  {
    "time": "23-12-2002",
    "open": 79.75,
    "high": 81.5,
    "low": 79.44,
    "close": 80.26,
    "volume": 5922200
  },
  {
    "time": "20-12-2002",
    "open": 79.4,
    "high": 80,
    "low": 78.88,
    "close": 79.79,
    "volume": 8053800
  },
  {
    "time": "19-12-2002",
    "open": 78.6,
    "high": 80.34,
    "low": 78.35,
    "close": 78.66,
    "volume": 7319900
  },
  {
    "time": "18-12-2002",
    "open": 79.25,
    "high": 79.69,
    "low": 78.24,
    "close": 79.13,
    "volume": 6514900
  },
  {
    "time": "17-12-2002",
    "open": 80.6,
    "high": 81.77,
    "low": 80.03,
    "close": 80.31,
    "volume": 6167100
  },
  {
    "time": "16-12-2002",
    "open": 79.6,
    "high": 81.79,
    "low": 79.05,
    "close": 81.62,
    "volume": 8483500
  },
  {
    "time": "13-12-2002",
    "open": 79.25,
    "high": 80,
    "low": 78.27,
    "close": 80,
    "volume": 7773800
  },
  {
    "time": "12-12-2002",
    "open": 81.25,
    "high": 81.69,
    "low": 79.7,
    "close": 80.17,
    "volume": 7208700
  },
  {
    "time": "11-12-2002",
    "open": 80.8,
    "high": 82.48,
    "low": 79.99,
    "close": 81.44,
    "volume": 6661000
  },
  {
    "time": "10-12-2002",
    "open": 78.6,
    "high": 81,
    "low": 78.6,
    "close": 80.8,
    "volume": 6869800
  },
  {
    "time": "09-12-2002",
    "open": 81,
    "high": 81.33,
    "low": 79.5,
    "close": 79.59,
    "volume": 8841900
  },
  {
    "time": "06-12-2002",
    "open": 81.5,
    "high": 82.75,
    "low": 81.07,
    "close": 82.32,
    "volume": 7594800
  },
  {
    "time": "05-12-2002",
    "open": 84.9,
    "high": 84.98,
    "low": 82.6,
    "close": 83.06,
    "volume": 6900000
  },
  {
    "time": "04-12-2002",
    "open": 83.41,
    "high": 84.86,
    "low": 82.82,
    "close": 83.69,
    "volume": 10053500
  },
  {
    "time": "03-12-2002",
    "open": 86.72,
    "high": 87.03,
    "low": 85.15,
    "close": 85.21,
    "volume": 7027800
  },
  {
    "time": "02-12-2002",
    "open": 88.6,
    "high": 89.46,
    "low": 86.3,
    "close": 87.3,
    "volume": 9694200
  },
  {
    "time": "29-11-2002",
    "open": 87.71,
    "high": 88.05,
    "low": 86.76,
    "close": 86.92,
    "volume": 2980700
  },
  {
    "time": "27-11-2002",
    "open": 85.9,
    "high": 88.11,
    "low": 85.85,
    "close": 87.7,
    "volume": 8209800
  },
  {
    "time": "26-11-2002",
    "open": 86.15,
    "high": 86.15,
    "low": 84.39,
    "close": 85.06,
    "volume": 8560800
  },
  {
    "time": "25-11-2002",
    "open": 84.63,
    "high": 86.25,
    "low": 83.52,
    "close": 86.2,
    "volume": 8651200
  },
  {
    "time": "22-11-2002",
    "open": 83.95,
    "high": 85.17,
    "low": 83.8,
    "close": 84.43,
    "volume": 9053300
  },
  {
    "time": "21-11-2002",
    "open": 82.05,
    "high": 84.92,
    "low": 82.05,
    "close": 84.9,
    "volume": 12737200
  },
  {
    "time": "20-11-2002",
    "open": 77.95,
    "high": 81.71,
    "low": 77.9,
    "close": 81.61,
    "volume": 9070900
  },
  {
    "time": "19-11-2002",
    "open": 78.24,
    "high": 79.45,
    "low": 77.84,
    "close": 78.37,
    "volume": 6918400
  },
  {
    "time": "18-11-2002",
    "open": 80.8,
    "high": 80.99,
    "low": 79.08,
    "close": 79.18,
    "volume": 7321300
  },
  {
    "time": "15-11-2002",
    "open": 80.38,
    "high": 80.4,
    "low": 79.01,
    "close": 80.01,
    "volume": 11367800
  },
  {
    "time": "14-11-2002",
    "open": 80.38,
    "high": 80.99,
    "low": 79.4,
    "close": 80.72,
    "volume": 9662800
  },
  {
    "time": "13-11-2002",
    "open": 79.5,
    "high": 80.33,
    "low": 77.98,
    "close": 79.35,
    "volume": 11984700
  },
  {
    "time": "12-11-2002",
    "open": 77.5,
    "high": 79.9,
    "low": 77.3,
    "close": 79.15,
    "volume": 9027700
  },
  {
    "time": "11-11-2002",
    "open": 77.59,
    "high": 78.36,
    "low": 76.7,
    "close": 77.29,
    "volume": 7386400
  },
  {
    "time": "08-11-2002",
    "open": 78.5,
    "high": 79.29,
    "low": 77.2,
    "close": 77.59,
    "volume": 8119600
  },
  {
    "time": "07-11-2002",
    "open": 80,
    "high": 80,
    "low": 78.32,
    "close": 78.95,
    "volume": 11036800
  },
  {
    "time": "06-11-2002",
    "open": 80.8,
    "high": 81.88,
    "low": 80.05,
    "close": 81.54,
    "volume": 10480800
  },
  {
    "time": "05-11-2002",
    "open": 81.75,
    "high": 82.01,
    "low": 80.47,
    "close": 81.68,
    "volume": 10466700
  },
  {
    "time": "04-11-2002",
    "open": 81.99,
    "high": 83.81,
    "low": 81.7,
    "close": 82.5,
    "volume": 12006000
  },
  {
    "time": "01-11-2002",
    "open": 78.9,
    "high": 80.5,
    "low": 78.32,
    "close": 80.4,
    "volume": 9395200
  },
  {
    "time": "31-10-2002",
    "open": 78.67,
    "high": 79.79,
    "low": 78.5,
    "close": 78.94,
    "volume": 9864300
  },
  {
    "time": "30-10-2002",
    "open": 77,
    "high": 79.4,
    "low": 76.02,
    "close": 78.67,
    "volume": 12865800
  },
  {
    "time": "29-10-2002",
    "open": 76.56,
    "high": 77.05,
    "low": 74.2,
    "close": 76.74,
    "volume": 11455200
  },
  {
    "time": "28-10-2002",
    "open": 75.15,
    "high": 77.5,
    "low": 74.56,
    "close": 76.56,
    "volume": 12544900
  },
  {
    "time": "25-10-2002",
    "open": 72.5,
    "high": 74.62,
    "low": 72.16,
    "close": 74.56,
    "volume": 7514200
  },
  {
    "time": "24-10-2002",
    "open": 75.5,
    "high": 75.55,
    "low": 71.75,
    "close": 72.1,
    "volume": 10524000
  },
  {
    "time": "23-10-2002",
    "open": 74.4,
    "high": 74.97,
    "low": 72.46,
    "close": 74.6,
    "volume": 12441900
  },
  {
    "time": "22-10-2002",
    "open": 74.5,
    "high": 75.68,
    "low": 73.62,
    "close": 74.49,
    "volume": 10371500
  },
  {
    "time": "21-10-2002",
    "open": 73.65,
    "high": 75.9,
    "low": 72.94,
    "close": 75.55,
    "volume": 10875700
  },
  {
    "time": "18-10-2002",
    "open": 71.73,
    "high": 74.25,
    "low": 70.26,
    "close": 74.25,
    "volume": 12482900
  },
  {
    "time": "17-10-2002",
    "open": 72.8,
    "high": 73,
    "low": 71.23,
    "close": 72.2,
    "volume": 21344600
  },
  {
    "time": "16-10-2002",
    "open": 66.6,
    "high": 67,
    "low": 64.24,
    "close": 64.9,
    "volume": 15900200
  },
  {
    "time": "15-10-2002",
    "open": 67.75,
    "high": 68.48,
    "low": 66.58,
    "close": 68.48,
    "volume": 14600200
  },
  {
    "time": "14-10-2002",
    "open": 61.54,
    "high": 63.8,
    "low": 61.54,
    "close": 63.42,
    "volume": 7925300
  },
  {
    "time": "11-10-2002",
    "open": 62,
    "high": 63.92,
    "low": 61.5,
    "close": 63.92,
    "volume": 17853800
  },
  {
    "time": "10-10-2002",
    "open": 54.65,
    "high": 58.48,
    "low": 54.01,
    "close": 57.58,
    "volume": 12670600
  },
  {
    "time": "09-10-2002",
    "open": 56.05,
    "high": 56.7,
    "low": 54.81,
    "close": 55.07,
    "volume": 12156000
  },
  {
    "time": "08-10-2002",
    "open": 57.78,
    "high": 58.09,
    "low": 54.8,
    "close": 57.05,
    "volume": 14744500
  },
  {
    "time": "07-10-2002",
    "open": 56.6,
    "high": 58.5,
    "low": 56.6,
    "close": 56.86,
    "volume": 9641600
  },
  {
    "time": "04-10-2002",
    "open": 59.5,
    "high": 59.51,
    "low": 55.77,
    "close": 56.6,
    "volume": 14551900
  },
  {
    "time": "03-10-2002",
    "open": 60.3,
    "high": 61.16,
    "low": 58.95,
    "close": 60,
    "volume": 11001100
  },
  {
    "time": "02-10-2002",
    "open": 61.18,
    "high": 62,
    "low": 58.35,
    "close": 59.63,
    "volume": 10653300
  },
  {
    "time": "01-10-2002",
    "open": 59.2,
    "high": 61.98,
    "low": 58.99,
    "close": 61.17,
    "volume": 11642800
  },
  {
    "time": "30-09-2002",
    "open": 59.55,
    "high": 59.89,
    "low": 57.99,
    "close": 58.31,
    "volume": 13020100
  },
  {
    "time": "27-09-2002",
    "open": 61.43,
    "high": 63.49,
    "low": 60.31,
    "close": 60.36,
    "volume": 8793100
  },
  {
    "time": "26-09-2002",
    "open": 64.2,
    "high": 64.88,
    "low": 61.33,
    "close": 62.02,
    "volume": 12368800
  },
  {
    "time": "25-09-2002",
    "open": 61.2,
    "high": 63.77,
    "low": 60.99,
    "close": 63.01,
    "volume": 13119200
  },
  {
    "time": "24-09-2002",
    "open": 61.08,
    "high": 63.3,
    "low": 59.55,
    "close": 59.75,
    "volume": 16710100
  },
  {
    "time": "23-09-2002",
    "open": 63.75,
    "high": 63.75,
    "low": 62.37,
    "close": 63.37,
    "volume": 8377100
  },
  {
    "time": "20-09-2002",
    "open": 65.4,
    "high": 65.4,
    "low": 63.9,
    "close": 63.92,
    "volume": 14195200
  },
  {
    "time": "19-09-2002",
    "open": 64.8,
    "high": 66.48,
    "low": 64.65,
    "close": 64.8,
    "volume": 22034900
  },
  {
    "time": "18-09-2002",
    "open": 70.65,
    "high": 70.9,
    "low": 68.86,
    "close": 69.55,
    "volume": 12712700
  },
  {
    "time": "17-09-2002",
    "open": 73,
    "high": 73.45,
    "low": 71.35,
    "close": 71.75,
    "volume": 6195100
  },
  {
    "time": "16-09-2002",
    "open": 72,
    "high": 72.72,
    "low": 71.23,
    "close": 72.32,
    "volume": 4595200
  },
  {
    "time": "13-09-2002",
    "open": 71.5,
    "high": 72.88,
    "low": 71.31,
    "close": 72.5,
    "volume": 5949600
  },
  {
    "time": "12-09-2002",
    "open": 74.05,
    "high": 74.05,
    "low": 71.64,
    "close": 71.87,
    "volume": 6707400
  },
  {
    "time": "11-09-2002",
    "open": 76.4,
    "high": 77.5,
    "low": 74.06,
    "close": 74.2,
    "volume": 5394400
  },
  {
    "time": "10-09-2002",
    "open": 74.6,
    "high": 75.6,
    "low": 73.85,
    "close": 75.6,
    "volume": 5538300
  },
  {
    "time": "09-09-2002",
    "open": 72.45,
    "high": 74.98,
    "low": 71.85,
    "close": 74.5,
    "volume": 5785000
  },
  {
    "time": "06-09-2002",
    "open": 73.75,
    "high": 73.99,
    "low": 73.07,
    "close": 73.2,
    "volume": 4586500
  },
  {
    "time": "05-09-2002",
    "open": 72.74,
    "high": 72.85,
    "low": 71.5,
    "close": 72.18,
    "volume": 6467700
  },
  {
    "time": "04-09-2002",
    "open": 72.35,
    "high": 73.99,
    "low": 71.6,
    "close": 73.73,
    "volume": 6264600
  },
  {
    "time": "03-09-2002",
    "open": 74.2,
    "high": 75,
    "low": 72.3,
    "close": 72.35,
    "volume": 6849400
  },
  {
    "time": "30-08-2002",
    "open": 75.55,
    "high": 76.35,
    "low": 75.1,
    "close": 75.38,
    "volume": 5002500
  },
  {
    "time": "29-08-2002",
    "open": 75.6,
    "high": 77.35,
    "low": 75.15,
    "close": 76.62,
    "volume": 5857400
  },
  {
    "time": "28-08-2002",
    "open": 76,
    "high": 77.01,
    "low": 75.67,
    "close": 76.06,
    "volume": 6590300
  },
  {
    "time": "27-08-2002",
    "open": 80.4,
    "high": 80.44,
    "low": 77.3,
    "close": 77.96,
    "volume": 7087800
  },
  {
    "time": "26-08-2002",
    "open": 80.4,
    "high": 80.89,
    "low": 77.8,
    "close": 79.42,
    "volume": 6997800
  },
  {
    "time": "23-08-2002",
    "open": 81.2,
    "high": 81.48,
    "low": 79.35,
    "close": 80.4,
    "volume": 5908900
  },
  {
    "time": "22-08-2002",
    "open": 80.95,
    "high": 82.45,
    "low": 80.2,
    "close": 81.99,
    "volume": 5446600
  },
  {
    "time": "21-08-2002",
    "open": 81.9,
    "high": 82.33,
    "low": 79.55,
    "close": 81,
    "volume": 7768600
  },
  {
    "time": "20-08-2002",
    "open": 81.56,
    "high": 82,
    "low": 80.18,
    "close": 81.27,
    "volume": 7671000
  },
  {
    "time": "19-08-2002",
    "open": 79.35,
    "high": 82.85,
    "low": 79.01,
    "close": 82.49,
    "volume": 10803900
  },
  {
    "time": "16-08-2002",
    "open": 76.5,
    "high": 79.98,
    "low": 75.98,
    "close": 79.35,
    "volume": 11645800
  },
  {
    "time": "15-08-2002",
    "open": 75.4,
    "high": 76.71,
    "low": 74.6,
    "close": 76.5,
    "volume": 9267300
  },
  {
    "time": "14-08-2002",
    "open": 71.95,
    "high": 75.02,
    "low": 71.35,
    "close": 74.92,
    "volume": 8353000
  },
  {
    "time": "13-08-2002",
    "open": 71.55,
    "high": 73.8,
    "low": 71.19,
    "close": 71.9,
    "volume": 8014700
  },
  {
    "time": "12-08-2002",
    "open": 71.05,
    "high": 72.64,
    "low": 70.61,
    "close": 71.77,
    "volume": 5263300
  },
  {
    "time": "09-08-2002",
    "open": 70.61,
    "high": 74.3,
    "low": 70.4,
    "close": 71.83,
    "volume": 6940900
  },
  {
    "time": "08-08-2002",
    "open": 69.2,
    "high": 72.05,
    "low": 68.35,
    "close": 71.61,
    "volume": 8420500
  },
  {
    "time": "07-08-2002",
    "open": 69,
    "high": 69.9,
    "low": 66.8,
    "close": 69.17,
    "volume": 8037300
  },
  {
    "time": "06-08-2002",
    "open": 67,
    "high": 69.42,
    "low": 66.61,
    "close": 67.9,
    "volume": 8370800
  },
  {
    "time": "05-08-2002",
    "open": 67.94,
    "high": 68.35,
    "low": 65.85,
    "close": 65.99,
    "volume": 7126900
  },
  {
    "time": "02-08-2002",
    "open": 68.21,
    "high": 68.23,
    "low": 67.12,
    "close": 67.88,
    "volume": 6915000
  },
  {
    "time": "01-08-2002",
    "open": 70.4,
    "high": 70.7,
    "low": 67.22,
    "close": 68.25,
    "volume": 8832000
  },
  {
    "time": "31-07-2002",
    "open": 70.75,
    "high": 71.6,
    "low": 69.11,
    "close": 70.4,
    "volume": 12718600
  },
  {
    "time": "30-07-2002",
    "open": 70.5,
    "high": 72.7,
    "low": 70.02,
    "close": 71.79,
    "volume": 10138600
  },
  {
    "time": "29-07-2002",
    "open": 70.37,
    "high": 71.4,
    "low": 68,
    "close": 71.18,
    "volume": 11919300
  },
  {
    "time": "26-07-2002",
    "open": 68.75,
    "high": 69.47,
    "low": 65.76,
    "close": 66.4,
    "volume": 11576400
  },
  {
    "time": "25-07-2002",
    "open": 68.9,
    "high": 70,
    "low": 67.3,
    "close": 69.35,
    "volume": 10989800
  },
  {
    "time": "24-07-2002",
    "open": 66,
    "high": 70,
    "low": 65.7,
    "close": 69.54,
    "volume": 13804500
  },
  {
    "time": "23-07-2002",
    "open": 68.25,
    "high": 70,
    "low": 66.66,
    "close": 67.05,
    "volume": 12766700
  },
  {
    "time": "22-07-2002",
    "open": 71.25,
    "high": 72.19,
    "low": 68.31,
    "close": 68.5,
    "volume": 14866900
  },
  {
    "time": "19-07-2002",
    "open": 70.96,
    "high": 72.7,
    "low": 70,
    "close": 72,
    "volume": 11612900
  },
  {
    "time": "18-07-2002",
    "open": 70.5,
    "high": 74.2,
    "low": 70,
    "close": 72.05,
    "volume": 15310800
  },
  {
    "time": "17-07-2002",
    "open": 71,
    "high": 71.6,
    "low": 69.62,
    "close": 70.69,
    "volume": 11537300
  },
  {
    "time": "16-07-2002",
    "open": 69.51,
    "high": 71.39,
    "low": 68.48,
    "close": 69.01,
    "volume": 10486800
  },
  {
    "time": "15-07-2002",
    "open": 68.4,
    "high": 71,
    "low": 66.6,
    "close": 71,
    "volume": 10420900
  },
  {
    "time": "12-07-2002",
    "open": 70,
    "high": 71.74,
    "low": 68.8,
    "close": 69.21,
    "volume": 8572200
  },
  {
    "time": "11-07-2002",
    "open": 68,
    "high": 69.75,
    "low": 67.55,
    "close": 69.41,
    "volume": 11026400
  },
  {
    "time": "10-07-2002",
    "open": 70.6,
    "high": 71.25,
    "low": 68.53,
    "close": 68.76,
    "volume": 10073400
  },
  {
    "time": "09-07-2002",
    "open": 71.4,
    "high": 72.25,
    "low": 69.45,
    "close": 69.67,
    "volume": 11165600
  },
  {
    "time": "08-07-2002",
    "open": 73,
    "high": 73.48,
    "low": 71.03,
    "close": 71.3,
    "volume": 9488100
  },
  {
    "time": "05-07-2002",
    "open": 72.45,
    "high": 73.9,
    "low": 72,
    "close": 73.5,
    "volume": 5243000
  },
  {
    "time": "03-07-2002",
    "open": 67.95,
    "high": 70.71,
    "low": 67.87,
    "close": 70.51,
    "volume": 8469900
  },
  {
    "time": "02-07-2002",
    "open": 67.85,
    "high": 69.4,
    "low": 67.7,
    "close": 68.58,
    "volume": 12012900
  },
  {
    "time": "01-07-2002",
    "open": 72.01,
    "high": 72.41,
    "low": 67.16,
    "close": 67.6,
    "volume": 12055900
  },
  {
    "time": "28-06-2002",
    "open": 71.35,
    "high": 73.62,
    "low": 71.25,
    "close": 72,
    "volume": 10945600
  },
  {
    "time": "27-06-2002",
    "open": 71.2,
    "high": 72.15,
    "low": 69.1,
    "close": 71.9,
    "volume": 10610100
  },
  {
    "time": "26-06-2002",
    "open": 66.1,
    "high": 70.59,
    "low": 66.1,
    "close": 70.05,
    "volume": 11544000
  },
  {
    "time": "25-06-2002",
    "open": 70.3,
    "high": 71.45,
    "low": 68.41,
    "close": 68.6,
    "volume": 8554800
  },
  {
    "time": "24-06-2002",
    "open": 67.25,
    "high": 71.49,
    "low": 67.25,
    "close": 69.7,
    "volume": 14687700
  },
  {
    "time": "21-06-2002",
    "open": 70,
    "high": 70.99,
    "low": 68.19,
    "close": 68.75,
    "volume": 15542600
  },
  {
    "time": "20-06-2002",
    "open": 74,
    "high": 74.09,
    "low": 71.43,
    "close": 71.58,
    "volume": 11116900
  },
  {
    "time": "19-06-2002",
    "open": 74.2,
    "high": 75.2,
    "low": 73.25,
    "close": 73.35,
    "volume": 9455400
  },
  {
    "time": "18-06-2002",
    "open": 75.6,
    "high": 76.9,
    "low": 75.47,
    "close": 75.94,
    "volume": 7242000
  },
  {
    "time": "17-06-2002",
    "open": 76.18,
    "high": 77.75,
    "low": 75.16,
    "close": 77.14,
    "volume": 7775800
  },
  {
    "time": "14-06-2002",
    "open": 74.2,
    "high": 76.77,
    "low": 73.25,
    "close": 76.17,
    "volume": 8592000
  },
  {
    "time": "13-06-2002",
    "open": 75.45,
    "high": 77.4,
    "low": 75.3,
    "close": 75.6,
    "volume": 8909600
  },
  {
    "time": "12-06-2002",
    "open": 75.4,
    "high": 75.95,
    "low": 73.25,
    "close": 74.65,
    "volume": 13296100
  },
  {
    "time": "11-06-2002",
    "open": 77.85,
    "high": 78.25,
    "low": 75.2,
    "close": 75.49,
    "volume": 7953400
  },
  {
    "time": "10-06-2002",
    "open": 77.75,
    "high": 78.8,
    "low": 76.6,
    "close": 76.8,
    "volume": 7075800
  },
  {
    "time": "07-06-2002",
    "open": 78.48,
    "high": 78.85,
    "low": 77,
    "close": 78.3,
    "volume": 9309400
  },
  {
    "time": "06-06-2002",
    "open": 80.55,
    "high": 80.95,
    "low": 78.89,
    "close": 79.66,
    "volume": 5540600
  },
  {
    "time": "05-06-2002",
    "open": 79.65,
    "high": 80.71,
    "low": 79.11,
    "close": 80.55,
    "volume": 7172100
  },
  {
    "time": "04-06-2002",
    "open": 78.11,
    "high": 79.65,
    "low": 77.6,
    "close": 79.31,
    "volume": 7273200
  },
  {
    "time": "03-06-2002",
    "open": 80.75,
    "high": 80.91,
    "low": 78,
    "close": 78.11,
    "volume": 8383600
  },
  {
    "time": "31-05-2002",
    "open": 82.05,
    "high": 82.05,
    "low": 80.4,
    "close": 80.45,
    "volume": 5126200
  },
  {
    "time": "30-05-2002",
    "open": 80.6,
    "high": 82.25,
    "low": 80.22,
    "close": 82.25,
    "volume": 7374100
  },
  {
    "time": "29-05-2002",
    "open": 81.5,
    "high": 82,
    "low": 81,
    "close": 81.6,
    "volume": 4519500
  },
  {
    "time": "28-05-2002",
    "open": 82.8,
    "high": 83.11,
    "low": 81.69,
    "close": 82.08,
    "volume": 4552100
  },
  {
    "time": "24-05-2002",
    "open": 83.65,
    "high": 83.75,
    "low": 82.62,
    "close": 83.1,
    "volume": 4122000
  },
  {
    "time": "23-05-2002",
    "open": 84.25,
    "high": 85.05,
    "low": 82.52,
    "close": 84.35,
    "volume": 6865600
  },
  {
    "time": "22-05-2002",
    "open": 83.76,
    "high": 84.39,
    "low": 82.74,
    "close": 84,
    "volume": 5040700
  },
  {
    "time": "21-05-2002",
    "open": 84.41,
    "high": 85,
    "low": 83.3,
    "close": 83.45,
    "volume": 4454000
  },
  {
    "time": "20-05-2002",
    "open": 85.45,
    "high": 85.46,
    "low": 83.93,
    "close": 84.45,
    "volume": 4530300
  },
  {
    "time": "17-05-2002",
    "open": 86.25,
    "high": 86.4,
    "low": 85,
    "close": 85.69,
    "volume": 5874800
  },
  {
    "time": "16-05-2002",
    "open": 84.2,
    "high": 86,
    "low": 83.4,
    "close": 85.45,
    "volume": 6768300
  },
  {
    "time": "15-05-2002",
    "open": 84.6,
    "high": 86.49,
    "low": 84.05,
    "close": 84.5,
    "volume": 8552300
  },
  {
    "time": "14-05-2002",
    "open": 84.5,
    "high": 85.48,
    "low": 82.19,
    "close": 85.48,
    "volume": 9624700
  },
  {
    "time": "13-05-2002",
    "open": 80.25,
    "high": 82.29,
    "low": 79.5,
    "close": 82.19,
    "volume": 6527600
  },
  {
    "time": "10-05-2002",
    "open": 79.95,
    "high": 80.5,
    "low": 79.25,
    "close": 79.68,
    "volume": 7386300
  },
  {
    "time": "09-05-2002",
    "open": 81.9,
    "high": 83,
    "low": 79,
    "close": 79.93,
    "volume": 10130100
  },
  {
    "time": "08-05-2002",
    "open": 78.2,
    "high": 82.8,
    "low": 78.2,
    "close": 82.45,
    "volume": 12779700
  },
  {
    "time": "07-05-2002",
    "open": 76.89,
    "high": 77.5,
    "low": 75.92,
    "close": 76.5,
    "volume": 17418700
  },
  {
    "time": "06-05-2002",
    "open": 81.7,
    "high": 81.87,
    "low": 76,
    "close": 76,
    "volume": 13648400
  },
  {
    "time": "03-05-2002",
    "open": 83.1,
    "high": 83.5,
    "low": 81.11,
    "close": 81.78,
    "volume": 8656300
  },
  {
    "time": "02-05-2002",
    "open": 83.75,
    "high": 84.8,
    "low": 83.13,
    "close": 83.86,
    "volume": 6582900
  },
  {
    "time": "01-05-2002",
    "open": 83.85,
    "high": 84.65,
    "low": 81.67,
    "close": 84.3,
    "volume": 12203800
  },
  {
    "time": "30-04-2002",
    "open": 83.91,
    "high": 85.23,
    "low": 83.53,
    "close": 83.76,
    "volume": 8883900
  },
  {
    "time": "29-04-2002",
    "open": 84.71,
    "high": 85.1,
    "low": 83.55,
    "close": 83.89,
    "volume": 6535600
  },
  {
    "time": "26-04-2002",
    "open": 86.7,
    "high": 86.96,
    "low": 84.7,
    "close": 84.71,
    "volume": 6684200
  },
  {
    "time": "25-04-2002",
    "open": 86.05,
    "high": 86.92,
    "low": 85.77,
    "close": 86.71,
    "volume": 6870000
  },
  {
    "time": "24-04-2002",
    "open": 88.2,
    "high": 88.25,
    "low": 86.27,
    "close": 86.5,
    "volume": 6520500
  },
  {
    "time": "23-04-2002",
    "open": 88.35,
    "high": 89.08,
    "low": 87.24,
    "close": 87.33,
    "volume": 5144100
  },
  {
    "time": "22-04-2002",
    "open": 88.95,
    "high": 88.95,
    "low": 87.36,
    "close": 87.93,
    "volume": 6757700
  },
  {
    "time": "19-04-2002",
    "open": 89.9,
    "high": 90.03,
    "low": 87.6,
    "close": 89,
    "volume": 9273200
  },
  {
    "time": "18-04-2002",
    "open": 86.97,
    "high": 89.1,
    "low": 86.9,
    "close": 88.95,
    "volume": 14763500
  },
  {
    "time": "17-04-2002",
    "open": 87.05,
    "high": 87.2,
    "low": 84,
    "close": 84.81,
    "volume": 11019500
  },
  {
    "time": "16-04-2002",
    "open": 86.35,
    "high": 86.85,
    "low": 85.58,
    "close": 86.2,
    "volume": 7933100
  },
  {
    "time": "15-04-2002",
    "open": 86.5,
    "high": 86.6,
    "low": 84.7,
    "close": 85.35,
    "volume": 8871600
  },
  {
    "time": "12-04-2002",
    "open": 87.56,
    "high": 87.96,
    "low": 84.4,
    "close": 85.6,
    "volume": 15819300
  },
  {
    "time": "11-04-2002",
    "open": 88.05,
    "high": 88.51,
    "low": 83.34,
    "close": 84.19,
    "volume": 23343700
  },
  {
    "time": "10-04-2002",
    "open": 88.45,
    "high": 89.95,
    "low": 88.2,
    "close": 89.01,
    "volume": 13143900
  },
  {
    "time": "09-04-2002",
    "open": 88.5,
    "high": 88.52,
    "low": 87.1,
    "close": 87.74,
    "volume": 17383600
  },
  {
    "time": "08-04-2002",
    "open": 87.5,
    "high": 88.59,
    "low": 85.35,
    "close": 87.41,
    "volume": 41207300
  },
  {
    "time": "05-04-2002",
    "open": 100.84,
    "high": 101.25,
    "low": 97.25,
    "close": 97.25,
    "volume": 9801800
  },
  {
    "time": "04-04-2002",
    "open": 99.96,
    "high": 101.14,
    "low": 99.5,
    "close": 100.84,
    "volume": 5928500
  },
  {
    "time": "03-04-2002",
    "open": 101.4,
    "high": 101.5,
    "low": 98.9,
    "close": 99.96,
    "volume": 7649100
  },
  {
    "time": "02-04-2002",
    "open": 102,
    "high": 102.01,
    "low": 100.51,
    "close": 100.95,
    "volume": 7382200
  },
  {
    "time": "01-04-2002",
    "open": 104,
    "high": 104,
    "low": 102.4,
    "close": 102.86,
    "volume": 5587000
  },
  {
    "time": "28-03-2002",
    "open": 103.55,
    "high": 105.14,
    "low": 103.39,
    "close": 104,
    "volume": 5347500
  },
  {
    "time": "27-03-2002",
    "open": 103.75,
    "high": 103.79,
    "low": 102.5,
    "close": 103.39,
    "volume": 5602400
  },
  {
    "time": "26-03-2002",
    "open": 103.57,
    "high": 105.7,
    "low": 102.3,
    "close": 102.9,
    "volume": 8144000
  },
  {
    "time": "25-03-2002",
    "open": 105.8,
    "high": 106.66,
    "low": 103.5,
    "close": 103.56,
    "volume": 6277900
  },
  {
    "time": "22-03-2002",
    "open": 106.5,
    "high": 106.7,
    "low": 105.07,
    "close": 105.6,
    "volume": 5507900
  },
  {
    "time": "21-03-2002",
    "open": 105.7,
    "high": 106.78,
    "low": 104.7,
    "close": 106.78,
    "volume": 5113100
  },
  {
    "time": "20-03-2002",
    "open": 106.9,
    "high": 106.9,
    "low": 105.49,
    "close": 105.5,
    "volume": 4844100
  },
  {
    "time": "19-03-2002",
    "open": 106.85,
    "high": 108.05,
    "low": 106.49,
    "close": 107.49,
    "volume": 4614800
  },
  {
    "time": "18-03-2002",
    "open": 107.1,
    "high": 108.64,
    "low": 106.23,
    "close": 106.35,
    "volume": 5301200
  },
  {
    "time": "15-03-2002",
    "open": 106.55,
    "high": 107.45,
    "low": 105.59,
    "close": 106.79,
    "volume": 10864100
  },
  {
    "time": "14-03-2002",
    "open": 107.02,
    "high": 107.95,
    "low": 106.59,
    "close": 106.6,
    "volume": 5335500
  },
  {
    "time": "13-03-2002",
    "open": 108.3,
    "high": 108.65,
    "low": 106.7,
    "close": 107.18,
    "volume": 6409200
  },
  {
    "time": "12-03-2002",
    "open": 105.52,
    "high": 108.85,
    "low": 105.51,
    "close": 108.5,
    "volume": 11059300
  },
  {
    "time": "11-03-2002",
    "open": 104.35,
    "high": 105.97,
    "low": 103.6,
    "close": 105.24,
    "volume": 8639100
  },
  {
    "time": "08-03-2002",
    "open": 104.82,
    "high": 107.34,
    "low": 104.82,
    "close": 105.09,
    "volume": 10726400
  },
  {
    "time": "07-03-2002",
    "open": 107.3,
    "high": 107.5,
    "low": 103.24,
    "close": 103.71,
    "volume": 10971900
  },
  {
    "time": "06-03-2002",
    "open": 105.15,
    "high": 107.09,
    "low": 104.75,
    "close": 106.3,
    "volume": 7687400
  },
  {
    "time": "05-03-2002",
    "open": 104.25,
    "high": 106.5,
    "low": 104.16,
    "close": 105.67,
    "volume": 7562100
  },
  {
    "time": "04-03-2002",
    "open": 103.35,
    "high": 106.53,
    "low": 103.13,
    "close": 105.9,
    "volume": 10767700
  },
  {
    "time": "01-03-2002",
    "open": 98.6,
    "high": 103.1,
    "low": 98.5,
    "close": 103.02,
    "volume": 10492900
  },
  {
    "time": "28-02-2002",
    "open": 98.49,
    "high": 99.6,
    "low": 97.82,
    "close": 98.12,
    "volume": 7939000
  },
  {
    "time": "27-02-2002",
    "open": 98.1,
    "high": 100.31,
    "low": 96.7,
    "close": 97.83,
    "volume": 11978700
  },
  {
    "time": "26-02-2002",
    "open": 99,
    "high": 99.19,
    "low": 95.99,
    "close": 97.15,
    "volume": 11634500
  },
  {
    "time": "25-02-2002",
    "open": 98.45,
    "high": 99.25,
    "low": 97.12,
    "close": 98.3,
    "volume": 9902800
  },
  {
    "time": "22-02-2002",
    "open": 96.38,
    "high": 99.2,
    "low": 95.76,
    "close": 98.45,
    "volume": 10471600
  },
  {
    "time": "21-02-2002",
    "open": 98.75,
    "high": 99.7,
    "low": 96.01,
    "close": 96.38,
    "volume": 10131700
  },
  {
    "time": "20-02-2002",
    "open": 99.54,
    "high": 100,
    "low": 97,
    "close": 99.31,
    "volume": 14525200
  },
  {
    "time": "19-02-2002",
    "open": 101.5,
    "high": 101.8,
    "low": 98.86,
    "close": 101.3,
    "volume": 13395900
  },
  {
    "time": "15-02-2002",
    "open": 104.8,
    "high": 104.8,
    "low": 101.7,
    "close": 102.89,
    "volume": 18204600
  },
  {
    "time": "14-02-2002",
    "open": 109,
    "high": 109.15,
    "low": 107.2,
    "close": 107.89,
    "volume": 5816300
  },
  {
    "time": "13-02-2002",
    "open": 106.7,
    "high": 108.48,
    "low": 106.12,
    "close": 108.07,
    "volume": 5086900
  },
  {
    "time": "12-02-2002",
    "open": 106.75,
    "high": 107.89,
    "low": 106.06,
    "close": 106.57,
    "volume": 4559800
  },
  {
    "time": "11-02-2002",
    "open": 105,
    "high": 107.69,
    "low": 104.51,
    "close": 107.38,
    "volume": 6271900
  },
  {
    "time": "08-02-2002",
    "open": 103,
    "high": 105.72,
    "low": 102.61,
    "close": 104.99,
    "volume": 8339400
  },
  {
    "time": "07-02-2002",
    "open": 106.64,
    "high": 107,
    "low": 103.86,
    "close": 103.91,
    "volume": 7253800
  },
  {
    "time": "06-02-2002",
    "open": 106.7,
    "high": 107.82,
    "low": 105.33,
    "close": 106.63,
    "volume": 6669800
  },
  {
    "time": "05-02-2002",
    "open": 106.25,
    "high": 108.5,
    "low": 106,
    "close": 106.3,
    "volume": 6737700
  },
  {
    "time": "04-02-2002",
    "open": 108,
    "high": 108,
    "low": 105.99,
    "close": 106.8,
    "volume": 6601900
  },
  {
    "time": "01-02-2002",
    "open": 107.64,
    "high": 109.3,
    "low": 106.64,
    "close": 108,
    "volume": 6799500
  },
  {
    "time": "31-01-2002",
    "open": 106.4,
    "high": 107.98,
    "low": 104.46,
    "close": 107.89,
    "volume": 9877000
  },
  {
    "time": "30-01-2002",
    "open": 104.25,
    "high": 105.75,
    "low": 101,
    "close": 105.55,
    "volume": 17713300
  },
  {
    "time": "29-01-2002",
    "open": 108.15,
    "high": 108.56,
    "low": 102.41,
    "close": 103,
    "volume": 14509400
  },
  {
    "time": "28-01-2002",
    "open": 109.5,
    "high": 110.21,
    "low": 107.06,
    "close": 108.15,
    "volume": 7092900
  },
  {
    "time": "25-01-2002",
    "open": 108.5,
    "high": 110.69,
    "low": 108.1,
    "close": 109.28,
    "volume": 6761800
  },
  {
    "time": "24-01-2002",
    "open": 108.8,
    "high": 109.73,
    "low": 107.07,
    "close": 108.72,
    "volume": 8581500
  },
  {
    "time": "23-01-2002",
    "open": 110.8,
    "high": 111.25,
    "low": 107,
    "close": 107.9,
    "volume": 12556700
  },
  {
    "time": "22-01-2002",
    "open": 114.25,
    "high": 114.26,
    "low": 110.33,
    "close": 110.5,
    "volume": 10130400
  },
  {
    "time": "18-01-2002",
    "open": 114.25,
    "high": 114.9,
    "low": 112.81,
    "close": 114.25,
    "volume": 18440200
  },
  {
    "time": "17-01-2002",
    "open": 119.85,
    "high": 120.55,
    "low": 117.26,
    "close": 119.9,
    "volume": 11153700
  },
  {
    "time": "16-01-2002",
    "open": 118,
    "high": 118.75,
    "low": 117.25,
    "close": 117.4,
    "volume": 5366300
  },
  {
    "time": "15-01-2002",
    "open": 118.05,
    "high": 119.7,
    "low": 117.72,
    "close": 118.85,
    "volume": 5647900
  },
  {
    "time": "14-01-2002",
    "open": 120,
    "high": 120.09,
    "low": 117.1,
    "close": 118.05,
    "volume": 10026700
  },
  {
    "time": "11-01-2002",
    "open": 121.5,
    "high": 122.18,
    "low": 120.28,
    "close": 120.31,
    "volume": 4797800
  },
  {
    "time": "10-01-2002",
    "open": 123.75,
    "high": 124,
    "low": 121.42,
    "close": 122.14,
    "volume": 8926300
  },
  {
    "time": "09-01-2002",
    "open": 124.7,
    "high": 126.39,
    "low": 124.15,
    "close": 124.49,
    "volume": 6839900
  },
  {
    "time": "08-01-2002",
    "open": 124.25,
    "high": 125.2,
    "low": 123.73,
    "close": 124.7,
    "volume": 5311800
  },
  {
    "time": "07-01-2002",
    "open": 125,
    "high": 126.19,
    "low": 123.7,
    "close": 124.05,
    "volume": 5939600
  },
  {
    "time": "04-01-2002",
    "open": 124.05,
    "high": 125.6,
    "low": 123.98,
    "close": 125.6,
    "volume": 8405200
  },
  {
    "time": "03-01-2002",
    "open": 121.5,
    "high": 124.22,
    "low": 120.25,
    "close": 123.66,
    "volume": 8621700
  },
  {
    "time": "02-01-2002",
    "open": 120.6,
    "high": 121.5,
    "low": 119.8,
    "close": 121.5,
    "volume": 6862800
  },
  {
    "time": "31-12-2001",
    "open": 122.75,
    "high": 123.24,
    "low": 120.95,
    "close": 120.96,
    "volume": 3512700
  },
  {
    "time": "28-12-2001",
    "open": 123.5,
    "high": 123.88,
    "low": 122.35,
    "close": 122.9,
    "volume": 4213900
  },
  {
    "time": "27-12-2001",
    "open": 122.75,
    "high": 123.93,
    "low": 122.64,
    "close": 123.5,
    "volume": 3826200
  },
  {
    "time": "26-12-2001",
    "open": 122,
    "high": 123.7,
    "low": 121.81,
    "close": 122.4,
    "volume": 3755700
  },
  {
    "time": "24-12-2001",
    "open": 122.05,
    "high": 122.48,
    "low": 121.1,
    "close": 121.45,
    "volume": 1654400
  },
  {
    "time": "21-12-2001",
    "open": 122.7,
    "high": 123.5,
    "low": 121.71,
    "close": 122,
    "volume": 8659100
  },
  {
    "time": "20-12-2001",
    "open": 123.89,
    "high": 124,
    "low": 122.15,
    "close": 122.7,
    "volume": 6344800
  },
  {
    "time": "19-12-2001",
    "open": 121.9,
    "high": 124.7,
    "low": 121.01,
    "close": 123.89,
    "volume": 8053900
  },
  {
    "time": "18-12-2001",
    "open": 121.51,
    "high": 122.97,
    "low": 121.35,
    "close": 122.2,
    "volume": 5596200
  },
  {
    "time": "17-12-2001",
    "open": 120.15,
    "high": 122.14,
    "low": 120.15,
    "close": 121.34,
    "volume": 6205100
  },
  {
    "time": "14-12-2001",
    "open": 120.8,
    "high": 121.48,
    "low": 119.41,
    "close": 121.1,
    "volume": 7036000
  },
  {
    "time": "13-12-2001",
    "open": 121.55,
    "high": 122.3,
    "low": 120.12,
    "close": 120.25,
    "volume": 8061000
  },
  {
    "time": "12-12-2001",
    "open": 121.12,
    "high": 123.21,
    "low": 120.06,
    "close": 123.2,
    "volume": 7977900
  },
  {
    "time": "11-12-2001",
    "open": 120.4,
    "high": 122.74,
    "low": 119.8,
    "close": 121.5,
    "volume": 7793200
  },
  {
    "time": "10-12-2001",
    "open": 120,
    "high": 121,
    "low": 119.41,
    "close": 119.66,
    "volume": 5570900
  },
  {
    "time": "07-12-2001",
    "open": 119.5,
    "high": 121,
    "low": 118.75,
    "close": 120.4,
    "volume": 6889200
  },
  {
    "time": "06-12-2001",
    "open": 120.5,
    "high": 121.7,
    "low": 119.8,
    "close": 120.14,
    "volume": 11225700
  },
  {
    "time": "05-12-2001",
    "open": 116.85,
    "high": 121.98,
    "low": 116.65,
    "close": 121.4,
    "volume": 14646200
  },
  {
    "time": "04-12-2001",
    "open": 114.3,
    "high": 116.72,
    "low": 113.38,
    "close": 116.64,
    "volume": 6585900
  },
  {
    "time": "03-12-2001",
    "open": 114.8,
    "high": 114.85,
    "low": 113.21,
    "close": 114.13,
    "volume": 5785800
  },
  {
    "time": "30-11-2001",
    "open": 114.43,
    "high": 115.94,
    "low": 113.8,
    "close": 115.59,
    "volume": 5597800
  },
  {
    "time": "29-11-2001",
    "open": 112.9,
    "high": 114.55,
    "low": 111.81,
    "close": 114.43,
    "volume": 6128700
  },
  {
    "time": "28-11-2001",
    "open": 114.1,
    "high": 114.38,
    "low": 112.1,
    "close": 112.15,
    "volume": 6161200
  },
  {
    "time": "27-11-2001",
    "open": 116.25,
    "high": 116.3,
    "low": 114.07,
    "close": 114.2,
    "volume": 6894100
  },
  {
    "time": "26-11-2001",
    "open": 115.9,
    "high": 116.4,
    "low": 115.2,
    "close": 116.33,
    "volume": 4854300
  },
  {
    "time": "23-11-2001",
    "open": 114.29,
    "high": 115.37,
    "low": 113.72,
    "close": 115.35,
    "volume": 1714000
  },
  {
    "time": "21-11-2001",
    "open": 114.95,
    "high": 115.25,
    "low": 113.05,
    "close": 114.34,
    "volume": 4184700
  },
  {
    "time": "20-11-2001",
    "open": 114.35,
    "high": 116.8,
    "low": 114.25,
    "close": 115.44,
    "volume": 6452200
  },
  {
    "time": "19-11-2001",
    "open": 114.7,
    "high": 115.8,
    "low": 113.77,
    "close": 115,
    "volume": 4931300
  },
  {
    "time": "16-11-2001",
    "open": 115,
    "high": 115,
    "low": 112.55,
    "close": 114.5,
    "volume": 6661500
  },
  {
    "time": "15-11-2001",
    "open": 114.1,
    "high": 116.33,
    "low": 113.84,
    "close": 114.75,
    "volume": 7013800
  },
  {
    "time": "14-11-2001",
    "open": 116.7,
    "high": 116.85,
    "low": 113.33,
    "close": 114.35,
    "volume": 8091700
  },
  {
    "time": "13-11-2001",
    "open": 115.7,
    "high": 117,
    "low": 115.7,
    "close": 116.7,
    "volume": 9216700
  },
  {
    "time": "12-11-2001",
    "open": 113,
    "high": 114.9,
    "low": 112.05,
    "close": 114.86,
    "volume": 5608400
  },
  {
    "time": "09-11-2001",
    "open": 113.1,
    "high": 114.9,
    "low": 113.1,
    "close": 114.08,
    "volume": 5045900
  },
  {
    "time": "08-11-2001",
    "open": 113.96,
    "high": 115.56,
    "low": 113.61,
    "close": 113.81,
    "volume": 7991200
  },
  {
    "time": "07-11-2001",
    "open": 113.05,
    "high": 115.2,
    "low": 113.03,
    "close": 113.85,
    "volume": 10129700
  },
  {
    "time": "06-11-2001",
    "open": 109.6,
    "high": 114.8,
    "low": 109,
    "close": 113.61,
    "volume": 8509700
  },
  {
    "time": "05-11-2001",
    "open": 109.95,
    "high": 110.59,
    "low": 109.08,
    "close": 109.97,
    "volume": 5708500
  },
  {
    "time": "02-11-2001",
    "open": 109.15,
    "high": 110.3,
    "low": 108.77,
    "close": 109.5,
    "volume": 6216900
  },
  {
    "time": "01-11-2001",
    "open": 107.25,
    "high": 110.17,
    "low": 106.9,
    "close": 109.89,
    "volume": 8024000
  },
  {
    "time": "31-10-2001",
    "open": 109.6,
    "high": 111.12,
    "low": 108.07,
    "close": 108.07,
    "volume": 7177700
  },
  {
    "time": "30-10-2001",
    "open": 107.8,
    "high": 109.75,
    "low": 107.76,
    "close": 108.65,
    "volume": 8625100
  },
  {
    "time": "29-10-2001",
    "open": 110.5,
    "high": 110.7,
    "low": 108.62,
    "close": 108.62,
    "volume": 7072800
  },
  {
    "time": "26-10-2001",
    "open": 110,
    "high": 112.1,
    "low": 109.62,
    "close": 111.16,
    "volume": 10142500
  },
  {
    "time": "25-10-2001",
    "open": 108.05,
    "high": 110.85,
    "low": 106.75,
    "close": 110.66,
    "volume": 9683400
  },
  {
    "time": "24-10-2001",
    "open": 106.5,
    "high": 108.75,
    "low": 106.09,
    "close": 108.57,
    "volume": 8293200
  },
  {
    "time": "23-10-2001",
    "open": 105.75,
    "high": 106.69,
    "low": 104.55,
    "close": 105.81,
    "volume": 7429300
  },
  {
    "time": "22-10-2001",
    "open": 102.65,
    "high": 105.78,
    "low": 101.9,
    "close": 105.25,
    "volume": 7623900
  },
  {
    "time": "19-10-2001",
    "open": 101.65,
    "high": 102.74,
    "low": 100.07,
    "close": 102.65,
    "volume": 11771900
  },
  {
    "time": "18-10-2001",
    "open": 103.05,
    "high": 103.75,
    "low": 100.78,
    "close": 101.26,
    "volume": 10694900
  },
  {
    "time": "17-10-2001",
    "open": 106.3,
    "high": 106.7,
    "low": 102.9,
    "close": 102.9,
    "volume": 18920500
  },
  {
    "time": "16-10-2001",
    "open": 101.87,
    "high": 103,
    "low": 99.66,
    "close": 101.85,
    "volume": 11946000
  },
  {
    "time": "15-10-2001",
    "open": 99.85,
    "high": 102.76,
    "low": 99.85,
    "close": 102,
    "volume": 10204200
  },
  {
    "time": "12-10-2001",
    "open": 98.7,
    "high": 101,
    "low": 98,
    "close": 100.84,
    "volume": 11501200
  },
  {
    "time": "11-10-2001",
    "open": 97.7,
    "high": 99.4,
    "low": 96.5,
    "close": 99.4,
    "volume": 11501600
  },
  {
    "time": "10-10-2001",
    "open": 95,
    "high": 97.3,
    "low": 94.9,
    "close": 97.25,
    "volume": 10636900
  },
  {
    "time": "09-10-2001",
    "open": 98.5,
    "high": 98.5,
    "low": 96.76,
    "close": 97.14,
    "volume": 7525300
  },
  {
    "time": "08-10-2001",
    "open": 98.03,
    "high": 99,
    "low": 96.75,
    "close": 98.5,
    "volume": 7093400
  },
  {
    "time": "05-10-2001",
    "open": 96.65,
    "high": 98.45,
    "low": 95.37,
    "close": 98.02,
    "volume": 9708200
  },
  {
    "time": "04-10-2001",
    "open": 97.15,
    "high": 98.88,
    "low": 96.8,
    "close": 97.31,
    "volume": 9785100
  },
  {
    "time": "03-10-2001",
    "open": 92.9,
    "high": 97.62,
    "low": 92.4,
    "close": 96.95,
    "volume": 11117000
  },
  {
    "time": "02-10-2001",
    "open": 92.3,
    "high": 93.9,
    "low": 92.1,
    "close": 93.77,
    "volume": 7221200
  },
  {
    "time": "01-10-2001",
    "open": 91.72,
    "high": 93.48,
    "low": 91.34,
    "close": 92.71,
    "volume": 8676200
  },
  {
    "time": "28-09-2001",
    "open": 90.2,
    "high": 92.71,
    "low": 89.9,
    "close": 91.72,
    "volume": 11077200
  },
  {
    "time": "27-09-2001",
    "open": 91.3,
    "high": 91.5,
    "low": 87.49,
    "close": 90,
    "volume": 13172300
  },
  {
    "time": "26-09-2001",
    "open": 94.45,
    "high": 94.45,
    "low": 90.3,
    "close": 91.3,
    "volume": 13783100
  },
  {
    "time": "25-09-2001",
    "open": 94.1,
    "high": 96,
    "low": 93.41,
    "close": 94.45,
    "volume": 12575100
  },
  {
    "time": "24-09-2001",
    "open": 91.8,
    "high": 95.54,
    "low": 91.8,
    "close": 94.8,
    "volume": 11201800
  },
  {
    "time": "21-09-2001",
    "open": 90.6,
    "high": 93.5,
    "low": 89.5,
    "close": 90.5,
    "volume": 18364700
  },
  {
    "time": "20-09-2001",
    "open": 94.1,
    "high": 95.75,
    "low": 92.85,
    "close": 93.4,
    "volume": 15182900
  },
  {
    "time": "19-09-2001",
    "open": 96.4,
    "high": 96.85,
    "low": 92.45,
    "close": 96,
    "volume": 14883500
  },
  {
    "time": "18-09-2001",
    "open": 94,
    "high": 96.75,
    "low": 93.68,
    "close": 96.4,
    "volume": 12305000
  },
  {
    "time": "17-09-2001",
    "open": 93.9,
    "high": 95.86,
    "low": 92.55,
    "close": 93.34,
    "volume": 15132800
  },
  {
    "time": "10-09-2001",
    "open": 96,
    "high": 97.4,
    "low": 95.59,
    "close": 96.47,
    "volume": 10311500
  },
  {
    "time": "07-09-2001",
    "open": 97.9,
    "high": 98.15,
    "low": 96,
    "close": 96.59,
    "volume": 10507000
  },
  {
    "time": "06-09-2001",
    "open": 100.68,
    "high": 100.68,
    "low": 97.65,
    "close": 98,
    "volume": 9043100
  },
  {
    "time": "05-09-2001",
    "open": 101.5,
    "high": 102.83,
    "low": 99.61,
    "close": 100.35,
    "volume": 11880700
  },
  {
    "time": "04-09-2001",
    "open": 100.15,
    "high": 103.13,
    "low": 99.29,
    "close": 101.49,
    "volume": 9891800
  },
  {
    "time": "31-08-2001",
    "open": 101,
    "high": 101.36,
    "low": 99.24,
    "close": 99.95,
    "volume": 8126600
  },
  {
    "time": "30-08-2001",
    "open": 102.75,
    "high": 103.4,
    "low": 98.86,
    "close": 100.36,
    "volume": 15015500
  },
  {
    "time": "29-08-2001",
    "open": 105.75,
    "high": 105.9,
    "low": 103.82,
    "close": 104.13,
    "volume": 4840300
  },
  {
    "time": "28-08-2001",
    "open": 106.9,
    "high": 107.17,
    "low": 104.87,
    "close": 104.95,
    "volume": 5808100
  },
  {
    "time": "27-08-2001",
    "open": 106.99,
    "high": 108.2,
    "low": 106.64,
    "close": 106.86,
    "volume": 4540900
  },
  {
    "time": "24-08-2001",
    "open": 103.95,
    "high": 107.8,
    "low": 103.41,
    "close": 106.99,
    "volume": 7711900
  },
  {
    "time": "23-08-2001",
    "open": 104,
    "high": 104.7,
    "low": 103,
    "close": 103,
    "volume": 5474300
  },
  {
    "time": "22-08-2001",
    "open": 102.5,
    "high": 104.04,
    "low": 101.76,
    "close": 103.96,
    "volume": 8029000
  },
  {
    "time": "21-08-2001",
    "open": 103.85,
    "high": 104.97,
    "low": 101.4,
    "close": 101.89,
    "volume": 6515900
  },
  {
    "time": "20-08-2001",
    "open": 104.68,
    "high": 105,
    "low": 102.8,
    "close": 104.1,
    "volume": 5612700
  },
  {
    "time": "17-08-2001",
    "open": 105.49,
    "high": 105.49,
    "low": 103.9,
    "close": 104.59,
    "volume": 7006200
  },
  {
    "time": "16-08-2001",
    "open": 104.2,
    "high": 106.02,
    "low": 103.66,
    "close": 105.75,
    "volume": 6215200
  },
  {
    "time": "15-08-2001",
    "open": 106.35,
    "high": 106.59,
    "low": 105.01,
    "close": 105.01,
    "volume": 4712900
  },
  {
    "time": "14-08-2001",
    "open": 106.5,
    "high": 106.85,
    "low": 105.4,
    "close": 106.2,
    "volume": 5730200
  },
  {
    "time": "13-08-2001",
    "open": 105,
    "high": 106.64,
    "low": 104.65,
    "close": 105.86,
    "volume": 6024400
  },
  {
    "time": "10-08-2001",
    "open": 103.85,
    "high": 104.95,
    "low": 102.81,
    "close": 104.95,
    "volume": 5254800
  },
  {
    "time": "09-08-2001",
    "open": 103.7,
    "high": 104.54,
    "low": 102.7,
    "close": 104.08,
    "volume": 6763200
  },
  {
    "time": "08-08-2001",
    "open": 105,
    "high": 106.1,
    "low": 104.1,
    "close": 104.19,
    "volume": 6398000
  },
  {
    "time": "07-08-2001",
    "open": 105.8,
    "high": 107.37,
    "low": 105.26,
    "close": 106.25,
    "volume": 4029300
  },
  {
    "time": "06-08-2001",
    "open": 107.25,
    "high": 107.37,
    "low": 105.79,
    "close": 106.51,
    "volume": 4176800
  },
  {
    "time": "03-08-2001",
    "open": 107.8,
    "high": 110.09,
    "low": 107.1,
    "close": 108.18,
    "volume": 3704100
  },
  {
    "time": "02-08-2001",
    "open": 108.5,
    "high": 109.47,
    "low": 107.21,
    "close": 108.8,
    "volume": 5675100
  },
  {
    "time": "01-08-2001",
    "open": 105.35,
    "high": 108.5,
    "low": 105.31,
    "close": 107.06,
    "volume": 6036100
  },
  {
    "time": "31-07-2001",
    "open": 105.9,
    "high": 107,
    "low": 105.15,
    "close": 105.21,
    "volume": 5603000
  },
  {
    "time": "30-07-2001",
    "open": 104.9,
    "high": 106.25,
    "low": 104.76,
    "close": 105.85,
    "volume": 4030800
  },
  {
    "time": "27-07-2001",
    "open": 104.75,
    "high": 105.95,
    "low": 104.11,
    "close": 104.7,
    "volume": 4346500
  },
  {
    "time": "26-07-2001",
    "open": 104.75,
    "high": 106,
    "low": 102.93,
    "close": 106,
    "volume": 8428700
  },
  {
    "time": "25-07-2001",
    "open": 104.5,
    "high": 105.7,
    "low": 102.73,
    "close": 104.89,
    "volume": 6247600
  },
  {
    "time": "24-07-2001",
    "open": 104.85,
    "high": 106.15,
    "low": 103.79,
    "close": 104.51,
    "volume": 7299800
  },
  {
    "time": "23-07-2001",
    "open": 105.7,
    "high": 106.95,
    "low": 105.3,
    "close": 105.85,
    "volume": 6315700
  },
  {
    "time": "20-07-2001",
    "open": 103.4,
    "high": 105.7,
    "low": 103.03,
    "close": 105.7,
    "volume": 8991700
  },
  {
    "time": "19-07-2001",
    "open": 105.6,
    "high": 106,
    "low": 103.1,
    "close": 104,
    "volume": 13644500
  },
  {
    "time": "18-07-2001",
    "open": 106.1,
    "high": 107.6,
    "low": 103.2,
    "close": 104.28,
    "volume": 11670300
  },
  {
    "time": "17-07-2001",
    "open": 106.1,
    "high": 108.58,
    "low": 104.75,
    "close": 108.53,
    "volume": 8662400
  },
  {
    "time": "16-07-2001",
    "open": 108.53,
    "high": 109.38,
    "low": 107.28,
    "close": 107.82,
    "volume": 5330100
  },
  {
    "time": "13-07-2001",
    "open": 106.15,
    "high": 109.3,
    "low": 106.11,
    "close": 108.53,
    "volume": 7841800
  },
  {
    "time": "12-07-2001",
    "open": 105.9,
    "high": 107.8,
    "low": 105.62,
    "close": 107.25,
    "volume": 8766400
  },
  {
    "time": "11-07-2001",
    "open": 101.6,
    "high": 104.13,
    "low": 101.56,
    "close": 103.85,
    "volume": 8895400
  },
  {
    "time": "10-07-2001",
    "open": 104.73,
    "high": 105.4,
    "low": 101.6,
    "close": 101.96,
    "volume": 10266600
  },
  {
    "time": "09-07-2001",
    "open": 106.5,
    "high": 106.95,
    "low": 103.65,
    "close": 104.72,
    "volume": 9312600
  },
  {
    "time": "06-07-2001",
    "open": 110.9,
    "high": 110.9,
    "low": 105.81,
    "close": 106.5,
    "volume": 11704600
  },
  {
    "time": "05-07-2001",
    "open": 112.6,
    "high": 113.26,
    "low": 111.79,
    "close": 112.1,
    "volume": 4419000
  },
  {
    "time": "03-07-2001",
    "open": 114.1,
    "high": 114.19,
    "low": 112.76,
    "close": 112.98,
    "volume": 3325800
  },
  {
    "time": "02-07-2001",
    "open": 113.5,
    "high": 115.4,
    "low": 112.96,
    "close": 114.35,
    "volume": 5913100
  },
  {
    "time": "29-06-2001",
    "open": 115.6,
    "high": 116.65,
    "low": 113,
    "close": 113.5,
    "volume": 9477400
  },
  {
    "time": "28-06-2001",
    "open": 113.8,
    "high": 116.95,
    "low": 113.75,
    "close": 115.1,
    "volume": 8181000
  },
  {
    "time": "27-06-2001",
    "open": 113.1,
    "high": 114.18,
    "low": 112.45,
    "close": 113.52,
    "volume": 4814100
  },
  {
    "time": "26-06-2001",
    "open": 111.25,
    "high": 114.05,
    "low": 111.1,
    "close": 113.04,
    "volume": 7081900
  },
  {
    "time": "25-06-2001",
    "open": 113.75,
    "high": 114.9,
    "low": 111.78,
    "close": 112.65,
    "volume": 5675900
  },
  {
    "time": "22-06-2001",
    "open": 112.6,
    "high": 114.43,
    "low": 112.5,
    "close": 112.87,
    "volume": 5607200
  },
  {
    "time": "21-06-2001",
    "open": 112.15,
    "high": 114.7,
    "low": 111.36,
    "close": 112.6,
    "volume": 8155800
  },
  {
    "time": "20-06-2001",
    "open": 113.5,
    "high": 114.99,
    "low": 111.76,
    "close": 113.09,
    "volume": 8828300
  },
  {
    "time": "19-06-2001",
    "open": 116.26,
    "high": 117.25,
    "low": 114.56,
    "close": 114.84,
    "volume": 6152800
  },
  {
    "time": "18-06-2001",
    "open": 113.98,
    "high": 115.44,
    "low": 113.31,
    "close": 114.27,
    "volume": 5182800
  },
  {
    "time": "15-06-2001",
    "open": 115.1,
    "high": 115.45,
    "low": 113.38,
    "close": 113.6,
    "volume": 12470100
  },
  {
    "time": "14-06-2001",
    "open": 115.6,
    "high": 116.08,
    "low": 113.99,
    "close": 115.75,
    "volume": 7502500
  },
  {
    "time": "13-06-2001",
    "open": 117.25,
    "high": 118.47,
    "low": 116.53,
    "close": 116.54,
    "volume": 5703200
  },
  {
    "time": "12-06-2001",
    "open": 116.75,
    "high": 117.8,
    "low": 115.55,
    "close": 117.25,
    "volume": 7301500
  },
  {
    "time": "11-06-2001",
    "open": 116.3,
    "high": 118.14,
    "low": 116.1,
    "close": 117.36,
    "volume": 6542700
  },
  {
    "time": "08-06-2001",
    "open": 117.5,
    "high": 117.5,
    "low": 115.6,
    "close": 116.1,
    "volume": 3099100
  },
  {
    "time": "07-06-2001",
    "open": 117,
    "high": 118.25,
    "low": 117,
    "close": 117.25,
    "volume": 6423700
  },
  {
    "time": "06-06-2001",
    "open": 117.6,
    "high": 119,
    "low": 116.5,
    "close": 117.5,
    "volume": 9624200
  },
  {
    "time": "05-06-2001",
    "open": 113.64,
    "high": 117.6,
    "low": 113.56,
    "close": 116.97,
    "volume": 6154400
  },
  {
    "time": "04-06-2001",
    "open": 113.25,
    "high": 114.75,
    "low": 112.85,
    "close": 113.64,
    "volume": 3812200
  },
  {
    "time": "01-06-2001",
    "open": 112.5,
    "high": 113.6,
    "low": 111.9,
    "close": 112.89,
    "volume": 5138900
  },
  {
    "time": "31-05-2001",
    "open": 112.65,
    "high": 113.86,
    "low": 111.64,
    "close": 111.8,
    "volume": 6724100
  },
  {
    "time": "30-05-2001",
    "open": 114,
    "high": 114.1,
    "low": 112,
    "close": 112.65,
    "volume": 9598700
  },
  {
    "time": "29-05-2001",
    "open": 117.35,
    "high": 117.59,
    "low": 115.08,
    "close": 115.27,
    "volume": 5818000
  },
  {
    "time": "25-05-2001",
    "open": 119.52,
    "high": 119.9,
    "low": 117.76,
    "close": 117.8,
    "volume": 5629100
  },
  {
    "time": "24-05-2001",
    "open": 117.5,
    "high": 119.6,
    "low": 117.5,
    "close": 119.6,
    "volume": 7726400
  },
  {
    "time": "23-05-2001",
    "open": 117.8,
    "high": 118.95,
    "low": 117.1,
    "close": 117.4,
    "volume": 6402600
  },
  {
    "time": "22-05-2001",
    "open": 119.7,
    "high": 119.7,
    "low": 117.25,
    "close": 118.01,
    "volume": 6005300
  },
  {
    "time": "21-05-2001",
    "open": 117.95,
    "high": 119.9,
    "low": 117.55,
    "close": 119.04,
    "volume": 8409400
  },
  {
    "time": "18-05-2001",
    "open": 115.07,
    "high": 117.68,
    "low": 114.9,
    "close": 117.44,
    "volume": 6714900
  },
  {
    "time": "17-05-2001",
    "open": 115.8,
    "high": 117.09,
    "low": 115.05,
    "close": 115.07,
    "volume": 6887700
  },
  {
    "time": "16-05-2001",
    "open": 112.5,
    "high": 115.8,
    "low": 112.2,
    "close": 115.8,
    "volume": 7796700
  },
  {
    "time": "15-05-2001",
    "open": 113.5,
    "high": 114.15,
    "low": 112.5,
    "close": 113.58,
    "volume": 5873400
  },
  {
    "time": "14-05-2001",
    "open": 111.2,
    "high": 113.18,
    "low": 111,
    "close": 112.56,
    "volume": 5254800
  },
  {
    "time": "11-05-2001",
    "open": 113.75,
    "high": 114.15,
    "low": 110.96,
    "close": 111.81,
    "volume": 8390200
  },
  {
    "time": "10-05-2001",
    "open": 118.5,
    "high": 118.9,
    "low": 115.2,
    "close": 115.2,
    "volume": 7993900
  },
  {
    "time": "09-05-2001",
    "open": 116.2,
    "high": 118.18,
    "low": 115.3,
    "close": 116.98,
    "volume": 7680200
  },
  {
    "time": "08-05-2001",
    "open": 117.25,
    "high": 117.75,
    "low": 115.5,
    "close": 117.7,
    "volume": 7605800
  },
  {
    "time": "07-05-2001",
    "open": 116.2,
    "high": 117.25,
    "low": 115,
    "close": 115.9,
    "volume": 6088600
  },
  {
    "time": "04-05-2001",
    "open": 112,
    "high": 115.86,
    "low": 111.2,
    "close": 115.86,
    "volume": 8681400
  },
  {
    "time": "03-05-2001",
    "open": 114.6,
    "high": 115.1,
    "low": 112.35,
    "close": 113.7,
    "volume": 6578000
  },
  {
    "time": "02-05-2001",
    "open": 118.3,
    "high": 118.95,
    "low": 113.74,
    "close": 115.4,
    "volume": 9706200
  },
  {
    "time": "01-05-2001",
    "open": 115.85,
    "high": 118.65,
    "low": 114.9,
    "close": 118.51,
    "volume": 7790800
  },
  {
    "time": "30-04-2001",
    "open": 116.4,
    "high": 118.05,
    "low": 114.72,
    "close": 115.14,
    "volume": 7357200
  },
  {
    "time": "27-04-2001",
    "open": 116.8,
    "high": 116.9,
    "low": 114.55,
    "close": 116.2,
    "volume": 7390600
  },
  {
    "time": "26-04-2001",
    "open": 114.95,
    "high": 116.7,
    "low": 113.68,
    "close": 113.74,
    "volume": 7346100
  },
  {
    "time": "25-04-2001",
    "open": 112,
    "high": 114.85,
    "low": 111.99,
    "close": 114.85,
    "volume": 6858500
  },
  {
    "time": "24-04-2001",
    "open": 113.4,
    "high": 114.75,
    "low": 112.3,
    "close": 112.67,
    "volume": 8742000
  },
  {
    "time": "23-04-2001",
    "open": 114.01,
    "high": 114.05,
    "low": 111.68,
    "close": 112,
    "volume": 8537500
  },
  {
    "time": "20-04-2001",
    "open": 114.35,
    "high": 116.4,
    "low": 113.75,
    "close": 114.83,
    "volume": 12759100
  },
  {
    "time": "19-04-2001",
    "open": 112,
    "high": 115.9,
    "low": 110.45,
    "close": 114.47,
    "volume": 25822400
  },
  {
    "time": "18-04-2001",
    "open": 103,
    "high": 110,
    "low": 102.3,
    "close": 106.5,
    "volume": 19814300
  },
  {
    "time": "17-04-2001",
    "open": 97,
    "high": 99.88,
    "low": 97,
    "close": 99.7,
    "volume": 9957300
  },
  {
    "time": "16-04-2001",
    "open": 96.2,
    "high": 99.89,
    "low": 95.2,
    "close": 96.75,
    "volume": 9188500
  },
  {
    "time": "12-04-2001",
    "open": 96.4,
    "high": 97.5,
    "low": 95.15,
    "close": 96.2,
    "volume": 9390100
  },
  {
    "time": "11-04-2001",
    "open": 101.5,
    "high": 101.94,
    "low": 95.8,
    "close": 97.43,
    "volume": 11498400
  },
  {
    "time": "10-04-2001",
    "open": 97,
    "high": 99.9,
    "low": 96.56,
    "close": 99.05,
    "volume": 10499400
  },
  {
    "time": "09-04-2001",
    "open": 98.5,
    "high": 98.74,
    "low": 93.8,
    "close": 96,
    "volume": 9486600
  },
  {
    "time": "06-04-2001",
    "open": 98,
    "high": 100,
    "low": 96.4,
    "close": 97.95,
    "volume": 13424200
  },
  {
    "time": "05-04-2001",
    "open": 95.8,
    "high": 100,
    "low": 95.53,
    "close": 98.21,
    "volume": 12506100
  },
  {
    "time": "04-04-2001",
    "open": 90.45,
    "high": 94.05,
    "low": 90.05,
    "close": 92,
    "volume": 13515600
  },
  {
    "time": "03-04-2001",
    "open": 94.6,
    "high": 94.65,
    "low": 90.1,
    "close": 90.39,
    "volume": 13330000
  },
  {
    "time": "02-04-2001",
    "open": 97.45,
    "high": 98.1,
    "low": 93.58,
    "close": 94.66,
    "volume": 8787000
  },
  {
    "time": "30-03-2001",
    "open": 96.5,
    "high": 97.56,
    "low": 95.14,
    "close": 96.18,
    "volume": 9461300
  },
  {
    "time": "29-03-2001",
    "open": 94.46,
    "high": 97.07,
    "low": 94.26,
    "close": 95.04,
    "volume": 10553700
  },
  {
    "time": "28-03-2001",
    "open": 97.9,
    "high": 98.45,
    "low": 94.25,
    "close": 94.41,
    "volume": 11890500
  },
  {
    "time": "27-03-2001",
    "open": 94,
    "high": 100,
    "low": 93.97,
    "close": 99.5,
    "volume": 11851200
  },
  {
    "time": "26-03-2001",
    "open": 95.25,
    "high": 95.66,
    "low": 93.75,
    "close": 95.4,
    "volume": 8581200
  },
  {
    "time": "23-03-2001",
    "open": 93.5,
    "high": 94.99,
    "low": 90.29,
    "close": 93.51,
    "volume": 12233600
  },
  {
    "time": "22-03-2001",
    "open": 89.12,
    "high": 91,
    "low": 87.65,
    "close": 89.1,
    "volume": 13328200
  },
  {
    "time": "21-03-2001",
    "open": 88.45,
    "high": 91.6,
    "low": 87.75,
    "close": 89.08,
    "volume": 11013000
  },
  {
    "time": "20-03-2001",
    "open": 91.6,
    "high": 92.03,
    "low": 88.1,
    "close": 88.3,
    "volume": 10101100
  },
  {
    "time": "19-03-2001",
    "open": 91,
    "high": 92.8,
    "low": 89.2,
    "close": 92.6,
    "volume": 10235500
  },
  {
    "time": "16-03-2001",
    "open": 93.9,
    "high": 93.9,
    "low": 88.2,
    "close": 90.1,
    "volume": 20029400
  },
  {
    "time": "15-03-2001",
    "open": 96,
    "high": 97.87,
    "low": 95.17,
    "close": 95.56,
    "volume": 8420000
  },
  {
    "time": "14-03-2001",
    "open": 95.4,
    "high": 98.9,
    "low": 93.34,
    "close": 94.96,
    "volume": 14246600
  },
  {
    "time": "13-03-2001",
    "open": 95.1,
    "high": 98.44,
    "low": 94.2,
    "close": 98.39,
    "volume": 12127900
  },
  {
    "time": "12-03-2001",
    "open": 97.9,
    "high": 98.4,
    "low": 95.02,
    "close": 95.49,
    "volume": 14494800
  },
  {
    "time": "09-03-2001",
    "open": 105,
    "high": 105.01,
    "low": 97.9,
    "close": 99.29,
    "volume": 16688100
  },
  {
    "time": "08-03-2001",
    "open": 107.17,
    "high": 107.34,
    "low": 105.37,
    "close": 106.47,
    "volume": 6793600
  },
  {
    "time": "07-03-2001",
    "open": 107.45,
    "high": 108.25,
    "low": 105.66,
    "close": 107.55,
    "volume": 7788900
  },
  {
    "time": "06-03-2001",
    "open": 106.25,
    "high": 108.4,
    "low": 105.25,
    "close": 106,
    "volume": 8120000
  },
  {
    "time": "05-03-2001",
    "open": 102.81,
    "high": 105.6,
    "low": 102.64,
    "close": 104.91,
    "volume": 6743900
  },
  {
    "time": "02-03-2001",
    "open": 104,
    "high": 107.65,
    "low": 100.94,
    "close": 102.3,
    "volume": 14745500
  },
  {
    "time": "01-03-2001",
    "open": 99.3,
    "high": 106.4,
    "low": 97.5,
    "close": 106.05,
    "volume": 15102000
  },
  {
    "time": "28-02-2001",
    "open": 102.95,
    "high": 103.54,
    "low": 99.1,
    "close": 99.9,
    "volume": 11933200
  },
  {
    "time": "27-02-2001",
    "open": 104.9,
    "high": 106.87,
    "low": 102.56,
    "close": 102.59,
    "volume": 9787900
  },
  {
    "time": "26-02-2001",
    "open": 105.8,
    "high": 105.8,
    "low": 101.83,
    "close": 105.3,
    "volume": 8344100
  },
  {
    "time": "23-02-2001",
    "open": 107.5,
    "high": 107.5,
    "low": 100.25,
    "close": 104,
    "volume": 15276600
  },
  {
    "time": "22-02-2001",
    "open": 106.5,
    "high": 110.9,
    "low": 103.38,
    "close": 108.9,
    "volume": 11709000
  },
  {
    "time": "21-02-2001",
    "open": 109.55,
    "high": 111.5,
    "low": 107.29,
    "close": 107.51,
    "volume": 9156900
  },
  {
    "time": "20-02-2001",
    "open": 114.55,
    "high": 115.6,
    "low": 110.78,
    "close": 111.5,
    "volume": 6748300
  },
  {
    "time": "16-02-2001",
    "open": 114.55,
    "high": 115.75,
    "low": 113.94,
    "close": 115,
    "volume": 7073000
  },
  {
    "time": "15-02-2001",
    "open": 116.8,
    "high": 118.64,
    "low": 116.38,
    "close": 116.78,
    "volume": 8098700
  },
  {
    "time": "14-02-2001",
    "open": 113.75,
    "high": 116.17,
    "low": 112.5,
    "close": 115.1,
    "volume": 7032900
  },
  {
    "time": "13-02-2001",
    "open": 114.15,
    "high": 117.38,
    "low": 113.07,
    "close": 113.75,
    "volume": 7193200
  },
  {
    "time": "12-02-2001",
    "open": 110.55,
    "high": 115.72,
    "low": 110.3,
    "close": 114.9,
    "volume": 6729300
  },
  {
    "time": "09-02-2001",
    "open": 114.03,
    "high": 114.99,
    "low": 111,
    "close": 112,
    "volume": 6318800
  },
  {
    "time": "08-02-2001",
    "open": 116.91,
    "high": 118.2,
    "low": 114.03,
    "close": 114.1,
    "volume": 8380800
  },
  {
    "time": "07-02-2001",
    "open": 113.4,
    "high": 117.6,
    "low": 112.67,
    "close": 116.91,
    "volume": 9006900
  },
  {
    "time": "06-02-2001",
    "open": 112.23,
    "high": 115.69,
    "low": 111.2,
    "close": 114.19,
    "volume": 7526400
  },
  {
    "time": "05-02-2001",
    "open": 109.4,
    "high": 112.6,
    "low": 109.3,
    "close": 112.22,
    "volume": 5113700
  },
  {
    "time": "02-02-2001",
    "open": 112.75,
    "high": 113.99,
    "low": 109.6,
    "close": 110.27,
    "volume": 5428400
  },
  {
    "time": "01-02-2001",
    "open": 112.4,
    "high": 114.34,
    "low": 111.2,
    "close": 114.05,
    "volume": 5580800
  },
  {
    "time": "31-01-2001",
    "open": 115.75,
    "high": 116.75,
    "low": 112,
    "close": 112,
    "volume": 8314200
  },
  {
    "time": "30-01-2001",
    "open": 115,
    "high": 116.75,
    "low": 113.4,
    "close": 116.61,
    "volume": 8242000
  },
  {
    "time": "29-01-2001",
    "open": 112.5,
    "high": 115.5,
    "low": 112.47,
    "close": 114.98,
    "volume": 5125000
  },
  {
    "time": "26-01-2001",
    "open": 109.62,
    "high": 115.25,
    "low": 109.19,
    "close": 114.19,
    "volume": 9095200
  },
  {
    "time": "25-01-2001",
    "open": 110.12,
    "high": 111.12,
    "low": 109.44,
    "close": 110.75,
    "volume": 4982100
  },
  {
    "time": "24-01-2001",
    "open": 109.06,
    "high": 111.44,
    "low": 108.94,
    "close": 110.44,
    "volume": 6760900
  },
  {
    "time": "23-01-2001",
    "open": 109.56,
    "high": 109.94,
    "low": 107.62,
    "close": 109.06,
    "volume": 9826900
  },
  {
    "time": "22-01-2001",
    "open": 109.5,
    "high": 109.94,
    "low": 108.19,
    "close": 108.56,
    "volume": 9618500
  },
  {
    "time": "19-01-2001",
    "open": 107.5,
    "high": 113.94,
    "low": 107.25,
    "close": 111.25,
    "volume": 14762200
  },
  {
    "time": "18-01-2001",
    "open": 104.37,
    "high": 110,
    "low": 103.5,
    "close": 108.31,
    "volume": 25244900
  },
  {
    "time": "17-01-2001",
    "open": 95.37,
    "high": 97.75,
    "low": 94.31,
    "close": 96.69,
    "volume": 9727000
  },
  {
    "time": "16-01-2001",
    "open": 93.75,
    "high": 94,
    "low": 91.81,
    "close": 92.75,
    "volume": 5671900
  },
  {
    "time": "12-01-2001",
    "open": 93.69,
    "high": 96.44,
    "low": 92.37,
    "close": 93.81,
    "volume": 6448000
  },
  {
    "time": "11-01-2001",
    "open": 92.94,
    "high": 94.25,
    "low": 91.25,
    "close": 93.69,
    "volume": 9635000
  },
  {
    "time": "10-01-2001",
    "open": 92.5,
    "high": 94.94,
    "low": 91.69,
    "close": 93.44,
    "volume": 7656100
  },
  {
    "time": "09-01-2001",
    "open": 94.44,
    "high": 95.75,
    "low": 91.44,
    "close": 92.56,
    "volume": 7547100
  },
  {
    "time": "08-01-2001",
    "open": 93.5,
    "high": 93.94,
    "low": 91.56,
    "close": 93.56,
    "volume": 6064800
  },
  {
    "time": "05-01-2001",
    "open": 93.94,
    "high": 94.69,
    "low": 91,
    "close": 94,
    "volume": 8043200
  },
  {
    "time": "04-01-2001",
    "open": 94.75,
    "high": 99.75,
    "low": 92.69,
    "close": 93.19,
    "volume": 15420500
  },
  {
    "time": "03-01-2001",
    "open": 83.75,
    "high": 95,
    "low": 83.75,
    "close": 94.62,
    "volume": 12769900
  },
  {
    "time": "02-01-2001",
    "open": 84.5,
    "high": 87.5,
    "low": 84.12,
    "close": 84.81,
    "volume": 8007200
  },
  {
    "time": "29-12-2000",
    "open": 86.94,
    "high": 86.94,
    "low": 84.25,
    "close": 85,
    "volume": 6943700
  },
  {
    "time": "28-12-2000",
    "open": 83.5,
    "high": 88.19,
    "low": 82.12,
    "close": 85.25,
    "volume": 9798600
  },
  {
    "time": "27-12-2000",
    "open": 84.75,
    "high": 88.12,
    "low": 83.56,
    "close": 84.69,
    "volume": 8245100
  },
  {
    "time": "26-12-2000",
    "open": 89,
    "high": 89.56,
    "low": 84.25,
    "close": 84.81,
    "volume": 6052300
  },
  {
    "time": "22-12-2000",
    "open": 84.5,
    "high": 89.37,
    "low": 84.5,
    "close": 89,
    "volume": 9514000
  },
  {
    "time": "21-12-2000",
    "open": 85.5,
    "high": 87.94,
    "low": 80.06,
    "close": 81.56,
    "volume": 12725500
  },
  {
    "time": "20-12-2000",
    "open": 85.87,
    "high": 88,
    "low": 84.81,
    "close": 86,
    "volume": 12579400
  },
  {
    "time": "19-12-2000",
    "open": 90.19,
    "high": 94.44,
    "low": 89.81,
    "close": 90.12,
    "volume": 7705300
  },
  {
    "time": "18-12-2000",
    "open": 88.37,
    "high": 90.5,
    "low": 88.37,
    "close": 90.5,
    "volume": 7693800
  },
  {
    "time": "15-12-2000",
    "open": 90,
    "high": 90.69,
    "low": 87.31,
    "close": 87.81,
    "volume": 14254500
  },
  {
    "time": "14-12-2000",
    "open": 92,
    "high": 93.69,
    "low": 91.81,
    "close": 92.44,
    "volume": 6131400
  },
  {
    "time": "13-12-2000",
    "open": 94.87,
    "high": 96.62,
    "low": 90.69,
    "close": 91.25,
    "volume": 6992600
  },
  {
    "time": "12-12-2000",
    "open": 95.25,
    "high": 96.12,
    "low": 92.5,
    "close": 93.87,
    "volume": 7913300
  },
  {
    "time": "11-12-2000",
    "open": 96.5,
    "high": 98.25,
    "low": 93.75,
    "close": 95,
    "volume": 6185600
  },
  {
    "time": "08-12-2000",
    "open": 98,
    "high": 99,
    "low": 95.75,
    "close": 97,
    "volume": 6140600
  },
  {
    "time": "07-12-2000",
    "open": 95.06,
    "high": 95.94,
    "low": 93.12,
    "close": 93.12,
    "volume": 7922000
  },
  {
    "time": "06-12-2000",
    "open": 101.75,
    "high": 101.81,
    "low": 94.81,
    "close": 96.75,
    "volume": 10096900
  },
  {
    "time": "05-12-2000",
    "open": 100.44,
    "high": 104.75,
    "low": 99.62,
    "close": 103.37,
    "volume": 7804200
  },
  {
    "time": "04-12-2000",
    "open": 96,
    "high": 99.19,
    "low": 95.75,
    "close": 98.37,
    "volume": 5566800
  },
  {
    "time": "01-12-2000",
    "open": 94.44,
    "high": 99.31,
    "low": 94.37,
    "close": 95.62,
    "volume": 7525100
  },
  {
    "time": "30-11-2000",
    "open": 98,
    "high": 98,
    "low": 92.25,
    "close": 93.5,
    "volume": 10651400
  },
  {
    "time": "29-11-2000",
    "open": 97.75,
    "high": 99.94,
    "low": 96.69,
    "close": 99.81,
    "volume": 5524600
  },
  {
    "time": "28-11-2000",
    "open": 98.75,
    "high": 100.75,
    "low": 97.37,
    "close": 97.87,
    "volume": 4799100
  },
  {
    "time": "27-11-2000",
    "open": 100.44,
    "high": 102,
    "low": 98.44,
    "close": 98.44,
    "volume": 3866200
  },
  {
    "time": "24-11-2000",
    "open": 99.37,
    "high": 101.75,
    "low": 99.25,
    "close": 99.94,
    "volume": 2497500
  },
  {
    "time": "22-11-2000",
    "open": 98.5,
    "high": 100.81,
    "low": 98.31,
    "close": 98.5,
    "volume": 5537100
  },
  {
    "time": "21-11-2000",
    "open": 103.25,
    "high": 104.37,
    "low": 98.5,
    "close": 98.5,
    "volume": 7180700
  },
  {
    "time": "20-11-2000",
    "open": 101.5,
    "high": 103.25,
    "low": 100.87,
    "close": 103.25,
    "volume": 7426600
  },
  {
    "time": "17-11-2000",
    "open": 98.31,
    "high": 102.5,
    "low": 98.25,
    "close": 101.94,
    "volume": 7717400
  },
  {
    "time": "16-11-2000",
    "open": 99.25,
    "high": 99.69,
    "low": 98.25,
    "close": 98.25,
    "volume": 3739700
  },
  {
    "time": "15-11-2000",
    "open": 99.5,
    "high": 100.06,
    "low": 97.62,
    "close": 99.37,
    "volume": 4160300
  },
  {
    "time": "14-11-2000",
    "open": 99.25,
    "high": 99.87,
    "low": 98.5,
    "close": 99.5,
    "volume": 5150900
  },
  {
    "time": "13-11-2000",
    "open": 91.81,
    "high": 99.44,
    "low": 91.62,
    "close": 97.44,
    "volume": 8684900
  },
  {
    "time": "10-11-2000",
    "open": 98,
    "high": 98.37,
    "low": 93,
    "close": 93,
    "volume": 6807900
  },
  {
    "time": "09-11-2000",
    "open": 96.75,
    "high": 100,
    "low": 95.69,
    "close": 99.44,
    "volume": 9047900
  },
  {
    "time": "08-11-2000",
    "open": 103.62,
    "high": 103.75,
    "low": 99.25,
    "close": 100,
    "volume": 6990800
  },
  {
    "time": "07-11-2000",
    "open": 101.12,
    "high": 103.5,
    "low": 100.69,
    "close": 102.31,
    "volume": 5420600
  },
  {
    "time": "06-11-2000",
    "open": 99.87,
    "high": 102.37,
    "low": 99.44,
    "close": 100.31,
    "volume": 4448500
  },
  {
    "time": "03-11-2000",
    "open": 102.5,
    "high": 102.62,
    "low": 99.37,
    "close": 100.12,
    "volume": 5848500
  },
  {
    "time": "02-11-2000",
    "open": 99,
    "high": 102.31,
    "low": 98.5,
    "close": 101.94,
    "volume": 7445100
  },
  {
    "time": "01-11-2000",
    "open": 98.44,
    "high": 99.87,
    "low": 97.25,
    "close": 98.56,
    "volume": 7466200
  },
  {
    "time": "31-10-2000",
    "open": 93.75,
    "high": 99.12,
    "low": 93.75,
    "close": 98.5,
    "volume": 10902600
  },
  {
    "time": "30-10-2000",
    "open": 93.5,
    "high": 93.75,
    "low": 90.31,
    "close": 93.31,
    "volume": 8084000
  },
  {
    "time": "27-10-2000",
    "open": 93,
    "high": 93.69,
    "low": 91,
    "close": 93.69,
    "volume": 6431400
  },
  {
    "time": "26-10-2000",
    "open": 88,
    "high": 92.87,
    "low": 87.62,
    "close": 92.75,
    "volume": 10135700
  },
  {
    "time": "25-10-2000",
    "open": 90.5,
    "high": 90.94,
    "low": 86.94,
    "close": 87.56,
    "volume": 14058500
  },
  {
    "time": "24-10-2000",
    "open": 93,
    "high": 94.37,
    "low": 90.19,
    "close": 91.44,
    "volume": 8826200
  },
  {
    "time": "23-10-2000",
    "open": 94.75,
    "high": 95,
    "low": 91.06,
    "close": 92.87,
    "volume": 9338600
  },
  {
    "time": "20-10-2000",
    "open": 95.12,
    "high": 96.25,
    "low": 94.19,
    "close": 94.75,
    "volume": 9369200
  },
  {
    "time": "19-10-2000",
    "open": 98,
    "high": 98.5,
    "low": 92.69,
    "close": 96.44,
    "volume": 17966900
  },
  {
    "time": "18-10-2000",
    "open": 96.75,
    "high": 99.12,
    "low": 90.25,
    "close": 95.44,
    "volume": 29777800
  },
  {
    "time": "17-10-2000",
    "open": 111.94,
    "high": 113.87,
    "low": 108.56,
    "close": 113,
    "volume": 8882500
  },
  {
    "time": "16-10-2000",
    "open": 111.5,
    "high": 113.12,
    "low": 108.56,
    "close": 111.12,
    "volume": 7710400
  },
  {
    "time": "13-10-2000",
    "open": 104.19,
    "high": 110.44,
    "low": 104.19,
    "close": 109.06,
    "volume": 9092600
  },
  {
    "time": "12-10-2000",
    "open": 114.25,
    "high": 114.37,
    "low": 103.12,
    "close": 103.12,
    "volume": 9704900
  },
  {
    "time": "11-10-2000",
    "open": 111.87,
    "high": 112.94,
    "low": 107.5,
    "close": 112,
    "volume": 8775800
  },
  {
    "time": "10-10-2000",
    "open": 116.62,
    "high": 117.12,
    "low": 112.44,
    "close": 114.87,
    "volume": 5614200
  },
  {
    "time": "09-10-2000",
    "open": 115.94,
    "high": 119.37,
    "low": 114.31,
    "close": 117.94,
    "volume": 4898000
  },
  {
    "time": "06-10-2000",
    "open": 114,
    "high": 116.12,
    "low": 112.75,
    "close": 116,
    "volume": 6108400
  },
  {
    "time": "05-10-2000",
    "open": 113.81,
    "high": 114.56,
    "low": 111.56,
    "close": 113.19,
    "volume": 5547000
  },
  {
    "time": "04-10-2000",
    "open": 110.37,
    "high": 114.87,
    "low": 108.56,
    "close": 114.37,
    "volume": 11668900
  },
  {
    "time": "03-10-2000",
    "open": 118.87,
    "high": 119.37,
    "low": 110,
    "close": 110.56,
    "volume": 11744200
  },
  {
    "time": "02-10-2000",
    "open": 112.62,
    "high": 119.62,
    "low": 112.62,
    "close": 117.81,
    "volume": 8872200
  },
  {
    "time": "29-09-2000",
    "open": 115.5,
    "high": 116.94,
    "low": 110.25,
    "close": 112.62,
    "volume": 9594600
  },
  {
    "time": "28-09-2000",
    "open": 117.37,
    "high": 118.87,
    "low": 115,
    "close": 115.25,
    "volume": 5309100
  },
  {
    "time": "27-09-2000",
    "open": 120.06,
    "high": 120.31,
    "low": 115.5,
    "close": 118,
    "volume": 5948900
  },
  {
    "time": "26-09-2000",
    "open": 120,
    "high": 121.87,
    "low": 118.37,
    "close": 119.12,
    "volume": 6217200
  },
  {
    "time": "25-09-2000",
    "open": 124.5,
    "high": 126.62,
    "low": 121,
    "close": 123,
    "volume": 4291000
  },
  {
    "time": "22-09-2000",
    "open": 123,
    "high": 124.31,
    "low": 121.31,
    "close": 123.87,
    "volume": 5930100
  },
  {
    "time": "21-09-2000",
    "open": 124.19,
    "high": 126.69,
    "low": 120,
    "close": 121.5,
    "volume": 4302200
  },
  {
    "time": "20-09-2000",
    "open": 124.69,
    "high": 126.56,
    "low": 123.5,
    "close": 124.75,
    "volume": 4623400
  },
  {
    "time": "19-09-2000",
    "open": 122.75,
    "high": 126.25,
    "low": 122.56,
    "close": 124.94,
    "volume": 3341000
  },
  {
    "time": "18-09-2000",
    "open": 125.25,
    "high": 126,
    "low": 123.06,
    "close": 123.25,
    "volume": 3932800
  },
  {
    "time": "15-09-2000",
    "open": 126.81,
    "high": 127.25,
    "low": 124.75,
    "close": 125,
    "volume": 5481700
  },
  {
    "time": "14-09-2000",
    "open": 128,
    "high": 129.19,
    "low": 126.31,
    "close": 126.87,
    "volume": 4425500
  },
  {
    "time": "13-09-2000",
    "open": 123.12,
    "high": 128.81,
    "low": 122.75,
    "close": 127.69,
    "volume": 5473600
  },
  {
    "time": "12-09-2000",
    "open": 124.81,
    "high": 126.87,
    "low": 124.06,
    "close": 125,
    "volume": 5001700
  },
  {
    "time": "11-09-2000",
    "open": 127.87,
    "high": 128.25,
    "low": 123.62,
    "close": 124.5,
    "volume": 7617700
  },
  {
    "time": "08-09-2000",
    "open": 132.5,
    "high": 132.94,
    "low": 129.38,
    "close": 129.5,
    "volume": 4993700
  },
  {
    "time": "07-09-2000",
    "open": 131.88,
    "high": 134.5,
    "low": 131.88,
    "close": 133.38,
    "volume": 4622800
  },
  {
    "time": "06-09-2000",
    "open": 130.88,
    "high": 134.88,
    "low": 130.38,
    "close": 131.44,
    "volume": 6473000
  },
  {
    "time": "05-09-2000",
    "open": 132.81,
    "high": 133.31,
    "low": 130.81,
    "close": 131.19,
    "volume": 4112100
  },
  {
    "time": "01-09-2000",
    "open": 133.06,
    "high": 134.94,
    "low": 132.63,
    "close": 133.63,
    "volume": 5776300
  },
  {
    "time": "31-08-2000",
    "open": 131.88,
    "high": 134.19,
    "low": 131.63,
    "close": 132.02,
    "volume": 5469500
  },
  {
    "time": "30-08-2000",
    "open": 130.75,
    "high": 131.5,
    "low": 129.56,
    "close": 130.31,
    "volume": 4928800
  },
  {
    "time": "29-08-2000",
    "open": 131.5,
    "high": 134.25,
    "low": 131,
    "close": 132.88,
    "volume": 6040000
  },
  {
    "time": "28-08-2000",
    "open": 129.88,
    "high": 133.19,
    "low": 129.75,
    "close": 131.5,
    "volume": 8536600
  },
  {
    "time": "25-08-2000",
    "open": 124.31,
    "high": 129.88,
    "low": 124.25,
    "close": 129,
    "volume": 8279000
  },
  {
    "time": "24-08-2000",
    "open": 123,
    "high": 125.44,
    "low": 122.69,
    "close": 124.81,
    "volume": 5988300
  },
  {
    "time": "23-08-2000",
    "open": 120.31,
    "high": 123.75,
    "low": 119.87,
    "close": 123.25,
    "volume": 4251200
  },
  {
    "time": "22-08-2000",
    "open": 121.19,
    "high": 122.19,
    "low": 121,
    "close": 121.37,
    "volume": 2769400
  },
  {
    "time": "21-08-2000",
    "open": 121.06,
    "high": 122.19,
    "low": 120.06,
    "close": 121.44,
    "volume": 3234200
  },
  {
    "time": "18-08-2000",
    "open": 122.37,
    "high": 123.37,
    "low": 120.12,
    "close": 120.44,
    "volume": 4127500
  },
  {
    "time": "17-08-2000",
    "open": 119.75,
    "high": 122.81,
    "low": 119.5,
    "close": 122.5,
    "volume": 4338400
  },
  {
    "time": "16-08-2000",
    "open": 121.06,
    "high": 122.94,
    "low": 119.5,
    "close": 122.37,
    "volume": 5946000
  },
  {
    "time": "15-08-2000",
    "open": 122.06,
    "high": 122.62,
    "low": 120.75,
    "close": 122,
    "volume": 4378100
  },
  {
    "time": "14-08-2000",
    "open": 120,
    "high": 123.69,
    "low": 119.31,
    "close": 123.25,
    "volume": 4949400
  },
  {
    "time": "11-08-2000",
    "open": 119.75,
    "high": 121.81,
    "low": 119.19,
    "close": 120.62,
    "volume": 6142400
  },
  {
    "time": "10-08-2000",
    "open": 118.75,
    "high": 120.25,
    "low": 117.87,
    "close": 119.75,
    "volume": 5484100
  },
  {
    "time": "09-08-2000",
    "open": 119,
    "high": 120.81,
    "low": 118.75,
    "close": 118.75,
    "volume": 6585400
  },
  {
    "time": "08-08-2000",
    "open": 115.69,
    "high": 119.62,
    "low": 115.56,
    "close": 118.87,
    "volume": 5736700
  },
  {
    "time": "07-08-2000",
    "open": 116.62,
    "high": 119,
    "low": 115.81,
    "close": 116.31,
    "volume": 5910700
  },
  {
    "time": "04-08-2000",
    "open": 116,
    "high": 116.5,
    "low": 114.31,
    "close": 115.87,
    "volume": 4460500
  },
  {
    "time": "03-08-2000",
    "open": 113,
    "high": 116.5,
    "low": 112.31,
    "close": 116,
    "volume": 5567400
  },
  {
    "time": "02-08-2000",
    "open": 111,
    "high": 114.87,
    "low": 110.75,
    "close": 114.25,
    "volume": 5877900
  },
  {
    "time": "01-08-2000",
    "open": 112,
    "high": 112.44,
    "low": 110.25,
    "close": 110.5,
    "volume": 2996500
  },
  {
    "time": "31-07-2000",
    "open": 110.5,
    "high": 113.69,
    "low": 110.06,
    "close": 112.25,
    "volume": 5113500
  },
  {
    "time": "28-07-2000",
    "open": 111.12,
    "high": 112.5,
    "low": 108.87,
    "close": 111.81,
    "volume": 5355600
  },
  {
    "time": "27-07-2000",
    "open": 109.87,
    "high": 111.5,
    "low": 109.31,
    "close": 110.31,
    "volume": 4047400
  },
  {
    "time": "26-07-2000",
    "open": 111,
    "high": 112.37,
    "low": 108.87,
    "close": 109.87,
    "volume": 6670400
  },
  {
    "time": "25-07-2000",
    "open": 113.25,
    "high": 113.37,
    "low": 110.25,
    "close": 112,
    "volume": 5566900
  },
  {
    "time": "24-07-2000",
    "open": 114.12,
    "high": 115.62,
    "low": 111.62,
    "close": 112.5,
    "volume": 6814400
  },
  {
    "time": "21-07-2000",
    "open": 117,
    "high": 117,
    "low": 113.75,
    "close": 114.75,
    "volume": 8966400
  },
  {
    "time": "20-07-2000",
    "open": 112,
    "high": 117.81,
    "low": 111.75,
    "close": 117.25,
    "volume": 23048400
  },
  {
    "time": "19-07-2000",
    "open": 102.37,
    "high": 109.5,
    "low": 101,
    "close": 108.75,
    "volume": 7781400
  },
  {
    "time": "18-07-2000",
    "open": 104.12,
    "high": 104.81,
    "low": 102.62,
    "close": 103.31,
    "volume": 6452700
  },
  {
    "time": "17-07-2000",
    "open": 104.44,
    "high": 107.87,
    "low": 104.19,
    "close": 105.5,
    "volume": 6632700
  },
  {
    "time": "14-07-2000",
    "open": 103.75,
    "high": 104.87,
    "low": 103.12,
    "close": 103.94,
    "volume": 4020000
  },
  {
    "time": "13-07-2000",
    "open": 105.44,
    "high": 105.94,
    "low": 102,
    "close": 104,
    "volume": 5669700
  },
  {
    "time": "12-07-2000",
    "open": 103.19,
    "high": 105.87,
    "low": 102.62,
    "close": 104.62,
    "volume": 5550500
  },
  {
    "time": "11-07-2000",
    "open": 103.25,
    "high": 104,
    "low": 101,
    "close": 102,
    "volume": 5619800
  },
  {
    "time": "10-07-2000",
    "open": 104.69,
    "high": 104.94,
    "low": 102.87,
    "close": 103.31,
    "volume": 4212200
  },
  {
    "time": "07-07-2000",
    "open": 102.69,
    "high": 105.06,
    "low": 101.62,
    "close": 105.06,
    "volume": 9031700
  },
  {
    "time": "06-07-2000",
    "open": 105,
    "high": 105.12,
    "low": 100,
    "close": 101.25,
    "volume": 12932700
  },
  {
    "time": "05-07-2000",
    "open": 105.62,
    "high": 105.62,
    "low": 101.12,
    "close": 105,
    "volume": 14339600
  },
  {
    "time": "03-07-2000",
    "open": 108.75,
    "high": 109.87,
    "low": 108.31,
    "close": 109.5,
    "volume": 2304400
  },
  {
    "time": "30-06-2000",
    "open": 112.69,
    "high": 113.19,
    "low": 108.62,
    "close": 109.56,
    "volume": 8612600
  },
  {
    "time": "29-06-2000",
    "open": 111.75,
    "high": 114.37,
    "low": 110.06,
    "close": 114,
    "volume": 7688500
  },
  {
    "time": "28-06-2000",
    "open": 110,
    "high": 115.19,
    "low": 109.87,
    "close": 113.78,
    "volume": 6436600
  },
  {
    "time": "27-06-2000",
    "open": 111.62,
    "high": 112.81,
    "low": 108.75,
    "close": 109.75,
    "volume": 6607900
  },
  {
    "time": "26-06-2000",
    "open": 113,
    "high": 115.75,
    "low": 112.87,
    "close": 114.41,
    "volume": 3625900
  },
  {
    "time": "23-06-2000",
    "open": 113.5,
    "high": 113.62,
    "low": 110.81,
    "close": 111.87,
    "volume": 3360900
  },
  {
    "time": "22-06-2000",
    "open": 114.12,
    "high": 114.31,
    "low": 111.25,
    "close": 111.81,
    "volume": 4853100
  },
  {
    "time": "21-06-2000",
    "open": 115.56,
    "high": 115.94,
    "low": 113.5,
    "close": 114.5,
    "volume": 6487000
  },
  {
    "time": "20-06-2000",
    "open": 120,
    "high": 120.69,
    "low": 115.37,
    "close": 116.37,
    "volume": 7103800
  },
  {
    "time": "19-06-2000",
    "open": 113.5,
    "high": 120.37,
    "low": 113.25,
    "close": 120.37,
    "volume": 6059200
  },
  {
    "time": "16-06-2000",
    "open": 116.31,
    "high": 117.69,
    "low": 113.25,
    "close": 113.25,
    "volume": 8046200
  },
  {
    "time": "15-06-2000",
    "open": 116,
    "high": 119,
    "low": 115.87,
    "close": 116.81,
    "volume": 5386300
  },
  {
    "time": "14-06-2000",
    "open": 118.87,
    "high": 120,
    "low": 115.5,
    "close": 116,
    "volume": 5282600
  },
  {
    "time": "13-06-2000",
    "open": 118.87,
    "high": 119.94,
    "low": 116.75,
    "close": 119.31,
    "volume": 4008600
  },
  {
    "time": "12-06-2000",
    "open": 119.12,
    "high": 120.44,
    "low": 118.84,
    "close": 118.84,
    "volume": 3640300
  },
  {
    "time": "09-06-2000",
    "open": 119.87,
    "high": 120.75,
    "low": 118.87,
    "close": 119.69,
    "volume": 4739100
  },
  {
    "time": "08-06-2000",
    "open": 119.37,
    "high": 122.31,
    "low": 117.5,
    "close": 119.75,
    "volume": 9325600
  },
  {
    "time": "07-06-2000",
    "open": 112.5,
    "high": 121.75,
    "low": 111.5,
    "close": 121,
    "volume": 11115500
  },
  {
    "time": "06-06-2000",
    "open": 112.25,
    "high": 114.62,
    "low": 111.25,
    "close": 112.37,
    "volume": 5369400
  },
  {
    "time": "05-06-2000",
    "open": 108.44,
    "high": 114.19,
    "low": 108.44,
    "close": 112.81,
    "volume": 7281400
  },
  {
    "time": "02-06-2000",
    "open": 109,
    "high": 109.75,
    "low": 107.62,
    "close": 108.81,
    "volume": 5473300
  },
  {
    "time": "01-06-2000",
    "open": 107.56,
    "high": 108.94,
    "low": 105.5,
    "close": 106,
    "volume": 5712500
  },
  {
    "time": "31-05-2000",
    "open": 110.5,
    "high": 110.75,
    "low": 107.19,
    "close": 107.31,
    "volume": 3732500
  },
  {
    "time": "30-05-2000",
    "open": 107.75,
    "high": 111,
    "low": 107.69,
    "close": 110.94,
    "volume": 3617500
  },
  {
    "time": "26-05-2000",
    "open": 105.5,
    "high": 107.19,
    "low": 104.56,
    "close": 106.94,
    "volume": 4296900
  },
  {
    "time": "25-05-2000",
    "open": 110.5,
    "high": 112.25,
    "low": 106.37,
    "close": 106.5,
    "volume": 5967600
  },
  {
    "time": "24-05-2000",
    "open": 106.5,
    "high": 110,
    "low": 104.19,
    "close": 109.62,
    "volume": 8184200
  },
  {
    "time": "23-05-2000",
    "open": 109,
    "high": 110,
    "low": 107.12,
    "close": 107.37,
    "volume": 5978900
  },
  {
    "time": "22-05-2000",
    "open": 106,
    "high": 109.25,
    "low": 103.44,
    "close": 109.25,
    "volume": 6769400
  },
  {
    "time": "19-05-2000",
    "open": 106.06,
    "high": 107.5,
    "low": 105,
    "close": 106.44,
    "volume": 6419200
  },
  {
    "time": "18-05-2000",
    "open": 106.12,
    "high": 107.31,
    "low": 104.69,
    "close": 106.06,
    "volume": 4577200
  },
  {
    "time": "17-05-2000",
    "open": 107.5,
    "high": 108.87,
    "low": 106.12,
    "close": 107.87,
    "volume": 5524500
  },
  {
    "time": "16-05-2000",
    "open": 105.12,
    "high": 109.25,
    "low": 105,
    "close": 109,
    "volume": 7265800
  },
  {
    "time": "15-05-2000",
    "open": 104,
    "high": 104.87,
    "low": 102,
    "close": 104.19,
    "volume": 5907800
  },
  {
    "time": "12-05-2000",
    "open": 103.75,
    "high": 105.75,
    "low": 103.25,
    "close": 104.44,
    "volume": 5599700
  },
  {
    "time": "11-05-2000",
    "open": 104,
    "high": 107.5,
    "low": 103.19,
    "close": 104.44,
    "volume": 8106200
  },
  {
    "time": "10-05-2000",
    "open": 105.5,
    "high": 105.69,
    "low": 102.06,
    "close": 103,
    "volume": 10012700
  },
  {
    "time": "09-05-2000",
    "open": 110.5,
    "high": 110.62,
    "low": 108.25,
    "close": 109,
    "volume": 4864900
  },
  {
    "time": "08-05-2000",
    "open": 108.06,
    "high": 110.37,
    "low": 107.62,
    "close": 109.75,
    "volume": 4826900
  },
  {
    "time": "05-05-2000",
    "open": 106.62,
    "high": 109.62,
    "low": 106.5,
    "close": 107.87,
    "volume": 4695100
  },
  {
    "time": "04-05-2000",
    "open": 109.12,
    "high": 109.19,
    "low": 107.19,
    "close": 107.62,
    "volume": 3631300
  },
  {
    "time": "03-05-2000",
    "open": 110.5,
    "high": 111.12,
    "low": 106.56,
    "close": 108.12,
    "volume": 5543800
  },
  {
    "time": "02-05-2000",
    "open": 111,
    "high": 113.62,
    "low": 110.69,
    "close": 111.37,
    "volume": 4085000
  },
  {
    "time": "01-05-2000",
    "open": 112.5,
    "high": 113.19,
    "low": 110.44,
    "close": 112,
    "volume": 4196100
  },
  {
    "time": "28-04-2000",
    "open": 112,
    "high": 112.69,
    "low": 110.12,
    "close": 111.5,
    "volume": 4888600
  },
  {
    "time": "27-04-2000",
    "open": 109.5,
    "high": 113.12,
    "low": 108.69,
    "close": 110.62,
    "volume": 6317500
  },
  {
    "time": "26-04-2000",
    "open": 112.5,
    "high": 113.94,
    "low": 109,
    "close": 110.5,
    "volume": 6182800
  },
  {
    "time": "25-04-2000",
    "open": 106.5,
    "high": 113,
    "low": 106.44,
    "close": 112.5,
    "volume": 9576400
  },
  {
    "time": "24-04-2000",
    "open": 102,
    "high": 107.19,
    "low": 101.25,
    "close": 106.5,
    "volume": 8523100
  },
  {
    "time": "20-04-2000",
    "open": 105,
    "high": 105.25,
    "low": 102.06,
    "close": 104,
    "volume": 10633300
  },
  {
    "time": "19-04-2000",
    "open": 109.75,
    "high": 109.75,
    "low": 102.81,
    "close": 105,
    "volume": 14674500
  },
  {
    "time": "18-04-2000",
    "open": 112.25,
    "high": 115.12,
    "low": 110.06,
    "close": 111.5,
    "volume": 8205500
  },
  {
    "time": "17-04-2000",
    "open": 103.87,
    "high": 112.25,
    "low": 103.31,
    "close": 111.87,
    "volume": 9969500
  },
  {
    "time": "14-04-2000",
    "open": 109.81,
    "high": 112.75,
    "low": 102.87,
    "close": 105,
    "volume": 11782000
  },
  {
    "time": "13-04-2000",
    "open": 112.5,
    "high": 114.31,
    "low": 110,
    "close": 110.62,
    "volume": 8236400
  },
  {
    "time": "12-04-2000",
    "open": 119.62,
    "high": 119.62,
    "low": 111.69,
    "close": 113.75,
    "volume": 8885200
  },
  {
    "time": "11-04-2000",
    "open": 121.62,
    "high": 121.62,
    "low": 117.81,
    "close": 119.37,
    "volume": 7738700
  },
  {
    "time": "10-04-2000",
    "open": 124.75,
    "high": 125.37,
    "low": 121.56,
    "close": 122.12,
    "volume": 5507300
  },
  {
    "time": "07-04-2000",
    "open": 123.5,
    "high": 125.69,
    "low": 121.5,
    "close": 123.12,
    "volume": 5380300
  },
  {
    "time": "06-04-2000",
    "open": 123.5,
    "high": 128,
    "low": 122.62,
    "close": 122.75,
    "volume": 8887400
  },
  {
    "time": "05-04-2000",
    "open": 120.5,
    "high": 125.75,
    "low": 119.75,
    "close": 125,
    "volume": 8693500
  },
  {
    "time": "04-04-2000",
    "open": 121.5,
    "high": 126.94,
    "low": 115.06,
    "close": 121.19,
    "volume": 16667200
  },
  {
    "time": "03-04-2000",
    "open": 120,
    "high": 122.37,
    "low": 119.44,
    "close": 122,
    "volume": 7715600
  },
  {
    "time": "31-03-2000",
    "open": 122.62,
    "high": 123.75,
    "low": 117.06,
    "close": 118.37,
    "volume": 8015200
  },
  {
    "time": "30-03-2000",
    "open": 118.87,
    "high": 123,
    "low": 118.69,
    "close": 122.75,
    "volume": 9158000
  },
  {
    "time": "29-03-2000",
    "open": 122.31,
    "high": 122.44,
    "low": 118.5,
    "close": 119,
    "volume": 7312300
  },
  {
    "time": "28-03-2000",
    "open": 125.75,
    "high": 127,
    "low": 122.06,
    "close": 122.5,
    "volume": 8149600
  },
  {
    "time": "27-03-2000",
    "open": 125,
    "high": 128.25,
    "low": 124.62,
    "close": 126.87,
    "volume": 11862900
  },
  {
    "time": "24-03-2000",
    "open": 115.37,
    "high": 122.25,
    "low": 115.06,
    "close": 120.62,
    "volume": 12125600
  },
  {
    "time": "23-03-2000",
    "open": 115.87,
    "high": 115.87,
    "low": 111.62,
    "close": 115.25,
    "volume": 6492400
  },
  {
    "time": "22-03-2000",
    "open": 114.5,
    "high": 115.37,
    "low": 113.87,
    "close": 114.25,
    "volume": 6137800
  },
  {
    "time": "21-03-2000",
    "open": 112.81,
    "high": 114.69,
    "low": 111.94,
    "close": 113.5,
    "volume": 6771600
  },
  {
    "time": "20-03-2000",
    "open": 110,
    "high": 113.37,
    "low": 109.94,
    "close": 112.75,
    "volume": 6563500
  },
  {
    "time": "17-03-2000",
    "open": 108.25,
    "high": 111.69,
    "low": 107.69,
    "close": 110,
    "volume": 8857300
  },
  {
    "time": "16-03-2000",
    "open": 109,
    "high": 109.81,
    "low": 107.87,
    "close": 109,
    "volume": 6622500
  },
  {
    "time": "15-03-2000",
    "open": 107.5,
    "high": 108.25,
    "low": 106.12,
    "close": 107,
    "volume": 5665900
  },
  {
    "time": "14-03-2000",
    "open": 107.75,
    "high": 110,
    "low": 107.37,
    "close": 108.62,
    "volume": 7973700
  },
  {
    "time": "13-03-2000",
    "open": 104,
    "high": 109,
    "low": 102.5,
    "close": 107.69,
    "volume": 7871000
  },
  {
    "time": "10-03-2000",
    "open": 107.62,
    "high": 109,
    "low": 105,
    "close": 105.25,
    "volume": 7093200
  },
  {
    "time": "09-03-2000",
    "open": 106.5,
    "high": 108,
    "low": 104.12,
    "close": 108,
    "volume": 6334100
  },
  {
    "time": "08-03-2000",
    "open": 103,
    "high": 106.87,
    "low": 102.75,
    "close": 106.25,
    "volume": 8376400
  },
  {
    "time": "07-03-2000",
    "open": 106,
    "high": 107,
    "low": 101.69,
    "close": 103,
    "volume": 10035100
  },
  {
    "time": "06-03-2000",
    "open": 109.94,
    "high": 111,
    "low": 101,
    "close": 103.06,
    "volume": 10747400
  },
  {
    "time": "03-03-2000",
    "open": 107.25,
    "high": 110,
    "low": 106.06,
    "close": 108,
    "volume": 10162800
  },
  {
    "time": "02-03-2000",
    "open": 100.5,
    "high": 105.44,
    "low": 99.5,
    "close": 103.12,
    "volume": 11192900
  },
  {
    "time": "01-03-2000",
    "open": 102,
    "high": 105.5,
    "low": 100.06,
    "close": 100.25,
    "volume": 10807800
  },
  {
    "time": "29-02-2000",
    "open": 105.56,
    "high": 105.56,
    "low": 100.94,
    "close": 102.75,
    "volume": 10484900
  },
  {
    "time": "28-02-2000",
    "open": 104.62,
    "high": 106.5,
    "low": 103.94,
    "close": 104.5,
    "volume": 8479900
  },
  {
    "time": "25-02-2000",
    "open": 109.87,
    "high": 109.87,
    "low": 104.94,
    "close": 108,
    "volume": 10312200
  },
  {
    "time": "24-02-2000",
    "open": 108.81,
    "high": 111,
    "low": 108.19,
    "close": 110.5,
    "volume": 9112000
  },
  {
    "time": "23-02-2000",
    "open": 110.5,
    "high": 110.5,
    "low": 108.5,
    "close": 108.75,
    "volume": 7435300
  },
  {
    "time": "22-02-2000",
    "open": 112,
    "high": 113.44,
    "low": 108.75,
    "close": 111,
    "volume": 7654800
  },
  {
    "time": "18-02-2000",
    "open": 115.25,
    "high": 115.94,
    "low": 111.5,
    "close": 112.5,
    "volume": 7673400
  },
  {
    "time": "17-02-2000",
    "open": 116.5,
    "high": 118.87,
    "low": 115.75,
    "close": 116.75,
    "volume": 5237500
  },
  {
    "time": "16-02-2000",
    "open": 116.5,
    "high": 117.31,
    "low": 115.25,
    "close": 115.75,
    "volume": 3966900
  },
  {
    "time": "15-02-2000",
    "open": 115.87,
    "high": 117.44,
    "low": 113.87,
    "close": 117.12,
    "volume": 5177500
  },
  {
    "time": "14-02-2000",
    "open": 116,
    "high": 116.37,
    "low": 114.5,
    "close": 116.06,
    "volume": 4544000
  },
  {
    "time": "11-02-2000",
    "open": 119.06,
    "high": 119.44,
    "low": 114.56,
    "close": 115.37,
    "volume": 5749400
  },
  {
    "time": "10-02-2000",
    "open": 117.69,
    "high": 119.12,
    "low": 116.87,
    "close": 119.12,
    "volume": 4446600
  },
  {
    "time": "09-02-2000",
    "open": 119,
    "high": 119.75,
    "low": 117,
    "close": 117.37,
    "volume": 5374700
  },
  {
    "time": "08-02-2000",
    "open": 116.94,
    "high": 119,
    "low": 116.19,
    "close": 118.81,
    "volume": 5464300
  },
  {
    "time": "07-02-2000",
    "open": 116,
    "high": 116.87,
    "low": 113.12,
    "close": 114.12,
    "volume": 5779500
  },
  {
    "time": "04-02-2000",
    "open": 118.19,
    "high": 118.44,
    "low": 114.81,
    "close": 115.62,
    "volume": 5405300
  },
  {
    "time": "03-02-2000",
    "open": 115,
    "high": 117.5,
    "low": 114.06,
    "close": 117.12,
    "volume": 6213800
  },
  {
    "time": "02-02-2000",
    "open": 112,
    "high": 115.81,
    "low": 110.37,
    "close": 113.5,
    "volume": 7707700
  },
  {
    "time": "01-02-2000",
    "open": 112.37,
    "high": 113.44,
    "low": 109.12,
    "close": 110,
    "volume": 7304700
  },
  {
    "time": "31-01-2000",
    "open": 111.37,
    "high": 112.81,
    "low": 109.62,
    "close": 112.25,
    "volume": 6202700
  },
  {
    "time": "28-01-2000",
    "open": 112.75,
    "high": 114.19,
    "low": 110.06,
    "close": 111.56,
    "volume": 6669400
  },
  {
    "time": "27-01-2000",
    "open": 118,
    "high": 118.44,
    "low": 111.62,
    "close": 113.5,
    "volume": 8324600
  },
  {
    "time": "26-01-2000",
    "open": 119.06,
    "high": 119.94,
    "low": 116,
    "close": 116.75,
    "volume": 4895100
  },
  {
    "time": "25-01-2000",
    "open": 116.75,
    "high": 119.5,
    "low": 116,
    "close": 119.12,
    "volume": 6936900
  },
  {
    "time": "24-01-2000",
    "open": 121.87,
    "high": 122.87,
    "low": 116.56,
    "close": 121.5,
    "volume": 6499200
  },
  {
    "time": "21-01-2000",
    "open": 121.87,
    "high": 123,
    "low": 119.94,
    "close": 121.5,
    "volume": 7868700
  },
  {
    "time": "20-01-2000",
    "open": 123,
    "high": 124.75,
    "low": 119,
    "close": 119,
    "volume": 17783400
  },
  {
    "time": "19-01-2000",
    "open": 115.56,
    "high": 122,
    "low": 112.69,
    "close": 119.5,
    "volume": 8634500
  },
  {
    "time": "18-01-2000",
    "open": 119.69,
    "high": 119.75,
    "low": 115,
    "close": 115.75,
    "volume": 7643900
  },
  {
    "time": "14-01-2000",
    "open": 120.94,
    "high": 123.31,
    "low": 117.5,
    "close": 119.62,
    "volume": 10956600
  },
  {
    "time": "13-01-2000",
    "open": 119.94,
    "high": 121,
    "low": 115.75,
    "close": 118.25,
    "volume": 8489700
  },
  {
    "time": "12-01-2000",
    "open": 119.62,
    "high": 122,
    "low": 118.25,
    "close": 119.5,
    "volume": 6803800
  },
  {
    "time": "11-01-2000",
    "open": 117.87,
    "high": 121.12,
    "low": 116.62,
    "close": 119,
    "volume": 7873300
  },
  {
    "time": "10-01-2000",
    "open": 117.25,
    "high": 119.37,
    "low": 115.37,
    "close": 118,
    "volume": 8540500
  },
  {
    "time": "07-01-2000",
    "open": 117.25,
    "high": 117.94,
    "low": 110.62,
    "close": 113.5,
    "volume": 11856700
  },
  {
    "time": "06-01-2000",
    "open": 118,
    "high": 118.94,
    "low": 113.5,
    "close": 114,
    "volume": 7971900
  },
  {
    "time": "05-01-2000",
    "open": 112.94,
    "high": 119.75,
    "low": 112.12,
    "close": 116,
    "volume": 12733200
  },
  {
    "time": "04-01-2000",
    "open": 114,
    "high": 114.5,
    "low": 110.87,
    "close": 112.06,
    "volume": 8227800
  },
  {
    "time": "03-01-2000",
    "open": 112.44,
    "high": 116,
    "low": 111.87,
    "close": 116,
    "volume": 10347700
  },
  {
    "time": "31-12-1999",
    "open": 109.19,
    "high": 109.5,
    "low": 106.62,
    "close": 107.87,
    "volume": 2870300
  },
  {
    "time": "30-12-1999",
    "open": 109.69,
    "high": 110.5,
    "low": 108.56,
    "close": 108.75,
    "volume": 3435100
  },
  {
    "time": "29-12-1999",
    "open": 110.44,
    "high": 110.5,
    "low": 108.75,
    "close": 109,
    "volume": 2683300
  },
  {
    "time": "28-12-1999",
    "open": 109.56,
    "high": 110.75,
    "low": 109.06,
    "close": 109.81,
    "volume": 4083100
  },
  {
    "time": "27-12-1999",
    "open": 109.69,
    "high": 110,
    "low": 108.12,
    "close": 109.75,
    "volume": 3740700
  },
  {
    "time": "23-12-1999",
    "open": 109.12,
    "high": 110.44,
    "low": 108.19,
    "close": 108.62,
    "volume": 4528700
  },
  {
    "time": "22-12-1999",
    "open": 109.87,
    "high": 110.31,
    "low": 108,
    "close": 108,
    "volume": 4005600
  },
  {
    "time": "21-12-1999",
    "open": 108.5,
    "high": 110.12,
    "low": 108.06,
    "close": 110.12,
    "volume": 4773500
  },
  {
    "time": "20-12-1999",
    "open": 109.06,
    "high": 110.44,
    "low": 107.75,
    "close": 109.2,
    "volume": 4836300
  },
  {
    "time": "17-12-1999",
    "open": 110.69,
    "high": 111.06,
    "low": 108.62,
    "close": 110,
    "volume": 7676800
  },
  {
    "time": "16-12-1999",
    "open": 108,
    "high": 109.69,
    "low": 105.75,
    "close": 109.19,
    "volume": 7122300
  },
  {
    "time": "15-12-1999",
    "open": 107.75,
    "high": 107.94,
    "low": 104.5,
    "close": 107,
    "volume": 9437900
  },
  {
    "time": "14-12-1999",
    "open": 110.19,
    "high": 110.19,
    "low": 106.87,
    "close": 109.25,
    "volume": 7437900
  },
  {
    "time": "13-12-1999",
    "open": 108.12,
    "high": 112.75,
    "low": 106.56,
    "close": 109.7,
    "volume": 7135200
  },
  {
    "time": "10-12-1999",
    "open": 111.62,
    "high": 111.94,
    "low": 107.56,
    "close": 109,
    "volume": 12588800
  },
  {
    "time": "09-12-1999",
    "open": 120.5,
    "high": 122.12,
    "low": 112.25,
    "close": 113.37,
    "volume": 16643000
  },
  {
    "time": "08-12-1999",
    "open": 116.25,
    "high": 121,
    "low": 115.5,
    "close": 118.28,
    "volume": 8139800
  },
  {
    "time": "07-12-1999",
    "open": 117,
    "high": 119.19,
    "low": 115.44,
    "close": 116.62,
    "volume": 11326100
  },
  {
    "time": "06-12-1999",
    "open": 113,
    "high": 116.5,
    "low": 112.5,
    "close": 116,
    "volume": 9928300
  },
  {
    "time": "03-12-1999",
    "open": 109.81,
    "high": 112.87,
    "low": 107.94,
    "close": 111.87,
    "volume": 14680300
  },
  {
    "time": "02-12-1999",
    "open": 103.44,
    "high": 106.31,
    "low": 103.37,
    "close": 105.27,
    "volume": 6216900
  },
  {
    "time": "01-12-1999",
    "open": 102.56,
    "high": 104.44,
    "low": 102.25,
    "close": 103.42,
    "volume": 5336400
  },
  {
    "time": "30-11-1999",
    "open": 103.69,
    "high": 104.5,
    "low": 102.12,
    "close": 103.06,
    "volume": 5758700
  },
  {
    "time": "29-11-1999",
    "open": 104.94,
    "high": 104.94,
    "low": 103.37,
    "close": 104.19,
    "volume": 5677700
  },
  {
    "time": "26-11-1999",
    "open": 105.94,
    "high": 106,
    "low": 104.12,
    "close": 105,
    "volume": 3108400
  },
  {
    "time": "24-11-1999",
    "open": 105,
    "high": 105,
    "low": 101.81,
    "close": 104.5,
    "volume": 9710400
  },
  {
    "time": "23-11-1999",
    "open": 108.81,
    "high": 109.87,
    "low": 106,
    "close": 106.06,
    "volume": 10665200
  },
  {
    "time": "22-11-1999",
    "open": 105.5,
    "high": 108.44,
    "low": 104.44,
    "close": 107.87,
    "volume": 13681600
  },
  {
    "time": "19-11-1999",
    "open": 99,
    "high": 105.12,
    "low": 98.37,
    "close": 103.94,
    "volume": 19678400
  },
  {
    "time": "18-11-1999",
    "open": 94,
    "high": 98.25,
    "low": 93.56,
    "close": 98,
    "volume": 18720500
  },
  {
    "time": "17-11-1999",
    "open": 94.87,
    "high": 94.87,
    "low": 92.62,
    "close": 93.75,
    "volume": 8355100
  },
  {
    "time": "16-11-1999",
    "open": 94.62,
    "high": 95,
    "low": 93,
    "close": 94.62,
    "volume": 8537400
  },
  {
    "time": "15-11-1999",
    "open": 96,
    "high": 96.37,
    "low": 93.87,
    "close": 94.06,
    "volume": 6259400
  },
  {
    "time": "12-11-1999",
    "open": 96,
    "high": 96.25,
    "low": 93,
    "close": 95.87,
    "volume": 9668400
  },
  {
    "time": "11-11-1999",
    "open": 97.5,
    "high": 97.5,
    "low": 94.87,
    "close": 95,
    "volume": 7147500
  },
  {
    "time": "10-11-1999",
    "open": 94.62,
    "high": 97.06,
    "low": 94.12,
    "close": 97,
    "volume": 8680300
  },
  {
    "time": "09-11-1999",
    "open": 95.06,
    "high": 95.5,
    "low": 92.12,
    "close": 93.62,
    "volume": 9551200
  },
  {
    "time": "08-11-1999",
    "open": 90.5,
    "high": 93.94,
    "low": 90.5,
    "close": 93.94,
    "volume": 8522300
  },
  {
    "time": "05-11-1999",
    "open": 92.75,
    "high": 92.94,
    "low": 90.19,
    "close": 90.25,
    "volume": 13737600
  },
  {
    "time": "04-11-1999",
    "open": 94.44,
    "high": 94.44,
    "low": 90,
    "close": 91.56,
    "volume": 16697600
  },
  {
    "time": "03-11-1999",
    "open": 95.87,
    "high": 95.94,
    "low": 93.5,
    "close": 94.37,
    "volume": 10369100
  },
  {
    "time": "02-11-1999",
    "open": 96.75,
    "high": 96.81,
    "low": 93.69,
    "close": 94.81,
    "volume": 11105400
  },
  {
    "time": "01-11-1999",
    "open": 98.5,
    "high": 98.81,
    "low": 96.37,
    "close": 96.75,
    "volume": 9551800
  }
]
  