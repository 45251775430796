import React from 'react'

const Header = () => {
    return (
        <>
            <div className="search-bar pb-5">
                {/* <!--------ul-----search-bar----> */}
                <div className="row">
                    <div className="col-lg-6">
                        <div className="search">
                            <i className="bi bi-search" type="submit"></i>
                            <input type="text" className="form-control" placeholder="Search Now" />
                        </div>
                    </div>


                    <div className="col-lg-6 select-bx">
                        <div className="user-img"></div>

                        <select className="form-select" aria-label="Default select example">
                            <option selected>Jeremys</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header