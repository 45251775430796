import React from 'react'
import {  toast } from 'react-toastify';

const NotificationToast = (msg) => {
    
  return (
    <>
        {msg!==undefined &&toast.success(msg)}
    </>
  )
}

export default NotificationToast