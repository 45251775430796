import React from 'react'
import { Link } from 'react-router-dom'

const SideBar = () => {
  return (
    <>
      <div className="col-lg-2 ">

        {/* <!-- <div className="toggle-bar">
        
    <a className="btn" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
         <i className="bi bi-list-nested"></i>
    </a>

       <div  className="col-lg-12">
             <div className="offcanvas offcanvas-start hide fade" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" aria-modal="true" role="dialog" style="visibility: visible;">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <div className="">
                  Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
                </div>
                <div className="dropdown mt-3">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                    Dropdown button
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                  </ul>
                </div>
              </div>
            </div>
       </div>
</div> --> */}

        <div className="left-bar left-side" >
          <div className="row">
            <div className="col-lg-12 pb-3 text-center">
              <img src="assets/image/logo.jpg" className="img-fluid" alt='img' />
            </div>


            <div className="menu-sec">
              {/* <!----====menu sec===----> */}
              <div className="row">
                <div className="col-lg-12">
                  <ul>

                    <li><Link to={'/'}> <i className="bi bi-houses"></i> <p> Dashboard</p>  </Link></li>

                    <li><Link to={'/tools'}> <i className="bi bi-wrench-adjustable"></i> <p> Tools </p>  </Link></li>

                    <li><Link to={'/Analysis'}><i className="bi bi-bar-chart"></i> <p> Analysis </p>  </Link></li>

                    <li><Link to={'/learn'}> <i className="bi bi-book-half"></i> <p> Learn </p>  </Link></li>

                    <li><Link to={'/trade-check'}> <i className="bi bi-check2-square"></i> <p> TradeCheck</p>  </Link></li>

                    {/* <li><a href=""> <i className="bi bi-chat-dots"></i> <p> Chat</p>  </a></li> */}

                  </ul>
                </div>
              </div>
            </div>
            {/* <!----====menu sec===----> */}

          </div>
        </div>
      </div>
    </>
  )
}

export default SideBar