import React, { useEffect, useState, useMemo } from 'react'
import { getStockCashFlow } from '../../../../services/TradeService'
import approximateNumber from 'approximate-number'
import NumberConverter from '../../../commonComponent/NumberConverter'
import Loader from '../../../commonComponent/Loader'

const StockCashFlow = ({ typeData, tradeCode }) => {
	const [cashFlowData, setCashFlowData] = useState([])
	const [cashFlowDataStatus, setCashFlowDataStatus] = useState(false)
	const [mapedData, setMapedData] = useState([])
	useEffect(() => {
		if (typeData === 'CashFlow') {
			try {
				getStockCashFlow(tradeCode).then((res) => {
					setCashFlowData(res.data)
					setCashFlowDataStatus(true)
				})
			} catch (error) {
				
			}
		}
	}, [typeData])
	useEffect(() => {
		if (cashFlowData.length > 0) {
			let a = []
			for (let i = 0; i < 5; i++) {
				let n = []
				const result = cashFlowData.filter((arrayData) => {
					if (arrayData.fiscalYear === cashFlowData[0].fiscalYear - i) {
						return arrayData
					}
				})
				for (let j = 0; j < result.length; j++) {
					// if (result[j].fiscalYear === 2023) {
					// 	

					// 		capital: true,
					// 		decimal: true,
					// 		// round: true
					// 	}))
					// }

					n = {
						"fiscalYear": result[j].fiscalYear,
						"capitalExpenditures": n?.capitalExpenditures || 0 + result[j].capitalExpenditures,
						"cashChange": n?.cashChange || 0 + result[j].cashChange,
						"cashFlow": n?.cashFlow || 0 + result[j].cashFlow,
						"cashFlowFinancing": n?.cashFlowFinancing || 0 + result[j].cashFlowFinancing,
						"fiscalQuarter": n?.fiscalQuarter || 0 + result[j].fiscalQuarter,
						"netBorrowings": n?.netBorrowings || 0 + result[j].netBorrowings,
						"netIncome": n?.netIncome || 0 + result[j].netIncome
					}
				}

				a.push(n)
				// 
			}
			setMapedData(a)
		}
	}, [cashFlowData])
	
	return (
		<>
			<div class="tab-pane fade" id="pills-cashflow" role="tabpanel"
				aria-labelledby="pills-cashflow-tab">
				<div class="row">

					<div className='d-flex'>
						<h4 class="mt-4 mb-4">CashFlow</h4>
						<h6 class="mt-4 mb-4 ml-auto">(USD)</h6>
					</div>
					<div class="col-sm-12">
						{
							cashFlowDataStatus==false ?
							 <Loader/> : 
							 mapedData.length>0?<div class="Fundamentals-bx">
							 <div class="table-responsive">
								 <div class="table-input1">
									 <table class="table ">

										 <tbody>
											 <tr>
												 <th scope="row" class="border-end col-lg-4">
													 Financial Year </th>
												 {
													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">FY {val.fiscalYear} </td>
														 )
													 })
												 }
											 </tr>
											 <tr>
												 <th scope="row" class="border-end col-lg-4">Net
													 Change in Cash</th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.cashChange)} </td>
														 )
													 })

												 }
											 </tr>
											 <tr>
												 <th scope="row" class="border-end col-lg-4">Net Income </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.netIncome)} </td>
														 )
													 })

												 }
											 </tr>

											 <tr>
												 <th scope="row" class="border-end col-lg-4">Capital
													 Expenditures </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.capitalExpenditures)} </td>
														 )
													 })

												 }
											 </tr>

											 <tr>
												 <th scope="row" class="border-end col-lg-4">Free
													 Cash Flow </th>
												 {

													 mapedData?.map((val, ind) => {
														 return (
															 <td key={ind} class="text-end ">$ {NumberConverter(val.cashFlow)} </td>
														 )
													 })

												 }
											 </tr>
										 </tbody>

									 </table>
								 </div>
							 </div>
						 </div>:<div className='w-100 text-center'>
										<h4 className='no-data'>Data Not Available</h4>
									</div>
						}

					</div>
				</div>
			</div>
		</>
	)
}

export default StockCashFlow