import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const ApplicationKey = process.env.REACT_APP_ApplicationKey;
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}

export const getCountryListAPI = async (id) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetCountry?ApplicationKey=${ApplicationKey}`,

            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
           
        });
    
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getExchangeRateAPI = async (CurrencyFrom ,CurrencyTo) => {
    try {
        const response = await axios({
            method: 'GET',
            // url: `${API_URL}/Crypto/GetExchangeRate/${first+'/'+second}?ApplicationKey=${ApplicationKey}`,
            url: `${API_URL}/Forex/GetExchangeRate/${CurrencyFrom}/${CurrencyTo}?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
        });
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
    }
}

export const getRapidExchangeRateAPI = async (CurrencyFrom, CurrencyTo) => {
  try {
    const response = await axios({
      method: "GET",
      // url: `${API_URL}/Crypto/GetExchangeRate/${first+'/'+second}?ApplicationKey=${ApplicationKey}`,
      url: `${API_URL}/Rapid/GetExchangeRate/${CurrencyFrom}/${CurrencyTo}?ApplicationKey=${ApplicationKey}`,
      mode: "cors",
      headers,
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
    }
    //
  } catch (error) {}
};


export const getStocks = async (stockcode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetCommodities/${stockcode}?ApplicationKey=${ApplicationKey}`,
            // url: `${API_URL}/Forex/GetCommodities/${stockCode}?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
    }
}

export const getCommoditiPrice = async (data) => {
    // console.log(data ,"dataaaaaa")
    try {
        const response = await axios({
            method: 'GET',
           // url: `${API_URL}/Rapid/GetStockDetails/${data.base}/${data.stockcode}?ApplicationKey=${ApplicationKey}`,
            // url: `${API_URL}/Forex/GetCommodities/${stockCode}?ApplicationKey=${ApplicationKey}`,
            url: `${API_URL}/Rapid/GetStockDetails/${data.stockcode}?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
    }
}

// export const getOHLCCommoditiPrice = async (data) => {
//     try {
//         const response = await axios({
//             method: 'GET',
//             url: `${API_URL}/Rapid/GetStockOHLC/${data.date}/${data.base}/${data.stockcode}?ApplicationKey=${ApplicationKey}`,
//             // url: `${API_URL}/Forex/GetCommodities/${stockCode}?ApplicationKey=${ApplicationKey}`,
//             mode: 'cors',
//             headers,
//             // data: {
//             //   firstName: 'Fred',
//             //   lastName: 'Flintstone'
//             // }
//         });
//         if(response?.status===200){
//             return response
//         }
//         else if(response?.status===401){
//             alert('Not authorized.')
//         }else if(response?.status>=500){
            
//         }
//         // 
//     } catch (error) {
//     }
// }