import React, { useEffect, useState } from 'react'
import { getStockIncomeStatement } from '../../../../services/TradeService'
import approximateNumber from 'approximate-number'
import NumberConverter from '../../../commonComponent/NumberConverter'

const StockIncomeStateMent = ({ typeData,tradeCode }) => {
	const [incomeData, setIncomeData] = useState([])
	const [incomeDataStatus, setIncomeDataStatus] = useState(false)
	const [mapedData, setMapedData] = useState([])
	useEffect(() => {
		if (typeData === 'IncomeStatement') {
			try {
				getStockIncomeStatement(tradeCode).then((res) => {
					
					setIncomeData(res.data)
					setIncomeDataStatus(true)
				})
			} catch (error) {
				
			}
		}
	}, [typeData])
	useEffect(() => {
		if (incomeData.length > 0) {
			let a = []
			for (let i = 0; i < 5; i++) {
				let n = []
				const result = incomeData.filter((arrayData) => {
					if (arrayData.fiscalYear === incomeData[0].fiscalYear - i) {
						return arrayData
					}
				})
				for (let j = 0; j < result.length; j++) {
					n = {
						"fiscalYear": result[j].fiscalYear,
						"costOfRevenue": n?.costOfRevenue || 0 + result[j].costOfRevenue,
						"grossProfit": n?.grossProfit || 0 + result[j].grossProfit,
						"totalRevenue": n?.totalRevenue || 0 + result[j].totalRevenue,
						"netIncome": n?.netIncome || 0 + result[j].netIncome
					}
				}
				a.push(n)
			}
			setMapedData(a)
		}
	}, [incomeData])
	return (
		<>
			<div class="tab-pane fade" id="pills-incomeStatement" role="tabpanel"
				aria-labelledby="pills-incomeStatement-tab">
				<div class="row">
				<div className='d-flex'>
						<h4 class="mt-4 mb-4">Income Statement</h4>
						<h6 class="mt-4 mb-4 ml-auto">(USD)</h6>
					</div>
					<div class="col-sm-12">
						{
							incomeDataStatus==false ? 
							<div class="container-fluid">
								<div class="container">
									<div class="row">
										<div class="col-md-12">
											<div class="preloader1">
												{/* <div class="loader loader-inner-1"> */}
												<div class="loader loader-inner-2">
													<div class="loader loader-inner-3">
													</div>
												</div>
												{/* </div> */}
											</div>
										</div>
									</div>
								</div>
							</div> :mapedData.length > 0? 
							<div class="Fundamentals-bx">
								<div class="table-responsive">
									<div class="table-input1">
										<table class="table ">
											<tbody>
												<tr>
													<th scope="row" class="border-end col-lg-4">
														Financial Year </th>
													{
														mapedData?.map((val, ind) => {
															return (
																val.fiscalYear && <td key={ind} class="text-end ">FY {val.fiscalYear} </td>
															)
														})
													}
												</tr>
												<tr>
													<th scope="row" class="border-end col-lg-4">Gross Profit</th>
													{
														mapedData?.map((val, ind) => {
															return (
																<td key={ind} class="text-end ">$ { NumberConverter(val.grossProfit)} </td>
															)
														})
													}
												</tr>
												<tr>
													<th scope="row" class="border-end col-lg-4">Net Income </th>
													{
														mapedData?.map((val, ind) => {
															return (
																<td key={ind} class="text-end ">$ {NumberConverter(val.netIncome)} </td>
															)
														})
													}
												</tr>
												<tr>
													<th scope="row" class="border-end col-lg-4">Cost Of Revenue </th>
													{
														mapedData?.map((val, ind) => {
															return (
																<td key={ind} class="text-end ">$ {NumberConverter(val.costOfRevenue)} </td>
															)
														})
													}
												</tr>
												<tr>
													<th scope="row" class="border-end col-lg-4">Total Revenue </th>
													{
														mapedData?.map((val, ind) => {
															return (
																<td key={ind} class="text-end ">$ {NumberConverter(val.totalRevenue)} </td>
															)
														})
													}
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>:<div className='w-100 text-center'>
										<h4 className='no-data'>Data Not Available</h4>
									</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default StockIncomeStateMent