import React from 'react'

const Loader = () => {
	return (
		<>
			<div class="container-fluid show main-loader">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="preloader1">
								{/* <div class="loader loader-inner-1"> */}
								<div class="loader loader-inner-2">
									<div class="loader loader-inner-3">
									</div>
								</div>
								{/* </div> */}
								<p className='animate__pulse'>Loading...</p>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}

export default Loader