import React from "react";

const ViewImage = ({ chartImg }) => {
  return (
    <>
      <div
        className="modal fade show backdrop-1"
        data-bs-backdrop="static"
        data-keyboard="false"
        id="viewImage"
        tabindex="-1"
        aria-labelledby="exampleModalLgLabel"
        style={{ display: "none" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-img modal-md  modal-dialog-centered">
          <div className="modal-content  provider-min-md">
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title h4" id="exampleModalLgLabel">
                Chart Image
              </h5>
              <button
                type="button"
                className=" ms-auto button-padding"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                ×
              </button>
            </div>

            <div className="modal-body blackout-bx">
              {chartImg?.length > 0 ? (
                <img
                  className="w-100"
                  //   src={"https://api.crypto.tradinglounge.com/" + chartImg}
                  src={"https://api.forex.tradinglounge.com/" + chartImg}
                />
              ) : (
                <h4 className="no-data">No image found</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewImage;
