import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const ApplicationKey = process.env.REACT_APP_ApplicationKey;
const API_KEY = process.env.REACT_APP_API_KEY

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
    'Cache-Control': 'no-cache',
    'Authorization':API_KEY
}
let msg = ''
let status = 0

export const searchStocks = async (data) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/Getstocks?${data.providerId ? 'ProviderId=' + data.providerId + '&' : ''}${data.a ? 'Searchtext=' + data.a : ''}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getStockList = async (data) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/Getstocks?ProviderId=${data}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {

            
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getSearchStockDetails = async (stockCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/commodities/GetQuotesDetails/${stockCode}/usd?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        // toast.error('Something went wrong. Please try again later.')
    }
}

export const getStocksByPortFolioId = async (id) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetPortfolioTrades?PortfolioId=${id}&ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}


export const addStocks = async (data) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddTrade?ApplicationKey=${ApplicationKey}`,
            mode: 'cors',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
                'Authorization':API_KEY
            },
            data: data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
