import React, { useEffect } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { PriceData } from './Datas/PriceData';
import { VolumeData } from './Datas/VolumeData';

const Chart = () => {
    const data = PriceData;
    const Volumes=VolumeData
    useEffect(() => {
        // console.log(VolumeData,PriceData)
        const container = document.getElementById('chart-container');
        const chart = createChart(container, {
          width: container.clientWidth,
          height: container.clientHeight,
          crosshair: {
            mode: CrosshairMode.Normal,
          },
        });
    
        const candlestickSeries = chart.addCandlestickSeries({
            scaleMargins: {
                // positioning the price scale for the area series
                top: 0.1,
                bottom: 0.4,
            },
        });
        const CandlevolumeSeries = chart.addHistogramSeries({
            // color: '#115c25',
            // wickUpColor: 'rgb(54, 116, 217)',
            upColor: '#115c25',
            // wickDownColor: 'rgb(225, 50, 85)',
            downColor: '#c41404',
            borderVisible: false,
            priceFormat: {
                type: 'volume',
            },
            priceScaleId: '', // set as an overlay by setting a blank priceScaleId
            // set the positioning of the volume series
            scaleMargins: {
                top: 0.7, // highest point of the series will be 70% away from the top
                bottom: 0,
            },
        });
        CandlevolumeSeries.priceScale().applyOptions({
            scaleMargins: {
                top: 0.7, // highest point of the series will be 70% away from the top
                bottom: 0,
            },
        });
        
        CandlevolumeSeries.setData(VolumeData)
        candlestickSeries.setData(data)
        // Sample candlestick and volume data (replace with your own data)
        // Ensure that your data has the required properties: time, open, high, low, close, volume
        // const volumeSeries = chart.addHistogramSeries({
        //     color: '#182233',
        //     lineWidth: 2,
        //     priceFormat: {
        //       type: 'volume',
        //     },
        //     overlay: true,
        //     scaleMargins: {
        //       top: 0.8,
        //       bottom: 0,
        //     },
        //   });
      
        //   volumeSeries.setData(VolumeData);
        
        // data.forEach((item) => {
        //   candlestickSeries.update(item);
        //   CandlevolumeSeries.update({ time: item.time, value: item.volume });
        // });

        // const candlestickSeries = chart.addCandlestickSeries();
        // const volumeSeries = chart.addHistogramSeries({
        //   color: 'rgba(0, 128, 0, 0.5)',
        //   priceFormat: {
        //     type: 'volume',
        //   },
        //   overlay: true,
        //   scaleMargins: {
        //     top: 0.8,
        //     bottom: 0,
        //   },
        // });
        // data.forEach((item) => {
        //   candlestickSeries.update(item);
        //   volumeSeries.update({ time: item.time, value: item.volume });
        // });



    
        return () => {
          chart.remove();
        };
      }, [data,VolumeData]);
      return <div id="chart-container" style={{ width: '100%', height: '80%' }} />;
}

export default Chart