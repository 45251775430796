import {
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/myStyle.css";
import "./assets/css/animetion.css";
import "./assets/css/loading.css";
import Analysis from "./pages/Analysis";
import Home from "./pages/Home";
import AdminDashboard from "./pages/AdminDashboard";
import Trade from "./pages/Trade";
import TradeCheck from "./pages/TradeCheck";
import CandleChartView from "./pages/CandleChartView";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import Loader from "./components/commonComponent/Loader";
import Records from "./pages/Records";

// import './assets/js/bootstrap.js'
// import useAuth from './routeProtect/useAuth'

function App() {
  const [userType, setUserType] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  function caseInsensitiveGet(obj, key) {
    const lowercaseKey = key.toLowerCase();
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop) && prop.toLowerCase() === lowercaseKey) {
        return obj[prop];
      }
    }
    return undefined;
  }
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const id = caseInsensitiveGet(params, "userId");
  let rol = "";
  //id = 277
  if (id == "5" || id == "6" || id == "10") {
    rol = "admin";
    console.log(rol, "rol");
  } else {
    rol = "user";
  }
  useEffect(() => {
    let data = {
      userId: id,
      role: rol?.toLowerCase(),
    };
    localStorage.setItem("userDetails", JSON.stringify(data));
    let nm = localStorage.getItem("userDetails");
    // setUserType(JSON.parse(nm))
    setUserType(data);
  }, []);

  // const { home } = useAuth();
  return (
    <>
      <ToastContainer position="top-center" autoClose={3000} theme="dark" />
      <Routes>
        {/* <Route path='/' element={<Home/>}/> */}
        <Route
          path="/"
          element={userType?.role !== undefined ? <Home /> : <Loader />}
        />
        <Route
          path="*"
          element={userType?.role !== undefined ? <Home /> : <Loader />}
        />
        <Route path="/trade" element={<Trade />} />
        <Route path="/admin" element={<AdminDashboard />} />
        {/* <Route path='*' element={userType?.role == 'admin' ? <AdminDashboard/> :userType?.role == 'user' ? <Home/>:<Loader/>} /> */}
        {/* <Route path='/provider' element={<Provider />} /> */}
        <Route path="/Analysis" element={<Analysis />} />
        {/* <Route path='/learn' element={<Tools/>} /> */}
        <Route path="/trade-check" element={<TradeCheck />} />
        <Route path="/chart" element={<CandleChartView />} />
        <Route path="/Records" element={<Records />} />
      </Routes>
    </>
  );
}

export default App;
