import React from 'react'
import SideBar from '../components/commonComponent/SideBar'
import Footer from '../components/commonComponent/Footer'
import Header from '../components/commonComponent/Header'
import Chart from '../chartFold/Chart'
import { BsSearch } from 'react-icons/bs'

const Analysis = () => {
    return (
        <>
            <div style={{ height: window.screen.height }}>
                <div style={{ height: '10%' }} className='chart_top_bar'>
                    <div class="row">
                        <div class="input-group">
                            <div class="form-outline">
                                <input type="search" id="form1" class="form-control" />
                            </div>
                            <button type="button" class="btn btn-primary">
                                <BsSearch />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='main_chart' style={{ height: '70%' }} >
                    <Chart />
                </div>
            </div>


            {/* <Footer/> */}
        </>
    )
}

export default Analysis